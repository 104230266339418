"use strict";

import Immutable from 'immutable';

/**
 * PlatformArticleDTO definition [api pre-process type]
 *
 * @typedef {Object} PlatformArticleDTO
 * @property {?number} Id - Gets or sets the identifier.
 * @property {?number} AffiliateId - Gets or sets the affiliate identifier.
 * @property {?string} ContextCode - Gets or sets the context code.
 * @property {?number} Ordinality - Gets or sets the ordinality.
 * @property {?string} Title - Gets or sets the title.
 * @property {?string} Body - Gets or sets the body.
 * @property {?string} AppSettingGroupId - Gets or sets the application setting group identifier.
 * @property {?string} ModifiedDate - Modified date
 * @property {?string} ModifiedBy - Modified by
 *
 */

/**
 * PlatformArticle definition
 *
 * @typedef {Immutable.Record} PlatformArticle
 *
 * @property {number} id
 * @property {number} affiliateId
 * @property {string} contextCode
 * @property {number} ordinality
 * @property {string} title
 * @property {string} body
 * @property {string} appSettingGroupId
 * @property {string} modifiedDate
 * @property {string} modifiedBy
 *
 */

/**
 * PlatformArticle record
 *
 * @type {PlatformArticle}
 *
 */
class PlatformArticle extends Immutable.Record({
    id: null,
    affiliateId: null,
    contextCode: null,
    ordinality: null,
    title: null,
    body: null,
    appSettingGroupId: null,
    modifiedDate: null,
    modifiedBy: null
}) {
    /**
     * toJS shallow
     * converts Record to object by keeping all second level immutable or other complex properties intact
     *
     * @return {Object}
     */
    toJSShallow() {
        return {
            ...this.toJS()
        };
    }

    /**
     * Set values from data transfer object
     *
     * @param {PlatformArticleDTO} dataTransferObject
     * @return {PlatformArticle}
     */
    fromDTO(dataTransferObject) {
        return new PlatformArticle({
            ...this.toJSShallow(),
            id: typeof dataTransferObject.Id !== 'undefined' ? dataTransferObject.Id : this.id,
            affiliateId: typeof dataTransferObject.AffiliateId !== 'undefined' ? dataTransferObject.AffiliateId : this.affiliateId,
            contextCode: typeof dataTransferObject.ContextCode !== 'undefined' ? dataTransferObject.ContextCode : this.contextCode,
            ordinality: typeof dataTransferObject.Ordinality !== 'undefined' ? dataTransferObject.Ordinality : this.ordinality,
            title: typeof dataTransferObject.Title !== 'undefined' ? dataTransferObject.Title : this.title,
            body: typeof dataTransferObject.Body !== 'undefined' ? dataTransferObject.Body : this.body,
            appSettingGroupId: typeof dataTransferObject.AppSettingGroupId !== 'undefined' ? dataTransferObject.AppSettingGroupId : this.appSettingGroupId,
            modifiedDate: typeof dataTransferObject.ModifiedDate !== 'undefined' ? dataTransferObject.ModifiedDate : this.modifiedDate,
            modifiedBy: typeof dataTransferObject.ModifiedBy !== 'undefined' ? dataTransferObject.ModifiedBy : this.modifiedBy
        });
    }
}

export default PlatformArticle;
