"use strict";

import Immutable from 'immutable';

/**
 * CardDetailsDTO definition
 *
 * @typedef {Object} CardDetailsDTO
 * @property {?boolean} IsDefault - Is card default
 * @property {?string} Brand - Card brand name
 * @property {?string} Last4Digits - Last 4 digits of card
 * @property {?string} CardToken - Card token number
 * @property {?string} City
 * @property {?string} Country
 * @property {?string} AddressLine1
 * @property {?string} AddressLine2
 * @property {?string} PostCode - Post code / zip code
 * @property {?string} CardHolderName - Cardholder name
 * @property {?string} ExpiryYear
 * @property {?string} ExpiryMonth
 * @property {?string} Message
 * @property {?string} HttpStatusCode - Http status code
 * @property {?string} ErrorType
 * @property {?string} ErrorCode
 * @property {?string} DeclineCode
 */

/**
 * CardDetails definition
 *
 * @typedef {Immutable.Record} CardDetails
 * @property {?boolean} isDefault
 * @property {?string} brand
 * @property {?string} lastFourDigits
 * @property {?string} number
 * @property {?number} cvc
 * @property {?string} cardToken
 * @property {?string} city
 * @property {?string} country
 * @property {?string} addressLine1
 * @property {?string} addressLine2
 * @property {?string} postCode
 * @property {?string} cardHolderName
 * @property {?string} expiryYear
 * @property {?string} expiryMonth
 * @property {?string} message
 * @property {?string} httpStatusCode
 * @property {?string} errorType
 * @property {?string} errorCode
 * @property {?string} declineCode
 */

/**
 * CardDetails record
 *
 * @type {CardDetails}
 */
class CardDetails extends Immutable.Record({
    isDefault: null,
    brand: null,
    lastFourDigits: null,
    number: null,
    cardToken: null,
    city: null,
    country: null,
    addressLine1: null,
    addressLine2: null,
    postCode: null,
    cardHolderName: null,
    expiryMonth: null,
    expiryYear: null,
    message: null,
    httpStatusCode: null,
    errorType: null,
    errorCode: null,
    declineCode: null
}) {
    /**
     * Set values from data transfer object
     *
     * @param {CardDetailsDTO} dataTransferObject
     * @return {CardDetails}
     */
    fromDTO(dataTransferObject) {
        return new CardDetails({
            ...this.toJS(),
            isDefault: typeof dataTransferObject.IsDefault !== 'undefined' ? dataTransferObject.IsDefault : this.isDefault,
            brand: typeof dataTransferObject.Brand !== 'undefined' ? dataTransferObject.Brand : this.brand,
            lastFourDigits: typeof dataTransferObject.Last4Digits !== 'undefined' ? dataTransferObject.Last4Digits : this.lastFourDigits,
            cardToken: typeof dataTransferObject.CardToken !== 'undefined' ? dataTransferObject.CardToken : this.cardToken,
            city: typeof dataTransferObject.City !== 'undefined' ? dataTransferObject.City : this.city,
            country: typeof dataTransferObject.Country !== 'undefined' ? dataTransferObject.Country : this.country,
            addressLine1: typeof dataTransferObject.AddressLine1 !== 'undefined' ? dataTransferObject.AddressLine1 : this.addressLine1,
            addressLine2: typeof dataTransferObject.AddressLine2 !== 'undefined' ? dataTransferObject.AddressLine2 : this.addressLine2,
            postCode: typeof dataTransferObject.PostCode !== 'undefined' ? dataTransferObject.PostCode : this.postCode,
            cardHolderName: typeof dataTransferObject.CardHolderName !== 'undefined' ? dataTransferObject.CardHolderName : this.cardHolderName,
            expiryMonth: typeof dataTransferObject.ExpiryMonth !== 'undefined' ? dataTransferObject.ExpiryMonth : this.expiryMonth,
            expiryYear: typeof dataTransferObject.ExpiryYear !== 'undefined' ? dataTransferObject.ExpiryYear : this.expiryYear,
            message: typeof dataTransferObject.Message !== 'undefined' ? dataTransferObject.Message : this.message,
            httpStatusCode: typeof dataTransferObject.HttpStatusCode !== 'undefined' ? dataTransferObject.HttpStatusCode : this.httpStatusCode,
            errorType: typeof dataTransferObject.ErrorType !== 'undefined' ? dataTransferObject.ErrorType : this.errorType,
            errorCode: typeof dataTransferObject.ErrorCode !== 'undefined' ? dataTransferObject.ErrorCode : this.errorCode,
            declineCode: typeof dataTransferObject.DeclineCode !== 'undefined' ? dataTransferObject.DeclineCode : this.declineCode
        });
    }
}

export default CardDetails;
