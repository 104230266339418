"use strict";

import React from 'react';
import store from 'store';
import MobileDetect from 'mobile-detect';
import ClientJS from 'clientjs';
import Config from '../../lib/Config';
import Router from '../../lib/Router';
import OverlayIds from '../../lib/OverlayIds';
import AnalyticsActions from '../../actions/AnalyticsActions';
import AppActions from '../../actions/AppActions';
import UIActions from '../../actions/UIActions';
import PurchaseActions from '../../actions/PurchaseActions';
import EntitlementActions from '../../actions/EntitlementActions';
import WatchlistActions from '../../actions/WatchlistActions';
import AppStore from '../../stores/AppStore';
import AuthStore from '../../stores/AuthStore';
import Button from '../ui/Button.react';
import MediaActions from '../../actions/MediaActions';

/**
 * MediaItemComponentCTAs
 *
 * @mixin MediaItemComponentCTAs
 */
const MediaItemComponentCTAs = (superclass) => class extends superclass {
    /**
     * Constructor
     */
    constructor(props, context) {
        super(props, context);
        if (!this.props.mediaItem) {
            throw Error('MediaItemComponentCTAs mixin requires mediaItem prop in target class.');
        }
        if (!this.context.router) {
            throw Error('MediaItemComponentCTAs mixin requires router context in target class.');
        }
    }

    /**
     * Toggle watchlist status
     */
    _toggleMediaItemWatchlistStatus = (mediaItem = null) => {
        mediaItem = mediaItem || this.props.mediaItem;

        if (!AuthStore.isSignedIn()) {
            this._openSignInPage();
            return;
        }

        if (mediaItem.watchlistPending) return;

        if (mediaItem.isOnWatchlist && mediaItem.watchlistItem) {
            WatchlistActions.removeMediaItemFromWatchlist({
                mediaItemId: mediaItem.id,
                watchlistItemId: mediaItem.watchlistItem.id
            });
        } else if (mediaItem.offers && mediaItem.offers.length > 0) {
            WatchlistActions.addMediaItemToWatchlist({
                mediaItemId: mediaItem.id,
                mediaItem: mediaItem,
                offerId: mediaItem.offers[0].id
            });
        }
    };

    /**
     * Pre-order mediaItem (adding to watchlist)
     */
    _preOrderMediaItem = () => {
        const mediaItem = this.props.mediaItem;
        if (!AuthStore.isSignedIn()) {
            this._openSignInPage();
            return;
        }

        if (!AuthStore.userProfile.paymentProviderCustomerIds.stripe) {
            UIActions.showCustomOverlay((
                <div className="alert-box-size col-xs-12 col-sm-8 col-md-6">
                    <h2 className="overlay-header">{AppStore.translate('view.cta_action_modal.title.finish_payment_setup')}</h2>
                    <div className="overlay-content">
                        <p>{AppStore.translate('view.cta_action_modal.message.finish_payment_setup')}</p>
                        <div className="buttons-container">
                            <Button
                                type='button'
                                shape="regular"
                                onClick={UIActions.closeCustomOverlay}>{AppStore.translate('button.cancel')}</Button>
                            <Button
                                type='button'
                                className="cta-virgin-purple"
                                shape="regular"
                                onClick={this._closeInfoOverlayAndOpenPaymentPopup}>{AppStore.translate('button.cta.go_to_my_account')}
                            </Button>
                        </div>
                    </div>
                </div>
            ));
            return;
        }
        if (mediaItem.watchlistPending || mediaItem.isOnWatchlist) return;

        if (mediaItem.offers && mediaItem.offers.length > 0) {
            WatchlistActions.addMediaItemToWatchlist({
                mediaItemId: mediaItem.id,
                mediaItem: mediaItem,
                offerId: mediaItem.offers[0].id
            });
        }
    };

    /**
     * Submit user's rating value
     *
     * @private
     */
    _rateMediaItem = (userRating) => {
        const mediaItem = this.props.mediaItem;
        MediaActions.rateMediaItem({
            userRating: userRating,
            mediaItemId: this.props.mediaItem.id,
            mediaItem: mediaItem
        });
    };

    /**
     * Share
     */
    _shareMediaItem = () => {
        const mediaItem = this.props.mediaItem;
        const subject = AppStore.translate('view.share_email.subject', {mediaItem: mediaItem});
        const body = AppStore.translate('view.share_email.body', {
            mediaItem: mediaItem,
            link: Config.BASE_URL + Router.MEDIA_ITEM(this.props.mediaItem)
        });

        AnalyticsActions.trackEvent({
            category: 'social',
            action: 'Share',
            label: mediaItem.title
        });

        window.location = 'mailto:?subject=' + subject + '&body=' + body;
    };

    /**
     * Add mediaItem offer to basket
     */
    _fastCheckoutMediaItemOffer = (offerId) => {
        const mediaItem = this.props.mediaItem;

        // show sign-in modal if user not authenticated
        if (!AuthStore.isSignedIn()) {
            this._openSignInPage();
            return;
        }

        PurchaseActions.fastCheckoutMediaItemOffer({
            offerId: offerId,
            mediaItemId: mediaItem.id
        });
    };

    /**
     * Play media item trailer
     */
    _playMediaItemTrailer = () => {
        const mediaItem = this.props.mediaItem;

        // show native app download option if user on mobile
        const md = new MobileDetect(window.navigator.userAgent);
        if ((md.os() === 'AndroidOS' || md.os() === 'iOS')) {
            const appUrl = md.os() === 'AndroidOS' ? Config.GOOGLE_PLAY_STORE_APP_URL : Config.APPLE_STORE_APP_URL;
            UIActions.showCustomOverlay((
                <div className="alert-box-size col-xs-12 col-sm-8 col-md-6">
                    <h2 className="overlay-header">{AppStore.translate('view.cta_action_modal.title.download_native_app')}</h2>
                    <div className="overlay-content">
                        <p>{AppStore.translate('view.cta_action_modal.message.download_native_app')}</p>
                        <div className="buttons-container">
                            <Button
                                type='button'
                                shape="regular"
                                onClick={UIActions.closeCustomOverlay}>{AppStore.translate('button.cancel')}</Button>
                            <Button
                                type='link'
                                shape="regular"
                                linkTo={appUrl}
                                target="_blank"
                                className="cta-virgin-purple">{AppStore.translate('view.cta_action_modal.message.get_app')}</Button>
                        </div>
                    </div>
                </div>
            ));
            return;
        }

        EntitlementActions.startMediaItemPlayback({
            mediaItem: mediaItem,
            playerAction: 'trailer'
        });
    };

    /**
     * Play media item
     */
    _playMediaItem = () => {
        let mediaItem = this.props.mediaItem;
        let md = new MobileDetect(window.navigator.userAgent);

        // show sign-in modal if user not authenticated
        if (!AuthStore.isSignedIn()) {
            this._openSignInPage();
            return;
        }

        // show native app download option if user on mobile
        if (md.os() === 'AndroidOS' || md.os() === 'iOS') {
            let appUrl = md.os() === 'AndroidOS' ? Config.GOOGLE_PLAY_STORE_APP_URL : Config.APPLE_STORE_APP_URL;
            UIActions.showCustomOverlay((
                <div className="alert-box-size col-xs-12 col-sm-8 col-md-6">
                    <h2 className="overlay-header">{AppStore.translate('view.cta_action_modal.title.download_native_app')}</h2>
                    <div className="overlay-content">
                        <p>{AppStore.translate('view.cta_action_modal.message.download_native_app')}</p>
                        <div className="buttons-container">
                            <Button
                                type='button'
                                shape="regular"
                                onClick={UIActions.closeCustomOverlay}>{AppStore.translate('button.cancel')}</Button>
                            <Button
                                type='link'
                                shape="regular"
                                linkTo={appUrl}
                                target="_blank"
                                className="cta-virgin-purple">{AppStore.translate('view.cta_action_modal.message.get_app')}</Button>
                        </div>
                    </div>
                </div>
            ));
            return;
        }

        // play media item
        EntitlementActions.startMediaItemPlayback({
            mediaItem: mediaItem,
            playerAction: 'play',
            descriptiveMediaTitle: this.props.descriptiveMediaTitle
        });
    };

    /**
     * Open download manager overlay
     */
    _openDownloadManagerOverlay = () => {
        let mediaItem = this.props.mediaItem;
        let md = new MobileDetect(window.navigator.userAgent);
        let client = new ClientJS(); // MobileDetect is not working properly on desktop, features should be
                                     // implemented with just one lib in future

        // show sign-in modal if user not authenticated
        if (!AuthStore.isSignedIn()) {
            this._openSignInPage();
            return;
        }

        // show native app download option if user on mobile
        if (md.os() === 'AndroidOS' || md.os() === 'iOS') {
            let appUrl = md.os() === 'AndroidOS' ? Config.GOOGLE_PLAY_STORE_APP_URL : Config.APPLE_STORE_APP_URL;
            UIActions.showCustomOverlay((
                <div className="alert-box-size col-xs-12 col-sm-8 col-md-6">
                    <h2 className="overlay-header">{AppStore.translate('view.cta_action_modal.title.download_native_app')}</h2>
                    <div className="overlay-content">
                        <p>{AppStore.translate('view.cta_action_modal.message.download_native_app')}</p>
                        <div className="buttons-container">
                            <Button
                                type='button'
                                shape="regular"
                                onClick={UIActions.closeCustomOverlay}>{AppStore.translate('button.cancel')}</Button>
                            <Button
                                type='link'
                                shape="regular"
                                linkTo={appUrl}
                                target="_blank"
                                className="cta-virgin-purple">{AppStore.translate('view.cta_action_modal.message.get_app')}</Button>
                        </div>
                    </div>
                </div>
            ));
            return;
        }

        // show Silverlight support notification if on unsupported browser
        if (client.getBrowser() && client.getBrowser().name !== 'IE' && !(client.getBrowser().name === 'Firefox' && parseInt(client.getBrowser().major, 10) < 52)) {
            AnalyticsActions.trackEvent({
                category: 'Download',
                action: 'error',
                label: this.props.descriptiveMediaTitle || mediaItem.sortTitle
            });

            UIActions.showCustomOverlay((
                <div className="alert-box-size col-xs-12 col-sm-8 col-md-6">
                    <h2 className="overlay-header">{AppStore.translate('view.cta_action_modal.title.browser_unsupported')}</h2>
                    <div className="overlay-content">
                        <p>{AppStore.translate('view.cta_action_modal.message.browser_unsupported')}</p>
                        <div className="buttons-container center-button">
                            <Button
                                type='button'
                                className="cta-virgin-purple"
                                shape="regular"
                                onClick={UIActions.closeCustomOverlay}>{AppStore.translate('button.cancel')}</Button>
                        </div>
                    </div>
                </div>
            ));
            return;
        }

        UIActions.showCustomOverlay((
            <div className="alert-box-size col-xs-12 col-sm-8 col-md-6">
                <h2 className="overlay-header">{AppStore.translate('view.cta_action_modal.title.download_store_app')}</h2>
                <div className="overlay-content">
                    <p>{AppStore.translate('view.cta_action_modal.message.download_store_app')}</p>
                    <div className="buttons-container">
                        <Button
                            type='button'
                            shape="regular"
                            onClick={UIActions.closeCustomOverlay}>{AppStore.translate('button.cancel')}</Button>
                        <Button
                            type='button'
                            className="cta-virgin-purple"
                            shape="regular"
                            onClick={this._downloadMediaItem.bind(null, mediaItem)}>{AppStore.translate('button.cta.download')}</Button>
                    </div>
                </div>
            </div>
        ));

    };

    /**
     * Download mediaItem
     */
    _downloadMediaItem = (mediaItem) => {
        mediaItem = mediaItem || this.props.mediaItem;

        UIActions.closeCustomOverlay();
        EntitlementActions.startMediaItemPlayback({
            mediaItem: mediaItem,
            playerAction: 'download',
            descriptiveMediaTitle: this.props.descriptiveMediaTitle
        });
    };

    /**
     * Open sign in page
     */
    _openSignInPage = (routerTransitionPath) => {
        const mediaItem = this.props.mediaItem;
        AppActions.storeRouterTransitionPath(routerTransitionPath || Router.MEDIA_ITEM(mediaItem));
        this.context.router.push(Router.SIGN_IN);
    };

    /**
     * Navigate to
     *
     * @param link
     * @private
     */
    _navigateTo = (link) => {
        const mediaItem = this.props.mediaItem;
        store.set("purchase_started", "YES");
        store.set("purchase_path", Router.MEDIA_ITEM(mediaItem));
        AppActions.storeRouterTransitionPath(Router.MEDIA_ITEM(mediaItem));
        this.context.router.push(link);
    };

    /**
     * Open set payment popup info
     */
    _openSetPaymentPopup = () => {
        const mediaItem = this.props.mediaItem;
        AppActions.storeRouterTransitionPath(Router.MEDIA_ITEM(mediaItem));
        this.context.router.push(Router.SIGN_IN);
    };

    /**
     * close info overlay and open payment popup
     */
    _closeInfoOverlayAndOpenPaymentPopup = () => {
        UIActions.closeCustomOverlay();
        UIActions.showCustomOverlay({
            overlayId: OverlayIds.PAYMENT_METHOD,
            showCloseButton: false
        });
    };
};

export default MediaItemComponentCTAs;
