"use strict";

import Immutable from 'immutable';
import MediaItem from './MediaItem';
import Offer from './Offer';

/**
 * BasketItemDTO definition
 *
 * @typedef {Object} BasketItemDTO
 * @property {?number} BasketItemId
 * @property {?number} OfferId
 * @property {?string} SupplierRef [removed by api pre-processor]
 * @property {?MediaItemDTO} MediaItemMidiDTO [removed by api pre-processor]
 * @property {?MediaItemDTO} MediaItemDTO [api pre-processor]
 * @property {?string} SupplierReference [api pre-processor]
 */

/**
 * BasketItem definition
 *
 * @typedef {Immutable.Record} BasketItem
 * @property {?number} id
 * @property {?number} offerId
 * @property {?Offer} offer
 * @property {?string} mediaItemId
 * @property {?MediaItem} mediaItem
 * @property {boolean} isInBasket
 * @property {boolean} voucherPending
 * @property {boolean} purchasePending
 * @property {boolean} updatePending
 */

/**
 * BasketItem record
 *
 * @type {BasketItem}
 */
class BasketItem extends Immutable.Record({
    id: null,
    offerId: null,
    offer: null,
    mediaItemId: null,
    mediaItem: null,
    isInBasket: true,
    voucherPending: false,
    purchasePending: false,
    updatePending: false
}) {
    /**
     * toJS shallow
     * converts Record to object by keeping all second level immutable or other complex properties intact
     *
     * @return {Object}
     */
    toJSShallow() {
        return {
            ...this.toJS(),
            offer: this.offer,
            mediaItem: this.mediaItem
        };
    }

    /**
     * Set values from data transfer object
     *
     * @param {BasketItemDTO} dataTransferObject
     * @return {BasketItem}
     */
    fromDTO(dataTransferObject) {
        return new BasketItem({
            ...this.toJS(),
            id: typeof dataTransferObject.BasketItemId !== 'undefined' ? dataTransferObject.BasketItemId : this.id,
            offerId: typeof dataTransferObject.OfferId !== 'undefined' ? dataTransferObject.OfferId : this.offerId,
            offer: typeof dataTransferObject.MediaItemDTO !== 'undefined' && typeof dataTransferObject.OfferId !== 'undefined'
                ? _getOfferFromDTO(dataTransferObject.MediaItemDTO, dataTransferObject.OfferId) : this.offer,
            mediaItemId: typeof dataTransferObject.SupplierReference !== 'undefined' ? dataTransferObject.SupplierReference : this.mediaItemId,
            mediaItem: typeof dataTransferObject.MediaItemDTO !== 'undefined' ? new MediaItem({}).fromDTO(dataTransferObject.MediaItemDTO) : this.mediaItem
        });
    }

    /**
     * Set voucher pending
     *
     * @param {boolean} voucherPending
     * @return {BasketItem}
     */
    setVoucherPending(voucherPending) {
        return this.voucherPending !== voucherPending ? new BasketItem({
            ...this.toJSShallow(),
            voucherPending: voucherPending
        }) : this;
    }

    /**
     * Set purchase pending
     *
     * @param {boolean} purchasePending
     * @return {BasketItem}
     */
    setPurchasePending(purchasePending) {
        return this.purchasePending !== purchasePending ? new BasketItem({
            ...this.toJSShallow(),
            purchasePending: purchasePending
        }) : this;
    }

    /**
     * Set update pending
     *
     * @param {boolean} updatePending
     * @return {BasketItem}
     */
    setUpdatePending(updatePending) {
        return this.updatePending !== updatePending ? new BasketItem({
            ...this.toJSShallow(),
            updatePending: updatePending
        }) : this;
    }
}

/**
 * Get offers from data transfer object
 *
 * @param {MediaItemDTO} mediaItemDTO
 * @param {number} offerId
 * @returns {Array<Offer>}
 * @private
 */
function _getOfferFromDTO(mediaItemDTO, offerId) {
    var offerDTO = mediaItemDTO ? mediaItemDTO.Offers.find((offerDTO) => {
        return offerDTO.Id === offerId;
    }) : null;

    return offerDTO ? new Offer({}).fromDTO(offerDTO) : null;
}

export default BasketItem;
