"use strict";

/**
 * MediaBreakpoint type
 *
 * @typedef {?number} MediaBreakpoint
 */

/**
 * Media query builder
 */
class MediaQueryBuilder {
    /**
     * Breakpoints
     */
    BREAKPOINT_XS = 360;
    BREAKPOINT_SM = 576;
    BREAKPOINT_MD = 768;
    BREAKPOINT_LG = 992;
    BREAKPOINT_XL = 1200;

    GUTTER_XS = 10;
    GUTTER_SM = 10;
    GUTTER_MD = 30;
    GUTTER_LG = 30;
    GUTTER_XL = 30;

    /**
     * Return MediaQueryList object
     *
     * @param {string|MediaBreakpoint} breakpointFrom
     * @param {MediaBreakpoint} breakpointTo
     * @return {boolean}
     */
    getMediaQueryList = (breakpointFrom, breakpointTo = null) => {
        var mediaQueryList = window.matchMedia(`foo`);

        if (typeof breakpointFrom === 'string') {
            mediaQueryList = window.matchMedia(breakpointFrom);

        } else if (breakpointFrom !== null && breakpointTo !== null) {
            mediaQueryList = window.matchMedia(`only screen and (min-width: ${breakpointFrom}px) and (max-width: ${breakpointTo}px)`);

        } else if (breakpointFrom !== null) {
            mediaQueryList = window.matchMedia(`only screen and (min-width: ${breakpointFrom}px)`);

        } else if (breakpointTo !== null) {
            mediaQueryList = window.matchMedia(`only screen and (max-width: ${breakpointTo}px)`);
        }

        return mediaQueryList;
    };

    /**
     * Matches by query or breakpoints
     *
     * @param {string|MediaBreakpoint|null} breakpointFrom
     * @param {MediaBreakpoint} breakpointTo
     * @return {boolean}
     */
    match = (breakpointFrom, breakpointTo = null) => {
        return this.getMediaQueryList(breakpointFrom, breakpointTo).matches;
    };

    /**
     * Returns gutter matching current media size
     *
     * @return {string}
     */
    getMediaSize = () => {
        var mediaSize = 'XL';
        var prevKey = null;
        Object.keys(this).forEach((key) => {
            if(key.indexOf('BREAKPOINT_') > -1) {
                if (this.match(this[prevKey], this[key])) mediaSize = key.replace('BREAKPOINT_', '');
                prevKey = key;
            }
        });
        return mediaSize;
    };

    /**
     * Returns gutter matching current media size
     *
     * @return {number}
     */
    getGutter = () => {
        return this['GUTTER_' + this.getMediaSize()];
    };
}

var MediaQuery = new MediaQueryBuilder();

export {MediaQuery as default, MediaQuery, MediaQueryBuilder};
