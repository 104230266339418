"use strict";

import Immutable from 'immutable';
import MediaItem, {MediaItemClassification} from './MediaItem';

/**
 * MediaBucket definition
 *
 * @typedef {Immutable.Record} MediaBucket
 * @property {string} key
 * @property {?number} mediaTotalCount
 * @property {Array<string>} primaryMediaItemIds
 * @property {Immutable.OrderedMap<string, MediaItem>} bulkMedia
 * @property {boolean} updatePending
 */

/**
 * MediaBucket record
 *
 * @type {MediaBucket}
 */
class MediaBucket extends Immutable.Record({
    key: null,
    mediaTotalCount: null,
    primaryMediaItemIds: [],
    bulkMedia: Immutable.OrderedMap(),
    updatePending: false
}) {
    /**
     * toJS shallow
     * converts Record to object by keeping all second level immutable or other complex properties intact
     *
     * @return {Object}
     */
    toJSShallow() {
        return {
            ...this.toJS(),
            bulkMedia: this.bulkMedia
        };
    }

    /**
     * Set update pending
     *
     * @param {boolean} updatePending
     * @return {MediaBucket}
     */
    setUpdatePending(updatePending) {
        return this.updatePending !== updatePending ? new MediaBucket({
            ...this.toJSShallow(),
            updatePending: updatePending
        }) : this;
    }

    /**
     * Set media total count
     *
     * @param {?number} mediaTotalCount
     * @return {MediaBucket}
     */
    setMediaTotalCount(mediaTotalCount) {
        return new MediaBucket({
            ...this.toJSShallow(),
            mediaTotalCount: mediaTotalCount
        });
    }

    /**
     * Fill with mediaItems
     *
     * @param {Array<MediaItemDTO>} mediaItemDTOList
     * @param {Array<string>} primaryMediaItemIds
     * @return {MediaBucket}
     */
    fill(mediaItemDTOList, primaryMediaItemIds) {
        var bulkMedia = Immutable.OrderedMap();

        /** @type {MediaItemDTO} mediaItemDTO */
        mediaItemDTOList.forEach((mediaItemDTO) => {
            let mediaItem = new MediaItem({}).fromDTO(mediaItemDTO);
            if (mediaItem.id) {
                mediaItem = mediaItem.setBucketKey(this.key);
                bulkMedia = bulkMedia.set(mediaItem.id, mediaItem);
            }
        });

        // sort by primaryMediaItemIds array position (preserves order given to getMedia API call)
        bulkMedia = bulkMedia.sort((firstMediaItem, secondMediaItem) => {
            let firstMediaItemPosition = primaryMediaItemIds.indexOf(firstMediaItem.id);
            let secondMediaItemPosition = primaryMediaItemIds.indexOf(secondMediaItem.id);

            if (firstMediaItemPosition > secondMediaItemPosition) {
                return 1;
            } else if (firstMediaItemPosition < secondMediaItemPosition) {
                return -1;
            } else {
                return 0;
            }
        });

        return new MediaBucket({
            ...this.toJSShallow(),
            primaryMediaItemIds: this.primaryMediaItemIds.concat(primaryMediaItemIds),
            bulkMedia: this.bulkMedia.merge(bulkMedia),
            updatePending: false
        });
    }

    /**
     * Set mediaItem watchlist state
     *
     * @param {MediaItem} mediaItem
     * @param {Immutable.Map<string, WatchlistItem>} watchlist
     * @return {MediaBucket}
     */
    setMediaItemWatchlistState(mediaItem, watchlist) {
        let bulkMedia = this.bulkMedia.toOrderedMap();
        mediaItem = this.bulkMedia.get(mediaItem.id);

        mediaItem = mediaItem.setWatchlistState(watchlist);
        bulkMedia = bulkMedia.set(mediaItem.id, mediaItem);

        return new MediaBucket({
            ...this.toJSShallow(),
            bulkMedia: bulkMedia
        });
    }

    /**
     * Set media watchlist states
     *
     * @param {Immutable.Map<string, WatchlistItem>} watchlist
     * @return {MediaBucket}
     */
    setMediaWatchlistStates(watchlist) {
        var bulkMedia = Immutable.OrderedMap();

        this.bulkMedia.forEach((mediaItem) => {
            mediaItem = mediaItem.setWatchlistState(watchlist);
            bulkMedia = bulkMedia.set(mediaItem.id, mediaItem);
        });

        return new MediaBucket({
            ...this.toJSShallow(),
            bulkMedia: bulkMedia
        });
    }

    /**
     * Set mediaItem offers bucket state
     *
     * @param {MediaItem} mediaItem
     * @param {Immutable.Map<number, BasketItem>} basket
     * @return {MediaBucket}
     */
    setMediaItemOffersBasketState(mediaItem, basket) {
        let bulkMedia = this.bulkMedia.toOrderedMap();
        mediaItem = this.bulkMedia.get(mediaItem.id);

        mediaItem = mediaItem.setOffersBasketState(basket);
        bulkMedia = bulkMedia.set(mediaItem.id, mediaItem);

        return new MediaBucket({
            ...this.toJSShallow(),
            bulkMedia: bulkMedia
        });
    }

    /**
     * Set media offers basket states
     *
     * @param {Immutable.Map<number, BasketItem>} basket
     * @return {MediaBucket}
     */
    setMediaOffersBasketStates(basket) {
        var bulkMedia = Immutable.OrderedMap();

        this.bulkMedia.forEach((mediaItem) => {
            mediaItem = mediaItem.setOffersBasketState(basket);
            bulkMedia = bulkMedia.set(mediaItem.id, mediaItem);
        });

        return new MediaBucket({
            ...this.toJSShallow(),
            bulkMedia: bulkMedia
        });
    }

    /**
     * Set mediaItem entitlement state
     *
     * @param {MediaItem} mediaItem
     * @param {Immutable.Map<string, Entitlement>} currentEntitlementsByMediaItem
     * @return {MediaBucket}
     */
    setMediaItemEntitlementState(mediaItem, currentEntitlementsByMediaItem) {
        let bulkMedia = this.bulkMedia.toOrderedMap();
        mediaItem = this.bulkMedia.get(mediaItem.id);

        mediaItem = mediaItem.setEntitlementState(currentEntitlementsByMediaItem);
        bulkMedia = bulkMedia.set(mediaItem.id, mediaItem);

        // set bucket mediaItem[MediaItemClassification.SEASON] descendants possible inherited state
        if ((mediaItem.classification === MediaItemClassification.SEASON
                || mediaItem.classification === MediaItemClassification.TV_BOX_SET
                || mediaItem.classification === MediaItemClassification.MOVIE_BOX_SET) && currentEntitlementsByMediaItem.get(mediaItem.id)) {
            const tempMediaBucket = new MediaBucket({
                ...this.toJSShallow(),
                bulkMedia: bulkMedia
            });

            tempMediaBucket.mediaItemDescendants(mediaItem).forEach((mediaItemDescendant) => {
                mediaItemDescendant = mediaItemDescendant.setEntitlementState(currentEntitlementsByMediaItem, mediaItem);
                bulkMedia = bulkMedia.set(mediaItemDescendant.id, mediaItemDescendant);
            });
        }

        // set possible hoisted state for parent of mediaItem[MediaItemClassification.EPISODE]
        if (mediaItem.classification === MediaItemClassification.EPISODE) {
            const tempMediaBucket = new MediaBucket({
                ...this.toJSShallow(),
                bulkMedia: bulkMedia
            });

            const parentMediaItem = tempMediaBucket.mediaItemParent(mediaItem);
            const isIndirectlyPurchased = tempMediaBucket.mediaItemChildren(parentMediaItem)
                .reduce(
                    (isIndirectlyPurchased, childMediaItem) => isIndirectlyPurchased && childMediaItem.isPurchased,
                    true
                );
            if (isIndirectlyPurchased) {
                bulkMedia = bulkMedia.set(parentMediaItem.id, parentMediaItem.merge({
                    isPurchased: true,
                    playbackAvailable: true
                }));
            }
        }

        return new MediaBucket({
            ...this.toJSShallow(),
            bulkMedia: bulkMedia
        });
    }

    /**
     * Set media entitlement states
     *
     * @param {Immutable.Map<string, Entitlement>} currentEntitlementsByMediaItem
     * @return {MediaBucket}
     */
    setMediaEntitlementStates(currentEntitlementsByMediaItem) {
        let bulkMedia = Immutable.OrderedMap();

        this.bulkMedia.forEach((mediaItem) => {
            mediaItem = mediaItem.setEntitlementState(
                currentEntitlementsByMediaItem,
                // set possible inherited state for mediaItem[MediaItemClassification.EPISODE]
                mediaItem.classification === MediaItemClassification.EPISODE && this.mediaItemParent(mediaItem)
                && currentEntitlementsByMediaItem.get(this.mediaItemParent(mediaItem).id)
                    ? this.mediaItemParent(mediaItem) : null
            );
            bulkMedia = bulkMedia.set(mediaItem.id, mediaItem);
        });

        // set possible hoisted state for mediaItem[MediaItemClassification.SEASON]
        bulkMedia.forEach((mediaItem) => {
            if (mediaItem.classification === MediaItemClassification.SEASON) {
                const tempMediaBucket = new MediaBucket({
                    ...this.toJSShallow(),
                    bulkMedia: bulkMedia
                });

                const isIndirectlyPurchased = tempMediaBucket.mediaItemChildren(mediaItem)
                    .reduce(
                        (isIndirectlyPurchased, childMediaItem) => isIndirectlyPurchased && childMediaItem.isPurchased,
                        true
                    );
                if (isIndirectlyPurchased) {
                    bulkMedia = bulkMedia.set(mediaItem.id, mediaItem.merge({
                        isPurchased: true,
                        playbackAvailable: true
                    }));
                }
            }
        });

        return new MediaBucket({
            ...this.toJSShallow(),
            bulkMedia: bulkMedia
        });
    }

    /**
     * Set mediaItem recently viewed state
     *
     * @param {MediaItem} mediaItem
     * @param {Immutable.Map<string, RecentlyViewedItem>} recentlyViewedItems
     * @return {MediaBucket}
     */
    setMediaItemRecentlyViewedState(mediaItem, recentlyViewedItems) {
        let bulkMedia = this.bulkMedia.toOrderedMap();
        mediaItem = this.bulkMedia.get(mediaItem.id);

        mediaItem = mediaItem.setRecentlyViewedState(recentlyViewedItems);
        bulkMedia = bulkMedia.set(mediaItem.id, mediaItem);

        return new MediaBucket({
            ...this.toJSShallow(),
            bulkMedia: bulkMedia
        });
    }

    /**
     * Set media recently viewed states
     *
     * @param {Immutable.Map<string, RecentlyViewedItem>} recentlyViewedItems
     * @return {MediaBucket}
     */
    setMediaRecentlyViewedStates(recentlyViewedItems) {
        var bulkMedia = Immutable.OrderedMap();

        this.bulkMedia.forEach((mediaItem) => {
            mediaItem = mediaItem.setRecentlyViewedState(recentlyViewedItems);
            bulkMedia = bulkMedia.set(mediaItem.id, mediaItem);
        });

        return new MediaBucket({
            ...this.toJSShallow(),
            bulkMedia: bulkMedia
        });
    }

    /**
     * Set mediaItem rating pending state
     *
     * @param {MediaItem} mediaItem
     * @param {boolean} ratingPending
     * @param {?number} userRating
     * @param {?number} rating
     * @return {MediaBucket}
     */
    setMediaItemRatingState(mediaItem, ratingPending, userRating = null, rating = null) {
        let bulkMedia = this.bulkMedia.toOrderedMap();
        mediaItem = this.bulkMedia.get(mediaItem.id);

        mediaItem = mediaItem.setRatingState(ratingPending, userRating, rating);
        bulkMedia = bulkMedia.set(mediaItem.id, mediaItem);

        return new MediaBucket({
            ...this.toJSShallow(),
            bulkMedia: bulkMedia
        });
    }

    /**
     * Returns primary media from bucket
     *
     * @returns {Immutable.OrderedMap<string, MediaItem>}
     */
    media() {
        return this.bulkMedia ? this.bulkMedia.filter((mediaItem) => {
            return this.primaryMediaItemIds.indexOf(mediaItem.id) > -1;
        }) : null;
    }

    /**
     * Returns mediaItem from bucket
     *
     * @param {?string} mediaItemId
     * @returns {MediaItem}
     */
    mediaItem(mediaItemId) {
        return this.bulkMedia.get(mediaItemId);
    }

    /**
     * Get mediaItem parent from bucket
     *
     * @param {MediaItem} mediaItem
     * @returns {MediaItem}
     */
    mediaItemParent(mediaItem) {
        return this.mediaItem(mediaItem.parentId);
    }

    /**
     * Get mediaItem root parent from bucket
     *
     * @param {MediaItem} mediaItem
     * @returns {MediaItem}
     */
    mediaItemRootParent(mediaItem) {
        var parentMediaItem = this.mediaItemParent(mediaItem);
        return parentMediaItem ? this.mediaItemRootParent(parentMediaItem) : mediaItem;
    }

    /**
     * Get mediaItem season parent from bucket
     *
     * @param {MediaItem} mediaItem
     * @returns {MediaItem}
     */
    mediaItemEpisodeParent(mediaItem) {
        var parentMediaItem = this.mediaItemParent(mediaItem);
        return parentMediaItem && parentMediaItem.classification !== MediaItemClassification.SEASON ? this.mediaItemEpisodeParent(parentMediaItem) : parentMediaItem;
    }

    /**
     * Get mediaItem children from bucket
     *
     * @param {MediaItem} mediaItem
     * @returns {Immutable.OrderedMap<MediaItem>}
     */
    mediaItemChildren(mediaItem) {
        var mediaItemChildren = Immutable.OrderedMap();
        mediaItem.childrenIds.map((mediaItemId) => {
            return this.mediaItem(mediaItemId);
        }).forEach((mediaItemChild) => {
            if (mediaItemChild) {
                mediaItemChildren = mediaItemChildren.set(mediaItemChild.id, mediaItemChild);
            }
        });

        return mediaItemChildren;
    }

    /**
     * Get mediaItem descendants from bucket
     *
     * @param {MediaItem} mediaItem
     * @param {?Immutable.OrderedMap<MediaItem>} mediaItemDescendants
     * @returns {Immutable.OrderedMap<MediaItem>}
     */
    mediaItemDescendants(mediaItem, mediaItemDescendants = Immutable.OrderedMap()) {
        mediaItem.childrenIds.map((mediaItemId) => {
            return this.mediaItem(mediaItemId);
        }).forEach((mediaItemChild) => {
            if (mediaItemChild) {
                mediaItemDescendants = mediaItemDescendants.set(mediaItemChild.id, mediaItemChild);
                mediaItemDescendants = this.mediaItemDescendants(mediaItemChild, mediaItemDescendants);
            }
        });

        return mediaItemDescendants;
    }
}

export default MediaBucket;
