"use strict";

import * as AppDispatcher from '../dispatchers/AppDispatcher';
import ActionTypes from './ActionTypes';

/**
 * Flux: NotificationActions
 */
export default {
    // Display notification
    displayNotification: (params) => {
        AppDispatcher.dispatch(ActionTypes.DISPLAY_NOTIFICATION, params);
    },

    // Hide notification
    hideNotification: () => {
        AppDispatcher.dispatch(ActionTypes.HIDE_NOTIFICATION, {});
    }
};
