import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'svgxuse'; // fixes IE10/11 issue when using external SVG
import React from 'react';
import ReactDOM from 'react-dom';
import './scss/style.scss';
import './scss/global/_fonts.scss';
// import 'babel-polyfill';
import 'number-to-locale-string';
import {Router, getSiteMap} from './js/lib/Router';

/**
 * ReactDOM: render app
 */
ReactDOM.render(getSiteMap(Router), document.getElementById('app'));
