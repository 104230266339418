"use strict";

import * as Request from './Request';
import ApiEndpoints from './ApiEndpoints';

/**
 * Log event
 *
 * @param {Object} params
 * @return {*}
 */
export function logEvent(params = {}) {
    return Request.getRequestPromise({
        url: ApiEndpoints.LOG_EVENT,
        method: 'POST',
        qs: {
            v: 3
        },
        body: {
            EventName: params.eventName,
            LoggingProperties: typeof params.loggingProperties !== 'undefined'
                ? params.loggingProperties.map(loggingProperty => {
                    return {
                        Name: loggingProperty.name,
                        Value: loggingProperty.value
                    };
                }) : [],
            LoggingMetrics: typeof params.loggingMetrics !== 'undefined'
                ? params.loggingMetrics.map(loggingMetric => {
                    return {
                        Name: loggingMetric.name,
                        Value: loggingMetric.value
                    };
                }) : []
        }
    });
}

/**
 * Log exception
 *
 * @param {Object} params
 * @return {*}
 */
export function logException(params = {}) {
    return Request.getRequestPromise({
        url: ApiEndpoints.LOG_EXCEPTION,
        method: 'POST',
        qs: {
            v: 3
        },
        body: {
            Message: params.message,
            LoggingProperties: typeof params.loggingProperties !== 'undefined'
                ? params.loggingProperties.map(loggingProperty => {
                    return {
                        Name: loggingProperty.name,
                        Value: loggingProperty.value
                    };
                }) : [],
            LoggingMetrics: typeof params.loggingMetrics !== 'undefined'
                ? params.loggingMetrics.map(loggingMetric => {
                    return {
                        Name: loggingMetric.name,
                        Value: loggingMetric.value
                    };
                }) : []
        }
    });
}

/**
 * Log page view
 *
 * @param {Object} params
 * @return {*}
 */
export function logPageView(params = {}) {
    return Request.getRequestPromise({
        url: ApiEndpoints.LOG_PAGE_VIEW,
        method: 'POST',
        qs: {
            v: 3
        },
        body: {
            PageName: params.pageName,
            UserId: params.userId || 0,
            DeviceId: params.deviceId || "04d2b6ed-bdde-eee5-24da-2ee64d1dc62c",
            AffiliateId: params.affiliateId,
            SessionId: params.sessionId || "03481877-2a30-4412-85bc-5243f4c6811a"
        }
    });
}

/**
 * Log custom event
 *
 * @param {Object} params
 * @return {*}
 */
export function logCustomEvent(params = {}) {
    return Request.getRequestPromise({
        url: ApiEndpoints[params.customEventType],
        method: 'POST',
        qs: {
            v: 3
        },
        body: params.payload
    });
}
