"use strict";

import Immutable from 'immutable';

/**
 * WatchlistItemDTO definition
 *
 * @typedef {Object} WatchlistItemDTO
 * @property {?number} BasketItemId - Internal Basket Item Id
 * @property {?number} OfferId - The offer Id
 * @property {?string} SupplierRef - Media Item Supplier Reference [removed by api pre-processor]
 * @property {?MediaItemDTO} MediaItemMidiDTO - The midi sized media item [removed by api pre-processor]
 * @property {?string} SupplierReference [api pre-processor]
 */

/**
 * WatchlistItem definition
 *
 * @typedef {Immutable.Record} WatchlistItem
 * @property {?number} id
 * @property {?number} offerId
 * @property {?string} mediaItemId
 * @property {boolean} isOnWatchlist
 * @property {boolean} updatePending
 */

/**
 * WatchlistItem record
 *
 * @type {WatchlistItem}
 */
class WatchlistItem extends Immutable.Record({
    id: null,
    offerId: null,
    mediaItemId: null,
    isOnWatchlist: true,
    updatePending: false
}) {
    /**
     * toJS shallow
     * converts Record to object by keeping all second level immutable or other complex properties intact
     *
     * @return {Object}
     */
    toJSShallow() {
        return {
            ...this.toJS()
        };
    }

    /**
     * Set values from data transfer object
     *
     * @param {WatchlistItemDTO} dataTransferObject
     * @return {Cast}
     */
    fromDTO(dataTransferObject) {
        return new WatchlistItem({
            ...this.toJSShallow(),
            id: typeof dataTransferObject.BasketItemId !== 'undefined' ? dataTransferObject.BasketItemId : this.id,
            offerId: typeof dataTransferObject.OfferId !== 'undefined' ? dataTransferObject.OfferId : this.offerId,
            mediaItemId: typeof dataTransferObject.SupplierReference !== 'undefined' ? dataTransferObject.SupplierReference : this.mediaItemId
        });
    }

    /**
     * Set update pending
     *
     * @param {boolean} updatePending
     * @return {WatchlistItem}
     */
    setUpdatePending(updatePending) {
        return this.updatePending !== updatePending ? new WatchlistItem({
            ...this.toJSShallow(),
            updatePending: updatePending
        }) : this;
    }
}

export default WatchlistItem;
