"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import shallowCompare from 'react-addons-shallow-compare';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {debounce} from '../../utils/Common';
import MediaQuery from '../../utils/MediaQuery';
import Button from '../ui/Button.react';
import Pagination from '../ui/Pagination.react';
import CollectionTile from './CollectionTile.react';
import Collection from '../../types/Collection';

/**
 * CollectionCarouselTally component
 */
export default class CollectionCarouselTally extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        title: PropTypes.string,
        linkTo: PropTypes.string,
        collectionList: ImmutablePropTypes.mapOf(PropTypes.instanceOf(Collection)),
        collectionListLoading: PropTypes.bool,
        tileType: PropTypes.oneOf(['poster', 'poster-bubble', 'backdrop', 'backdrop-progress-bar', 'backdrop-detailed']),
        collapsibleTiles: PropTypes.bool,
        navigationType: PropTypes.oneOf(['none', 'side-arrows', 'top-arrows', 'side-arrows-in-elipse']),
        gesturesEnabled: PropTypes.bool,
        highlighted: PropTypes.bool,
        className: PropTypes.string
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        title: '',
        linkTo: null,
        collectionList: null,
        collectionListLoading: false,
        tileType: 'poster-bubble',
        collapsibleTiles: false,
        navigationType: 'none',
        gesturesEnabled: false,
        highlighted: false
    };

    /**
     * React: state
     */
    state = {
        slideTrayOffset: 0,
        navigationLeftDisabled: true,
        navigationRightDisabled: true,
        slideCount: 0,
        activeSlide: 0,
        shouldTileBubbleBeVisible: false,
        tileBubbleTileNode: null
    };

    /**
     * Constructor
     */
    constructor(props, context) {
        super(props, context);
        this._recalculateActiveSlide = debounce(this._recalculateActiveSlide, 350);
    }

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        window.addEventListener("resize", this._recalculateActiveSlide);
        this._setCarouselState();
    }

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: componentDidUpdate
     */
    componentDidUpdate(prevProps) {
        if (prevProps.collectionList !== this.props.collectionList) {
            this._setCarouselState();
        }
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this._recalculateActiveSlide);
    }

    /**
     * React: render
     */
    render() {
        var collections = [];

        const keys = this.props.collectionList.keySeq().toArray();
        if (this.props.collectionList && this.props.collectionList.size > 0) {
            //this.props.collectionList.forEach((collection, key) => {
            for (let index = 0; index <= this._calculateItemsInCarousel(this.props.collectionList.size); index++) {
                collections.push(
                    <CollectionTile key={index}
                                        collection={this.props.collectionList.get(keys[index])}
                                        tileType={this.props.tileType}
                                        collapsible={this.props.collapsibleTiles} />
                );
            }

            // show loading tile set instead
        } else {
            for (let index = 0; index <= 5; index++) {
                collections.push(
                    <CollectionTile key={index}
                                        tileType={this.props.tileType}
                                        useImageLoadingIndicator={true}
                                        collapsible={this.props.collapsibleTiles} />
                );
            }
        }

        if (this.props.collectionListLoading && this.props.collectionList && this.props.collectionList.size > 0) {
            collections.push(
                <CollectionTile key="loading"
                                    tileType={this.props.tileType}
                                    useImageLoadingIndicator={true}
                                    collapsible={this.props.collapsibleTiles} />
            );
        }

        var className = 'media-carousel'
            + (this.props.collapsibleTiles ? ' collapsible-tiles' : '')
            + (this.props.highlighted ? ' highlighted' : '')
            + (this.props.className ? ' ' + this.props.className : '');

        return collections.length === 0 ? null : (
            <div className={className}>
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 header">
                            <h2><Link to={this.props.linkTo}>{this.props.title || null}</Link></h2>
                            {this.props.navigationType === 'top-arrows' && this.state.slideCount > 1 ? (
                                <Pagination className="navigation-top-arrows"
                                            pageCount={this.state.slideCount}
                                            activePage={this.state.activeSlide}
                                            navigationLeftDisabled={this.state.navigationLeftDisabled}
                                            navigationRightDisabled={this.state.navigationRightDisabled}
                                            onNavigateLeft={this._changeSlide.bind(null, -1)}
                                            onNavigateRight={this._changeSlide.bind(null, 1)}
                                            onPageSelect={this._goToSlide} />
                            ) : null}
                            {this.props.linkTo ? (
                                <Link to={this.props.linkTo}><span className="more tally">
                                    ({this.props.collectionList.size.toString()} {this.props.collectionList.size > 1 ? 'collections' : 'collection'})
                                </span></Link>
                            ) : null}
                        </div>
                    </div>

                    <div className="row">
                        <div className="viewing-frame" ref="viewingFrame">
                            <div className="slide-tray"
                                 style={{left: this.state.slideTrayOffset + 'px'}}
                                 ref="slideTray">
                                {collections}
                            </div>

                            {this.props.navigationType === 'side-arrows' && this.state.slideCount > 1 ? (
                                <div className="navigation-side-arrows">
                                    <Button shape="arrow-left-large"
                                            disabled={this.state.navigationLeftDisabled}
                                            onClick={this._changeSlide.bind(null, -1)} />
                                    <Button shape="arrow-right-large"
                                            disabled={this.state.navigationRightDisabled}
                                            onClick={this._changeSlide.bind(null, 1)} />
                                </div>
                            ) : null}

                            {this.props.navigationType === 'side-arrows-in-elipse' && this.state.slideCount > 1 ? (
                                <div className="navigation-side-arrows">
                                    <Button shape="icon-elipse-large-left"
                                            disabled={this.state.navigationLeftDisabled}
                                            onClick={this._changeSlide.bind(null, -1)}><i
                                        className="ui-icon icon-vrg-arrow-left" /></Button>
                                    <div
                                        className={`gradient-background gradient-background-left${this.state.navigationLeftDisabled ? ' hidden' : ''}`} />
                                    <Button shape="icon-elipse-large-right"
                                            disabled={this.state.navigationRightDisabled}
                                            onClick={this._changeSlide.bind(null, 1)}><i
                                        className="ui-icon icon-vrg-arrow-right" /></Button>
                                    <div
                                        className={`gradient-background gradient-background-right${this.state.navigationRightDisabled ? ' hidden' : ''}`} />
                                </div>
                            ) : null}

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Change slide
     *
     * @param {number} direction
     * @private
     */
    _changeSlide = (direction) => {
        var tileWidth = this.refs.viewingFrame.getElementsByClassName('media-item-tile')[0].getBoundingClientRect().width;
        var viewingFrameWidth = this.refs.viewingFrame.getBoundingClientRect().width;
        var newSlideTrayOffset = this.state.slideTrayOffset
            + -1 * direction * tileWidth * (
                ((direction === 1 && this.state.activeSlide === this.state.slideCount - 2)
                    || (direction === -1 &&  this.state.activeSlide === this.state.slideCount - 1))
                && this.props.collectionList.size % this._numberOfWholeTilesIn(viewingFrameWidth) !== 0
                    ? this.props.collectionList.size % this._numberOfWholeTilesIn(viewingFrameWidth)
                    : this._numberOfWholeTilesIn(viewingFrameWidth)
            );
        //firefox fix: check if newSlideTrayOffset > 0 and reset to 0 for first slide
        this._setCarouselState(newSlideTrayOffset > 0 ? 0 : newSlideTrayOffset);
    };

    /**
     * Go to slide
     *
     * @param {number} index
     * @private
     */
    _goToSlide = (index) => {
        var viewingFrameWidth = this.refs.viewingFrame.getBoundingClientRect().width;
        var tileWidth = this.refs.viewingFrame.getElementsByClassName('media-item-tile')[0].getBoundingClientRect().width;

        this._setCarouselState(-1 * index * this._numberOfWholeTilesIn(viewingFrameWidth) * tileWidth);
    };

    /**
     * Recalculate active slide
     *
     * @private
     */
    _recalculateActiveSlide = () => {
        if (this.props.collapsibleTiles && MediaQuery.match(MediaQuery.BREAKPOINT_LG)) {
            var tileWidth = this.refs.viewingFrame.getElementsByClassName('media-item-tile')[0].getBoundingClientRect().width;
            var viewingFrameWidth = this.refs.viewingFrame.getBoundingClientRect().width;
            var slideTrayWidth = this.refs.slideTray.scrollWidth;

            var newSlideTrayOffset = -1 * this._numberOfSlidesIn(this.state.slideTrayOffset) * this._numberOfWholeTilesIn(viewingFrameWidth) * tileWidth;
            newSlideTrayOffset = Math.abs(newSlideTrayOffset) < slideTrayWidth - this._numberOfWholeTilesIn(viewingFrameWidth) * tileWidth
                ? newSlideTrayOffset
                : newSlideTrayOffset + this._numberOfWholeTilesIn(viewingFrameWidth) * tileWidth;

            this._setCarouselState(newSlideTrayOffset);
        } else {
            this._setCarouselState(0);
        }
    };

    /**
     * Set carousel state
     *
     * @param {?number} newSlideTrayOffset
     * @private
     */
    _setCarouselState = (newSlideTrayOffset = null) => {
        if (!this.props.collectionList || (this.props.collectionList && this.props.collectionList.size === 0)) return;

        var viewingFrameWidth = this.refs.viewingFrame.getBoundingClientRect().width;
        var slideTrayWidth = this.refs.slideTray.scrollWidth;
        newSlideTrayOffset = typeof newSlideTrayOffset !== 'undefined' && newSlideTrayOffset !== null && newSlideTrayOffset <= 0
            ? newSlideTrayOffset : this.state.slideTrayOffset;

        this.setState({
            slideTrayOffset: newSlideTrayOffset,
            slideCount: this._numberOfSlidesIn(slideTrayWidth),
            activeSlide: this._numberOfSlidesIn(newSlideTrayOffset),
            navigationLeftDisabled: this._numberOfTilesIn(newSlideTrayOffset) === 0,
            navigationRightDisabled: this._numberOfTilesIn(newSlideTrayOffset) + this._numberOfWholeTilesIn(viewingFrameWidth) >= this._numberOfTilesIn(slideTrayWidth)
        });
    };

    /**
     * Returns rounded number of tiles in target width
     *
     * @param {number} targetWidth
     * @param {number} tileWidthPercent
     * @return {number}
     * @private
     */
    _numberOfTilesIn = (targetWidth, tileWidthPercent = 50) => {
        var tileWidth = this.refs.viewingFrame.getElementsByClassName('media-item-tile')[0].getBoundingClientRect().width;

        return Math.abs(
            targetWidth % tileWidth >= tileWidth * tileWidthPercent / 100
                ? Math.ceil(targetWidth / tileWidth)
                : Math.floor(targetWidth / tileWidth)
        );
    };

    /**
     * Returns number of whole tiles in target width
     * tile is whole if it is larger than tileWidthPercent
     *
     * @param {number} targetWidth
     * @param {number} tileWidthPercent
     * @return {number}
     * @private
     */
    _numberOfWholeTilesIn = (targetWidth, tileWidthPercent = 80) => {
        return this._numberOfTilesIn(targetWidth, tileWidthPercent);
    };

    /**
     * Returns rounded number of slides in target width
     *
     * @param {number} targetWidth
     * @param {number} slideWidthPercent
     * @return {number}
     * @private
     */
    _numberOfSlidesIn = (targetWidth, slideWidthPercent = 10) => {
        var viewingFrameWidth = this.refs.viewingFrame.getBoundingClientRect().width;
        var tileWidth = this.refs.viewingFrame.getElementsByClassName('media-item-tile')[0].getBoundingClientRect().width;
        var slideWidth = this._numberOfWholeTilesIn(viewingFrameWidth) * tileWidth;

        return Math.abs(
            targetWidth % slideWidth >= slideWidth * slideWidthPercent / 100
                ? Math.ceil(targetWidth / slideWidth)
                : Math.floor(targetWidth / slideWidth)
        );
    };

    /**
     * Returns number of whole slides in target width
     * slide is whole if it is larger slideWidthPercent
     *
     * @param {number} targetWidth
     * @param {number} slideWidthPercent
     * @return {number}
     * @private
     */
    _numberOfWholeSlidesIn = (targetWidth, slideWidthPercent = 80) => {
        return this._numberOfSlidesIn(targetWidth, slideWidthPercent);
    };

    /**
     * Returns number of items for Carousel to display
     * max 29
     *
     * @param {number} itemsInCollection
     * @private
     */
    _calculateItemsInCarousel = (itemsInCollection) => {
        return itemsInCollection < 30 ? itemsInCollection - 1 : 28;
    };

}
