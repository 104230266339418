"use strict";

import React from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import NotificationActions from '../../actions/NotificationActions';
import NotificationStore from '../../stores/NotificationStore';
import closeCircleIcon from '../../../assets/images/svg_icons/close-circle.svg';
/**
 * NotificationBar component
 */
export default class NotificationBarOverlay extends React.Component {
    /**
     * React: state
     */
    state = {
        notification: null,
        timeoutId: 0
    };

    /**
     * React: Constructor
     */
    constructor(props) {
        super(props);

        this.state = {
            notification: NotificationStore.overlayNotification,
            timeoutId: NotificationStore.overlayNotification && NotificationStore.overlayNotification.hideAfter ? setTimeout(this._hideNotification, NotificationStore.overlayNotification.hideAfter) : 0
        };
    }

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        NotificationStore.addUpdateListener(this._setNotification);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        NotificationStore.removeUpdateListener(this._setNotification);
    }

    /**
     * React: shouldComponentUpdate
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        const notification = this.state.notification;
        const className = 'notification-bar-stripe '
            + (notification ? notification.type : '');

        return this.state.notification ? (
            <div className="stripe-notification-container">
                <div className={className}>
                    <div className="msg-container">
                        {notification.content}
                        <button
                            className="notification-close"
                            onClick={this._closeNotification}
                            style={{'backgroundImage': `url(${closeCircleIcon})`}}
                             />
                    </div>
                </div>
            </div>
        ) : null;
    }

    /**
     * Hide notification
     *
     * @private
     */
    _hideNotification = () => {
        NotificationActions.hideNotification();
    };

    /**
     * Close notification
     *
     * @private
     */
    _closeNotification = () => {
        if (typeof this.state.notification.onClose === 'function') {
            this.state.notification.onClose();
        }
        NotificationActions.hideNotification();
    };

    /**
     * Set notification
     */
    _setNotification = () => {
        clearTimeout(this.state.timeoutId);
        this.setState({
            notification: NotificationStore.overlayNotification,
            timeoutId: NotificationStore.overlayNotification && NotificationStore.overlayNotification.hideAfter ? setTimeout(this._hideNotification, NotificationStore.overlayNotification.hideAfter) : 0
        });
    };
}
