"use strict";

import Immutable from 'immutable';

/**
 * Main navigation section definition
 *
 * @typedef {Immutable.Record} MainNavigationSection
 * @property {?string} key
 * @property {Immutable.OrderedMap<MainNavigationItem>} items
 * @property {?string} classification
 */

/**
 * MainNavigationSection record
 *
 * @type {MainNavigationSection}
 */
class MainNavigationSection extends Immutable.Record({
    key: null,
    items: Immutable.OrderedMap(),
    classification: null,
    displayGenres: true
}) {
    // no op
}

export default MainNavigationSection;
