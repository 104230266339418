"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import store from 'store';
import moment from 'moment';
import {Link} from 'react-router';
import Router from '../lib/Router';
import Config from '../lib/Config';
import OverlayIds from '../lib/OverlayIds';
import {throttle} from '../utils/Common';
import AuthStore from '../stores/AuthStore';
import AppStore from '../stores/AppStore';
import UIStore from '../stores/UIStore';
import UIActions from '../actions/UIActions';
import EntitlementStore from '../stores/EntitlementStore';
import AnalyticsStore from '../stores/AnalyticsStore';
import AppActions from '../actions/AppActions';
import EntitlementActions from '../actions/EntitlementActions';
import NotificationActions from '../actions/NotificationActions';
import AnalyticsActions from '../actions/AnalyticsActions';
import Spinner from './ui/Spinner2.react';
import AppMeta from './elements/meta/AppMeta.react';
import NotificationBar from './elements/NotificationBar.react';
import MediaPlayerOverlay from './elements/MediaPlayerOverlay.react';
import CustomOverlay from './CustomOverlay.react';

/**
 * AppContainer component
 */
export default class AppContainer extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        navigation: PropTypes.element,
        footer: PropTypes.element,
        content: PropTypes.element,
        location: PropTypes.object
    };

    /**
     * React: state
     */
    state = {
        isContentPreLoadingCompleted: AppStore.isContentPreLoadingCompleted(),
        isNavigationFixed: false,
        userIdentity: AuthStore.identity,
        mediaStateTimestamp: moment()
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * History listener handle
     * when set and called, un-subscribes history listener
     *
     * @property {?function}
     * @private
     */
    _historyListenerHandle = null;

    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        this._setNavigationState = throttle(this._setNavigationState, 200);
    }

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        //add pre-load listeners and call action
        AuthStore.addUpdateListener(this._onUserIdentityChange);
        AppStore.addUpdateListener(this._toggleContentPreLoading);
        AnalyticsStore.addUpdateListener(this._onAnalyticsStoreUpdate);
        AppActions.initializeSession({identity: AuthStore.identity});
        AppActions.preLoadContent();
        this._displayCookiesNotification();

        document.addEventListener("scroll", this._setNavigationState);
        window.addEventListener('storage', this._refreshPreLoadedContent);
        this._historyListenerHandle = this.context.router.listen(this._historyListener);

        this._detectDeviceType();
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AuthStore.removeUpdateListener(this._onUserIdentityChange);
        AppStore.removeUpdateListener(this._toggleContentPreLoading);
        AnalyticsStore.removeUpdateListener(this._onAnalyticsStoreUpdate);

        if (typeof this._historyListenerHandle === 'function') {
            this._historyListenerHandle();
        }

        document.removeEventListener("scroll", this._setNavigationState);
        window.removeEventListener('storage', this._refreshPreLoadedContent);
    }

    /**
     * React: render
     */
    render() {
        return this.state.isContentPreLoadingCompleted ? (
            <div className={"app-container" + (this.state.isNavigationFixed ? " small-navbar" : "")}>
                <AppMeta titleText={this._capitalize(this._extractTabTitleText(this.props.location.pathname))}/>
                {this.props.navigation}
                <NotificationBar />
                {this.props.content}
                {this.props.footer}
                <MediaPlayerOverlay playerType={Config.MEDIA_PLAYER_TYPE}/>
                <CustomOverlay />
            </div>
        ) : (
            <div className="app-container">
                <AppMeta titleText={this._extractTabTitleText(this.props.location.pathname)}/>
                <Spinner/>
            </div>
        );
    }

    /**
     * Toggle content pre-loading
     *
     * @private
     */
    _toggleContentPreLoading = () => {
        setTimeout(() => {
            this.setState({
                isContentPreLoadingCompleted: AppStore.isContentPreLoadingCompleted()
            }, () => {
                if (this.state.isContentPreLoadingCompleted) {
                    AppStore.removeUpdateListener(this._toggleContentPreLoading);

                    // Check if payment method is set
                    // if (AuthStore.isSignedIn()
                    //     && AuthStore.userProfile
                    //     && !AuthStore.userProfile.paymentProviderCustomerIds.stripe
                    //     && !paymentSkip) {
                    //     if (this.props.location.pathname !== Router.SET_PAYMENT_METHOD) {
                    //         AppActions.storeRouterTransitionPath(this.props.location.pathname);
                    //         this.context.router.push(Router.SET_PAYMENT_METHOD);
                    //     }
                    //     return;
                    // }

                    // Check if terms and conditions are accepted
                    if (AuthStore.isSignedIn()
                        && AuthStore.identity
                        && !AuthStore.identity.termsAndConditionsAccepted) {
                        if (this.props.location.pathname !== Router.ACCEPT_TERMS_AND_CONDITIONS) {
                            AppActions.storeRouterTransitionPath(this.props.location.pathname);
                            this.context.router.push(Router.ACCEPT_TERMS_AND_CONDITIONS);
                        }
                        // return;
                    }
                }
            });
        }, 0);
    };

    /**
     * On analytics store update
     */
    _onAnalyticsStoreUpdate = () => {
        // no op
        // needed to keep AnalyticsStore in flux pipeline
    };

    /**
     * On user identity change
     */
    _onUserIdentityChange = () => {

        // return if identity is not changed
        if (this.state.userIdentity === AuthStore.identity || EntitlementStore.stbPairingProcessStarted) return;
        if (!AuthStore.isSignedIn() && EntitlementStore.stbPairingData.size > 0) return;

        if (!AuthStore.isSignedIn() && EntitlementStore.currentTrailerStatus) return;

        const nextTransitionPath = AppStore.nextTransitionPath;
        console.info("** [router] signed-in:", AuthStore.isSignedIn(), ", nextTransitionPath: ", nextTransitionPath);

        this.setState({
            userIdentity: AuthStore.identity
        }, () => {
            setTimeout(() => {
                //add pre-load listeners and call action
                AppStore.addUpdateListener(this._toggleContentPreLoading);
                this.setState({
                    isContentPreLoadingCompleted: false
                }, () => {
                    AppActions.initializeSession({identity: AuthStore.identity});
                    AppActions.preLoadContent();
                });

                // transition
                this.context.router.push(nextTransitionPath);
            }, 0);
        });
    };

    /**
     * Set navigation state
     *
     * @private
     */
    _setNavigationState = () => {
        const topPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        this.setState({
            isNavigationFixed: topPosition > (this.state.isNavigationFixed ? 50 : 90)
        });
    };

    /**
     * History listener
     *
     * @private
     */
    _historyListener = (location) => {
        // analytics
        AnalyticsActions.trackPageView({
            pathname: location.pathname
        });

        // clear any active overlays (media player, custom, ..)
        if (UIStore.customOverlayContent)  UIActions.closeCustomOverlay({overlayId: OverlayIds.ALL});
        if (EntitlementStore.playerMediaItem) EntitlementActions.stopMediaItemPlayback();
    };

    /**
     * Refresh pre-loaded content if change of media states is detected
     *
     * @private
     */
    _refreshPreLoadedContent = () => {
        if (window.document.hasFocus()) return;

        let mediaStateChange = store.get("media_state_change");
        if (mediaStateChange && moment(mediaStateChange) > this.state.mediaStateTimestamp) {
            AppStore.addUpdateListener(this._toggleContentPreLoading);
            this.setState({
                mediaStateTimestamp: moment(mediaStateChange),
                isContentPreLoadingCompleted: false
            }, () => {
                AppActions.preLoadContent();
            });
        }
    };

    /**
     * Display cookies notification
     *
     * @private
     */
    _displayCookiesNotification = () => {
        let cookiesInformationAcknowledged = store.get("cookies_information_acknowledged");
        if (cookiesInformationAcknowledged && moment() < moment(cookiesInformationAcknowledged).add(7 ,'days')) return;

        NotificationActions.displayNotification({
            notificationType: '',
            content: (
                <div>
                    We use cookies to improve your online experience and the service we offer. To accept cookies <br />
                    continue browsing as normal. <Link to={Router.PRIVACY} target="_blank">Read our
                    cookies policy</Link> for more information.
                </div>
            ),
            hideAfter: 60000,
            onClose: () => {
                store.set("cookies_information_acknowledged", moment().toJSON());
            }
        });
    };

    /**
     * Detect device type
     *
     * @private
     */
    _detectDeviceType = () => {
        const androidUA = navigator.userAgent.indexOf('Android');
        const iPadUA = navigator.userAgent.indexOf('iPad');
        const iOSUA = navigator.userAgent.indexOf('iOS');
        const mobileUA = navigator.userAgent.indexOf('Mobile');

        if(androidUA>-1 || mobileUA>-1) {
            document.body.className += " mobile-device android-device side-menu-no-hover ";
        } else if(iPadUA>-1 || iOSUA>-1 || mobileUA>-1) {
            document.body.className += " mobile-device ios-device side-menu-no-hover ";
        }
    };

    /**
     * extract browser tab title text from router path
     *
     * @private
     */
    _extractTabTitleText = (path) => {
        const tabTitle = path.split('/').filter( element => element !== '');
        return tabTitle.join(' ');
    };

    /**
     * capitalize first letter of string
     *
     * @private
     */
    _capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
}
