"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import MediaItem, {MediaItemClassification} from '../../../types/MediaItem';
import AppStore from '../../../stores/AppStore';
import Button from '../../ui/Button.react';
import MediaItemComponentCTAs from '../../mixins/MediaItemComponentCTAs';

/**
 * MiscInformationTwoColumn component
 */
export default class MiscInformationTwoColumn extends MediaItemComponentCTAs(React.Component) {
    /**
     * React: propTypes
     */
    static propTypes = {
        mediaItem: PropTypes.instanceOf(MediaItem).isRequired,
        seasonMediaItem: PropTypes.instanceOf(MediaItem)
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        var mediaItem = this.props.mediaItem;
        var watchlistMediaItem = mediaItem.classification === MediaItemClassification.SERIES
            ? this.props.seasonMediaItem : mediaItem;
        var basicInfoGridColumns = mediaItem.classification === MediaItemClassification.SERIES
            || mediaItem.classification === MediaItemClassification.TV_BOX_SET
            ? " col-xs-9 col-md-9 col-lg-8" : " col-xs-12 col-md-12 col-lg-12";

        return (
            <div className={"misc-information two-column two-column-button" + basicInfoGridColumns}>
                <div className="">
                    {mediaItem.classification === MediaItemClassification.MOVIE_BOX_SET ? (
                        <strong>{AppStore.translate('label.movie_count', {itemCount: mediaItem.childrenIds.length})}<br/></strong>
                    ) : (mediaItem.classification === MediaItemClassification.TV_BOX_SET ? (
                        <strong>{AppStore.translate('label.season_count', {itemCount: mediaItem.childrenIds.length})}<br/></strong>
                    ) : (
                        <strong>{mediaItem.releaseYear}<br/></strong>
                    ))}
                    <div className="certificate"><strong>{AppStore.translate('label.certificate')}</strong> {mediaItem.certificate}<br/></div>
                    <div className="genre-list">
                        <strong>{AppStore.translate('label.genre')}</strong> {mediaItem.genres.join(', ')}
                    </div>
                    {mediaItem.classification !== MediaItemClassification.MOVIE_BOX_SET && mediaItem.classification !== MediaItemClassification.TV_BOX_SET && mediaItem.duration ? (
                        <div className="language-duration">
                            {this._formatTime(mediaItem.duration)}
                        </div>
                    ) : null}
                </div>

                {watchlistMediaItem.watchlistAvailable ? (
                    <Button shape="square-chromeless"
                            className={'watchlist-button watchlist-add'}
                            disabled={watchlistMediaItem.updatePending}
                            updatePending={watchlistMediaItem.watchlistPending}
                            onClick={this._toggleMediaItemWatchlistStatus.bind(null, watchlistMediaItem)}>
                        {watchlistMediaItem.isOnWatchlist
                            ? (<i className="ui-icon icon-remove"/>)
                            : (<i className="ui-icon icon-add"/>)}
                        {AppStore.translate('button.my_watchlist')}
                    </Button>
                ) : null }
            </div>
        );
    }

    /**
     * Format time in hours and mins ( Xhr Xmin )
     *
     * @private
     */
    _formatTime = (durationMin) => {
        if (durationMin < 60 ) {
            return durationMin + 'min';
        } else {
            return `${Math.floor(durationMin / 60)}hr ${durationMin % 60}min`;
        }
    };
}
