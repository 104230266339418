/* eslint-disable import/no-cycle */
import React, {Fragment} from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import Config from '../lib/Config';
import OverlayIds from '../lib/OverlayIds';
import UIActions from '../actions/UIActions';
import UIStore from '../stores/UIStore';
import CheckoutOverlay from './elements/CheckoutOverlay.react';
import PaymentMethodV1Overlay from './elements/PaymentMethodV1Overlay.react';
import PaymentMethodV3Overlay from './elements/PaymentMethodV3Overlay.react';
import CVVInfoOverlay from './elements/CVVInfoOverlay.react';
import PreorderedMediaItemOverlay from './elements/PreOrderedMediaItemAlertOverlay.react';

const CustomOverlayIndex = {
    [OverlayIds.CHECKOUT]: (
        <CheckoutOverlay />
    ),
    [OverlayIds.PAYMENT_METHOD]: !Config.STRIPE_USE_V3 ? (
        <PaymentMethodV1Overlay />
    ) : (
        <PaymentMethodV3Overlay />
    ),
    [OverlayIds.CVV_INFO]: (
        <CVVInfoOverlay />
    ),
    [OverlayIds.PREORDERED_ITEM]: (
        <PreorderedMediaItemOverlay />
    ),
};

/**
 * CustomOverlay component
 */
export default class CustomOverlay extends React.Component {
    /**
     * React: state
     */
    state = {
        customOverlayContent: null,
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        UIStore.addUpdateListener(this.setOverlayState);
    }

    /**
     * React: shouldComponentUpdate
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        UIStore.removeUpdateListener(this.setOverlayState);
    }

    /**
     * Close overlay
     *
     * @private
     */
    closeOverlay = () => {
        const customOverlayItem = this.state.customOverlayContent.last();

        if (customOverlayItem.isNode) {
            UIActions.closeCustomOverlay();
            return;
        }

        UIActions.closeCustomOverlay({overlayId: customOverlayItem.overlayId});
    };

    /**
     * Set overlay state
     */
    setOverlayState = () => {
        if (UIStore.customOverlayContent !== this.state.customOverlayContent) {
            this.setState({
                customOverlayContent: UIStore.customOverlayContent,
            });
        }
    };

    /**
     * React: render
     */
    render() {
        if (!this.state.customOverlayContent) return null;
        // TODO refactor when legacy pop-ups reconfigured
        return this.state.customOverlayContent.size !== 0 ? (
            <Fragment>
                {this.state.customOverlayContent.toArray().map((customOverlayItem, index) => {
                    return (
                        <div className="overlay-backdrop" key={index}>
                            {customOverlayItem.isNode
                                ? customOverlayItem.overlayId
                                : CustomOverlayIndex[customOverlayItem.overlayId]}
                            {customOverlayItem.showCloseButton
                                ? (<button type="button" className="close" onClick={this.closeOverlay} />)
                                : null}
                        </div>
                    );
                })
                }
            </Fragment>
        ) : null;
    }
}
