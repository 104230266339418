"use strict";

import request from 'request';
import Promise from 'bluebird';
import Config from '../Config';
import {APIRequestError} from '../../types/ExtendableError';
import AuthStore from '../../stores/AuthStore';

/**
 * Base request
 */
let baseRequest = request.defaults({
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Affiliate-Id': Config.AFFILIATE_ID
    },
    json: true
});

let secondaryRequest = request.defaults({
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    json: true
});
/**
 * Returns promise for base request
 *
 * @param {Object} requestParams
 */
export function getRequestPromise(requestParams) {
    return new Promise((resolve, reject) => {
        baseRequest(requestParams,
            (error, response, body) => {
                if (error || response.statusCode >= 400) {
                    const apiRequestError = new APIRequestError('', error, response, body);
                    return reject(apiRequestError);
                }
                return resolve(body);
            }
        );
    });
}

/**
 * Returns promise for base request with authentication
 *
 * @param {Object} requestParams
 * @param {?string} authToken
 */
export function getAuthRequestPromise(requestParams, authToken = null) {
    AuthStore.validateToken();

    requestParams = {
        ...requestParams,
        headers: {
            'Authorization': 'Basic ' + (authToken || AuthStore.identity.authToken)
        }
    };

    return new Promise((resolve, reject) => {
        baseRequest(requestParams,
            (error, response, body) => {
                if (error || response.statusCode >= 400) {
                    const apiRequestError = new APIRequestError('', error, response, body);
                    return reject(apiRequestError);
                }
                return resolve(body);
            }
        );
    });
}

export function getApiRequestPromise(requestParams) {
    AuthStore.validateToken();

    requestParams = {
        ...requestParams

    };
    return new Promise((resolve, reject) => {
        secondaryRequest(requestParams,
            (error, response, body) => {
                if (error || response.statusCode >= 400) {
                    const apiRequestError = new APIRequestError('', error, response, body);
                    return reject(apiRequestError);
                }
                return resolve(body);
            }
        );
    });
}
