"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import shallowCompare from 'react-addons-shallow-compare';
import CustomerPaymentDetails from '../../../types/CustomerPaymentDetails';
import Voucher, {VoucherStatus} from '../../../types/Voucher';
import AppStore from '../../../stores/AppStore';
import TextInput from '../../ui/TextInput.react';
import Button from '../../ui/Button.react';
import IconButtonWithBubblePop from '../../ui/IconButtonWithBubblePop.react';

/**
 * PaymentDetails component
 */
export default class PaymentDetails extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        updatePending: PropTypes.bool.isRequired,
        voucherPending: PropTypes.bool.isRequired,
        customerPaymentDetails: PropTypes.instanceOf(CustomerPaymentDetails),
        togglePaymentMethodForm: PropTypes.func.isRequired,
        voucher: PropTypes.instanceOf(Voucher),
        verifyVoucher: PropTypes.func
    };

    /**
     * React: state
     */
    state = {
        voucherCode: ''
    };

    /**
     * React: shouldComponentUpdate
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        let card = this.props.customerPaymentDetails ? this.props.customerPaymentDetails.defaultCard : null;
        let voucher = this.props.voucher;
        let isVoucherValid = voucher && this.props.voucher.status === VoucherStatus.VALID;
        let voucherStatusMessage = voucher ? AppStore.translate('notification.voucher.status.'
            + Object.keys(VoucherStatus).find((key) => {
                return this.props.voucher.status === VoucherStatus[key];
            }).toLowerCase()) : '';

        const popupInfoText = (
            <div>
                <p>You only pay for the movie or TV show you want to watch.</p>
                <p>Store purchases do not appear on your Virgin Media bill.</p>
                <p>We need your debit or credit card details to take payment for your purchases.</p>
                <p>Once you save your payment details online you can use your TV PIN to confirm purchases on your Virgin TV.</p>
                <p>You can change your payment details anytime by going to My Account.</p>
            </div>);

        return (
            <div className="details-block-container">
                <div className="details-title-container">
                    Payment details
                </div>

                <div className="details-content-container">
                    {card ? (
                        <div>
                            <p>
                                Your {card.brand} card expiring
                            on &nbsp;{moment().month(card.expiryMonth - 1).format('MM')} / {card.expiryYear} will be charged for this purchase.
                        </p>
                            <div className="changable-user-information">
                                <h4>#### #### #### {card.lastFourDigits}</h4>
                                <div className="button-container">
                                    <Button type='submit'
                                        className="right-button"
                                        shape="regular"
                                        disabled={this.props.updatePending}
                                        onClick={this.props.togglePaymentMethodForm}>
                                        <b>{AppStore.translate('button.change')}</b>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ) : (
                            <div className="payment-details-info-container">
                                <div className="changable-user-information">
                                    <p className="payment-details-text">
                                        You haven’t saved your card details.
                                        Click Add Card to save your payment details and make Store purchases.
                                    </p>
                                    <h4 />
                                    <div className="payment-details-icon-btn-container">
                                        <IconButtonWithBubblePop
                                            icon="icon-ic-info"
                                            infoTitle="Info"
                                            infoContent={popupInfoText}
                                        />
                                    </div>
                                    <div className="button-container">
                                        <Button type='submit'
                                            className="right-button"
                                            shape="regular"
                                            disabled={this.props.updatePending}
                                            onClick={this.props.togglePaymentMethodForm}>
                                            <b>{AppStore.translate('button.add_payment')}</b>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}

                    <div>
                        <p>
                            {AppStore.translate('view.payment_page.voucher.message.apply')}
                        </p>
                        <div
                            className={"changable-user-information" + (voucher && isVoucherValid ? " voucher-added" : '')}>
                            <div className="user-information-input-container">
                                <TextInput useStatusIcon={false}
                                    statusIcon={null}
                                    value={this.state.voucherCode}
                                    disabled={!card || this.props.updatePending || (voucher && isVoucherValid)}
                                    onChange={this._onFormFieldChange.bind(null, 'voucherCode')} />
                            </div>
                            <div className="button-container">
                                <Button className="right-button"
                                    shape="regular"
                                    disabled={!card || this.props.updatePending || (voucher && isVoucherValid)}
                                    updatePending={this.props.voucherPending}
                                    onClick={this.props.verifyVoucher.bind(null, this.state.voucherCode)}>
                                    <b>{AppStore.translate('button.apply')}</b>
                                </Button>
                            </div>
                        </div>
                        {voucher && !isVoucherValid ? (
                            <p className="voucher-error-message">
                                {voucherStatusMessage}
                            </p>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }

    /**
     * On field change
     *
     * @param {string} fieldName
     * @param {*} value
     * @private
     */
    _onFormFieldChange = (fieldName, value) => {
        var newState = {};
        newState[fieldName] = value;
        this.setState(newState);
    };
}
