"use strict";


/**
 * InputUIs
 *
 * @mixin
 */
var InputUIs = (superclass) => class extends superclass {

    /**
     * On change
     */
    _onChange = (event) => {
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(this.state.value, event);
        }
    };

    /**
     * On click
     */
    _onClick = (event) => {
        if (typeof this.props.onClick === 'function') {
            this.props.onClick(event.target.value, event);
        }
    };
};

export default InputUIs;
