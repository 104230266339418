/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Router from '../lib/Router';
import AppStore from '../stores/AppStore';
import AuthActions from '../actions/AuthActions';
import AuthStore from '../stores/AuthStore';
import Button from './ui/Button.react';
import NotificationActions from '../actions/NotificationActions';

/**
 * AccountResetPasswordPage component
 */
export default class AccountResetPasswordPage extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        location: PropTypes.object.isRequired,
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object,
    };

    /**
     * React: state
     */
    state = {
        newPassword: '',
        newPasswordConfirmation: '',
        updatePending: false,
        passwordFieldsValuesMatch: false,
        confirmPasswordEnabled: false,
        showPassword: false,
        passwordIsStrong: false,
        inputTouched: false,
        confirmInputTouched: false,
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        document.body.className += ' black-background';
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AuthStore.removeListener(AuthStore.STORE_UPDATED, this.setFormStatus);
        this.setState({updatePending: false});

        document.body.className = document.body.className.replace(/black-background/, '');
    }

    /**
     * On field change
     * @param {Object} event
     * @private
     */
    onFieldChange = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    /**
     * check password match
     */
    checkPasswordMatch = () => {
        this.setState(prevState => (
            {passwordFieldsValuesMatch: prevState.newPassword === prevState.newPasswordConfirmation}
        ));
    }

    /**
     * On form submit
     *
     * @param {Object} event
     * @private
     */
    onSubmit = event => {
        event.preventDefault();
        if (this.state.newPassword !== this.state.newPasswordConfirmation) {
            NotificationActions.displayNotification({
                notificationType: 'error',
                content: AppStore.translate('notification.account.error.password_mismatch'),
            });
            return;
        }

        this.setState({
            updatePending: true,
        }, () => {
            AuthStore.addUpdateListener(this.setFormStatus);
            NotificationActions.hideNotification();
            AuthActions.resetUserPassword({
                token: this.props.location.query.token.replace(/ /g, '+'),
                newPassword: this.state.newPassword,
            });
        });
    };

    /**
     * Set form status
     */
    setFormStatus = () => {
        this.setState({updatePending: AuthStore.updatePending}, () => {
            if (!this.state.updatePending && !AuthStore.error) {
                setTimeout(() => {
                    this.context.router.push(Router.SIGN_IN);
                }, 0);
            }
        });
    };

    /**
     * On field change
     *
     * @param {string} fieldName
     * @param {Object} event
     * @private
     */
    checkFieldValidity = e => {
        if (e.target.name === 'newPassword') {
            const regExPasswordRule = /^(?=.*?[A-Z+])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
            this.setState({
                passwordIsStrong: regExPasswordRule.test(e.target.value),
                confirmPasswordEnabled: regExPasswordRule.test(e.target.value),
                inputTouched: true,
            });
        }
        if (e.target.name === 'newPasswordConfirmation') {
            this.setState({confirmInputTouched: true});
        }
        this.checkPasswordMatch();
    };

    /**
     * React: render
     */
    render() {
        return (
            <main className="account-page">
                <h4 className="account-page-title">{AppStore.translate('view.account_page.title.reset_password')}</h4>
                <div className="container">
                    <div className="row">
                        <div className="account-page-content col-xs-12 col-sm-8 col-md-5 col-lg-4">

                            <div className="login-avatar">
                                <i className="ui-icon icon-user-avatar" />
                            </div>

                            <div className="page-title-wrapper">
                                <p>{AppStore.translate('view.account_page.message.provide_new_password')}</p>
                            </div>

                            <form onSubmit={this.onSubmit}>
                                <input
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    name="newPassword"
                                    className={`simple-input simple-input-retype${
                                        this.state.inputTouched
                                        && !this.state.passwordIsStrong ? ' simple-input-error' : ''}`}
                                    placeholder={AppStore.translate('field.placeholder.new-password-signup')}
                                    value={this.state.newPassword}
                                    disabled={this.state.updatePending}
                                    autoComplete="new-password"
                                    onBlur={this.checkFieldValidity}
                                    onChange={this.onFieldChange}
                                />
                                <input
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    name="newPasswordConfirmation"
                                    className={`simple-input${
                                        this.state.confirmInputTouched
                                            && !this.state.passwordFieldsValuesMatch ? ' simple-input-error' : ''}`}
                                    placeholder={AppStore.translate('field.placeholder.new-password-update-retype')}
                                    value={this.state.newPasswordConfirmation}
                                    disabled={this.state.updatePending || !this.state.confirmPasswordEnabled}
                                    autoComplete="new-password"
                                    onBlur={this.checkFieldValidity}
                                    onChange={this.onFieldChange}
                                />
                                {this.state.confirmInputTouched
                                    && !this.state.passwordFieldsValuesMatch
                                    && this.state.passwordIsStrong ? (
                                        <span className="input-error-message">
                                            {AppStore.translate('field.error.password-confirm-not-match')}
                                        </span>
                                    ) : null }
                                { this.state.newPassword.length > 0 && !this.state.passwordIsStrong ? (
                                    <span className="input-error-message">
                                        {AppStore.translate('field.error.password-not-strong')}
                                    </span>
                                ) : null }

                                <div className="form-annotation">
                                    <p className="no-top-padding">
                                        {AppStore.translate('field.label.password_rule')}
                                    </p>
                                </div>
                                <Button
                                    type="submit"
                                    shape="square"
                                    className="cta-play full-width"
                                    disabled={this.state.updatePending
                                        || !this.state.passwordIsStrong
                                        || !this.state.inputTouched
                                        || !this.state.passwordFieldsValuesMatch}
                                    updatePending={this.state.updatePending}
                                >
                                    {AppStore.translate('button.reset')}
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
