"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import InputUIs from '../../mixins/InputUIs';

/**
 * Checkbox component
 */
export default class Checkbox extends InputUIs(React.Component) {
    /**
     * React: propTypes
     */
    static propTypes = {
        value: PropTypes.string,
        label: PropTypes.string,
        updatePending: PropTypes.bool,
        disabled: PropTypes.bool,
        error: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
        checked: PropTypes.bool
        // useStatusIcon: PropTypes.bool,
        // statusIcon: PropTypes.string,
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        value: '',
        updatePending: false,
        disabled: false,
        checked: false
        // useStatusIcon: false
    };

    /**
     * React: state
     */
    state = {
        value: this.props.value
    };

    /**
     * React: UNSAFE_componentWillReceiveProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value
        });
    }

    /**
     * React: render
     */
    render() {

        var className = 'ui-checkbox-wrapper ' +
            (this.props.inputClassName ? this.props.inputClassName : '') +
            (this.props.updatePending ? 'update-pending ' : '') +
            (this.props.statusIcon == 'success' ? 'status-success ' : '') +
            (this.props.statusIcon == 'error' ? 'status-error ' : '');

        return (
            <label className={className}>
                <input type="checkbox"
                       disabled={this.props.disabled}
                       onChange={() => this._onChange(event)}
                       onClick={() => this._onClick(event)}
                       checked={this.props.checked}/>
                {this.props.label ? this.props.label : this.props.value}
            </label>
        );
    }

    _onChange = (event) => {
        if(typeof this.props.onChange==='function'){
            this.props.onChange(event.target.value);
        }
    };

    _onClick = (event) => {
        if(typeof this.props.onClick==='function'){
            this.props.onClick(event.target.value);
        }
    };
}
