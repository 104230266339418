"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import StorefrontPage from './StorefrontPage.react';
import AuthActions from '../actions/AuthActions';

/**
 * AccountActivatePage component
 */
export default class AccountActivatePage extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        location: PropTypes.object
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        AuthActions.activateUser({token: encodeURIComponent(this.props.location.query.token.replace(/ /g, '+'))});
    }

    /**
     * React: render
     */
    render() {
        return (
            <StorefrontPage/>
        );
    }
}
