"use strict";

import React from 'react';
import PropTypes from 'prop-types';

/**
 * DropDownSelect component
 */
export default class DropDownSelect extends React.Component {

    /**
     * React: propTypes
     */
    static propTypes = {
        title: PropTypes.string,
        list: PropTypes.array,
        toggleItem: PropTypes.func
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        title: '',
        list: []
    };

    /**
     * React: state
     */
    state = {
        listOpen: false
    };

    /**
     * React: render
     */
    render() {
        const{list} = this.props;
        const{listOpen} = this.state;
        return(
            <div className="ui-drop-down-select-wrapper" onMouseLeave={() => this.closeList()}>
                <div className="ui-drop-down-select-header" onClick={() => this.toggleList()}>
                    <div className="ui-drop-down-select-header-title">{this.props.title}</div>
                    {listOpen ? (
                        <span><i className="ui-icon icon-select_arrow_up" /></span>
                    ) : (
                        <span><i className="ui-icon icon-select_arrow_down" /></span>
                    )}
                </div>

                {listOpen && <ul className="ui-drop-down-select-list" onMouseLeave={() => this.toggleList()}>
                    {list.map((item) => (
                        <li className="ui-drop-down-select-list-item"
                            key={item.key}
                            onClick={() => {
                                this.props.toggleItem({ target: {value: item.key} });
                                this.toggleList();
                            }}
                        >
                            {this.props.title === item.value && <i className="ui-icon icon-check-sign" />}{item.value}
                        </li>
                    ))}
                </ul>}
            </div>
        );
    }

    toggleList(){
        this.setState(prevState => ({
            listOpen: !prevState.listOpen
        }));
    }

    closeList(){
        this.setState(() => ({
            listOpen: false
        }));
    }
}
