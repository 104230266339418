"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import AppStore from '../stores/AppStore';
import MediaActions from '../actions/MediaActions';
import MediaStore from '../stores/MediaStore';
import Filter from '../lib/Filter';
import FilterBar from './elements/FilterBar.react';
import MediaGallery from './elements/MediaGallery.react';
import Router from '../lib/Router';

/**
 * GenrePage component
 */
export default class GenrePage extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        params: PropTypes.object,
        location: PropTypes.object
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: state
     */
    state = {
        activeFilters: AppStore.activeFilters,
        mediaBucket: null,
        page: 0,
        currentLocation: null

    };

    /**
     * Bucket keys
     */
    _mediaBucketKey = null;

    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        if (this.props.params && this.props.params.genreId) {
            this._mediaBucketKey = MediaActions.getGenreMedia({
                genreId: this.props.params.genreId,
                classification: this.props.location.query.classification,
                page: this.state.page,
                currentLocation: this.props.location.pathname
            });
        }
    }

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        // add store listeners
        AppStore.addUpdateListener(this._setActiveFilters);
        MediaStore.addUpdateListener(this._setMediaBucket);
        window.addEventListener('scroll', this._areGenresResultsScrolledToBottom, false);
    }





    /**
    * nContainer = document.querySelector('.js-collection-container');
     * React: UNSAFE_componentWillReceiveProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((nextProps.params && nextProps.params.genreId && nextProps.params.genreId !== this.props.params.genreId)
            || (nextProps.location.query && nextProps.location.query.classification && nextProps.location.query.classification !== this.props.location.query.classification)) {
            MediaStore.destroyMediaBucket(this._mediaBucketKey);
            this._mediaBucketKey = null;
            this.setState({
                mediaBucket: null,
                currentLocation: nextProps.location.pathname,
                page: this.state.currentLocation !== nextProps.location.pathname ? 0 : this.state.page
            }, () => {
                this._mediaBucketKey = MediaActions.getGenreMedia({
                    genreId: nextProps.params.genreId,
                    classification: this.props.location.query.classification,
                    page: this.state.page,
                    currentLocation: this.props.location.pathname
                });
            });
        }
    }


    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AppStore.removeUpdateListener(this._setActiveFilters);
        MediaStore.removeUpdateListener(this._setMediaBucket);
        MediaStore.destroyMediaBucket(this._mediaBucketKey);
        window.removeEventListener('scroll', this._areGenresResultsScrolledToBottom, false);
    }

    /**
     * React: render
     */
    render() {
        var mediaBucket = this.state.mediaBucket;
        var media = mediaBucket ? mediaBucket.media() : null;
        var filteredMedia = media ? Filter.media(media, this.state.activeFilters) : null;
        return (
            <main>
                <FilterBar/>
                {media && media.size === 0 ? (
                    <div className="no-results">
                        {AppStore.translate('view.genre_page.message.no_media_for_genre', {genreName: this.props.params.genreId})}
                    </div>
                ) : (filteredMedia && filteredMedia.size === 0 ? (
                    <div className="no-results">{AppStore.translate('message.no_media_for_filter_selection')}</div>
                ) : (
                    <MediaGallery title={this.props.params.genreId}
                        className="genre-results"
                        media={media}
                        mediaLoading={mediaBucket && mediaBucket.updatePending}/>
                ))}
            </main>
        );
    }

    /**
     * Set active filters
     */
    _setActiveFilters = () => {
        this.setState({
            activeFilters: AppStore.activeFilters
        });
    };

    /**
     * Set mediaBucket
     */
    _setMediaBucket = () => {
        // const numberOfPages =
        this.setState({
            mediaBucket: MediaStore.getMediaBucket(this._mediaBucketKey)
        });
    };

    /**
     * Checks if genres page is active
     * @private
     */
    _isGenresPageActive = () => {
        return this.context.router.isActive(Router.GENRES);
    };

    /**
     * Checks if genre results are scrolled to bottom
     * @private
     */
    _areGenresResultsScrolledToBottom = () => {
        const genreResultsElement = document.querySelector('main .genre-results');
        if (this._isGenresPageActive() && (window.innerHeight + window.pageYOffset) >= genreResultsElement.offsetHeight) {
            this._genreMediaOnResultsScrolledToBottom();
        }
    };

    /**
     * Genres media on scrolled to bottom
     */
    _genreMediaOnResultsScrolledToBottom = () => {
        const mediaBucket = MediaStore.getMediaBucket(MediaStore.genreMediaBucketKey);
        if (mediaBucket && !mediaBucket.updatePending && mediaBucket.media().size !== 0 && mediaBucket.media().size < MediaStore.genreResultTotalCount) {
            MediaActions.getGenreMedia({
                uuid: MediaStore.genreMediaBucketKey,
                genreId: MediaStore.genreId,
                classification: MediaStore.genreClassification,
                page: this.state.page + 1
            });
            this.setState({
                page: this.state.page + 1
            });
        }
    };

}
