"use strict";

/**
 * OverlayIds
 */
export default {
    ALL: 'ALL',
    CHECKOUT: 'CHECKOUT',
    PAYMENT_METHOD: 'PAYMENT_METHOD',
    CVV_INFO: 'CVV_INFO',
    PREORDERED_ITEM: 'PREORDERED_ITEM',
};
