"use strict";

import React from 'react';
import Config from '../../lib/Config';
import {StripeProvider, Elements} from 'react-stripe-elements';
import PaymentMethodStripeElementsForm from './payment-method-v3-overlay/PaymentMethodStripeElementsForm.react';

/**
 * PaymentMethodV3Overlay component
 */
export default class PaymentMethodV3Overlay extends React.Component {
    /**
     * React: render
     */
    render() {
        return (
            <StripeProvider apiKey={Config.STRIPE_API_PK}>
                <Elements>
                    <PaymentMethodStripeElementsForm />
                </Elements>
            </StripeProvider>
        );
    }
}
