"use strict";

import Immutable from 'immutable';
import moment from 'moment';

/**
 * UserIdentityDTO definition (ValidateIdentityResultDTO)
 *
 * @typedef {Object} UserIdentityDTO
 * @property {?string} CustomerName - The customer name as known on the affiliate system
 * @property {?string} Username
 * @property {?string} UniqueIdentifier - Vubiquity stopped using username and instead used UserIdentifier
 * @property {?string} AccountNumber - The account number as known on the affiliates system
 * @property {?number} VuAccountResolutionMethod = ['AffiliateAccountMatchesUsername', 'AffiliateAccountMatchesString1',
 *                         'AffiliateAccountMatchesString2', 'AffiliateAccountMatchesString3', 'AffiliateAccountMatchesString4',
 *                         'AffiliateAccountMatchesString5', 'NoAffiliateAccountRef']
 * @property {?string} Token - The Users Token / Session Id
 * @property {?number} IdentityStatusCode - The users Identity Status Code as given by the affiliates system = ['Match',
 *                                          'InternalError', 'InvalidInput', 'IPLookupFailed', 'PhoneNumberMismatch', 'UnrecognisedErrorCode']
 * @property {?string} IdentityStatus - The users Identity Status as given by the affiliates system
 * @property {?string} CustomerTVODProduct - The customers product as known on the affiliates system
 * @property {?number} TVODStatusCode - The users TVOD Status Code as given by the affiliates system = ['OK', 'InternalError',
 *                          'InvalidInput', 'NoSuchCustomer', 'NoQualifyingProduct', 'CustomerSuspended', 'CustomerSuspendedCeaseOrderFound',
 *                          'StatusNotChecked', 'UnrecognisedErrorCode']
 * @property {?string} TVODStatus - The users TVOD Status as given by the affiliates system
 * @property {?string} CustomerSVODProduct - The customers product as known on the affiliates system
 * @property {?number} SVODStatusCode - The users SVOD Status Code as given by the affiliates system = ['OK', 'CustomerBarred',
 *                        'NoQualifyingProduct', 'CustomerSuspended', 'InternalError', 'InvalidInput', 'UnrecognisedServiceResponse',
 *                        'StatusNotChecked', 'UnrecognisedErrorCode'],
 * @property {?string} SVODStatus - The users SVOD Status as given by the affiliates system
 * @property {?number} ESTStatusCode - The users EST Status Code as given by the affiliates system = ['OK', 'InternalError',
 *                                          'InvalidInput', 'NoSuchCustomer', 'NoQualifyingProduct', 'CustomerSuspended',
 *                                          'CustomerSuspendedCeaseOrderFound', 'StatusNotChecked', 'UnrecognisedErrorCode']
 * @property {?string} ESTStatus - The users EST Status as given by the affiliates system
 * @property {?string} SubscriptionTiers
 * @property {?boolean} TermsAndConditionsAccepted
 * @property {?number} PortalStatus - The resultant status of the portal account = ['Unknown', 'Ok',
 *                                      'TermsAndConditionsNotAccepted', 'AccountSuspended', 'FFLockOut', 'NotApproved']
 * @property {?number} AffiliateStatus - The resultant status a customer has in the affiliate's system  = ['Unknown', 'Active', 'Inactive',
 *                                      'Suspended', 'Closed']
 * @property {?number} AffiliateId - Identifier of the affiliate
 */

/**
 * UserIdentity definition
 *
 * @typedef {Immutable.Record} UserIdentity
 * @property {?string} customerName
 * @property {?string} username
 * @property {?string} accountNumber
 * @property {?string} vuAccountResolutionMethod
 * @property {?string} token
 * @property {?string} authToken
 * @property {?number} tokenExpiration
 * @property {?string} identityStatusCode
 * @property {?string} identityStatus
 * @property {?string} customerProductTVOD
 * @property {?string} statusCodeTVOD
 * @property {?string} statusTVOD
 * @property {?string} customerProductSVOD
 * @property {?string} statusCodeSVOD
 * @property {?string} statusSVOD
 * @property {?string} statusCodeEST
 * @property {?string} statusEST
 * @property {?string} subscriptionTiers
 * @property {?boolean} termsAndConditionsAccepted
 * @property {?string} portalStatus
 * @property {?string} affiliateStatus
 */

/**
 * UserIdentity record
 *
 * @type {UserIdentity}
 */
class UserIdentity extends Immutable.Record({
    customerName: null,
    username: null,
    accountNumber: null,
    vuAccountResolutionMethod: null,
    token: null,
    authToken: null,
    tokenExpiration: null,
    identityStatusCode: null,
    identityStatus: null,
    customerProductTVOD: null,
    statusCodeTVOD: null,
    statusTVOD: null,
    customerProductSVOD: null,
    statusCodeSVOD: null,
    statusSVOD: null,
    statusCodeEST: null,
    statusEST: null,
    subscriptionTiers: null,
    termsAndConditionsAccepted: null,
    portalStatus: null,
    affiliateStatus: null
}) {
    /**
     * Set values from data transfer object
     *
     * @param {UserIdentityDTO} dataTransferObject
     * @return {UserIdentity}
     */
    fromDTO(dataTransferObject) {
        return new UserIdentity({
            ...this.toJS(),
            customerName: typeof dataTransferObject.CustomerName !== 'undefined' ? dataTransferObject.CustomerName : this.customerName,
            username: typeof dataTransferObject.UniqueIdentifier !== 'undefined' ? dataTransferObject.UniqueIdentifier : this.username,
            accountNumber: typeof dataTransferObject.AccountNumber !== 'undefined' ? dataTransferObject.AccountNumber : this.accountNumber,
            vuAccountResolutionMethod: typeof dataTransferObject.VuAccountResolutionMethod !== 'undefined'
                ? AccountResolutionMethodType.getValue(dataTransferObject.VuAccountResolutionMethod) : this.vuAccountResolutionMethod,
            token: typeof dataTransferObject.Token !== 'undefined' ? dataTransferObject.Token : this.token,
            authToken: btoa(typeof dataTransferObject.Token !== 'undefined' ? (dataTransferObject.UniqueIdentifier + ":" + dataTransferObject.Token) : ""),
            tokenExpiration: moment().add(24, 'h').unix(),
            identityStatusCode: typeof dataTransferObject.IdentityStatusCode !== 'undefined'
                ? UserIdentityStatusType.getValue(dataTransferObject.IdentityStatusCode, 'statusCodeIdentity') : this.identityStatusCode,
            identityStatus: typeof dataTransferObject.IdentityStatus !== 'undefined' ? dataTransferObject.IdentityStatus : this.identityStatus,
            customerProductTVOD: typeof dataTransferObject.CustomerTVODProduct !== 'undefined' ? dataTransferObject.CustomerTVODProduct : this.customerProductTVOD,
            statusCodeTVOD: typeof dataTransferObject.TVODStatusCode !== 'undefined'
                ? UserIdentityStatusType.getValue(dataTransferObject.TVODStatusCode, 'statusCodeTVOD') : this.statusCodeTVOD,
            statusTVOD: typeof dataTransferObject.TVODStatus !== 'undefined' ? dataTransferObject.TVODStatus : this.statusTVOD,
            customerProductSVOD: typeof dataTransferObject.CustomerSVODProduct !== 'undefined' ? dataTransferObject.CustomerSVODProduct : this.customerProductSVOD,
            statusCodeSVOD: typeof dataTransferObject.SVODStatusCode !== 'undefined'
                ? UserIdentityStatusType.getValue(dataTransferObject.SVODStatusCode, 'statusCodeSVOD') : this.statusCodeSVOD,
            statusSVOD: typeof dataTransferObject.SVODStatus !== 'undefined' ? dataTransferObject.SVODStatus : this.statusSVOD,
            statusCodeEST: typeof dataTransferObject.ESTStatusCode !== 'undefined'
                ? UserIdentityStatusType.getValue(dataTransferObject.ESTStatusCode, 'statusCodeEST') : this.statusCodeEST,
            statusEST: typeof dataTransferObject.ESTStatus !== 'undefined' ? dataTransferObject.ESTStatus : this.statusEST,
            subscriptionTiers: typeof dataTransferObject.SubscriptionTiers !== 'undefined' ? dataTransferObject.SubscriptionTiers : this.subscriptionTiers,
            termsAndConditionsAccepted: typeof dataTransferObject.TermsAndConditionsAccepted !== 'undefined'
                ? dataTransferObject.TermsAndConditionsAccepted : this.termsAndConditionsAccepted,
            portalStatus: typeof dataTransferObject.PortalStatus !== 'undefined'
                ? UserIdentityStatusType.getValue(dataTransferObject.PortalStatus, 'statusCodePortal') : this.portalStatus,
            affiliateStatus: typeof dataTransferObject.AffiliateStatus !== 'undefined'
                ? UserIdentityStatusType.getValue(dataTransferObject.AffiliateStatus, 'statusCodeAffiliate') : this.affiliateStatus
        });
    }
}

/**
 * Account resolution method
 *
 * @type {Object}
 */
var AccountResolutionMethodType = {
    AFFILIATE_ACCOUNT_MATCHES_USERNAME: 'AffiliateAccountMatchesUsername',
    AFFILIATE_ACCOUNT_MATCHES_STRING_1: 'AffiliateAccountMatchesString1',
    AFFILIATE_ACCOUNT_MATCHES_STRING_2: 'AffiliateAccountMatchesString2',
    AFFILIATE_ACCOUNT_MATCHES_STRING_3: 'AffiliateAccountMatchesString3',
    AFFILIATE_ACCOUNT_MATCHES_STRING_4: 'AffiliateAccountMatchesString4',
    AFFILIATE_ACCOUNT_MATCHES_STRING_5: 'AffiliateAccountMatchesString5',
    AFFILIATE_ACCOUNT_NO_MATCH: 'NoAffiliateAccountRef',

    accountResolutionMethods: ['AffiliateAccountMatchesUsername', 'AffiliateAccountMatchesString1', 'AffiliateAccountMatchesString2',
        'AffiliateAccountMatchesString3', 'AffiliateAccountMatchesString4', 'AffiliateAccountMatchesString5', 'NoAffiliateAccountRef'],

    /**
     * Get value
     */
    getValue: function (inputIndex) {
        var inputValue = this.accountResolutionMethods[inputIndex];
        var value = this.AFFILIATE_ACCOUNT_NO_MATCH;
        Object.keys(this).forEach((key) => {
            if (this[key] === inputValue) value = this[key];
        });
        return value;
    }
};

/**
 * Identity status type
 *
 * @type {Object}
 */
var UserIdentityStatusType = {
    STATUS_OK: 'OK',
    STATUS_MATCH: 'Match',
    STATUS_INTERNAL_ERROR: 'InternalError',
    STATUS_INVALID_INPUT: 'InvalidInput',
    STATUS_IP_LOOKUP_FAILED: 'IPLookupFailed',
    STATUS_PHONE_NUMBER_MISMATCH: 'PhoneNumberMismatch',
    STATUS_UNRECOGNISED_ERROR_CODE: 'UnrecognisedErrorCode',
    STATUS_NO_SUCH_CUSTOMER: 'NoSuchCustomer',
    STATUS_NO_QUALIFYING_PRODUCT: 'NoQualifyingProduct',
    STATUS_CUSTOMER_SUSPENDED: 'CustomerSuspended',
    STATUS_CUSTOMER_SUSPENDED_CEASE_ORDER_FOUND: 'CustomerSuspendedCeaseOrderFound',
    STATUS_STATUS_NOT_CHECKED: 'StatusNotChecked',
    STATUS_CUSTOMER_BARRED: 'CustomerBarred',
    STATUS_UNRECOGNIZED_SERVICE_RESPONSE: 'UnrecognisedServiceResponse',
    STATUS_UNKNOWN: 'Unknown',
    STATUS_TERMS_AND_CONDITIONS_NOT_ACCEPTED: 'TermsAndConditionsNotAccepted',
    STATUS_ACCOUNT_SUSPENDED: 'AccountSuspended',
    STATUS_FF_LOCK_OUT: 'FFLockOut',
    STATUS_NOT_APPROVED: 'NotApproved',
    STATUS_ACTIVE: 'Active',
    STATUS_INACTIVE: 'Inactive',
    STATUS_SUSPENDED: 'Suspended',
    STATUS_CLOSED: 'Closed',

    statusCodeIdentity: ['Match', 'InternalError', 'InvalidInput', 'IPLookupFailed', 'PhoneNumberMismatch', 'UnrecognisedErrorCode'],
    statusCodeTVOD: ['OK', 'InternalError', 'InvalidInput', 'NoSuchCustomer', 'NoQualifyingProduct', 'CustomerSuspended',
        'CustomerSuspendedCeaseOrderFound', 'StatusNotChecked', 'UnrecognisedErrorCode'],
    statusCodeSVOD: ['OK', 'CustomerBarred', 'NoQualifyingProduct', 'CustomerSuspended', 'InternalError', 'InvalidInput',
        'UnrecognisedServiceResponse', 'StatusNotChecked', 'UnrecognisedErrorCode'],
    statusCodeEST: ['OK', 'InternalError', 'InvalidInput', 'NoSuchCustomer', 'NoQualifyingProduct', 'CustomerSuspended',
        'CustomerSuspendedCeaseOrderFound', 'StatusNotChecked', 'UnrecognisedErrorCode'],
    statusCodePortal: ['Unknown', 'OK', 'TermsAndConditionsNotAccepted', 'AccountSuspended', 'FFLockOut', 'NotApproved'],
    statusCodeAffiliate: ['Unknown', 'Active', 'Inactive', 'Suspended', 'Closed'],

    /**
     * Get value
     */
    getValue: function (inputIndex, indexName) {
        var inputValue = this[indexName][inputIndex];
        var value = this.STATUS_UNKNOWN;
        Object.keys(this).forEach((key) => {
            if (this[key] === inputValue) value = this[key];
        });
        return value;
    }
};

export {UserIdentity as default, UserIdentity, AccountResolutionMethodType, UserIdentityStatusType};
