"use strict";

import Promise from 'bluebird';
import * as AppDispatcher from '../dispatchers/AppDispatcher';
import ActionTypes from './ActionTypes';
import ApiService from '../lib/api/ApiService';
import {MediaItemClassification} from '../types/MediaItem';

/**
 * Flux: EntitlementActions
 */
let EntitlementActions = {
    /**
     * Get current entitlements
     *
     * @param {Object} params
     */
    getCurrentEntitlements: (params = {}) => {
        let promise = ApiService.getCurrentEntitlements(params)
            .then(entitlementDTOCollection => ({entitlementDTOCollection}));

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_GET_CURRENT_ENTITLEMENTS,
            success: ActionTypes.REQUEST_GET_CURRENT_ENTITLEMENTS_SUCCESS,
            failure: ActionTypes.REQUEST_GET_CURRENT_ENTITLEMENTS_ERROR
        }, params);
    },

    /**
     * Get history entitlements
     *
     * @param params
     */
    getHistoryEntitlements: (params = {}) => {
        let promise = ApiService.getHistoryEntitlements(params)
            .then(entitlementDTOCollection => ({entitlementDTOCollection}));

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_GET_HISTORY_ENTITLEMENTS,
            success: ActionTypes.REQUEST_GET_HISTORY_ENTITLEMENTS_SUCCESS,
            failure: ActionTypes.REQUEST_GET_HISTORY_ENTITLEMENTS_ERROR
        }, params);
    },

    /**
     * Get recently viewed entitlements
     *
     * @param params
     */
    getRecentlyViewedItems: (params = {}) => {
        let promise = ApiService.getRecentlyViewedItems(params)
            .then(recentlyViewedItemDTOCollection => ({recentlyViewedItemDTOCollection}));

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_GET_RECENTLY_VIEWED_ITEMS,
            success: ActionTypes.REQUEST_GET_RECENTLY_VIEWED_ITEMS_SUCCESS,
            failure: ActionTypes.REQUEST_GET_RECENTLY_VIEWED_ITEMS_ERROR
        }, params);
    },

    /**
     * Get AMS license
     *
     * @param {Object} params
     */
    getAMSLicense: (params = {}) => {
        let promise = Promise.resolve(params.mediaItemClassification)
            .then(mediaItemClassification => {
                return mediaItemClassification === MediaItemClassification.EPISODE
                    ? ApiService.getMediaItem(params)
                        .then(mediaItemDTO => mediaItemDTO.Offers[0] ? mediaItemDTO.Offers[0].Id : 0)
                    : ApiService.getEntitlement(params)
                        .then(entitlementDTO => entitlementDTO.OfferId);
            })
            .then(offerId => {
                return ApiService.getAMSLicense({
                    ...params,
                    offerId: offerId
                })
                    .then(amsLicenceDTO => ({amsLicenceDTO}));
            });

        // Old method commented out until Vubiquity fixes offerId values in Entitlement responses. Used method is not
        // stable as active offers change on mediaItems, Offer[0] may be expired, or not connected with user's purchase
        // order
        //
        // let promise = ApiService.getEntitlement(params)
        //     .then(entitlementDTO => {
        //         return ApiService.getAMSLicense({
        //             ...params,
        //             offerId: entitlementDTO.OfferId
        //         })
        //             .then(amsLicenceDTO => ({amsLicenceDTO}));
        //     });

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_GET_AMS_LICENSE,
            success: ActionTypes.REQUEST_GET_AMS_LICENSE_SUCCESS,
            failure: ActionTypes.REQUEST_GET_AMS_LICENSE_ERROR
        }, params);
    },
    /**
     * Get Trailer DRM
     *
     * @param params
     */
    getTrailerLicense: (params = {}) => {
        let promise = ApiService.getTrailerLicense(params)
        .then(amsLicenceDTO => ({amsLicenceDTO}));

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_GET_TRAILER_LICENSE,
            success: ActionTypes.REQUEST_GET_TRAILER_LICENSE_SUCCESS,
            failure: ActionTypes.REQUEST_GET_TRAILER_LICENSE_ERROR
        }, params);
    },
    /**
     * Start mediaItem playback
     *
     * @param params
     */
    startMediaItemPlayback: (params = {}) => {
        AppDispatcher.dispatch(ActionTypes.START_MEDIA_ITEM_PLAYBACK, params);
    },

    /**
     * Stop mediaItem playback
     *
     * @param params
     */
    stopMediaItemPlayback: (params = {}) => {
        AppDispatcher.dispatch(ActionTypes.STOP_MEDIA_ITEM_PLAYBACK, params);
    },

    /**
     * Create playback log
     *
     * @param params
     */
    createPlaybackLog: (params = {}) => {
        let promise = ApiService.createPlaybackLog(params)
            .then(logWatchResponseDTO => ({logWatchResponseDTO}));

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_CREATE_PLAYBACK_LOG,
            success: ActionTypes.REQUEST_CREATE_PLAYBACK_LOG_SUCCESS,
            failure: ActionTypes.REQUEST_CREATE_PLAYBACK_LOG_ERROR
        }, params);
    },

    /**
     * Get registered devices
     *
     * @param params
     */
    getRegisteredDevices: (params = {}) => {
        let promise = ApiService.getRegisteredDevices(params)
            .catch(() => []) // fixes 404 status FilmFlex API issue
            .then(registeredDeviceDTOCollection => ({registeredDeviceDTOCollection}));

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_GET_REGISTERED_DEVICES,
            success: ActionTypes.REQUEST_GET_REGISTERED_DEVICES_SUCCESS,
            failure: ActionTypes.REQUEST_GET_REGISTERED_DEVICES_ERROR
        }, params);
    },

    /**
     * Add registered device
     *
     * @param params
     */
    registerDevice: (params = {}) => {
        let promise = ApiService.registerDevice(params)
            .then(response => response)
            .catch(error => Promise.reject(new Error(error)));

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_REGISTER_DEVICE,
            success: ActionTypes.REQUEST_REGISTER_DEVICE_SUCCESS,
            failure: ActionTypes.REQUEST_REGISTER_DEVICE_ERROR
        }, params);
    },
    /**
     * Remove registered device
     *
     * @param params
     */
    removeRegisteredDevice: (params = {}) => {
        let promise = ApiService.removeRegisteredDevice(params)
            .catch(body => Promise.reject(new Error(body)))
            .then(unregisterDeviceDTO => ({unregisterDeviceDTO}));

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_REMOVE_REGISTERED_DEVICE,
            success: ActionTypes.REQUEST_REMOVE_REGISTERED_DEVICE_SUCCESS,
            failure: ActionTypes.REQUEST_REMOVE_REGISTERED_DEVICE_ERROR
        }, params);
    },

    /**
     * Rename registered device
     *
     * @param params
     */
    renameRegisteredDevice: (params = {}) => {
        let promise = ApiService.renameRegisteredDevice(params)
            .catch(body => Promise.reject(new Error(body)))
            .then(unregisterDeviceDTO => ({unregisterDeviceDTO}));

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_CHANGE_REGISTERED_DEVICE_NAME,
            success: ActionTypes.REQUEST_CHANGE_REGISTERED_DEVICE_NAME_SUCCESS,
            failure: ActionTypes.REQUEST_CHANGE_REGISTERED_DEVICE_NAME_ERROR
        }, params);
    },
    /**
     * stb pairing process enabled
     */
    stbPairingProcessEnabled: () => {
        AppDispatcher.dispatch(ActionTypes.REQUEST_STB_PAIRING_PROCESS_ENABLED);
    },

    /**
     * stb pairing process disabled
     */
    stbPairingProcessDisabled: () => {
        AppDispatcher.dispatch(ActionTypes.REQUEST_STB_PAIRING_PROCESS_DISABLED);
    },

    /**
     * stb pairing process finished
     */
    stbPairingProcessFinished: () => {
        AppDispatcher.dispatch(ActionTypes.REQUEST_STB_PAIRING_PROCESS_FINISHED);
    },

    /**
     * Validate stb link code
     *
     * @param params
     */
    validateStbLinkCode: (params = {}) => {
        let promise = ApiService.validateStbLinkCode(params)
            .then(response => {
                if (response.errorType) {
                    return Promise.reject(new Error(response.errorType));
                }
                return response;
            })
            .catch((error) => Promise.reject(new Error(error)));


        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_VALIDATE_STB_CODE,
            success: ActionTypes.REQUEST_VALIDATE_STB_CODE_SUCCESS,
            failure: ActionTypes.REQUEST_VALIDATE_STB_CODE_ERROR
        }, params);
    },

    /**
     * Consume stb link code
     *
     * @param params
     */
    consumeStbLinkCode: (params = {}) => {
        let promise = ApiService.consumeStbLinkCode(params)
            .then(response => {
                if (response.errorType) {
                    return Promise.reject(new Error(response.errorType));
                }
                return response;
            })
            .catch((error) => Promise.reject(new Error(error)));

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_CONSUME_STB_CODE,
            success: ActionTypes.REQUEST_CONSUME_STB_CODE_SUCCESS,
            failure: ActionTypes.REQUEST_CONSUME_STB_CODE_ERROR
        }, params);
    },

    /**
     * Confirm stb registration
     * @param params
     */
    confirmStbRegistration: (params = {}) => {
        let promise = ApiService.confirmStbRegistration(params)
            .then(response => {
                return response;
            }).catch(error => Promise.reject(new Error(error)));

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_CONFIRM_STB_REGISTRATION,
            success: ActionTypes.REQUEST_CONFIRM_STB_REGISTRATION_SUCCESS,
            failure: ActionTypes.REQUEST_CONFIRM_STB_REGISTRATION_ERROR
        }, params);
    }
};

window.stopMediaItemPlayback = EntitlementActions.stopMediaItemPlayback;

export {EntitlementActions as default, EntitlementActions};
