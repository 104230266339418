"use strict";

import Immutable from 'immutable';

/**
 * ProtectionInfoDTO definition
 *
 * @typedef {Object} ProtectionInfoDTO
 * @property {?string} ProrectionType
 * @property {?string} ProtectionType
 * @property {?string} CertificateUrl
 * @property {?string} AuthenticationToken
 */

/**
 * AMSLicenseKey definition
 *
 * @typedef {Immutable.Record} AMSLicenseKey
 * @property {?string} protectionType
 * @property {?string} certificateUrl
 * @property {?string} authenticationToken
 * @property {?string} laurl
 */

/**
 * AMSLicenseKey record
 *
 * @type {AMSLicenseKey}
 */
class AMSLicenseKey extends Immutable.Record({
    protectionType: null,
    certificateUrl: null,
    authenticationToken: null,
    laurl:null
}) {
    /**
     * Set values from data transfer object
     *
     * @param {ProtectionInfoDTO} dataTransferObject
     * @return {AMSLicenseKey}
     */
    fromDTO(dataTransferObject) {
        return new AMSLicenseKey({
            ...this.toJS(),
            protectionType: typeof dataTransferObject.ProtectionType !== 'undefined' ? dataTransferObject.ProtectionType :
                (typeof dataTransferObject.ProrectionType !== 'undefined' ? dataTransferObject.ProrectionType : this.protectionType),
            certificateUrl: typeof dataTransferObject.CertificateUrl !== 'undefined' ? dataTransferObject.CertificateUrl : this.certificateUrl,
            authenticationToken: typeof dataTransferObject.AuthenticationToken !== 'undefined' ? dataTransferObject.AuthenticationToken : this.authenticationToken,
            laurl:typeof dataTransferObject.laurl !== 'undefined' ? dataTransferObject.laurl : this.laurl
        });
    }
}

export {AMSLicenseKey as default, AMSLicenseKey};
