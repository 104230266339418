"use strict";

import React from 'react';
import PropTypes from 'prop-types';

/**
 * ProgressBarProps definition
 *
 * @typedef {Object} ProgressBarProps
 * @property {?number} progress
 * @property {?string} className
 */

/**
 * ProgressBar component
 *
 * @param {ProgressBarProps} props
 * @constructor
 */
const ProgressBar = (props) => {
    return (
        <div className={"ui-progress-bar " + (props.className || "")}>
            <div className="progress"
                 style={{width: (props.progress ? props.progress : 0) + '%'}}></div>
        </div>
    );
};

ProgressBar.propTypes = {
    progress: PropTypes.number,
    className: PropTypes.string
};

export default ProgressBar;
