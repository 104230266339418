"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import ReCAPTCHA from 'react-google-recaptcha';
import Config from '../lib/Config';
import Router from '../lib/Router';
import AppStore from '../stores/AppStore';
import EntitlementStore from '../stores/EntitlementStore';
import NotificationActions from '../actions/NotificationActions';
import AppActions from '../actions/AppActions';
import AuthActions from '../actions/AuthActions';
import AuthStore from '../stores/AuthStore';
import Button from './ui/Button.react';
import AnalyticsActions from '../actions/AnalyticsActions';
import ToggleButton from './ui/ToggleButton.react';

/**
 * AccountSignUpPage component
 */
export default class AccountSignUpPage extends React.Component {
    /**
     * React: state
     */
    state = {
        fullName: '',
        fullNameIsValid: false,
        fullNameIsTouched: false,
        email: '',
        emailIsValid: false,
        emailIsTouched: false,
        password: '',
        showPassword: false,
        passwordIsTouched: false,
        passwordIsStrong: false,
        reCaptchaCompleted: !Config.RE_CAPTCHA_SITE_KEY,
        newsletterOptedIn: true,
        updatePending: false
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        AuthStore.addUpdateListener(this._setFormStatus);
        document.body.className += " black-background";
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AuthStore.removeListener(AuthStore.STORE_UPDATED, this._setFormStatus);
        this.setState({updatePending: false});

        document.body.className = document.body.className.replace(/black-background/, '');
    }

    /**
     * React: render
     */
    render() {

        return (
            <main className="account-page">
                <h4 className="account-page-title">Create a Store account</h4>
                <div className="container">
                    <div className="row">
                    <div className="account-page-content signup-page-content col-xs-12 col-sm-10 col-md-8 col-md-5 col-lg-4">
                        <form onSubmit={this._signUpUser}>
                            <span className="input-label">
                                {AppStore.translate('field.label.full_name')}</span>
                                <input type="text"
                                       className={`simple-input ${this.state.fullNameIsTouched
                                        //    && this.state.fullName.length > 0
                                           && !this.state.fullNameIsValid ? 'simple-input-error' : ''}`}
                                       placeholder={AppStore.translate('field.placeholder.full_name')}
                                       value={this.state.fullName}
                                       disabled={this.state.updatePending}
                                       autoComplete="name"
                                       onBlur={this._onFocusChange.bind(null, 'fullName')}
                                       onChange={this._onFieldChange.bind(null, 'fullName')}/>

                            {this.state.fullNameIsTouched && !this.state.fullNameIsValid ? <span className="input-error-message">
                                {AppStore.translate('field.error.fullname-confirm-not-valid')}</span>:null}

                            <span className="input-label">
                                {AppStore.translate('field.label.email')}</span>
                                <input type="email"
                                       className={`simple-input simple-input-retype${this.state.emailIsTouched
                                        //    && this.state.email.length > 0
                                           && !this.state.emailIsValid ? ' simple-input-error' : ''}`}
                                       placeholder={AppStore.translate('field.placeholder.email')}
                                       value={this.state.email}
                                       disabled={this.state.updatePending}
                                       autoComplete="username"
                                       onBlur={this._onFocusChange.bind(null, 'email')}
                                       onChange={this._onFieldChange.bind(null, 'email')}/>

                            {this.state.emailIsTouched && !this.state.emailIsValid ? <span className="input-error-message">
                                {AppStore.translate('field.error.email-not-valid')}</span>:null}

                                <div className="input-label-container">
                                    <span className="input-label">
                                        {AppStore.translate('field.label.new-password-signup')}</span>
                                    <a className="input-label-password-state" onClick={this._showHidePassword}>
                                        {this.state.showPassword ? 'Hide' : 'Show'}</a>
                                </div>

                                <input type={this.state.showPassword ? "text" : "password" }
                                       className={`simple-input simple-input-retype${this.state.passwordIsTouched
                                        //    && this.state.password.length > 0
                                           && !this.state.passwordIsStrong ? ' simple-input-error' : ''}`}
                                       placeholder={AppStore.translate('field.placeholder.new-password-signup')}
                                       value={this.state.password}
                                       disabled={this.state.updatePending}
                                       autoComplete="new-password"
                                       onBlur={this._onFocusChange.bind(null, 'password')}
                                       onChange={this._onFieldChange.bind(null, 'password')}/>

                                    {this.state.passwordIsTouched && !this.state.passwordIsStrong
                                        ? <span className="input-error-message">
                                        {AppStore.translate('field.error.password-not-strong')}</span>
                                    : null
                                    }
                                <span className="signup-page-password-info-text">
                                    Rules: 8 characters or more with at least one upper case letter, one lower case letter and one number.
                                </span>

                            {Config.RE_CAPTCHA_SITE_KEY ? (
                                <div className="recaptcha-wrapper">
                                    <ReCAPTCHA ref="recaptcha"
                                               sitekey={Config.RE_CAPTCHA_SITE_KEY}
                                               onChange={this._toggleReCaptchaCompleted}
                                               onExpired={this._toggleReCaptchaExpired}
                                               theme="dark"/>
                                </div>
                            ) : null}

                                <div className="signup-gdpr-button-container">
                                    <div className="signup-gdpr-button">
                                        <ToggleButton
                                            name="gdpr-button"
                                            isChecked={this.state.newsletterOptedIn}
                                            isDisabled={this.state.updatePending}
                                            onClick={this._onGdprButtonToggle}
                                        />
                                    </div>
                                    <div className="signup-display-account-info">
                                        <span className="signup-gdpr-info-message">
                                            {AppStore.translate('view.my-account_page_gdpr_button_info_text')}
                                        </span>
                                    </div>
                                </div>

                            <Button type="submit"
                                    shape="square"
                                    className="cta-play full-width"
                                    disabled={this.state.updatePending
                                    || !this.state.fullNameIsValid
                                    || !this.state.emailIsValid
                                    || this.state.email.length < 4
                                    || !this.state.passwordIsStrong
                                    || this.state.password.length < 8
                                    || !this.state.reCaptchaCompleted}
                                    updatePending={this.state.updatePending}>
                                {AppStore.translate('button.signup')}
                            </Button>
                        </form>

                        <div className="label-for-centered-custom-checkbox">
                            <p className="terms-conditions-annotation-grey">
                                {AppStore.translate('view.account_page.message.accept_terms_and_conditions')} <Link onClick={()=>{}} to={Router.TERMS_AND_CONDITIONS} target="_blank">{AppStore.translate('view.account_page.message.accept_terms_and_conditions_link_text')}</Link>
                                {AppStore.translate('view.account_page.message.accept_privacy_policy_text')}
                                    <Link onClick={() => {}} to={Router.PRIVACY} target="_blank"> {AppStore.translate('view.account_page.message.accept_policy_link_text')}</Link>
                            </p>
                        </div>

                            <div className="account-page-link-container signup-page-account-page-link-container">
                            <span>Already registered? </span>
                            <Link onClick={this._alreadyHaveAccount}
                                to={Router.SIGN_IN}>{AppStore.translate('view.navigation.label.sign_in')}</Link>
                                <p>{AppStore.translate('view.navigation.label.sign_up_special_note')}</p>
                        </div>
                    </div>
                </div>
                </div>
            </main>
        );
    }

    /**
     * On field change
     *
     * @param {string} fieldName
     * @param {Object} event
     * @private
     */
    _onFieldChange = (fieldName, event) => {
        this.setState({
            [fieldName]: event.target.value
        });
    };

    /**
     * On field change
     *
     * @param {string} fieldName
     * @private
     */
    _onFocusChange = (fieldName) => {
        switch(fieldName) {
            case 'fullName': this._checkFieldValidity('fullName', this.state.fullName);
                break;
            case 'email': this._checkFieldValidity('email', this.state.email);
                break;
            case 'password': this._checkFieldValidity('password', this.state.password);
                break;
            default: //no-op;
        }
    };

    /**
     * Handle Paste
     *
     * @param {string} fieldName
     * @param {Object} event
     * @private
     */
    _handlePaste = (fieldName, event) => {
        event.preventDefault(event);
        return false;
    };

    /**
     * Handle alreadyHaveAccount click
     *
     * @private
     */
    _alreadyHaveAccount = () => {
        event.preventDefault(event);
        AnalyticsActions.trackEvent({
            category: 'Registration',
            action: 'Sign Up',
            label: 'Sign In'
        });
    };

    /**
     * show hide password
     *
     * @private
     */
    _showHidePassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        }, AnalyticsActions.trackEvent({
            category: 'Registration',
            action: 'Sign Up',
            label: this.state.showPassword ? 'Show Password' : 'Hide Password'
        }));
    };

    /**
     * Gdpr button click event
     * @private
     */
    _onGdprButtonToggle = () => {
        this.setState({
            newsletterOptedIn: !this.state.newsletterOptedIn
        }, () => {
            if (this.state.newsletterOptedIn) {
                AnalyticsActions.trackEvent({
                    category: 'Registration',
                    action: 'Sign Up',
                    label: 'GDPR'
                })
            }
        });
    }
    /**
     * On field change
     *
     * @param {string} fieldName
     * @param {Object} value
     * @private
     */
    _checkFieldValidity = (fieldName, value) => {
        //event.preventDefault(event);
        if (fieldName === 'fullName') {
            const regExPasswordRule = /^[a-zA-Z0-9\*!_\s]+$/gi;
            this.setState({
                fullNameIsValid: regExPasswordRule.test(value),
                fullNameIsTouched: true
            });
        }
        if (fieldName === 'email') {
            const regExRule = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            this.setState({
                emailIsValid: regExRule.test(value),
                emailIsTouched: true
            });

            !regExRule.test(value) && AnalyticsActions.trackEvent({
                category: 'Registration',
                action: 'Sign Up',
                label: "Email Invalid"
            });
        }
        if (fieldName === 'password') {
            const regExPasswordRule = /^(?=.*?[A-Z+])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
            this.setState({
                passwordIsStrong: regExPasswordRule.test(value),
                passwordIsTouched: true
            });

            !regExPasswordRule.test(value) && AnalyticsActions.trackEvent({
                category: 'Registration',
                action: 'Sign Up',
                label: "Password Invalid"
            });
        }
    };

    /**
     * Toggle reCaptcha completed
     *
     * @private
     */
    _toggleReCaptchaCompleted = () => {
        this.setState({
            reCaptchaCompleted: true
        }, !this.state.reCaptchaCompleted && AnalyticsActions.trackEvent({
            category: 'Registration',
            action: 'Sign Up',
            label: "Captcha"
        }));
    };

    /**
     * Toggle reCaptcha expired
     *
     * @private
     */
    _toggleReCaptchaExpired = () => {
        this.setState({
            reCaptchaCompleted: false
        });
    };
    /**
     * Sign up user
     *
     * @param {Object} event
     * @private
     */
    _signUpUser = (event) => {
        let params = {};
        event.preventDefault();
        NotificationActions.hideNotification();

        params = {
            ...params,
            cd1: 'Sign In Only User'
        };
        AnalyticsActions.trackEvent({
            category: 'Registration',
            action: 'Sign Up',
            label: 'Signed Up',
            params
        });
        if (!this.state.fullName
            || !this.state.email
            || !this.state.password
        ) {
            NotificationActions.displayNotification({
                notificationType: 'error',
                content: AppStore.translate('notification.account.error.sign_up_form_incomplete')
            });
            return;
        }

        this.setState({
            updatePending: true
        }, () => {
            NotificationActions.hideNotification();
            AppActions.storeRouterTransitionPath(Router.HOME);

            // redirect user to set payment method page if coming from stb pairing (fromProductPurchase == true)
            if (EntitlementStore.stbPairingData.get("fromProductPurchase")) {
                AppActions.storeRouterTransitionPath(Router.UPDATE_ACCOUNT);
            }
            AuthActions.signUpUserThenAutoSignIn({
                fullName: this.state.fullName,
                email: this.state.email,
                username: this.state.email,
                password: this.state.password,
                acceptTermsAndConditions: true,
                newsletterOptedIn: this.state.newsletterOptedIn
            });
        });
    };

    /**
     * Set form status
     */
    _setFormStatus = () => {
        this.setState({
            updatePending: this.state.updatePending && !AuthStore.updatePending ? false : this.state.updatePending
        }, () => {
            if (!this.state.updatePending && AuthStore.error) {
                AppStore.nextTransitionPath; // remove next step path if failed
            }
        });
    };
}
