"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import store from 'store';
import moment from 'moment';
import Router from '../lib/Router';
import Config from '../lib/Config';
import AppStore from '../stores/AppStore';
import EntitlementStore from '../stores/EntitlementStore';
import AppActions from '../actions/AppActions';
import UIActions from '../actions/UIActions';
import AuthActions from '../actions/AuthActions';
import NotificationActions from '../actions/NotificationActions';
import AuthStore from '../stores/AuthStore';
import Button from './ui/Button.react';
import IconButtonWithBubblePop from './ui/IconButtonWithBubblePop.react';
import Spinner from './ui/Spinner2.react';
import TextInput from './ui/TextInput.react';
import PaymentCardInput from './ui/PaymentCardInput.react';
import AnalyticsActions from '../actions/AnalyticsActions';

/**
 * AccountSetPaymentMethodPage component
 */
export default class AccountSetPaymentMethodPage extends React.Component {
    /**
     * React: state
     */
    state = {
        cardName: '',
        cardNumber: '',
        cardCVC: '',
        cardExpiryMonth: parseInt(moment().format('M')),
        cardExpiryYear: parseInt(moment().format('YYYY')),
        customerPaymentDetails: null,
        paymentMethodUpdatePending: false,
        updatePending: false,
        isInfoBoxOpen: false
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        let userProfile = AuthStore.userProfile;
        if (userProfile.paymentProviderCustomerIds && userProfile.paymentProviderCustomerIds.stripe) {
            AuthActions.getCustomerPaymentDetails();
            AuthStore.addUpdateListener(this._setCreditCardInformation);
        }
        document.body.className += " black-background";
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AuthStore.removeListener(AuthStore.STORE_UPDATED, this._setFormStatus);
        AuthStore.removeListener(AuthStore.STORE_UPDATED, this._setCreditCardInformation);
        this.setState({
            updatePending: false,
            paymentMethodUpdatePending: false,
            customerPaymentDetails: null
        });

        document.body.className = document.body.className.replace(/black-background/,'');
    }

    /**
     * React: render
     *
     */
    render() {
        const userProfile = AuthStore.userProfile;

        const popupInfoText = (
            <div>
                <p>You only pay for the movie or TV show you want to watch.</p>
                <p>Store purchases do not appear on your Virgin Media bill.</p>
                <p>We need your debit or credit card details to take payment for your purchases.</p>
                <p>Once you save your payment details online you can use your TV PIN to confirm purchases on your Virgin TV.</p>
                <p>You can change your payment details anytime by going to My Account.</p>
            </div>);

        return userProfile ? (
            <main className="account-page payment-header">
                <h4 className="account-page-title">{AppStore.translate('view.account_page_set_payment.title_text')}</h4>
                <div className="container">
                    <div className="row">
                        <div className="account-page-content col-xs-12 col-lg-4 col-sm-8 col-md-5">
                            <div className="set-payment-container">
                                {userProfile.paymentProviderCustomerIds && userProfile.paymentProviderCustomerIds.stripe && !this.state.customerPaymentDetails ? (
                                    <Spinner />
                                ) : (
                                    <form onSubmit={this._setPaymentMethod}>
                                        <IconButtonWithBubblePop
                                            icon="icon-ic-info"
                                            infoTitle="Info"
                                            infoContent={popupInfoText}
                                        />
                                        <TextInput
                                            label={AppStore.translate('view.payment_page.card.name')}
                                            value={this.state.cardName}
                                            disabled={this.state.updatePending}
                                            placeholder={AppStore.translate('view.account_page_set_payment.card_name_placeholder')}
                                            inline={false}
                                            onChange={this._onFormFieldChange.bind(null, 'cardName')}/>
                                        <PaymentCardInput
                                            label={AppStore.translate('view.payment_page.card.number')}
                                            value={this.state.cardNumber}
                                            disabled={this.state.updatePending}
                                            inline={false}
                                            inlineLabel={false}
                                            onChange={this._onFormFieldChange.bind(null, 'cardNumber')}/>
                                        <p className="terms-conditions-annotation info-text">
                                            {AppStore.translate('view.account_page_set_payment.payment_card_types_info')}
                                        </p>
                                        <div className="date-ccv-wrapper">
                                            <div className="ui-drop-down-wrapper ui-input">
                                                <label>{AppStore.translate('view.payment_page.card.expiry_date')}</label>

                                                <div className="card-exp-date-container">
                                                    <div className="ui-drop-down small-width">
                                                        <select className="drop-down-select"
                                                                disabled={this.state.updatePending}
                                                                value={this.state.cardExpiryMonth}
                                                                onChange={this._onFieldChange.bind(null, 'cardExpiryMonth')}>
                                                            <option value={1}>01</option>
                                                            <option value={2}>02</option>
                                                            <option value={3}>03</option>
                                                            <option value={4}>04</option>
                                                            <option value={5}>05</option>
                                                            <option value={6}>06</option>
                                                            <option value={7}>07</option>
                                                            <option value={8}>08</option>
                                                            <option value={9}>09</option>
                                                            <option value={10}>10</option>
                                                            <option value={11}>11</option>
                                                            <option value={12}>12</option>
                                                        </select>
                                                    </div>

                                                    <div className="ui-drop-down small-width">
                                                        <select className="drop-down-select"
                                                                value={this.state.cardExpiryYear}
                                                                disabled={this.state.updatePending}
                                                                onChange={this._onFieldChange.bind(null, 'cardExpiryYear')}>
                                                            {[...new Array(Config.NUMBER_OF_YEARS + parseInt(moment().format('Y')) - Config.START_YEAR)].map((x, i) => {
                                                                let optionYear = Config.START_YEAR + i;
                                                                return (
                                                                    <option key={i} value={optionYear}>
                                                                        {optionYear}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="cvv-container">
                                                <TextInput
                                                    inputClassName="small-width cvv-input"
                                                    label={AppStore.translate('view.payment_page.card.cvc')}
                                                    placeholder={AppStore.translate('view.account_page_set_payment.cvv_placeholder')}
                                                    value={this.state.cardCVC}
                                                    disabled={this.state.updatePending}
                                                    onChange={this._onFormFieldChange.bind(null, 'cardCVC')}/>
                                                <span onClick={!this.state.updatePending ? this._showCVVInfo : null}
                                                      className={`cvv-info-link payment-cvv this.state.updatePending ? 'disabled' : ''`}>
                                                    {AppStore.translate('view.payment_page.card.what_is_cvc')}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="cta-buttons-container center-button">
                                            <Button type="submit"
                                                    shape="square"
                                                    className="cta-play full-width"
                                                    disabled={this.state.updatePending
                                                        || this.state.paymentMethodUpdatePending
                                                        || this._formIsIncomplete()}
                                                    updatePending={this.state.paymentMethodUpdatePending}>
                                                {userProfile.paymentProviderCustomerIds && userProfile.paymentProviderCustomerIds.stripe
                                                    ? AppStore.translate('button.change')
                                                    : AppStore.translate('button.add_payment_details')}
                                            </Button>
                                        </div>
                                        <div className="ui-checkbox">
                                            <p className={`terms-conditions-annotation`}>
                                                {AppStore.translate('view.account_page.message.payment_agreement')}
                                            </p>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        ) : null;
    }

    /**
     * Track GA Analytics event
     *
     * @private
     */
    _trackAnalyticsEvent = (category, action, label) => {
        AnalyticsActions.trackEvent({
            category,
            action,
            label
        });
    };

    /**
     * Set credit card details
     */
    _setCreditCardInformation = () => {
        if (AuthStore.customerPaymentDetails !== null) {
            let card = AuthStore.customerPaymentDetails.defaultCard;
            this.setState({
                cardName: card.cardHolderName,
                cardNumber: '************' + card.lastFourDigits,
                cardCVC: '',
                cardExpiryMonth: card.expiryMonth,
                cardExpiryYear: card.expiryYear,
                customerPaymentDetails: AuthStore.customerPaymentDetails
            });
        }
        AuthStore.removeListener(AuthStore.STORE_UPDATED, this._setCreditCardInformation);
    };

    /**
     * On field change
     *
     * @param {string} fieldName
     * @param {*} value
     * @private
     */
    _onFormFieldChange = (fieldName, value) => {
        let newState = {};
        newState[fieldName] = value;
        this.setState(newState);
    };

    /**
     * On field change
     *
     * @param {string} fieldName
     * @param {Object} event
     * @private
     */
    _onFieldChange = (fieldName, event) => {
        let newState = {};
        newState[fieldName] = event.target.value;
        this.setState(newState);
    };

    /**
     * Returns if all some form field is not completed
     *
     * @private
     */
    _formIsIncomplete = () => {
        return !this.state.cardName
            || !this.state.cardNumber
            || !this.state.cardCVC
            || !this.state.cardExpiryYear
            || !this.state.cardExpiryMonth;
    };

    /**
     * Set payment method
     *
     * @param {Object} event
     * @private
     */
    _setPaymentMethod = (event) => {
        let params = {};
        event.preventDefault();
        NotificationActions.hideNotification();

        params = {
            ...params,
            cd2: 'Sign In Full User'
        };

        AnalyticsActions.trackEvent({
            category: 'Registration',
            action: 'Sign Up - Step 2',
            label: 'Add Payment Details',
            params
        });

        if (this._formIsIncomplete()) {
            NotificationActions.displayNotification({
                notificationType: 'error',
                content: AppStore.translate('notification.account.error.payment_method_form_incomplete')
            });
            return;
        }

        if (this.state.cardNumber.indexOf('*') > -1) {
            NotificationActions.displayNotification({
                notificationType: 'error',
                content: AppStore.translate('notification.account.error.payment_method_form_card_number_wrong')
            });
            return;
        }

        let userProfile = AuthStore.userProfile;
        let actionParams = {
            cardName: this.state.cardName,
            cardNumber: this.state.cardNumber,
            cardCVC: this.state.cardCVC,
            cardExpiryYear: this.state.cardExpiryYear,
            cardExpiryMonth: this.state.cardExpiryMonth,
            email: userProfile.email
        };

        const isPurchaseStarted = store.get('purchase_started');
        const purchasePath = store.get('purchase_path');
        if (isPurchaseStarted === 'YES') {
            AppActions.storeRouterTransitionPath(purchasePath);
            store.set("purchase_path", "");
            store.set("purchase_started", "");
        } else {
            AppActions.storeRouterTransitionPath(Router.HOME);
        }
        if (userProfile.newsletterOptedIn) {
            AppActions.storeRouterTransitionPath(Router.HOME);
        }

        AuthStore.addUpdateListener(this._setFormStatus);
        if (userProfile.paymentProviderCustomerIds && userProfile.paymentProviderCustomerIds.stripe) {
            AuthActions.updatePaymentMethod(actionParams);
        } else {
            isPurchaseStarted !== 'YES' && AppActions.storeRouterTransitionPath(Router.HOME);
            AuthActions.addPaymentMethod(actionParams);
        }
        if (EntitlementStore.stbPairingData.size > 0) {
            AppActions.storeRouterTransitionPath('/');
        }
    };

    /**
     * Set form status
     *
     * @private
     */
    _setFormStatus = () => {
        this.setState({
            paymentMethodUpdatePending: AuthStore.updatePending,
            updatePending: AuthStore.updatePending
        }, () => {
            if (!this.state.paymentMethodUpdatePending) {
                AuthStore.removeListener(AuthStore.STORE_UPDATED, this._setFormStatus);
                if (!AuthStore.error) {
                    setTimeout(() => {
                        this.context.router.push(AppStore.nextTransitionPath);
                    }, 0);
                }
            }
        });
    };

    /**
     * Show CVV info
     *
     * @private
     */
    _showCVVInfo = () => {
        AnalyticsActions.trackEvent({
            category: 'Registration',
            action: 'Sign Up - Step 2',
            label: 'Whats CSV'
        });
        UIActions.showCustomOverlay((
            <div className="alert-box-size col-xs-12 col-sm-8 col-md-6">
                <h2 className="overlay-header">
                    {AppStore.translate('view.payment_page.card.what_is_cvc')}
                    <button className="close" onClick={UIActions.closeCustomOverlay}/>
                </h2>
                <div className="overlay-content">
                    <p>{AppStore.translate('view.payment_page.card.cvc_explanation')}</p>
                </div>
            </div>
        ), false);
    };
}
