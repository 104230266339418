"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import Router from '../lib/Router';
import AppStore from '../stores/AppStore';
import AppActions from '../actions/AppActions';
import AuthActions from '../actions/AuthActions';
import AuthStore from '../stores/AuthStore';
import Button from './ui/Button.react';
import AnalyticsActions from '../actions/AnalyticsActions';

/**
 * AccountSetPaymentMethodPage component
 */
export default class AccountMarketingOptInPage extends React.Component {
    /**
     * React: state
     */
    state = {
        newsletterOptedIn: false
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        AuthActions.getUserPreferences({
            authToken: AuthStore.identity.authToken
        });

        document.body.className += " black-background";
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {

        document.body.className = document.body.className.replace(/black-background/,'');
    }

    /**
     * React: render
     */
    render() {
        let userProfile = AuthStore.userProfile;
        const termsAndConditionsLink = Router.TERMS_AND_CONDITIONS;
        const gdprLink = AppStore.translate('gdpr_info_link');

        const gdprParagraphText = AppStore.translate('view.account_page.message.gdpr.paragraph_text', {
            termsAndConditionsLink,
            gdprLink
        });

        return userProfile ? (
            <main className="account-page payment-header">
                <h4 className="account-page-title">Sign Up</h4>
                <div className="container">
                    <div className="row">
                        <div className="account-page-content col-xs-12 col-sm-8 col-md-4">
                            <div className="page-title-wrapper">
                                <h3>{AppStore.translate('view.account_marketing_opt_in.title_text')}</h3>
                                <p className="account-page-intro-text">{AppStore.translate('view.account_marketing_opt_in.subtitle_text')}</p>
                            </div>
                            <form onSubmit={this._updatePreferences}>
                                <div className="accept-terms-conditions-container ui-checkbox">
                                    <div className="centered-custom-checkbox">
                                        <input type="checkbox"
                                               id="newsletter"
                                            //disabled={this.state.updatePending || !this.state.termsAndConditionsRead}
                                               disabled={false}
                                               checked={this.state.newsletterOptedIn}
                                               onClick={this._toggleNewsletterOptedIn}/>
                                        <label className={`${this.state.newsletterOptedIn ? 'label-checked' : ''}`} htmlFor="newsletter">
                                        </label>
                                    </div>
                                    <div className="label-for-centered-custom-checkbox">
                                        <p className="terms-conditions-annotation">
                                            {AppStore.translate('view.account_page.message.newsletter_opt_in.text')}
                                        </p>
                                    </div>
                                </div>
                                <p className="terms-conditions-annotation info-text">
                                    {AppStore.translate('view.account_page.message.newsletter_opt_in.text_2')}
                                </p>
                                <p className="terms-conditions-annotation info-text"
                                    onClick={this._trackAnalyticsEvent('Registration', 'Sign Up - Step 3', 'Store Terms OR GDPR')}
                                    dangerouslySetInnerHTML={{__html: gdprParagraphText}}>
                                </p>
                                <div className="cta-buttons-container center-button">
                                    <Button type="submit"
                                            shape="square"
                                            className="cta-play full-width"
                                            disabled={this.state.updatePending}
                                            updatePending={this.state.updatePending}>
                                        {AppStore.translate('button.save_contact_preferences')}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        ) : null;
    }

    /**
     * Track GA Analytics event
     *
     * @private
     */
    _trackAnalyticsEvent = (category, action, label) => {
        AnalyticsActions.trackEvent({
            category,
            action,
            label
        });
    };

    /**
     * Toggle newsletter opt in
     *
     * @private
     */
    _toggleNewsletterOptedIn = () => {
        this.setState({
            newsletterOptedIn: !this.state.newsletterOptedIn
        });
    };

    /**
     * update user preferences
     *
     * @param {Object} event
     * @private
     */
    _updatePreferences = (event) => {
        event.preventDefault();
        AppActions.storeRouterTransitionPath(Router.HOME);
        AuthActions.updateUserPreferences({
            authToken: AuthStore.identity.authToken,
            newsletterOptedIn: this.state.newsletterOptedIn});
        if (!this.state.updatePending && !AuthStore.error) {
            setTimeout(() => {
                this.context.router.push(AppStore.nextTransitionPath);
            }, 0);
        }
    };

    _onFormFieldChange = (fieldName, value) => {
        var newState = {};
        newState[fieldName] = value;
        this.setState(newState);
    };

    /**
     * On field change
     *
     * @param {string} fieldName
     * @param {Object} event
     * @private
     */
    _onFieldChange = (fieldName, event) => {
        var newState = {};
        newState[fieldName] = event.target.value;
        this.setState(newState);
    };

    /**
     * Set form status
     *
     * @private
     */
    _setFormStatus = () => {
        this.setState({
            updatePending: this.state.updatePending && !AuthStore.updatePending ? false : this.state.updatePending
        }, () => {
            if (!this.state.updatePending && !AuthStore.error) {
                setTimeout(() => {
                    this.context.router.push(AppStore.nextTransitionPath);
                }, 0);
            }
        });
    };

}
