"use strict";

import Promise from 'bluebird';
import * as AppDispatcher from '../dispatchers/AppDispatcher';
import ActionTypes from './ActionTypes';
import ApiService from '../lib/api/ApiService';

/**
 * Flux: PurchaseActions
 */
let PurchaseActions = {
    /**
     * Add mediaItem offer to bundle shopping cart
     *
     * @param {Object} params
     */
    addMediaItemOfferToBundleShoppingCart: (params = {}) => {
        AppDispatcher.dispatch(ActionTypes.ADD_MEDIA_ITEM_OFFER_TO_BUNDLE_SHOPPING_CART, params);

        if (params.isShoppingCartFull) {
            let promise =
                // ApiService.removeAllBasketItems()
                ApiService.getBasketItems()
                    .then((basketItemDTOCollection) => {
                        return Promise.map(
                            basketItemDTOCollection,
                            (basketItemDTO) => {
                                return ApiService.removeBasketItem({
                                    basketItemId: basketItemDTO.BasketItemId
                                });
                            }
                        );
                    })
                    .then(() => {
                        return Promise.map(
                            params.mediaItemOffers,
                            ({offerId, mediaItemId}) => ApiService.addMediaItemOfferToBasket({offerId, mediaItemId})
                        );
                    })
                    .then(() => {
                        return ApiService.validateBundleOffer(params)
                            .then(validateBundleOfferResultDTO => ({
                                amountDue: validateBundleOfferResultDTO.BasketPrice,
                                currencyCode: validateBundleOfferResultDTO.BasketCurrencyCode || params.currencyCode // solves missing API data
                            }));
                    });

            AppDispatcher.dispatchAsync(promise, {
                request: ActionTypes.REQUEST_GET_BUNDLE_SHOPPING_CART_VALUE,
                success: ActionTypes.REQUEST_GET_BUNDLE_SHOPPING_CART_VALUE_SUCCESS,
                failure: ActionTypes.REQUEST_GET_BUNDLE_SHOPPING_CART_VALUE_ERROR
            }, params);
        }
    },

    /**
     * Remove mediaItem offer from bundle shopping cart
     *
     * @param {Object} params
     */
    removeMediaItemOfferFromBundleShoppingCart: (params = {}) => {
        AppDispatcher.dispatch(ActionTypes.REMOVE_MEDIA_ITEM_OFFER_FROM_BUNDLE_SHOPPING_CART, params);
    },

    /**
     * Fast checkout mediaItem offer
     *
     * starts checkout process by:
     * - removing any items in basket
     * - adding mediaItem offer to the basket
     * - loading customer payment details
     *
     * @param {Object} params
     */
    fastCheckoutMediaItemOffer: (params = {}) => {
        let promise =
            // ApiService.removeAllBasketItems()
            ApiService.getBasketItems()
                .then((basketItemDTOCollection) => {
                    return Promise.map(
                        basketItemDTOCollection,
                        (basketItemDTO) => {
                            return ApiService.removeBasketItem({
                                basketItemId: basketItemDTO.BasketItemId
                            });
                        }
                    );
                })
                .then(() => {
                    return Promise.all([
                        ApiService.addMediaItemOfferToBasket(params),
                        ApiService.getMediaItem(params)
                            .then(ApiService.generateMediaItemGraphBucketPayload),
                        ApiService.getCustomerPaymentDetails(params)
                    ]);
                })
                .then((results) => {
                    return {
                        basketItemDTO: results[0],
                        ...results[1],
                        customerPaymentDetailsDTO: results[2]
                    };
                });

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_FAST_CHECKOUT_MEDIA_ITEM_OFFER,
            success: ActionTypes.REQUEST_FAST_CHECKOUT_MEDIA_ITEM_OFFER_SUCCESS,
            failure: ActionTypes.REQUEST_FAST_CHECKOUT_MEDIA_ITEM_OFFER_ERROR
        }, params);
    },

    /**
     * Fast checkout bundleOffer
     *
     * starts checkout process by:
     * - removing any items in basket
     * - adding selected mediaItem offers from bundle to the basket
     * - validating bundle offer & loading price data
     * - loading customer payment details
     *
     * @param {Object} params
     * @param {Array<Object>} params.mediaItemOffers
     * @param {string} params.currencyCode
     */
    fastCheckoutBundleOffer: (params = {}) => {
        params = {
            mediaItemOffers: [],
            ...params
        };

        let promise =
            // ApiService.removeAllBasketItems()
            ApiService.getBasketItems()
                .then((basketItemDTOCollection) => {
                    return Promise.map(
                        basketItemDTOCollection,
                        (basketItemDTO) => {
                            return ApiService.removeBasketItem({
                                basketItemId: basketItemDTO.BasketItemId
                            });
                        }
                    );
                })
                .then(() => {
                    return Promise.map(
                        params.mediaItemOffers,
                        ({offerId, mediaItemId}) => ApiService.addMediaItemOfferToBasket({offerId, mediaItemId})
                    );
                })
                .then(basketItemDTOCollection => {
                    const mediadItemDTOCollection = basketItemDTOCollection.map(basketItemDTO => basketItemDTO.MediaItemDTO);
                    return Promise.all([
                        ApiService.validateBundleOffer(params),
                        ApiService.generateMediaGraphBucketPayload(mediadItemDTOCollection),
                        ApiService.getCustomerPaymentDetails(params)
                    ])
                        .then((results) => {
                            /** @type {ValidateBundleOfferResultDTO} **/
                            const validateBundleOfferResultDTO = results[0];
                            return {
                                amountDue: validateBundleOfferResultDTO.BasketPrice,
                                currencyCode: validateBundleOfferResultDTO.BasketCurrencyCode || params.currencyCode, // solves missing API data
                                basketItemDTOCollection,
                                ...results[1],
                                customerPaymentDetailsDTO: results[2]
                            };
                        });
                });

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_FAST_CHECKOUT_BUNDLE_OFFER,
            success: ActionTypes.REQUEST_FAST_CHECKOUT_BUNDLE_OFFER_SUCCESS,
            failure: ActionTypes.REQUEST_FAST_CHECKOUT_BUNDLE_OFFER_ERROR
        }, params);
    },

    /**
     * Cancel checkout
     *
     * @param {Object} params
     */
    cancelCheckout: (params = {}) => {
        AppDispatcher.dispatch(ActionTypes.CANCEL_CHECKOUT, params);
    },

    /**
     * Charge basket
     *
     * Option A) (temp card token):
     * - get temp card token
     * - charge basket with temporary card token [optional: save card]
     * - load new entitlements
     *
     * Option B) (payment provider customer id):
     * - charge basket with payment provider customer id
     * - load new entitlements
     *
     * @param params
     */
    chargeBasket: (params = {}) => {
        let promise = null;

        if (params.chargeWithPaymentProviderCustomerId) {
            promise = ApiService.chargeBasketWithPaymentProviderCustomerId({
                ...params,
                amountDue: params.amountDue,
                currencyCode: params.currencyCode
            });
        } else {
            promise = ApiService.createStripeCardToken(params)
                .then((stripeTokenDTO) => {
                    return ApiService.chargeBasketWithTemporaryCardToken({
                        ...params,
                        stripeToken: stripeTokenDTO.id,
                        saveCardProvider: !!params.saveCardProvider,
                        amountDue: params.amountDue,
                        currencyCode: params.currencyCode
                    });
                });
        }

        promise = promise.then((chargeBasketResultDTO) => {
            return ApiService.getCurrentEntitlements(params)
                .then((entitlementDTOCollection) => {
                    return entitlementDTOCollection.filter(entitlementDTO => entitlementDTO.PurchaseOrderId === chargeBasketResultDTO.PurchaseOrderId);
                })
                .then(entitlementDTOCollection => {
                    // remove new entitlements from watchlist
                    return ApiService.getWatchlist(params)
                        .then(watchlistItemDTOCollection => {
                            const entitlementMediaItemIds = entitlementDTOCollection.map(entitlementDTO =>
                                entitlementDTO.SupplierReference);
                            Promise.map(
                                watchlistItemDTOCollection
                                    .filter(watchlistItemDTO => entitlementMediaItemIds.indexOf(watchlistItemDTO.SupplierReference) > -1),
                                watchlistItemDTO => ApiService.removeMediaItemFromWatchlist({watchlistItemId: watchlistItemDTO.BasketItemId})
                                    .catch(() => null)
                            );
                            removeChildrenItemsFromWishlist(entitlementDTOCollection, watchlistItemDTOCollection);
                            // return remaining watchlistItemDTOs
                            return watchlistItemDTOCollection
                                .filter(watchlistItemDTO => entitlementMediaItemIds.indexOf(watchlistItemDTO.SupplierReference) === -1);
                        })
                        .then(watchlistItemDTOCollection => ({entitlementDTOCollection, watchlistItemDTOCollection}));
                });
        });

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_CHARGE_BASKET,
            success: ActionTypes.REQUEST_CHARGE_BASKET_SUCCESS,
            failure: ActionTypes.REQUEST_CHARGE_BASKET_ERROR
        }, params);
    },

    /**
     * Charge basket Stripe v3
     *
     * Option B) (payment provider customer id):
     * - charge basket with payment provider customer id
     * - load new entitlements
     *
     * @param params
     */
    chargeBasketStripeV3: (params = {}) => {
        let promise = ApiService.chargeBasketWithStripeV3(params)
        .then((chargeBasketResultDTO) => {
            return ApiService.getCurrentEntitlements(params)
                .then((entitlementDTOCollection) => {
                    return entitlementDTOCollection.filter(entitlementDTO => entitlementDTO.PurchaseOrderId === chargeBasketResultDTO.PurchaseOrderId);
                })
                .then(entitlementDTOCollection => {
                    // remove new entitlements from watchlist
                    return ApiService.getWatchlist(params)
                        .then(watchlistItemDTOCollection => {
                            const entitlementMediaItemIds = entitlementDTOCollection.map(entitlementDTO =>
                                entitlementDTO.SupplierReference);
                            Promise.map(
                                watchlistItemDTOCollection
                                    .filter(watchlistItemDTO => entitlementMediaItemIds.indexOf(watchlistItemDTO.SupplierReference) > -1),
                                watchlistItemDTO => ApiService.removeMediaItemFromWatchlist({watchlistItemId: watchlistItemDTO.BasketItemId})
                                    .catch(() => null)
                            );
                            removeChildrenItemsFromWishlist(entitlementDTOCollection, watchlistItemDTOCollection);
                            // return remaining watchlistItemDTOs
                            return watchlistItemDTOCollection
                                .filter(watchlistItemDTO => entitlementMediaItemIds.indexOf(watchlistItemDTO.SupplierReference) === -1);
                        })
                        .then(watchlistItemDTOCollection => ({entitlementDTOCollection, watchlistItemDTOCollection}));
                });
        });

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_CHARGE_BASKET,
            success: ActionTypes.REQUEST_CHARGE_BASKET_SUCCESS,
            failure: ActionTypes.REQUEST_CHARGE_BASKET_ERROR
        }, params);
    },

    /**
     * Apply voucher
     *
     * @param params
     */
    verifyVoucher: (params = {}) => {
        let promise = ApiService.verifyVoucher(params)
            .catch(() => ({Value: 0.0, RedeemableValue: 0.0, Status: 0, StatusMessage: 'Voucher not found'}))
            .then(voucherDTO => ({voucherDTO}));

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_VERIFY_VOUCHER,
            success: ActionTypes.REQUEST_VERIFY_VOUCHER_SUCCESS,
            failure: ActionTypes.REQUEST_VERIFY_VOUCHER_ERROR
        }, params);
    },

    /**
     * Create purchase order VOD item
     *
     * @param params
     */
    createPurchaseOrderVODItem: (params = {}) => {
        let promise = ApiService.createPurchaseOrderVODItem(params)
            .then(entitlementDTO => ({entitlementDTOCollection: [entitlementDTO]}));

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_CREATE_PURCHASE_ORDER_VOD_ITEM,
            success: ActionTypes.REQUEST_CREATE_PURCHASE_ORDER_VOD_ITEM_SUCCESS,
            failure: ActionTypes.REQUEST_CREATE_PURCHASE_ORDER_VOD_ITEM_ERROR
        }, params);
    }
};

/**
* Remove children items from wishlist if parent purchased - helper function
*/
const removeChildrenItemsFromWishlist = (entitlementDTOCollection, watchlistItemDTOCollection) => {
    const children = entitlementDTOCollection.map(entitlementDTO => entitlementDTO.Children);
    const mergedChildren = [].concat.apply([], children);
    const supplierReferenceCollection = watchlistItemDTOCollection.reduce((obj, child) => {
        obj[child.SupplierReference] = child.BasketItemId;
        return obj;
    }, {});
    if (mergedChildren && mergedChildren.length > 0) {
        mergedChildren.forEach(child => {
            if (supplierReferenceCollection[child.SupplierReference]) {
                ApiService.removeMediaItemFromWatchlist({watchlistItemId: supplierReferenceCollection[child.SupplierReference]});
            }
        });
    }
};

export {PurchaseActions as default, PurchaseActions};
