import AppStore from '../stores/AppStore';

/**
 * Error parser methods
 */

/**
 * Parse errorMessage
 *
 * @param {string} errorMessage
 * @return {*}
 */
export function parseErrorMessage(errorMessage) {
    if (!errorMessage) return null;

    if (errorMessage.indexOf('exists') > -1) {
        errorMessage = AppStore.translate('notification.account.error.account_exist');
    }

    if (errorMessage.indexOf('short') > -1) {
        errorMessage = AppStore.translate('notification.account.error.password_short');
    }

    if (errorMessage.indexOf('contain') > -1) {
        errorMessage = AppStore.translate('notification.account.error.password_not_secure');
    }

    if (errorMessage.indexOf('userProfile.EmailAddress') > -1) {
        errorMessage = AppStore.translate('notification.account.error.email_not_valid');
    }

    if (errorMessage.indexOf('short') > -1) {
        errorMessage = AppStore.translate('notification.account.error.password_short');
    }

    if (errorMessage.indexOf('contain') > -1) {
        errorMessage = AppStore.translate('notification.account.error.password_not_secure');
    }

    if (errorMessage.indexOf('NullReferenceException') > -1) {
        errorMessage = AppStore.translate('notification.account.error.email_not_valid');
    }

    if (errorMessage.indexOf('ExpiredCard') > -1) {
        errorMessage = AppStore.translate('notification.purchase.error.expired_card');
    }

    if (errorMessage.indexOf('IncorrectCvc') > -1) {
        errorMessage = AppStore.translate('notification.purchase.error.incorrect_cvc');
    }

    if (errorMessage.indexOf('IncorrectZip') > -1) {
        errorMessage = AppStore.translate('notification.purchase.error.incorrect_zip');
    }

    if (errorMessage.indexOf('CardDeclined') > -1) {
        errorMessage = AppStore.translate('notification.purchase.error.card_declined');
    }

    if (errorMessage.indexOf('Missing') > -1) {
        errorMessage = AppStore.translate('notification.purchase.error.missing');
    }

    if (errorMessage.indexOf('ProcessingError') > -1) {
        errorMessage = AppStore.translate('notification.purchase.error.processing_error');
    }

    if (errorMessage.indexOf('Unable to unregister') > -1) {
        errorMessage = AppStore.translate('notification.account.error.unable_to_remove_device');
    }

    if (errorMessage.indexOf('Unable to unregister') > -1) {
        errorMessage = AppStore.translate('notification.account.error.unable_to_rename_device');
    }

    if (errorMessage.indexOf('MediaItem Not Found') > -1) {
        errorMessage = AppStore.translate('notification.checkout.error.processing_error');
    }

    if (errorMessage.indexOf('Internal Server Error') > -1) {
        errorMessage = AppStore.translate('notification.watchlist.error.processing_error');
    }

    if (errorMessage.indexOf('Internal Server Error') > -1) {
        errorMessage = AppStore.translate('notification.watchlist.error.processing_error');
    }

    if (errorMessage.indexOf('error_code_') > -1) {
        errorMessage = AppStore.translate(errorMessage);
    }

    if (errorMessage.indexOf('PaymentIntentAuthenticationError') > -1) {
        errorMessage = AppStore.translate('notification.charge.basket.payment.intent_error');
    }

    if (errorMessage.indexOf('We are unable to authenticate your payment method. Please choose a different payment method and try again.') > -1) {
        errorMessage = AppStore.translate('notification.charge.basket.3ds.auth_error');
    }

    if (errorMessage.indexOf('XHR error') > -1) {
        errorMessage = AppStore.translate('error_code_57');
    }

    return errorMessage;
}
