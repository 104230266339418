/* eslint-disable import/no-cycle */
import React from 'react';
import AppStore from '../stores/AppStore';
import AuthActions from '../actions/AuthActions';
import AuthStore from '../stores/AuthStore';
import Button from './ui/Button.react';
import NotificationActions from '../actions/NotificationActions';

/**
 * AccountChangePasswordPage component
 */
export default class AccountChangePasswordPage extends React.Component {
    /**
     * React: state
     */
    state = {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
        updatePending: false,
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        AuthStore.addUpdateListener(this.setFormStatus);
        document.body.className += ' black-background';
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AuthStore.removeListener(AuthStore.STORE_UPDATED, this.setFormStatus);
        this.setState({updatePending: false});
        document.body.className = document.body.className.replace(/black-background/, '');
    }

    /**
     * On form submit
     *
     * @param {Object} event
     * @private
     */
    onSubmit = event => {
        event.preventDefault();
        if (this.state.newPassword !== this.state.newPasswordConfirmation) {
            NotificationActions.displayNotification({
                notificationType: 'error',
                content: AppStore.translate('notification.account.error.password_mismatch'),
            });
            return;
        }

        this.setState({
            updatePending: true,
        }, () => {
            NotificationActions.hideNotification();
            AuthActions.changeUserPassword({
                oldPassword: this.state.oldPassword,
                newPassword: this.state.newPassword,
            });
        });
    };

    /**
     * Set form status
     */
    setFormStatus = () => {
        this.setState({updatePending: AuthStore.updatePending});
    };

    /**
     * On field change
     *
     * @param {string} fieldName
     * @param {Object} event
     * @private
     */
    onFieldChange = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    /**
     * React: render
     */
    render() {
        return (
            <main className="account-page container">
                <div className="row">
                    <div className="account-page-content login-form-container  col-xs-12 col-sm-8 col-md-5 col-lg-4">

                        <div className="form-title">
                            <h4>{AppStore.translate('view.account_page.title.change_password')}</h4>
                        </div>

                        <div className="login-avatar">
                            <i className="ui-icon icon-user-avatar" />
                        </div>

                        <div className="form-annotation">
                            {AppStore.translate('view.account_page.message.provide_new_password')}
                        </div>

                        <form onSubmit={this.onSubmit}>
                            <span
                                className="password-input-wrapper input-wrapper icon-input password-input"
                            >
                                <input
                                    type="password"
                                    name="oldPassword"
                                    className="simple-input"
                                    placeholder={AppStore.translate('field.placeholder.old_password')}
                                    value={this.state.oldPassword}
                                    disabled={this.state.updatePending}
                                    autoComplete="current-password"
                                    onChange={this.onFieldChange}
                                />
                            </span>
                            <span
                                className="password-input-wrapper input-wrapper icon-input password-input"
                            >
                                <input
                                    type="password"
                                    name="newPassword"
                                    className="simple-input"
                                    placeholder={AppStore.translate('field.placeholder.new_password')}
                                    value={this.state.newPassword}
                                    disabled={this.state.updatePending}
                                    autoComplete="new-password"
                                    onChange={this.onFieldChange}
                                />
                            </span>
                            <span
                                className="password-input-wrapper input-wrapper icon-input password-confirm-input"
                            >
                                <input
                                    type="password"
                                    name="newPasswordConfirmation"
                                    className="simple-input"
                                    placeholder={AppStore.translate('field.placeholder.confirm_password')}
                                    value={this.state.newPasswordConfirmation}
                                    disabled={this.state.updatePending}
                                    autoComplete="new-password"
                                    onChange={this.onFieldChange}
                                />
                            </span>
                            <Button
                                type="submit"
                                shape="regular"
                                className="cta-play full-width"
                                updatePending={this.state.updatePending}
                            >
                                {AppStore.translate('button.submit')}
                            </Button>
                        </form>

                    </div>
                </div>
            </main>
        );
    }
}
