"use strict";

import Immutable from 'immutable';
import moment from 'moment';
import EntitlementType from './EntitlementType';
import RegisteredDevice from './RegisteredDevice';
import MediaAsset from './MediaAsset';

/**
 * EntitlementDTO definition
 *
 * @typedef {Object} EntitlementDTO
 * @property {?EntitlementTypeDTO} EntitlementType - The type of entitlement acquired
 * @property {?string} RentalExpiryTime - The date and time when this rental will expire
 * @property {?string} LastPlayPosition - The last play position for the current entitlement
 * @property {?RegisteredDeviceDTO} RegisteredRegisteredDevice - For persistent licenses, the device that the entitlement was made against
 * @property {?boolean} TransientLicenceIssued - If not Persistent License issued
 * @property {?MediaItemDTO} MediaItem - The media item associated with this entitlement
 * @property {?Array<MediaAssetDTO>} MediaAssets
 * @property {?number} Id - Purchase Order Item Id
 * @property {?number} PurchaseOrderId - The associated Purchase Order Id
 * @property {?number} OfferId - FK of offer that is/was being purchased
 * @property {?number} UnitPrice - unit price that the user is paying
 * @property {?string} SupplierRef - Supplier Reference for the media item [removed by api pre-processor]
 * @property {?string} TimeOfFirstPlay - The date and time when the first DRM licence was issued for this rental
 * @property {?number} RentalTime - Total time (in days) from the time the order was created until the rental ends whether watched or not
 * @property {?number} ViewTime - Total time (in hours) from first play to when the rental expires
 * @property {?string} Title - Film title. For historical records - film may no longer exist on the service
 * @property {?string} BbfcRating - BBFC rating. For historical records - film may no longer exist on the service
 * @property {?number} Duration - Film duration. For historical records - film may no longer exist on the service
 * @property {?boolean} Deleted - Deleted flag. Item may be flagged as deleted, but will remain in the db
 * @property {?boolean} RequiresPreRoll - Film requires pre-roll ad when played.
 * @property {?boolean} PersistentLicenseIssued - Persistent License Issued
 * @property {?boolean} IsDtr - Purchase supports DTR
 * @property {?string} SupportedDevices
 * @property {?string} DrmProfile - The drm profile to apply when delivering a license
 * @property {?boolean} LicenseAcknowledged
 * @property {?number} EntitlementTypeId
 * @property {?Array<EntitlementDTO>} Children - Child entitlements
 * @property {?EntitlementDTO} Parent - Parent entitlement
 * @property {?string} SupplierReference [api pre-processor]
 */

/**
 * Entitlement definition
 *
 * @typedef {Immutable.Record} Entitlement
 * @property {?number} id
 * @property {?number} purchaseOrderId
 * @property {?number} entitlementTypeId
 * @property {?EntitlementType} entitlementType
 * @property {?string} entitlementClassification
 * @property {?RegisteredDevice} registeredDevice
 * @property {?number} offerId
 * @property {?number} unitPrice
 * @property {?string} mediaItemId
 * @property {Array<MediaAsset>} mediaAssets
 * @property {?string} timeOfFirstPlay
 * @property {?string} lastPlayPosition
 * @property {?number} rentalTime
 * @property {?number} viewTime
 * @property {?Object} rentalExpiryTime
 * @property {?string} title
 * @property {?string} bbfcRating
 * @property {?number} duration
 * @property {?boolean} deleted
 * @property {?boolean} requiresPreRoll
 * @property {?boolean} persistentLicenceIssued
 * @property {?boolean} isDtr
 * @property {?string} supportedDevices
 * @property {?string} drmProfile
 * @property {?boolean} licenseAcknowledged
 * @property {?boolean} transientLicenceIssued
 * @property {?string} timeLeft
 * @property {boolean} updatePending
 */

/**
 * Entitlement record
 *
 * @type {Entitlement}
 */
class Entitlement extends Immutable.Record({
    id: null,
    purchaseOrderId: null,
    entitlementTypeId: null,
    entitlementType: null,
    entitlementClassification: null,
    registeredDevice: null,
    offerId: null,
    unitPrice: null,
    mediaItemId: null,
    mediaAssets: [],
    timeOfFirstPlay: null,
    lastPlayPosition: null,
    rentalTime: null,
    viewTime: null,
    rentalExpiryTime: null,
    title: null,
    bbfcRating: null,
    duration: 0,
    deleted: null,
    requiresPreRoll: null,
    persistentLicenceIssued: null,
    isDtr: null,
    supportedDevices: null,
    drmProfile: null,
    licenseAcknowledged: null,
    transientLicenceIssued: null,
    timeLeft: null,
    updatePending: false
}) {
    /**
     * toJS shallow
     * converts Record to object by keeping all second level immutable or other complex properties intact
     *
     * @return {Object}
     */
    toJSShallow() {
        return {
            ...this.toJS(),
            entitlementType: this.entitlementType,
            registeredDevice: this.registeredDevice,
            mediaAssets: this.mediaAssets,
            rentalExpiryTime: this.rentalExpiryTime
        };
    }

    /**
     * Set values from data transfer object
     *
     * @param {EntitlementDTO} dataTransferObject
     * @return {Entitlement}
     */
    fromDTO(dataTransferObject) {
        return new Entitlement({
            ...this.toJSShallow(),
            id: typeof dataTransferObject.Id !== 'undefined' ? dataTransferObject.Id : this.id,
            purchaseOrderId: typeof dataTransferObject.PurchaseOrderId !== 'undefined'
                ? dataTransferObject.PurchaseOrderId : this.purchaseOrderId,
            entitlementTypeId: typeof dataTransferObject.EntitlementTypeId !== 'undefined'
                ? dataTransferObject.EntitlementTypeId : this.entitlementTypeId,
            entitlementType: typeof dataTransferObject.EntitlementType !== 'undefined' && dataTransferObject.EntitlementType
                ? new EntitlementType({}).fromDTO(dataTransferObject.EntitlementType) : this.entitlementType,
            entitlementClassification: typeof dataTransferObject.EntitlementType !== 'undefined' && dataTransferObject.EntitlementType
                ? EntitlementClassification.getValue(dataTransferObject.EntitlementType) : this.entitlementClassification,
            registeredDevice: typeof dataTransferObject.RegisteredRegisteredDevice !== 'undefined' && dataTransferObject.RegisteredRegisteredDevice
                ? new RegisteredDevice({}).fromDTO(dataTransferObject.RegisteredRegisteredDevice) : this.registeredDevice,
            offerId: typeof dataTransferObject.OfferId !== 'undefined'
                ? dataTransferObject.OfferId : this.offerId,
            unitPrice: typeof dataTransferObject.UnitPrice !== 'undefined'
                ? dataTransferObject.UnitPrice : this.unitPrice,
            mediaItemId: typeof dataTransferObject.SupplierReference !== 'undefined'
                ? dataTransferObject.SupplierReference : this.mediaItemId,
            mediaAssets: typeof dataTransferObject.MediaAssets !== 'undefined' && dataTransferObject.MediaAssets
                ? _getMediaAssetsFromDTO(dataTransferObject) : this.mediaAssets,
            timeOfFirstPlay: typeof dataTransferObject.TimeOfFirstPlay !== 'undefined'
                ? dataTransferObject.TimeOfFirstPlay : this.timeOfFirstPlay,
            lastPlayPosition: typeof dataTransferObject.LastPlayPosition !== 'undefined'
                ? dataTransferObject.LastPlayPosition : this.lastPlayPosition,
            rentalTime: typeof dataTransferObject.RentalTime !== 'undefined'
                ? dataTransferObject.RentalTime : this.rentalTime,
            viewTime: typeof dataTransferObject.ViewTime !== 'undefined'
                ? dataTransferObject.ViewTime : this.viewTime,
            rentalExpiryTime: typeof dataTransferObject.RentalExpiryTime !== 'undefined'
                ? moment(dataTransferObject.RentalExpiryTime) : this.rentalExpiryTime,
            title: typeof dataTransferObject.Title !== 'undefined'
                ? dataTransferObject.Title : this.title,
            bbfcRating: typeof dataTransferObject.BbfcRating !== 'undefined'
                ? dataTransferObject.BbfcRating : this.bbfcRating,
            duration: typeof dataTransferObject.Duration !== 'undefined'
                ? dataTransferObject.Duration : this.duration,
            deleted: typeof dataTransferObject.Deleted !== 'undefined'
                ? dataTransferObject.Deleted : this.deleted,
            requiresPreRoll: typeof dataTransferObject.RequiresPreRoll !== 'undefined'
                ? dataTransferObject.RequiresPreRoll : this.requiresPreRoll,
            persistentLicenceIssued: typeof dataTransferObject.PersistentLicenseIssued !== 'undefined'
                ? dataTransferObject.PersistentLicenseIssued : this.persistentLicenceIssued,
            isDtr: typeof dataTransferObject.IsDtr !== 'undefined'
                ? dataTransferObject.IsDtr : this.isDtr,
            supportedDevices: typeof dataTransferObject.SupportedDevices !== 'undefined'
                ? dataTransferObject.SupportedDevices : this.supportedDevices,
            drmProfile: typeof dataTransferObject.DrmProfile !== 'undefined'
                ? dataTransferObject.DrmProfile : this.drmProfile,
            licenseAcknowledged: typeof dataTransferObject.LicenseAcknowledged !== 'undefined'
                ? dataTransferObject.LicenseAcknowledged : this.licenseAcknowledged,
            transientLicenceIssued: typeof dataTransferObject.TransientLicenceIssued !== 'undefined'
                ? dataTransferObject.TransientLicenceIssued : this.transientLicenceIssued,
            timeLeft: typeof dataTransferObject.TimeOfFirstPlay !== 'undefined' && typeof dataTransferObject.ViewTime !== 'undefined'
                ? _calculateTimeLeft(dataTransferObject) : this.timeLeft
        });
    }

    /**
     * Set update pending
     *
     * @param {boolean} updatePending
     * @return {Entitlement}
     */
    setUpdatePending(updatePending) {
        return this.updatePending !== updatePending
            ? new Entitlement({
                ...this.toJSShallow(),
                updatePending: updatePending
            }) : this;
    }

    /**
     * Set last play position (and watched percentage)
     *
     * @param {string} lastPlayPosition
     * @return {Entitlement}
     */
    setLastPlayPosition(lastPlayPosition) {
        return this.lastPlayPosition !== lastPlayPosition
            ? new Entitlement({
                ...this.toJSShallow(),
                lastPlayPosition: lastPlayPosition
            }) : this;
    }
}

/**
 * Get media assets from data transfer object
 *
 * @param {EntitlementDTO} dataTransferObject
 * @returns {Array<MediaAsset>}
 * @private
 */
function _getMediaAssetsFromDTO(dataTransferObject) {
    var mediaAssets = [];
    dataTransferObject.MediaAssets.forEach((mediaAssetDTO) => {
        let mediaAsset = new MediaAsset({}).fromDTO(mediaAssetDTO);
        if (mediaAsset.assetType) {
            mediaAssets.push(mediaAsset);
        }
    });

    return mediaAssets;
}

/**
 * Calculate time left
 *
 * @param {EntitlementDTO} dataTransferObject
 * @returns {string|null}
 * @private
 */
function _calculateTimeLeft(dataTransferObject) {
    return moment(dataTransferObject.TimeOfFirstPlay).add(dataTransferObject.ViewTime, 'hours').diff(moment(), 'hours') + 'h';
}

/**
 * Entitlement classification (derived from EntitlementType)
 * @type {Object}
 */
var EntitlementClassification = {
    UNKNOWN: 'Unknown',
    EST: 'DownloadToOwn',
    DTR: 'DownloadToRent',
    FVOD: 'FreeVideoOnDemand',
    SVOD: 'SubscriptionVideoOnDemand',

    /**
     * Get value
     *
     * @param {EntitlementTypeDTO} inputValue
     */
    getValue: function (inputValue) {
        var value = this.UNKNOWN;
        Object.keys(this).forEach((key) => {
            if (this[key] === inputValue.Name) value = this[key];
        });
        return value;
    }
};

export {Entitlement as default, Entitlement, EntitlementClassification};
