"use strict";

import React from 'react';
import PropTypes from 'prop-types';
// import {Link} from 'react-router';
// import Spinner from './Spinner2.react';
import TextInput from './form-fields/TextInput.react';
import TextAreaInput from './form-fields/TextAreaInput.react';
import Choice from './form-fields/Choice.react';

export default class Button extends React.Component {

    /**
     * React: propTypes
     */
    static propTypes = {
        onClick: PropTypes.func,
        onChange: PropTypes.func,
        name: PropTypes.string,
        type: PropTypes.oneOf(['text', 'password', 'email', 'textarea', 'choice-select', 'choice-radio', 'choice-checkbox']),
        value: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
        updatePending: PropTypes.bool,
        disabled: PropTypes.bool,
        label: PropTypes.string,
        error: PropTypes.string,
        inlineLabel: PropTypes.bool,
        choiceArray: PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.object
        ]))
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        type: 'text',
        className: '',
        style: {},
        updatePending: false,
        disabled: false,
        label: '',
        error: '',
        inlineLabel: true
    };

    /**
     * React: state
     */
    state = {
        // value: this.props.value
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value
        });
    }

    /**
     * React: render
     */
    render() {
        var className=this.props.className + ' form-field ' +
            (this.props.inlineLabel ? 'inline-label ' : '') +
            (this.props.updatePending ? 'update-pending ' : '') +
            (this.props.disabled ? 'disabled ' : '');

        return (
            <div className={className}>
                {this.props.label!='' ? (
                    <label>{this.props.label}</label>
                ) : null }

                {this.props.type=='text' || this.props.type=='password' ||
                    this.props.type=='email' || this.props.type=='choice-select' || this.props.type=='textarea' ? (
                    <div className="form-field-inner-container">
                        {this.props.type=='text' || this.props.type=='password' || this.props.type=='email' ? (
                            <TextInput
                                updatePending={this.props.updatePending}
                                disabled={this.props.disabled}
                                type={this.props.type}
                                value={this.props.value}
                                onChange={this._changeTextInput}
                            />
                        ) : null }

                        {this.props.type=='textarea' ? (
                            <TextAreaInput
                                value={this.state.value}
                                updatePending={this.props.updatePending}
                                disabled={this.props.disabled}
                                onChange={this._changeTextArea}
                            />
                        ) : null}

                        {this.props.type=='choice-select' ? (
                            <Choice
                                updatePending={this.props.updatePending}
                                disabled={this.props.disabled}
                                type={this.props.type}
                                value={this.props.value}
                                choices={this.props.choiceArray}
                                onChange={this._changeSelectValue}
                                onClick={this._selectClick}
                            />
                        ) : null}

                        {this.props.error!='' ? (
                            <div className="error-container">{this.props.error}</div>
                        ) : null }
                    </div>
                ) : null}

                {this.props.type=='choice-radio' ? (
                    <Choice
                        updatePending={this.props.updatePending}
                        disabled={this.props.disabled}
                        type={this.props.type}
                        value={this.props.value}
                        choices={this.props.choiceArray}
                        onChange={this._changeRadioValue}
                    />
                ) : null}

                {this.props.type=='choice-checkbox' ? (
                    <div className="choice-field-container">
                        <Choice
                            updatePending={this.props.updatePending}
                            disabled={this.props.disabled}
                            type={this.props.type}
                            value={this.state.value}
                            choices={this.props.choiceArray}
                            onChange={this._changeCheckboxValue}
                        />
                        {/*<label key={index}><input type="checkbox" />{element.label ? element.label : element.value}</label>*/}

                        {this.props.error!='' ? (
                            <div className="error-container">{this.props.error}</div>
                        ) : null }
                    </div>
                ) : null}
            </div>
        );
    }

    /**
     * onClickHandler
     */
    _onClickHandler = () => {
        if(typeof this.props.onClick=='function'){
            this.props.onClick();
        }
    };

    _selectClick = () => {
        // console.log(this.state.value);
    };

    _changeTextInput = (newValue) => {
        // console.log(newValue)

        if(typeof this.props.onChange==='function'){
            this.props.onChange(newValue);
        }
    };

    _changeSelectValue = (newValue) => {
        if(typeof this.props.onChange==='function'){
            this.props.onChange(newValue);
        }
    };

    _changeTextArea = (newValue) => {
        if(typeof this.props.onChange==='function'){
            this.props.onChange(newValue);
        }
    };

    _changeRadioValue = (newValue) => {
        if(typeof this.props.onChange==='function'){
            this.props.onChange(newValue);
        }
    };

    _changeCheckboxValue = (newArray) => {
        if(typeof this.props.onChange==='function'){
            this.props.onChange(newArray);
        }
    };
}
