"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import AppStore from '../stores/AppStore';
import Router from '../lib/Router';

/**
 * PlatformArticlePage component
 */
export default class PlatformArticlePage extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        params: PropTypes.object
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * Map page keys to routes
     */
    _pageMappings = {
        "legal-stuff": "terms_and_conditions",
        "about": "What is Virgin Media Store",
        "help-faq": "Getting Started",
        "contact": "Contact Us",
        "cookies": "Cookies"
    };

    /**
     * React: state
     */
    state = {
        page: AppStore.platformArticles.get(this._pageMappings[this.props.params.contextCode])
    };

    /**
     * React: UNSAFE_componentWillReceiveProps
     */
    componentDidMount() {
        if (!this.props.params) {
            this.context.router.push(Router.PAGE_NOT_FOUND);
            return;
        }

        if (!this.props.params.contextCode) {
            this.context.router.push(Router.PAGE_NOT_FOUND);
            return;
        }

        var page = AppStore.platformArticles.get(this._pageMappings[this.props.params.contextCode]);

        if (!page) {
            this.context.router.push(Router.PAGE_NOT_FOUND);
            return;
        }

        this._removeStyles(document.getElementsByClassName('content-page')[0]);
    }

    /**
     * React: UNSAFE_componentWillReceiveProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.params) {
            this.context.router.push(Router.PAGE_NOT_FOUND);
            return;
        }

        if (!nextProps.params.contextCode) {
            this.context.router.push(Router.PAGE_NOT_FOUND);
            return;
        }

        var page = AppStore.platformArticles.get(this._pageMappings[nextProps.params.contextCode]);

        if (!page) {
            this.context.router.push(Router.PAGE_NOT_FOUND);
            return;
        }

        this.setState({
            page: page
        });
    }

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        return this.state.page ? (
            <main className="content-page">
                <div className="row">
                    <div className="content col-xs-12 col-md-8"
                         dangerouslySetInnerHTML={{__html: this.state.page.body}}>
                    </div>
                </div>
            </main>
        ) : null;
    }

    /**
     * Remove styles
     *
     * @param el
     * @private
     */
    _removeStyles = (el) => {
        el.removeAttribute('style');

        if (el.childNodes.length > 0) {
            for (var child in el.childNodes) {
                /* filter element nodes only */
                if (el.childNodes[child].nodeType == 1)
                    this._removeStyles(el.childNodes[child]);
            }
        }
    };
}
