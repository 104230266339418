"use strict";

import Immutable from 'immutable';
import moment from 'moment';

/**
 * RegisteredDeviceDTO definition
 *
 * @typedef {Object} RegisteredDeviceDTO
 * @property {?number} Id - Gets or sets the identifier
 * @property {?number} DeviceTypeId - Gets or sets the device type identifier
 * @property {?string} DeviceTypeName - Device Type
 * @property {?string} DeviceName - The name of the device
 * @property {?string} UserId - User Id
 * @property {?string} DeviceId - User Id
 * @property {?string} UpdatedDate - Date last updated
 * @property {?boolean} IsActive - Is active
 * @property {?string} ClientId - The client device id
 * @property {?boolean} CanDelete - Can delete
 * @property {?string} LastRegistrationEventDate - Gets or sets the last registration event date
 * @property {?string} DateNextDeregistrationAllowed - Gets or sets the date next deregistration allowed
 * @property {?boolean} DeregistrationAllowed
 * @property {?boolean} IsUnrestricted - Gets or sets a value indicating whether this instance is unrestricted
 */

/**
 * RegisteredDevice definition
 *
 * @typedef {Immutable.Record} RegisteredDevice
 * @property {?number} id
 * @property {?number} deviceTypeId
 * @property {?string} deviceTypeName
 * @property {?string} deviceName
 * @property {?string} userId
 * @property {?Object} updatedDate
 * @property {?boolean} isActive
 * @property {?string} clientId
 * @property {?boolean} canDelete
 * @property {?Object} lastRegistrationEventDate
 * @property {?Object} removalAvailableDate
 * @property {?boolean} isUnrestricted
 */

/**
 * RegisteredDevice record
 *
 * @type {RegisteredDevice}
 */
class RegisteredDevice extends Immutable.Record({
    id: null,
    deviceTypeId: null,
    deviceTypeName: null,
    deviceName: null,
    userId: null,
    deviceId: null,
    updatedDate: null,
    isActive: null,
    clientId: null,
    canDelete: null,
    lastRegistrationEventDate: null,
    removalAvailableDate: null,
    removalAvailable: false,
    // updatePending: false, // disabled as UI is not ready for it
    isUnrestricted: false
}) {
    /**
     * toJS shallow
     * converts Record to object by keeping all second level immutable or other complex properties intact
     *
     * @return {Object}
     */
    toJSShallow() {
        return {
            ...this.toJS()
        };
    }

    /**
     * Set values from data transfer object
     *
     * @param {RegisteredDeviceDTO} dataTransferObject
     * @return {RegisteredDevice}
     */
    fromDTO(dataTransferObject) {
        return new RegisteredDevice({
            ...this.toJSShallow(),
            id: typeof dataTransferObject.Id !== 'undefined' ? dataTransferObject.Id : this.id,
            deviceTypeId: typeof dataTransferObject.DeviceTypeId !== 'undefined' ? dataTransferObject.DeviceTypeId : this.deviceTypeId,
            deviceTypeName: typeof dataTransferObject.DeviceTypeName !== 'undefined' ? dataTransferObject.DeviceTypeName : this.deviceTypeName,
            deviceName: typeof dataTransferObject.DeviceName !== 'undefined' ? dataTransferObject.DeviceName : this.deviceName,
            userId: typeof dataTransferObject.UserId !== 'undefined' ? dataTransferObject.UserId : this.userId,
            deviceId: typeof dataTransferObject.DeviceId !== 'undefined' ? dataTransferObject.DeviceId : this.deviceId,
            updatedDate: typeof dataTransferObject.UpdatedDate !== 'undefined' ? moment(dataTransferObject.UpdatedDate) : this.updatedDate,
            isActive: typeof dataTransferObject.IsActive !== 'undefined' ? dataTransferObject.IsActive : this.isActive,
            clientId: typeof dataTransferObject.ClientId !== 'undefined' ? dataTransferObject.ClientId : this.clientId,
            lastRegistrationEventDate: typeof dataTransferObject.LastRegistrationEventDate !== 'undefined' ? moment(dataTransferObject.LastRegistrationEventDate) : this.lastRegistrationEventDate,
            removalAvailableDate: typeof dataTransferObject.DateNextDeregistrationAllowed !== 'undefined' ? moment(dataTransferObject.DateNextDeregistrationAllowed) : this.removalAvailableDate,
            removalAvailable: typeof dataTransferObject.DeregistrationAllowed !== 'undefined' ? dataTransferObject.DeregistrationAllowed : this.removalAvailable,
            isUnrestricted: typeof dataTransferObject.IsUnrestricted !== 'undefined' ? dataTransferObject.IsUnrestricted : this.isUnrestricted
        });
    }

    /**
     * Set update pending
     *
     * @param {boolean} updatePending
     * @return {RegisteredDevice}
     */
    setUpdatePending(updatePending) {
        return this.updatePending !== updatePending ? new RegisteredDevice({
            ...this.toJSShallow(),
            updatePending: updatePending
        }) : this;
    }
}

export default RegisteredDevice;
