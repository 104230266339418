"use strict";

import Immutable from 'immutable';

/**
 * AddressLookupResultDTO definition
 *
 * @typedef {Object} AddressLookupResultDTO
 * @property {?number} Latitude
 * @property {?number} Longitude
 * @property {?array} Addresses
 */

/**
 * AddressLookupResult definition
 *
 * @typedef {Immutable.Record} AddressLookupResult
 * @property {?number} latitude
 * @property {?number} longitude
 * @property {?array} addresses
 */

/**
 * AddressLookupResult record
 *
 * @type {AddressLookupResult}
 */
class AddressLookupResult extends Immutable.Record({
    latitude: null,
    longitude: null,
    addresses: null
}) {
    /**
     * Set values from data transfer object
     *
     * @param {AddressLookupResultDTO} dataTransferObject
     * @return {AddressLookupResult}
     */
    fromDTO(dataTransferObject) {
        return new AddressLookupResult({
            ...this.toJS(),
            latitude: typeof dataTransferObject.latitude !== 'undefined' ? dataTransferObject.latitude : this.latitude,
            longitude: typeof dataTransferObject.longitude !== 'undefined' ? dataTransferObject.longitude : this.longitude,
            addresses: typeof dataTransferObject.addresses !== 'undefined' ? dataTransferObject.addresses : this.addresses
        });
    }
}

export default AddressLookupResult;
