"use strict";

import Immutable from 'immutable';

/**
 * BundleOfferDTO definition
 *
 * @typedef {Object} BundleOfferDTO
 * @property {?number} Id
 * @property {?string} Title
 * @property {?string} Description
 * @property {?number} MinSelectQuantity - Indicates number of Titles that need to be selected
 * @property {?number} OfferQuantity - Indicates number of Titles that are covered by the offerPriceReductionPercentage
 * @property {?number} OfferPrice - Price of offer if offer is x for OfferPrice
 * @property {?number} OfferPriceReductionPercentage - Percentage of reduction on offered titles if Buy x get y at z percentage reduction
 * @property {?number} HighOrLowPricedTitlesOffered - Indicates if highest or lowest priced titles are offered as the reduced ones
 * @property {?number} LinkedCollectionsCount
 */

/**
 * BundleOffer definition
 *
 * @typedef {Immutable.Record} BundleOffer
 * @property {?number} id
 * @property {?string} title
 * @property {?string} description
 * @property {?number} minSelectQuantity
 * @property {?number} offerQuantity
 * @property {?number} offerPrice
 * @property {?number} offerPriceReductionPercentage
 * @property {?number} highOrLowPricedTitlesOffered
 * @property {?number} linkedCollectionsCount
 */

/**
 * BundleOffer record
 *
 * @type {BundleOffer}
 */
class BundleOffer extends Immutable.Record({
    id: null,
    title: null,
    description: null,
    minSelectQuantity: null,
    offerQuantity: null,
    offerPrice: null,
    offerPriceReductionPercentage: null,
    offerCurrency: 'GBP',
    highOrLowPricedTitlesOffered: null,
    linkedCollectionsCount: null
}) {
    /**
     * Set values from data transfer object
     *
     * @param {BundleOfferDTO} dataTransferObject
     * @return {BundleOffer}
     */
    fromDTO(dataTransferObject) {
        return new BundleOffer({
            ...this.toJS(),
            id: typeof dataTransferObject.Id !== 'undefined' ? dataTransferObject.Id : this.id,
            title: typeof dataTransferObject.Title !== 'undefined' ? dataTransferObject.Title : this.title,
            description: typeof dataTransferObject.Description !== 'undefined' ? dataTransferObject.Description : this.description,
            minSelectQuantity: typeof dataTransferObject.MinSelectQuantity !== 'undefined' ? dataTransferObject.MinSelectQuantity : this.minSelectQuantity,
            offerQuantity: typeof dataTransferObject.OfferQuantity !== 'undefined' ? dataTransferObject.OfferQuantity : this.offerQuantity,
            offerPrice: typeof dataTransferObject.OfferPrice !== 'undefined' ? dataTransferObject.OfferPrice : this.offerPrice,
            offerPriceReductionPercentage: typeof dataTransferObject.OfferPriceReductionPercentage !== 'undefined' ? dataTransferObject.OfferPriceReductionPercentage : this.offerPriceReductionPercentage,
            highOrLowPricedTitlesOffered: typeof dataTransferObject.HighOrLowPricedTitlesOffered !== 'undefined' ? dataTransferObject.HighOrLowPricedTitlesOffered : this.highOrLowPricedTitlesOffered,
            linkedCollectionsCount: typeof dataTransferObject.LinkedCollectionsCount !== 'undefined' ? dataTransferObject.LinkedCollectionsCount : this.linkedCollectionsCount
        });
    }
}

export default BundleOffer;
