"use strict";

import Immutable from 'immutable';

/**
 * MediaAssetDTO definition
 *
 * @typedef {Object} MediaAssetDTO
 * @property {?string} AssetType - Asset Type Description
 * @property {?string} MaterialTitle - The Title for the particular material
 * @property {?string} MediaUri - The URI location of the playable assets
 * @property {?string} KeyId - The Licence Encryption Key ID
 * @property {?string} AspectRatio - The aspect ratio
 * @property {?number} TranscodeTypeId - Transcode type Id
 * @property {?string} TranscodeTypeDescription - Description name of the transcode type
 */

/**
 * MediaAsset definition
 *
 * @typedef {Immutable.Record} MediaAsset
 * @property {?string} assetType
 * @property {?string} materialTitle
 * @property {?string} mediaUri
 * @property {?string} keyId
 * @property {?string} aspectRatio
 * @property {?number} transcodeTypeId
 * @property {?string} transcodeTypeDescription
 */

/**
 * MediaAsset record
 *
 * @type {MediaAsset}
 */
class MediaAsset extends Immutable.Record({
    assetType: null,
    materialTitle: null,
    mediaUri: null,
    keyId: null,
    aspectRatio: null,
    transcodeTypeId: null,
    transcodeTypeDescription: null
}) {
    /**
     * Set values from data transfer object
     *
     * @param {MediaAssetDTO} dataTransferObject
     * @return {MediaAsset}
     */
    fromDTO(dataTransferObject) {
        return new MediaAsset({
            ...this.toJS(),
            assetType: typeof dataTransferObject.AssetType !== 'undefined' ? dataTransferObject.AssetType : this.assetType,
            materialTitle: typeof dataTransferObject.MaterialTitle !== 'undefined' ? dataTransferObject.MaterialTitle : this.materialTitle,
            mediaUri: typeof dataTransferObject.MediaUri !== 'undefined' ? dataTransferObject.MediaUri : this.mediaUri,
            keyId: typeof dataTransferObject.KeyId !== 'undefined' ? dataTransferObject.KeyId : this.keyId,
            aspectRatio: typeof dataTransferObject.AspectRatio !== 'undefined' ? dataTransferObject.AspectRatio : this.aspectRatio,
            transcodeTypeId: typeof dataTransferObject.TranscodeTypeId !== 'undefined' ? dataTransferObject.TranscodeTypeId : this.transcodeTypeId,
            transcodeTypeDescription: typeof dataTransferObject.TranscodeTypeDescription !== 'undefined' ? dataTransferObject.TranscodeTypeDescription : this.transcodeTypeDescription
        });
    }
}

export default MediaAsset;
