"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import ReactDOM from 'react-dom';
import Config from '../../lib/Config';
import Collection from '../../types/Collection';
import Spinner from '../ui/Spinner2.react';

/**
 * CollectionImage component
 */
export default class CollectionImage extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        collection: PropTypes.instanceOf(Collection),
        imageType: PropTypes.oneOf(['poster', 'backdrop', 'background', 'trailer']),
        useImageLoadingIndicator: PropTypes.bool,
        onClick: PropTypes.func,
        onMouseOver: PropTypes.func,
        onMouseOut: PropTypes.func,
        className: PropTypes.string
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        collection: null,
        imageType: 'poster',
        useImageLoadingIndicator: true
    };

    /**
     * React: state
     */
    state = {
        imageLoaded: null
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        if (this.props.collection) {
            var imageNode = ReactDOM.findDOMNode(this).getElementsByTagName('img')[1];
            imageNode.addEventListener("load", this._setImageLoadedStatus);
            imageNode.addEventListener("error", this._setImageLoadedStatus);
        }
    }

    /**
     * React: UNSAFE_componentWillReceiveProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.collection.id !== this.props.collection.id) {
            this.setState({imageLoaded: null});
        }
    }

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: componentDidMount
     */
    componentDidUpdate() {
        if (this.props.collection) {
            var imageNode = ReactDOM.findDOMNode(this).getElementsByTagName('img')[1];
            imageNode.addEventListener("load", this._setImageLoadedStatus);
            imageNode.addEventListener("error", this._setImageLoadedStatus);
        }
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        var imageNode = ReactDOM.findDOMNode(this).getElementsByTagName('img')[1];
        if (imageNode) {
            imageNode.removeEventListener("load", this._setImageLoadedStatus);
            imageNode.removeEventListener("error", this._setImageLoadedStatus);
        }
    }

    /**
     * React: render
     */
    render() {
        const collection = this.props.collection;

        const className = "media-item-image "
            + this.props.imageType
            + (this.state.imageLoaded === null ? " loading" : "")
            + (this.state.imageLoaded === false ? " failed" : "")
            + (!collection && !this.props.useImageLoadingIndicator ? " placeholder" : "")
            + (this.props.className ? ' ' + this.props.className : '');

        return (
            <div className={className}
                 onClick={this._onClick}
                 onMouseOver={this._onMouseOver}
                 onMouseOut={this._onMouseOut}>
                <img src={Config['PLACEHOLDER_IMAGE_' + this.props.imageType.toUpperCase()]} alt=""/>
                {collection ? (
                    <img className="content"
                         src={collection.imageUrl}
                         alt={collection.title}/>
                ) : null}
                {this.props.useImageLoadingIndicator ? (<Spinner />) : null}
            </div>
        );
    }

    /**
     * Set image loaded status
     *
     * @private
     */
    _setImageLoadedStatus = (event) => {
        this.setState({
            imageLoaded: event.type !== 'error'
        });
    };

    /**
     * On click
     */
    _onClick = () => {
        if (typeof this.props.onClick === 'function') {
            this.props.onClick();
        }
    };

    /**
     * On mouse over
     */
    _onMouseOver = () => {
        if (typeof this.props.onMouseOver === 'function') {
            this.props.onMouseOver();
        }
    };

    /**
     * On mouse out
     */
    _onMouseOut = () => {
        if (typeof this.props.onMouseOut === 'function') {
            this.props.onMouseOut();
        }
    };
}
