"use strict";

import platform from 'platform';
import Config from './Config';

/**
 * Get device
 *
 * @return {*}
 */
export function getDeviceTypeFromPlatformInfo() {
    if (Config.USE_DEFAULT_DEVICE_TYPE) return Config.DEFAULT_DEVICE_TYPE;

    let platformOS = 'OtherOS';
    if (platform.os.toString().indexOf('Windows') > -1) platformOS = 'Windows';
    if (platform.os.toString().indexOf('OS X') > -1) platformOS = 'Mac';

    let platformName = 'Other';
    if (['Chrome', 'Firefox', 'IE', 'Edge', 'Safari'].indexOf(platform.name) > -1) platformName = platform.name;

    return `${platformOS}${platformOS !== 'OtherOS' ? '-' + platformName : ''}`;
}

/* List of available types on Vubiquity FFAPI service

 Windows-Chrome
 Windows-Firefox
 Windows-IE
 Windows-Edge
 Windows-Safari
 Windows-Other

 Mac-Chrome
 Mac-Firefox
 Mac-Safari
 Mac-Other

 OtherOS
*/
