"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import MediaStore from '../stores/MediaStore';
import moment from 'moment';
import {MediaItemClassification} from '../types/MediaItem';
import AppStore from '../stores/AppStore';
import MediaGallery from './elements/MediaGallery.react';
import FilterBar from './elements/FilterBar.react';

/**
 * SearchPage component
 */
export default class SearchPage extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        params: PropTypes.object
    };

    /**
     * React: state
     */
    state = {
        mediaBucket: null,
        activeFilters: AppStore.activeFilters
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        // add store listeners
        MediaStore.addUpdateListener(this._setMediaBucket);
        AppStore.addUpdateListener(this._setActiveFilters);
    }

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AppStore.removeUpdateListener(this._setActiveFilters);
        MediaStore.removeUpdateListener(this._setMediaBucket);
        this.setState({mediaBucket: null});
    }

    /**
     * React: render
     */
    render() {
        var mediaBucket = this.state.mediaBucket;
        var media = mediaBucket ? mediaBucket.media().filter( item => {
            const range = moment().range(item.startAvailability, item.endAvailability);
            return range.contains(new Date()) && item.offers.length > 0 || item.classification === MediaItemClassification.SERIES;
        }) : null;
        var mediaGalleryTitle = ['all', 'movie', 'series'].indexOf(MediaStore.searchQueryString) != -1
            ? (MediaStore.searchQueryString === 'movie' ? 'All Movies' : (MediaStore.searchQueryString === 'series' ? 'All TV Shows' : 'All Movies & TV Shows'))
            : AppStore.translate('view.search_page.title', {resultTotalCount: media ? media.size: 0, searchQuery: MediaStore.searchQueryString || "/"});

        return (
            <main>
                <FilterBar/>
                {MediaStore.searchQueryString === "" ? (
                    <div className="no-results">{AppStore.translate('view.search_page.message.no_search_query')}</div>
                ) : (media && media.size === 0 && !mediaBucket.updatePending ? (
                    <div className="no-results">
                        {AppStore.translate('view.search_page.message.no_media_for_search_query',
                            {searchQuery: MediaStore.searchQueryString, filtersActive: this.state.activeFilters.size > 0})}
                    </div>
                ) : (
                    <MediaGallery title={mediaGalleryTitle}
                                  className="search-results"
                                  media={media}
                                  mediaLoading={mediaBucket && mediaBucket.updatePending}/>
                ))}
            </main>
        );
    }

    /**
     * Set search mediaBucket
     */
    _setMediaBucket = () => {
        this.setState({
            mediaBucket: MediaStore.getMediaBucket(MediaStore.searchMediaBucketKey)
        });
    };

    /**
     * Set active filters
     */
    _setActiveFilters = () => {
        this.setState({
            activeFilters: AppStore.activeFilters
        });
    };
}
