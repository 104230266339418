"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import MediaItem, {MediaItemClassification} from '../../../types/MediaItem';
import AppStore from '../../../stores/AppStore';
import Button from '../../ui/Button.react';
import MediaItemComponentCTAs from '../../mixins/MediaItemComponentCTAs';

/**
 * MiscInformation component
 */
export default class MiscInformation extends MediaItemComponentCTAs(React.Component) {
    /**
     * React: propTypes
     */
    static propTypes = {
        mediaItem: PropTypes.instanceOf(MediaItem).isRequired,
        seasonMediaItem: PropTypes.instanceOf(MediaItem)
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        var mediaItem = this.props.mediaItem;
        //show release year from specific season not from whole Brand
        var releaseYear = mediaItem.classification === MediaItemClassification.SERIES ? this.props.seasonMediaItem.releaseYear : mediaItem.releaseYear;
        var certificate = mediaItem.classification === MediaItemClassification.SERIES ? this.props.seasonMediaItem.certificate : mediaItem.certificate;
        var watchlistMediaItem = mediaItem.classification === MediaItemClassification.SERIES
            ? this.props.seasonMediaItem : mediaItem;
        var basicInfoGridColumns = mediaItem.classification === MediaItemClassification.SERIES
            || mediaItem.classification === MediaItemClassification.TV_BOX_SET
            ? " col-xs-9 col-md-9 col-lg-8" : " col-xs-12 col-md-12 col-lg-12";

        return (
            <div className={"misc-information" + basicInfoGridColumns}>
                <div>
                    {mediaItem.classification === MediaItemClassification.MOVIE_BOX_SET ? (
                        <strong>{AppStore.translate('label.movie_count', {itemCount: mediaItem.childrenIds.length})}<br/></strong>
                    ) : (mediaItem.classification === MediaItemClassification.TV_BOX_SET ? (
                        <strong>{AppStore.translate('label.season_count', {itemCount: mediaItem.childrenIds.length})}<br/></strong>
                    ) : (
                        <strong>{releaseYear}<br/></strong>
                    ))}
                    <em>{AppStore.translate('label.certificate')} {certificate}<br/></em>
                    <div className="genre-list">{mediaItem.genres.join(', ')}</div>
                    {mediaItem.classification !== MediaItemClassification.MOVIE_BOX_SET && mediaItem.classification !== MediaItemClassification.TV_BOX_SET ? (
                        <div className="language-duration">
                            {mediaItem.classification !== MediaItemClassification.SERIES && mediaItem.classification !== MediaItemClassification.SEASON
                                ? `${mediaItem.duration} ${AppStore.translate('label.duration_min')} / ${mediaItem.audioLanguages.join(', ')}` : null}
                        </div>
                    ) : null}

                    {watchlistMediaItem.watchlistAvailable ? (
                        <Button shape="square-chromeless"
                                className={'watchlist-button watchlist-add'}
                                disabled={watchlistMediaItem.updatePending}
                                updatePending={watchlistMediaItem.watchlistPending}
                                onClick={this._toggleMediaItemWatchlistStatus.bind(null, watchlistMediaItem)}>
                            {watchlistMediaItem.isOnWatchlist
                                ? (<i className="ui-icon icon-remove"/>)
                                : (<i className="ui-icon icon-add"/>)}
                            {AppStore.translate('button.my_watchlist')}
                        </Button>
                    ) : null}
                </div>
            </div>
        );
    }
}
