"use strict";

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Checkbox component
 */
export default class Form extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        onSubmit: PropTypes.func,
        updatePending: PropTypes.bool,
        disabled: PropTypes.bool,
        error: PropTypes.string,
        className: PropTypes.string
        // ,
        // value: PropTypes.string,
        // label: PropTypes.string,
        // style: PropTypes.object,
        // checked: PropTypes.bool
        // useStatusIcon: PropTypes.bool,
        // statusIcon: PropTypes.string,
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        updatePending: false,
        disabled: false
    };

    /**
     * React: state
     */
    state = {
        // value: this.props.value
        disabled: this.props.disabled,
        updatePending: this.props.updatePending
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {

    }

    UNSAFE_componentWillMount(){
        var stateMap = {};

        if(Array.isArray(this.props.children)) {
            this.props.children.forEach((element) => {
                stateMap[element.props.name]=element.props.value;
            });
        }

        this.setState(stateMap);
    }

    /**
     * React: UNSAFE_componentWillReceiveProps
     */
    // UNSAFE_componentWillReceiveProps(nextProps) {
    //     console.log(nextProps.disabled);
    // }

    /**
     * React: render
     */
    render() {
        var formElements = [];
        var stateMap = {};

        if(Array.isArray(this.props.children)) {
            this.props.children.forEach((element, index) => {
                var newElement = React.cloneElement(element, {
                    value: this.state[element.props.name],
                    disabled: this.state.disabled,
                    updatePending: this.state.updatePending,
                    onChange: this._onChangeFormElement.bind(null, element.props.name),
                    key: index
                });

                stateMap[element.props.name]=element.props.value;

                formElements.push(newElement);
            });
        }

        return (
            <div className={"form-wrapper " + this.props.className}>
                <form onSubmit={this._formSubmit}>
                    {formElements}
                    <button type='submit' onClick={this._submitForm}>Submit</button>
                </form>
            </div>
        );
    }

    // _onChange = (event) => {
    //     if(typeof this.props.onChange==='function'){
    //         this.props.onChange(event.target.value);
    //     }
    // };
    //
    // _onClick = (event) => {
    //     if(typeof this.props.onClick==='function'){
    //         this.props.onClick(event.target.value);
    //     }
    // };

    _onChangeFormElement = (name, value) => {
        var newValue = {};
        newValue[name] = value;
        this.setState(newValue);
    };

    _submitForm = (event) => {
        event.preventDefault();

        if(typeof this.props.onSubmit==='function') {
            this.props.onSubmit(this.state);
        }
    };
}
