"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import Router from '../../../lib/Router';
import {PageRowType} from '../../../types/PageRow';
import AppStore from '../../../stores/AppStore';
import MainNavigationSection from '../../../types/MainNavigationSection';

/**
 * NavigationMain component
 */
export default class NavigationMain extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        mainNavigationSection: PropTypes.instanceOf(MainNavigationSection).isRequired
    };

    /**
     * React: render
     */
    render() {
        var mainNavigationSection = this.props.mainNavigationSection;

        return (
            <div className="nav-menu-popup">
                <ul className={'primary' + this._calculateColumnClassName(mainNavigationSection.items)}>
                    {mainNavigationSection.items.toArray()
                        .filter( mainNavItem => {
                            if (AppStore.emptyCollectionList.length === 0) return mainNavItem;
                            return AppStore.emptyCollectionList.find(el => el.toUpperCase() !== mainNavItem.label.toUpperCase());
                        }
                        )
                        .map((mainNavigationItem, index) => {
                            // collection
                            if (mainNavigationItem.type === PageRowType.COLLECTION
                                && AppStore.collectionListByTitle.get(mainNavigationItem.identifier)) {
                                let collection = AppStore.collectionListByTitle.get(mainNavigationItem.identifier);
                                return (
                                    <li key={index}>
                                        <Link
                                            to={Router.COLLECTIONS(collection, mainNavigationSection.classification)}>
                                            {mainNavigationItem.label}
                                        </Link>
                                    </li>
                                );
                            }
                            // promotion [PromotionItemType.MEDIA_ITEM]
                            if (mainNavigationItem.type === PageRowType.PROMOTION) {
                                return (
                                    <li key={index}>
                                        <Link
                                            to={mainNavigationItem.identifier.indexOf('SPECIAL_OFFERS_') === -1
                                                ? Router.PROMOTIONS(mainNavigationItem.identifier, mainNavigationSection.classification)
                                                : Router.PROMOTION_COLLECTIONS(mainNavigationItem.identifier)}>
                                            {mainNavigationItem.label}
                                        </Link>
                                    </li>
                                );
                            }
                            // search all
                            if (mainNavigationItem.type === PageRowType.SEARCH_ALL) {
                                return (
                                    <li key={index}>
                                        <Link to={Router.ALL_MEDIA(mainNavigationSection.classification)}>
                                            {mainNavigationItem.label}
                                        </Link>
                                    </li>
                                );
                            }
                        })}
                </ul>
                {mainNavigationSection.displayGenres ? (
                        <ul className={'regular' + this._calculateColumnClassName(AppStore.genreList)}>
                            {AppStore.genreList.toArray().map((genre) => {
                                return (
                                    <li key={genre.id}><Link
                                        to={Router.GENRES(genre.id, mainNavigationSection.classification)}>{genre.name}</Link>
                                    </li>
                                );
                            })}
                        </ul>
                    ) : null}
            </div>
        );
    }

    /**
     * Calculate column class name
     *
     * @param items
     * @private
     */
    _calculateColumnClassName = items => items.size < 15 ? ''
        : (items.size < 30 ? ' two-column'
        : (items.size < 45 ? ' three-column' : ' four-column'));
}
