"use strict";

import React from 'react';
import MediaActions from '../actions/MediaActions';
import { MediaItemClassification } from '../types/MediaItem';
import MediaStore from '../stores/MediaStore';
import AuthStore from '../stores/AuthStore';
import ShakaPlayerPrototype from './elements/media-player/ShakaPlayerPrototype.react';
import Spinner from './ui/Spinner2.react';

/**
 * TestVideoPage component
 */
export default class TestVideoPage extends React.Component {
  /**
   * React: state
   */
  state = {
    mediaBucket: null
  };

  /**
   * Bucket keys
   */
  _mediaBucketKey = null;

  /**
   * Constructor
   */
  constructor(props) {
    super(props);

    if (AuthStore.isSignedIn()) {
      this._mediaBucketKey = MediaActions.getLibraryMedia();
    }
  }

  /**
   * React: componentDidMount
   */
  componentDidMount() {
    MediaStore.addUpdateListener(this._setMediaBucket);
  }

  /**
   * React: componentWillUnmount
   */
  componentWillUnmount() {
    MediaStore.removeUpdateListener(this._setMediaBucket);
    this._mediaBucketKey = MediaStore.destroyMediaBucket(this._mediaBucketKey);
  }

  /**
   * React: render
   */
  render() {
    let mediaBucket = this.state.mediaBucket;
    let media = mediaBucket ? mediaBucket.media() : null;
    media = media ? media.filter(mediaItem => mediaItem.classification === MediaItemClassification.MOVIE
      || mediaItem.classification === MediaItemClassification.EPISODE) : null;

    return (
      <main className="test-page">
        {AuthStore.isSignedIn() ? (
          <div className='test-page-player-container'>
            <ShakaPlayerPrototype
              poster={"//shaka-player-demo.appspot.com/assets/poster.jpg"}
              video={"//vm-amd-p001.cdn.vubiquity.com/680adfcc-9cde-4f12-aaf6-a51647a580a4/PREV0000000002871880_virgin_store_preview_nodrm_bento4_Trailer_2.0EN.ism/manifest(format=mpd-time-csf)"}
              autoPlay={false}
            />
          </div>
        ) : null}
      </main>
    );
  }

  /**
   * Set mediaBucket
   */
  _setMediaBucket = () => {
    this.setState({
      mediaBucket: MediaStore.getMediaBucket(this._mediaBucketKey)
    });
  };
}

var _fixedStreams = {
  1: {
    title: 'Clear',
    duration: '53min',
    video: '//dynamicstreaming.streaming.mediaservices.windows.net/919bf867-5d34-4f0e-a858-f3ee21423d37/CN-10316150-FM-01_SD_SMOOTHSTREAM.ism/manifest',
    type: 'application/vnd.ms-sstr+xml',
    progress: 25,
    details: null
  },
  2: {
    title: 'HLS v3 (aes-128)',
    duration: '52min',
    video: '//dynamicstreaming.streaming.mediaservices.windows.net/046e8d65-8969-4596-b51a-b8b251a56a20/CN-10316151-FM-01_SD_SMOOTHSTREAM.ism/manifest(format=m3u8-aapl-v3)',
    type: 'application/vnd.ms-sstr+xml',
    progress: 0,
    details: null
  },
  3: {
    title: 'CENC/PlayReady (IE11+ only)',
    duration: '47min',
    video: '//dynamicstreaming.streaming.mediaservices.windows.net/dd4980b4-d111-44db-9d15-9dc067f03055/CN-10316147-FM-01_SD_SMOOTHSTREAM.ism/manifest(format=mpd-time-csf)',
    type: 'application/vnd.ms-sstr+xml',
    progress: 0,
    details: null
  },
  4: {
    title: 'CENC/Widevine (Chrome only)',
    duration: '49min',
    video: '//dynamicstreaming.streaming.mediaservices.windows.net/80cf5f8b-2435-4dfc-8793-0a852c194415/CN-10316149-FM-01_SD_SMOOTHSTREAM.ism/manifest(format=mpd-time-csf)',
    type: 'application/vnd.ms-sstr+xml',
    progress: 100,
    details: null
  },
  5: {
    title: 'AMS Test SS',
    duration: 'Xmin',
    video: 'https://vucsamsstgne.streaming.mediaservices.windows.net/7c5429d7-c8e6-4a29-8f2e-23466ed068b9/CONT0000000001887674_vu_movie_sd_devices_SD_2.0EN.ism/manifest',
    type: 'application/vnd.ms-sstr+xml',
    progress: 100,
    details: null
  },
  6: {
    title: 'AMS Test HLS v3',
    duration: 'Xmin',
    video: 'https://vucsamsstgne.streaming.mediaservices.windows.net/7c5429d7-c8e6-4a29-8f2e-23466ed068b9/CONT0000000001887674_vu_movie_sd_devices_SD_2.0EN.ism/manifest(format=m3u8-aapl-v3)',
    type: 'application/vnd.ms-sstr+xml',
    progress: 100,
    details: null
  },
  7: {
    title: 'AMS Test DASH',
    duration: 'Xmin',
    video: 'https://vucsamsstgne.streaming.mediaservices.windows.net/7c5429d7-c8e6-4a29-8f2e-23466ed068b9/CONT0000000001887674_vu_movie_sd_devices_SD_2.0EN.ism/manifest(format=mpd-time-csf)',
    type: 'application/vnd.ms-sstr+xml',
    progress: 100,
    details: null
  },
  8: {
    title: 'Oi Play Test',
    duration: 'Xmin',
    video: '//vucsamsstgbs.streaming.mediaservices.windows.net/eaa913f2-8992-44d3-a0ad-ece4c6d43978/CONT0000000001869604_caas_sd_SD_2.0EN.ism/manifest(format=mpd-time-csf)',
    type: 'application/vnd.ms-sstr+xml',
    progress: 100,
    details: null
  },
  9: {
    title: 'Trailer Rogue Nation',
    duration: 'Xmin',
    video: '//vucsamsprodne.streaming.mediaservices.windows.net/27ab1bb4-b24e-4a98-af32-fe957833ca3a/PREV0000000001866274_vu_preview_Trailer.ism/manifest(format=mpd-time-csf)',
    type: 'application/vnd.ms-sstr+xml',
    progress: 100,
    details: null
  },
  10: {
    title: 'Trailer Jungle Book',
    duration: 'Xmin',
    video: '//vucsamsprodne.streaming.mediaservices.windows.net/42f20a17-343e-448e-a5ca-2f776e261a2d/PREV0000000001866208_vu_preview_Trailer.ism/manifest(format=mpd-time-csf)',
    type: 'application/vnd.ms-sstr+xml',
    progress: 100,
    details: null
  }
};
