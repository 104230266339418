"use strict";

import Config from "./Config";

/**
 * Shaka Media Player
 */
export default class ShakaMediaPlayer {
  static initialize() {
    let scriptElement = document.createElement("script");
    let linkElement = document.createElement("link");
    scriptElement.src = "https://cdnjs.cloudflare.com/ajax/libs/shaka-player/" + Config.SHAKA_MEDIA_PLAYER_VERSION + "/shaka-player.ui.min.js";
    linkElement.href = "https://cdnjs.cloudflare.com/ajax/libs/shaka-player/" + Config.SHAKA_MEDIA_PLAYER_VERSION + "/controls.min.css";
    linkElement.type = "text/css";
    linkElement.rel = "stylesheet";
    document.head.appendChild(scriptElement);
    document.head.appendChild(linkElement);
  }
}
