"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import MediaItem, {MediaItemClassification} from '../../../types/MediaItem';
import AppStore from '../../../stores/AppStore';
import CTAButtons from './CTAButtons.react';
import CTAButtonsBottom from './CTAButtonsBottom.react';

/**
 * Content component
 *
 * @extends {React.Component}
 */
export default class Content extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        mediaItem: PropTypes.instanceOf(MediaItem).isRequired,
        shouldCTAButtonsBeVisible: PropTypes.bool,
        isPurchaseEnabled: PropTypes.bool
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        isPurchaseEnabled: true
    };

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        var mediaItem = this.props.mediaItem;

        return (
            <div className="movie-tile-bubble-inner">
                <h2>{mediaItem.title}</h2>
                {mediaItem.subtitle ? (<h4>{ mediaItem.subtitle }</h4>) : null}

                <div className="non-rented-top-info">
                    <div className="minimal-info-container">
                        <span>{mediaItem.releaseYear}</span>|
                        <span>{AppStore.translate('label.certificate')} {mediaItem.certificate}</span>
                        {mediaItem.duration
                        && mediaItem.classification !== MediaItemClassification.SERIES
                        && mediaItem.classification !== MediaItemClassification.SEASON
                            ? (<span>| {this._formatTime(mediaItem.duration)}</span>) : null}

                        {mediaItem.classification === MediaItemClassification.SERIES
                            ? mediaItem.childrenCount !== 0 && (
                                <span>
                                    | {mediaItem.childrenCount} {`${mediaItem.childrenCount > 1 ? 'Seasons' : 'Season'}`}
                                </span>
                            ) : null}
                    </div>
                </div>

                <CTAButtons mediaItem={mediaItem}
                            shouldCTAButtonsBeVisible={this.props.shouldCTAButtonsBeVisible}
                            isPurchaseEnabled={this.props.isPurchaseEnabled} />
                <div className="description-container">
                    <p>{mediaItem.shortSynopsis || (mediaItem.mediumSynopsis ? mediaItem.mediumSynopsis.substr(0, 60)+'…' : '')}</p>
                    {mediaItem.contentAdvice ? (
                        <div className="advice-container">
                            {AppStore.translate('label.content_advice')} {mediaItem.contentAdvice}
                        </div>
                    ) : null}
                </div>

                <CTAButtonsBottom mediaItem={mediaItem}/>
            </div>
        );
    }

    /**
     * Format time in hours and mins ( Xhr Xmin )
     *
     * @private
     */
    _formatTime = (durationMin) => {
        if (durationMin < 60 ) {
            return durationMin + 'min';
        } else {
            return `${Math.floor(durationMin / 60)}hr ${durationMin % 60}min`;
        }
    };
}
