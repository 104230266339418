/* eslint-disable import/no-cycle */
import React from 'react';
import moment from 'moment';
import {Link} from 'react-router';
import Router from '../lib/Router';
import UIActions from '../actions/UIActions';
import EntitlementActions from '../actions/EntitlementActions';
import EntitlementStore from '../stores/EntitlementStore';
import AppStore from '../stores/AppStore';
import Spinner from './ui/Spinner2.react';
import Button from './ui/Button.react';
import RenameDevicePopupContent from './elements/RenameDevicePopupContent.react';

/**
 * AccountMyDevicesPage component
 */
export default class AccountMyDevicesPage extends React.Component {
    /**
     * React: state
     */
    state = {
        registeredDevices: null,
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        EntitlementStore.addUpdateListener(this.setRegisteredDevices);
        EntitlementActions.getRegisteredDevices({includeInactiveDevices: false});

        document.body.className += ' black-background';
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        EntitlementStore.removeListener(EntitlementStore.STORE_UPDATED, this.setRegisteredDevices);
        this.setState({registeredDevices: null});

        document.body.className = document.body.className.replace(/black-background/, '');
    }

    /**
     * Set registered devices
     *
     * @private
     */
    setRegisteredDevices = () => {
        this.setState({
            registeredDevices: EntitlementStore.registeredDevices,
        });
    };

    /**
     * Confirm registered device removal
     *
     * @param registeredDevice
     * @private
     */
    confirmRegisteredDeviceRemoval = registeredDevice => {
        if (registeredDevice.deviceTypeName === 'Tivo') {
            UIActions.showCustomOverlay((
                <div className="alert-box-size col-xs-12 col-sm-8 col-md-6">
                    <h2 className="overlay-header">
                        {AppStore.translate('view.account_page.message.remove_other_device_title').toUpperCase()}
                    </h2>
                    <div className="overlay-content">
                        <p>{AppStore.translate('view.account_page.message.remove_stb_device')}</p>

                        <div className="buttons-container center-button">
                            <Button
                                type="button"
                                shape="regular"
                                className="cta-payment-positive uppercase-button"
                                onClick={UIActions.closeCustomOverlay}
                            >
                                {AppStore.translate('button.ok')}
                            </Button>
                        </div>
                    </div>
                </div>
            ));
            return;
        }

        if (registeredDevice.deviceId !== AppStore.deviceId) {
            UIActions.showCustomOverlay((
                <div className="alert-box-size col-xs-12 col-sm-8 col-md-6">
                    <h2 className="overlay-header">
                        {AppStore.translate('view.account_page.message.remove_other_device_title').toUpperCase()}
                    </h2>
                    <div className="overlay-content">
                        <p>{AppStore.translate('view.account_page.message.remove_other_device')}</p>

                        <div className="buttons-container center-button">
                            <Button
                                type="button"
                                shape="regular"
                                className="cta-payment-positive uppercase-button"
                                onClick={UIActions.closeCustomOverlay}
                            >
                                {AppStore.translate('button.ok')}
                            </Button>
                        </div>
                    </div>
                </div>
            ));
            return;
        }

        if (registeredDevice.removalAvailableDate > moment()) {
            UIActions.showCustomOverlay((
                <div className="alert-box-size col-xs-12 col-sm-8 col-md-6">
                    <h2 className="overlay-header">
                        {AppStore.translate('view.account_page.message.remove_device_unavailable_title')}
                    </h2>
                    <div className="overlay-content">
                        <p>{AppStore.translate('view.account_page.message.remove_device_unavailable',
                            {removalAvailableDate: registeredDevice.removalAvailableDate.format(AppStore.translate('view.account_page.date_time.removal_available_date'))})}
                        </p>
                        <div className="buttons-container center-button">
                            <Button
                                type="button"
                                shape="regular"
                                className="cta-cancel uppercase-button"
                                onClick={UIActions.closeCustomOverlay}
                            >
                                {AppStore.translate('button.continue')}
                            </Button>
                        </div>
                    </div>
                </div>
            ));
            return;
        }

        if (registeredDevice.deviceId === AppStore.deviceId) {
            UIActions.showCustomOverlay((
                <div className="alert-box-size col-xs-12 col-sm-8 col-md-6">
                    <h2 className="overlay-header">
                        {AppStore.translate('view.account_page.message.remove_current_device_title')}
                    </h2>
                    <div className="overlay-content">
                        <p>{AppStore.translate('view.account_page.message.remove_current_device')}</p>

                        <div className="buttons-container">
                            <Button
                                type="button"
                                shape="regular"
                                className="cta-cancel uppercase-button"
                                onClick={UIActions.closeCustomOverlay}
                            >
                                {AppStore.translate('button.cancel')}
                            </Button>
                            <Button
                                type="button"
                                shape="regular"
                                className="cta-payment-positive uppercase-button"
                                onClick={() => this.removeRegisteredDevice(registeredDevice)}
                            >
                                {AppStore.translate('button.continue')}
                            </Button>
                        </div>
                    </div>
                </div>
            ));
        }
    };

    /**
     * Rename registered device
     *
     * @param registeredDevice
     * @private
     */
    confirmRegisteredDeviceRename = registeredDevice => {
        if (registeredDevice.deviceTypeName === 'Tivo') return;

        UIActions.showCustomOverlay(
            <RenameDevicePopupContent registeredDevice={registeredDevice} />
        );
    };

    /**
     * Remove registered device
     */
    removeRegisteredDevice = registeredDevice => {
        UIActions.closeCustomOverlay();
        EntitlementActions.removeRegisteredDevice({
            registeredDeviceId: registeredDevice.id,
            deviceId: registeredDevice.deviceId,
        });
    };

    /**
     * React: render
     */
    render() {
        const registeredDevices = this.state.registeredDevices;
        return (
            <main className="account-page container">
                <div className="row">
                    <div className="account-page-content col-xs-12 col-md-8 col-lg-6 center-column">
                        <div className="form-title">
                            <h4>{AppStore.translate('view.account_page.title.device_settings').toUpperCase()}</h4>
                            {registeredDevices ? (
                                <h6>
                                    {AppStore.translate('view.account_page.message.devices_status', {registeredDevicesCount: registeredDevices.size})}
                                </h6>
                            ) : null}
                        </div>
                        <div className="registered-devices-inner-container">
                            {registeredDevices ? (
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{AppStore.translate('view.account_page.table_title.device')}</th>
                                            <th>{AppStore.translate('view.account_page.table_title.added_date')}</th>
                                            <th>{AppStore.translate('view.account_page.table_title.actions')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {registeredDevices.toArray().sort((left, right) => {
                                            // sort in ascending order : oldest first
                                            return moment.utc(left.updatedDate).diff(moment.utc(right.updatedDate));
                                        }).map((registeredDevice, index) => {
                                            return (
                                                <tr key={registeredDevice.id}>
                                                    <td>
                                                        <span className="device-index">{index + 1}.</span>
                                                        <span
                                                            // eslint-disable-next-line max-len
                                                            onClick={() => this.confirmRegisteredDeviceRename(registeredDevice)}
                                                            className={registeredDevice.deviceTypeName === 'Tivo'
                                                                ? `device-name device-name-tivo` : 'device-name'}
                                                        >
                                                            {registeredDevice.deviceTypeName === 'Tivo'
                                                                ? registeredDevice.deviceName.substring(0, 20)
                                                                : registeredDevice.deviceName
                                                            }

                                                        </span>
                                                    </td>
                                                    <td>{registeredDevice.updatedDate.format(AppStore.translate('view.account_page.date_time.added_date'))}</td>
                                                    <td>
                                                        <Button
                                                            shape="icon-regular"
                                                            style={registeredDevice.deviceTypeName === 'Tivo' ? {
                                                                color: '#c1c1c1',
                                                            } : null}
                                                            updatePending={registeredDevice.updatePending}
                                                            disabled={registeredDevice.updatePending
                                                                || !registeredDevice.removalAvailable}
                                                            // eslint-disable-next-line max-len
                                                            onClick={() => this.confirmRegisteredDeviceRemoval(registeredDevice)}
                                                        >
                                                            <i className="ui-icon icon-close-circle" />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        {[...new Array(5 - registeredDevices.size)].map((value, index) => {
                                            index += registeredDevices.size + 1;
                                            return (
                                                <tr key={index}>
                                                    <td colSpan="3">
                                                        <span className="device-index">{index}.</span>
                                                        <span
                                                            className="device-name"
                                                        >{AppStore.translate('view.account_page.table_value.empty_slot')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            ) : (
                                <Spinner />
                            )}
                            <p>
                                {AppStore.translate('view.account_page.message.contact_support_to_remove_device')}
                                <Link
                                    to={Router.CONTACT}
                                >{AppStore.translate('view.account_page.message.contact_support_to_remove_device_2')}
                                </Link>
                                {AppStore.translate('view.account_page.message.contact_support_to_remove_device_3')}
                            </p>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
