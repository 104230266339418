"use strict";

import Immutable from 'immutable';

/**
 * SupportedDeviceDTO definition
 *
 * @typedef {Object} SupportedDeviceDTO
 * @property {?string} DeviceType - The type or category of device. You know who you are ;-)
 * @property {?boolean} Downloadable - Is Downloading allowed?
 * @property {?boolean} Streamable - Is Streaming allowed?
 */

/**
 * SupportedDevice definition
 *
 * @typedef {Immutable.Record} SupportedDevice
 * @property {?string} deviceType
 * @property {?boolean} downloadable
 * @property {?boolean} streamable
 */

/**
 * SupportedDevice record
 *
 * @type {SupportedDevice}
 */
class SupportedDevice extends Immutable.Record({
    deviceType: null,
    downloadable: null,
    streamable: null
}) {
    /**
     * Set values from data transfer object
     *
     * @param {SupportedDeviceDTO} dataTransferObject
     * @return {SupportedDevice}
     */
    fromDTO(dataTransferObject) {
        return new SupportedDevice({
            ...this.toJS(),
            deviceType: typeof dataTransferObject.DeviceType !== 'undefined' ? dataTransferObject.DeviceType : this.deviceType,
            downloadable: typeof dataTransferObject.Downloadable !== 'undefined' ? dataTransferObject.Downloadable : this.downloadable,
            streamable: typeof dataTransferObject.Streamable !== 'undefined' ? dataTransferObject.Streamable : this.streamable
        });
    }
}

export default SupportedDevice;
