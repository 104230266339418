"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Config from '../../lib/Config';
import Router from '../../lib/Router';
import AnalyticsActions from '../../actions/AnalyticsActions';
import MediaItem from '../../types/MediaItem';
import PromotionItem from '../../types/PromotionItem';
import Pagination from '../ui/Pagination.react';
import MediaPlayer from './MediaPlayer.react';
import BluredImageSvg from '../ui/BluredImageSvg.react';

/**
 * PromotionCarousel component
 */
export default class PromotionCarousel extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        media: ImmutablePropTypes.mapOf(PropTypes.instanceOf(MediaItem)),
        promotionItems: PropTypes.arrayOf(PropTypes.instanceOf(PromotionItem)),
        className: PropTypes.string
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        media: null
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * Constructor
     */
    constructor(props, context) {
        super(props, context);
        this.state = {
            activeSlide: this.props.media && this.props.media.size > 0 ? this.props.media.first().id : null,
            intervalId: setInterval(this._rotateSlides, 5000),
            controlsHidden: true,
            isPaused: false,
            isPlaybackActive: false
        };
    }

    /**
     * React: UNSAFE_componentWillReceiveProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        clearInterval(this.state.intervalId);

        this.setState({
            intervalId: setInterval(this._rotateSlides, 5000),
            activeSlide: nextProps.media && nextProps.media.size > 0 ? nextProps.media.first().id : null
        });
    }

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        clearInterval(this.state.intervalId);

        this.setState({
            intervalId: 0,
            activeSlide: null
        });
    }

    /**
     * React: render
     */
    render() {
        let slides = [];
        let index = 0;
        let activeIndex = 0;
        if (this.props.media !== null) {
            this.props.media.forEach((mediaItem, key) => {
                let promotionItem = this.props.promotionItems
                    ? this.props.promotionItems.find(promotionItem => promotionItem.promotionalLink === mediaItem.id) : null;
                let slideImage = promotionItem ? promotionItem.imageUrl : mediaItem.getImageLocation('landscape');
                slides.push(
                    <div className={"slide" + (this.state.activeSlide === key ? ' active' : '')}
                         key={key}>

                        <div className={"blur-background" + (this.state.activeSlide === key ? ' active' : '')}
                             style={{backgroundImage: `url(${slideImage})`}}
                             key={key}>
                        </div>

                        <BluredImageSvg imageUrl={slideImage} height="200%" width="100%"/>

                        <div className="container">
                            <div className="slide-bg"
                                 style={{backgroundImage: `url(${slideImage})`}}
                                 onClick={this._onPromotionClick.bind(null, mediaItem)}>
                                <div className="container">
                                    <span className="title">{mediaItem.title}</span>
                                </div>
                            </div>

                            {this.state.activeSlide === key && mediaItem.trailerUrl ? (
                                <MediaPlayer mediaItem={mediaItem}
                                             playerType={Config.MEDIA_PLAYER_TRAILER_TYPE}
                                             availablePlayerAction={['trailer']}
                                             onPlaybackStart={() => this._onPlaybackChanged(true)}
                                             onPlaybackStop={() => this._onPlaybackChanged(false)}/>
                            ) : null}

                        </div>
                    </div>
                );
                activeIndex = this.state.activeSlide === key ? index : activeIndex;
                index += 1;
            });
        }

        let className = 'promotion-carousel'
            + (this.props.className ? ' ' + this.props.className : '');

        return slides.length === 0 ? null : (
            <div className={className}
                 onMouseEnter={this._onMouseEnter}
                 onMouseLeave={this._onMouseLeave}>
                {slides}
                <div className="carousel-nav"
                     onMouseOver={this._togglePaused.bind(null, true, false)}
                     onMouseOut={this._togglePaused.bind(null, false, false)}>
                    {!this.state.controlsHidden ? (
                        <Pagination pageCount={this.props.media.size}
                                    activePage={activeIndex}
                                    useArrows={false}
                                    playbackActive={this.state.isPlaybackActive}
                                    onPageSelect={this._goToSlide}/>
                    ) : null}
                </div>
            </div>
        );
    }

    /**
     * Rotate slides
     *
     * @private
     */
    _rotateSlides = () => {
        if (this.props.media && this.props.media.size > 0 && !this.state.isPaused) {
            let mediaSubset = this.props.media.skipUntil((promotion) => {
                return promotion.id === this.state.activeSlide;
            }).rest();

            this.setState({
                activeSlide: mediaSubset.size > 0 ? mediaSubset.first().id : this.props.media.first().id
            });
        }
    };

    /**
     * Go to slide
     *
     * @private
     */
    _goToSlide = (index) => {
        let promotion = this.props.media.toList().get(index);

        this.setState({
            activeSlide: promotion ? promotion.id : null
        }, () => this._togglePaused(true, false));
    };

    _onPlaybackChanged = isPlaybackActive => {
        this.setState({
            isPlaybackActive: isPlaybackActive
        }, () => isPlaybackActive ? this._togglePaused(true, true) : this._togglePaused(false, false)
        );
    };

    /**
     * Toggle paused
     *
     * @param {boolean} isPaused
     * @param {boolean} controlsHidden
     * @private
     */
    _togglePaused = (isPaused, controlsHidden) => {
        let intervalId = null;

        if (isPaused) {
            clearInterval(this.state.intervalId);
        } else {
            clearInterval(this.state.intervalId);
            if (!this.state.isPlaybackActive) {
                intervalId = setInterval(this._rotateSlides, 5000);
            }
        }

        this.setState({
            intervalId: intervalId,
            isPaused: isPaused,
            controlsHidden: controlsHidden
        });
    };

    /**
     * On promotion click
     */
    _onPromotionClick = (mediaItem) => {
        this.context.router.push(Router.MEDIA_ITEM(mediaItem));
        AnalyticsActions.trackEvent({
            category: 'promotion',
            action: 'Teaser',
            label: mediaItem.title
        });
    };

    /**
     * On mouse enter
     *
     * @private
     */
    _onMouseEnter = () => {
        this.setState({
            controlsHidden: false
        });
    };

    /**
     * On mouse leave
     *
     * @private
     */
    _onMouseLeave = () => {
        this.setState({
            controlsHidden: true
        });
    };
}
