"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import InputUIs from '../../mixins/InputUIs';

/**
 * Checkbox component
 */
export default class UiTextInput extends InputUIs(React.Component) {
    /**
     * React: propTypes
     */
    static propTypes = {
        type: PropTypes.oneOf(['text', 'email', 'password']),
        value: PropTypes.string,
        updatePending: PropTypes.bool,
        disabled: PropTypes.bool,
        // useStatusIcon: PropTypes.bool,
        // statusIcon: PropTypes.string,
        error: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
        onChange: PropTypes.func
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        type: 'text',
        value: '',
        updatePending: false,
        disabled: false
        // useStatusIcon: false
    };

    /**
     * React: state
     */
    state = {
        value: this.props.value
    };

    /**
     * React: UNSAFE_componentWillReceiveProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value
        });
    }

    /**
     * React: render
     */
    render() {

        var className = 'ui-text-input-wrapper ' +
            (this.props.inputClassName ? this.props.inputClassName : '') +
            (this.props.updatePending ? 'update-pending ' : '') +
            (this.props.useStatusIcon ? 'use-status-icon ' : '') +
            (this.props.statusIcon == 'success' ? 'status-success ' : '') +
            (this.props.statusIcon == 'error' ? 'status-error ' : '');

        // var error = this.props.error ? (<div className="error-message">{this.props.error}</div>) : '';

        return (
            <div className={className}>
                <input type={this.props.type}
                       value={this.state.value}
                       onChange={this._onChange}
                       onClick={this._onClick}
                       disabled={this.props.disabled}
                       style={this.props.style}/>
            </div>
        );
    }

    /**
     * On change
     */
    _onChange = (event) => {
        this.setState({
            value: event.target.value
        }, () => {
            if (typeof this.props.onChange === 'function') {
                this.props.onChange(this.state.value);
            }
        });
    };

    /**
     * On click
     */
    _onClick = () => {
        if (typeof this.props.onClick === 'function') {
            this.props.onClick(this.state.value);
        }
    };
}
