"use strict";

/**
 * Error response Stripe
 */
export default {
    CREDIT_CARD_ERROR: 'Your card number is invalid.',
    EXPIRATION_DATE_ERROR: 'Your card\'s expiration year is in the past.',
    SECURITY_CODE_ERROR: 'Your card\'s security code is incorrect.',
    CARD_DECLINED: 'Your card was declined.',
    CARD_INCOMPLETE: 'Your card number is incomplete.',
    PROCESSING_ERROR: 'An error occurred while processing your card. Try again in a little bit.'
};
