"use strict";

import Promise from 'bluebird';
import * as AppDispatcher  from '../dispatchers/AppDispatcher';
import Config from '../lib/Config';
import VubiquityDeviceFingerprint from '../lib/VubiquityDeviceFingerprint';
import {getDeviceTypeFromPlatformInfo} from '../lib/DeviceType';
import ActionTypes from './ActionTypes';
import ApiService from '../lib/api/ApiService';
import {AlertType} from '../types/Alert';
import AuthStore from '../stores/AuthStore';
import WatchlistActions from './WatchlistActions';
import EntitlementActions from './EntitlementActions';
import AuthActions from './AuthActions';

/**
 * Flux: AppActions
 */
export default {
    // Initialize session
    initializeSession: (params = {}) => {
        // device type
        AppDispatcher.dispatch(ActionTypes.STORE_DEVICE_TYPE, {
            deviceType: getDeviceTypeFromPlatformInfo()
        });

        // device id (vudevicefp)
        VubiquityDeviceFingerprint.initialize(() => new Fingerprint2({  // eslint-disable-line no-undef
            uid: !params.identity ? 'anonymous' : params.identity.username
        }).get(function(result) {
            AppDispatcher.dispatch(ActionTypes.STORE_FINGERPRINT_DEVICE_ID, {
                deviceId: result.replace(/(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/, "$1-$2-$3-$4-$5")
            });
        }));
    },

    // Pre-load content
    preLoadContent: (params = {}) => {
        // app setting
        AppDispatcher.dispatchAsync(
            Promise.all([
                ApiService.getAppSettings().catch(() => []),
                ApiService.getCollectionList().catch(() => []),
                ApiService.getPromotionList().catch(() => []),
                ApiService.getGenreList().catch(() => []),
                ApiService.getGenreIcons().catch(() => [])
            ]).then(results => {
                // Images from Vub API for genre list are not available. Only ImageURL which is null, so we are using
                // our ProxyAPI for getting active and inactive state of genre filter icons.
                // We make 2 API calls and then
                // merge genre objects results[3] and result [4]
                const mergedGenresArray = results[3].map( item => {
                    return {
                        ...item,
                        ...results[4][results[4].findIndex(el => item.Name === el.Name)]
                    };
                });
                return {
                    appSettingDTOCollection: results[0],
                    collectionDTOCollection: results[1],
                    promotionDTOCollection: results[2],
                    // genreDTOCollection: results[3],
                    // genreIconsDTOCollection: results[4],
                    genreDTOCollection: mergedGenresArray,
                    genreIconsDTOCollection: mergedGenresArray
                };
            }),
            {
                request: ActionTypes.REQUEST_GET_APP_SETTINGS,
                success: ActionTypes.REQUEST_GET_APP_SETTINGS_SUCCESS,
                failure: ActionTypes.REQUEST_GET_APP_SETTINGS_ERROR
            },
            params
        );

        // translations
        AppDispatcher.dispatchAsync(ApiService.getTranslations(params), {
            request: ActionTypes.REQUEST_GET_TRANSLATIONS,
            success: ActionTypes.REQUEST_GET_TRANSLATIONS_SUCCESS,
            failure: ActionTypes.REQUEST_GET_TRANSLATIONS_ERROR
        }, params);

        // certificate List
        AppDispatcher.dispatchAsync(
            ApiService.getCertificateList(params)
                .then(certificateDTOCollection => ({certificateDTOCollection})),
            {
                request: ActionTypes.REQUEST_GET_CERTIFICATE_LIST,
                success: ActionTypes.REQUEST_GET_CERTIFICATE_LIST_SUCCESS,
                failure: ActionTypes.REQUEST_GET_CERTIFICATE_LIST_ERROR
            },
            params
        );

        // platform articles
        AppDispatcher.dispatchAsync(
            ApiService.getPlatformHelp()
                .then(helpArticleDTOCollection => {
                    return ApiService.getPlatformTermsAndConditions()
                        .catch(() => "")
                        .then(termsAndConditions => {
                            if (termsAndConditions) {
                                helpArticleDTOCollection.push({
                                    Id: null,
                                    AppSettingGroupId: Config.APP_SETTINGS_GROUP,
                                    AffiliateId: Config.AFFILIATE_ID,
                                    ContextCode: 'terms_and_conditions',
                                    Ordinality: 0,
                                    Title: 'Terms and conditions',
                                    Body: termsAndConditions,
                                    ModifiedDate: null,
                                    ModifiedBy: null
                                });
                            }

                            return {platformArticleDTOCollection: helpArticleDTOCollection};
                        });
                }),
            {
                request: ActionTypes.REQUEST_GET_PLATFORM_ARTICLES,
                success: ActionTypes.REQUEST_GET_PLATFORM_ARTICLES_SUCCESS,
                failure: ActionTypes.REQUEST_GET_PLATFORM_ARTICLES_ERROR
            },
            params
        );

        if (AuthStore.isSignedIn()) {
            // user profile information
            AuthActions.getUserProfile(params);

            // recently viewed items
            EntitlementActions.getRecentlyViewedItems(params);

            // watchlist
            WatchlistActions.getWatchlist({...params, alertType: AlertType.PRE_ORDER});

            // current entitlements
            EntitlementActions.getCurrentEntitlements(params);

            // user contact preferences
            AuthActions.getUserPreferences(params);
        }
    },

    // Router
    storeRouterTransitionPath: (path) => {
        AppDispatcher.dispatch(ActionTypes.STORE_ROUTER_TRANSITION_PATH, {path});
    },

    // Filter actions
    loadSavedFilters: () => {
        AppDispatcher.dispatch(ActionTypes.LOAD_SAVED_FILTERS, {});
    },

    toggleFilter: (filterKey) => {
        AppDispatcher.dispatch(ActionTypes.TOGGLE_FILTER, {filterKey});
    },

    resetFilters: () => {
        AppDispatcher.dispatch(ActionTypes.RESET_FILTERS, {});
    },
    toggleNavigationSearchFocus: (isFocused) => {
        AppDispatcher.dispatch(ActionTypes.TOGGLE_NAVIGATION_FOCUS, {isFocused})
    },

    // Lookup address by postcode
    lookupAddressByPostcode: (params = {}) => {
        let promise = ApiService.lookupAddressByPostcode(params)
            .then(userAddressDTO => ({userAddressDTO}));

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_LOOKUP_ADDRESS_BY_POSTCODE,
            success: ActionTypes.REQUEST_LOOKUP_ADDRESS_BY_POSTCODE_SUCCESS,
            failure: ActionTypes.REQUEST_LOOKUP_ADDRESS_BY_POSTCODE_ERROR
        }, params);
    }
};
