"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {Link} from 'react-router';
import Router from '../../../lib/Router';
import shallowCompare from 'react-addons-shallow-compare';
import MediaItem, {MediaItemClassification} from '../../../types/MediaItem';
import MediaBucket from '../../../types/MediaBucket';
import AppStore from '../../../stores/AppStore';
import MediaItemImage from '../../elements/MediaItemImage.react';
import digitalCopyIcon from '../../../../assets/images/payment-icons/digital-copy.png';
import tvIcon from '../../../../assets/images/payment-icons/tv.png';
import laptopIcon from '../../../../assets/images/payment-icons/laptop.png';
import ipadIcon from '../../../../assets/images/payment-icons/ipad.png';
import mobileIcon from '../../../../assets/images/payment-icons/mobile.png';

/**
 * OrderDetails component
 */
export default class OrderDetails extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        media: ImmutablePropTypes.mapOf(PropTypes.instanceOf(MediaItem)),
        mediaBucket: PropTypes.instanceOf(MediaBucket).isRequired
    };

    /**
     * React: shouldComponentUpdate
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        const media = this.props.media;

        return (
            <div>
                {media ? media.toArray().map((mediaItem, index) => {
                    let parentMediaItem = null;
                    if(mediaItem.classification === MediaItemClassification.SEASON) {
                        parentMediaItem = this.props.mediaBucket.mediaItem(mediaItem.parentId);
                    }

                    return (
                        <div key={index} className="details-block-container">
                            <div className="details-title-container">
                                {index === 0 ? `Order details` : ''}
                            </div>
                            <div key={mediaItem.id} className="details-content-container order-details basic-information-container">
                                <div className="media-item-image-outer-container">
                                    <MediaItemImage
                                        mediaItem={mediaItem.classification === MediaItemClassification.EPISODE
                                            ? this.props.mediaBucket.mediaItemEpisodeParent(mediaItem)
                                            : mediaItem}
                                        imageType="poster"/>
                                </div>

                                <div className="information-block">
                                    <h2>
                                        {mediaItem.classification === MediaItemClassification.SEASON
                                            ? parentMediaItem.title : null}
                                        {mediaItem.classification === MediaItemClassification.EPISODE
                                            ? `${mediaItem.sortTitle} -` : null} {mediaItem.title}
                                    </h2>
                                    <div className="minimal-information">
                                        <span>{mediaItem.releaseYear}</span>
                                        <span className="dot"/>
                                        {mediaItem.classification !== MediaItemClassification.SERIES && mediaItem.classification !== MediaItemClassification.SEASON ? (
                                            <span>{this._formatTime(mediaItem.duration)}</span>
                                        ) : null}
                                        {mediaItem.classification !== MediaItemClassification.SERIES && mediaItem.classification !== MediaItemClassification.SEASON ? (
                                            <span className="dot"/>
                                        ) : null}
                                        <span>{AppStore.translate('label.certificate')} {mediaItem.certificate}</span>
                                    </div>
                                </div>

                                <div className="purchase-information-block">
                                    <div className="compatible-devices">
                                        <div className="payment-icon payment-icon-digital-copy">
                                            <img src={digitalCopyIcon}/>
                                        </div>
                                        <div className="payment-icon payment-icon-tv">
                                            <img src={tvIcon}/>
                                        </div>
                                        <div className="payment-icon payment-icon-laptop">
                                            <img src={laptopIcon} />
                                        </div>
                                        <div className="payment-icon payment-icon-ipad">
                                            <img src={ipadIcon} />
                                        </div>
                                        <div className="payment-icon payment-icon-mobile">
                                            <img src={mobileIcon} />
                                        </div>
                                    </div>
                                        <p className="annotation-text">
                                            This price includes a digital copy playable on your&nbsp;
                                            <Link to={Router.HELP}target="_blank">compatible device</Link>&nbsp;
                                            including your Virgin TV box.
                                        </p>
                                </div>
                            </div>
                        </div>
                    );
                }) : null}
            </div>
        );
    }

    /**
     * Format time in hours and mins ( Xhr Xmin )
     *
     * @private
     */
    _formatTime = (durationMin) => {
        if (durationMin < 60 ) {
            return durationMin + 'min';
        } else {
            return `${Math.floor(durationMin / 60)}hr ${durationMin % 60}min`;
        }
    };
}
