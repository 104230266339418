"use strict";

import Immutable from 'immutable';
import moment from 'moment';
import MediaItem from './MediaItem';

/**
 * RecentlyViewedItemDTO definition
 *
 * @typedef {Object} RecentlyViewedItemDTO
 * @property {?string} SupplierReference - The supplier reference for media item viewed ,
 * @property {?string} LastViewed - Date and time this entitlement was viewed ,
 * @property {?string} LastPlayPosition - The last play position for the item,
 * @property {?MediaItemDTO} MediaItemMidiDTO - Related Media Item (Midi)
 */

/**
 * RecentlyViewedItem definition
 *
 * @typedef {Immutable.Record} RecentlyViewedItem
 * @property {?number} mediaItemId
 * @property {?Object} lastViewed
 * @property {?MediaItem} mediaItem
 * @property {?string} lastPlayPosition
 */

/**
 * RecentlyViewedItem record
 *
 * @type {RecentlyViewedItem}
 */
class RecentlyViewedItem extends Immutable.Record({
    mediaItemId: null,
    lastViewed: null,
    mediaItem: null,
    lastPlayPosition: null
}) {
    /**
     * toJS shallow
     * converts Record to object by keeping all second level immutable or other complex properties intact
     *
     * @return {Object}
     */
    toJSShallow() {
        return {
            ...this.toJS(),
            mediaItem: this.mediaItem
        };
    }

    /**
     * Set values from data transfer object
     *
     * @param {RecentlyViewedItemDTO} dataTransferObject
     * @return {RecentlyViewedItem}
     */
    fromDTO(dataTransferObject) {
        return new RecentlyViewedItem({
            ...this.toJSShallow(),
            mediaItemId: typeof dataTransferObject.SupplierReference !== 'undefined' ? dataTransferObject.SupplierReference : this.mediaItemId,
            mediaItem: typeof dataTransferObject.MediaItemMidiDTO !== 'undefined' && dataTransferObject.MediaItemMidiDTO
                ? new MediaItem({}).fromDTO(dataTransferObject.MediaItemMidiDTO) : this.mediaItem,
            lastViewed: typeof dataTransferObject.LastViewed !== 'undefined' ? moment(dataTransferObject.LastViewed) : this.lastViewed,
            lastPlayPosition: typeof dataTransferObject.LastPlayPosition !== 'undefined'
                ? dataTransferObject.LastPlayPosition : this.lastPlayPosition
        });
    }
}

export default RecentlyViewedItem;
