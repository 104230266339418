"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import store from 'store';
import Router from '../lib/Router';
import OverlayIds from '../lib/OverlayIds';
import AppActions from '../actions/AppActions';
import UIActions from '../actions/UIActions';
import {CollectionType} from '../types/Collection';
import MediaActions from '../actions/MediaActions';
import PurchaseActions from '../actions/PurchaseActions';
import AppStore from '../stores/AppStore';
import AuthStore from '../stores/AuthStore';
import MediaStore from '../stores/MediaStore';
import PurchaseStore from '../stores/PurchaseStore';
import Button from './ui/Button.react';
import BundleDetails from './elements/BundleDetails.react';
import MediaCarousel from './elements/MediaCarousel.react';

/**
 * BundlePrePackedPage component
 */
export default class BundlePrePackedPage extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        params: PropTypes.object,
        location: PropTypes.object
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: state
     */
    state = {
        mediaBucket: null,
        updatePending: false
    };

    /**
     * Bucket keys
     */
    _mediaBucketKey = null;

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        if (this.props.params && this.props.params.collectionTitle && AppStore.collectionListByTitle.get(this.props.params.collectionTitle)) {
            this._mediaBucketKey = MediaActions.getCollectionMedia({
                collectionTitle: this.props.params.collectionTitle
            });
        }
    }

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        MediaStore.addUpdateListener(this._setMediaBucket);
        PurchaseStore.addUpdateListener(this._setUpdatePending);
    }

    /**
     * React: UNSAFE_componentWillReceiveProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((nextProps.params && nextProps.params.collectionTitle && nextProps.params.collectionTitle !== this.props.params.collectionTitle
                && AppStore.collectionListByTitle.get(this.props.params.collectionTitle))) {
            MediaStore.destroyMediaBucket(this._mediaBucketKey);
            this._mediaBucketKey = null;
            this.setState({
                mediaBucket: null
            }, () => {
                this._mediaBucketKey = MediaActions.getCollectionMedia({
                    collectionTitle: this.props.params.collectionTitle
                });
            });
        }
    }

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        MediaStore.removeUpdateListener(this._setMediaBucket);
        PurchaseStore.removeUpdateListener(this._setUpdatePending);
        this._mediaBucketKey = MediaStore.destroyMediaBucket(this._mediaBucketKey);
    }

    /**
     * React: render
     */
    render() {
        const collection = this.props.params && this.props.params.collectionTitle
            ? AppStore.collectionListByTitle.get(this.props.params.collectionTitle)
            : null;
        const mediaBucket = this.state.mediaBucket;
        const media = mediaBucket ? mediaBucket.media() : null;
        const purchaseAvailable = media && media.size > 0 && !!media.find(mediaItem => !mediaItem.isPurchased);

        return (
            <main>
                {typeof collection === 'undefined' ? (
                    <div className="no-results">
                        {AppStore.translate('view.collection_page.message.collection_not_found')}
                    </div>
                ) : (media && media.size === 0 ? (
                    <div className="no-results">
                        {AppStore.translate('view.collection_page.message.no_media_in_collection', {collectionTitle: this.props.params.collectionTitle})}
                    </div>
                ) : (media && media.size > 0 ? (
                    <BundleDetails collection={collection}
                                   contentMediaItem={media.first()}
                                   fastCheckoutBundleOffer={this._fastCheckoutBundleOffer}
                                   purchaseAvailable={purchaseAvailable}
                                   updatePending={this.state.updatePending}
                    />
                ) : null))}

                {media && media.size > 0 ? (
                    <MediaCarousel media={media}
                                   title={collection.collectionType === CollectionType.MOVIE_BUNDLE
                                       ? AppStore.translate('view.bundle_page.movie_count', {itemCount: collection.mediaCount})
                                       : AppStore.translate('view.bundle_page.season_count', {itemCount: collection.mediaCount})}
                                   tileType="poster-cta"
                                   collapsibleTiles={true}
                                   navigationType="top-arrows"
                                   mediaLoading={mediaBucket && mediaBucket.updatePending}
                                   isPurchaseEnabled={false}/>
                ) : null}
            </main>
        );
    }

    /**
     * Set mediaBucket
     */
    _setMediaBucket = () => {
        this.setState({
            mediaBucket: MediaStore.getMediaBucket(this._mediaBucketKey)
        });
    };

    /**
     * Set updatePending
     */
    _setUpdatePending = () => {
        this.setState({
            updatePending: PurchaseStore.updatePending
        });
    };

    /**
     * Fast checkout bundleOffer
     *
     * @private
     */
    _fastCheckoutBundleOffer = () => {
        const collection = this.props.params && this.props.params.collectionTitle
            ? AppStore.collectionListByTitle.get(this.props.params.collectionTitle)
            : null;
        const mediaBucket = this.state.mediaBucket;
        const media = mediaBucket ? mediaBucket.media() : null;
        if (!collection || !media) return;

        if (!AuthStore.isSignedIn()) {
            AppActions.storeRouterTransitionPath(Router.COLLECTIONS(collection));
            this.context.router.push(Router.SIGN_IN);
            return;
        }

        if (!AuthStore.userProfile.paymentProviderCustomerIds.stripe) {
            UIActions.showCustomOverlay((
                <div className="alert-box-size col-xs-12 col-sm-8 col-md-6">
                    <h2 className="overlay-header">{AppStore.translate('view.cta_action_modal.title.finish_payment_setup')}</h2>
                    <div className="overlay-content">
                        <p>{AppStore.translate('view.cta_action_modal.message.finish_payment_setup')}</p>
                        <div className="buttons-container">
                            <Button
                                type='button'
                                shape="regular"
                                onClick={UIActions.closeCustomOverlay}>{AppStore.translate('button.cancel')}</Button>
                            <Button
                                type='button'
                                className="cta-virgin-purple"
                                shape="regular"
                                onClick={this._closeInfoOverlayAndOpenPaymentPopup}>{AppStore.translate('button.cta.go_to_my_account')}
                            </Button>
                        </div>
                    </div>
                </div>
            ));
            return;
        }

        PurchaseActions.fastCheckoutBundleOffer({
            collectionId: collection.id,
            collection: collection,
            mediaItemOffers: media.toArray().map(mediaItem => ({
                mediaItemId: mediaItem.id,
                offerId: typeof mediaItem.offers[0] !== 'undefined' ? mediaItem.offers[0].id : null
            })).filter(mediaItemOffer => !!mediaItemOffer.offerId),
            currencyCode: typeof media.first().offers[0] !== 'undefined'
                ? media.first().offers[0].currency : undefined
        });
    };

    /**
     * Navigate to
     *
     * @param link
     * @private
     */
    _navigateTo = (link) => {
        const collection = this.props.params && this.props.params.collectionTitle
            ? AppStore.collectionListByTitle.get(this.props.params.collectionTitle)
            : null;
        if (!collection) return;

        store.set("purchase_started", "YES");
        store.set("purchase_path", Router.COLLECTIONS(collection));
        AppActions.storeRouterTransitionPath(Router.COLLECTIONS(collection));
        this.context.router.push(link);
    };

    /**
     * close info overlay and open payment popup
     */
    _closeInfoOverlayAndOpenPaymentPopup = () => {
        UIActions.closeCustomOverlay();
        UIActions.showCustomOverlay({
            overlayId: OverlayIds.PAYMENT_METHOD,
            showCloseButton: false
        });
    };
}
