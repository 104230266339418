"use strict";

import Immutable from 'immutable';

/**
 * Notification definition
 *
 * @typedef {Immutable.Record} Notification
 *
 * @property {string} type - notification type
 * @property {Object} content - notification content
 * @property {number} hideAfter - number of millisecond after which to hide notification
 * @property {number} onClose - method to be called on notification closing
 */

/**
 * Notification record
 *
 * @type {Notification}
 */
class Notification extends Immutable.Record({
    type: 'information',
    content: null,
    hideAfter: 8000,
    onClose: null
}) {
    // no op
}

export default Notification;
