"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import ImmutablePropTypes from 'react-immutable-proptypes';
import MediaItem from '../../types/MediaItem';
import Spinner from '../ui/Spinner2.react';
import MediaItemTile from './MediaItemTile.react';
import MediaItemTileBubble from './MediaItemTileBubble.react';

/**
 * MediaGallery component
 */
export default class MediaGallery extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        title: PropTypes.string,
        media: ImmutablePropTypes.mapOf(PropTypes.instanceOf(MediaItem)),
        mediaLoading: PropTypes.bool,
        tileType: PropTypes.oneOf(['poster', 'poster-bubble', 'backdrop', 'backdrop-progress-bar', 'backdrop-detailed']),
        collapsibleTiles: PropTypes.bool,
        highlighted: PropTypes.bool,
        className: PropTypes.string,
        mediaItemInfoTextVisible: PropTypes.bool,
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        title: '',
        media: null,
        mediaLoading: false,
        tileType: 'poster-bubble',
        collapsibleTiles: false,
        highlighted: false,
        mediaItemInfoTextVisible: true,
    };

    /**
     * React: state
     */
    state = {
        shouldTileBubbleBeVisible: false,
        tileBubbleMediaItem: null,
        tileBubbleMediaItemTileNode: null
    };

    /**
     * React: UNSAFE_componentWillReceiveProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.media && nextProps.media !== this.props.media && this.state.tileBubbleMediaItem) {
            this.setState({
                tileBubbleMediaItem: nextProps.media.get(this.state.tileBubbleMediaItem.id)
            });
        } else if (!nextProps.media) {
            this.setState({
                tileBubbleMediaItem: null,
                shouldTileBubbleBeVisible: false
            });
        }
    }

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        var media = [];
        if (this.props.media && this.props.media.size > 0) {
            this.props.media.forEach((mediaItem, key) => {
                media.push(
                    <MediaItemTile key={key}
                                   mediaItem={mediaItem}
                                   tileType={this.props.tileType}
                                   toggleTileBubble={this._toggleTileBubble}
                                   mediaItemInfoTextVisible={this.props.mediaItemInfoTextVisible}
                                   collapsible={this.props.collapsibleTiles}/>
                );
            });

            // show loading tile set instead
        } else {
            for (let index = 0; index <= 5; index++) {
                media.push(<MediaItemTile key={index} tileType={this.props.tileType}/>);
            }
        }

        var className = 'media-gallery'
            + (this.props.collapsibleTiles ? ' collapsible-tiles' : '')
            + (this.props.highlighted ? ' highlighted' : '')
            + (this.props.className ? ' ' + this.props.className : '');

        return (
            <div className={className}>
                <div className="container">
                    {this.props.title ? (
                        <div className="row">
                            <div className="col-xs-12 header">
                                <h2>{this.props.title}</h2>
                            </div>
                        </div>
                    ) : null}

                    <div className="row">
                        <div className="movies-container">
                            {media}
                        </div>
                        {this.props.tileType === 'poster-bubble' ? (
                            <MediaItemTileBubble shouldTileBubbleBeVisible={this.state.shouldTileBubbleBeVisible}
                                                 mediaItem={this.state.tileBubbleMediaItem}
                                                 tileNode={this.state.tileBubbleMediaItemTileNode}/>
                        ) : null}
                    </div>
                </div>
                {this.props.mediaLoading && this.props.media && this.props.media.size > 0 ? (
                    <div className="media-loading">
                        <Spinner />
                    </div>
                ) : null}
            </div>
        );
    }

    /**
     * Toggle tile bubble
     *
     * @param shouldBeVisible
     * @param mediaItem
     * @param tileNode
     * @private
     */
    _toggleTileBubble = (shouldBeVisible, mediaItem = null, tileNode = null) => {
        // return if tile type does not supports bubble
        if (this.props.tileType !== 'poster-bubble') return;

        this.setState({
            shouldTileBubbleBeVisible: shouldBeVisible,
            tileBubbleMediaItem: shouldBeVisible ? mediaItem : this.state.tileBubbleMediaItem,
            tileBubbleMediaItemTileNode: shouldBeVisible ? tileNode : this.state.tileBubbleMediaItemTileNode
        });
    };
}
