"use strict";

import Immutable from 'immutable';


/**
 * GenreDTO definition
 *
 * @typedef {Object} GenreDTO
 * @property {?number} TotalMediaItemsCount
 * @property {?number} BrandsCount
 * @property {?number} SeasonsCount
 * @property {?number} EpisodesCount
 * @property {?number} MoviesCount
 * @property {?number} TVBoxSetsCount
 * @property {?number} MovieBoxSetsCount
 * @property {?string} Name - The friendly name of the Genre
 * @property {?string} ImageUrl
 * @property {?string} ImageActive
 * @property {?string} ImageInactive
 */

/**
 * Genre definition
 *
 * @typedef {Immutable.Record} Genre
 * @property {?string} id
 * @property {?number} totalMediaItemsCount
 * @property {?number} brandsCount
 * @property {?number} seasonsCount
 * @property {?number} episodesCount
 * @property {?number} moviesCount
 * @property {?number} tvBoxSetsCount
 * @property {?number} movieBoxSetsCount
 * @property {?string} name
 * @property {?string} imageUrl
 * @property {?string} imageActive
 * @property {?string} imageInactive
 */

/**
 * Genre record
 *
 * @type {Genre}
 */
class Genre extends Immutable.Record({
    id: null,
    totalMediaItemsCount: null,
    brandsCount: null,
    seasonsCount: null,
    episodesCount: null,
    moviesCount: null,
    tvBoxSetsCount: null,
    movieBoxSetsCount: null,
    name: null,
    imageUrl: null,
    imageActive: null,
    imageInactive: null
}) {
    /**
     * Set values from data transfer object
     *
     * @param {GenreDTO} dataTransferObject
     * @return {Genre}
     */
    fromDTO(dataTransferObject) {
        return new Genre({
            ...this.toJS(),
            id: typeof dataTransferObject.Name !== 'undefined' ? dataTransferObject.Name : this.name,
            totalMediaItemsCount: typeof dataTransferObject.TotalMediaItemsCount !== 'undefined' ? dataTransferObject.TotalMediaItemsCount : this.TotalMediaItemsCount,
            brandsCount: typeof dataTransferObject.BrandsCount !== 'undefined' ? dataTransferObject.BrandsCount : this.brandsCount,
            seasonsCount: typeof dataTransferObject.SeasonsCount !== 'undefined' ? dataTransferObject.SeasonsCount : this.seasonsCount,
            episodesCount: typeof dataTransferObject.EpisodesCount !== 'undefined' ? dataTransferObject.EpisodesCount : this.episodesCount,
            moviesCount: typeof dataTransferObject.MoviesCount !== 'undefined' ? dataTransferObject.MoviesCount : this.moviesCount,
            tvBoxSetsCount: typeof dataTransferObject.TVBoxSetsCount !== 'undefined' ? dataTransferObject.TVBoxSetsCount : this.tvBoxSetsCount,
            name: typeof dataTransferObject.Name !== 'undefined' ? dataTransferObject.Name : this.name,
            imageUrl: typeof dataTransferObject.ImageUrl !== 'undefined' ? dataTransferObject.ImageUrl : this.imageUrl,
            imageActive: typeof dataTransferObject.ImageActive !== 'undefined' ? dataTransferObject.ImageActive : this.imageActive,
            imageInactive: typeof dataTransferObject.ImageInactive !== 'undefined' ? dataTransferObject.ImageInactive : this.imageInactive
        });
    }
}

export default Genre;
