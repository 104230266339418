"use strict";

import Immutable from 'immutable';

/**
 * SubtitleDTO definition
 *
 * @typedef {Object} SubtitleDTO
 * @property {?string} LanguageCode - Language code
 * @property {?string} LanguageName - Language name
 */

/**
 * Subtitle definition
 *
 * @typedef {Immutable.Record} Subtitle
 * @property {?string} languageCode
 * @property {?string} languageName
 */

/**
 * Subtitle record
 *
 * @type {Subtitle}
 */
class Subtitle extends Immutable.Record({
    languageCode: null,
    languageName: null
}) {
    /**
     * Set values from data transfer object
     *
     * @param {SubtitleDTO} dataTransferObject
     * @return {Subtitle}
     */
    fromDTO(dataTransferObject) {
        return new Subtitle({
            ...this.toJS(),
            languageCode: typeof dataTransferObject.LanguageCode !== 'undefined' ? dataTransferObject.LanguageCode : this.languageCode,
            languageName: typeof dataTransferObject.LanguageName !== 'undefined' ? dataTransferObject.LanguageName : this.languageName
        });
    }
}

export default Subtitle;
