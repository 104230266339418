"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import UIActions from '../../actions/UIActions';

/**
 * Icon Button with popup on click component
 */
export default class IconButtonWithBubblePop extends React.Component {

    /**
     * React: propTypes
     */
    static propTypes = {
        icon: PropTypes.string,
        infoContent: PropTypes.node,
        infoTitle: PropTypes.string
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        icon: '',
        infoContent: '',
        infoTitle: ''
    };

    /**
     * React: state
     */
    state = {
        isInfoBoxOpen: false
    };
    /**
     * React: render
     */
    render() {
        const {icon} = this.props;
        const {infoContent} = this.props;
        return (
            <div
                className="ui-icon-button-wrapper"
            >
                <div className={`ui-icon-button-popup${this.state.isInfoBoxOpen ? '-open' : ''} ui-popup-icon-hidden`} onClick={this._togglePopupBox}>
                    <i className={`ui-icon ${icon}`} />
                </div>
                <div
                    className={`ui-icon-button-bubble${this.state.isInfoBoxOpen ? '-open' : ''} ui-bubble-icon-hidden`}
                    onMouseEnter={this._toggleInfoBox}
                    onMouseLeave={this._toggleInfoBox}
                >
                    <i className={`ui-icon ${icon}`} />
                    <div className={`info-content ${this.state.isInfoBoxOpen ? '' : 'info-content-hidden'}`}>
                        {infoContent}
                    </div>
                </div>
            </div>
        );
    }

    _toggleInfoBox = () => {
        this.setState({
            isInfoBoxOpen: !this.state.isInfoBoxOpen
        });
    };

    _togglePopupBox = () => {
        UIActions.showCustomOverlay((
            <div className="alert-box-size col-xs-12 col-sm-8 col-md-6">
                <h2 className="overlay-header">{this.props.infoTitle}</h2>
                <div className="overlay-content">
                    {this.props.infoContent}
                </div>
            </div>), true);
    }
}
