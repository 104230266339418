"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import Router from '../../lib/Router';
import AppStore from '../../stores/AppStore';
import AuthStore from '../../stores/AuthStore';
import NavigationMain from './navigation/NavigationMain.react';
import NavigationMobile from './navigation/NavigationMobile.react';
import NavigationSearch from './navigation/NavigationSearch.react';
import NavigationFilters from './navigation/NavigationFilters.react';
import NavigationProfile from './navigation/NavigationProfile.react';
import headerLogo from '../../../assets-virgin/images/virgin_logo_top_bar.png';

/**
 * Navigation component
 */
export default class Navigation extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        params: PropTypes.object,
        location: PropTypes.object
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: state
     */
    state = {
        isSearchOpen: this.context.router.isActive(Router.SEARCH),
        isFilterMenuOpened: false,
        isSelectInFocus: false,
        isMobileMenuOpened: false
    };

    // reference to the beggining of the mobile menu list
    mobileNavListRef = React.createRef();
    /**
     * React: UNSAFE_componentWillUpdate
     */
    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if ((nextState.isFilterMenuOpened && !this.state.isFilterMenuOpened) || (nextState.isMobileMenuOpened && !this.state.isMobileMenuOpened)) {
            document.addEventListener('click', this._closeMenusOnBlur, false);
            document.addEventListener('touchstart', this._closeMenusOnBlur, false);
        } else if ((!nextState.isFilterMenuOpened && this.state.isFilterMenuOpened) && (!nextState.isMobileMenuOpened && this.state.isMobileMenuOpened)) {
            document.removeEventListener('click', this._closeMenusOnBlur, false);
            document.removeEventListener('touchstart', this._closeMenusOnBlur, false);
        }
    }

    /**
     * React: UNSAFE_componentWillReceiveProps
     */
    UNSAFE_componentWillReceiveProps() {
        this.setState({
            isSearchOpen: this.context.router.isActive(Router.SEARCH)
        });
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        document.removeEventListener('click', this._closeMenusOnBlur, false);
        document.removeEventListener('touchstart', this._closeMenusOnBlur, false);
    }

    /**
     * React: render
     */
    render() {
        const isOnCollectionPath = this.props ? this.props.location.pathname.indexOf("collections") > -1 : false;
        const isOnSearchPath = this.props ? this.props.location.pathname.indexOf("search") > -1 : false;
        const isOnSignInPath = this.props ? this.props.location.pathname.indexOf("sign-in") > -1 : false;
        const isOnSignUpPath = this.props ? this.props.location.pathname.indexOf("sign-up") > -1 : false;
        const isOnWishlistPath = this.props ? this.props.location.pathname.indexOf("wishlist") > -1 : false;
        const isOnLibraryPath = this.props ? this.props.location.pathname.indexOf("library") > -1 : false;
        const isOnCastPath = this.props ? this.props.location.pathname.indexOf("names") > -1 : false;
        const showSignUpButton = !AuthStore.isSignedIn() && !isOnSignUpPath;
        const showSignInButton = !AuthStore.isSignedIn() && !isOnSignInPath;
        const displayName = AuthStore.isSignedIn() && AuthStore.userProfile.firstName.split(" ")[0];

        return (
            <div className={this.state.isSearchOpen ? 'double-size' : ''}>
                <div className="nav-placeholder"></div>
                <nav>
                    <div className="container">

                        {/* desktop navigation menu */}
                        <ul className="desktop-nav">
                            {AppStore.mainNavigation.toArray().map(mainNavigationSection => (
                                <li key={mainNavigationSection.key}>
                                    <a>{AppStore.translate('view.navigation.label.' + mainNavigationSection.key)}</a>
                                    <NavigationMain mainNavigationSection={mainNavigationSection}/>
                                </li>
                            ))}
                        </ul>

                        {/* mobile menu */}
                        <div id="nav-mobile-menu"
                             ref="mobileMenuLinks"
                             className={"menu-button-container side-menu side-menu-no-hover " + (this.state.isMobileMenuOpened ? " opened-menu" : "")}>
                            <div id="nav-menu" className="menu-button" onClick={this._toggleMobileMenu}>
                                <i className="ui-icon icon-burger"/>
                            </div>
                            <NavigationMobile
                                toggleMobileMenu={this._toggleMobileMenu}
                                scrollMenuIntoView={this._scrollMenuIntoView}
                                navRef={this.mobileNavListRef} />
                        </div>

                        <Link to={Router.HOME} id="header-logo" className="logo" style={{'backgroundImage': `url(${headerLogo})`}} />

                        {/* filter profile */}
                        {AuthStore.isSignedIn() && <div className="menu-button right-buttons menu-button-container">
                            <span className="display-name">{displayName}</span>
                        </div>}
                        {AuthStore.isSignedIn() ? (
                            <div id="user-info" className="right-buttons menu-button-container">
                                <div className="user-avatar-container menu-button"></div>
                                <NavigationProfile/>
                            </div>
                        ) : ( <div id="user-info" className="right-buttons menu-button-container">
                                {showSignInButton && (
                                        <Link to={Router.SIGN_IN} className="virgin-nav_signin-button">
                                            {AppStore.translate('view.navigation.label.sign_in')}
                                        </Link>
                                )}
                                {showSignUpButton && (
                                        <Link to={Router.SIGN_UP} className="virgin-nav_signup-button">
                                            {AppStore.translate('view.navigation.label.sign_up')}
                                        </Link>
                                )}
                            </div>
                        )}
                        {/* filter menu */}
                        {/*!this.context.router.isActive(Router.HOME) && !this.context.router.isActive(Router.ROOT, true)*/}
                        {isOnCollectionPath || isOnSearchPath || isOnWishlistPath || isOnLibraryPath || isOnCastPath ? (
                            <div id="nav-filters"
                                 ref="filterMenu"
                                 className={"right-buttons menu-button-container side-menu" +
                                 (this.state.isSelectInFocus ? " select-in-focus" : "") +
                                 (this.state.isFilterMenuOpened ? " opened-menu" : "")}>
                                <div id="nav-settings" className="menu-button" onClick={this._toggleFilterMenu}></div>
                                <NavigationFilters setSelectFieldFocus={this._setSelectFieldFocusState}/>
                            </div>
                        ) : null}

                        <NavigationSearch params={this.props.params} parentToggleSearch={this._toggleSearch}/>
                    </div>
                </nav>
            </div>
        );
    }

    /**
     * Toggle search
     *
     * @private
     */
    _toggleSearch = (isSearchOpenParameter) => {
        this.setState({
            isSearchOpen: isSearchOpenParameter
        });
    };

    /**
     * Toggle filter menu
     *
     * @private
     */
    _toggleFilterMenu = () => {
        this.setState({
            isFilterMenuOpened: !this.state.isFilterMenuOpened
        });
    };

    /**
     * Toggle menu
     *
     * @private
     */
    _toggleMobileMenu = () => {
        this.setState({
            isMobileMenuOpened: !this.state.isMobileMenuOpened
        }, () => {
                this.state.isMobileMenuOpened ? this._scrollMenuIntoView() : null;
        });
    };

    _scrollMenuIntoView = () => {
        this.mobileNavListRef.current.scrollIntoView({behavior: 'smooth'})
    }

    /**
     * Close filterMenu and mobileMenu on blur
     *
     * @private
     */
    _closeMenusOnBlur = () => {
        var filterMenuLinks = this.refs.filterMenu;
        var mobileMenuLinks = this.refs.mobileMenuLinks;

        if (filterMenuLinks && !filterMenuLinks.contains(event.target)) {
            this.setState({
                isFilterMenuOpened: false
            });
        }

        if (mobileMenuLinks && !mobileMenuLinks.contains(event.target)) {
            this.setState({
                isMobileMenuOpened: false
            });
        }
    };

    /**
     * Set select field focus state
     * needed for IE10 which closes menu when select opens
     *
     * @private
     */
    _setSelectFieldFocusState = () => {
        this.setState({
            isSelectInFocus: true
        }, () => {
            document.addEventListener('click', this._unsetSelectFieldFocusState, false);
        });
    };

    /**
     * Unset select field focus state
     * @private
     */
    _unsetSelectFieldFocusState = () => {
        this.setState({
            isSelectInFocus: false
        });

        document.removeEventListener('click', this._unsetSelectFieldFocusState, false);
    };
}
