"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import Router from '../../../lib/Router';
import shallowCompare from 'react-addons-shallow-compare';
import Config from '../../../lib/Config';
import Voucher, {VoucherStatus} from '../../../types/Voucher';
import Button from '../../ui/Button.react';
import AppStore from '../../../stores/AppStore';
import AuthStore from '../../../stores/AuthStore';
import MediaItem, {MediaItemClassification} from '../../../types/MediaItem';

/**
 * ConfirmPurchaseBar component
 */
export default class ConfirmPurchaseBar extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        updatePending: PropTypes.bool.isRequired,
        purchasePending: PropTypes.bool.isRequired,
        basketAmountDue: PropTypes.number,
        basketCurrencyCode: PropTypes.string,
        voucher: PropTypes.instanceOf(Voucher),
        cancelCheckoutProcess: PropTypes.func.isRequired,
        chargeBasket: PropTypes.func.isRequired,
        mediaItem: PropTypes.instanceOf(MediaItem)
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        basketAmountDue: 0,
        basketCurrencyCode: ''
    };

    state = {
        stripeEnabled: AuthStore.userProfile.paymentProviderCustomerIds.stripe
    }

    componentDidMount() {
        AuthStore.addUpdateListener(this.checkIsUserProfileUpdated);
    }

    componentWillUnmount() {
        AuthStore.removeUpdateListener(this.checkIsUserProfileUpdated);
    }
    /**
     * React: shouldComponentUpdate
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    checkIsUserProfileUpdated = () => {
        if (this.state.stripeEnabled !== AuthStore.userProfile.paymentProviderCustomerIds.stripe) {
            this.setState({stripeEnabled: AuthStore.userProfile.paymentProviderCustomerIds.stripe});
        }

    }

    /**
     * React: render
     */
    render() {
        let basketAmountDue = this.props.basketAmountDue;
        let basketCurrencyCode = this.props.basketCurrencyCode;
        let voucher = this.props.voucher;
        let isVoucherValid = voucher && voucher.status === VoucherStatus.VALID;
        const mediaItem = this.props.mediaItem;

        return (
            <div className="confirm-purchase-container">
                <div className="confirm-purchase-bottom-container">
                    <div className="price-container">
                        <h3 className="">
                            {basketAmountDue.toLocaleString(Config.LOCALE, {
                                style: 'currency',
                                currency: basketCurrencyCode,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </h3>

                        {voucher && isVoucherValid ? (
                            <div className="discount-price-container">
                                <div className="voucher-discount-container price-container">
                                    Voucher Code Applied
                                    <span className="voucher-discount">-
                                        {voucher.redeemableValue.toLocaleString(Config.LOCALE, {
                                            style: 'currency',
                                            currency: basketCurrencyCode
                                        })}</span>
                                </div>
                                <div className="final-amount price-container">
                                    <h4>Amount to be paid</h4>
                                    <h3 className="">
                                        {(basketAmountDue - voucher.redeemableValue).toLocaleString(Config.LOCALE, {
                                            style: 'currency',
                                            currency: basketCurrencyCode
                                        })}
                                    </h3>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <p>
                        By pressing "Confirm purchase" you agree that you have read and accept
                        the <Link to={Router.TERMS_AND_CONDITIONS} target="_blank">Terms & Conditions</Link> and wish to complete your
                        purchase. You agree that supply of the digital content will begin immediately and that you
                        cannot cancel after this point.
                    </p>
                    <Button shape="regular"
                            className="cancel-button"
                            disabled={this.props.updatePending}
                            onClick={this.props.cancelCheckoutProcess}>
                        {AppStore.translate('button.cancel')}
                    </Button>
                    <Button shape="regular"
                            className="cta-payment-positive cta-colored"
                            disabled={this.props.updatePending || !this.state.stripeEnabled}
                            updatePending={this.props.purchasePending}
                            onClick={this.props.chargeBasket}>
                        {basketCurrencyCode === 'GBP' ? (
                            <i className="ui-icon icon-pound-circle"/>
                        ) : (basketCurrencyCode === 'EUR' ? (
                            <i className="ui-icon icon-euro-circle"/>
                        ) : (
                            <i className="ui-icon icon-basket"/>
                        ))}
                        Confirm purchase
                    </Button>
                </div>
            </div>
        );
    }
}
