"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import Router from '../../lib/Router';
import * as ErrorParser from '../../lib/ErrorParser';
import OverlayIds from '../../lib/OverlayIds';
import PurchaseActions from '../../actions/PurchaseActions';
import {MediaItemClassification} from '../../types/MediaItem';
import {VoucherStatus} from '../../types/Voucher';
import AppStore from '../../stores/AppStore';
import AuthStore from '../../stores/AuthStore';
import UIActions from '../../actions/UIActions';
import AnalyticsActions from '../../actions/AnalyticsActions';
import AnalyticsStore from '../../stores/AnalyticsStore';
import MediaStore from '../../stores/MediaStore';
import PurchaseStore from '../../stores/PurchaseStore';
import EntitlementStore from '../../stores/EntitlementStore';
import OrderDetails from './checkout-overlay/OrderDetails.react';
import PaymentDetails from './checkout-overlay/PaymentDetails.react';
import ConfirmPurchaseBar from './checkout-overlay/ConfirmPurchaseBar.react';
import PurchaseSuccessful from './checkout-overlay/PurchaseSuccessful.react';
import NotificationBarOverlay from './NotificationBarOverlay.react';

/**
 * CheckoutOverlay component
 */
export default class CheckoutOverlay extends React.Component {
    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: state
     */
    state = {
        mediaBucket: null,
        updatePending: false,
        voucher: null,
        customerPaymentDetails: null,
        errorMessage: '',
        voucherCode: ''
    };

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        let errorMessage = null;
        if (PurchaseStore.error) {
            errorMessage = ErrorParser.parseErrorMessage(PurchaseStore.error.message)
                || AppStore.translate('notification.general.error.communication_error');
        }
        const mediaBucket = MediaStore.getMediaBucket(PurchaseStore.basketMediaBucketKey) || null;

        this.state = {
            ...this.state,
            mediaBucket: mediaBucket,
            updatePending: PurchaseStore.updatePending,
            voucher: PurchaseStore.voucher,
            customerPaymentDetails: AuthStore.customerPaymentDetails,
            errorMessage: errorMessage
        };
    }

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        PurchaseStore.addUpdateListener(this._setBasketState);
        MediaStore.addUpdateListener(this._setBasketMediaBucket);
        AuthStore.addUpdateListener(this._setCustomerPaymentDetails);
        AnalyticsStore.addUpdateListener(this._setCurrentBasketItem);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        PurchaseStore.removeUpdateListener(this._setBasketState);
        MediaStore.removeUpdateListener(this._setBasketMediaBucket);
        AuthStore.removeUpdateListener(this._setCustomerPaymentDetails);
        AnalyticsStore.removeUpdateListener(this._setCurrentBasketItem);
        this.setState({
            mediaBucket: null,
            currentBasketItem: null,
            updatePending: false,
            voucher: null,
            customerPaymentDetails: null,
            errorMessage: '',
            voucherCode: ''
        });
    }

    /**
     * React: shouldComponentUpdate
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        const mediaBucket = this.state.mediaBucket;
        const media = mediaBucket ? mediaBucket.media() : null;
        let entitlement = null;
        if (media && media.size !== 0) {
            entitlement = media.first() ? EntitlementStore.currentEntitlementsByMediaItem.get(media.first().id) : null;
        }

        return media && media.size !== 0 ? (
            <main className="checkout-overlay">
                <div className="container">
                    <div className="row">
                        {!entitlement ? (
                            <div
                                className="checkout-block-content col-xs-12 col-md-12 col-lg-10 col-xl-10 col-offset-md-0 col-offset-lg-1 col-offset-xl-1">
                                <div className="payment-outer-container main-information">
                                    <h2 className="overlay-header">{AppStore.translate('view.payment_page.title').toUpperCase()}</h2>
                                    <NotificationBarOverlay />
                                    <div className="payment-block-container">
                                        <div className="payment-details-container">
                                            <OrderDetails media={media} mediaBucket={mediaBucket} />

                                            <PaymentDetails updatePending={this.state.updatePending}
                                                voucherPending={PurchaseStore.voucherPending}
                                                customerPaymentDetails={this.state.customerPaymentDetails}
                                                togglePaymentMethodForm={this._openPaymentMethodOverlay}
                                                voucher={this.state.voucher}
                                                verifyVoucher={this._verifyVoucher} />
                                        </div>

                                        <ConfirmPurchaseBar updatePending={this.state.updatePending}
                                            purchasePending={PurchaseStore.purchasePending}
                                            voucherPending={PurchaseStore.voucherPending}
                                            mediaItem={media.first()}
                                            voucher={this.state.voucher}
                                            cancelCheckoutProcess={this._cancelCheckoutProcess}
                                            chargeBasket={this._chargeBasket}
                                            basketAmountDue={PurchaseStore.basketAmountDue}
                                            basketCurrencyCode={PurchaseStore.basketCurrencyCode} />
                                    </div>
                                </div>
                            </div>
                        ) : (
                                <PurchaseSuccessful mediaItem={entitlement ? mediaBucket.mediaItem(entitlement.mediaItemId) : null}
                                    userProfile={AuthStore.userProfile}
                                    goToLibrary={this._goToLibrary}
                                    cancelCheckoutProcess={this._cancelCheckoutProcess}
                                    displayWatchNowButton={media.size === 1
                                        && media.first().classification !== MediaItemClassification.MOVIE_BOX_SET
                                        && media.first().classification !== MediaItemClassification.TV_BOX_SET} />
                            )}
                    </div>
                </div>
            </main>
        ) : null;
    }

    /**
     * Set basket state
     *
     * @private
     */
    _setBasketState = () => {
        let errorMessage = null;
        if (PurchaseStore.error) {
            errorMessage = ErrorParser.parseErrorMessage(PurchaseStore.error.message) || AppStore.translate('notification.general.error.communication_error');
        }
        const mediaBucket = MediaStore.getMediaBucket(PurchaseStore.basketMediaBucketKey) || null;

        this.setState({
            mediaBucket: mediaBucket,
            updatePending: PurchaseStore.updatePending,
            voucher: PurchaseStore.voucher,
            customerPaymentDetails: AuthStore.customerPaymentDetails,
            errorMessage: errorMessage
        });
    };

    /**
     * Set basket media bucket
     *
     * @private
     */
    _setBasketMediaBucket = () => {
        const mediaBucket = MediaStore.getMediaBucket(PurchaseStore.basketMediaBucketKey) || null;
        if (this.state.mediaBucket !== mediaBucket) {
            this.setState({
                mediaBucket: mediaBucket
            });
        }
    };

    /**
     * Set customer payment details
     *
     * @private
     */
    _setCustomerPaymentDetails = () => {
        this.setState({
            customerPaymentDetails: AuthStore.customerPaymentDetails
        });
    };

    /**
     * Set current basket item
     *
     * @private
     */
    _setCurrentBasketItem = () => {
        this.setState({
            currentBasketItem: AnalyticsStore.getCurrentBasketItem()
        });
    };

    /**
     * Open payment method overlay
     *
     * @private
     */
    _openPaymentMethodOverlay = () => {
        UIActions.showCustomOverlay({
            overlayId: OverlayIds.PAYMENT_METHOD
        });

        AnalyticsActions.trackEvent({
            category: 'Purchase',
            action: AnalyticsStore.getCurrentBasketItem() === 'MovieBoxSet' || AnalyticsStore.getCurrentBasketItem() === 'TVBoxSet'
                ? 'Boxset Purchase' : 'Single Purchase',
            label: 'Update Payment'
        });
    };

    /**
     * Verify voucher
     *
     * @private
     */
    _verifyVoucher = (voucherCode) => {
        this.setState({
            voucherCode: voucherCode
        }, () => {
            PurchaseActions.verifyVoucher({
                voucherCode: voucherCode,
                collectionId: PurchaseStore.basketBundleCollectionId
            });
        });
    };

    /**
     * Cancel checkout process
     *
     * @private
     */
    _cancelCheckoutProcess = () => {
        PurchaseActions.cancelCheckout();
    };

    /**
     * Charge basket
     *
     * @private
     */
    _chargeBasket = () => {
        let voucher = PurchaseStore.voucher;

        PurchaseActions.chargeBasket({
            chargeWithPaymentProviderCustomerId: true,
            userProfile: AuthStore.userProfile,
            amountDue: voucher && voucher.status === VoucherStatus.VALID
                ? Math.round((PurchaseStore.basketAmountDue - voucher.redeemableValue) * 100) / 100
                : PurchaseStore.basketAmountDue,
            currencyCode: PurchaseStore.basketCurrencyCode,
            voucherCode: this.state.voucherCode,
            voucher: voucher && voucher.status === VoucherStatus.VALID ? voucher : null,
            collectionId: PurchaseStore.basketBundleCollectionId
        });

        this.setState({
            errorMessage: null
        });
    };

    /**
     * Go to library
     *
     * @private
     */
    _goToLibrary = () => {
        PurchaseActions.cancelCheckout();
        this.context.router.push(Router.LIBRARY);
    };
}
