"use strict";

import Immutable from 'immutable';


/**
 * CertificateDTO definition
 *
 * @typedef {Object} CertificateDTO
 * @property {?string} Name - The friendly name of the Rating
 * @property {?number} MinRating - The minimum age supported by this certificate
 */

/**
 * Certificate definition
 *
 * @typedef {Immutable.Record} Certificate
 * @property {?string} name
 * @property {?number} minRating
 */

/**
 * Certificate record
 *
 * @type {Certificate}
 */
class Certificate extends Immutable.Record({
    name: null,
    minRating: null
}) {
    /**
     * Set values from data transfer object
     *
     * @param {CertificateDTO} dataTransferObject
     * @return {Certificate}
     */
    fromDTO(dataTransferObject) {
        return new Certificate({
            ...this.toJS(),
            name: typeof dataTransferObject.Name !== 'undefined' ? dataTransferObject.Name : this.name,
            minRating: typeof dataTransferObject.MinRating !== 'undefined' ? dataTransferObject.MinRating : this.minRating
        });
    }
}

export default Certificate;
