"use strict";

import Immutable from 'immutable';

/**
 * FurnitureDTO definition
 *
 * @typedef {Object} FurnitureDTO
 * @property {?string} FurnitureType - The type of furniture, e.g. image type and size
 * @property {?string} Uri - The Uri location of the furniture
 */

/**
 * Furniture definition
 *
 * @typedef {Immutable.Record} Furniture
 * @property {?string} furnitureType
 * @property {?string} uri
 */

/**
 * Furniture record
 *
 * @type {Furniture}
 */
class Furniture extends Immutable.Record({
    furnitureType: null,
    uri: null
}) {
    /**
     * Set values from data transfer object
     *
     * @param {FurnitureDTO} dataTransferObject
     * @return {Furniture}
     */
    fromDTO(dataTransferObject) {
        return new Furniture({
            ...this.toJS(),
            furnitureType: typeof dataTransferObject.FurnitureType !== 'undefined' ? dataTransferObject.FurnitureType : this.furnitureType,
            uri: typeof dataTransferObject.Uri !== 'undefined' ? dataTransferObject.Uri : this.uri
        });
    }
}

export default Furniture;
