"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import {Link} from 'react-router';
import Router from '../../../lib/Router';
import MediaItem from '../../../types/MediaItem';
import AppStore from '../../../stores/AppStore';
import ProgressBar from '../../ui/ProgressBar.react';
import MediaItemImage from '../MediaItemImage.react';
import MediaItemComponentCTAs from './../../mixins/MediaItemComponentCTAs';

/**
 * TileImage component
 *
 * @mixes MediaItemComponentCTAs
 */
export default class TileImage extends MediaItemComponentCTAs(React.Component) {
    /**
     * React: propTypes
     */
    static propTypes = {
        mediaItem: PropTypes.instanceOf(MediaItem).isRequired,
        onClick: PropTypes.func,
        tileType: PropTypes.oneOf(['poster', 'poster-bubble', 'poster-cta', 'backdrop', 'backdrop-progress-bar', 'backdrop-detailed'])
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        mediaItem: null,
        tileType: 'poster-bubble'
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        let mediaItem = this.props.mediaItem;
        let tileType = this.props.tileType;

        return (
            <Link to={Router.MEDIA_ITEM(mediaItem)} onClick={this._onClick}>
                <MediaItemImage mediaItem={mediaItem}
                                imageType={(tileType === 'poster' || tileType === 'poster-bubble' || tileType === 'poster-cta') ? 'poster' : 'backdrop'}
                                useImageLoadingIndicator={this.props.useImageLoadingIndicator}/>
                <div className="information">
                    <h4 title={mediaItem.title}>{mediaItem.title}</h4>
                    <div className="description">
                        <p className="misc">{mediaItem.duration} {AppStore.translate('label.duration_min')}
                            / {mediaItem.audioLanguages.join(', ')}</p>
                        <p>{mediaItem.mediumSynopsis}</p>
                    </div>
                    <ProgressBar
                        progress={mediaItem.watchedPercentage || 0}/>
                </div>
            </Link>
        );
    }

    /**
     * On click
     */
    _onClick = (event) => {
        let mediaItem = this.props.mediaItem;

        if (typeof this.props.onClick === 'function') {
            this.props.onClick(event);
        }

        if (this.props.tileType === 'backdrop-progress-bar' && mediaItem.playbackAvailable) {
            event.preventDefault();
            event.stopPropagation();

            this._playMediaItem();
        }
    };
}
