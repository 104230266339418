"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button.react';

/**
 * Pagination component
 */
export default class Pagination extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        pageCount: PropTypes.number,
        activePage: PropTypes.number,
        useArrows: PropTypes.bool,
        navigationLeftDisabled: PropTypes.bool,
        navigationRightDisabled: PropTypes.bool,
        onNavigateLeft: PropTypes.func,
        onNavigateRight: PropTypes.func,
        onPageSelect: PropTypes.func,
        className: PropTypes.string
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        pageCount: 0,
        activePage: null,
        useArrows: true,
        navigationLeftDisabled: false,
        navigationRightDisabled: false,
        onNavigateLeft: null,
        onNavigateRight: null,
        onPageSelect: null
    };

    /**
     * React: render
     */
    render() {
        const {playbackActive} = this.props;
        return !playbackActive ? (
            <div className={"ui-pagination " + (this.props.className ? this.props.className : "")}>
                {this.props.useArrows ? (
                    <Button shape="arrow-left"
                        disabled={this.props.navigationLeftDisabled}
                        onClick={this._onNavigateLeft}/>
                ) : null}
                {this.props.pageCount ? [...new Array(this.props.pageCount)].map((value, index) => {
                    return (
                        <Button shape="dot"
                                key={index}
                                active={this.props.activePage === index}
                                onClick={this._onPageSelect.bind(null, index)}/>
                    );
                }) : (
                    <Button shape="dot" disabled={true}/>
                )}
                {this.props.useArrows ? (
                    <Button shape="arrow-right"
                        disabled={this.props.navigationRightDisabled}
                        onClick={this._onNavigateRight}/>
                ) : null}
            </div>
        ) : null
    }

    /**
     * On navigate left
     *
     * @private
     */
    _onNavigateLeft = () => {
        if (typeof this.props.onNavigateLeft === 'function') {
            this.props.onNavigateLeft();
        }
    };

    /**
     * On navigate right
     *
     * @private
     */
    _onNavigateRight = () => {
        if (typeof this.props.onNavigateRight === 'function') {
            this.props.onNavigateRight();
        }
    };

    /**
     * On page select
     *
     * @param {number} index
     * @private
     */
    _onPageSelect = (index) => {
        if (typeof this.props.onPageSelect === 'function') {
            this.props.onPageSelect(index);
        }
    };
}
