"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {Link} from 'react-router';
import Router from '../../../lib/Router';
import {debounce, throttle} from '../../../utils/Common';
import MediaActions from '../../../actions/MediaActions';
import MediaStore from '../../../stores/MediaStore';
import AppStore from '../../../stores/AppStore';
import AppActions from '../../../actions/AppActions';
import Spinner from '../../ui/Spinner2.react';
import MediaItemImage from '../MediaItemImage.react';

/**
 * NavigationSearch component
 *
 */
export default class NavigationSearch extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        params: PropTypes.object,
        parentToggleSearch: PropTypes.func
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: state
     */
    state = {
        isSearchOpen: this.context.router.isActive(Router.SEARCH),
        areResultsVisible: false,
        searchQueryString: this.props.params.searchQueryString || '',
        mediaBucket: null,
        activeFilters: AppStore.activeFilters
    };

    /**
     * Constructor
     */
    constructor(props, context) {
        super(props, context);
        this._searchMedia = debounce(this._searchMedia, 350);
        this._areSearchResultsScrolledToBottom = throttle(this._areSearchResultsScrolledToBottom, 200);

        if (this.props.params && this.props.params.searchQueryString) {
            MediaActions.searchMedia({
                query: this.props.params.searchQueryString
            });
        }
    }

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        MediaStore.addUpdateListener(this._setMediaBucket);
        AppStore.addUpdateListener(this._searchMediaOnFilterUpdate);
        document.addEventListener('click', this._closeSearchOnBlur, false);
        window.addEventListener('scroll', this._areSearchResultsScrolledToBottom, false);
    }

    /**
     * React: UNSAFE_componentWillReceiveProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        let nextSearchQueryString = nextProps.params ? nextProps.params.searchQueryString : '';
        let prevSearchQueryString = this.props.params ? this.props.params.searchQueryString : '';

        if (nextSearchQueryString !== prevSearchQueryString && typeof nextSearchQueryString !== 'undefined') {
            MediaStore.destroyMediaBucket(MediaStore.searchMediaBucketKey);
            this.setState({
                searchQueryString: nextSearchQueryString,
                mediaBucket: null
            }, () => {
                this._searchMedia();
            });
        }
    }

    /**
     * React: UNSAFE_componentWillUpdate
     */
    UNSAFE_componentWillUpdate(nextProps, nextState) {
        var navigationSearch = ReactDOM.findDOMNode(this);

        if (nextState.isSearchOpen && !this.state.isSearchOpen) {
            navigationSearch.addEventListener('mouseleave', this._hideResults, false);
            navigationSearch.addEventListener('mouseenter', this._showResults, false);

        } else if (!nextState.isSearchOpen && this.state.isSearchOpen) {
            navigationSearch.removeEventListener('mouseleave', this._hideResults, false);
            navigationSearch.removeEventListener('mouseenter', this._showResults, false);
        }
    }

    /**
     * React: componentDidUpdate
     */
    componentDidUpdate(prevProps, prevState) {
        if (this.state.isSearchOpen !== prevState.isSearchOpen) {
            this.props.parentToggleSearch(this.state.isSearchOpen);
        }
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        var navigationSearch = ReactDOM.findDOMNode(this);
        navigationSearch.removeEventListener('mouseleave', this._hideResults, false);
        navigationSearch.removeEventListener('mouseenter', this._showResults, false);

        document.removeEventListener('click', this._closeSearchOnBlur, false);
        window.removeEventListener('scroll', this._areSearchResultsScrolledToBottom, false);
        MediaStore.removeUpdateListener(this._setMediaBucket);
        AppStore.removeUpdateListener(this._searchMediaOnFilterUpdate);
        MediaStore.destroyMediaBucket(MediaStore.searchMediaBucketKey);
        this.setState({mediaBucket: null});
    }

    /**
     * React: render
     */
    render() {
        var mediaBucket = this.state.mediaBucket;
        var media = [];
        if (mediaBucket && mediaBucket.media()) {
            mediaBucket.media().slice(0, 4).forEach((mediaItem) => {
                if (mediaItem.offers && mediaItem.offers.length >0) {
                    media.push(
                        <li key={mediaItem.id}>
                            <Link to={Router.MEDIA_ITEM(mediaItem)}>
                                <MediaItemImage mediaItem={mediaItem} imageType="poster"/>
                                <div className="movie-title-container">
                                    {mediaItem.title} ({mediaItem.releaseYear})
                                </div>
                            </Link>
                        </li>
                    );
                }
            });
        }

        var className = 'nav-search right-buttons menu-button-container'
            + (this.state.isSearchOpen || this._isSearchPageActive() ? ' active' : '');

        return (
            <div className={className} ref="navigationSearch">
                <div className="menu-button"
                     style={!this._isSearchPageActive() ? null : {cursor: 'default'}}
                     onClick={this._toggleSearch}></div>

                <div className="nav-input-container">
                    <form onSubmit={this._goToSearchPage}>
                        <input type="text"
                               className="simple-input"
                               placeholder={AppStore.translate('field.placeholder.search')}
                               ref="searchBox"
                               value={this.state.searchQueryString}
                               onChange={this._setSearchQueryString}
                               onBlur={this._blurSearchInput}
                               onFocus={this._showResults}/>
                    </form>
                </div>

                <div className="nav-menu-popup autocomplete-movie-popup"
                     style={this.state.areResultsVisible && this.state.searchQueryString && !this._isSearchPageActive()
                         ? null : {display: 'none'}}>
                    <ul className="">
                        {mediaBucket && mediaBucket.updatePending ? (
                            <li className="content-pending"><Spinner /></li>
                        ) : (media.length > 0 ? media : (
                            <li className="content-pending">
                                {AppStore.translate('view.navigation_search.message.no_media_for_search_query')}
                            </li>
                        ))}
                        <li className="show-more">
                            <Link to={Router.SEARCH_MEDIA(this.state.searchQueryString)}>
                                {AppStore.translate('button.show_more')}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    /**
     * Checks if search page is active
     * @private
     */
    _isSearchPageActive = () => {
        return this.context.router.isActive(Router.SEARCH);
    };

    /**
     * Checks if search results are scrolled to bottom
     * @private
     */
    _areSearchResultsScrolledToBottom = () => {
        var searchResultsElement = document.querySelector('main .search-results');
        if (this._isSearchPageActive() && (window.innerHeight + window.pageYOffset) >= searchResultsElement.offsetHeight) {
            this._searchMediaOnResultsScrolledToBottom();
        }
    };

    /**
     * Toggle search
     *
     * @private
     */
    _toggleSearch = () => {
        if (this._isSearchPageActive()) return;
        MediaStore.destroyMediaBucket(MediaStore.searchMediaBucketKey);
        this.setState({
            isSearchOpen: !this.state.isSearchOpen,
            areResultsVisible: this.state.isSearchOpen ? false : this.state.areResultsVisible,
            mediaBucket: null
        }, () => {
            if (this.state.isSearchOpen) {
                this.refs.searchBox.focus();
                AppActions.toggleNavigationSearchFocus(true);
            }
        });
    };

    /**
     * Close search on blur
     *
     * @private
     * @param {Object} event
     */
    _closeSearchOnBlur = (event) => {
        if (this._isSearchPageActive()) return;
        var navSearchInput = this.refs.navigationSearch;
        if (!navSearchInput.contains(event.target)) {
            this.setState({
                isSearchOpen: false,
                areResultsVisible: false,
                searchQueryString: ''
            });
        }
    };

    /**
     * blur search input field
     */
    _blurSearchInput = () => {
        AppActions.toggleNavigationSearchFocus(false);
    }

    /**
     * Show result
     *
     * @private
     */
    _showResults = () => {
        if (this._isSearchPageActive()) return;

        this.setState({
            areResultsVisible: true
        });
    };

    /**
     * Hide result
     *
     * @private
     */
    _hideResults = () => {
        if (this._isSearchPageActive()) return;

        this.setState({
            areResultsVisible: false
        });
    };

    /**
     * Set search query
     *
     * @param {Object} event
     */
    _setSearchQueryString = (event) => {
        this.setState({
            searchQueryString: event.target.value,
            mediaBucket: event.target.value === '' ? null : this.state.mediaBucket
        }, () => {
            if (this.state.searchQueryString) {
                this._searchMedia();
            } else if(this._isSearchPageActive()) {
                this.context.router.push(Router.SEARCH);
            }
        });
    };

    /**
     * Search media
     */
    _searchMedia = () => {
        MediaActions.searchMedia({
            query: this.state.searchQueryString
        });
    };

    /**
     * Search media on filter update
     */
    _searchMediaOnFilterUpdate = () => {
        if (this.state.activeFilters !== AppStore.activeFilters) {
            this.setState({
                activeFilters: AppStore.activeFilters
            }, () => {
                MediaActions.searchMedia({
                    query: this.state.searchQueryString
                });
            });
        }
    };

    /**
     * Search media on results scrolled to bottom
     */
    _searchMediaOnResultsScrolledToBottom = () => {
        let mediaBucket = MediaStore.getMediaBucket(MediaStore.searchMediaBucketKey);

        if (mediaBucket && !mediaBucket.updatePending && mediaBucket.media().size !== 0 && mediaBucket.media().size < MediaStore.searchResultTotalCount) {
            MediaActions.searchMedia({
                uuid: MediaStore.searchMediaBucketKey,
                query: this.state.searchQueryString,
                offset: mediaBucket.media().size
            });
        }
    };

    /**
     * Set search mediaBucket
     */
    _setMediaBucket = () => {
        let mediaStoreMediaBucket = MediaStore.getMediaBucket(MediaStore.searchMediaBucketKey) || null;
        if (this.state.mediaBucket !== mediaStoreMediaBucket && this.state.searchQueryString) {
            this.setState({
                mediaBucket: mediaStoreMediaBucket
            });
        }
    };

    /**
     * On submit go to search page
     */
    _goToSearchPage = (event) => {
        event.preventDefault();
        if (!this.state.searchQueryString) return;
        this.context.router.push(Router.SEARCH_MEDIA(this.state.searchQueryString));
    };
}
