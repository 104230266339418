"use strict";

import React from 'react';
import UIActions from '../../actions/UIActions';
import AppStore from '../../stores/AppStore';
import Button from '../ui/Button.react';
import EntitlementActions from '../../actions/EntitlementActions';
import TextInput from '../ui/TextInput.react';

/**
 * SignInPopup component
 */
export default class RenameDevicePopupContent extends React.Component {

    /**
     * React: state
     */
    state = {
        newDeviceName: '',
        newDeviceNameValid: false
    };

    /**
     * React: render
     */
    render() {
        return (
            <div className="alert-box-size col-xs-12 col-sm-8 col-md-6">
                <h2 className="overlay-header">
                    {AppStore.translate('view.account_page.message.rename_device_title').toUpperCase()}</h2>
                <div className="overlay-content rename-popup-content">
                    <span>{AppStore.translate('view.account_page.message.rename_device_message')}</span>
                    <TextInput
                        value={this.state.newDeviceName}
                        maxLength={15}
                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                        disabled={false}
                        requiered={true}
                        onChange={this._onFieldChange.bind(null, 'newDeviceName')}/>

                    <div className="buttons-container">
                        <Button type="button"
                                shape="regular"
                                className="cta-cancel uppercase-button"
                                onClick={UIActions.closeCustomOverlay}>
                            {AppStore.translate('button.cancel')}
                        </Button>
                        <Button type="button"
                                shape="regular"
                                className="cta-payment-positive uppercase-button"
                                disabled={!this.state.newDeviceNameValid}
                                onClick={this._renameRegisteredDevice.bind(null, this.props.registeredDevice)}>
                            {AppStore.translate('button.rename')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * On field change
     *
     * @param {string} fieldName
     * @param {Object} value
     * @private
     */
    _onFieldChange = (fieldName, value) => {
        this.setState({
            newDeviceNameValid: value.trim().length > 3,
            newDeviceName: value
        });
    };

    /**
     * Rename registered device
     */
    _renameRegisteredDevice = (registeredDevice) => {
        UIActions.closeCustomOverlay();

        EntitlementActions.renameRegisteredDevice({
            registeredDevice: registeredDevice,
            registeredDeviceId: registeredDevice.id,
            newName: this.state.newDeviceName
        });
    };
}
