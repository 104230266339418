"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import shallowCompare from 'react-addons-shallow-compare';
import ReactDOM from 'react-dom';
import MediaQuery from '../../utils/MediaQuery';
import MediaItem from '../../types/MediaItem';
import AppStore from '../../stores/AppStore';
import Spinner from '../ui/Spinner2.react';
import Button from '../ui/Button.react';
import PromotionTag from '../ui/PromotionTag.react';
import MediaItemImage from './MediaItemImage.react';
import CTAButtons from './media-item-tile/CTAButtons.react';
import TileImage from './media-item-tile/TileImage.react';

/**
 * MediaItemTile component
 *
 * @mixes MediaItemComponentCTAs
 */
export default class MediaItemTile extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        mediaItem: PropTypes.instanceOf(MediaItem),
        toggleTileBubble: PropTypes.func,
        tileType: PropTypes.oneOf(['poster', 'poster-bubble', 'poster-cta', 'backdrop', 'backdrop-progress-bar', 'backdrop-detailed']),
        collapsible: PropTypes.bool,
        seeMore: PropTypes.bool,
        showAddItemPlaceholder: PropTypes.bool,
        seeMoreLink: PropTypes.string,
        useImageLoadingIndicator: PropTypes.bool,
        isPurchaseEnabled: PropTypes.bool,
        mediaItemInfoTextVisible: PropTypes.bool,
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        mediaItem: null,
        tileType: 'poster-bubble',
        collapsible: false,
        seeMore: false,
        seeMoreLink: '',
        useImageLoadingIndicator: true,
        isPurchaseEnabled: true,
        mediaItemInfoTextVisible: true,
    };

    /**
     * React: state
     */
    state = {
        isCollapsed: true,
        isCTAMenuActive: false
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        document.addEventListener('click', this._closeMobileMenuOnBlur, false);
    }

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        document.removeEventListener('click', this._closeMobileMenuOnBlur, false);
    }

    /**
     * React: render
     */
    render() {
        let mediaItem = this.props.mediaItem;
        let tileType = this.props.tileType;
        const {mediaItemInfoTextVisible} = this.props
        let className = 'media-item-tile'
            + ' ' + tileType
            + (this.props.collapsible ? ' collapsible' : '')
            + (this.props.collapsible && this.state.isCollapsed ? ' collapsed' : '')
            + (tileType === 'poster' || tileType === 'poster-bubble' || tileType === 'poster-cta' ? ' col-lg-2 col-md-3' : '')
            + (tileType === 'backdrop' || tileType === 'backdrop-progress-bar' ? ' col-lg-4 col-md-6' : '')
            + (tileType === 'backdrop-detailed' ? ' col-lg-2 col-md-3' : '')
            + (this.props.collapsible ? ' col-xs-12' : (
                    (tileType === 'poster' || tileType === 'poster-bubble' || tileType === 'poster-cta' || tileType === 'backdrop-detailed' ? ' col-xs-4' : '')
                    || (tileType === 'backdrop' || tileType === 'backdrop-progress-bar' ? ' col-xs-8' : '')
                ));

        if (!this.props.seeMore && !this.props.showAddItemPlaceholder) {
            return mediaItem ? (
                <div className={className}>
                    <div className="tile-content"
                         onMouseEnter={this._onMouseEnter}
                         onMouseLeave={this._onMouseLeave}>
                        {mediaItem.promotionTag ? (
                                <PromotionTag tag={mediaItem.promotionTag}/>
                            ) : null}
                        <TileImage mediaItem={mediaItem} tileType={tileType} onClick={this._toggleCollapsed}/>
                        <div className="mediaitem-tile-info">
                            {mediaItemInfoTextVisible && mediaItem.classification === 'Season' && (
                                <p className="mediaitem-tile-text">
                                    {`${mediaItem.sortTitle} (${mediaItem.childrenIds.length} episodes)`}
                                </p>
                            )}
                        </div>
                        <div className="cta-menu" ref="ctaMenu">
                            <CTAButtons isPurchaseEnabled={this.props.isPurchaseEnabled} mediaItem={mediaItem} isCTAMenuActive={this.state.isCTAMenuActive}/>
                            <Button shape="square-glass" className="cta-menu-button" onClick={this._toggleCTAMenu}>
                                <i className="ui-icon icon-menu-dots"/>
                            </Button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={className + " update-pending"}>
                    <div className="tile-content">
                        <MediaItemImage mediaItem={mediaItem}
                                        imageType={(tileType === 'backdrop' || tileType === 'backdrop-progress-bar') ? 'backdrop' : 'poster'}
                                        useImageLoadingIndicator={this.props.useImageLoadingIndicator}/>
                        <div className="information">
                            <h4 title="Loading..."><Spinner />{AppStore.translate('label.loading')}</h4>
                        </div>
                    </div>
                </div>
            );
        } else if (this.props.showAddItemPlaceholder) {
            return (
                <div className={className + " update-pending"}>
                    <div className="tile-content">
                        <div className="add-item-wrapper">
                            <span className="see-more-tile-link">Choose a movie above</span>
                        </div>
                        <MediaItemImage mediaItem={mediaItem}
                                        imageType={(tileType === 'backdrop' || tileType === 'backdrop-progress-bar') ? 'backdrop' : 'poster'}
                                        useImageLoadingIndicator={false}/>
                    </div>
                </div>
            );
        } else {
            return (this.props.seeMoreLink && (<div className={className + " update-pending"}>
                <div className="see-more-wrapper">
                    <Link to={this.props.seeMoreLink}>
                        <span className="see-more-tile-link">See more</span>
                    </Link>
                </div>
            </div>));
        }
    }

    /**
     * Toggle collapsed
     */
    _toggleCollapsed = (event) => {
        if (this.props.collapsible && !MediaQuery.match(MediaQuery.BREAKPOINT_MD)) {
            event.preventDefault();
            event.stopPropagation();

            this.setState({
                isCollapsed: !this.state.isCollapsed
            });
        }
    };

    /**
     * Toggle CTA menu
     */
    _toggleCTAMenu = () => {
        this.setState({
            isCTAMenuActive: !this.state.isCTAMenuActive
        });
    };

    /**
     * On mouse enter
     *
     * @private
     */
    _onMouseEnter = () => {
        if (typeof this.props.toggleTileBubble === 'function'
            && MediaQuery.match(MediaQuery.BREAKPOINT_LG)
            && this.props.tileType === 'poster-bubble') {
            this.props.toggleTileBubble(true, this.props.mediaItem, ReactDOM.findDOMNode(this).firstChild);
        }
    };

    /**
     * On mouse leave
     *
     * @private
     */
    _onMouseLeave = () => {
        if (typeof this.props.toggleTileBubble === 'function'
            && MediaQuery.match(MediaQuery.BREAKPOINT_LG)
            && this.props.tileType === 'poster-bubble') {
            this.props.toggleTileBubble(false);
        }
    };

    /**
     * Close mobile menu on blur
     *
     * @private
     */
    _closeMobileMenuOnBlur = (event) => {
        let ctaMenu = this.refs.ctaMenu;
        if (ctaMenu && !ctaMenu.contains(event.target)) {
            this.setState({
                isCTAMenuActive: false
            });
        }
    };
}
