"use strict";

import Promise from 'bluebird';
import * as MediaItemApi from './MediaItemApi';
import { MediaItemClassification } from '../../types/MediaItem';

/**
 * Get media item graph
 * for supplied mediaItemDTO returns related ancestors and descendants
 *
 * @param {MediaItemDTO} mediaItemDTO
 */
export function getMediaItemGraph(mediaItemDTO) {
    // create media graph hash
    let mediaGraph = {};

    // for each mediaItemDTO from collection get ancestors
    return _addMediaItemAncestors(mediaItemDTO, mediaGraph)
        .then(() => {
            return Promise.each(
                Object.keys(mediaGraph).map((key) => {
                    return mediaGraph[key];
                }),
                (graphMediaItemDTO) => {
                    return _addMediaItemDescendants(graphMediaItemDTO, mediaGraph);
                });
        })
        .then(() => {
            return mediaGraph;
        });
}

/**
 * Get media graph
 * for each mediaItemDTO from supplied mediaItemDTOCollection returns related ancestors and descendants
 *
 * @param {Array<MediaItemDTO>} mediaItemDTOCollection
 */
export function getMediaGraph(mediaItemDTOCollection) {
    // create media graph hash
    let mediaGraph = {};

    // for each mediaItemDTO from collection get ancestors
    return Promise.each(mediaItemDTOCollection,
        (mediaItemDTO) => {
            return _addMediaItemAncestors(mediaItemDTO, mediaGraph);
        })
        .then(() => {
            return Promise.each(
                Object.keys(mediaGraph).map((key) => {
                    return mediaGraph[key];
                }),
                (graphMediaItemDTO) => {
                    return _addMediaItemDescendants(graphMediaItemDTO, mediaGraph);
                });
        })
        .then(() => {
            return mediaGraph;
        });
}

/**
 * Add mediaItemDTO ancestors
 *
 * @param {MediaItemDTO} mediaItemDTO
 * @param {Object.<string, MediaItemDTO>} mediaGraph
 * @return {*}
 */
function _addMediaItemAncestors(mediaItemDTO, mediaGraph = {}) {
    // add mediaItemDTO to mediaGraph if not included
    if (typeof mediaGraph[mediaItemDTO.SupplierReference] === 'undefined') {
        mediaGraph[mediaItemDTO.SupplierReference] = mediaItemDTO;
    }

    // return mediaGraph if mediaItemDTO has no parent
    if (!mediaItemDTO.Parent) return Promise.resolve(mediaGraph);

    // process mediaItemDTO ancestors
    // or return mediaGraph if parent was already processed
    return typeof mediaGraph[mediaItemDTO.ParentId] !== 'undefined'
        ? Promise.resolve(mediaGraph)
        : MediaItemApi.getMediaItem({mediaItemId: mediaItemDTO.ParentId})
            .then((mediaItemDTO) => {
                return _addMediaItemAncestors(mediaItemDTO, mediaGraph);
            });
}

/**
 * Add mediaItemDTO descendants
 *
 * @param {MediaItemDTO} mediaItemDTO
 * @param {Object.<string, MediaItemDTO>} mediaGraph
 * @return {*}
 */
function _addMediaItemDescendants(mediaItemDTO, mediaGraph = {}) {
    // return if mediaItemDTO has no children
    if (!mediaItemDTO.ChildrenIds || mediaItemDTO.ChildrenIds.length === 0) return Promise.resolve(mediaGraph);

    // process mediaItemDTO descendants
    return MediaItemApi.getMedia({mediaItemIds: mediaItemDTO.ChildrenIds, includeInactiveMediaItems: true})
        .then((mediaItemDTOCollection) => {
            return Promise.each(
                mediaItemDTOCollection,
                (childMediaItemDTO) => {
                    // add childMediaItemDTO to mediaGraph if not included
                    if (typeof mediaGraph[childMediaItemDTO.SupplierReference] === 'undefined') {
                        mediaGraph[childMediaItemDTO.SupplierReference] = childMediaItemDTO;
                    }

                    return _addMediaItemDescendants(childMediaItemDTO, mediaGraph);
                });
        })
        .then(() => {
            return Promise.resolve(mediaGraph);
        });
}

/**
 * Get mediaItemDTO parent
 *
 * @param {MediaItemDTO} mediaItemDTO
 * @return {*}
 */
export function getMediaItemParent(mediaItemDTO) {
    // return mediaItemDTO if there is no parent
    if (!mediaItemDTO.ParentId) return Promise.resolve(mediaItemDTO);

    // return MediaItemDTO parent
    return MediaItemApi.getMediaItem({mediaItemId: mediaItemDTO.ParentId});
}

/**
 * Get mediaItemDTO root parent
 *
 * @param {MediaItemDTO} mediaItemDTO
 * @return {*}
 */
export function getMediaItemRootParent(mediaItemDTO) {
    if (mediaItemDTO.Classification === MediaItemClassification.TV_BOX_SET) {
        return Promise.resolve(mediaItemDTO);
    }

    return getMediaItemParent(mediaItemDTO)
        .then((parentMediaItemDTO) => {
            return parentMediaItemDTO.SupplierReference !== mediaItemDTO.SupplierReference
                ? getMediaItemParent(parentMediaItemDTO) : Promise.resolve(parentMediaItemDTO);
        });
}

/**
 * Get mediaItemDTO season parent
 *
 * @param {MediaItemDTO} mediaItemDTO
 * @return {*}
 */
export function getMediaItemSeasonParent(mediaItemDTO) {
    return getMediaItemParent(mediaItemDTO)
        .then((parentMediaItemDTO) => {
            return parentMediaItemDTO.SupplierReference !== mediaItemDTO.SupplierReference && mediaItemDTO.Classification === MediaItemClassification.SEASON
                ? getMediaItemParent(parentMediaItemDTO) : Promise.resolve(parentMediaItemDTO);
        });
}

/**
 * Generate mediaItemDTO bucket payload
 *
 * @param {MediaItemDTO} mediaItemDTO
 */
export function generateMediaItemBucketPayload(mediaItemDTO) {
    return Promise.resolve(mediaItemDTO)
        .then((mediaItemDTO) => {
            return {
                mediaItemDTOCollection: [mediaItemDTO],
                primaryMediaItemIds: mediaItemDTO.SupplierReference
            };
        });
}

/**
 * Generate mediaItemDTOCollection bucket payload
 *
 * @param {Array<MediaItemDTO>} mediaItemDTOCollection
 */
export function generateMediaBucketPayload(mediaItemDTOCollection) {
    return Promise.resolve(mediaItemDTOCollection)
        .then((mediaItemDTOCollection) => {
            return {
                mediaItemDTOCollection: mediaItemDTOCollection,
                primaryMediaItemIds: mediaItemDTOCollection.map((mediaItemDTO) => {
                    return mediaItemDTO.SupplierReference;
                })
            };
        });
}

/**
 * Generate mediaItemDTO Graph bucket payload
 *
 * @param {MediaItemDTO} mediaItemDTO
 */
export function generateMediaItemGraphBucketPayload(mediaItemDTO) {
    let primaryMediaItemIds = [];
    return Promise.resolve(mediaItemDTO)
        .then((mediaItemDTO) => {
            primaryMediaItemIds.push(mediaItemDTO.SupplierReference);
            return mediaItemDTO;
        })
        .then(getMediaItemGraph)
        .then((mediaGraph) => {
            return {
                mediaItemDTOCollection: Object.keys(mediaGraph).map((key) => {
                    return mediaGraph[key];
                }),
                primaryMediaItemIds: primaryMediaItemIds
            };
        });
}

/**
 * Generate mediaItemDTOCollection Graph bucket payload
 *
 * @param {Array<MediaItemDTO>} mediaItemDTOCollection
 */
export function generateMediaGraphBucketPayload(mediaItemDTOCollection) {
    let primaryMediaItemIds = [];
    return Promise.resolve(mediaItemDTOCollection)
        .then((mediaItemDTOCollection) => {
            primaryMediaItemIds = mediaItemDTOCollection.map((mediaItemDTO) => {
                return mediaItemDTO.SupplierReference;
            });
            return mediaItemDTOCollection;
        })
        .then(getMediaGraph)
        .then((mediaGraph) => {
            return {
                mediaItemDTOCollection: Object.keys(mediaGraph).map((key) => {
                    return mediaGraph[key];
                }),
                primaryMediaItemIds: primaryMediaItemIds
            };
        });
}
