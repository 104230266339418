"use strict";

import Immutable from 'immutable';
import moment from 'moment';
import PromotionItem, {PromotionItemType} from './PromotionItem';

/**
 * PromotionDTO definition
 *
 * @typedef {Object} PromotionDTO
 * @property {?string} Id - Id (Primary key)
 * @property {?string} AffiliateId - Affiliate Id. Zero indicates that this setting applies to all affiliates unless overridden
 * @property {?string} StartPublish - Promotion start date (required)
 * @property {?string} StopPublish - Promotion end date (optional)
 * @property {?string} Name - Promotion name
 * @property {?string} Title - Promotion title
 * @property {?string} Description - Promotion description
 * @property {?string} AppSettingGroupId - Promotion pp setting group id
 * @property {?string} ModifiedDate - Promotion modified date
 * @property {?string} ModifiedBy - Promotion was modified by
 * @property {?string} VersionTrack - Version track
 * @property {?Array<PromotionItemDTO>} Items - Promotion items collection
 */

/**
 * Promotion definition
 *
 * @typedef {Immutable.Record} Promotion
 * @property {?string} id
 * @property {?string} affiliateId
 * @property {?string} startPublish
 * @property {?string} stopPublish
 * @property {?string} name
 * @property {?string} title
 * @property {?string} description
 * @property {?string} appSettingGroupId
 * @property {?string} modifiedDate
 * @property {?string} modifiedBy
 * @property {?string} versionTrack
 * @property {?Array<PromotionItem>} items
 */

/**
 * Promotion record
 *
 * @type {Promotion}
 */
class Promotion extends Immutable.Record({
    id: null,
    affiliateId: null,
    name: null,
    title: null,
    description: null,
    appSettingGroupId: null,
    modifiedDate: null,
    modifiedBy: null,
    startPublish: null,
    stopPublish: null,
    versionTrack: null,
    items: [],
    mediaItemItems: [],
    collectionItems: [],
    genreItems: [],
    urlItems: []
}) {
    /**
     * toJS shallow
     * converts Record to object by keeping all second level immutable or other complex properties intact
     *
     * @return {Object}
     */
    toJSShallow() {
        return {
            ...this.toJS(),
            startPublish: this.startPublish,
            stopPublish: this.stopPublish,
            modifiedDate: this.modifiedDate,
            items: this.items
        };
    }

    /**
     * Set values from data transfer object
     *
     * @param {PromotionDTO} dataTransferObject
     * @return {Promotion}
     */
    fromDTO(dataTransferObject) {
        let items = typeof dataTransferObject.Items !== 'undefined' ? _getItemsFromDTO(dataTransferObject) : this.items;
        let mediaItemItems = items.filter(promotionItem => promotionItem.itemType === PromotionItemType.MEDIA_ITEM);
        let collectionItems = items.filter(promotionItem => promotionItem.itemType === PromotionItemType.COLLECTION);
        let genreItems = items.filter(promotionItem => promotionItem.itemType === PromotionItemType.GENRE);
        let urlItems = items.filter(promotionItem => promotionItem.itemType === PromotionItemType.URL);

        return new Promotion({
            ...this.toJSShallow(),
            id: typeof dataTransferObject.Id !== 'undefined' ? dataTransferObject.Id : this.id,
            affiliateId: typeof dataTransferObject.AffiliateId !== 'undefined' ? dataTransferObject.AffiliateId : this.affiliateId,
            name: typeof dataTransferObject.Name !== 'undefined' ? dataTransferObject.Name : this.name,
            title: typeof dataTransferObject.Title !== 'undefined' ? dataTransferObject.Title : this.title,
            description: typeof dataTransferObject.Description !== 'undefined' ? dataTransferObject.Description : this.description,
            appSettingGroupId: typeof dataTransferObject.AppSettingGroupId !== 'undefined' ? dataTransferObject.AppSettingGroupId : this.appSettingGroupId,
            modifiedDate: typeof dataTransferObject.ModifiedDate !== 'undefined' ? moment(dataTransferObject.ModifiedDate) : this.modifiedDate,
            modifiedBy: typeof dataTransferObject.ModifiedBy !== 'undefined' ? dataTransferObject.ModifiedBy : this.modifiedBy,
            startPublish: typeof dataTransferObject.StartPublish !== 'undefined' ? moment(dataTransferObject.StartPublish) : this.startPublish,
            stopPublish: typeof dataTransferObject.StopPublish !== 'undefined' ? moment(dataTransferObject.StopPublish) : this.stopPublish,
            versionTrack: typeof dataTransferObject.VersionTrack !== 'undefined' ? dataTransferObject.VersionTrack : this.versionTrack,
            items: items,
            mediaItemItems: mediaItemItems,
            collectionItems: collectionItems,
            genreItems: genreItems,
            urlItems: urlItems
        });
    }
}

/**
 * Get items from data transfer object
 *
 * @param {PromotionDTO} dataTransferObject
 * @returns {Array<PromotionItem>}
 * @private
 */
function _getItemsFromDTO(dataTransferObject) {
    var items = [];
    dataTransferObject.Items.forEach((promotionItemDTO) => {
        let promotionItem = new PromotionItem({}).fromDTO(promotionItemDTO);
        if (promotionItem.id) {
            items.push(promotionItem);
        }
    });

    return items;
}

export default Promotion;
