"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import Filter from '../../../lib/Filter';
import AppActions from '../../../actions/AppActions';
import AppStore from '../../../stores/AppStore';
import Button from '../../ui/Button.react';
import DropDownSelect from '../../ui/DropDownSelect.react';

/**
 * NavigationFilters component
 */
export default class NavigationFilters extends React.Component {

    /**
     * React: propTypes
     */
    static propTypes = {
        setSelectFieldFocus: PropTypes.func
    };

    /**
     * React: state
     */
    state = {
        activeFilters: AppStore.activeFilters
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        AppStore.addUpdateListener(this._setActiveFilters);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AppStore.removeUpdateListener(this._setActiveFilters);
    }

    /**
     * React: shouldComponentUpdate
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        return nextState.activeFilters !== this.state.activeFilters;
    }

    /**
     * React: render
     */
    render() {
        var certificateFilters = Filter.getTypesByGroup('certificate');
        var genreFilters = Filter.getTypesByGroup('genre');
        var releaseYearFilters = Filter.getTypesByGroup('year');
        var selectedGenreFilterCount = AppStore.activeFilters.reduce((selectedGenreFilterCount, filter, filterKey) => {
            return selectedGenreFilterCount + (filterKey.indexOf('GENRE_') !== -1 ? 1 : 0);
        }, 0);

        return (
            <div className="nav-menu-popup-outer filter-menu-outer right-menu">
                <div className="nav-menu-popup filter-menu">

                    <div className="filter-row-controls">
                        <span className="filter-controls" onClick={this._loadSavedFilters}>
                            <i className="ui-icon icon-undo"/>{AppStore.translate('button.use_last')}
                        </span>
                        <span className="filter-controls" onClick={this._resetFilters}>
                            <i className="ui-icon icon-reset"/>{AppStore.translate('button.reset')}
                        </span>
                    </div>

                    <div className="filter-row">
                        <span className="filter-label">{AppStore.translate('filter.label.certificate')}</span>
                        <DropDownSelect title={AppStore.translate('filter.certificate.' +
                            (this.state.activeFilters.findKey((filter, filterKey) => filterKey.indexOf('CERTIFICATE_') > -1) || 'any')
                                .replace('CERTIFICATE_', '').toLowerCase())}
                                        list={Object.keys(certificateFilters).map((filterKey)=> {
                                            return {
                                                value: AppStore.translate('filter.certificate.' + filterKey.replace('CERTIFICATE_', '').toLowerCase()),
                                                key: filterKey
                                            };
                                        })}
                                        toggleItem={this._toggleFilterFromSelect}/>
                    </div>

                    <div className="filter-row">
                        <span className="filter-label">{AppStore.translate('filter.label.release_year')}</span>
                        <DropDownSelect title={AppStore.translate('filter.year.' +
                            (this.state.activeFilters.findKey((filter, filterKey) => filterKey.indexOf('YEAR_') > -1) || 'any')
                            .replace('YEAR_', '').toLowerCase())}
                                        list={Object.keys(releaseYearFilters).map((filterKey)=> {
                                            return {
                                                value: AppStore.translate('filter.year.' + filterKey.replace('YEAR_', '').toLowerCase()),
                                                key: filterKey
                                            };
                                        })}
                                        toggleItem={this._toggleFilterFromSelect}/>
                    </div>

                    <div className="filter-row genre-row">
                        <span className="filter-label">
                            {AppStore.translate('filter.label.genre')}
                            {selectedGenreFilterCount > 0 ? (
                                <i className="selected-genres">({selectedGenreFilterCount})</i>
                            ) : null}
                        </span>
                        <div className="filter-tiles-container">
                            {Object.keys(genreFilters).map((filterKey)=> {
                                /** @type {Genre} */
                                let genre = genreFilters[filterKey];
                                return (
                                    <Button shape="icon-switch"
                                            key={filterKey}
                                            className="genre-filter"
                                            onClick={this._toggleFilter.bind(null, filterKey)}
                                            active={typeof this.state.activeFilters.get(filterKey) !== 'undefined'}>
                                        <i className="ui-icon"
                                           style={{backgroundImage: `url("${genre.imageActive}")`}}/>
                                        {genre.name}
                                    </Button>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Toggle filter from select
     */
    _toggleFilterFromSelect = (event) => {
        this._toggleFilter(event.target.value);
    };

    /**
     * Set active filters
     */
    _setActiveFilters = () => {
        this.setState({
            activeFilters: AppStore.activeFilters
        });
    };

    /**
     * Toggle filter
     * @param {string} filterKey
     */
    _toggleFilter = (filterKey) => {
        AppActions.toggleFilter(filterKey);
    };

    /**
     * Reset filters
     */
    _resetFilters = () => {
        AppActions.resetFilters();
    };

    /**
     * Load saved filter
     */
    _loadSavedFilters = () => {
        AppActions.loadSavedFilters();
    };
}
