"use strict";

import Config from './Config';
import ApiService from './api/ApiService';
import AuthStore from '../stores/AuthStore';
import AppStore from '../stores/AppStore';

/**
 * VubiquityAnalytics
 */
var VubiquityAnalytics = {
    // event
    event: (category, action, label) => {
        if (!Config.VUBIQUITY_ANALYTICS_ENABLED) return;

        ApiService.logEvent({
            eventName: action,
            loggingProperties: [
                {name: 'category', value: category},
                {name: 'label', value: label}
            ],
            loggingMetrics: []
        })
            .catch(() => {
                // no op
            });
    },

    // exception
    exception: (category, action, label) => {
        if (!Config.VUBIQUITY_ANALYTICS_ENABLED) return;

        ApiService.logException({
            eventName: action,
            loggingProperties: [
                {name: 'category', value: category},
                {name: 'label', value: label}
            ],
            loggingMetrics: []
        })
            .catch(() => {
                // no op
            });
    },

    // page view
    pageView: (pathname) => {
        if (!Config.VUBIQUITY_ANALYTICS_ENABLED) return;

        ApiService.logPageView({
            pageName: pathname,
            affiliateId: Config.AFFILIATE_ID,
            deviceId: AppStore.deviceId,
            sessionId: AppStore.sessionId,
            userId: AuthStore.isSignedIn() && AuthStore.identity ? AuthStore.identity.username : 0
        })
            .catch(() => {
                // no op
            });
    },

    // custom event
    customEvent: (customEventType, params = {}) => {
        if (!Config.VUBIQUITY_ANALYTICS_ENABLED) return;

        ApiService.logCustomEvent(VubiquityCustomEventType.prepareParams(customEventType, params))
            .catch(() => {
                // no op
            });
    }
};

var VubiquityCustomEventType = {
    LOG_SEARCH: 'LOG_SEARCH',
    LOG_APP_LAUNCH: 'LOG_APP_LAUNCH',
    LOG_SIGN_IN_ATTEMPT: 'LOG_SIGN_IN_ATTEMPT',
    LOG_PLAYBACK_BUFFERING: 'LOG_PLAYBACK_BUFFERING',
    LOG_PLAYBACK_QUALITY: 'LOG_PLAYBACK_QUALITY',
    LOG_PLAYBACK_START: 'LOG_PLAYBACK_START',
    LOG_PLAYBACK_PAUSE: 'LOG_PLAYBACK_PAUSE',
    LOG_PLAYBACK_STOP: 'LOG_PLAYBACK_STOP',
    LOG_PLAYBACK_FAULT: 'LOG_PLAYBACK_FAULT',

    /**
     * Prepare payload
     */
    prepareParams: function (customEventType, params) {
        let payload = {
            AffiliateId: Config.AFFILIATE_ID,
            DeviceId: AppStore.deviceId,
            UserId: AuthStore.isSignedIn() && AuthStore.identity ? AuthStore.identity.username : 0
        };

        switch (customEventType) {
            case this.LOG_SEARCH:
                payload = {
                    ...payload,
                    SearchTerm: params.searchQuery,
                    SearchType: params.searchType,
                    ResultsCount: params.resultTotalCount
                };
                break;

            case this.LOG_APP_LAUNCH:
                payload = {
                    ...payload,
                    AppSessionId: AppStore.sessionId,
                    TsnNumber: params.tsnNumber,
                    TimeInApp: params.timeInApp
                };
                break;

            case this.LOG_SIGN_IN_ATTEMPT:
                payload = {
                    ...payload,
                    IsLoginSuccessful: params.isLoginSuccessful,
                    ReasonForLoginFailure: params.errorMessage,
                    LoginError: params.errorMessage
                };
                break;

            case this.LOG_PLAYBACK_BUFFERING:
                payload = {
                    ...payload,
                    DurationInSeconds: params.durationInSeconds
                };
                break;

            case this.LOG_PLAYBACK_QUALITY:
                payload = {
                    ...payload,
                    PlaybackSessionId: AppStore.sessionId,
                    Bitrate: params.bitrate
                };
                break;

            case this.LOG_PLAYBACK_START:
                payload = {
                    ...payload,
                    PlaybackSessionId: AppStore.sessionId,
                    SupplierRef: params.mediaItemId,
                    PlayPosition: params.playPosition,
                    PlayerDuration: params.playerDuration
                };
                break;

            case this.LOG_PLAYBACK_PAUSE:
                payload = {
                    ...payload,
                    PlaybackSessionId: AppStore.sessionId,
                    SupplierRef: params.mediaItemId,
                    PlayPosition: params.playPosition,
                    PlayerDuration: params.playerDuration
                };
                break;

            case this.LOG_PLAYBACK_STOP:
                payload = {
                    ...payload,
                    PlaybackSessionId: AppStore.sessionId,
                    SupplierRef: params.mediaItemId,
                    PlayPosition: params.playPosition,
                    PlayerDuration: params.playerDuration
                };
                break;

            case this.LOG_PLAYBACK_FAULT:
                payload = {
                    ...payload,
                    PlaybackSessionId: AppStore.sessionId,
                    SupplierRef: params.mediaItemId,
                    FaultReason: params.errorMessage,
                    PlayPosition: params.playPosition,
                    PlayerDuration: params.playerDuration
                };
                break;
        }

        // return prepared params
        return {
            customEventType: customEventType,
            payload: payload
        };
    }
};

export {VubiquityAnalytics as default, VubiquityAnalytics, VubiquityCustomEventType};
