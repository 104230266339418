"use strict";

import Config from '../Config';

/**
 * Base API URL
 *
 * @type {string}
 */
let PROXY_API_URL = Config.PROXY_API_URL;
let VUB_API_URL = Config.VUB_API_URL;
let VUB_LOGWATCH_URL = Config.VUB_LOGWATCH_URL;
let VUB_LICENSOR_URL = Config.VUB_LICENSOR_URL;
let STRIPE_API_URL = Config.STRIPE_API_URL;
let GET_ADDRESS_IO_URL = Config.GET_ADDRESS_IO_URL;

/**
 * API endpoints
 */
export default {
    PROXY_API_URL: PROXY_API_URL,
    VUB_API_URL: VUB_API_URL,
    VUB_LOGWATCH_URL: VUB_LOGWATCH_URL,
    VUB_LICENSOR_URL: VUB_LICENSOR_URL,

    // Auth
    SIGN_UP_USER: VUB_API_URL + `/api/Account/${Config.AFFILIATE_ID}`,
    RESEND_ACTIVATION_EMAIL: VUB_API_URL + `/api/Account/${Config.AFFILIATE_ID}/ResendValidation`,
    ACTIVATE_USER: VUB_API_URL + `/api/Account/ValidateActivationToken/${Config.AFFILIATE_ID}`,
    ACCEPT_TERMS_AND_CONDITIONS: VUB_API_URL + `/api/Account/AcceptTandC/${Config.AFFILIATE_ID}`,
    SIGN_IN_USER: VUB_API_URL + `/api/Identity/Validate/${Config.AFFILIATE_ID}`,
    GET_USER_PROFILE: VUB_API_URL + `/api/Profile/${Config.AFFILIATE_ID}`,
    UPDATE_USER_PROFILE: VUB_API_URL + `/api/Profile/${Config.AFFILIATE_ID}`,
    GET_USER_PREFERENCES: VUB_API_URL + `/api/Profile/Preferences/${Config.AFFILIATE_ID}`,
    UPDATE_USER_PREFERENCES: VUB_API_URL + `/api/Profile/Preferences/${Config.AFFILIATE_ID}`,
    CHANGE_USER_PASSWORD: VUB_API_URL + `/api/Account/ChangePassword/${Config.AFFILIATE_ID}`,
    SEND_RESET_USER_PASSWORD_EMAIL: VUB_API_URL + `/api/Account/SendResetPasswordEmail/${Config.AFFILIATE_ID}`,
    RESET_USER_PASSWORD: VUB_API_URL + `/api/Account/ResetPassword/${Config.AFFILIATE_ID}`,
    CREATE_PAYMENT_PROVIDER_CUSTOMER: (providerKey = 'stripe') => VUB_API_URL + `/api/Purchase/${Config.AFFILIATE_ID}/${providerKey}/CreateAccount`,
    UPDATE_PAYMENT_PROVIDER_CUSTOMER: (providerKey = 'stripe') => VUB_API_URL + `/api/Purchase/${Config.AFFILIATE_ID}/${providerKey}/UpdateAccount`,
    REMOVE_PAYMENT_PROVIDER_CUSTOMER: (providerKey = 'stripe') => VUB_API_URL + `/api/Purchase/${Config.AFFILIATE_ID}/${providerKey}/RemoveAccount`,
    CREATE_STRIPE_TOKEN: STRIPE_API_URL + `/v1/tokens`,
    RETRIEVE_STRIPE_TOKEN: (stripeTokenId) => STRIPE_API_URL + `/v1/tokens/${stripeTokenId}`,
    GET_CUSTOMER_PAYMENT_DETAILS: VUB_API_URL + `/api/Purchase/${Config.AFFILIATE_ID}/GetCustomerPaymentDetail/Stripe`,

    // App
    GET_APP_SETTINGS: VUB_API_URL + `/api/AppSettings/${Config.AFFILIATE_ID}/${Config.APP_SETTINGS_GROUP}/`,
    GET_COLLECTION_LIST: VUB_API_URL + `/api/Collections/${Config.AFFILIATE_ID}`,
    GET_PROMOTION_LIST: VUB_API_URL + `/api/Promotions/GetCurrent/${Config.AFFILIATE_ID}`,
    GET_TRANSLATIONS: PROXY_API_URL + `/v2/translations/${Config.ID}/en`,
    GET_GENRE_LIST: VUB_API_URL + `/api/Genres/${Config.AFFILIATE_ID}?v=3`,
    GET_GENRE_ICONS: PROXY_API_URL + `/v2/genres/${Config.ID}/${Config.LOCALE}`,
    GET_GENRE_MEDIA_FILTERED: VUB_API_URL + `/api/Genres/MediaItems/Filter/${Config.AFFILIATE_ID}`,
    GET_CERTIFICATE_LIST: PROXY_API_URL + `/v2/certificates`,
    GET_PLATFORM_HELP: VUB_API_URL + `/api/Platform/${Config.AFFILIATE_ID}/Help`,
    GET_PLATFORM_TERMS_AND_CONDITIONS: VUB_API_URL + `/api/Platform/${Config.AFFILIATE_ID}/TandCs`,
    CREATE_ALERT: VUB_API_URL + `/api/Alerts/${Config.AFFILIATE_ID}`,
    REMOVE_ALERT: (alertId) => VUB_API_URL + `/api/Alerts/${Config.AFFILIATE_ID}/${alertId}`,
    GET_ALERTS: (alertType) => VUB_API_URL + `/api/Alerts/Current/${Config.AFFILIATE_ID}${alertType ? '/' + alertType : ''}`,
    CANCEL_ALERT: (alertId) => VUB_API_URL + `/api/Alerts/${Config.AFFILIATE_ID}/Cancel/${alertId}`,
    INCREMENT_ALERT_DISPLAY_COUNT: (alertId) => VUB_API_URL + `/api/Alerts/${Config.AFFILIATE_ID}/Displayed/${alertId}`,
    LOOKUP_ADDRESS_BY_POSTCODE: (postcode) => GET_ADDRESS_IO_URL + `/find/${postcode}`,

    // Media
    SEARCH_MEDIA: PROXY_API_URL + `/v2/media-items/search`,
    GET_MEDIA: PROXY_API_URL + `/v2/media-items`,
    GET_MEDIA_ITEM: (mediaItemId) => PROXY_API_URL + `/v2/media-items/${mediaItemId}`,
    GET_MEDIA_ITEM_RATING: (mediaItemId) => PROXY_API_URL + `/v2/media-items/${mediaItemId}/rating`,
    RATE_MEDIA_ITEM: (mediaItemId) => PROXY_API_URL + `/v2/media-items/${mediaItemId}/rating`,
    GET_MEDIA_ITEM_RECOMMENDATIONS: (mediaItemId) => PROXY_API_URL + `/v2/media-items/${mediaItemId}/recommendations`,
    GET_COLLECTION_MEDIA: VUB_API_URL + `/api/Collections/MediaItems/${Config.AFFILIATE_ID}`,

    // Watchlist
    GET_WATCHLIST: PROXY_API_URL + `/v2/watch-list`,
    ADD_MEDIA_ITEM_TO_WATCHLIST: (offerId) => PROXY_API_URL + `/v2/watch-list/${offerId}`,
    REMOVE_MEDIA_ITEM_FROM_WATCHLIST: (basketItemId) => PROXY_API_URL + `/v2/watch-list/${basketItemId}`,

    // Purchase
    GET_BASKET_ITEMS: VUB_API_URL + `/api/Basket/${Config.AFFILIATE_ID}`,
    ADD_MEDIA_ITEM_OFFER_TO_BASKET: VUB_API_URL + `/api/Basket/${Config.AFFILIATE_ID}`,
    REMOVE_ITEM_FROM_BASKET: VUB_API_URL + `/api/Basket/${Config.AFFILIATE_ID}`,
    VALIDATE_BUNDLE_OFFER: (collectionId) => VUB_API_URL + `/api/Purchase/${Config.AFFILIATE_ID}/ValidateBundleOffer/${collectionId}`,
    CHARGE_BASKET: VUB_API_URL + `/api/Purchase/${Config.AFFILIATE_ID}/ChargeCustomerBasket`,
    VERIFY_VOUCHER: (voucherCode) => VUB_API_URL + `/api/Purchase/${Config.AFFILIATE_ID}/VerifyVoucher/${voucherCode}`,
    CREATE_PURCHASE_ORDER_VOD_ITEM: (offerId, subscription = false) => VUB_API_URL + `/api/Purchase/${Config.AFFILIATE_ID}/PurchaseOrderItem/${subscription ? 'SVOD' : 'FVOD'}/${offerId}`,

    // Entitlement
    GET_ENTITLEMENT: (mediaItemId) => VUB_API_URL + `/api/Entitlement/${Config.AFFILIATE_ID}/${mediaItemId}`,
    GET_CURRENT_ENTITLEMENTS: VUB_API_URL + `/api/Entitlement/Current/${Config.AFFILIATE_ID}`,
    GET_HISTORY_ENTITLEMENTS: VUB_API_URL + `/api/Entitlement/History/${Config.AFFILIATE_ID}`,
    GET_RECENTLY_VIEWED_ITEMS: VUB_API_URL + `/api/Entitlement/RecentlyViewed/${Config.AFFILIATE_ID}`,
    GET_AMS_LICENSE: VUB_API_URL + `/api/License/RequestAmsLicense/${Config.AFFILIATE_ID}`,
    GET_TRAILER_LICENSE: VUB_API_URL + `/api/License/RequestTrailerLicense/${Config.AFFILIATE_ID}`,
    REGISTER_DEVICE: VUB_API_URL + `/api/Devices/${Config.AFFILIATE_ID}/Register`,
    GET_REGISTERED_DEVICES: VUB_API_URL + `/api/Devices/${Config.AFFILIATE_ID}/Get`,
    CHANGE_REGISTERED_DEVICE_NAME: (registeredDeviceId) => VUB_API_URL + `/api/Devices/${Config.AFFILIATE_ID}/ChangeName/${registeredDeviceId}`,
    REMOVE_REGISTERED_DEVICE: (registeredDeviceId) => VUB_API_URL + `/api/Devices/${Config.AFFILIATE_ID}/Unregister/${registeredDeviceId}`,
    CREATE_PLAYBACK_LOG: VUB_LOGWATCH_URL + `/api/logwatch/${Config.AFFILIATE_ID}`,

    // Vision 360 API
    VISION360_API_URL: Config.VISION360_API_URL,
    VISION360_AUTH_KEY: Config.VISION360_AUTH_KEY,

    // Logging
    LOG_EVENT: VUB_API_URL + `/api/Logging/Event`,
    LOG_EXCEPTION: VUB_API_URL + `/api/Logging/Exception`,
    LOG_PAGE_VIEW: VUB_API_URL + `/api/Logging/Pageview`,
    LOG_SEARCH: VUB_API_URL + `/api/Logging/Search`,
    LOG_APP_LAUNCH: VUB_API_URL + `/api/Logging/AppLaunch`,
    LOG_SIGN_IN_ATTEMPT: VUB_API_URL + `/api/Logging/LoginAttempt`,
    LOG_PLAYBACK_BUFFERING: VUB_API_URL + `/api/Logging/Buffering`,
    LOG_PLAYBACK_QUALITY: VUB_API_URL + `/api/Logging/PlaybackQuality`,
    LOG_PLAYBACK_START: VUB_API_URL + `/api/Logging/PlaybackStart`,
    LOG_PLAYBACK_PAUSE: VUB_API_URL + `/api/Logging/PlaybackPause`,
    LOG_PLAYBACK_STOP: VUB_API_URL + `/api/Logging/PlaybackStop`,
    LOG_PLAYBACK_FAULT: VUB_API_URL + `/api/Logging/PlaybackFault`
};
