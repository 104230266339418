"use strict";

import 'moment-range';
import * as Request from './Request';
import ApiEndpoints from './ApiEndpoints';
import * as MediaItemApi from './MediaItemApi';

/**
 * Get basket items
 *
 * @return {*}
 */
export function getBasketItems() {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.GET_BASKET_ITEMS,
        method: 'GET',
        qs: {
            v: 3
        }
    })
        .then(body => body || [])
        .then(basketItemDTOCollection => basketItemDTOCollection.map(_preProcessBasketItem));
}

/**
 * Add mediaItem offer to the basket
 *
 * @param {Object} params
 * @return {*}
 */
export function addMediaItemOfferToBasket(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.ADD_MEDIA_ITEM_OFFER_TO_BASKET,
        method: 'POST',
        qs: {
            v: 3,
            offerId: params.offerId,
            includeMediaItem: params.includeMediaItem ? params.includeMediaItem : false
        }
    })
        .then(basketItemDTOCollection => basketItemDTOCollection.map(_preProcessBasketItem))
        .then((basketItemDTOCollection) => {
            return basketItemDTOCollection.find(basketItemDTO => basketItemDTO.SupplierReference === params.mediaItemId);
        })
        .then((basketItemDTO) => {
            return MediaItemApi.getMediaItem({
                mediaItemId: params.mediaItemId
            }).then((mediaItemDTO) => {
                basketItemDTO.MediaItemDTO = mediaItemDTO;
                return basketItemDTO;
            });
        });
}

/**
 * Remove item from basket
 *
 * @param {Object} params
 * @return {*}
 */
export function removeBasketItem(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.REMOVE_ITEM_FROM_BASKET,
        method: 'DELETE',
        qs: {
            v: 3,
            basketItemId: params.basketItemId,
            includeMediaItem: params.includeMediaItem
        }
    });
}

/**
 * Remove all items from basket
 *
 * @return {*}
 */
export function removeAllBasketItems() {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.REMOVE_ITEM_FROM_BASKET,
        method: 'DELETE',
        qs: {
            v: 3
        }
    });
}

/**
 * Validate bundle offer
 *
 * @param {Object} params
 * @return {*}
 */
export function validateBundleOffer(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.VALIDATE_BUNDLE_OFFER(params.collectionId),
        method: 'GET',
        qs: {
            v: 3,
            includeMediaItem: typeof params.includeMediaItem !== 'undefined' ? params.includeMediaItem : false
        }
    });
}

/**
 * Charge basket with payment provider customer ID
 *
 * @param {Object} params
 * @return {*}
 */
export function chargeBasketWithPaymentProviderCustomerId(params = {}) {
    let voucherParams = params.voucher
        ? {
            VoucherCode: params.voucherCode,
            VoucherRedeemableValue: params.voucher.redeemableValue
        }
        : {};

    return Request.getAuthRequestPromise({
        url: ApiEndpoints.CHARGE_BASKET,
        method: 'POST',
        qs: {
            v: 3
        },
        body: {
            CollectionId: params.collectionId,
            AmountDue: params.amountDue,
            CurrencyCode: params.currencyCode,
            PaymentProviderType: "Stripe",
            ...voucherParams
        }
    });
}

/**
 * Charge basket with payment provider customer ID
 *
 * @param {Object} params
 * @return {*}
 */
export function chargeBasketWithStripeV3(params = {}) {
    let voucherParams = params.voucher
        ? {
            VoucherCode: params.voucherCode,
            VoucherRedeemableValue: params.voucher.redeemableValue
        }
        : {};
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.CHARGE_BASKET,
        method: 'POST',
        qs: {
            v: 3
        },
        body: {
            CollectionId: params.collectionId,
            AmountDue: params.amountDue,
            CurrencyCode: params.currencyCode,
            PaymentProviderType: "Stripe",
            ...voucherParams
        }
    });
}

/**
 * Charge basket with temporary card token
 *
 * @param {Object} params
 * @return {*}
 */
export function chargeBasketWithTemporaryCardToken(params = {}) {
    let voucherParams = params.voucher
        ? {
            VoucherCode: params.voucherCode,
            VoucherRedeemableValue: params.voucher.redeemableValue
        }
        : {};

    return Request.getAuthRequestPromise({
        url: ApiEndpoints.CHARGE_BASKET,
        method: 'POST',
        qs: {
            v: 3
        },
        body: {
            TempCardToken: params.stripeToken,
            SaveCardProvider: params.saveCard || false,
            AmountDue: params.amountDue,
            CurrencyCode: params.currencyCode,
            PaymentProviderType: "Stripe",
            ...(params.collectionId ? {CollectionId: params.collectionId} : {}),
            ...voucherParams
        }
    });
}

/**
 * Verify voucher
 *
 * @param {Object} params
 * @return {*}
 */
export function verifyVoucher(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.VERIFY_VOUCHER(params.voucherCode.replace(/[#?&=]/g, '') || 'none'),
        method: 'GET',
        qs: {
            v: 3,
            ...(params.collectionId ? {collectionId: params.collectionId} : {})
        }
    });
}

/**
 * Create purchase order VOD item
 *
 * @param {Object} params
 * @return {*}
 */
export function createPurchaseOrderVODItem(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.CREATE_PURCHASE_ORDER_VOD_ITEM(params.offerId, params.subscription),
        method: 'POST',
        qs: {
            includeMediaItem: typeof params.includeMediaItem !== 'undefined' ? params.includeMediaItem : true
        }
    });
}

/**
 * Pre-process basketItemDTO
 * - renames property SupplierRef into SupplierReference
 * - removes MediaItem property
 *
 * @param {BasketItemDTO} basketItemDTO
 * @return {BasketItemDTO}
 */
function _preProcessBasketItem(basketItemDTO) {
    basketItemDTO.SupplierReference = basketItemDTO.SupplierRef;
    delete basketItemDTO.SupplierRef;
    delete basketItemDTO.MediaItemMidiDTO;

    return basketItemDTO;
}
