"use strict";

import React from "react";
import PropTypes from "prop-types";
import ShakaMediaPlayer from "../../../lib/ShakaMediaPlayer";

ShakaMediaPlayer.initialize();

class ShakaPlayerPrototype extends React.Component {
  /**
   * React: propTypes
   */
  static propTypes = {
    autoPlay: PropTypes.bool,
    poster: PropTypes.string.isRequired,
    video: PropTypes.string.isRequired,
  };

  /**
   * React: defaultProps
   */
  static defaultProps = {
    autoPlay: true,
  };

  componentDidMount() {
    this.initApp();
  }

  initPlayer = async () => {
    const video = document.getElementById('video');
    const player = new shaka.Player();
    await player.attach(video);
    window.player = this.player;
    player.addEventListener('error', this.onErrorEvent);

    try {
      await player.load(this.props.video);
      console.log('The video has now been loaded!');
    } catch (e) {
      this.onError(e);
    }
  }

  initApp = () => {
    shaka.polyfill.installAll();
    if (shaka.Player.isBrowserSupported()) {
      this.initPlayer();
    } else {
      console.error('Browser not supported!');
    }
  }

  onErrorEvent = (event) => {
    onError(event.detail);
  }

  onError = (error) => {
    console.error('Error code', error.code, 'object', error);
  }

  render() {
    return (
      <video
        id="video"
        width="720"
        height="480"
        poster={this.props.poster}
        controls
        autoPlay={this.props.autoPlay}>
      </video>
    );
  }
}
export default ShakaPlayerPrototype;
