"use strict";

import React from 'react';
import AppActions from '../../actions/AppActions';
import AppStore from '../../stores/AppStore';

/**
 * FilterBar component
 */
export default class FilterBar extends React.Component {
    /**
     * React: state
     */
    state = {
        activeFilters: AppStore.activeFilters
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        AppStore.addUpdateListener(this._setActiveFilters);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AppStore.removeUpdateListener(this._setActiveFilters);
    }

    /**
     * React: shouldComponentUpdate
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        return nextState.activeFilters !== this.state.activeFilters;
    }

    /**
     * React: render
     */
    render() {
        var filterTags = [];
        this.state.activeFilters.forEach((filter, filterKey) => {
            filterTags.push(
                <span className="filter-category" key={filterKey}
                      onClick={this._toggleFilter.bind(null, filterKey)}>
                    {filter.name}
                    <i className="ui-icon icon-close"/>
                </span>
            );
        });

        return !this.state.activeFilters.isEmpty() ? (
            <div className="filter-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <span className="filter-categories-container">
                                <span className="filter-label">{AppStore.translate('view.filter_bar.title')}</span>
                                {filterTags}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        ) : null;
    }

    /**
     * Set active filters
     */
    _setActiveFilters = () => {
        this.setState({
            activeFilters: AppStore.activeFilters
        });
    };

    /**
     * Toggle filter
     * @param {string} filterKey
     */
    _toggleFilter = (filterKey) => {
        AppActions.toggleFilter(filterKey);
    };
}
