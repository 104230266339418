"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import shallowCompare from 'react-addons-shallow-compare';
import {Link} from 'react-router';
import store from 'store';
import MediaQuery from '../utils/MediaQuery';
import Router from '../lib/Router';
import OverlayIds from '../lib/OverlayIds';
import BundleShoppingCart from '../types/BundleShoppingCart';
import AppActions from '../actions/AppActions';
import UIActions from '../actions/UIActions';
import MediaActions from '../actions/MediaActions';
import PurchaseActions from '../actions/PurchaseActions';
import AppStore from '../stores/AppStore';
import AuthStore from '../stores/AuthStore';
import MediaStore from '../stores/MediaStore';
import PurchaseStore from '../stores/PurchaseStore';
import Button from './ui/Button.react';
import MediaItemImage from './elements/MediaItemImage.react';
import MediaItemTileBubble from './elements/MediaItemTileBubble.react';
import BundleShoppingCartControls from './elements/BundleShoppingCartControls.react';

/**
 * BundlePage component
 */
export default class BundlePage extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        params: PropTypes.object,
        location: PropTypes.object
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: state
     */
    state = {
        mediaBucket: null,
        collection: null,
        bundleShoppingCart: null,
        updatePending: false,
        shouldTileBubbleBeVisible: false,
        tileBubbleMediaItem: null,
        tileBubbleMediaItemTileNode: null
    };

    /**
     * Bucket keys
     */
    _mediaBucketKey = null;

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        if (this.props.params && this.props.params.collectionTitle && AppStore.collectionListByTitle.get(this.props.params.collectionTitle)) {
            this._mediaBucketKey = MediaActions.getCollectionMedia({
                collectionTitle: this.props.params.collectionTitle,
                skipPreProcessing: true // we need to load children too here so we are switching this flag on
            });

            const collection = AppStore.collectionListByTitle.get(this.props.params.collectionTitle);
            this.state = {
                ...this.state,
                collection: collection,
                bundleShoppingCart: collection ? PurchaseStore.getBundleShoppingCart(collection.id) : new BundleShoppingCart()
            };
        }
    }

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        MediaStore.addUpdateListener(this._setMediaBucket);
        PurchaseStore.addUpdateListener(this._setShoppingCartState);
    }

    /**
     * React: UNSAFE_componentWillReceiveProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((nextProps.params && nextProps.params.collectionTitle && nextProps.params.collectionTitle !== this.props.params.collectionTitle
                && AppStore.collectionListByTitle.get(this.props.params.collectionTitle))) {
            MediaStore.destroyMediaBucket(this._mediaBucketKey);
            this._mediaBucketKey = null;
            const collection = AppStore.collectionListByTitle.get(nextProps.params.collectionTitle);
            this.setState({
                mediaBucket: null,
                collection: collection,
                bundleShoppingCart: collection ? PurchaseStore.getBundleShoppingCart(collection.id) : new BundleShoppingCart()
            }, () => {
                this._mediaBucketKey = MediaActions.getCollectionMedia({
                    collectionTitle: nextProps.params.collectionTitle,
                    skipPreProcessing: true
                });
            });
        }
    }

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        MediaStore.removeUpdateListener(this._setMediaBucket);
        PurchaseStore.removeUpdateListener(this._setShoppingCartState);
        this._mediaBucketKey = MediaStore.destroyMediaBucket(this._mediaBucketKey);
    }

    /**
     * React: render
     */
    render() {
        const collection = this.state.collection;
        const mediaBucket = this.state.mediaBucket;
        const media = mediaBucket ? mediaBucket.media() : null;
        const bundleShoppingCart = this.state.bundleShoppingCart;
        const cartItems = bundleShoppingCart.cartItems;

        return (
            <main className="bundle-page">
                {typeof collection === 'undefined' ? (
                    <div className="no-results">
                        {AppStore.translate('view.collection_page.message.collection_not_found')}
                    </div>
                ) : (media && media.size === 0 ? (
                    <div className="no-results">
                        {AppStore.translate('view.collection_page.message.no_media_in_collection', {collectionTitle: this.props.params.collectionTitle})}
                    </div>
                ) : (media && media.size > 0 ? (
                    <div className="bundle-media-gallery">
                        <div className="container">
                            {collection ? (
                                <div className="row">
                                    <div className="col-xs-12 header">
                                        <h2>{collection.title}</h2>
                                    </div>
                                </div>
                            ) : null}

                            <div className="row">
                                <div className="movies-container">
                                    {media.toArray().map((mediaItem, key) => {
                                        const isInShoppingCart = !!cartItems.get(mediaItem.id);
                                        return (
                                            <div key={key} className="col-lg-2 col-md-3">
                                                <Link to={Router.MEDIA_ITEM(mediaItem)} onClick={this._onClick}>
                                                    <MediaItemImage mediaItem={mediaItem}
                                                                    tileType="poster-bubble"
                                                                    useImageLoadingIndicator={true}
                                                                    onMouseEnter={this._onMouseEnter.bind(null, mediaItem)}
                                                                    onMouseLeave={this._onMouseLeave}
                                                                    className={`js-media-item-image-${mediaItem.id}`}/>
                                                </Link>
                                                <Button shape="plain"
                                                        className={`flat-button ${isInShoppingCart ? 'btn-selected' : ''}`}
                                                        disabled={this.state.updatePending
                                                            || bundleShoppingCart.updatePending
                                                            || mediaItem.isPurchased
                                                            || (!isInShoppingCart && cartItems.size === collection.bundleOffer.minSelectQuantity)}
                                                        onClick={isInShoppingCart ? this._removeMediaItemOfferFromBundleShoppingCart.bind(null, mediaItem)
                                                            : this._addMediaItemOfferToBundleShoppingCart.bind(null, mediaItem)}>
                                                    {mediaItem.isPurchased ? 'Purchased' : (isInShoppingCart ? 'Remove' : 'Add to cart')}
                                                </Button>
                                            </div>
                                        );
                                    })}
                                </div>
                                <MediaItemTileBubble shouldTileBubbleBeVisible={this.state.shouldTileBubbleBeVisible}
                                                     isPurchaseEnabled={false}
                                                     mediaItem={this.state.tileBubbleMediaItem}
                                                     tileNode={this.state.tileBubbleMediaItemTileNode}/>
                            </div>
                        </div>
                    </div>
                ) : null))}

                {collection && mediaBucket ? (
                    <BundleShoppingCartControls collection={collection}
                                                mediaBucket={mediaBucket}
                                                bundleShoppingCart={bundleShoppingCart}
                                                totalSlotCount={collection.bundleOffer.minSelectQuantity}
                                                removeMediaItemOfferFromBundleShoppingCart={this._removeMediaItemOfferFromBundleShoppingCart}
                                                fastCheckoutBundleOffer={this._fastCheckoutBundleOffer}
                                                updatePending={this.state.updatePending}
                    />
                ) : null}
            </main>
        );
    }

    /**
     * Set mediaBucket
     */
    _setMediaBucket = () => {
        const mediaBucket =  MediaStore.getMediaBucket(this._mediaBucketKey);

        this.setState({
            mediaBucket: mediaBucket,
            tileBubbleMediaItem: mediaBucket && this.state.tileBubbleMediaItem ? mediaBucket.mediaItem(this.state.tileBubbleMediaItem.id) : null
        });
    };

    /**
     * Set shopping cart state
     */
    _setShoppingCartState = () => {
        const collection = this.state.collection;
        this.setState({
            bundleShoppingCart: collection ? PurchaseStore.getBundleShoppingCart(collection.id) : new BundleShoppingCart(),
            updatePending: PurchaseStore.updatePending
        });
    };

    /**
     * Remove mediaItem from shopping cart
     *
     * @param {MediaItem} mediaItem
     */
    _addMediaItemOfferToBundleShoppingCart = (mediaItem) => {
        const collection = this.state.collection;
        const mediaBucket = this.state.mediaBucket;
        const media = mediaBucket ? mediaBucket.media() : null;
        if (!media || !collection) return;

        if (!AuthStore.isSignedIn()) {
            AppActions.storeRouterTransitionPath(Router.COLLECTIONS(collection));
            this.context.router.push(Router.SIGN_IN);
            return;
        }

        if (!AuthStore.userProfile.paymentProviderCustomerIds.stripe) {
            UIActions.showCustomOverlay((
                <div className="alert-box-size col-xs-12 col-sm-8 col-md-6">
                    <h2 className="overlay-header">{AppStore.translate('view.cta_action_modal.title.finish_payment_setup')}</h2>
                    <div className="overlay-content">
                        <p>{AppStore.translate('view.cta_action_modal.message.finish_payment_setup')}</p>
                        <div className="buttons-container">
                            <Button
                                type='button'
                                shape="regular"
                                onClick={UIActions.closeCustomOverlay}>{AppStore.translate('button.cancel')}</Button>
                            <Button
                                type='button'
                                className="cta-virgin-purple"
                                shape="regular"
                                onClick={this._closeInfoOverlayAndOpenPaymentPopup}>{AppStore.translate('button.cta.go_to_my_account')}
                            </Button>
                        </div>
                    </div>
                </div>
            ));
            return;
        }

        const offer = mediaItem.offers[0] || null;
        if (!offer) return;

        const bundleShoppingCart = this.state.bundleShoppingCart;
        PurchaseActions.addMediaItemOfferToBundleShoppingCart({
            collectionId: collection.id,
            collection: collection,
            mediaItemId: mediaItem.id,
            offerId: offer.id,
            isShoppingCartFull: bundleShoppingCart.cartItems.size + 1 === collection.bundleOffer.minSelectQuantity,
            mediaItemOffers: [
                ...bundleShoppingCart.cartItems.toArray(),
                {mediaItemId: mediaItem.id, offerId: offer.id}
            ],
            currencyCode: typeof media.first().offers[0] !== 'undefined'
                ? media.first().offers[0].currency : undefined
        });
    };

    /**
     * Remove mediaItem from shopping cart
     *
     * @param {MediaItem} mediaItem
     */
    _removeMediaItemOfferFromBundleShoppingCart = (mediaItem) => {
        const collection = this.state.collection;
        if (!collection) return;

        PurchaseActions.removeMediaItemOfferFromBundleShoppingCart({
            collectionId: collection.id,
            mediaItemId: mediaItem.id
        });
    };

    /**
     * Fast checkout bundleOffer
     *
     * @private
     */
    _fastCheckoutBundleOffer = () => {
        const collection = this.state.collection;
        const mediaBucket = this.state.mediaBucket;
        const media = mediaBucket ? mediaBucket.media() : null;
        const bundleShoppingCart = this.state.bundleShoppingCart;
        if (!media || !collection || bundleShoppingCart.cartItems.size !== collection.bundleOffer.minSelectQuantity) return;

        PurchaseActions.fastCheckoutBundleOffer({
            collectionId: collection.id,
            collection: collection,
            mediaItemOffers: bundleShoppingCart.cartItems.toArray(),
            currencyCode: typeof media.first().offers[0] !== 'undefined'
                ? media.first().offers[0].currency : undefined
        });
    };

    /**
     * On mouse enter
     *
     * @private
     */
    _onMouseEnter = (mediaItem) => {
        if (MediaQuery.match(MediaQuery.BREAKPOINT_LG)) {
            this._toggleTileBubble(
                true,
                mediaItem,
                ReactDOM.findDOMNode(this).querySelector(`.js-media-item-image-${mediaItem.id}`)
            );
        }
    };

    /**
     * On mouse leave
     *
     * @private
     */
    _onMouseLeave = () => {
        if (MediaQuery.match(MediaQuery.BREAKPOINT_LG)) {
            this._toggleTileBubble(false);
        }
    };

    /**
     * Toggle tile bubble
     *
     * @param shouldBeVisible
     * @param mediaItem
     * @param tileNode
     * @private
     */
    _toggleTileBubble = (shouldBeVisible, mediaItem = null, tileNode = null) => {
        this.setState({
            shouldTileBubbleBeVisible: shouldBeVisible,
            tileBubbleMediaItem: shouldBeVisible ? mediaItem : this.state.tileBubbleMediaItem,
            tileBubbleMediaItemTileNode: shouldBeVisible ? tileNode : this.state.tileBubbleMediaItemTileNode
        });
    };

    /**
     * Navigate to
     *
     * @param link
     * @private
     */
    _navigateTo = (link) => {
        const collection = this.state.collection;

        store.set("purchase_started", "YES");
        store.set("purchase_path", Router.COLLECTIONS(collection));
        AppActions.storeRouterTransitionPath(Router.COLLECTIONS(collection));
        this.context.router.push(link);
    };

    /**
     * close info overlay and open payment popup
     */
    _closeInfoOverlayAndOpenPaymentPopup = () => {
        UIActions.closeCustomOverlay();
        UIActions.showCustomOverlay({
            overlayId: OverlayIds.PAYMENT_METHOD,
            showCloseButton: false
        });
    };
}
