"use strict";

import * as Request from './Request';
import ApiEndpoints from './ApiEndpoints';

/**
 * Get watchlist
 *
 * @return {*}
 */
export function getWatchlist() {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.GET_WATCHLIST,
        method: 'GET'
    })
        .then(body => body.Data)
        .then(watchlistItemDTOCollection => watchlistItemDTOCollection.map(_preProcessWatchlistItem));
}

/**
 * Add media item to watchlist
 *
 * @param {Object} params
 * @return {*}
 */
export function addMediaItemToWatchlist(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.ADD_MEDIA_ITEM_TO_WATCHLIST(params.offerId),
        method: 'PUT'
    })
        .then(body => body.Data)
        .then(watchlistItemDTOCollection => watchlistItemDTOCollection.map(_preProcessWatchlistItem))
        .then(
            watchlistItemDTOCollection => watchlistItemDTOCollection.filter(
                watchlistItemDTO => watchlistItemDTO.SupplierReference === params.mediaItemId
            )[0]
        );
}

/**
 * Remove media item from watchlist
 *
 * @param {Object} params
 * @return {*}
 */
export function removeMediaItemFromWatchlist(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.REMOVE_MEDIA_ITEM_FROM_WATCHLIST(params.watchlistItemId),
        method: 'DELETE',
        form: {}
    })
        .then(body => body.Data)
        .then(watchlistItemDTOCollection => watchlistItemDTOCollection.map(_preProcessWatchlistItem));
}

/**
 * Pre-process watchlistItemDTO
 * - renames property SupplierRef into SupplierReference
 * - removes MediaItemMidiDTO property
 *
 * @param {WatchlistItemDTO} watchlistItemDTO
 * @return {WatchlistItemDTO}
 */
function _preProcessWatchlistItem(watchlistItemDTO) {
    watchlistItemDTO.SupplierReference = watchlistItemDTO.SupplierRef;
    delete watchlistItemDTO.SupplierRef;
    delete watchlistItemDTO.MediaItemMidiDTO;

    return watchlistItemDTO;
}
