"use strict";

import Immutable from 'immutable';
import moment from 'moment';
import slugify from 'slugify';
import Cast from './Cast';
import Subtitle from './Subtitle';
import Offer, {OfferType} from './Offer';
import Furniture from './Furniture';
import ExternalIdentifier from './ExternalIdentifier';
import MediaItemImageTools from './decorators/MediaItemImageTools';

/**
 * MediaItemDTO definition
 *
 * @typedef {Object} MediaItemDTO
 * @property {?string} SupplierReference - The unique reference to this media item
 * @property {?number} Id - Media Item Id (not used)
 * @property {?string} Title - The full Film or Episode Title
 * @property {?string} SortTitle - Title to be used for sorting only
 * @property {?string} TitleKeywords - Title keywords where special characters have been substituted. To be used for
 *                                     internal english based searches.
 * @property {?number} ReleaseYear - The year the film was released
 * @property {?string} Certificate - This is the Media Items Certificate rating. e.g. '15', 'PG', 'U' etc..
 * @property {?string} Duration - Media item duration in MINUTES
 * @property {?string} Classification - This is the Media Item type classification. e.g. 'Movie', 'Series', 'Season' or 'Episode'
 * @property {?Array<CastDTO>} Cast - A collection of cast and production members names and their role. Including Directors.
 * @property {?string} NewsletterSynopsis - Newsletter Synopsis (Max 500 chars)
 * @property {?string} LongSynopsis - Long Synopsis (Max 255 chars)
 * @property {?string} MediumSynopsis - Medium Synopsis (Max 180 chars)
 * @property {?string} ShortSynopsis - Short Synopsis (Max 60 chars)
 * @property {?string} TrailerUri - Url to the trailer video asset
 * @property {?string} FurnitureBaseUrl - The base Url to prefix all furniture image url properties with to resolve
 *                                        the full location. This is the largest common component found and is to help
 *                                        reduce the size of the return payload. Prefix all Furniture Uris with this to
 *                                        get the full asset Uri
 * @property {?Array<FurnitureDTO>} Furniture - A list of Media Item furniture (usually images)
 * @property {?Array<ExternalIdentifierDTO>} ExternalIdentifiers - A list of key - values that hold identifiers used
 *                                                                 by external parties that relate to a Media Item
 * @property {?Array<OfferDTO>} Offers - Offers available for this Media Item and Affiliate. This
 *                                       includes the type of media items (HD, SD), price, currency and dates.
 * @property {?Array<GenreDTO>} Genres - List of genres
 * @property {?MediaItemDTO} Parent [api pre-processor] (also on FilmFlex API)
 * @property {?Array<MediaItemDTO>} Children [proxy api, removed by api pre-processor] (also on FilmFlex API)
 * @property {?Array<SubtitleDTO>} Subtitles - List of subtitles tracks available for this media item
 * @property {?number} NumberInSequence - Episode or Series Number
 * @property {?number} SeasonNumber - The number of the season of episode
 * @property {?number} TotalNumberOfParts - Total number of Episodes or Series
 * @property {?string} ParentId - Media Item parent id [api pre-processor] (also on FilmFlex API)
 * @property {?Array<string>} AudioLanguage - A list of audio languages available
 * @property {?boolean} AudioDescriptionAvailable - Gets or sets the audio description available
 * @property {?string} AffiliateContentAdvice - The affiliate content advice
 * @property {?string} BrandSupplierReference - The supplier reference of the brand of episode or season
 * @property {?Array<string>} ChildrenIds [api pre-processor]
 * @property {?Array<MediaItemDTO>} Parents [proxy api, removed by api pre-processor]
 * @property {?number} Rating - Rating 0-10 (not clear if certificate or user rating)
 * @property {?Array<string>} PromotionTag [proxy api]
 * @property {?Array<string>} PrimaryGenre [proxy api]
 * @property {?Array<string>} SecondaryGenre [proxy api]
 * @property {?string} Slug [proxy api]
 */

/**
 * MediaItem definition
 *
 * @typedef {Immutable.Record} MediaItem
 * @property {?string} id
 * @property {?string} bucketKey
 * @property {?string} title
 * @property {?string} sortTitle
 * @property {?string} titleKeywords
 * @property {?string} slug
 * @property {?number} releaseYear
 * @property {?string} certificate
 * @property {?string} duration
 * @property {string} classification
 * @property {Array<Cast>} cast
 * @property {Array<Subtitles>} subtitles
 * @property {?string} newsletterSynopsis
 * @property {?string} longSynopsis
 * @property {?string} mediumSynopsis
 * @property {?string} shortSynopsis
 * @property {?string} trailerUrl
 * @property {?string} furnitureBaseUrl
 * @property {Array<Furniture>} furniture
 * @property {Array<ExternalIdentifier>} externalIdentifiers
 * @property {Array<Offer>} offers
 * @property {Array<string>} genres
 * @property {string} parentId
 * @property {?number} numberInSequence
 * @property {?number} seasonNumber
 * @property {Array<string>} childrenIds
 * @property {?number} rating
 * @property {?boolean} ratingPending
 * @property {?number} userRating
 * @property {?string} contentAdvice
 * @property {?string} promotionTag
 * @property {Array<string>} secondaryGenres
 * @property {boolean} isOnWatchlist
 * @property {?WatchlistItem} watchlistItem
 * @property {boolean} watchlistPending
 * @property {boolean} isPurchased
 * @property {?Entitlement} entitlement
 * @property {boolean} entitlementPending
 * @property {boolean} playbackAvailable
 * @property {boolean} downloadAvailable
 * @property {boolean} isRecentlyViewed
 * @property {number} watchedPercentage
 * @property {boolean} updatePending
 */

/**
 * MediaItem record
 *
 * @type {MediaItem}
 */
class MediaItem extends Immutable.Record({
    id: null,
    bucketKey: null,
    title: null,
    sortTitle: null,
    titleKeywords: null,
    slug: null,
    releaseYear: null,
    certificate: null,
    duration: null,
    classification: null,
    cast: [],
    subtitles: [],
    newsletterSynopsis: null,
    longSynopsis: null,
    mediumSynopsis: null,
    shortSynopsis: null,
    trailerUrl: null,
    furnitureBaseUrl: null,
    furniture: [],
    externalIdentifiers: [],
    offers: [],
    parentId: [],
    numberInSequence: null,
    seasonNumber: null,
    childrenIds: [],
    childrenCount: 0,
    rating: null,
    ratingPending: false,
    userRating: null,
    contentAdvice: null,
    promotionTag: null,
    genres: [],
    secondaryGenres: [],
    audioLanguages: [],
    watchlistAvailable: false,
    isOnWatchlist: false,
    watchlistItem: null,
    watchlistPending: false,
    isPurchased: false,
    entitlement: null,
    entitlementPending: false,
    playbackAvailable: false,
    downloadAvailable: false,
    isRecentlyViewed: false,
    watchedPercentage: 0,
    updatePending: false
}) {
    /**
     * toJS shallow
     * converts Record to object by keeping all second level immutable or other complex properties intact
     *
     * @return {Object}
     */
    toJSShallow() {
        return {
            ...this.toJS(),
            furniture: this.furniture,
            cast: this.cast,
            offers: this.offers,
            externalIdentifiers: this.externalIdentifiers,
            watchlistItem: this.watchlistItem,
            entitlement: this.entitlement
        };
    }

    /**
     * Set values from data transfer object
     *
     * @param {MediaItemDTO} dataTransferObject
     * @return {MediaItem}
     */
    fromDTO(dataTransferObject) {
        return new MediaItem({
            ...this.toJSShallow(),
            id: typeof dataTransferObject.SupplierReference !== 'undefined' ? dataTransferObject.SupplierReference : this.id,
            title: typeof dataTransferObject.Title !== 'undefined' ? dataTransferObject.Title : this.title,
            sortTitle: typeof dataTransferObject.SortTitle !== 'undefined' ? dataTransferObject.SortTitle : this.sortTitle,
            titleKeywords: typeof dataTransferObject.TitleKeywords !== 'undefined' ? dataTransferObject.TitleKeywords : this.titleKeywords,
            slug: _getSlugFromDTO(dataTransferObject),
            releaseYear: typeof dataTransferObject.ReleaseYear !== 'undefined' ? dataTransferObject.ReleaseYear : this.releaseYear,
            certificate: typeof dataTransferObject.Certificate !== 'undefined' ? dataTransferObject.Certificate : this.certificate,
            duration: typeof dataTransferObject.Duration !== 'undefined' ? dataTransferObject.Duration : this.duration,
            classification: typeof dataTransferObject.Classification !== 'undefined' ? MediaItemClassification.getValue(dataTransferObject.Classification) : this.classification,
            newsletterSynopsis: typeof dataTransferObject.NewsletterSynopsis !== 'undefined' ? dataTransferObject.NewsletterSynopsis : this.newsletterSynopsis,
            longSynopsis: typeof dataTransferObject.LongSynopsis !== 'undefined' ? dataTransferObject.LongSynopsis : this.longSynopsis,
            mediumSynopsis: typeof dataTransferObject.MediumSynopsis !== 'undefined' ? dataTransferObject.MediumSynopsis : this.mediumSynopsis,
            shortSynopsis: typeof dataTransferObject.ShortSynopsis !== 'undefined' ? dataTransferObject.ShortSynopsis : this.shortSynopsis,
            trailerUrl: typeof dataTransferObject.TrailerUri !== 'undefined' && dataTransferObject.TrailerUri != null ? _formatTrailerUrl(dataTransferObject) : this.trailerUrl,
            childrenCount: typeof dataTransferObject.TotalNumberOfParts !== 'undefined' ? dataTransferObject.TotalNumberOfParts : this.childrenCount,
            rating: typeof dataTransferObject.Rating !== 'undefined' ? dataTransferObject.Rating : this.rating,
            furnitureBaseUrl: typeof dataTransferObject.FurnitureBaseUrl !== 'undefined' ? dataTransferObject.FurnitureBaseUrl : this.furnitureBaseUrl,
            furniture: typeof dataTransferObject.Furniture !== 'undefined' ? _getFurnitureFromDTO(dataTransferObject) : this.furniture,
            cast: typeof dataTransferObject.Cast !== 'undefined' ? _getCastFromDTO(dataTransferObject) : this.cast,
            subtitles: typeof dataTransferObject.Subtitles !== 'undefined' ? _getSubtitlesFromDTO(dataTransferObject) : this.subtitles,
            offers: typeof dataTransferObject.Offers !== 'undefined' ? _getOffersFromDTO(dataTransferObject) : this.offers,
            externalIdentifiers: typeof dataTransferObject.ExternalIdentifiers !== 'undefined' ? _getExternalIdentifiersFromDTO(dataTransferObject) : this.externalIdentifiers,
            parentId: typeof dataTransferObject.ParentId !== 'undefined' ? dataTransferObject.ParentId : this.parentId,
            numberInSequence: typeof dataTransferObject.NumberInSequence !== 'undefined' ? dataTransferObject.NumberInSequence : this.numberInSequence,
            seasonNumber: typeof dataTransferObject.SeasonNumber !== 'undefined' ? dataTransferObject.SeasonNumber : this.seasonNumber,
            childrenIds: typeof dataTransferObject.ChildrenIds !== 'undefined' ? dataTransferObject.ChildrenIds : this.childrenIds,
            contentAdvice: typeof dataTransferObject.AffiliateContentAdvice !== 'undefined' ? dataTransferObject.AffiliateContentAdvice : this.contentAdvice,
            promotionTag: typeof dataTransferObject.PromotionTag !== 'undefined' ? dataTransferObject.PromotionTag[0] : this.promotionTag,
            genres: typeof dataTransferObject.Genres !== 'undefined' ? this.setGenres(dataTransferObject.Genres) : this.genres,
            secondaryGenres: typeof dataTransferObject.SecondaryGenre !== 'undefined' ? dataTransferObject.SecondaryGenre : this.secondaryGenres,
            audioLanguages: typeof dataTransferObject.AudioLanguage !== 'undefined' ? dataTransferObject.AudioLanguage : this.audioLanguages
        });
    }

    /**
     * Set update pending
     *
     * @param {boolean} updatePending
     * @return {MediaItem}
     */
    setUpdatePending(updatePending) {
        return this.updatePending !== updatePending ? new MediaItem({
            ...this.toJSShallow(),
            updatePending: updatePending
        }) : this;
    }

    /**
     * Set bucket key
     *
     * @param {string} bucketKey
     * @return {MediaItem}
     */
    setBucketKey(bucketKey) {
        return this.bucketKey !== bucketKey ? new MediaItem({
            ...this.toJSShallow(),
            bucketKey: bucketKey
        }) : this;
    }

    /**
     * Set watchlist state
     *
     * @param {Immutable.Map<string, WatchlistItem>} watchlist
     * @return {MediaItem}
     */
    setWatchlistState(watchlist) {
        var watchlistAvailable = this.offers.reduce((watchlistAvailable, offer) => watchlistAvailable || offer.offerType !== OfferType.POEST, false) && !this.isPurchased;
        var watchlistItem = watchlist.get(this.id);
        var isOnWatchlist = watchlistItem ? watchlistItem.isOnWatchlist : false;
        var watchlistPending = watchlistItem ? watchlistItem.updatePending : false;

        return this.watchlistAvailable !== watchlistAvailable
        || this.isOnWatchlist !== isOnWatchlist
        || this.watchlistItem !== watchlistItem
        || this.watchlistPending !== watchlistPending ? new MediaItem({
            ...this.toJSShallow(),
            watchlistAvailable: watchlistAvailable,
            isOnWatchlist: isOnWatchlist,
            watchlistItem: watchlistItem,
            watchlistPending: watchlistPending,
            updatePending: watchlistPending
        }) : this;
    }

    /**
     *  Set entitlement state
     *
     * @param {Immutable.Map<string, Entitlement>} currentEntitlementsByMediaItem
     * @param {MediaItem} fromMediaItem - mediaItem to use state from
     * @return {MediaItem}
     */
    setEntitlementState(currentEntitlementsByMediaItem, fromMediaItem = null) {
        var entitlement = currentEntitlementsByMediaItem.get(fromMediaItem ? fromMediaItem.id : this.id);
        var isPurchased = typeof entitlement !== 'undefined';
        var playbackAvailable = isPurchased
            && this.classification !== MediaItemClassification.MOVIE_BOX_SET
            && this.classification !== MediaItemClassification.TV_BOX_SET;
        var downloadAvailable = isPurchased
            && (this.classification === MediaItemClassification.MOVIE || this.classification === MediaItemClassification.EPISODE)
            && this.classification !== MediaItemClassification.MOVIE_BOX_SET
            && this.classification !== MediaItemClassification.TV_BOX_SET;
        var entitlementPending = entitlement ? entitlement.updatePending : false;

        return this.isPurchased !== isPurchased
        || this.playbackAvailable !== playbackAvailable
        || this.downloadAvailable !== downloadAvailable
        || this.entitlementPending !== entitlementPending
        || this.entitlement !== entitlement ? new MediaItem({
            ...this.toJSShallow(),
            isPurchased: isPurchased,
            playbackAvailable: playbackAvailable,
            downloadAvailable: downloadAvailable,
            entitlement: entitlement,
            entitlementPending: entitlementPending,
            updatePending: entitlementPending
        }) : this;
    }

    /**
     * Set offers basket state
     *
     * @param {Immutable.Map<number, BasketItem>} basket
     * @return {MediaItem}
     */
    setOffersBasketState(basket) {
        var offers = this.offers.map((offer) => {
            return offer.setBasketState(basket);
        });

        var updatePending = offers.reduce((updatePending, offer) => {
            return updatePending || offer.updatePending;
        }, false);

        return new MediaItem({
            ...this.toJSShallow(),
            offers: offers,
            updatePending: updatePending
        });
    }

    /**
     *  Set recently viewed state
     *
     * @param {Immutable.Map<string, RecentlyViewedItem>} recentlyViewedItems
     * @return {MediaItem}
     */
    setRecentlyViewedState(recentlyViewedItems) {
        var recentlyViewedItem = recentlyViewedItems.get(this.id);
        var isRecentlyViewed = typeof recentlyViewedItem !== 'undefined';

        return this.isRecentlyViewed !== isRecentlyViewed ? new MediaItem({
            ...this.toJSShallow(),
            isRecentlyViewed: isRecentlyViewed,
            watchedPercentage: recentlyViewedItem
                ? _calculateWatchedPercentage(recentlyViewedItem.lastPlayPosition, this.duration) : 0
        }) : this;
    }

    /**
     *  Set rating state
     *
     * @param {boolean} ratingPending
     * @param {?number} userRating
     * @param {?number} rating
     * @return {MediaItem}
     */
    setRatingState(ratingPending, userRating = null, rating = null) {
        userRating = userRating || this.userRating;
        rating = rating || this.rating;

        return this.ratingPending !== ratingPending
        || this.rating !== rating
        || this.userRating !== userRating ?
            new MediaItem({
                ...this.toJSShallow(),
                ratingPending: ratingPending,
                rating: rating,
                userRating: userRating
            }) : this;
    }

    /**
     *  Set Genres
     *
     * @param {?Array} genres
     * @return {Array}
     */
    setGenres(genres) {
        return genres.map( item => item.Name);
    }
}
MediaItem = MediaItemImageTools(MediaItem);

/**
 * Get slug from data transfer object
 *
 * @param {MediaItemDTO} dataTransferObject
 * @returns {string}
 * @private
 */
function _getSlugFromDTO(dataTransferObject) {
    let slug = '';

    if (typeof dataTransferObject.Slug !== 'undefined') {
        slug = dataTransferObject.Slug;
    } else if (typeof dataTransferObject.Title !== 'undefined') {
        slug = slugify(dataTransferObject.Title);
        slug = slug.toLowerCase();
    }

    return slug;
}

/**
 * Get furniture from data transfer object
 *
 * @param {MediaItemDTO} dataTransferObject
 * @returns {Array<Furniture>}
 * @private
 */
function _getFurnitureFromDTO(dataTransferObject) {
    let furniture = [];

    // fix types so they are sortable by size, filter out non-valid types
    dataTransferObject.Furniture
        .filter(furnitureDTO => furnitureDTO.FurnitureType.indexOf('_') !== -1)
        .map(furnitureDTO => {
            let furnitureTypeParts = furnitureDTO.FurnitureType.split('_');
            furnitureDTO.FurnitureType = furnitureTypeParts[0] + '_'
                + furnitureTypeParts[1].split('x')
                    .map(furnitureSize => furnitureSize.length === 2 ? `00${furnitureSize}`
                        : (furnitureSize.length === 3 ? `0${furnitureSize}` : furnitureSize))
                    .join('x');
        });

    dataTransferObject.Furniture.forEach((furnitureDTO) => {
        let furnitureItem = new Furniture({}).fromDTO(furnitureDTO);
        if (furnitureItem.furnitureType) {
            furniture.push(furnitureItem);
        }
    });

    return furniture
        .sort((furnitureA, furnitureB) => {
            let furnitureAType = furnitureA.furnitureType.split('_');
            let furnitureBType = furnitureB.furnitureType.split('_');
            if (furnitureAType[0] > furnitureBType[0]) {
                return 1;
            } else if (furnitureAType[0] < furnitureBType[0]) {
                return -1;
            } else {
                return 0;
            }
        })
        .sort((furnitureA, furnitureB) => {
            let furnitureAType = furnitureA.furnitureType.split('_');
            let furnitureBType = furnitureB.furnitureType.split('_');
            if (furnitureAType[0] === furnitureBType[0] && furnitureAType[1] < furnitureBType[1]) {
                return 1;
            } else if (furnitureAType[0] === furnitureBType[0] && furnitureAType[1] > furnitureBType[1]) {
                return -1;
            } else {
                return 0;
            }
        });
}

/**
 * Get cast from data transfer object
 *
 * @param {MediaItemDTO} dataTransferObject
 * @returns {Array<Cast>}
 * @private
 */
function _getCastFromDTO(dataTransferObject) {
    var cast = [];
    dataTransferObject.Cast.forEach((castDTO) => {
        let castMember = new Cast({}).fromDTO(castDTO);
        if (castMember.type) {
            cast.push(castMember);
        }
    });

    return cast;
}

/**
 * Get cast from data transfer object
 *
 * @param {MediaItemDTO} dataTransferObject
 * @returns {Array<Cast>}
 * @private
 */
function _getSubtitlesFromDTO(dataTransferObject) {
    var subtitles = [];
    dataTransferObject.Subtitles.forEach((subtitleDTO) => {
        let subtitle = new Subtitle({}).fromDTO(subtitleDTO);
        subtitles.push(subtitle);
    });

    return subtitles;
}

/**
 * Get offers from data transfer object
 *
 * @param {MediaItemDTO} dataTransferObject
 * @returns {Array<Offer>}
 * @private
 */
function _getOffersFromDTO(dataTransferObject) {
    var offers = [];
    dataTransferObject.Offers.forEach((offerDTO) => {
        let offer = new Offer({}).fromDTO(offerDTO);
        if (offer.id && offer.endAvailability > moment() && offer.startAvailability < moment()) {
            offers.push(offer);
        }
    });

    return offers;
}

/**
 * Calculate watched percentage
 *
 * @param {string} lastPlayPosition
 * @param {string} duration
 * @returns {number}
 * @private
 */
function _calculateWatchedPercentage(lastPlayPosition = '', duration = '0') {
    let lastPlayPositionParts = (lastPlayPosition && lastPlayPosition.split(":")) || '';
    let playSeconds = lastPlayPositionParts.length === 3
        ? parseInt(lastPlayPositionParts[0]) * 3600
        + parseInt(lastPlayPositionParts[1]) * 60
        + parseInt(lastPlayPositionParts[2])
        : 0;
    let durationSeconds = parseInt(duration, 10) * 60;

    return Math.round(playSeconds / durationSeconds * 100);
}

/**
 * Get external identifiers from data transfer object
 *
 * @param {MediaItemDTO} dataTransferObject
 * @returns {Array<ExternalIdentifier>}
 * @private
 */
function _getExternalIdentifiersFromDTO(dataTransferObject) {
    var externalIdentifiers = [];
    dataTransferObject.ExternalIdentifiers.forEach((externalIdentifierDTO) => {
        let externalIdentifier = new ExternalIdentifier({}).fromDTO(externalIdentifierDTO);
        if (externalIdentifier.key) {
            externalIdentifiers.push(externalIdentifier);
        }
    });

    return externalIdentifiers;
}

/**
 * Format trailer URL
 *
 * @param {MediaItemDTO} dataTransferObject
 * @returns {string}
 * @private
 */
function _formatTrailerUrl(dataTransferObject) {
    let trailerUrl = dataTransferObject.TrailerUri;
    // trailerUrl = trailerUrl.replace('http:', '').replace('https:', '');
    trailerUrl = trailerUrl.toLowerCase().indexOf('.ism') > -1 && trailerUrl.toLowerCase().indexOf('/manifest') === -1
        ? trailerUrl + '/manifest' : trailerUrl;

    return trailerUrl;
}

/**
 * Media item classification
 * @type {Object}
 */
var MediaItemClassification = {
    MOVIE: 'Movie',
    SERIES: 'Brand',
    SEASON: 'Season',
    EPISODE: 'Episode',
    MOVIE_BOX_SET: 'MovieBoxSet',
    TV_BOX_SET: 'TVBoxSet',

    /**
     * Get value
     */
    getValue: function (inputValue) {
        var value = this.MOVIE;
        Object.keys(this).forEach((key) => {
            if (this[key] === inputValue) value = this[key];
        });
        return value;
    }
};

export {MediaItem as default, MediaItem, MediaItemClassification};
