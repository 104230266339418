import React from 'react';
import PropTypes from 'prop-types';

const ToggleButton = props => {
    const buttonIsCheckedClass = props.isChecked ? 'gdpr-is-checked' : '';
    const buttonDisabledClass = props.isDisabled ? 'gdpr-is-disabled' : '';
    const className = `gdpr-toggle-button ${props.className} ${buttonIsCheckedClass} ${buttonDisabledClass}`;

    return (
        <label className={className}>
            <div className={`gdpr-toggle-button__inner ${buttonIsCheckedClass} ${buttonDisabledClass}`} />
            <input
                type="checkbox"
                name={props.name}
                checked={props.isChecked}
                disabled={props.isDisabled}
                onChange={props.onClick}
                className={`gdpr-toggle-button__input ${buttonIsCheckedClass} ${buttonDisabledClass}`}
            />
        </label>
    );
};

ToggleButton.propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
    isChecked: PropTypes.bool,
    className: PropTypes.string
};

ToggleButton.defaultProps = {
    onClick: () => {},
    name: '',
    isDisabled: false,
    isChecked: false,
    className: ''
};

export default ToggleButton;
