"use strict";

import Immutable from 'immutable';
import moment from 'moment';

/**
 * PromotionItemDTO definition
 *
 * @typedef {Object} PromotionItemDTO
 * @property {?number} Id - Id (Primary key)
 * @property {?number} PromotionId - Promotion id
 * @property {?string} Name - Promotion item unique name
 * @property {?string} PromotionItemTypeId - Promotion item type: 'Collection', 'Genre', 'MediaItem' or 'Url'
 * @property {?string} PromotionalLink - PromotionalLink - Reference string, value depending on Promotion item type:
 *                      Collection = CollectionName, Genre = GenreName, MediaItem = SupplierRef, Url = Hyperlink text url
 * @property {?string} PromotionalLinkText - Alternative text for promotional link
 * @property {?string} ImageUrl - Promotion item image URL
 * @property {?number} Ordinality - Promotion item ordinarity
 * @property {?number} TemplateId - Promotion item template id
 * @property {?string} ModifiedDate - Promotion item modified date and time
 * @property {?string} ModifiedBy - Promotion item was modified by
 * @property {?string} StartPublish - Promotion start date (required)
 * @property {?string} StopPublish - Promotion end date (optional)
 * @property {?string} VersionTrack - Promotion item version track
 * @property {?Array<MediaItemDTO>} MediaItems - Promotion item media items
 * @property {?string} DataFile - Gets or sets the data file
 */

/**
 * PromotionItem definition
 *
 * @typedef {Immutable.Record} PromotionItem
 * @property {?number} id
 * @property {?number} promotionId
 * @property {?string} name
 * @property {?string} itemType
 * @property {?string} promotionalLink
 * @property {?string} promotionalLinkText
 * @property {?string} imageUrl
 * @property {?number} ordinality
 * @property {?number} templateId
 * @property {?string} modifiedDate
 * @property {?string} modifiedBy
 * @property {?string} startPublish
 * @property {?string} stopPublish
 * @property {?string} versionTrack
 * @property {?string} dataFile
 */

/**
 * PromotionItem record
 *
 * @type {PromotionItem}
 */
class PromotionItem extends Immutable.Record({
    id: null,
    promotionId: null,
    name: null,
    itemType: null,
    promotionalLink: null,
    promotionalLinkText: null,
    imageUrl: null,
    ordinality: null,
    templateId: null,
    modifiedDate: null,
    modifiedBy: null,
    startPublish: null,
    stopPublish: null,
    versionTrack: null,
    dataFile: null
}) {
    /**
     * toJS shallow
     * converts Record to object by keeping all second level immutable or other complex properties intact
     *
     * @return {Object}
     */
    toJSShallow() {
        return {
            ...this.toJS(),
            startPublish: this.startPublish,
            stopPublish: this.stopPublish,
            modifiedDate: this.modifiedDate
        };
    }

    /**
     * Set values from data transfer object
     *
     * @param {PromotionItemDTO} dataTransferObject
     * @return {PromotionItem}
     */
    fromDTO(dataTransferObject) {
        return new PromotionItem({
            ...this.toJS(),
            id: typeof dataTransferObject.Id !== 'undefined' ? dataTransferObject.Id : this.id,
            promotionId: typeof dataTransferObject.PromotionId !== 'undefined' ? dataTransferObject.PromotionId : this.promotionId,
            name: typeof dataTransferObject.Name !== 'undefined' ? dataTransferObject.Name : this.name,
            itemType: typeof dataTransferObject.PromotionItemTypeId !== 'undefined' ? PromotionItemType.getValue(dataTransferObject.PromotionItemTypeId) : this.itemType,
            promotionalLink: typeof dataTransferObject.PromotionalLink !== 'undefined' ? dataTransferObject.PromotionalLink : this.promotionalLink,
            promotionalLinkText: typeof dataTransferObject.PromotionalLinkText !== 'undefined' ? dataTransferObject.PromotionalLinkText : this.promotionalLinkText,
            imageUrl: typeof dataTransferObject.ImageUrl !== 'undefined' ? dataTransferObject.ImageUrl : this.imageUrl,
            ordinality: typeof dataTransferObject.Ordinality !== 'undefined' ? dataTransferObject.Ordinality : this.ordinality,
            templateId: typeof dataTransferObject.TemplateId !== 'undefined' ? dataTransferObject.TemplateId : this.templateId,
            modifiedDate: typeof dataTransferObject.ModifiedDate !== 'undefined' ? moment(dataTransferObject.ModifiedDate) : this.modifiedDate,
            modifiedBy: typeof dataTransferObject.ModifiedBy !== 'undefined' ? dataTransferObject.ModifiedBy : this.modifiedBy,
            startPublish: typeof dataTransferObject.StartPublish !== 'undefined' ? moment(dataTransferObject.StartPublish) : this.startPublish,
            stopPublish: typeof dataTransferObject.StopPublish !== 'undefined' ? moment(dataTransferObject.StopPublish) : this.stopPublish,
            versionTrack: typeof dataTransferObject.VersionTrack !== 'undefined' ? dataTransferObject.VersionTrack : this.versionTrack,
            dataFile: typeof dataTransferObject.Items !== 'undefined' ? dataTransferObject.DataFile : this.dataFile
        });
    }
}

/**
 * Promotion item type
 * @type {Object}
 */
var PromotionItemType = {
    UNKNOWN: 'UNKNOWN',
    COLLECTION: 'Collection',
    GENRE: 'Genre',
    MEDIA_ITEM: 'MediaItem',
    URL: 'Url',

    /**
     * Get value
     */
    getValue: function (inputValue) {
        var value = this.UNKNOWN;
        Object.keys(this).forEach((key) => {
            if (this[key] === inputValue) value = this[key];
        });
        return value;
    }
};

export {PromotionItem as default, PromotionItem, PromotionItemType};
