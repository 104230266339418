"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import AppStore from '../stores/AppStore';
import MediaActions from '../actions/MediaActions';
import MediaStore from '../stores/MediaStore';
import {MediaItemClassification} from '../types/MediaItem';
import Filter from '../lib/Filter';
import MediaGallery from './elements/MediaGallery.react';
import FilterBar from './elements/FilterBar.react';

/**
 * CollectionPage component
 */
export default class CollectionPage extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        params: PropTypes.object,
        location: PropTypes.object
    };

    /**
     * React: state
     */
    state = {
        activeFilters: AppStore.activeFilters,
        mediaBucket: null
    };

    /**
     * Bucket keys
     */
    _mediaBucketKey = null;

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        if (this.props.params && this.props.params.collectionTitle && AppStore.collectionListByTitle.get(this.props.params.collectionTitle)) {
            this._mediaBucketKey = MediaActions.getCollectionMedia({
                collectionTitle: this.props.params.collectionTitle
            });
        }
    }

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        AppStore.addUpdateListener(this._setActiveFilters);
        MediaStore.addUpdateListener(this._setMediaBucket);
    }

    /**
     * React: UNSAFE_componentWillReceiveProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((nextProps.params && nextProps.params.collectionTitle && nextProps.params.collectionTitle !== this.props.params.collectionTitle && AppStore.collectionListByTitle.get(this.props.params.collectionTitle))
            || (nextProps.location.query && nextProps.location.query.classification && nextProps.location.query.classification !== this.props.location.query.classification)) {
            MediaStore.destroyMediaBucket(this._mediaBucketKey);
            this._mediaBucketKey = null;
            this.setState({
                mediaBucket: null
            }, () => {
                this._mediaBucketKey = MediaActions.getCollectionMedia({
                    collectionTitle: this.props.params.collectionTitle
                });
            });
        }
    }

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AppStore.removeUpdateListener(this._setActiveFilters);
        MediaStore.removeUpdateListener(this._setMediaBucket);
        this._mediaBucketKey = MediaStore.destroyMediaBucket(this._mediaBucketKey);
    }

    /**
     * React: render
     */
    render() {
        var collection = this.props.params && this.props.params.collectionTitle
            ? AppStore.collectionListByTitle.get(this.props.params.collectionTitle)
            : null;
        var mediaBucket = this.state.mediaBucket;
        var media = mediaBucket ? mediaBucket.media() : null;
        media = media && this.props.location && this.props.location.query && this.props.location.query.classification ? media.filter(mediaItem => {
            let classification = this.props.location.query.classification;
            return MediaItemClassification[classification.toUpperCase()] === MediaItemClassification.SERIES
                ? mediaItem.classification === MediaItemClassification.SERIES
                || mediaItem.classification === MediaItemClassification.SEASON
                || mediaItem.classification === MediaItemClassification.EPISODE
                || mediaItem.classification === MediaItemClassification.TV_BOX_SET
                : mediaItem.classification === MediaItemClassification.MOVIE
                || mediaItem.classification === MediaItemClassification.MOVIE_BOX_SET;
        }) : media;
        var filteredMedia = media ? Filter.media(media, this.state.activeFilters) : null;

        return (
            <main>
                <FilterBar />
                {typeof collection === 'undefined' ? (
                    <div className="no-results">
                        {AppStore.translate('view.collection_page.message.collection_not_found')}
                    </div>
                ) : (media && media.size === 0 ? (
                    <div className="no-results">
                        {AppStore.translate('view.collection_page.message.no_media_in_collection', {collectionTitle: this.props.params.collectionTitle})}
                    </div>
                ) : (filteredMedia && filteredMedia.size === 0 ? (
                    <div className="no-results">{AppStore.translate('message.no_media_for_filter_selection')}</div>
                ) : (
                    <MediaGallery title={collection ? collection.title : null}
                                  media={filteredMedia}
                                  mediaLoading={mediaBucket && mediaBucket.updatePending}/>
                )))}
            </main>
        );
    }

    /**
     * Set active filters
     */
    _setActiveFilters = () => {
        this.setState({
            activeFilters: AppStore.activeFilters
        });
    };

    /**
     * Set mediaBucket
     */
    _setMediaBucket = () => {
        this.setState({
            mediaBucket: MediaStore.getMediaBucket(this._mediaBucketKey)
        });
    };
}
