"use strict";

import * as AppDispatcher from '../dispatchers/AppDispatcher';
import ActionTypes from './ActionTypes';
import ApiService from '../lib/api/ApiService';

/**
 * Flux: WatchlistActions
 */
export default {
    // Get watchlist
    getWatchlist: (params = {}) => {
        let promise = ApiService.getWatchlist(params)
            .then(watchlistItemDTOCollection => {
                return ApiService.getAlerts(params)
                    .catch(() => []) // fixes 404 status FilmFlex API issue
                    .then(alertDTOCollection => alertDTOCollection.map(alertDTO => {
                        let watchlistItemDTO = watchlistItemDTOCollection.find(
                            watchlistItemDTO => watchlistItemDTO.BasketItemId === parseInt(alertDTO.ReferenceId)
                        );
                        alertDTO.ReferenceId = watchlistItemDTO ? watchlistItemDTO.SupplierReference : null; // replace BasketItemId with more usable SupplierReference
                        return alertDTO;
                    }))
                    .then(alertDTOCollection => alertDTOCollection.filter(alertDTO => alertDTO.ReferenceId !== null))
                    .then(alertDTOCollection => {
                        return ApiService.getMedia({mediaItemIds: alertDTOCollection.map(alertDTO => alertDTO.ReferenceId)})
                            .then(ApiService.generateMediaGraphBucketPayload)
                            .then(mediaGraphBucketPayload => ({
                                ...mediaGraphBucketPayload,
                                watchlistItemDTOCollection,
                                alertDTOCollection
                            }));
                    });
            });

        return AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_GET_WATCHLIST,
            success: ActionTypes.REQUEST_GET_WATCHLIST_SUCCESS,
            failure: ActionTypes.REQUEST_GET_WATCHLIST_ERROR
        }, params);
    },

    // Add media item to watchlist
    addMediaItemToWatchlist: (params = {}) => {
        let promise = ApiService.addMediaItemToWatchlist(params)
            .then(watchlistItemDTO => ({watchlistItemDTO}));

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_ADD_MEDIA_ITEM_TO_WATCHLIST,
            success: ActionTypes.REQUEST_ADD_MEDIA_ITEM_TO_WATCHLIST_SUCCESS,
            failure: ActionTypes.REQUEST_ADD_MEDIA_ITEM_TO_WATCHLIST_ERROR
        }, params);
    },

    // Remove media item from watchlist
    removeMediaItemFromWatchlist: (params = {}) => {
        let promise = ApiService.removeMediaItemFromWatchlist(params);

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_REMOVE_MEDIA_ITEM_FROM_WATCHLIST,
            success: ActionTypes.REQUEST_REMOVE_MEDIA_ITEM_FROM_WATCHLIST_SUCCESS,
            failure: ActionTypes.REQUEST_REMOVE_MEDIA_ITEM_FROM_WATCHLIST_ERROR
        }, params);
    },

    // Increment pre-ordered mediaItem alert display count
    incrementPreOrderedMediaItemAlertDisplayCount: (params = {}) => {
        let promise = ApiService.incrementAlertDisplayCount(params)
            .then(alertDTO => alertDTO.DisplayedCount >= 2 ? ApiService.cancelAlert(params) : alertDTO);

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_INCREMENT_PRE_ORDERED_MEDIA_ITEM_ALERT_DISPLAY_COUNT,
            success: ActionTypes.REQUEST_INCREMENT_PRE_ORDERED_MEDIA_ITEM_ALERT_DISPLAY_COUNT_SUCCESS,
            failure: ActionTypes.REQUEST_INCREMENT_PRE_ORDERED_MEDIA_ITEM_ALERT_DISPLAY_COUNT_ERROR
        }, params);
    },

    // Close pre-ordered mediaItem alert
    closePreOrderedMediaItemAlert: (params = {}) => {
        AppDispatcher.dispatch(ActionTypes.CLOSE_PRE_ORDERED_MEDIA_ITEM_ALERT, params);
    },

    // Cancel pre-order mediaItem alert
    cancelPreOrderedMediaItemAlert: (params = {}) => {
        let promise = ApiService.incrementAlertDisplayCount(params)
            .then(() => ApiService.cancelAlert(params));

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_CANCEL_PRE_ORDERED_MEDIA_ITEM_ALERT,
            success: ActionTypes.REQUEST_CANCEL_PRE_ORDERED_MEDIA_ITEM_ALERT_SUCCESS,
            failure: ActionTypes.REQUEST_CANCEL_PRE_ORDERED_MEDIA_ITEM_ALERT_ERROR
        }, params);
    }
};
