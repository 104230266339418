"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import MediaItem from '../../../types/MediaItem';
import UserProfile from '../../../types/UserProfile';
import AppStore from '../../../stores/AppStore';
import MediaItemComponentCTAs from '../../mixins/MediaItemComponentCTAs';
import Button from '../../ui/Button.react';

/**
 * PurchaseSuccessful component
 */
export default class PurchaseSuccessful extends MediaItemComponentCTAs(React.Component) {
    /**
     * React: propTypes
     */
    static propTypes = {
        mediaItem: PropTypes.instanceOf(MediaItem),
        userProfile: PropTypes.instanceOf(UserProfile).isRequired,
        goToLibrary: PropTypes.func.isRequired,
        cancelCheckoutProcess: PropTypes.func.isRequired,
        displayWatchNowButton: PropTypes.bool
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        displayWatchNowButton: true
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: shouldComponentUpdate
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        return (
            <div
                className="checkout-block-content col-xs-12 col-md-10 col-lg-8 col-offset-md-1 col-offset-lg-2">
                <h2 className="overlay-header payment-succesfull"/>
                <div className="payment-block-container small-height payment-done-overlay">
                    <div className="thank-you-container">
                        <h3>{AppStore.translate('view.payment_page.successful.message.title')}</h3>
                            <p>
                                {AppStore.translate('view.payment_page.successful.message.payment_successful')}<br/>
                                {AppStore.translate('view.payment_page.successful.message.receipt_sent')}
                                <a href="mailto:{this.props.userProfile.email}">{this.props.userProfile.email}</a>
                            </p>
                        <h3>{AppStore.translate('view.payment_page.successful.message.what_next')}</h3>
                    </div>
                    <div className="confirm-purchase-container">
                        <div className="cta-buttons-container full-width-buttons">
                            {this.props.mediaItem && this.props.displayWatchNowButton ? (
                                <Button type='button'
                                        className="cta-payment-positive"
                                        shape="regular"
                                        onClick={this._closeOverlayAndPlayMediaItem}>
                                    <i className="ui-icon icon icon-play"/>Watch now
                                </Button>
                            ) : null}
                            <Button type='button'
                                    shape="regular"
                                    onClick={this.props.goToLibrary}>
                                Go to My Library
                            </Button>
                            <Button type='button'
                                    shape="regular"
                                    onClick={this.props.cancelCheckoutProcess}>
                                {AppStore.translate('button.close')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Close overlay and play media item
     * @private
     */
    _closeOverlayAndPlayMediaItem = () => {
        this.props.cancelCheckoutProcess();
        this._playMediaItem();
    };
}
