'use strict';

import Immutable from 'immutable';

/**
 * BundleShoppingCart definition
 *
 * @typedef {Immutable.Record} BundleShoppingCart
 * @property {?number} collectionId
 * @property {?number} amountDue
 * @property {?string} currencyCode
 * @property {Immutable.OrderedMap<string, Object>} cartItems
 * @property {?boolean} updatePending
 */

/**
 * BundleShoppingCart record
 *
 * @type {BundleShoppingCart}
 */
class BundleShoppingCart extends Immutable.Record({
    collectionId: null,
    amountDue: null,
    currencyCode: null,
    cartItems: Immutable.OrderedMap(),
    updatePending: false
}) {
    /**
     * toJS shallow
     * converts Record to object by keeping all second level immutable or other complex properties intact
     *
     * @return {Object}
     */
    toJSShallow() {
        return {
            ...this.toJS(),
            cartItems: this.cartItems
        };
    }

    /**
     * Add cart item
     *
     * @param {string} mediaItemId
     * @param {number} offerId
     * @return {BundleShoppingCart}
     */
    addCartItem(mediaItemId, offerId) {
        let cartItems = this.cartItems.set(mediaItemId, {mediaItemId, offerId});
        return new BundleShoppingCart({
            ...this.toJS(),
            cartItems: cartItems
        });
    }

    /**
     * Remove cart item
     *
     * @param {string} mediaItemId
     * @return {BundleShoppingCart}
     */
    removeCartItem(mediaItemId) {
        let cartItems = this.cartItems.delete(mediaItemId);
        return new BundleShoppingCart({
            ...this.toJS(),
            amountDue: null,
            currencyCode: null,
            cartItems: cartItems
        });
    }

    /**
     * Set cart value
     *
     * @param {?number} amountDue
     * @param {?string} currencyCode
     * @return {BundleShoppingCart}
     */
    setCartValue(amountDue, currencyCode) {
        return this.amountDue !== amountDue
        || this.currencyCode !== currencyCode
            ? new BundleShoppingCart({
                ...this.toJSShallow(),
                amountDue: amountDue,
                currencyCode: currencyCode
            }) : this;
    }

    /**
     * Set update pending
     *
     * @param {boolean} updatePending
     * @return {BundleShoppingCart}
     */
    setUpdatePending(updatePending) {
        return this.updatePending !== updatePending ? new BundleShoppingCart({
            ...this.toJSShallow(),
            updatePending: updatePending
        }) : this;
    }
}

export default BundleShoppingCart;
