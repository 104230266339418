"use strict";

import Immutable from 'immutable';

/**
 * PageRowDTO definition
 *
 * @typedef {Object} PageRowDTO
 * @property {?string} Type - Type of settings value.
 * @property {?string} Value - The setting value.
 * @property {?number} Ordinality - Ordinality.
 * @property {?boolean} Mini - Is value Mini.
 */

/**
 * PageRow definition
 *
 * @typedef {Immutable.Record} PageRow
 * @property {string} type
 * @property {string} value
 * @property {number} ordinality
 * @property {boolean} mini
 */

/**
 * PageRow record
 *
 * @type {PageRow}
 *
 */
class PageRow extends Immutable.Record({
    type: null,
    value: null,
    ordinality: null,
    mini: null
}) {
    /**
     * toJS shallow
     * converts Record to object by keeping all second level immutable or other complex properties intact
     *
     * @return {Object}
     */
    toJSShallow() {
        return {
            ...this.toJS()
        };
    }

    /**
     * Set values from data transfer object
     *
     * @param {PageRowDTO} dataTransferObject
     * @return {PageRow}
     */
    fromDTO(dataTransferObject) {
        return new PageRow({
            ...this.toJSShallow(),
            type: typeof dataTransferObject.Type !== 'undefined' ? PageRowType.getValue(dataTransferObject.Type) : this.type,
            value: typeof dataTransferObject.Value !== 'undefined' ? dataTransferObject.Value : this.value,
            ordinality: typeof dataTransferObject.Ordinality !== 'undefined' ? dataTransferObject.Ordinality : this.ordinality,
            mini: typeof dataTransferObject.Mini !== 'undefined' ? dataTransferObject.Mini : this.mini
        });
    }
}

/**
 * Page row type
 * @type {Object}
 */
var PageRowType = {
    UNKNOWN: 'UNKNOWN',
    PROMOTION: 'PROMO',
    COLLECTION: 'COLITEMS',
    GENRE: 'GENRE',
    RECENTLY_VIEWED: 'RECENTLY_VIEWED',
    SEARCH_ALL: 'ALLMEDIAITEMS',
    HELP: 'HELP',

    /**
     * Get value
     */
    getValue: function (inputValue) {
        var value = this.UNKNOWN;
        Object.keys(this).forEach((key) => {
            if (this[key] === inputValue) value = this[key];
        });
        return value;
    }
};

export {PageRow as default, PageRow, PageRowType};
