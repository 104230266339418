"use strict";

import * as AppDispatcher  from '../dispatchers/AppDispatcher';
import ActionTypes from './ActionTypes';

/**
 * Flux: AnalyticsActions
 */
export default {
    // Track page view
    trackPageView: (params) => {
        AppDispatcher.dispatch(ActionTypes.TRACK_PAGE_VIEW, {analyticsPayload: params});
    },

    // Track event
    trackEvent: (params) => {
        AppDispatcher.dispatch(ActionTypes.TRACK_EVENT, {analyticsPayload: params});
    },

    // Track custom event
    trackCustomEvent: (params) => {
        AppDispatcher.dispatch(ActionTypes.TRACK_CUSTOM_EVENT, {analyticsPayload: params});
    }
};
