"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import Router from '../lib/Router';
import AppStore from '../stores/AppStore';
import EntitlementStore from '../stores/EntitlementStore';
import AuthActions from '../actions/AuthActions';
import AppActions from '../actions/AppActions';
import NotificationActions from '../actions/NotificationActions';
import AuthStore from '../stores/AuthStore';
import Button from './ui/Button.react';
import AnalyticsActions from '../actions/AnalyticsActions';

/**
 * AccountSignInPage component
 */
export default class AccountSignInPage extends React.Component {
    /**
     * React: state
     */
    state = {
        email: '',
        emailIsValid: false,
        password: '',
        emailIsTouched: false,
        passwordIsTouched: false,
        passwordIsValid: false,
        showPassword: false,
        updatePending: false,
        waitingForUserSignIn: true
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    // email input ref
    _emailRef = null;
    /**
     * React: componentDidMount
     */
    componentDidMount() {
        AuthStore.addUpdateListener(this._setFormStatus);
        this._forceOnBlurOnAutofilledInput();
        document.body.className += " black-background";
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AuthStore.removeListener(AuthStore.STORE_UPDATED, this._setFormStatus);
        this.setState({updatePending: false});
        clearTimeout(this._emailRef);
        document.body.className = document.body.className.replace(/black-background/, '');
    }

    /**
     * React: render
     */
    render() {
        return (
            <main className="account-page">
                <h4 className="account-page-title">Sign in</h4>
                <div className="container">
                    <div className="row">
                        <div className="account-page-content col-xs-12 col-sm-8 col-md-5 col-lg-4">
                            <form onSubmit={this._onSubmit}>
                                {this.state.emailIsTouched
                                 && !this.state.emailIsValid ? <span className="input-error-message">
                                {AppStore.translate('field.error.email-not-valid')}</span>:null}

                                {/* {this.state.password.length > 8 && !this.state.passwordIsValid
                                    && this.state.passwordIsTouched ? <span className="input-error-message">
                                {AppStore.translate('field.error.password-not-strong')}</span>:null } */}

                                <span className="input-label">
                                Email address</span>
                                <input type="email"
                                       className={`simple-input ${!this.state.emailIsValid &&
                                            this.state.emailIsTouched ? ' simple-input-error' : ''}`}

                                       placeholder={AppStore.translate('field.placeholder.email-signin')}
                                       value={this.state.email}
                                       disabled={this.state.updatePending}
                                       autoComplete="username"
                                       onBlur={this._checkFieldValidity.bind(null, 'email')}
                                       onChange={(e) => this._onFieldChange('email', e)}
                                       ref={(input) => this.emailInput = input}
                                       />
                                <div className="input-label-container">
                                    <span className="input-label">Password</span>
                                    <a className="input-label-password-state" onClick={this._showHidePassword}>
                                        {this.state.showPassword ? 'Hide' : 'Show'}</a>
                                </div>
                                <input type={this.state.showPassword ? "text" : "password" }
                                       className={`simple-input ${!this.state.passwordIsValid &&
                                            this.state.passwordIsTouched ? ' simple-input-error' : ''}`}
                                       placeholder={AppStore.translate('field.placeholder.password-signin')}
                                       value={this.state.password}
                                       disabled={this.state.updatePending}
                                       autoComplete="current-password"
                                       onBlur={this._checkFieldValidity.bind(null, 'password')}
                                       onChange={(e) => this._onFieldChange('password', e)}
                                       />
                                <div className="account-page-link-container-signup-link">
                                    <span>{AppStore.translate('view.account_page.message.signup_info')} </span>
                                    <Link onClick={this._signUpClickAnalyticsEvent}
                                        to={Router.SIGN_UP}>{AppStore.translate('view.account_page.message.signup_link')}</Link>
                                </div>
                                <Button type="submit"
                                        shape="square"
                                        className="cta-play full-width"
                                        disabled={this.state.updatePending
                                        || !this.state.emailIsValid
                                        || this.state.password.length < 8}
                                        updatePending={this.state.updatePending}>
                                    {AppStore.translate('button.signin')}
                                </Button>
                            </form>
                            <div className="account-page-forgot-password-link-container">
                                <Link className="push-right account-page-link-container" onClick={this._forgotPasswordAnalyticsEvent}
                                    to={Router.REQUEST_PASSWORD_RESET}>{AppStore.translate('view.account_page.message.forgot_password')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }

    /**
     * On form submit
     *
     * @param {Object} event
     * @private
     */
    _onSubmit = (event) => {
        event.preventDefault();
        AnalyticsActions.trackEvent({
            category: 'Sign In',
            action: 'Sign In',
            label: "Submit"
        });
        if (!this.state.password || !this.state.email) {
            NotificationActions.displayNotification({
                notificationType: 'error',
                content: AppStore.translate('notification.account.error.empty_credentials')
            });
            return;
        }

        this.setState({
            updatePending: true
        }, () => {
            NotificationActions.hideNotification();

            if (EntitlementStore.stbPairingData.size > 0 && EntitlementStore.stbPairingData.get("fromProductPurchase")) {
                AuthStore.addUpdateListener(this._waitForCustomerPaymentDetails);
                AppActions.storeRouterTransitionPath(Router.SIGN_IN);

            }
            AuthActions.signInUser({
                username: this.state.email,
                password: this.state.password
            });
        });
    };

    /**
     * Set form status
     */
    _setFormStatus = () => {
        this.setState({updatePending: AuthStore.updatePending});
    };

    /**
     * show hide password
     *
     * @private
     */
    _showHidePassword = () => {
        AnalyticsActions.trackEvent({
            category: 'Sign In',
            action: 'Sign In',
            label: this.state.showPassword ? "Hide Password" : "Show Password"
        });
        this.setState({
            showPassword: !this.state.showPassword
        });
    };

    /**
     * forgot password event catch
     *
     * @private
     */
    _forgotPasswordAnalyticsEvent = () => {
        AnalyticsActions.trackEvent({
            category: 'Sign In',
            action: 'Sign In',
            label: "Forgot Password"
        });
    };

    /**
     * Track sing up click on sign page
     *
     * @private
     */
    _signUpClickAnalyticsEvent = () => {
        AnalyticsActions.trackEvent({
            category: 'Sign In',
            action: 'Sign In',
            label: "Sign up"
        });
    };
    /**
     * On field change
     *
     * @param {string} fieldName
     * @param {Object} event
     * @private
     */
    _onFieldChange = (fieldName, event) => {
        this.setState({
            [fieldName]: event.target.value
        });

    };

    /**
     * On field change
     *
     * @param {string} fieldName
     * @param {Object} event
     * @private
     */
    _checkFieldValidity = (fieldName, event) => {
        event.preventDefault(event);
        if (fieldName === 'email') {
            const regExRule = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            this.setState({
                emailIsValid: regExRule.test(event.target.value),
                [fieldName]: event.target.value,
                emailIsTouched: true
            });

            if (!regExRule.test(event.target.value)) {
                AnalyticsActions.trackEvent({
                    category: 'Sign In',
                    action: 'Sign In',
                    label: 'Invalid Email'
                });
            }
        }
        if (fieldName === 'password') {
            const regExPasswordRule = /^(?=.*?[A-Z+])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
            this.setState({
                passwordIsValid: event.target.value.length >= 7 && regExPasswordRule.test(event.target.value),
                [fieldName]: event.target.value,
                passwordIsTouched: true
            });
        }
        return false;
    };
    /**
     * check if username and password are prefilled by browser
     * as firefox doesn't trigger onBlur for prefilled inputs
     */
    _forceOnBlurOnAutofilledInput = () => {
        this._emailRef = setTimeout(() => {
            if (this.state.email && this.state.password) {
                this.emailInput.focus();
                this.emailInput.blur();
            }
        }, 200);
    };

    /**
     * wait for for customer sign in, and get payment details before redirecting to set payment or home (stb pairing)
     */
    _waitForCustomerPaymentDetails = () => {
        if (AuthStore.isSignedIn() && this.state.waitingForUserSignIn) {
            this.setState({waitingForUserSignIn: false}, () => {
                setTimeout(() => {AuthActions.getCustomerPaymentDetails();}, 0);
            });
            return;
        }

        if (AuthStore.customerPaymentDetails !== null && AuthStore.customerPaymentDetails.defaultCard === null) {
            AuthStore.removeListener(AuthStore.STORE_UPDATED, this._waitForCustomerPaymentDetails);
            setTimeout(() => {
                this.context.router.push(Router.UPDATE_ACCOUNT);
            }, 0);
        }

        if (AuthStore.customerPaymentDetails !== null && AuthStore.customerPaymentDetails.defaultCard !== null) {
            AuthStore.removeListener(AuthStore.STORE_UPDATED, this._waitForCustomerPaymentDetails);
            setTimeout(() => {
                this.context.router.push(Router.HOME);
            }, 0);
        }
    }
}
