/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import AppStore from '../stores/AppStore';
import AuthActions from '../actions/AuthActions';
import NotificationActions from '../actions/NotificationActions';
import AuthStore from '../stores/AuthStore';
import Button from './ui/Button.react';
import Router from '../lib/Router';

/**
 * AccountRequestPasswordResetPage component
 */
export default class AccountRequestPasswordResetPage extends React.Component {
    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object,
    };

    /**
     * React: state
     */
    state = {
        email: '',
        emailIsValid: false,
        emailIsTouched: false,
        updatePending: false,
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        AuthStore.addUpdateListener(this.setFormStatus);

        document.body.className += ' black-background';
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AuthStore.removeListener(AuthStore.STORE_UPDATED, this.setFormStatus);
        this.setState({updatePending: false});

        document.body.className = document.body.className.replace(/black-background/, '');
    }

    /**
     * On form submit
     *
     * @param {Object} event
     * @private
     */
    onSubmit = event => {
        event.preventDefault();
        this.setState({
            updatePending: true,
        }, () => {
            NotificationActions.hideNotification();
            AuthActions.sendResetUserPasswordEmail({
                email: this.state.email,
            });
        });
    };

    /**
     * Set form status
     */
    setFormStatus = () => {
        this.setState({updatePending: AuthStore.updatePending});
    };


    /**
     * Go to Sign in
     *
     * @private
     */
    goToSignIn = () => {
        this.context.router.push(Router.SIGN_IN);
    };

    /**
     * check field validity
     *
     * @param {string} email
     * @private
     */
    checkFieldValidity = email => {
        if (email) {
            // eslint-disable-next-line no-useless-escape
            const regExRule = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            this.setState({
                emailIsValid: regExRule.test(email),
            });
        }
        return false;
    };

    /**
     * set email is touched state onBlur
     */
    handleEmailIsTouchedOnBlur = () => {
        this.setState({emailIsTouched: true});
    };

    /**
     * On field change
     * @param {Object} event
     * @private
     */
    onFieldChange = e => {
        this.setState({[e.target.name]: e.target.value});
        this.checkFieldValidity(e.target.value);
    };

    /**
     * React: render
     */
    render() {
        return (
            <main className="account-page">
                <h4 className="account-page-title">{AppStore.translate('view.account_page.title.request_password_reset')}</h4>
                <div className="container">
                    <div className="row">
                        <div className="account-page-content col-xs-12 col-sm-8 col-md-5 col-lg-4">
                            <div className="form-title" />

                            <div className="form-annotation">
                                <p>{AppStore.translate('view.account_page.message.provide_email')}</p>
                            </div>

                            <form onSubmit={this.onSubmit}>
                                <span className="email-input-wrapper">
                                    <input
                                        type="email"
                                        name="email"
                                        className={`simple-input ${this.state.emailIsTouched
                                               && !this.state.emailIsValid ? ' simple-input-error' : ''}`}
                                        placeholder={AppStore.translate('field.placeholder.email_forgot_password')}
                                        value={this.state.email}
                                        disabled={this.state.updatePending}
                                        autoComplete="username"
                                        onBlur={this.handleEmailIsTouchedOnBlur}
                                        onChange={this.onFieldChange}
                                    />
                                </span>

                                {this.state.emailIsTouched && !this.state.emailIsValid
                                    ? (
                                        <span className="input-error-message">
                                            {AppStore.translate('field.error.email-not-valid')}
                                        </span>
                                    ) : null}

                                <Button
                                    type="submit"
                                    shape="square"
                                    className="cta-play full-width password-reset-page-submit-btn"
                                    disabled={this.state.updatePending
                                            || !this.state.emailIsValid
                                            || !this.state.email}
                                    updatePending={this.state.updatePending}
                                >
                                    {AppStore.translate('button.submit')}
                                </Button>

                                <Button
                                    type="button"
                                    shape="square"
                                    onClick={this.goToSignIn}
                                    className="full-width"
                                    disabled={this.state.updatePending}
                                >
                                    {AppStore.translate('button.back')}
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
