"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import MediaItem from '../../../types/MediaItem';
import MediaItemImage from './../MediaItemImage.react';

/**
 * PosterImage component
 */
export default class PosterImage extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        mediaItem: PropTypes.instanceOf(MediaItem).isRequired
    };

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        var mediaItem = this.props.mediaItem;

        return (
            <div className="media-item-poster col-xs-3 col-md-3 col-lg-4">
                <MediaItemImage mediaItem={mediaItem} imageType="poster"/>
            </div>
        );
    }
}
