"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import {Link} from 'react-router';
import Config from '../../lib/Config';
import Router from '../../lib/Router';
import MediaItem, {MediaItemClassification} from '../../types/MediaItem';
import AppStore from '../../stores/AppStore';
import MediaItemComponentCTAs from '../mixins/MediaItemComponentCTAs';
import {CastType} from '../../types/Cast';
import Button from '../ui/Button.react';
import MediaItemImage from './MediaItemImage.react';
import MediaPlayer from './MediaPlayer.react';
import SeriesSeasons from './media-item-details/SeriesSeasons.react';
import CTAButtons from './media-item-details/CTAButtons.react';
import MiscInformation from './media-item-details/MiscInformation.react';
import MiscInformationTwoColumn from './media-item-details/MiscInformationTwoColumn.react';
import PosterImage from './media-item-details/PosterImage.react';
import BackToSeason from './media-item-details/BackToSeason.react';

/**
 * MediaItemDetails component
 *
 * @mixes MediaItemComponentCTAs
 */
export default class MediaItemDetails extends MediaItemComponentCTAs(React.Component) {
    /**
     * React: propTypes
     */
    static propTypes = {
        mediaItem: PropTypes.instanceOf(MediaItem).isRequired,
        seasonMediaItem: PropTypes.instanceOf(MediaItem),
        setMediaItemSeason: PropTypes.func
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        mediaItem: null
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: state
     */
    state = {
        playTrailer: false,
        isDescriptionVisible: false
    };

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        var mediaItem = this.props.mediaItem;
        var seasonMediaItem = this.props.seasonMediaItem;
        var contentMediaItem = mediaItem.classification === MediaItemClassification.SERIES
            ? seasonMediaItem : mediaItem; // used for episodic content when we should display selected season info instead of main mediaItem

        var className = "media-item-details no-social-links"
            + (mediaItem.classification === MediaItemClassification.MOVIE ? ' movie' : '')
            + (mediaItem.classification === MediaItemClassification.SERIES ? ' series' : '')
            + (mediaItem.classification === MediaItemClassification.SEASON ? ' season' : '')
            + (mediaItem.classification === MediaItemClassification.EPISODE ? ' episode' : '')
            + (mediaItem.classification === MediaItemClassification.MOVIE_BOX_SET ? ' movie-box-set' : '')
            + (mediaItem.classification === MediaItemClassification.TV_BOX_SET ? ' tv-box-set' : '');
        var basicInfoGridColumns = mediaItem.classification === MediaItemClassification.SERIES
            || mediaItem.classification === MediaItemClassification.TV_BOX_SET
            ? " col-xs-9 col-md-9 col-lg-8" : " col-xs-12 col-md-12 col-lg-12";

        return (
            <div className={className}
                 style={{backgroundImage: `url('${mediaItem.getImageLocation('background')}')`}}>
                <div className="transparent-backdrop" />
                <div className="container">
                    <div className="row">

                        <div className="basic-information col-xs-12 col-md-12 col-lg-5">
                            {mediaItem.classification === MediaItemClassification.SERIES ? (
                                    <SeriesSeasons mediaItem={mediaItem}
                                                   seasonMediaItem={seasonMediaItem}
                                                   setMediaItemSeason={this.props.setMediaItemSeason}/>
                                ) : null}

                            {mediaItem.classification === MediaItemClassification.EPISODE ? (
                                    <BackToSeason mediaItem={mediaItem}/>
                                ) : null}

                            {mediaItem.classification === MediaItemClassification.SERIES ? (
                                    <PosterImage mediaItem={seasonMediaItem}/>
                                ) : null}

                            {mediaItem.classification === MediaItemClassification.TV_BOX_SET ? (
                                    <PosterImage mediaItem={mediaItem}/>
                                ) : null}

                            <div className={"title" + basicInfoGridColumns}>
                                <h2>{mediaItem.title}</h2>
                                {mediaItem.classification === MediaItemClassification.EPISODE ? (
                                        <h4>{AppStore.translate('label.classification.episode') + ' ' + mediaItem.numberInSequence}</h4>
                                    ) : null}
                            </div>

                            {mediaItem.classification === MediaItemClassification.MOVIE
                                || mediaItem.classification === MediaItemClassification.MOVIE_BOX_SET ?
                                (
                                    <MiscInformationTwoColumn mediaItem={mediaItem}
                                                           seasonMediaItem={seasonMediaItem}/>
                                ) : (
                                    <MiscInformation mediaItem={mediaItem}
                                                     seasonMediaItem={seasonMediaItem}/>
                                )}
                        </div>

                        <div className="media-item-backdrop col-xs-12 col-lg-7">
                            <MediaItemImage mediaItem={contentMediaItem} imageType="trailer"/>
                            {contentMediaItem.trailerUrl ? (
                                    <MediaPlayer mediaItem={contentMediaItem}
                                                 playerType={Config.MEDIA_PLAYER_TRAILER_TYPE}
                                                 availablePlayerAction={['trailer']}/>
                                ) : null}
                        </div>

                        <CTAButtons mediaItem={contentMediaItem}
                                    descriptiveMediaTitle={ mediaItem.title + ' -- ' +  contentMediaItem.title } />

                        <div
                            className={"description col-xs-12 col-lg-5" + (this.state.isDescriptionVisible ? " active" : "" )}>
                            <p className="synopsis">{contentMediaItem.longSynopsis}</p>
                            {mediaItem.contentAdvice ? (
                                    <p className="hanging-indent advice-container">
                                        <span>Advisory:</span>{mediaItem.contentAdvice}
                                    </p>
                                ) : null}
                            {CastType.getAll().map((castType, key) => {
                                if (castType === CastType.UNKNOWN) return;
                                let castTypeMembers = contentMediaItem.cast.filter((castMember) => {
                                    return castMember.type === castType;
                                }).map((castMember, index) => {
                                    return (
                                        <Link className="cast-member"
                                              to={Router.NAMES(castMember.fullName)}
                                              key={index}>
                                            {castMember.fullName}
                                        </Link>
                                    );
                                });
                                return castTypeMembers.length > 0 ? (
                                        <p className="hanging-indent" key={key}>
                                            <span>{AppStore.translate('label.cast_list.' + castType.toLowerCase())}</span>
                                            {castTypeMembers}
                                        </p>
                                    ) : null;
                            })}
                            <p className="hanging-indent advice-container">
                                {AppStore.translate('label.hd_advice')} <Link to={Router.HELP} >{AppStore.translate('label.hd_advice_devices')}</Link>
                            </p>
                        </div>

                        <div className="description-visibility-control col-xs-12">
                            <Button shape="square"
                                    onClick={this._toggleDescriptionVisibility}>
                                {this.state.isDescriptionVisible ? AppStore.translate('button.show_less') : AppStore.translate('button.show_more')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Toggle description visibility
     *
     * @private
     */
    _toggleDescriptionVisibility = () => {
        this.setState({
            isDescriptionVisible: !this.state.isDescriptionVisible
        });
    };
}
