"use strict";

import React from 'react';
import Router from '../lib/Router';
import Button from './ui/Button.react';
// import Checkbox from './ui/Checkbox.react';
// import TextInput from './ui/TextInput.react';
// import RadioButton from './ui/RadioButton.react';
// import FormField from './ui/FormField.react';
// import Choice from './ui/Choice.react';

/**
 * UIDemoPage component
 */
export default class UIDemoPage extends React.Component {
    /**
     * React: state
     */
    state = {
        buttonsUpdatePending: false,
        buttonsActive: false,
        buttonsDisabled: false,
        buttonsCTA: ''
    };

    /**
     * React: render
     */
    render() {
        return (
            <main className="ui-demo-page">
                <div className="container">
                    <div className="row">
                        <div className="demo-box col-sm-12">
                            <h3>Button controls</h3>
                            <Button shape="square"
                                    onClick={this._toggleButtonsState.bind(null, 'updatePending')}
                                    active={this.state.buttonsUpdatePending}>pending state</Button>
                            <Button shape="square"
                                    onClick={this._toggleButtonsState.bind(null, 'active')}
                                    active={this.state.buttonsActive}>active state</Button>
                            <Button shape="square"
                                    onClick={this._toggleButtonsState.bind(null, 'disabled')}
                                    active={this.state.buttonsDisabled}>disabled state</Button>
                            <select value={this.state.buttonsCTA} onChange={this._setButtonsCTAState}>
                                <option value="">CTA mode..</option>
                                <option value="cta-trailer">Trailer</option>
                                <option value="cta-details">Details</option>
                                <option value="cta-watchlist">Watchlist</option>
                                <option value="cta-share">Share</option>
                                <option value="cta-purchase-fvod">Purchase FVOD</option>
                                <option value="cta-purchase-svod">Purchase SVOD</option>
                                <option value="cta-purchase-est">Purchase EST</option>
                                <option value="cta-purchase-dtr">Purchase DTR</option>
                                <option value="cta-play">Play</option>
                                <option value="cta-download">Download</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        {this._renderButtonsSignpost()}
                        {this._renderButtonsSquare()}
                        {this._renderButtonsSquare('top-icon')}
                        {this._renderButtonsIconRounded()}
                        {this._renderButtonsRegular()}
                        {this._renderButtonsDotArrow()}
                        {this._renderButtonsIconSwitch()}
                        {/*this._renderLightForm()*/}
                        {/*this._renderDarkForm()*/}
                    </div>
                </div>
            </main>
        );
    }

    /**
     * Toggle buttons state
     *
     * @param {string} buttonsState
     * @private
     */
    _toggleButtonsState = (buttonsState) => {
        var newState = {};
        var stateKey = 'buttons' + buttonsState.charAt(0).toUpperCase() + buttonsState.slice(1);
        newState[stateKey] = !this.state[stateKey];
        this.setState(newState);
    };

    /**
     * Set buttons CTA state
     *
     * @param {Object} event
     * @private
     */
    _setButtonsCTAState = (event) => {
        this.setState({
            buttonsCTA: event.target.value
        });
    };

    /**
     * Render: Signpost button
     */
    _renderButtonsSignpost = () => {
        return (
            <div className="demo-box col-sm-6 col-md-6 col-lg-6 col-xl-4">
                <div className="button-row">
                    <Button shape="signpost-left"
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}>
                        Button<i className="ui-icon icon-star"/>
                    </Button>
                    <Button shape="signpost-right"
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}>
                        <i className="ui-icon icon-star"/>Button
                    </Button>
                </div>
                <div className="button-row">
                    <Button shape="signpost-left"
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}>
                        <i className="ui-icon icon-star"/>
                    </Button>
                    <Button shape="signpost-right"
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}>
                        <i className="ui-icon icon-star"/>
                    </Button>
                </div>
                <div className="button-row">
                    <Button shape="signpost-left"
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}/>
                    <Button shape="signpost-right"
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}/>
                </div>
                <div className="button-row">
                    <Button shape="signpost-left"
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}>
                        Button
                    </Button>
                    <Button shape="signpost-right"
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}>
                        Button
                    </Button>
                </div>
                <div className="button-row">
                    <Button shape="signpost-left"
                            type="link"
                            linkTo={Router.UI_DEMO}
                            updatePending={this.state.buttonsUpdatePending}>
                        Link<i className="ui-icon icon-star"/>
                    </Button>
                    <Button shape="signpost-right"
                            type="link"
                            linkTo={Router.UI_DEMO}
                            updatePending={this.state.buttonsUpdatePending}>
                        <i className="ui-icon icon-star"/>Link
                    </Button>
                </div>
                <div className="button-row">
                    <Button shape="signpost-left"
                            type="link"
                            linkTo={Router.UI_DEMO}
                            updatePending={this.state.buttonsUpdatePending}>
                        <i className="ui-icon icon-star"/>
                    </Button>
                    <Button shape="signpost-right"
                            type="link"
                            linkTo={Router.UI_DEMO}
                            updatePending={this.state.buttonsUpdatePending}>
                        <i className="ui-icon icon-star"/>
                    </Button>
                </div>
                <div className="button-row">
                    <Button shape="signpost-left"
                            type="link"
                            linkTo={Router.UI_DEMO}
                            updatePending={this.state.buttonsUpdatePending}/>
                    <Button shape="signpost-right"
                            type="link"
                            linkTo={Router.UI_DEMO}
                            updatePending={this.state.buttonsUpdatePending}/>
                </div>
                <div className="button-row">
                    <Button shape="signpost-left"
                            type="link"
                            linkTo={Router.UI_DEMO}
                            updatePending={this.state.buttonsUpdatePending}>
                        Link
                    </Button>
                    <Button shape="signpost-right"
                            type="link"
                            linkTo={Router.UI_DEMO}
                            updatePending={this.state.buttonsUpdatePending}>
                        Link
                    </Button>
                </div>
            </div>
        );
    };

    /**
     * Render: Dot & Arrow buttons
     */
    _renderButtonsDotArrow = () => {
        return (
            <div className="demo-box col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <div className="button-row">
                    <Button shape="arrow-left" onClick={this._onClick}>Arrow left</Button>
                    <Button shape="arrow-right" onClick={this._onClick}>Arrow right</Button>
                    <Button shape="arrow-left" onClick={this._onClick} disabled={true}>Arrow left</Button>
                    <Button shape="arrow-right" onClick={this._onClick} disabled={true}>Arrow right</Button>
                </div>
                <div className="button-row">
                    <Button shape="dot" onClick={this._onClick}>Dot</Button>
                    <Button shape="dot" onClick={this._onClick} active={true}>Dot</Button>
                    <Button shape="dot" onClick={this._onClick} disabled={true}>Dot</Button>
                </div>
                <div className="button-row">
                    <Button shape="arrow-left-large" onClick={this._onClick}>Arrow left large</Button>
                    <Button shape="arrow-right-large" onClick={this._onClick}>Arrow right large</Button>
                    <Button shape="arrow-left-large" onClick={this._onClick} disabled={true}>
                        Arrow left large
                    </Button>
                    <Button shape="arrow-right-large" onClick={this._onClick} disabled={true}>
                        Arrow right large
                    </Button>
                </div>
            </div>
        );
    };

    /**
     * Render: Icon rounded
     */
    _renderButtonsIconRounded = () => {
        return (
            <div className="demo-box col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <div className="button-row">
                    <Button shape="icon-rounded"
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}/>
                    <Button shape="icon-rounded"
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}>
                        <i className="ui-icon icon-facebook"/>
                    </Button>
                    <Button shape="icon-rounded"
                            type="link"
                            linkTo={Router.UI_DEMO}
                            target="_blank"
                            updatePending={this.state.buttonsUpdatePending}>
                        <i className="ui-icon icon-facebook"/>
                    </Button>
                    <Button shape="icon-rounded-large"
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}/>
                    <Button shape="icon-rounded-large"
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}>
                        <i className="ui-icon icon-facebook"/>
                    </Button>
                    <Button shape="icon-rounded-large"
                            type="link"
                            linkTo={Router.UI_DEMO}
                            updatePending={this.state.buttonsUpdatePending}>
                        <i className="ui-icon icon-facebook"/>
                    </Button>
                </div>
            </div>
        );
    };

    /**
     * Render: Icon switch
     */
    _renderButtonsRegular = () => {
        return (
            <div className="demo-box col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <div className="button-row">
                    <Button shape="regular" onClick={this._onClick}>Button</Button>
                    <Button shape="icon-regular" onClick={this._onClick}>Button icon</Button>
                </div>
            </div>
        );
    };

    /**
     * Render: Icon switch
     */
    _renderButtonsIconSwitch = () => {
        return (
            <div className="demo-box col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <div className="button-row">
                    <Button shape="icon-switch"
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}
                            active={this.state.buttonsActive}
                            disabled={this.state.buttonsDisabled}/>
                    <Button shape="icon-switch"
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}
                            active={this.state.buttonsActive}
                            disabled={this.state.buttonsDisabled}>
                        <i className="ui-icon icon-rent"/>
                    </Button>
                    <Button shape="icon-switch"
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}
                            active={this.state.buttonsActive}
                            disabled={this.state.buttonsDisabled}>
                        Button
                    </Button>
                    <Button shape="icon-switch"
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}
                            active={this.state.buttonsActive}
                            disabled={this.state.buttonsDisabled}>
                        <i className="ui-icon icon-rent"/>Button
                    </Button>
                    <Button shape="icon-switch"
                            className="genre-filter"
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}
                            active={this.state.buttonsActive}
                            disabled={this.state.buttonsDisabled}>
                        <i className="ui-icon"
                           style={{backgroundImage: `url("https://cdn.auth0.com/blog/react-js/react.png")`}}/>Button & Button
                    </Button>
                </div>
            </div>
        );
    };

    /**
     * Render: Square
     */
    _renderButtonsSquare = (className) => {
        className = (className || '') + ' ' + this.state.buttonsCTA;
        return (
            <div className="demo-box col-sm-6 col-md-6 col-lg-4 col-xl-4">
                <div className="button-row">
                    <Button shape="regular"
                            className={className}
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}
                            active={this.state.buttonsActive}
                            disabled={this.state.buttonsDisabled}>
                        <i className="ui-icon icon-rent"/>
                    </Button>
                    <Button shape="regular"
                            className={className}
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}
                            active={this.state.buttonsActive}
                            disabled={this.state.buttonsDisabled}>
                        <i className="ui-icon icon-rent"/>button
                    </Button>
                    <Button shape="regular"
                            className={className}
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}
                            active={this.state.buttonsActive}
                            disabled={this.state.buttonsDisabled}>
                        button
                    </Button>
                </div>
                <div className="button-row">
                    <Button shape="square-glass"
                            className={className}
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}
                            active={this.state.buttonsActive}
                            disabled={this.state.buttonsDisabled}>
                        <i className="ui-icon icon-rent"/>
                    </Button>
                    <Button shape="square-glass"
                            className={className}
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}
                            active={this.state.buttonsActive}
                            disabled={this.state.buttonsDisabled}>
                        <i className="ui-icon icon-rent"/>button
                    </Button>
                    <Button shape="square-glass"
                            className={className}
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}
                            active={this.state.buttonsActive}
                            disabled={this.state.buttonsDisabled}>
                        button
                    </Button>
                </div>
                <div className="button-row">
                    <Button shape="square"
                            className={className}
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}
                            active={this.state.buttonsActive}
                            disabled={this.state.buttonsDisabled}>
                        <i className="ui-icon icon-rent"/>
                    </Button>
                    <Button shape="square"
                            className={className}
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}
                            active={this.state.buttonsActive}
                            disabled={this.state.buttonsDisabled}>
                        <i className="ui-icon icon-rent"/>button
                    </Button>
                    <Button shape="square"
                            className={className}
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}
                            active={this.state.buttonsActive}
                            disabled={this.state.buttonsDisabled}>
                        button
                    </Button>
                </div>
                <div className="button-row">
                    <Button shape="square-chromeless"
                            className={className}
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}
                            active={this.state.buttonsActive}
                            disabled={this.state.buttonsDisabled}>
                        <i className="ui-icon icon-rent"/>button
                    </Button>
                    <Button shape="square-chromeless"
                            className={className}
                            onClick={this._onClick}
                            updatePending={this.state.buttonsUpdatePending}
                            active={this.state.buttonsActive}
                            disabled={this.state.buttonsDisabled}>
                        button
                    </Button>
                </div>
                <div className="button-row">
                    <Button shape="square-chromeless"
                            className={className}
                            type="link"
                            linkTo={Router.UI_DEMO}
                            updatePending={this.state.buttonsUpdatePending}
                            active={this.state.buttonsActive}
                            disabled={this.state.buttonsDisabled}>
                        <i className="ui-icon icon-rent"/>link
                    </Button>
                    <Button shape="square-chromeless"
                            className={className}
                            type="link"
                            linkTo={Router.UI_DEMO}
                            updatePending={this.state.buttonsUpdatePending}
                            active={this.state.buttonsActive}
                            disabled={this.state.buttonsDisabled}>
                        link
                    </Button>
                </div>
            </div>
        );
    };

    /**
     * Render: Light form
     */
    // _renderLightForm = () => {
    //     return (
    //         <div className="demo-box col-sm-6">
    //             <div className="ui-form light">
    //                 <FormField
    //                     label="Text Input Label"
    //                     value="Hello world"
    //                     error="This is error message"/><br/>
    //                 <FormField
    //                     type="password"
    //                     label="Password Input Label"
    //                     error="This is error message"/><br/>
    //                 <FormField
    //                     type="email"
    //                     label="Email Input Label"
    //                     error="This is error message"/><br/>
    //                 <FormField
    //                     type="choice-select"
    //                     label="Select Input Label"
    //                     choiceArray={[
    //                         {
    //                             label: 'option 1',
    //                             value: 'opt1'
    //                         }, {
    //                             label: 'option 2',
    //                             value: 'opt2'
    //                         }, {
    //                             value: 'opt3'
    //                         }
    //                     ]}/><br/>
    //                 <FormField
    //                     type="choice-radio"
    //                     label="Select Input Label"
    //                     choiceArray={[
    //                         {
    //                             label: 'option 1',
    //                             value: 'opt1'
    //                         }, {
    //                             label: 'option 2',
    //                             value: 'opt2'
    //                         }, {
    //                             value: 'opt3'
    //                         }
    //                     ]}/><br/>
    //                 <FormField
    //                     type="choice-checkbox"
    //                     label="Select Input Label"
    //                     choiceArray={[
    //                         {
    //                             label: 'option 1',
    //                             value: 'opt1'
    //                         }, {
    //                             label: 'option 2',
    //                             value: 'opt2'
    //                         }, {
    //                             value: 'opt3'
    //                         }
    //                     ]}/><br/>
    //                 <TextInput
    //                     label="Text Input Label"
    //                     value="Hello world"
    //                     error="This is error message"/>
    //                 <TextInput
    //                     type="email"
    //                     label="Email Input Label"
    //                     error="This is email error message"/>
    //                 <TextInput
    //                     type="password"
    //                     label="Password Input Label"
    //                     value="Hello world"
    //                     error="This is password error message"/>
    //                 <TextInput
    //                     type="text"
    //                     label="Disabled Input Label"
    //                     value="Hello world"
    //                     disabled={true}
    //                     error="This is password error message"/>
    //                 <TextInput
    //                     label="Double Input"
    //                     value="200px"
    //                     error="This is error message"
    //                     inline={true}
    //                     labelStyle={{width: '100px'}}
    //                     inputStyle={{width: '200px'}}/>
    //                 <TextInput
    //                     value="120px"
    //                     error="This is error message"
    //                     inline={true}
    //                     inputStyle={{width: '120px'}}/>
    //                 <br/>
    //
    //                 <Checkbox
    //                     label="Checkbox label" />
    //                 <Checkbox
    //                     label="Checkbox label, checked"
    //                     value={true}/>
    //                 <br/>
    //
    //                 <Checkbox
    //                     label="Disabled checkbox label"
    //                     disabled={true} />
    //                 <Checkbox
    //                     label="Disabled checkbox label checked"
    //                     value={true}
    //                     disabled={true} />
    //                 <br/>
    //
    //                 <RadioButton
    //                     inline={false}
    //                     name='gender'
    //                     value="male"
    //                     label="Male"
    //                 />
    //                 <RadioButton
    //                     inline={false}
    //                     name='gender'
    //                     value="female"
    //                     label="Female"
    //                 />
    //                 <RadioButton
    //                     inline={false}
    //                     name='gender'
    //                     value="other"
    //                     label="Other"
    //                     checked={true}
    //                 />
    //                 <br/>
    //
    //                 <RadioButton
    //                     inline={false}
    //                     name='disabled'
    //                     value="false"
    //                     label="Unknown"
    //                     disabled={true}
    //                 />
    //                 <RadioButton
    //                     inline={false}
    //                     name='disabled'
    //                     value="true"
    //                     label="Unknown"
    //                     disabled={true}
    //                     checked={true}
    //                 />
    //                 <br/>
    //
    //                 <div className="ui-drop-down-wrapper">
    //                     <label>Select text</label>
    //
    //                     <div className="ui-drop-down">
    //                         <select className="drop-down-select">
    //                             <option>Option 1</option>
    //                             <option>Option 1</option>
    //                             <option>Option 1</option>
    //                         </select>
    //                     </div>
    //                 </div>
    //
    //                 <br/>
    //
    //                 <Choice
    //                     choices={["option 1", "option 2", "option 3"]}
    //                     updatePending={false}
    //                     value={"some value"}
    //                 />
    //
    //                 <Choice
    //                     choices={["multiple option 1", "multiple option 2", "multiple option 3"]}
    //                     updatePending={false}
    //                     value={"some value"}
    //                     multiple={true}
    //                     expanded={true}
    //                 />
    //
    //                 <Choice
    //                     choices={["radio option 1", "radio option 2", "radio option 3"]}
    //                     updatePending={false}
    //                     expanded={true}
    //                 />
    //             </div>
    //         </div>
    //     );
    // };

    /**
     * Render: Dark form
     */
    // _renderDarkForm = () => {
    //     return (
    //         <div className="demo-box col-sm-6">
    //             <div className="ui-form dark">
    //                 <TextInput
    //                     label="Text Input Label"
    //                     value="Hello world"
    //                     error="This is error message"/>
    //                 <TextInput
    //                     type="email"
    //                     label="Email Input Label"
    //                     error="This is email error message"/>
    //                 <TextInput
    //                     type="password"
    //                     label="Password Input Label"
    //                     value="Hello world"
    //                     error="This is password error message"/>
    //                 <TextInput
    //                     type="text"
    //                     label="Disabled Input Label"
    //                     value="Hello world"
    //                     disabled={true}
    //                     error="This is password error message"/>
    //                 <TextInput
    //                     label="Double Input"
    //                     value="200px"
    //                     error="This is error message"
    //                     inline={true}
    //                     labelStyle={{width: '100px'}}
    //                     inputStyle={{width: '200px'}}/>
    //                 <TextInput
    //                     value="120px"
    //                     error="This is error message"
    //                     inline={true}
    //                     inputStyle={{width: '120px'}}/>
    //                 <br/>
    //
    //                 <Checkbox
    //                     label="Checkbox label" />
    //                 <Checkbox
    //                     label="Checkbox label, checked"
    //                     value={true}/>
    //                 <br/>
    //
    //                 <Checkbox
    //                     label="Disabled checkbox label"
    //                     disabled={true} />
    //                 <Checkbox
    //                     label="Disabled checkbox label checked"
    //                     value={true}
    //                     disabled={true} />
    //                 <br/>
    //
    //                 <RadioButton
    //                     inline={false}
    //                     name='gender'
    //                     value="male"
    //                     label="Male"
    //                 />
    //                 <RadioButton
    //                     inline={false}
    //                     name='gender'
    //                     value="female"
    //                     label="Female"
    //                 />
    //                 <RadioButton
    //                     inline={false}
    //                     name='gender'
    //                     value="other"
    //                     label="Other"
    //                     checked={true}
    //                 />
    //                 <br/>
    //
    //                 <RadioButton
    //                     inline={false}
    //                     name='disabled'
    //                     value="false"
    //                     label="Unknown"
    //                     disabled={true}
    //                 />
    //                 <RadioButton
    //                     inline={false}
    //                     name='disabled'
    //                     value="true"
    //                     label="Unknown"
    //                     disabled={true}
    //                     checked={true}
    //                 />
    //                 <br/>
    //                 <div className="ui-drop-down-wrapper">
    //                     <label>Select text</label>
    //
    //                     <div className="ui-drop-down">
    //                         <select className="drop-down-select">
    //                             <option>Option 1</option>
    //                             <option>Option 1</option>
    //                             <option>Option 1</option>
    //                         </select>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // };

    /**
     * Click test
     */
    _onClick = () => {

    };
}
