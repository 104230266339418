"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import Router from '../lib/Router';
import {MediaItemClassification} from '../types/MediaItem';
import MediaActions from '../actions/MediaActions';
import MediaStore from '../stores/MediaStore';
import AppStore from '../stores/AppStore';
import AuthStore from '../stores/AuthStore';
import Spinner from './ui/Spinner.react';
import MediaItemMeta from './elements/meta/MediaItemMeta.react';
import MediaItemDetails from './elements/MediaItemDetails.react';
import MediaCarousel from './elements/MediaCarousel.react';

/**
 * MediaItemPage component
 */
export default class MediaItemPage extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        params: PropTypes.object
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: state
     */
    state = {
        mediaBucket: null,
        recommendationsMediaBucket: null,
        seasonMediaItemId: this.props.params.childMediaItemId || null
    };

    /**
     * MediaBucket keys
     */
    _mediaBucketKey = null;
    _recommendationsMediaBucketKey = null;

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        this._mediaBucketKey = MediaActions.getMediaItem({mediaItemId: this.props.params.mediaItemId});
        if (AuthStore.isSignedIn()) {
            this._recommendationsMediaBucketKey = MediaActions.getMediaItemRecommendations({mediaItemId: this.props.params.mediaItemId});
        }
    }

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        // add store listeners
        MediaStore.addUpdateListener(this._setMediaItemMediaBucket);
        MediaStore.addUpdateListener(this._setRecommendationsMediaBucket);

        document.body.className += "dark-background";
    }

    /**
     * React: UNSAFE_componentWillReceiveProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.params.mediaItemId !== this.props.params.mediaItemId) {
            this._mediaBucketKey = MediaStore.destroyMediaBucket(this._mediaBucketKey);
            this._recommendationsMediaBucketKey = MediaStore.destroyMediaBucket(this._recommendationsMediaBucketKey);
            this.setState({
                mediaBucket: null,
                recommendationsMediaBucket: null,
                seasonMediaItemId: nextProps.params.childMediaItemId || null
            });

            this._mediaBucketKey = MediaActions.getMediaItem({mediaItemId: nextProps.params.mediaItemId});
            if (AuthStore.isSignedIn()) {
                this._recommendationsMediaBucketKey = MediaActions.getMediaItemRecommendations({mediaItemId: nextProps.params.mediaItemId});
            }
        }
    }

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        MediaStore.removeUpdateListener(this._setMediaItemMediaBucket);
        MediaStore.removeUpdateListener(this._setRecommendationsMediaBucket);
        MediaStore.destroyMediaBucket(this._mediaBucketKey);
        MediaStore.destroyMediaBucket(this._recommendationsMediaBucketKey);

        document.body.className = document.body.className.replace(/dark-background/, '');
    }

    /**
     * React: render
     */
    render() {
        var mediaBucket = this.state.mediaBucket;
        var media = mediaBucket ? mediaBucket.media() : null;
        var mediaItem = media ?  media.first() : null;
        var recommendationsMediaBucket = this.state.recommendationsMediaBucket;

        // in case of SERIES AND BOX SETS
        var childMediaItem = null;
        var childMediaItemMedia = null;
        var title = null;
        var isPurchaseEnabled = true;
        if (mediaItem && mediaItem.childrenIds.length > 0) {

            if(mediaItem.classification === MediaItemClassification.SERIES) {
                const mediaChildrenItems = mediaItem.childrenIds.map(id => mediaBucket.mediaItem(id));
                const mediaChildrenItemsWithOffer = mediaChildrenItems.filter(item => item && item.offers && item.offers.length);
                const lastChildWithOffer = mediaChildrenItemsWithOffer[mediaChildrenItemsWithOffer.length - 1];
                childMediaItem = this.state.seasonMediaItemId
                    ? mediaBucket.mediaItem(this.state.seasonMediaItemId)
                    : lastChildWithOffer; // << sets last season with offer for media details page
                title = AppStore.translate('view.season_carousel.title', {
                    seasonMediaItemTitle: childMediaItem.title,
                    episodeCount: childMediaItem.childrenIds.length
                });
            } else if (mediaItem.classification === MediaItemClassification.TV_BOX_SET
                || mediaItem.classification === MediaItemClassification.MOVIE_BOX_SET) {
                childMediaItem = mediaItem;
                isPurchaseEnabled = false;
                title = AppStore.translate(
                    mediaItem.classification === MediaItemClassification.MOVIE_BOX_SET ? 'view.movie_box_set_carousel.title' : 'view.tv_box_set_carousel.title',
                    {itemCount: childMediaItem.childrenIds.length}
                );
            }

            childMediaItemMedia = childMediaItem ? mediaBucket.mediaItemChildren(childMediaItem) : null;
        }

        return (
            <main>
                {mediaItem && (
                    <MediaItemMeta mediaItem={mediaItem}/>
                )}
                {mediaItem ? (
                        <MediaItemDetails mediaItem={mediaItem} setMediaItemSeason={this._setMediaItemMediaBucketSeason}
                                          seasonMediaItem={childMediaItem}/>
                    ) : (
                        <Spinner/>
                    )}
                {childMediaItem && childMediaItem.offers ? (
                        <MediaCarousel media={childMediaItemMedia}
                                       title={title}
                                       tileType={mediaItem.classification === MediaItemClassification.SERIES
                                           ? 'backdrop-detailed' : 'poster-cta'}
                                       collapsibleTiles={true}
                                       navigationType="top-arrows"
                                       mediaLoading={mediaBucket && mediaBucket.updatePending}
                                       isPurchaseEnabled={isPurchaseEnabled}/>
                    ) : null}
                {AuthStore.isSignedIn()
                    && recommendationsMediaBucket
                    && recommendationsMediaBucket.media().size > 0
                    && mediaItem.classification !== MediaItemClassification.MOVIE_BOX_SET
                    && mediaItem.classification !== MediaItemClassification.TV_BOX_SET ? (
                        <MediaCarousel title={AppStore.translate('view.recommendations_carousel.title')}
                                       media={recommendationsMediaBucket && recommendationsMediaBucket.media() ? recommendationsMediaBucket.media().slice(0, 6) : null}
                                       highlighted={true}
                                       tileType="poster-bubble"
                                       mediaLoading={recommendationsMediaBucket && recommendationsMediaBucket.updatePending}/>
                    ) : null}
            </main>
        );
    }

    /**
     * Set mediaItem mediaBucket
     */
    _setMediaItemMediaBucket = () => {

        this.setState({
            mediaBucket: MediaStore.getMediaBucket(this._mediaBucketKey)
        }, () => setTimeout(()=> {
            const media = this.state.mediaBucket.media() ? this.state.mediaBucket.media() : null;
            const mediaItem = media.first() ? media.first() : null;
            const currentMedia = mediaItem ? mediaItem : null;
            if ((currentMedia && currentMedia.isPurchased) ||
                (currentMedia && currentMedia.classification === MediaItemClassification.SERIES)) {
                return;
            }

            if ( currentMedia && currentMedia.offers.length == 0 ) {
                this.context.router.push(Router.HOME);
            }
            //}
        },0));
    };

    /**
     * Set recommendations mediaBucket
     */
    _setRecommendationsMediaBucket = () => {
        this.setState({
            recommendationsMediaBucket: MediaStore.getMediaBucket(this._recommendationsMediaBucketKey)
        });
    };

    /**
     * Set mediaItem season
     */
    _setMediaItemMediaBucketSeason = (seasonMediaItemId) => {
        this.setState({
            seasonMediaItemId: seasonMediaItemId
        }, () => {
            let mediaBucket = this.state.mediaBucket;
            let seasonMediaItem = mediaBucket.mediaItem(this.state.seasonMediaItemId);
            this.context.router.push(Router.MEDIA_ITEM(seasonMediaItem));
        });
    };
}
