"use strict";

import Immutable from 'immutable';


/**
 * CustomOverlayContentItem definition
 *
 * @typedef {Immutable.Record} CustomOverlayContentItem
 * @property {?object|string} overlayId
 * @property {?bool} showCloseButton
 * @property {?bool} isNode
 */

/**
 * CustomOverlayContentItem record
 *
 * @type {CustomOverlayContentItem}
 */
class CustomOverlayContentItem extends Immutable.Record({
    overlayId: null,
    showCloseButton: true,
    isNode: false // TODO legacy default, refactor out when all popups converted
}) {
    /**
     * Set values from data transfer object
     *
     * @param {object} dataTransferObject
     * @return {CustomOverlayContentItem}
     */
    fromDTO(dataTransferObject) {
        return new CustomOverlayContentItem({
            ...this.toJS(),
            ...dataTransferObject
        });
    }
}

export default CustomOverlayContentItem;
