"use strict";

import Immutable from 'immutable';

/**
 * CastDTO definition
 *
 * @typedef {Object} CastDTO
 * @property {?string} Type - The type of cast member (e.g. 'Director', 'Actor', etc..)
 * @property {?string} FullName - The cast members full name (FirstName FamilyName)
 */

/**
 * Cast definition
 *
 * @typedef {Immutable.Record} Cast
 * @property {?string} type
 * @property {?string} fullName
 */

/**
 * Cast record
 *
 * @type {Cast}
 */
class Cast extends Immutable.Record({
    type: null,
    fullName: null
}) {
    /**
     * Set values from data transfer object
     *
     * @param {CastDTO} dataTransferObject
     * @return {Cast}
     */
    fromDTO(dataTransferObject) {
        return new Cast({
            ...this.toJS(),
            type: typeof dataTransferObject.Type !== 'undefined' ? CastType.getValue(dataTransferObject.Type) : this.type,
            fullName: typeof dataTransferObject.FullName !== 'undefined' ? dataTransferObject.FullName : this.fullName
        });
    }
}

/**
 * Cast types
 * @type {Object}
 */
var CastType = {
    UNKNOWN: 'Unknown',
    DIRECTOR: 'Director',
    WRITER: 'Writer',
    ACTOR: 'Actor',
    COMPOSER: 'Composer',

    /**
     * Get all
     */
    getAll: function() {
        return Object.keys(this).filter((key) => {
            return key.indexOf('get') === -1;
        }).map((key) => {
            return this[key];
        });
    },

    /**
     * Get value
     */
    getValue: function(inputValue) {
        var value = this.UNKNOWN;
        Object.keys(this).forEach((key) => {
            if (this[key] === inputValue) value = this[key];
        });
        return value;
    }
};

export {Cast as default, Cast, CastType};
