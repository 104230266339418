"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import AppStore from '../stores/AppStore';
import Router from '../lib/Router';
import NotificationActions from '../actions/NotificationActions';
import EntitlementActions from '../actions/EntitlementActions';
import EntitlementStore from '../stores/EntitlementStore';
import Button from './ui/Button.react';

/**
 * StbPairing component
 */
export default class StbPairingPage extends React.Component {
    /**
     * React: state
     */
    state = {
        updatePending: false,
        stbCode: ''
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        EntitlementActions.stbPairingProcessEnabled();
        document.body.className += " black-background";
    }
    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        document.body.className = document.body.className.replace(/black-background/, '');
        EntitlementStore.removeListener(EntitlementStore.STORE_UPDATED, this._getStbPairingData);
        EntitlementActions.stbPairingProcessDisabled();
    }

    /**
     * React: render
     */
    render() {
        return (
            <main className="account-page">
                <h4 className="account-page-title">{AppStore.translate('view.stb-pairing-page.title')}</h4>
                <div className="container">
                    <div className="row">
                        <div className="account-page-content col-xs-12 col-sm-8 col-md-5 col-lg-4">
                            <div className="login-avatar">
                                <i className="ui-icon icon-ic-tv" />
                            </div>
                            <form onSubmit={this._onSubmit}>
                                <input type="text"
                                    className={`simple-input`}
                                    maxLength={7}
                                    placeholder={AppStore.translate('field.placeholder.stb_pairing_code')}
                                    value={this.state.stbCode}
                                    onChange={this._onFieldChange.bind(null, 'stbCode')} />
                                <Button type="submit"
                                    shape="square"
                                    className="cta-play full-width"
                                    disabled={this.state.updatePending ||
                                        (this.state.stbCode.length != 7)}
                                    updatePending={this.state.updatePending}
                                >
                                    {AppStore.translate('button.connect_tv')}
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        );
    }

    /**
     * On form submit
     *
     * @param {Object} event
     * @private
     */
    _onSubmit = (e) => {
        e.preventDefault();
        EntitlementStore.addUpdateListener(this._getStbPairingData);
        if (!this.state.stbCode) {
            NotificationActions.displayNotification({
                notificationType: 'error',
                content: AppStore.translate('notification.account.error.empty_credentials')
            });
            return;
        }

        this.setState({
            updatePending: true
        }, () => {
            NotificationActions.hideNotification();
            EntitlementActions.validateStbLinkCode({
                stbCode: this.state.stbCode
            });
        });
    };

    /**
     * Listen for stb pairing data from Entitlement Store
     */
    _getStbPairingData = () => {
        const responseData = EntitlementStore.stbPairingData.toJS();
        if (responseData.error) {
            setTimeout(() => {
                NotificationActions.displayNotification({
                    notificationType: 'error',
                    content: AppStore.translate('view-stb-pairing.notification-error')
                });
            }, 0);
            this.setState({updatePending: false});
            return;
        }
        if (responseData.validated) {
            setTimeout(() => {
                this.context.router.push(Router.SIGN_IN);
            }, 0);
        }
    }

    _onFieldChange = (fieldName, event) => {
        if (event.target.value.length > 7) return;
        const regex = /^[a-z0-9]+$/i;
        if (regex.test(event.target.value) || event.target.value === "") {
            this.setState({
                [fieldName]: event.target.value
            });
        }
    };
}
