"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import {Link} from 'react-router';
import Router from '../../../lib/Router';
import Collection from '../../../types/Collection';
import CollectionImage from '../CollectionImage.react';

/**
 * MediaItemTile component
 *
 * @mixes MediaItemComponentCTAs
 */
export default class TileImage extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        collection: PropTypes.instanceOf(Collection).isRequired,
        useImageLoadingIndicator: PropTypes.bool,
        onClick: PropTypes.func,
        tileType: PropTypes.oneOf(['poster', 'poster-bubble', 'backdrop', 'backdrop-progress-bar', 'backdrop-detailed'])
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        collection: null,
        tileType: 'poster-bubble'
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        let collection = this.props.collection;
        let tileType = this.props.tileType;

        return (
            <Link to={Router.COLLECTIONS(collection)} onClick={this._onClick}>
                <CollectionImage collection={collection}
                                imageType={(tileType === 'poster' || tileType === 'poster-bubble') ? 'poster' : 'backdrop'}
                                useImageLoadingIndicator={this.props.useImageLoadingIndicator}/>
            </Link>
        );
    }

    /**
     * On click
     */
    _onClick = (event) => {
        let collection = this.props.collection;

        if (typeof this.props.onClick === 'function') {
            this.props.onClick(event);
        }

        if (this.props.tileType === 'backdrop-progress-bar' && collection.playbackAvailable) {
            event.preventDefault();
            event.stopPropagation();

            this._playMediaItem();
        }
    };
}
