"use strict";

/**
 * ExtendableError
 */
class ExtendableError extends Error {
    constructor(message) {
        super(message);
        this.name = this.constructor.name;
        this.message = message;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = (new Error(message)).stack;
        }
    }
}

/**
 * APIRequestError
 */
class APIRequestError extends ExtendableError {
    constructor(message = 'default_error', error = null, response = null, responseBody = null) {
        super(message);

        // general error
        if (error && error.message) {
            message = error.message;
        }

        // Vubiquity 'standard' error
        if (responseBody && responseBody.Message) {
            message = responseBody.Message;
        }

        // Vubiquity payment card error
        if (responseBody && responseBody.Message
            && responseBody.ErrorType === 'CardError' && responseBody.ErrorCode) {
            message = responseBody.ErrorCode;
        }

        // Vubiquity's "here-is-a-string-in-a-body-error"
        if (responseBody && typeof responseBody === 'string') {
            message = responseBody;
        }

        // Stripe error object
        if (responseBody && responseBody.error
            && responseBody.error.code && responseBody.error.message) {
            message = responseBody.error.message;
        }

        if (responseBody && responseBody.subcodes) {
            message = `error_code_${responseBody.subcodes[0].subcode}`;
        }

        this.response = {
            ...response
        };

        this.message = message === 'default_error' ? '' : message;
    }
}

export {ExtendableError as default, ExtendableError, APIRequestError};
