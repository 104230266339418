"use strict";

import React from 'react';
import {Router as ReactRouter, Route, browserHistory, IndexRoute} from 'react-router';
import Config from '../lib/Config';
import {MediaItemClassification} from '../types/MediaItem';
import AppActions from '../actions/AppActions';
import AuthStore from '../stores/AuthStore';
import AppContainer from '../components/AppContainer.react';
import Navigation from '../components/elements/Navigation.react';
import Footer from '../components/elements/Footer.react';
import StorefrontPage from '../components/StorefrontPage.react';
import AccountSignUpPage from '../components/AccountSignUpPage.react';
import AccountSignInPage from '../components/AccountSignInPage.react';
import AccountSetPaymentMethodPage from '../components/AccountSetPaymentMethodPage.react';
// import AccountSetPaymentMethodPage from '../components/AccountSetPaymentMethodPageStripeV3.react';
import AccountMarketingOptInPage from '../components/AccountMarketingOptInPage.react';
import AccountAcceptTermsAndConditionsPage from '../components/AccountAcceptTermsAndConditionsPage.react';
import AccountActivatePage from '../components/AccountActivatePage.react';
import AccountChangePasswordPage from '../components/AccountChangePasswordPage.react';
import AccountRequestPasswordResetPage from '../components/AccountRequestPasswordResetPage.react';
import AccountResetPasswordPage from '../components/AccountResetPasswordPage.react';
import AccountUpdatePage from '../components/AccountUpdatePage.react';
// import AccountUpdatePage from '../components/AccountUpdatePageStripeV3.react';
import AccountMyDevicesPage from '../components/AccountMyDevicesPage.react';
import SearchPage from '../components/SearchPage.react';
import MediaItemPage from '../components/MediaItemPage.react';
import CollectionPage from '../components/CollectionPage.react';
import PromotionPage from '../components/PromotionPage.react';
import GenrePage from '../components/GenrePage.react';
import CastPage from '../components/CastPage.react';
import LibraryPage from '../components/LibraryPage.react';
import WatchlistPage from '../components/WatchlistPage.react';
import PlatformArticlePage from '../components/PlatformArticlePage.react';
import PageNotFound from '../components/PageNotFound.react';
import UIDemoPage from '../components/UIDemoPage.react';
import UIFormPage from '../components/UIFormPage.react';
import TestPage from '../components/TestPage.react';
import TestVideoPage from '../components/TestVideoPage.react';
import BundlePage from '../components/BundlePage.react';
import BundlePrePackedPage from '../components/BundlePrePackedPage.react';
import PromotionCollectionsPage from '../components/PromotionCollectionsPage.react';
import StbPairingPage from '../components/StbPairingPage.react';

/**
 * @name Router.MEDIA_ITEM
 * @function
 * @param {MediaItem|null} mediaItem
 */

/**
 * Router paths
 */
var Router = {
    // General
    ROOT: Config.ROOT,
    HOME: Config.ROOT + `home`,
    PAGE: Config.ROOT + `pages/:contextCode`,
    CONTACT: Config.ROOT + `pages/contact`,
    PAGE_NOT_FOUND: Config.ROOT + `404`,
    PARENT: `https://www.virginmedia.com`,
    ABOUT: `http://www.virginmedia.com/shop/tv/virginmediastore.html`,
    BROADBAND: `https://www.virginmedia.com/broadband/broadband-and-tv`,
    TERMS_AND_CONDITIONS: `http://www.virginmedia.com/shop/the-legal-stuff/virgin-media-store.html`,
    PRIVACY: `http://store.virginmedia.com/the-legal-stuff/privacy-policy.html`,
    GDPR: `http://www.virginmedia.com/shop/the-legal-stuff.html`,
    COOKIES: `http://www.virginmedia.com/shop/our-cookies.html`,
    HELP: Config.ROOT + `pages/help-faq`,

    // Account
    SIGN_UP: Config.ROOT + `sign-up`,
    SET_PAYMENT_METHOD: Config.ROOT + `set-payment-method`,
    SET_MARKETING_OPT_IN_PREFERENCES: Config.ROOT + `set-marketing-opt-in`,
    ACCEPT_TERMS_AND_CONDITIONS: Config.ROOT + `accept-terms-and-conditions`,
    RE_ACCEPT_TERMS_AND_CONDITIONS: Config.ROOT + `re-accept-terms-and-conditions`,
    ACTIVATE: Config.ROOT + `activate`,
    SIGN_IN: Config.ROOT + `sign-in`,
    CHANGE_PASSWORD: Config.ROOT + `change-password`,
    UPDATE_ACCOUNT: Config.ROOT + `update-account`,
    REQUEST_PASSWORD_RESET: Config.ROOT + `request-password-reset`,
    RESET_PASSWORD: Config.ROOT + `reset-password`,
    LIBRARY: Config.ROOT + `library`,
    MY_DEVICES: Config.ROOT + `my-devices`,
    STB_PAIRING: Config.ROOT + `stb-pairing`,
    WATCHLIST: Config.ROOT + `wishlist`,

    // Media
    SEARCH: Config.ROOT + `search`,
    SEARCH_MEDIA: (searchQueryString = null) => Config.ROOT + (searchQueryString !== null ? `search/${searchQueryString}` : `search(/:searchQueryString)`),
    ALL_MEDIA: (classification = null) => Config.ROOT + `search/` + (classification ? classification : `all`),
    MEDIA_ITEM: function (mediaItem = null) {
        if (!mediaItem) return this.HOME;
        switch (mediaItem.classification) {
            case MediaItemClassification.MOVIE:
                return this.MOVIES(mediaItem);

            case MediaItemClassification.SERIES:
                return this.SERIES(mediaItem);

            case MediaItemClassification.SEASON:
                return this.SERIES_SEASONS(mediaItem);
                // return this.SEASONS(mediaItem);

            case MediaItemClassification.EPISODE:
                return this.EPISODES(mediaItem);

            case MediaItemClassification.MOVIE_BOX_SET:
            case MediaItemClassification.TV_BOX_SET:
                return this.BOX_SETS(mediaItem);

            default:
                return this.HOME;
        }
    },
    COLLECTIONS: function (collection, classification) {
        if (collection.isPrePackedBundle) {
            return this.PRE_PACKED_BUNDLE_COLLECTIONS(collection);
        }

        if (collection.isBundle) {
            return this.BUNDLE_COLLECTIONS(collection);
        }

        return this.GENERAL_COLLECTIONS(collection, classification);
    },
    GENERAL_COLLECTIONS: (collection = null, classification = null) => Config.ROOT + (collection !== null ? `collections/${collection.title}` : `collections/:collectionTitle`)
        + (classification ? `?classification=${classification}` : ``),
    BUNDLE_COLLECTIONS: (collection = null) => Config.ROOT + (collection !== null ? `bundles/${collection.title}` : `bundles/:collectionTitle`),
    PRE_PACKED_BUNDLE_COLLECTIONS: (collection = null) => Config.ROOT + (collection !== null ? `pre-packed-bundles/${collection.title}` : `pre-packed-bundles/:collectionTitle`),
    PROMOTIONS: (promotionId = null, classification = null) => Config.ROOT + (promotionId !== null ? `promotions/${promotionId}` : `promotions/:promotionName`)
    + (classification ? `?classification=${classification}` : ``),
    PROMOTION_COLLECTIONS: (promotionId = null) => Config.ROOT + (promotionId !== null ? `promotions/${promotionId}/collections` : `promotions/:promotionName/collections`),
    GENRES: (genreId = null, classification = null) => Config.ROOT + (genreId !== null ? `genres/${genreId}` : `genres/:genreId`)
    + (classification ? `?classification=${classification}` : ``),
    NAMES: (castFullName = null) => Config.ROOT + (castFullName !== null ? `names/${castFullName}` : `names/:castFullName`),
    MOVIES: (mediaItem = null) => Config.ROOT + (mediaItem !== null
        ? `movies/${mediaItem.id}${mediaItem.slug ? '/' + mediaItem.slug : ''}` : `movies/:mediaItemId(/**)`),
    BOX_SETS: (mediaItem = null) => Config.ROOT + (mediaItem !== null
        ? `box-sets/${mediaItem.id}${mediaItem.slug ? '/' + mediaItem.slug : ''}` : `box-sets/:mediaItemId(/**)`),
    SERIES_SEASONS: (mediaItem = null) => Config.ROOT + (mediaItem !== null ? `series/${mediaItem.parentId}/seasons/${mediaItem.id}`
        : `series/:mediaItemId/seasons/:childMediaItemId`),
    SERIES: (mediaItem = null) => Config.ROOT + (mediaItem !== null
        ? `series/${mediaItem.id}${mediaItem.slug ? '/' + mediaItem.slug : ''}` : `series/:mediaItemId(/**)`),
    SEASONS: (mediaItem = null) => Config.ROOT + (mediaItem !== null ? `seasons/${mediaItem.id}` : `seasons/:mediaItemId`),
    EPISODES: (mediaItem = null) => Config.ROOT + (mediaItem !== null ? `episodes/${mediaItem.id}` : `episodes/:mediaItemId`),

    // Social networks
    SOCIAL_NETWORK_FACEBOOK: 'https://facebook.com/',
    SOCIAL_NETWORK_TWITTER: 'https://twitter.com/',
    SOCIAL_NETWORK_INSTAGRAM: 'https://instagram.com/',

    // Misc
    UI_DEMO: Config.ROOT + `ui-demo`,
    UI_FORM: Config.ROOT + `ui-form`
};

/**
 * React router: returns site map
 *
 * @params {Object} Router
 */
function getSiteMap(Router = {}) {
    return (
        <ReactRouter onUpdate={() => window.scrollTo(0, 0)} history={browserHistory}>
            <Route path={Router.ROOT}
                   onEnter={allowAnyone}
                   component={AppContainer}>
                {/* General */}
                <IndexRoute components={{navigation: Navigation, footer: Footer, content: StorefrontPage}}/>
                <Route path={Router.HOME}
                       onEnter={allowAnyone}
                       components={{navigation: Navigation, footer: Footer, content: StorefrontPage}}/>
                <Route path={Router.PAGE}
                       onEnter={allowAnyone}
                       components={{navigation: Navigation, footer: Footer, content: PlatformArticlePage}}/>

                {/* Account */}
                <Route path={Router.SIGN_UP}
                       onEnter={requireAnonymous}
                       components={{navigation: Navigation, footer: Footer, content: AccountSignUpPage}}/>
                <Route path={Router.ACCEPT_TERMS_AND_CONDITIONS}
                       onEnter={requireAuth}
                       components={{
                           navigation: Navigation,
                           footer: Footer,
                           content: AccountAcceptTermsAndConditionsPage
                       }}/>
                <Route path={Router.RE_ACCEPT_TERMS_AND_CONDITIONS}
                       onEnter={requireAuth}
                       components={{
                           navigation: Navigation,
                           footer: Footer,
                           content: AccountAcceptTermsAndConditionsPage
                       }}/>
                <Route path={Router.SET_PAYMENT_METHOD}
                       onEnter={requireAuth}
                                components={{navigation: Navigation, footer: Footer, content: AccountSetPaymentMethodPage}}/>
                <Route path={Router.SET_MARKETING_OPT_IN_PREFERENCES}
                       onEnter={requireAuth}
                       components={{navigation: Navigation, footer: Footer, content: AccountMarketingOptInPage}}/>
                <Route path={Router.ACTIVATE}
                       onEnter={requireAnonymous}
                       components={{navigation: Navigation, footer: Footer, content: AccountActivatePage}}/>
                <Route path={Router.SIGN_IN}
                       onEnter={requireAnonymous}
                       components={{navigation: Navigation, footer: Footer, content: AccountSignInPage}}/>
                <Route path={Router.UPDATE_ACCOUNT}
                       onEnter={requireAuth}
                       components={{navigation: Navigation, footer: Footer, content: AccountUpdatePage}}/>
                <Route path={Router.CHANGE_PASSWORD}
                       onEnter={requireAuth}
                       components={{navigation: Navigation, footer: Footer, content: AccountChangePasswordPage}}/>
                <Route path={Router.REQUEST_PASSWORD_RESET}
                       onEnter={requireAnonymous}
                       components={{navigation: Navigation, footer: Footer, content: AccountRequestPasswordResetPage}}/>
                <Route path={Router.RESET_PASSWORD}
                       onEnter={requireAnonymous}
                       components={{navigation: Navigation, footer: Footer, content: AccountResetPasswordPage}}/>
                <Route path={Router.LIBRARY}
                       onEnter={requireAuth}
                       components={{navigation: Navigation, footer: Footer, content: LibraryPage}}/>
                <Route path={Router.WATCHLIST}
                       onEnter={requireAuth}
                       components={{navigation: Navigation, footer: Footer, content: WatchlistPage}}/>
                <Route path={Router.MY_DEVICES}
                       onEnter={requireAuth}
                       components={{navigation: Navigation, footer: Footer, content: AccountMyDevicesPage}}/>
                <Route path={Router.PAGE_NOT_FOUND}
                       components={{navigation: Navigation, footer: Footer, content: PageNotFound}}/>
                <Route path={Router.STB_PAIRING}
                       components={{navigation: Navigation, footer: Footer, content: StbPairingPage}}/>

                {/* Media */}
                <Route path={Router.SEARCH}
                       onEnter={allowAnyone}
                       components={{navigation: Navigation, footer: Footer, content: SearchPage}}>
                    <Route path={Router.SEARCH_MEDIA()}/>
                </Route>
                <Route path={Router.ALL_MEDIA()}
                       onEnter={allowAnyone}
                       components={{navigation: Navigation, footer: Footer, content: SearchPage}}/>
                <Route path={Router.MOVIES()}
                       onEnter={allowAnyone}
                       components={{navigation: Navigation, footer: Footer, content: MediaItemPage}}/>
                <Route path={Router.BOX_SETS()}
                       onEnter={allowAnyone}
                       components={{navigation: Navigation, footer: Footer, content: MediaItemPage}}/>
                <Route path={Router.SERIES_SEASONS()}
                       onEnter={allowAnyone}
                       components={{navigation: Navigation, footer: Footer, content: MediaItemPage}}/>
                <Route path={Router.SERIES()}
                       onEnter={allowAnyone}
                       components={{navigation: Navigation, footer: Footer, content: MediaItemPage}}/>
                <Route path={Router.SEASONS()}
                       onEnter={allowAnyone}
                       components={{navigation: Navigation, footer: Footer, content: MediaItemPage}}/>
                <Route path={Router.EPISODES()}
                       onEnter={allowAnyone}
                       components={{navigation: Navigation, footer: Footer, content: MediaItemPage}}/>
                <Route path={Router.GENERAL_COLLECTIONS()}
                       onEnter={allowAnyone}
                       components={{navigation: Navigation, footer: Footer, content: CollectionPage}}/>
                <Route path={Router.BUNDLE_COLLECTIONS()}
                       onEnter={allowAnyone}
                       components={{navigation: Navigation, footer: Footer, content: BundlePage}}/>
                <Route path={Router.PRE_PACKED_BUNDLE_COLLECTIONS()}
                       onEnter={allowAnyone}
                       components={{navigation: Navigation, footer: Footer, content: BundlePrePackedPage}}/>
                <Route path={Router.PROMOTIONS()}
                       onEnter={allowAnyone}
                       components={{navigation: Navigation, footer: Footer, content: PromotionPage}}/>
                <Route path={Router.PROMOTION_COLLECTIONS()}
                       onEnter={allowAnyone}
                       components={{navigation: Navigation, footer: Footer, content: PromotionCollectionsPage}}/>
                <Route path={Router.GENRES()}
                       onEnter={allowAnyone}
                       components={{navigation: Navigation, footer: Footer, content: GenrePage}}/>
                <Route path={Router.NAMES()}
                       onEnter={allowAnyone}
                       components={{navigation: Navigation, footer: Footer, content: CastPage}}/>

                {/* Misc */}
                <Route path={Router.UI_DEMO}
                       components={{navigation: Navigation, footer: Footer, content: UIDemoPage}}/>
                <Route path={Router.UI_FORM}
                       components={{navigation: Navigation, footer: Footer, content: UIFormPage}}/>
                <Route path="/test"
                       components={{navigation: Navigation, footer: Footer, content: TestPage}}/>
                <Route path="/test-video"
                       components={{navigation: Navigation, footer: Footer, content: TestVideoPage}}/>
                <Route path="*"
                       components={{navigation: Navigation, footer: Footer, content: PageNotFound}}/>
            </Route>
        </ReactRouter>
    );
}

/**
 * Allow anyone
 *
 * @param nextState
 * @param replace
 * @private
 */
function allowAnyone(nextState, replace) {
    validateSessionConditions(nextState, replace);
}

/**
 * Check if user is authenticated
 *
 * @param nextState
 * @param replace
 * @private
 */
function requireAuth(nextState, replace) {
    if (!AuthStore.isSignedIn()) {
        AppActions.storeRouterTransitionPath(nextState.location.pathname);
        replace(Router.SIGN_IN);
        return;
    }

    validateSessionConditions(nextState, replace);
}

/**
 * Check if user is anonymous
 *
 * @param nextState
 * @param replace
 * @private
 */
function requireAnonymous(nextState, replace) {
    if (AuthStore.isSignedIn()) {
        replace(Router.HOME);
        return;
    }

    validateSessionConditions(nextState, replace);
}

/**
 * Validate other session conditions
 *
 * @param nextState
 * @param replace
 * @private
 */
function validateSessionConditions(nextState, replace) {
    // Check if payment method is set;

    // if (AuthStore.isSignedIn()
    //     && AuthStore.userProfile
    //     && !AuthStore.userProfile.paymentProviderCustomerIds.stripe) {
    //     if (nextState.location.pathname !== Router.SET_PAYMENT_METHOD) {
    //         AppActions.storeRouterTransitionPath(nextState.location.pathname);
    //         replace(Router.SET_PAYMENT_METHOD);
    //     }
    //     return;
    // }

    // Check if terms and conditions are accepted
    if (AuthStore.isSignedIn()
        && AuthStore.identity
        && !AuthStore.identity.termsAndConditionsAccepted) {
        if (nextState.location.pathname !== Router.ACCEPT_TERMS_AND_CONDITIONS) {
            AppActions.storeRouterTransitionPath(nextState.location.pathname);
            replace(Router.ACCEPT_TERMS_AND_CONDITIONS);
        }
        // return;
    }
}

export {Router as default, Router, getSiteMap, requireAnonymous, requireAuth};
