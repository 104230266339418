"use strict";

import {EventEmitter} from 'events';
import {register} from '../dispatchers/AppDispatcher';

/**
 * Flux: BaseStore
 */
export default class BaseStore extends EventEmitter {
    /**
     * Store events
     */
    STORE_UPDATED = 'store_updated';

    /**
     * Store error
     *
     * @type {Object|null}
     * @private
     */
    _storeError = null;

    /**
     * Constructor
     */
    constructor() {
        super();
    }

    /**
     * Subscribe store to actions
     *
     * @param actionSubscribe
     */
    subscribe(actionSubscribe) {
        this._dispatchToken = register(actionSubscribe());
    }

    /**
     * Return dispatch token
     *
     * @return {*}
     */
    get dispatchToken() {
        return this._dispatchToken;
    }

    /**
     * Store update emitter
     */
    emitUpdate() {
        this.emit(this.STORE_UPDATED);
    }

    /**
     * EventEmitter.on() wrapper
     *
     * @param storeEvent
     * @param callback
     */
    addListener(storeEvent, callback) {
        this.on(storeEvent, callback);
    }

    /**
     * Adds store update listener
     */
    addUpdateListener(callback) {
        this.addListener(this.STORE_UPDATED, callback);
    }

    /**
     * Removes store update listener
     */
    removeUpdateListener(callback) {
        this.removeListener(this.STORE_UPDATED, callback);
    }

    /**
     * Set storeError
     */
    set _error(storeError) {
        this._storeError = storeError;
    }

    /**
     * Return storeError
     *
     * @return {*}
     */
    get _error() {
        return this._storeError;
    }
}
