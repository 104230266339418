"use strict";

import Immutable from 'immutable';

/**
 * EntitlementTypeDTO definition
 *
 * @typedef {Object} EntitlementTypeDTO
 * @property {?number} Id - Entitlement Type Id
 * @property {?string} Name - Entitlement Type Name
 */

/**
 * EntitlementType definition
 *
 * @typedef {Immutable.Record} EntitlementType
 * @property {?number} id
 * @property {?string} name
 */

/**
 * EntitlementType record
 *
 * @type {EntitlementType}
 */
class EntitlementType extends Immutable.Record({
    id: null,
    name: null
}) {
    /**
     * Set values from data transfer object
     *
     * @param {EntitlementTypeDTO} dataTransferObject
     * @return {EntitlementType}
     */
    fromDTO(dataTransferObject) {
        return new EntitlementType({
            ...this.toJS(),
            id: typeof dataTransferObject.Id !== 'undefined' ? dataTransferObject.Id : this.id,
            name: typeof dataTransferObject.Name !== 'undefined' ? dataTransferObject.Name : this.name
        });
    }
}

export default EntitlementType;
