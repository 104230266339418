"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import Config from '../../../lib/Config';
import MediaItem, {MediaItemClassification} from '../../../types/MediaItem';
import {OfferType} from '../../../types/Offer';
import AppStore from '../../../stores/AppStore';
import Button from '../../ui/Button.react';
import MediaItemComponentCTAs from '../../mixins/MediaItemComponentCTAs';

/**
 * CTAButtons component
 *
 * @mixes MediaItemComponentCTAs
 */
export default class CTAButtons extends MediaItemComponentCTAs(React.Component) {
    /**
     * React: propTypes
     */
    static propTypes = {
        mediaItem: PropTypes.instanceOf(MediaItem).isRequired,
        shouldCTAButtonsBeVisible: PropTypes.bool,
        isPurchaseEnabled: PropTypes.bool
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        shouldCTAButtonsBeVisible: true,
        isPurchaseEnabled: true
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        var mediaItem = this.props.mediaItem;

        return this.props.shouldCTAButtonsBeVisible ? (
            <div className="call-to-action">
                {!mediaItem.isPurchased && this.props.isPurchaseEnabled ? mediaItem.offers.map((offer, index) => {
                    let purchaseAvailable = offer.offerType !== OfferType.POEST;
                    return (
                        <Button shape="square"
                                key={index}
                                className={(purchaseAvailable ? 'cta-purchase-est' : 'cta-pre-order') + ' left-align full-width'}
                                disabled={purchaseAvailable ? mediaItem.updatePending : (mediaItem.updatePending || mediaItem.isOnWatchlist)}
                                updatePending={purchaseAvailable ? offer.updatePending : mediaItem.watchlistPending}
                                onClick={purchaseAvailable
                                    ? this._fastCheckoutMediaItemOffer.bind(null, offer.id) : this._preOrderMediaItem}>
                            {!purchaseAvailable && mediaItem.isOnWatchlist ? (
                                <i className="ui-icon icon-check-circle"/>
                            ) : (offer.currency === 'GBP' ? (
                                <i className="ui-icon icon-pound-circle"/>
                            ) : (offer.currency === 'EUR' ? (
                                <i className="ui-icon icon-euro-circle"/>
                            ) : (
                                <i className="ui-icon icon-basket"/>
                            )))}

                            {purchaseAvailable ? offer.price.toLocaleString(Config.LOCALE, {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }) : (!mediaItem.isOnWatchlist ? AppStore.translate('button.cta.pre-order') : AppStore.translate('label.pre-ordered'))}

                            {!purchaseAvailable ?
                                <span className="right-content">
                                    {AppStore.translate('label.digital_pre_order')}&nbsp;
                                    <i className="ui-icon icon-hd-sign video-options-icon"/>
                                </span>
                                : (mediaItem.classification === MediaItemClassification.MOVIE
                                || mediaItem.classification === MediaItemClassification.MOVIE_BOX_SET ? (
                                <span className="right-content">
                                    {AppStore.translate('label.digital')}&nbsp;
                                    <i className="ui-icon icon-hd-sign video-options-icon"/>&nbsp;
                                </span>
                            ) : (
                                <span className="right-content">
                                    {AppStore.translate('label.digital')}&nbsp;
                                    <i className="ui-icon icon-hd-sign video-options-icon"/>
                                </span>
                            ))}
                        </Button>
                    );
                }) : null}

                {mediaItem.playbackAvailable ? (
                    <Button shape="square"
                            className="cta-play"
                            onClick={this._playMediaItem}>
                        <i className="ui-icon icon-play"/>{AppStore.translate('button.cta.play')}
                    </Button>
                ) : null}

                {mediaItem.downloadAvailable ? (
                    <Button shape="square"
                            className="cta-download"
                            onClick={this._openDownloadManagerOverlay}>
                        <i className="ui-icon icon-download"/>{AppStore.translate('button.cta.download')}
                    </Button>
                ) : null}
            </div>
        ) : (
            <div className="call-to-action">&nbsp;</div>
        );
    }
}
