"use strict";

import Immutable from 'immutable';
import CardDetails from './CardDetails';

/**
 * CustomerPaymentDetailsDTO definition
 *
 * @typedef {Object} CustomerPaymentDetailsDTO
 * @property {?string} City
 * @property {?string} Country
 * @property {?string} AddressLine1
 * @property {?string} AddressLine2
 * @property {?string} PostCode - Post code / zip code
 * @property {?string} FullName - Full customer name with title
 * @property {?Array<CardDetailsDTO>} AvailableCards
 * @property {?CardDetailsDTO} DefaultCard
 * @property {?string} Message
 * @property {?string} HttpStatusCode
 * @property {?string} ErrorType
 * @property {?string} ErrorCode
 * @property {?string} DeclineCode
 */

/**
 * CustomerPaymentDetails definition
 *
 * @typedef {Immutable.Record} CustomerPaymentDetails
 * @property {?string} city
 * @property {?string} country
 * @property {?string} addressLine1
 * @property {?string} addressLine2
 * @property {?string} postCode
 * @property {?string} fullName
 * @property {?Array<CardDetails>} availableCards
 * @property {?CardDetails} defaultCard
 * @property {?string} message
 * @property {?string} httpStatusCode
 * @property {?string} errorType
 * @property {?string} errorCode
 * @property {?string} declineCode
 */

/**
 * CustomerPaymentDetails record
 *
 * @type {CustomerPaymentDetails}
 */
class CustomerPaymentDetails extends Immutable.Record({
    city: null,
    country: null,
    addressLine1: null,
    addressLine2: null,
    postCode: null,
    fullName: null,
    availableCards: [],
    defaultCard: null,
    message: null,
    httpStatusCode: null,
    errorType: null,
    errorCode: null,
    declineCode: null
}) {
    /**
     * Set values from data transfer object
     *
     * @param {CustomerPaymentDetailsDTO} dataTransferObject
     * @return {CustomerPaymentDetails}
     */
    fromDTO(dataTransferObject) {
        return new CustomerPaymentDetails({
            ...this.toJS(),
            city: typeof dataTransferObject.City !== 'undefined' ? dataTransferObject.City : this.city,
            country: typeof dataTransferObject.Country !== 'undefined' ? dataTransferObject.Country : this.country,
            addressLine1: typeof dataTransferObject.AddressLine1 !== 'undefined' ? dataTransferObject.AddressLine1 : this.addressLine1,
            addressLine2: typeof dataTransferObject.AddressLine2 !== 'undefined' ? dataTransferObject.AddressLine2 : this.addressLine2,
            postCode: typeof dataTransferObject.PostCode !== 'undefined' ? dataTransferObject.PostCode : this.postCode,
            fullName: typeof dataTransferObject.FullName !== 'undefined' ? dataTransferObject.FullName : this.fullName,
            availableCards: typeof dataTransferObject.AvailableCards !== 'undefined' && dataTransferObject.AvailableCards ? dataTransferObject.AvailableCards.map((cardDetailsDTO) => {
                return new CardDetails({}).fromDTO(cardDetailsDTO);
            }) : this.availableCards,
            defaultCard: typeof dataTransferObject.DefaultCard !== 'undefined' && dataTransferObject.DefaultCard ? new CardDetails({}).fromDTO(dataTransferObject.DefaultCard) : this.defaultCard,
            message: typeof dataTransferObject.Message !== 'undefined' ? dataTransferObject.Message : this.message,
            httpStatusCode: typeof dataTransferObject.HttpStatusCode !== 'undefined' ? dataTransferObject.HttpStatusCode : this.httpStatusCode,
            errorType: typeof dataTransferObject.ErrorType !== 'undefined' ? dataTransferObject.ErrorType : this.errorType,
            errorCode: typeof dataTransferObject.ErrorCode !== 'undefined' ? dataTransferObject.ErrorCode : this.errorCode,
            declineCode: typeof dataTransferObject.DeclineCode !== 'undefined' ? dataTransferObject.DeclineCode : this.declineCode
        });
    }
}

export default CustomerPaymentDetails;
