"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import {Link} from 'react-router';
import Config from '../../lib/Config';
import Router from '../../lib/Router';
import Collection, {CollectionType} from '../../types/Collection';
import MediaItem from '../../types/MediaItem';
import AppStore from '../../stores/AppStore';
import Button from '../ui/Button.react';
import CollectionImage from './CollectionImage.react';

/**
 * BundleDetails component
 */
export default class BundleDetails extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        collection: PropTypes.instanceOf(Collection).isRequired,
        contentMediaItem: PropTypes.instanceOf(MediaItem).isRequired,
        fastCheckoutBundleOffer: PropTypes.func.isRequired,
        purchaseAvailable: PropTypes.bool,
        updatePending: PropTypes.bool
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        purchaseAvailable: true,
        updatePending: false
    };

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        const collection = this.props.collection;
        const bundleOffer = collection.bundleOffer;
        const contentMediaItem = this.props.contentMediaItem;
        const basicInfoGridColumns = " col-xs-12 col-md-12 col-lg-12";

        return (
            <div className="bundle-details"
                 style={{backgroundImage: `url('${contentMediaItem.getImageLocation('landscape')}')`}}>
                <div className="transparent-backdrop" />
                <div className="container">
                    <div className="row">
                        <div className="basic-information col-xs-12 col-md-12 col-lg-5">
                            <div className={"title" + basicInfoGridColumns}>
                                <h2>{collection.title}</h2>
                            </div>
                            <div className={"misc-information" + basicInfoGridColumns}>
                                <div className="column">
                                    {collection.collectionType === CollectionType.MOVIE_BUNDLE ? (
                                        <strong>{AppStore.translate('label.movie_count', {itemCount: collection.mediaCount})}<br/></strong>
                                    ) : (
                                        <strong>{AppStore.translate('label.season_count', {itemCount: collection.mediaCount})}<br/></strong>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="bundle-backdrop col-xs-12 col-lg-7">
                            <CollectionImage collection={collection} imageType="backdrop" />
                        </div>

                        {this.props.purchaseAvailable &&  bundleOffer.offerPrice !== null ? (
                            <div className="cta-menu col-xs-12 col-lg-5">
                                <Button shape="square"
                                        className="cta-purchase-est left-align"
                                        disabled={this.props.updatePending}
                                        updatePending={this.props.updatePending}
                                        onClick={this.props.fastCheckoutBundleOffer}>
                                    {bundleOffer.offerCurrency === 'GBP' ? (
                                        <i className="ui-icon icon-pound-circle"/>
                                    ) : (bundleOffer.offerCurrency === 'EUR' ? (
                                        <i className="ui-icon icon-euro-circle"/>
                                    ) : (
                                        <i className="ui-icon icon-basket"/>
                                    ))}

                                    {bundleOffer.offerPrice.toLocaleString(Config.LOCALE, {
                                        style: 'decimal',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}

                                    <span className="right-content">
                                    {AppStore.translate('label.digital')}&nbsp;
                                        <i className="ui-icon icon-hd-sign video-options-icon"/>&nbsp;
                                        ({AppStore.translate('label.with')}&nbsp;
                                        <i className="ui-icon icon-dvd video-options-icon"/>)
                                </span>
                                </Button>
                            </div>
                        ) : null}

                        <div className={"description col-xs-12 col-lg-5"}>
                            <p className="synopsis">{collection.description}</p>

                            <p className="hanging-indent advice-container">
                                {AppStore.translate('label.hd_advice')} <Link to={Router.HELP} >{AppStore.translate('label.hd_advice_devices')}</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
