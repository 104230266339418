"use strict";

import React from 'react';
import {Link} from 'react-router';
import Router from '../../../lib/Router';
import AuthActions from '../../../actions/AuthActions';
import AppStore from '../../../stores/AppStore';
import UIActions from '../../../actions/UIActions';
import Button from '../../ui/Button.react';
import AuthStore from '../../../stores/AuthStore';

/**
 * NavigationProfile component
 */
export default class NavigationProfile extends React.Component {
    /**
     * React: render
     */
    render() {
        var userProfile = AuthStore.userProfile;
        return (
            <div className="nav-menu-popup">
                <ul className="">
                    {AuthStore.isSignedIn() && userProfile ? (
                        <li>
                            <Link to={Router.UPDATE_ACCOUNT}>
                                <i className="ui-icon icon-user"/>{AppStore.translate('view.navigation.label.account')}
                            </Link>
                        </li>
                    ) : null}
                    {AuthStore.isSignedIn() ? (
                        <li>
                            <Link to={Router.MY_DEVICES}>
                                <i className="ui-icon icon-menu-devices"/>{AppStore.translate('view.navigation.label.devices')}
                            </Link>
                        </li>
                    ) : null}
                    {AuthStore.isSignedIn() ? (
                        <li>
                            <Link to={Router.WATCHLIST}>
                                <i className="ui-icon icon-wishlist"/>{AppStore.translate('view.navigation.label.watchlist')}
                            </Link>
                        </li>
                    ) : null}
                    {AuthStore.isSignedIn() ? (
                        <li>
                            <Link to={Router.LIBRARY}>
                                <i className="ui-icon icon-menu-library"/>{AppStore.translate('view.navigation.label.library')}
                            </Link>
                        </li>
                    ) : null}

                    <li>
                        <Link to={Router.CONTACT}>
                            <i className="ui-icon icon-menu-contact"/>{AppStore.translate('view.navigation.label.contact')}
                        </Link>
                    </li>

                    {AuthStore.isSignedIn() ? (
                        <li>
                            <span onClick={this._confirmSignOut}>
                                <i className="ui-icon icon-menu-logout"/>{AppStore.translate('view.navigation.label.sign_out')}
                            </span>
                        </li>
                    ) : (
                        <li>
                            <Link to={Router.SIGN_IN}>
                                <i className="ui-icon icon-menu-logout"/>{AppStore.translate('view.navigation.label.sign_in')}
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
        );
    }
    /**
     * Show Signout Popup
     *
     * @private
     */
    _confirmSignOut = () => {
        UIActions.showCustomOverlay((
            <div className="alert-box-size col-xs-12 col-sm-8 col-md-6">
                <h2 className="overlay-header">{AppStore.translate('view.navigation.label.sign_out_popup_title')}</h2>
                <div className="overlay-content">
                    <p className="popup-text-centered">{AppStore.translate('view.navigation.label.sign_out_popup_info_text')}</p>

                    <div className="buttons-container ">
                        <Button type="button"
                                shape="regular"
                                className="uppercase-button"
                                onClick={UIActions.closeCustomOverlay}>
                            Cancel
                        </Button>
                        <Button type="button"
                                shape="regular"
                                className="uppercase-button"
                                onClick={AuthActions.signOutUser}>
                            Sign out
                        </Button>
                    </div>
                </div>
            </div>
        ));
    };
}
