"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import Immutable from 'immutable';
import Config from '../lib/Config';
import Router from '../lib/Router';
import {PageRowType} from '../types/PageRow';
import MediaActions from '../actions/MediaActions';
import EntitlementActions from '../actions/EntitlementActions';
import UIActions from '../actions/UIActions';
import AuthActions from '../actions/AuthActions';
import NotificationActions from '../actions/NotificationActions';
import AuthStore from '../stores/AuthStore';
import AppStore from '../stores/AppStore';
import WatchlistStore from '../stores/WatchlistStore';
import MediaStore from '../stores/MediaStore';
import EntitlementStore from '../stores/EntitlementStore';
import PromotionCarousel from './elements/PromotionCarousel.react';
import MediaCarousel from './elements/MediaCarousel.react';
import MediaCarouselTally from './elements/MediaCarouselTally.react';
import CollectionCarouselTally from './elements/CollectionCarouselTally.react';
import Button from './ui/Button.react';
import {Link} from 'react-router';
import OverlayIds from '../lib/OverlayIds';
/**
 * StorefrontPage component
 */
export default class StorefrontPage extends React.Component {
    /**
     * React: state
     */
    state = {
        mediaBucketHash: {},
        pageRowsLoaded: Config.STOREFRONT_INITIAL_PAGE_ROWS_COUNT,
        creditCardAdded: false,
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * MediaBucket keys
     */
    _mediaBucketKeyHash = {};

    /**
     * Storefront page rows
     */
    _storefrontPageRows = [];

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        this._storefrontPageRows = AppStore.appSettings.get(Config.STOREFRONT_SETTING_KEY)
            ? AppStore.appSettings.get(Config.STOREFRONT_SETTING_KEY).settingValue : [];

        // filter pageRows based on content
        this._storefrontPageRows = this._storefrontPageRows.filter(pageRow => {
            // featured mediaItems
            if (pageRow.type === PageRowType.PROMOTION && pageRow.value.indexOf('FEATURED_') > -1) {
                let promotion = AppStore.promotionListByName.get(pageRow.value);
                return !!promotion;
            }

            // collections
            if (pageRow.type === PageRowType.COLLECTION) {
                let collection = AppStore.collectionListByTitle.get(pageRow.value);
                return !!collection && collection.mediaCount > 0;
            }

            // collection promotion
            if (pageRow.type === PageRowType.PROMOTION && pageRow.value.indexOf('COLLECTIONS') > -1) {
                let promotion = AppStore.promotionListByName.get(pageRow.value);
                return !!promotion;
            }

            // collection promotion - SPECIAL_OFFERS
            if (pageRow.type === PageRowType.PROMOTION && pageRow.value.indexOf('SPECIAL_OFFERS_') > -1) {
                let promotion = AppStore.promotionListByName.get(pageRow.value);
                return !!promotion;
            }

            // featured mediaItems
            if (pageRow.type === PageRowType.PROMOTION && pageRow.value.indexOf('RECOMMENDATIONS_') > -1) {
                let promotion = AppStore.promotionListByName.get(pageRow.value);
                return !!promotion;
            }

            // recently viewed
            if (pageRow.type === PageRowType.RECENTLY_VIEWED && AuthStore.isSignedIn()) {
                return !!EntitlementStore.recentlyViewedItems.size;
            }

            // genres
            if (pageRow.type === PageRowType.GENRE) {
                let genre = pageRow.value;
                return !!genre;
            }
        });

        this._getMediaForPageRows(this._storefrontPageRows ? this._storefrontPageRows.slice(0, this.state.pageRowsLoaded) : null);
    }

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        MediaStore.addUpdateListener(this._setMediaBucket);
        if (WatchlistStore.preOrderedMediaItemAlerts.size > 0) {
            this._displayWatchlistItemPopup();
        }
        //check if user is coming from stb pairing page / stb pairing data is available
        if (EntitlementStore.stbPairingData.size > 0) {
            EntitlementStore.addUpdateListener(this._registerNewStbDevice);
            AuthActions.getCustomerPaymentDetails();
            this._consumeStbCodeAndGetRegisteredDevices();
        }
    }

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        MediaStore.removeUpdateListener(this._setMediaBucket);
        EntitlementStore.removeUpdateListener(this._registerNewStbDevice);
        Object.keys(this._mediaBucketKeyHash).forEach(key => MediaStore.destroyMediaBucket(this._mediaBucketKeyHash[key]));
        this._mediaBucketKeyHash = {};
        this.setState({
            mediaBucketHash: {}
        });
    }

    /**
     * React: render
     */
    render() {
        return (
            <main>
                {this._storefrontPageRows.length === 0
                    ? (
                        <div className="no-results">
                            {AppStore.translate('message.no_content_available')}
                        </div>
                    )
                    : this._storefrontPageRows.map((pageRow, index) => {
                        if (index >= this.state.pageRowsLoaded) return null;

                        // featured mediaItems promotion
                        if (pageRow.type === PageRowType.PROMOTION && pageRow.value.indexOf('FEATURED') > -1) {
                            let promotion = AppStore.promotionListByName.get(pageRow.value);
                            if (promotion) {
                                let mediaBucket = this.state.mediaBucketHash[pageRow.value];
                                let media = mediaBucket ? mediaBucket.media() : null;
                                return media && media.size > 0 ? (
                                    <PromotionCarousel key={pageRow.value}
                                        media={media}
                                        promotionItems={promotion.mediaItemItems} />
                                ) : null;
                            }
                        }

                        // collection
                        if (pageRow.type === PageRowType.COLLECTION) {
                            let collection = AppStore.collectionListByTitle.get(pageRow.value);
                            if (collection) {
                                let mediaBucket = this.state.mediaBucketHash[pageRow.value];
                                let media = mediaBucket ? mediaBucket.media() : null;
                                return (mediaBucket && mediaBucket.updatePending) || (media && media.size > 0) ? (
                                    <MediaCarouselTally key={collection.title}
                                        title={collection.title}
                                        linkTo={Router.COLLECTIONS(collection)}
                                        media={media}
                                        mediaTotalCount={media ? media.size : 0}
                                        navigationType="side-arrows-in-elipse"
                                        mediaLoading={mediaBucket && mediaBucket.updatePending} />
                                ) : null;
                            }
                        }

                        // recently viewed
                        if (pageRow.type === PageRowType.RECENTLY_VIEWED) {
                            let mediaBucket = this.state.mediaBucketHash[pageRow.value];
                            let media = mediaBucket ? mediaBucket.media() : null;
                            return (mediaBucket && mediaBucket.updatePending) || (media && media.size > 0) ? (
                                <MediaCarousel className="recently-viewed-carousel"
                                    key="recently_viewed"
                                    title={AppStore.translate('view.recently_viewed_carousel.title')}
                                    media={media}
                                    tileType="backdrop-progress-bar"
                                    navigationType="side-arrows"
                                    mediaLoading={mediaBucket && mediaBucket.updatePending} />
                            ) : null;
                        }

                        // genres
                        if (pageRow.type === PageRowType.GENRE) {
                            let genre = pageRow.value;
                            if (genre) {
                                let mediaBucket = this.state.mediaBucketHash[genre.name];
                                let media = mediaBucket ? mediaBucket.media() : null;
                                return (mediaBucket && mediaBucket.updatePending) || (media && media.size > 0) ? (
                                    <MediaCarouselTally key={genre.name}
                                        title={genre.name}
                                        linkTo={Router.GENRES(genre.id)}
                                        media={media}
                                        mediaTotalCount={mediaBucket ? mediaBucket.mediaTotalCount : null}
                                        navigationType="side-arrows-in-elipse"
                                        mediaLoading={mediaBucket && mediaBucket.updatePending} />
                                ) : null;
                            }
                        }

                        // collection promotion
                        if (pageRow.type === PageRowType.PROMOTION && pageRow.value.indexOf('COLLECTIONS') > -1) {
                            let promotion = AppStore.promotionListByName.get(pageRow.value);
                            if (promotion) {
                                let collectionList = promotion.collectionItems.reduce((collectionList, promotionItem) => {
                                    let collection = AppStore.collectionListByTitle.get(promotionItem.promotionalLink);
                                    return collection ? collectionList.set(collection.id, collection) : collectionList;
                                }, Immutable.OrderedMap());
                                return collectionList.size > 0 ? (
                                    <CollectionCarouselTally key={promotion.id}
                                        title={promotion.title}
                                        linkTo={Router.PROMOTIONS(promotion.name)}
                                        collectionList={collectionList}
                                        tileType="backdrop"
                                        navigationType="side-arrows-in-elipse"
                                    />
                                ) : null;
                            }
                        }

                        // collection promotion - SPECIAL_OFFERS
                        if (pageRow.type === PageRowType.PROMOTION && pageRow.value.indexOf('SPECIAL_OFFERS_') > -1) {
                            let promotion = AppStore.promotionListByName.get(pageRow.value);
                            if (promotion) {
                                let collectionList = promotion.collectionItems.reduce((collectionList, promotionItem) => {
                                    let collection = AppStore.collectionListByTitle.get(promotionItem.promotionalLink);
                                    return collection ? collectionList.set(collection.id, collection) : collectionList;
                                }, Immutable.OrderedMap());
                                return collectionList.size > 0 ? (
                                    <CollectionCarouselTally key={promotion.id}
                                        title={promotion.title}
                                        linkTo={Router.PROMOTION_COLLECTIONS(promotion.name)}
                                        collectionList={collectionList}
                                        tileType="backdrop"
                                        navigationType="side-arrows-in-elipse"
                                    />
                                ) : null;
                            }
                        }

                        // featured mediaItems promotion
                        if (pageRow.type === PageRowType.PROMOTION && pageRow.value.indexOf('RECOMMENDATIONS_') > -1) {
                            let promotion = AppStore.promotionListByName.get(pageRow.value);
                            if (promotion) {
                                let mediaBucket = this.state.mediaBucketHash[pageRow.value];
                                let media = mediaBucket ? mediaBucket.media() : null;
                                return media && media.size > 0 ? (
                                    <MediaCarouselTally key={pageRow.value}
                                        title={promotion.title}
                                        linkTo={Router.PROMOTIONS(pageRow.value)}
                                        media={media}
                                        mediaTotalCount={mediaBucket ? mediaBucket.mediaTotalCount : null}
                                        navigationType="side-arrows-in-elipse"
                                        mediaLoading={mediaBucket && mediaBucket.updatePending} />
                                ) : null;
                            }
                        }
                    })
                }

                {this._storefrontPageRows.length > 0 ? (
                    <div className="container">
                        <div className="load-more-wrapper">
                            {(this._storefrontPageRows.length > this.state.pageRowsLoaded) && (
                                <Button shape="regular"
                                    className="cta-play full-width red-bg"
                                    onClick={this._handleLoadMore}
                                    updatePending={this.state.updatePending}>
                                    {AppStore.translate('button.load_more')}
                                </Button>
                            )}
                            <span className="back-to-top-button" onClick={this._handleScrollToTop}><i
                                className="ui-icon icon-mp_vrg_up_arrow" />back to top</span>
                        </div>
                    </div>
                ) : null}
            </main>
        );
    }

    /**
     * Loads media for given page rows
     */
    _getMediaForPageRows = (pageRows) => {
        if (pageRows) {
            pageRows.forEach(pageRow => {
                // featured mediaItems
                if (pageRow.type === PageRowType.PROMOTION
                    && (pageRow.value.indexOf('FEATURED_') > -1 || pageRow.value.indexOf('RECOMMENDATIONS_') > -1)) {
                    let promotion = AppStore.promotionListByName.get(pageRow.value);
                    if (promotion) {
                        this._mediaBucketKeyHash[pageRow.value] = MediaActions.getPromotionMedia({
                            mediaItemIds: promotion.mediaItemItems.map(promotionItem => promotionItem.promotionalLink)
                        });
                    }
                }

                // collections
                if (pageRow.type === PageRowType.COLLECTION) {
                    let collection = AppStore.collectionListByTitle.get(pageRow.value);
                    if (collection) {
                        this._mediaBucketKeyHash[pageRow.value] = MediaActions.getCollectionMedia({
                            collectionTitle: collection.title
                        });
                    }
                }

                // recently viewed
                if (pageRow.type === PageRowType.RECENTLY_VIEWED && AuthStore.isSignedIn()) {
                    this._mediaBucketKeyHash[pageRow.value] = MediaActions.getRecentlyViewedMedia();
                }

                // genres
                if (pageRow.type === PageRowType.GENRE) {
                    let genre = pageRow.value;
                    if (genre) {
                        this._mediaBucketKeyHash[genre.name] = MediaActions.getGenreMedia({
                            genreId: genre.id,
                            limit: + Config.STOREFRONT_GENRE_PAGE_ROW_MEDIA_LIMIT,
                            page: 0
                        });
                    }
                }
            });
        }
    };

    /**
     * Set mediaBucket
     */
    _setMediaBucket = () => {
        this.setState({
            mediaBucketHash: Object.keys(this._mediaBucketKeyHash).reduce((mediaBucketHash, key) => {
                mediaBucketHash[key] = MediaStore.getMediaBucket(this._mediaBucketKeyHash[key]);
                return mediaBucketHash;
            }, {})
        });
    };

    /**
     * handle Load more click
     */
    _handleLoadMore = () => {
        this._getMediaForPageRows(
            this._storefrontPageRows.slice(this.state.pageRowsLoaded, this.state.pageRowsLoaded + Config.STOREFRONT_PAGE_ROWS_BATCH_COUNT
            ));
        this.setState({
            pageRowsLoaded: this.state.pageRowsLoaded + Config.STOREFRONT_PAGE_ROWS_BATCH_COUNT
        });
    };

    /**
     * handle Load more click
     */
    _handleScrollToTop = () => {
        window.scroll({top: 0, left: 0, behavior: 'smooth'});
    };

    /**
     * Consume stb link code & get registered devices
     */
    _consumeStbCodeAndGetRegisteredDevices = () => {
        const code = EntitlementStore.stbPairingData.get('stbCode');
        EntitlementActions.consumeStbLinkCode({consumeStbLinkCode: code});
        EntitlementActions.getRegisteredDevices({
            includeInactiveDevices: false
        });
    };

    /**
     * register new Stb device if user is coming from stb pairing page
     * @returns {*}
     */
    _registerNewStbDevice = () => {
        const stbPairingData = EntitlementStore.stbPairingData.toJS();
        const ccAdded = AuthStore.customerPaymentDetails !== null && AuthStore.customerPaymentDetails.get("defaultCard") !== null;
        const newDeviceRegisteredSuccess = EntitlementStore.newDeviceRegisteredSuccess;
        if (newDeviceRegisteredSuccess) {
            this._confirmStbRegistration(stbPairingData.deviceID, true, ccAdded);
            EntitlementStore.removeUpdateListener(this._registerNewStbDevice);
            return;
        }
        if (stbPairingData.validated && stbPairingData.consumed && !newDeviceRegisteredSuccess) {
            let registeredDevices = [];
            if (EntitlementStore.registeredDevices) {
                registeredDevices = EntitlementStore.registeredDevices.toArray();
            }
            const deviceIsRegistered = this._checkForRegisteredDevices(stbPairingData, registeredDevices, ccAdded);

            if (deviceIsRegistered) return;

            if (registeredDevices.length > 4) {
                this._confirmStbRegistration(stbPairingData.deviceID, false, ccAdded);
                this._displayDeviceLimitErrorPopup();
                EntitlementStore.removeUpdateListener(this._registerNewStbDevice);
                return;
            }
            setTimeout(() => {
                EntitlementActions.registerDevice({
                    deviceTypeDescription: "Tivo",
                    deviceName: stbPairingData.deviceFriendlyName,
                    deviceId: stbPairingData.deviceID
                });
            }, 0);
            return;
        }
    };

    /**
     * check if device is already registered
     * @param {Object} stbPairingData
     * @param {Array<Object>} registeredDevices
     * @returns {Boolean}
     */
    _checkForRegisteredDevices = (stbPairingData, registeredDevices, ccAdded) => {
        const tivoSerialNo = stbPairingData.deviceFriendlyName.match(/\_(.*)\_/).pop();
        let deviceRegistered = false;

        registeredDevices.map(item => {
            const device = item.toJS();
            if (device.deviceName.includes(tivoSerialNo) && !deviceRegistered) {
                deviceRegistered = true;
                this._confirmStbRegistration(stbPairingData.deviceID, true, ccAdded, device.deviceId);
                EntitlementStore.removeUpdateListener(this._registerNewStbDevice);
            }
        });
        return deviceRegistered;
    };

    /**
     * confirm new stb device registration
     *@param {String} deviceID
     *@param {Boolean} registered
     *@param {Boolean} isCardAdded
     *@param {String} updatedDeviceID
     @returns {*}
     */
    _confirmStbRegistration = (deviceID, registered, isCardAdded, updatedDeviceID) => {
        setTimeout(() => {
            EntitlementActions.confirmStbRegistration({
                device: deviceID,
                registered: registered,
                isCardAdded: isCardAdded,
                ...(updatedDeviceID && {updatedDeviceID: updatedDeviceID})
            });
        }, 0);

        if (!registered) {
            setTimeout(() => {EntitlementActions.stbPairingProcessFinished();}, 0);
            return;
        }
        this.setState({creditCardAdded: isCardAdded});
        EntitlementStore.addUpdateListener(this._displayPairingProcessInfoMessage);
    };

    /**
     * Stb pairing process info message
     */
    _displayPairingProcessInfoMessage = () => {
        const message = EntitlementStore.stbDeviceRegistrationStatus;

        if (message.error) {
            this._displayPairingErrorNotification();
            setTimeout(() => {EntitlementActions.stbPairingProcessFinished();}, 0);
            EntitlementStore.removeUpdateListener(this._displayPairingProcessInfoMessage);
            return;
        }

        if (message.stbPairingProcessSuccess) {
            setTimeout(() => {
                this._showCustomOverlayPopup(message);
                setTimeout(() => {EntitlementActions.stbPairingProcessFinished();}, 0);
                EntitlementStore.removeUpdateListener(this._displayPairingProcessInfoMessage);
                return;
            }, 0);
        }
    };

    /**
     * close overlay and redirect to my account details page
     */
    _closePopupAndRedirectToMyAccountDetailsPage = () => {
        UIActions.closeCustomOverlay();
        setTimeout(() => {
            this.context.router.push(Router.UPDATE_ACCOUNT);
        }, 0);
    };

    /**
     * display notification in case of error
     */
    _displayDeviceLimitErrorPopup = () => {
        setTimeout(() => {
            UIActions.showCustomOverlay(
                (<div className="alert-box-size col-xs-12 col-sm-8 col-md-6">
                    <h2 className="overlay-header stb-pairing-popup-box-header">
                        {AppStore.translate('view.cta_action_modal.title.finish_payment_setup')}
                        <button className="close" onClick={this._closeOverlay} />
                    </h2>
                    <div className="overlay-content">
                        <p>{AppStore.translate('view.stb-pairing-device-limit-reached')}</p>
                    </div>
                </div>), false);
        }, 0);
    };

    /**
    * Close overlay
    */
    _closeOverlay = () => {
        UIActions.closeCustomOverlay();
    };

    /**
     * display pairing error notification
     */
    _displayPairingErrorNotification = () => {
        setTimeout(() => {
            NotificationActions.displayNotification({
                notificationType: 'error',
                content: AppStore.translate('view.stb-pairing-error')
            });
        }, 0);
    }

    /**
     * show custom overlay stb pairing popup
     */
    _showCustomOverlayPopup = (message) => {
        return UIActions.showCustomOverlay(
            (<div className="alert-box-size col-xs-12 col-sm-8 col-md-6">
                <h2 className="overlay-header stb-pairing-popup-box-header">
                    {message.fromProductPurchase
                        ? AppStore.translate('stb-pairing-popup-success-productpurchase-header')
                        : AppStore.translate('view.stb-pairing-popup-success-message-header')
                    }
                    <button className="close" onClick={this._closePopupAndRedirectToMyAccountDetailsPage} />
                </h2>
                <div className="overlay-content stb-pairing-overlay-content">
                    <div>
                        {message.fromProductPurchase && this.state.creditCardAdded &&
                            (<div>
                                {AppStore.translate('stb-pairing-popup-success-productpurchase-msg-1')}
                                <p />
                                {AppStore.translate('stb-pairing-popup-success-productpurchase-msg-2')}
                            </div>)
                        }
                        {message.fromProductPurchase && !this.state.creditCardAdded &&
                            (<div>
                                {AppStore.translate('stb-pairing-cc-not-added-msg')}
                            </div>)
                        }
                        {!message.fromProductPurchase &&
                            AppStore.translate('view.stb-pairing-popup-success-message')
                        }
                    </div>
                    {!message.fromProductPurchase || (message.fromProductPurchase && !this.state.creditCardAdded)
                        ? <Link to={Router.UPDATE_ACCOUNT}>
                            <div className="stb-pairing-popup-box-link">
                                {AppStore.translate('view.stb-pairing-popup-success-message-link')}
                            </div>
                        </Link>
                        : null
                    }
                </div>
            </div>), false
        );
    };

    _displayWatchlistItemPopup = () => {
        if (WatchlistStore.preOrderedMediaItemAlerts.size > 0) {
            setTimeout(() => {
                UIActions.showCustomOverlay({
                    overlayId: OverlayIds.PREORDERED_ITEM,
                });
            }, 0)
        }
    };
}
