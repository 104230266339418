"use strict";

import moment from 'moment';
import store from 'store';
import {waitFor} from '../dispatchers/AppDispatcher';
import {UserIdentity, UserIdentityStatusType} from '../types/UserIdentity';
import UserPreferences from '../types/UserPreferences';
import UserProfile from '../types/UserProfile';
import CustomerPaymentDetails from '../types/CustomerPaymentDetails';
import ActionTypes from '../actions/ActionTypes';
import BaseStore from './BaseStore';
import AppStore from './AppStore';
import WatchlistStore from './WatchlistStore';
import PurchaseStore from './PurchaseStore';
import EntitlementStore from './EntitlementStore';
import MediaStore from './MediaStore';

/**
 * Flux: AuthStore
 */
class AuthStore extends BaseStore {
    /**
     * Customer payment details
     *
     * type {?CustomerPaymentDetails} _customerPaymentDetails
     * @private
     */
    _customerPaymentDetails = null;

    /**
     * Constructor
     */
    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this));
        this._identity = null;
        this._userProfile = null;
        this._userPreferences = null;
        this._profileUpdateSuccess = false;
        this._error = null;
        this._updatePending = false;
        this._profileUpdatePending = false;

        // load identity from local storage
        if (!store.get("identity")) {
            store.set("identity", "");
        }
        this._loadLocalStorageIdentity();
        window.addEventListener('storage', this._refreshLocalStorageIdentity);
    }

    /**
     * Flux: register store to actions
     *
     * @param {Object} action
     * @private
     */
    _registerToActions(action) {
        let userIdentityAffiliateStatus = null;

        switch (action.type) {
            // generic request actions
            case ActionTypes.REQUEST_SIGN_UP_USER:
            case ActionTypes.REQUEST_SIGN_UP_USER_THEN_AUTO_SIGN_IN:
            case ActionTypes.REQUEST_SIGN_IN_USER:
            case ActionTypes.REQUEST_GET_USER_PROFILE:
            case ActionTypes.REQUEST_UPDATE_USER_PROFILE:
            case ActionTypes.REQUEST_GET_USER_PREFERENCES:
            case ActionTypes.REQUEST_UPDATE_USER_PREFERENCES:
            case ActionTypes.REQUEST_RESEND_ACTIVATION_EMAIL:
            case ActionTypes.REQUEST_ACTIVATE_USER:
            case ActionTypes.REQUEST_CHANGE_USER_PASSWORD:
            case ActionTypes.REQUEST_SEND_RESET_USER_PASSWORD_EMAIL:
            case ActionTypes.REQUEST_RESET_USER_PASSWORD:
            case ActionTypes.REQUEST_ACCEPT_TERMS_AND_CONDITIONS:
            case ActionTypes.REQUEST_ADD_PAYMENT_METHOD:
            case ActionTypes.REQUEST_UPDATE_PAYMENT_METHOD:
                this._updatePending = true;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_UPDATE_USER_PROFILE_AND_PASSWORD:
                this._updatePending = true;
                this._profileUpdatePending = true;
                this._error = null;
                this.emitUpdate();
                break;

            // sign up user
            // user sign up with auto sign in (error)
            case ActionTypes.REQUEST_SIGN_UP_USER_SUCCESS:
                this._updatePending = false;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_SIGN_UP_USER_ERROR:
            case ActionTypes.REQUEST_SIGN_UP_USER_THEN_AUTO_SIGN_IN_ERROR:
                this._updatePending = false;
                this._error = action.error;
                this.emitUpdate();
                break;

            // sign in user
            case ActionTypes.REQUEST_SIGN_IN_USER_SUCCESS:
                userIdentityAffiliateStatus = UserIdentityStatusType.getValue(action.userIdentityDTO && action.userIdentityDTO.AffiliateStatus, 'statusCodeAffiliate');
                if (userIdentityAffiliateStatus === UserIdentityStatusType.STATUS_ACTIVE
                    || userIdentityAffiliateStatus === UserIdentityStatusType.STATUS_UNKNOWN) {
                    this._identity = new UserIdentity({}).fromDTO(action.userIdentityDTO);
                    store.set("identity", JSON.stringify(this._identity.toJS()));
                    store.set("purchase_path", "");
                    store.set("purchase_started", "");
                }
                this._updatePending = false;
                this._error = null;
                this.emitUpdate();
                break;

            // user sign up with auto sign in (success)
            case ActionTypes.REQUEST_SIGN_UP_USER_THEN_AUTO_SIGN_IN_SUCCESS:
                userIdentityAffiliateStatus = UserIdentityStatusType.getValue(action.userIdentityDTO.AffiliateStatus, 'statusCodeAffiliate');
                if (userIdentityAffiliateStatus === UserIdentityStatusType.STATUS_ACTIVE
                    || userIdentityAffiliateStatus === UserIdentityStatusType.STATUS_UNKNOWN) {
                    this._identity = new UserIdentity({}).fromDTO(action.userIdentityDTO);
                    store.set("identity", JSON.stringify(this._identity.toJS()));
                    store.set("purchase_path", "");
                    store.set("purchase_started", "");
                    store.set("payment_skip", "");
                }
                this._updatePending = false;
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_SIGN_IN_USER_ERROR:
                this._updatePending = false;
                this._error = action.error;
                this.emitUpdate();
                break;

            // accept terms and conditions
            case ActionTypes.REQUEST_ACCEPT_TERMS_AND_CONDITIONS_SUCCESS:
                this._identity = new UserIdentity({
                    ...this._identity.toJS(),
                    termsAndConditionsAccepted: true
                });
                store.set("identity", JSON.stringify(this._identity.toJS()));
                this._updatePending = false;
                this._error = null;
                this.emitUpdate();
                break;

            // get user profile information
            case ActionTypes.REQUEST_GET_USER_PROFILE_SUCCESS:
                this._userProfile = new UserProfile({}).fromDTO(action.userProfileDTO);
                this._updatePending = false;
                this._error = null;
                this.emitUpdate();
                break;

            // update user profile
            // update user profile and password
            case ActionTypes.REQUEST_UPDATE_USER_PROFILE_SUCCESS:
            case ActionTypes.REQUEST_UPDATE_USER_PROFILE_AND_PASSWORD_SUCCESS:
                if (action.userProfileDTO) {
                    this._userProfile = new UserProfile({}).fromDTO(action.userProfileDTO);
                }
                if (action.userIdentityDTO) {
                    this._identity = new UserIdentity({}).fromDTO(action.userIdentityDTO);
                    store.set("identity", JSON.stringify(this._identity.toJS()));
                }
                this._updatePending = false;
                this._profileUpdatePending = false;
                this._error = null;
                this.emitUpdate();
                break;

            // update user profile and password
            case ActionTypes.REQUEST_GET_USER_PREFERENCES_SUCCESS:
                this._userPreferences = new UserPreferences({}).fromDTO(action.userPreferencesDTO);
                this._updatePending = false;
                this._error = null;
                this.emitUpdate();
                break;

            // update user profile and password
            case ActionTypes.REQUEST_UPDATE_USER_PREFERENCES_SUCCESS:
                this._updatePending = false;
                this._error = null;
                this.emitUpdate();
                break;

            // get customer payment details
            case ActionTypes.REQUEST_GET_CUSTOMER_PAYMENT_DETAILS:
                this._error = null;
                break;

            case ActionTypes.REQUEST_GET_CUSTOMER_PAYMENT_DETAILS_SUCCESS:
                this._customerPaymentDetails = new CustomerPaymentDetails({}).fromDTO(action.customerPaymentDetailsDTO);
                this._error = null;
                this.emitUpdate();
                break;

            case ActionTypes.REQUEST_GET_CUSTOMER_PAYMENT_DETAILS_ERROR:
                this._error = action.error;
                this.emitUpdate();
                break;

            // waited for by PurchaseStore: fast checkout media item offer
            // waited for by PurchaseStore: fast checkout bundle offer
            case ActionTypes.REQUEST_FAST_CHECKOUT_MEDIA_ITEM_OFFER_SUCCESS:
            case ActionTypes.REQUEST_FAST_CHECKOUT_BUNDLE_OFFER_SUCCESS:
                this._customerPaymentDetails = action.customerPaymentDetailsDTO ? new CustomerPaymentDetails({}).fromDTO(action.customerPaymentDetailsDTO) : null;
                this.emitUpdate();
                break;

            // sign out user
            case ActionTypes.SIGN_OUT_USER:
                waitFor([AppStore.dispatchToken]);
                waitFor([WatchlistStore.dispatchToken]);
                waitFor([PurchaseStore.dispatchToken]);
                waitFor([EntitlementStore.dispatchToken]);
                waitFor([MediaStore.dispatchToken]);
                this._identity = new UserIdentity({});
                this._userProfile = null;
                store.set("identity", "");
                store.set("purchase_path", "");
                store.set("purchase_started", "");
                this._error = null;
                this.emitUpdate();
                break;

            // add payment method to user account
            // update payment method to user account
            case ActionTypes.REQUEST_ADD_PAYMENT_METHOD_SUCCESS:
            case ActionTypes.REQUEST_UPDATE_PAYMENT_METHOD_SUCCESS:
                this._customerPaymentDetails = action.customerPaymentDetailsDTO ? new CustomerPaymentDetails({}).fromDTO(action.customerPaymentDetailsDTO) : null;
                store.remove("payment_skip");
                this._userProfile = new UserProfile({}).fromDTO(action.userProfileDTO);
                this._profileUpdateSuccess = true;
                this._updatePending = false;
                this._error = null;
                this.emitUpdate();
                break;

            // generic success actions
            case ActionTypes.REQUEST_RESEND_ACTIVATION_EMAIL_SUCCESS:
            case ActionTypes.REQUEST_ACTIVATE_USER_SUCCESS:
            case ActionTypes.REQUEST_CHANGE_USER_PASSWORD_SUCCESS:
            case ActionTypes.REQUEST_SEND_RESET_USER_PASSWORD_EMAIL_SUCCESS:
            case ActionTypes.REQUEST_RESET_USER_PASSWORD_SUCCESS:
                this._updatePending = false;
                this._error = null;
                this.emitUpdate();
                break;

            // generic error actions
            case ActionTypes.REQUEST_GET_USER_PROFILE_ERROR:
            case ActionTypes.REQUEST_UPDATE_USER_PROFILE_ERROR:
            case ActionTypes.REQUEST_GET_USER_PREFERENCES_ERROR:
            case ActionTypes.REQUEST_UPDATE_USER_PREFERENCES_ERROR:
            case ActionTypes.REQUEST_UPDATE_USER_PROFILE_AND_PASSWORD_ERROR:
            case ActionTypes.REQUEST_RESEND_ACTIVATION_EMAIL_ERROR:
            case ActionTypes.REQUEST_ACTIVATE_USER_ERROR:
            case ActionTypes.REQUEST_CHANGE_USER_PASSWORD_ERROR:
            case ActionTypes.REQUEST_SEND_RESET_USER_PASSWORD_EMAIL_ERROR:
            case ActionTypes.REQUEST_RESET_USER_PASSWORD_ERROR:
            case ActionTypes.REQUEST_ACCEPT_TERMS_AND_CONDITIONS_ERROR:
            case ActionTypes.REQUEST_ADD_PAYMENT_METHOD_ERROR:
            case ActionTypes.REQUEST_UPDATE_PAYMENT_METHOD_ERROR:
                console.log(this, 'this');
                this._updatePending = false;
                this._profileUpdatePending = false;
                this._profileUpdateSuccess = false;
                this._error = action.error;
                this.emitUpdate();
                break;

            // default (no-op)
            default:
                break;
        }
    }

    /**
     * Load user identity from local storage
     *
     * @private
     */
    _loadLocalStorageIdentity() {
        console.info('** [auth] loading identity ');
        let identity = store.get("identity");
        if (identity) {
            this._identity = new UserIdentity(JSON.parse(identity));
            console.info("** [auth] identity loaded (user)");
            return;
        }

        this._identity = new UserIdentity({});
        console.info("** [auth] identity loaded (anonymous)");
    }

    /**
     * Refresh user identity from local storage
     *
     * @private
     */
    _refreshLocalStorageIdentity = () => {
        if (window.document.hasFocus()) return;

        let identity = store.get("identity");
        identity = identity ? JSON.parse(identity) : {username: null};
        if (this._identity.username !== identity.username) {
            this._identity = new UserIdentity(identity);
            console.info(`** [auth] identity re-loaded (${identity.username ? 'user' : 'anonymous'})`);
            this.emitUpdate();
        }
    };

    /**
     * Get identity
     *
     * @returns {null|*}
     */
    get identity() {
        return this._identity;
    }

    /**
     * Get user profile
     *
     * @returns {null|*}
     */
    get userProfile() {
        return this._userProfile;
    }

    /**
     * Get user preferences
     *
     * @returns {null|*}
     */
    get userPreferences() {
        return this._userPreferences;
    }

    /**
     * Get _customerPaymentDetails
     *
     * @returns {CustomerPaymentDetails}
     */
    get customerPaymentDetails() {
        return this._customerPaymentDetails;
    }

    /**
     * Get error
     *
     * @returns {null|*}
     */
    get error() {
        return this._error;
    }

    /**
     * Get _updatePending
     *
     * @returns {boolean}
     */
    get updatePending() {
        return this._updatePending;
    }

    /**
     * Get _profileUpdatePending
     *
     * @returns {boolean}
     */
    get profileUpdatePending() {
        return this._profileUpdatePending;
    }

    /**
     * Check if user is signed in
     *
     * @returns {boolean}
     */
    isSignedIn() {
        return !!this._identity && this._identity.tokenExpiration > moment().unix();
    }

    /**
     * Check if token is valid
     *
     * @returns {boolean}
     */
    validateToken() {
        if (this._identity && this._identity.tokenExpiration < moment().unix()) {
            this._identity = new UserIdentity({});
            store.set("identity", "");
            this._error = null;
            this.emitUpdate();
        }
    }
}

export default new AuthStore();
