"use strict";

import * as Request from './Request';
import ApiEndpoints from './ApiEndpoints';
import Config from '../Config';

/**
 * Get platform app settings
 *
 * @return {*}
 */
export function getAppSettings() {
    return Request.getRequestPromise({
        url: ApiEndpoints.GET_APP_SETTINGS,
        method: 'GET',
        qs: {
            v: 3
        },
        headers: {
            'Authorization': 'Bearer ' + Config.TRUSTED_APPLICATION_TOKEN
        }
    });
}

/**
 * Get collection list
 *
 * @return {*}
 */
export function getCollectionList() {
    return Request.getRequestPromise({
        url: ApiEndpoints.GET_COLLECTION_LIST,
        method: 'GET',
        qs: {
            v: 3
        }
    });
}

/**
 * Get promotion list
 *
 * @return {*}
 */
export function getPromotionList() {
    return Request.getRequestPromise({
        url: ApiEndpoints.GET_PROMOTION_LIST,
        method: 'GET',
        qs: {
            v: 3,
            appSettingGroup: Config.APP_SETTINGS_GROUP
        },
        headers: {
            'Authorization': 'Bearer ' + Config.TRUSTED_APPLICATION_TOKEN
        }
    });
}

/**
 * Get translations
 *
 * @return {*}
 */
export function getTranslations() {
    return Request.getRequestPromise({
        url: ApiEndpoints.GET_TRANSLATIONS,
        method: 'GET'
    }).then(body => ({translations: body.Data}));
}

/**
 * Get genre list
 *
 * @return {*}
 */
export function getGenreList() {
    return Request.getRequestPromise({
        url: ApiEndpoints.GET_GENRE_LIST,
        method: 'GET'
    }).then((body) => {
        return body;
    });
}

/**
 * Get genre icons
 *
 * @return {*}
 */
export function getGenreIcons() {
    return Request.getRequestPromise({
        url: ApiEndpoints.GET_GENRE_ICONS,
        method: 'GET'
    }).then((body) => {
        return body.Data;
    });
}

/**
 * Get certificate list
 *
 * @return {*}
 */
export function getCertificateList() {
    return Request.getRequestPromise({
        url: ApiEndpoints.GET_CERTIFICATE_LIST,
        method: 'GET'
    }).then((body) => {
        return body.Data;
    }).then(certificateDTOCollection => certificateDTOCollection.sort((firstCertificateDTO, secondCertificateDTO) => {
        if (firstCertificateDTO.MinRating > secondCertificateDTO.MinRating) {
            return 1;
        } else if (firstCertificateDTO.MinRating < secondCertificateDTO.MinRating) {
            return -1;
        } else {
            return 0;
        }
    }));
}

/**
 * Get platform help
 *
 * @return {*}
 */
export function getPlatformHelp() {
    return Request.getRequestPromise({
        url: ApiEndpoints.GET_PLATFORM_HELP,
        method: 'GET',
        qs: {
            v: 3,
            appSettingGroup: Config.APP_SETTINGS_GROUP
        }
    });
}

/**
 * Get platform terms and conditions
 *
 * @return {*}
 */
export function getPlatformTermsAndConditions() {
    return Request.getRequestPromise({
        url: ApiEndpoints.GET_PLATFORM_TERMS_AND_CONDITIONS,
        method: 'GET',
        qs: {
            v: 3,
            appSettingGroup: Config.APP_SETTINGS_GROUP
        }
    });
}

/**
 * Get alerts
 *
 * @param {Object} params
 * @return {*}
 */
export function getAlerts(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.GET_ALERTS(params.alertType),
        method: 'GET',
        qs: {
            v: 3
        }
    });
}

/**
 * Cancel alert
 *
 * @param {Object} params
 * @return {*}
 */
export function cancelAlert(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.CANCEL_ALERT(params.alertId),
        method: 'PUT',
        qs: {
            v: 3
        }
    });
}

/**
 * Increment alert display count
 *
 * @param {Object} params
 * @return {*}
 */
export function incrementAlertDisplayCount(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.INCREMENT_ALERT_DISPLAY_COUNT(params.alertId),
        method: 'PUT',
        qs: {
            v: 3
        }
    });
}

/**
 * Lookup address by postcode
 *
 * @return {*}
 */
export function lookupAddressByPostcode(params = {}) {
    return Request.getRequestPromise({
        url: ApiEndpoints.LOOKUP_ADDRESS_BY_POSTCODE(params.postCode),
        method: 'GET',
        qs: {
            'api-key': Config.GET_ADDRESS_IO_API_KEY
        }
    });
}
