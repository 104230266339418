/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import OverlayIds from '../lib/OverlayIds';
import AppStore from '../stores/AppStore';
import EntitlementStore from '../stores/EntitlementStore';
import AuthActions from '../actions/AuthActions';
import UIActions from '../actions/UIActions';
import NotificationActions from '../actions/NotificationActions';
import AuthStore from '../stores/AuthStore';
import Button from './ui/Button.react';
import Spinner from './ui/Spinner2.react';
import ToggleButton from './ui/ToggleButton.react';
import IconButtonWithBubblePop from './ui/IconButtonWithBubblePop.react';

/**
 * AccountUpdatePage component
 */
export default class AccountUpdatePage extends React.Component {
    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object,
    };

    /**
     * React: state
     */
    state = {
        updatePending: false,
        // Sign In details form
        profileEditable: false,
        fullName: AuthStore.userProfile.firstName,
        oldPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
        passwordFieldsValuesMatch: true,
        profileUpdateDisable: true,
        showPassword: false,
        oldPasswordIsStrong: true,
        passwordIsStrong: true,
        gdprButtonUpdatePending: false,
        // payment method
        customerPaymentDetails: null,
        // contact preferences
        userPreferencesEditable: false,
        newsletterOptedIn: AuthStore.userPreferences.commsAffiliateEmail,
        newsletterUpdate: false,
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        const userProfile = AuthStore.userProfile;
        AuthStore.addUpdateListener(this.setCustomerPaymentDetails);
        if (userProfile.paymentProviderCustomerIds && userProfile.paymentProviderCustomerIds.stripe) {
            AuthActions.getCustomerPaymentDetails();
        }
        if (EntitlementStore.stbPairingData.size > 0) {
            this.autoDispatchSetPaymentOverlay();
        }

        document.body.className += ' black-background';
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AuthStore.removeUpdateListener(this.setCustomerPaymentDetails);
        this.setState({
            updatePending: false,
            customerPaymentDetails: null,
        });

        document.body.className = document.body.className.replace(/black-background/, '');
    }

    /**
     * Set customer payment details
     *
     * @private
     */
    setCustomerPaymentDetails = () => {
        if (AuthStore.customerPaymentDetails !== null) {
            this.setState({
                customerPaymentDetails: AuthStore.customerPaymentDetails,
            });
        }
    };

    /**
     * Enable profile editing
     *
     * @private
     */
    enableProfileEditing = () => {
        this.setState({
            oldPassword: '',
            newPassword: '',
            newPasswordConfirmation: '',
            profileEditable: true,
        });
    };

    /**
     * Disable profile editing
     *
     * @private
     */
    disableProfileEditing = () => {
        this.setState({
            fullName: AuthStore.userProfile.firstName,
            profileEditable: false,
        });
    };

    /**
     * update user preferences
     *
     * @private
     */
    updatePreferences = () => {
        AuthActions.updateUserPreferences({
            newsletterOptedIn: this.state.newsletterOptedIn,
            authToken: AuthStore.identity.authToken,

        });
        this.setState({
            userPreferencesEditable: false,
            newsletterUpdate: false,
        });
        // temporary
        setTimeout(() => {
            this.setState({gdprButtonUpdatePending: false});
            AuthActions.getUserPreferences({
                authToken: AuthStore.identity.authToken,
            });
        }, 2000);
    };

    /**
     * Toggle newsletter opt in and prepare for cancel or update
     *
     * @private
     */
    prepareForUpdateNewsletterOptedIn = () => {
        this.setState(prevState => ({
            newsletterUpdate: !prevState.newsletterUpdate,
            newsletterOptedIn: !prevState.newsletterOptedIn,
            gdprButtonUpdatePending: true,
        }), () => {
            this.updatePreferences();
        });
    };

    /**
     * On field change
     *
     * @param {string} fieldName
     * @param {Object} event
     * @private
     */
    onFieldChange = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    /**
     * auto dispatch payment overlay
     */
    autoDispatchSetPaymentOverlay = () => {
        setTimeout(this.openPaymentMethodOverlay, 1000);
    };

    /**
     * On field change
     *
     * @param {string} fieldName
     * @private
     */
    onFocusChange = e => {
        if (e.target.name === 'newPassword') {
            this.state.newPasswordConfirmation !== '' && this.setState(prevState => ({
                passwordFieldsValuesMatch: prevState.newPassword === prevState.newPasswordConfirmation,
            }));
            this.checkFieldValidity(e.target.name, this.state.newPassword);
        }
        if (e.target.name === 'newPasswordConfirmation') {
            this.setState(prevState => ({
                passwordFieldsValuesMatch: prevState.newPassword === prevState.newPasswordConfirmation,
                profileUpdateDisable: !(prevState.newPassword === prevState.newPasswordConfirmation),
            }));
        }
        if (e.target.name === 'oldPassword') {
            this.checkFieldValidity(e.target.name, this.state.oldPassword);
        }
    };

    /**
     * Update user profile and password
     *
     * @param {Object} event
     * @private
     */
    updateUserProfileAndPassword = event => {
        event.preventDefault();
        if (!this.state.fullName) {
            NotificationActions.displayNotification({
                notificationType: 'error',
                content: AppStore.translate('notification.account.error.profile_form_incomplete'),
            });
            return;
        }

        if ((this.state.oldPassword !== '' && (!this.state.newPassword || !this.state.newPasswordConfirmation))
            || (!this.state.oldPassword && (this.state.newPassword !== '' || this.state.newPasswordConfirmation !== ''))) {
            NotificationActions.displayNotification({
                notificationType: 'error',
                content: AppStore.translate('notification.account.error.password_form_incomplete'),
            });
            return;
        }

        if (this.state.newPassword !== this.state.newPasswordConfirmation) {
            NotificationActions.displayNotification({
                notificationType: 'error',
                content: AppStore.translate('notification.account.error.password_mismatch'),
            });
            return;
        }

        NotificationActions.hideNotification();
        AuthActions.updateUserProfileAndPassword({
            firstName: this.state.fullName,
            oldPassword: this.state.oldPassword,
            newPassword: this.state.newPassword,
            username: AuthStore.identity.username, // if password change is successful we will auto sign-in again
            password: this.state.newPassword,
        });
    };

    /**
     * Open payment method overlay
     *
     * @private
     */
    openPaymentMethodOverlay = () => {
        UIActions.showCustomOverlay({
            overlayId: OverlayIds.PAYMENT_METHOD,
        });
    };

    /**
     * Show hide password
     *
     * @private
     */
    showHidePassword = () => {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword,
        }));
    };

    /**
     * On field change
     *
     * @param {string} fieldName
     * @param {string} value
     * @private
     */
    checkFieldValidity = (fieldName, value) => {
        if (fieldName === 'newPassword') {
            const regExPasswordRule = /^(?=.*?[A-Z+])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
            this.setState({
                passwordIsStrong: regExPasswordRule.test(value),
            });
        }
        if (fieldName === 'oldPassword') {
            const regExPasswordRule = /^(?=.*?[A-Z+])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
            this.setState({
                oldPasswordIsStrong: regExPasswordRule.test(value),
            });
        }
        return false;
    };

    /**
     * React: render
     */
    render() {
        const userProfile = AuthStore.userProfile;
        const card = this.state.customerPaymentDetails ? this.state.customerPaymentDetails.defaultCard : null;

        const popupInfoText = (
            <div>
                <p>You only pay for the movie or TV show you want to watch.</p>
                <p>Store purchases do not appear on your Virgin Media bill.</p>
                <p>We need your debit or credit card details to take payment for your purchases.</p>
                <p>Once you save your payment details online you can use your TV PIN to confirm
                purchases on your Virgin TV.
                </p>
                <p>You can change your payment details anytime by going to My Account.</p>
            </div>
        );

        return userProfile && (
            <main className="my-account-page">
                <h4 className="account-page-title">My Account</h4>
                <div className="container">
                    <div className="row">
                        <div className="my-account-page-content set-payment-container col-xs-12 col-sm-8 col-md-6 col-lg-5">

                            <div className="login-avatar">
                                <i className="ui-icon icon-avatar-profile-circle" />
                            </div>
                            <div className="profile-section-wrapper">
                                <div className="form-title">
                                    <h4>{AppStore.translate('view.account_page.title.update_profile')}</h4>
                                </div>

                                {!this.state.profileEditable && (
                                    <div className="display-user-account-info">
                                        <div className="display-user-account-info-left">
                                            <span className="label-user-info">Display Name </span>
                                            <span className="label-user-info">Email address </span>
                                            <span className="label-user-info">Password </span>
                                        </div>
                                        <div className="display-user-account-info-right">
                                            <span className="item-user-info-text">{this.state.fullName}</span>
                                            <span className="item-user-info-text">{userProfile.email}</span>
                                            <span className="item-user-info-text">********</span>
                                        </div>
                                    </div>
                                )}

                                {this.state.profileEditable && (
                                    <form onSubmit={this.updateUserProfileAndPassword}>
                                        <span className="input-label">
                                            {AppStore.translate('field.label.full_name')}
                                        </span>
                                        <input
                                            type="text"
                                            name="fullName"
                                            className="simple-input"
                                            placeholder={AppStore.translate('field.placeholder.full_name')}
                                            value={this.state.fullName}
                                            autoComplete="name"
                                            onChange={this.onFieldChange}
                                        />
                                        <span className="input-label">
                                            {AppStore.translate('field.label.email')}
                                        </span>
                                        <input
                                            type="text"
                                            className="simple-input"
                                            placeholder={AppStore.translate('field.placeholder.email')}
                                            value={userProfile.email}
                                            disabled={true}
                                            autoComplete="email"
                                            onChange={null}
                                        />

                                        {/* Password update */}
                                        <div className="input-label-container">
                                            <span className="input-label">
                                                {AppStore.translate('field.label.password')}
                                            </span>
                                            <button type="button" className="button-input-label" onClick={this.showHidePassword}>
                                                {this.state.showPassword ? 'Hide' : 'Show'}
                                            </button>
                                        </div>
                                        <input
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            name="oldPassword"
                                            className={`simple-input simple-input-retype${
                                                !this.state.oldPasswordIsStrong
                                                && this.state.oldPassword.length > 2 ? ' simple-input-error' : ''}`}
                                            placeholder={AppStore.translate('field.placeholder.old_password')}
                                            value={this.state.oldPassword}
                                            autoComplete="current-password"
                                            onBlur={this.onFocusChange}
                                            onChange={this.onFieldChange}
                                        />

                                        <input
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            name="newPassword"
                                            className={`simple-input simple-input-retype${
                                                !this.state.passwordIsStrong
                                                && this.state.newPassword.length > 2 ? ' simple-input-error' : ''}`}
                                            placeholder={AppStore.translate('field.placeholder.new-password-signup')}
                                            value={this.state.newPassword}
                                            disabled={this.state.updatePending}
                                            autoComplete="new-password"
                                            onBlur={this.onFocusChange}
                                            onChange={this.onFieldChange}
                                        />
                                        <input
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            name="newPasswordConfirmation"
                                            className={`simple-input${
                                                !this.state.passwordFieldsValuesMatch ? ' simple-input-error' : ''}`}
                                            placeholder={AppStore.translate('field.placeholder.new-password-update-retype')}
                                            value={this.state.newPasswordConfirmation}
                                            disabled={this.state.updatePending}
                                            autoComplete="new-password"
                                            onBlur={this.onFocusChange}
                                            onChange={this.onFieldChange}
                                        />
                                        {!this.state.passwordFieldsValuesMatch && this.state.passwordIsStrong ? (
                                            <span className="input-error-message">
                                                {AppStore.translate('field.error.password-confirm-not-match')}
                                            </span>
                                        ) : null}
                                        {this.state.newPassword.length > 0
                                            && (!this.state.passwordIsStrong || !this.state.oldPasswordIsStrong) ? (
                                                <span className="input-error-message">
                                                    {AppStore.translate('field.error.password-not-strong')}
                                                </span>
                                            ) : null}

                                        <div className="form-annotation">
                                            <p className="no-top-padding">
                                                {AppStore.translate('field.label.password_rule')}
                                            </p>
                                        </div>
                                        <div className="buttons-container clearfix">
                                            <Button
                                                shape="square"
                                                className="small-button"
                                                onClick={AuthStore.profileUpdatePending
                                                    ? null
                                                    : this.disableProfileEditing}
                                            >
                                                {AppStore.translate('button.cancel')}
                                            </Button>
                                            <Button
                                                type="submit"
                                                shape="square"
                                                disabled={
                                                    this.state.profileUpdateDisable
                                                    || !this.state.fullName.length
                                                    || !this.state.oldPassword.length
                                                    || !this.state.newPassword.length
                                                    || !this.state.newPasswordConfirmation
                                                }
                                                className="cta-play small-button"
                                                updatePending={AuthStore.profileUpdatePending}
                                            >
                                                {AppStore.translate('button.update')}
                                            </Button>
                                        </div>
                                    </form>
                                )}

                                {!this.state.profileEditable && (
                                    <div className="buttons-container clearfix">
                                        <Button
                                            shape="square"
                                            className="small-button"
                                            updatePending={AuthStore.profileUpdatePending}
                                            disabled={this.state.userPreferencesEditable}
                                            onClick={this.state.profileEditable ? null : this.enableProfileEditing}
                                        >
                                            {AppStore.translate('button.edit')}
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <div className="profile-section-wrapper">
                                <div className="form-title">
                                    <h4>{AppStore.translate('view.account_page.title.payment_details_update')}</h4>
                                </div>

                                {userProfile.paymentProviderCustomerIds
                                    && userProfile.paymentProviderCustomerIds.stripe
                                    && !this.state.customerPaymentDetails ? (
                                        <div className="display-card-spinner">
                                            <div>
                                                <span className="label-card-info">&nbsp;</span>
                                                <span className="item-card-info-text"><Spinner /></span>
                                            </div>
                                        </div>
                                    ) : (AuthStore.userProfile.paymentProviderCustomerIds.stripe && (
                                        <div className="display-card-account-info">
                                            <div className="display-card-account-left-wrapper">
                                                <span className="label-card-info">Name on card </span>
                                                <span className="label-card-info">Card Number </span>
                                                <span className="label-card-info">Expires </span>
                                                <span className="label-card-info">Billing address </span>
                                            </div>
                                            <div className="display-card-account-right-wrapper">
                                                <span className="item-card-info-text">
                                                    {card.cardHolderName || userProfile.firstName}
                                                </span>
                                                <span className="item-card-info-text">
                                                    {`**** **** **** ${card.lastFourDigits}`}
                                                </span>
                                                {!this.state.customerPaymentDetails ? (
                                                    <span className="item-card-info-text" />
                                                ) : (
                                                    <span className="item-card-info-text">
                                                        {moment().month(card.expiryMonth - 1).format('MM')} / {card.expiryYear}
                                                    </span>
                                                )}
                                                <span className="item-card-info-text">
                                                    {`${userProfile.addressLine1 ? userProfile.addressLine1 + ', ' : ''}
                                                    ${userProfile.addressLine2 ? userProfile.addressLine2 + ', ' : ''}
                                                    ${userProfile.city ? userProfile.city + ', ' : ''}
                                                    ${userProfile.postCode ? userProfile.postCode : ''}`}
                                                </span>
                                            </div>
                                        </div>
                                    ))}

                                {!AuthStore.userProfile.paymentProviderCustomerIds.stripe ? (
                                    <div className="display-account-info">
                                        <span className="info-message">
                                            {AppStore.translate('view.my-account_page_set_payment.payment_not_set')}
                                        </span>
                                    </div>
                                ) : null}

                                {AuthStore.userProfile.paymentProviderCustomerIds.stripe ? (
                                    <div className="buttons-container clearfix">
                                        <Button
                                            shape="square"
                                            className="small-button"
                                            updatePending={AuthStore.profileUpdatePending}
                                            disabled={this.state.profileEditable
                                                || AuthStore.profileUpdatePending
                                                || this.state.userPreferencesEditable}
                                            onClick={this.state.profileEditable ? null : this.openPaymentMethodOverlay}
                                        >
                                            {AppStore.translate('button.edit_card')}
                                        </Button>
                                    </div>
                                ) : (
                                    <div className="buttons-container clearfix">
                                        {!AuthStore.userProfile.paymentProviderCustomerIds.stripe && (
                                        <div className="account-payment-details-btn-wrapper">
                                            <IconButtonWithBubblePop
                                                icon="icon-ic-info"
                                                infoTitle="Info"
                                                infoContent={popupInfoText}
                                            />
                                            <Button
                                                shape="square"
                                                className="button small-button"
                                                updatePending={AuthStore.profileUpdatePending}
                                                disabled={this.state.profileEditable
                                                    || AuthStore.profileUpdatePending
                                                    || this.state.userPreferencesEditable}
                                                onClick={this.state.profileEditable
                                                    ? null
                                                    : this.openPaymentMethodOverlay}
                                            >
                                                {AppStore.translate('button.add_payment')}
                                            </Button>
                                        </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="profile-section-wrapper">
                                <div className="form-title my-contact-preferences-wrapper">
                                    <h4>{AppStore.translate('view.account_page.title.user_preferences')}</h4>
                                </div>
                                <div className="my-contact-preferences">
                                    <div className="gdpr-toggle-button-container">
                                        <ToggleButton
                                            name="gdpr-button"
                                            isChecked={this.state.newsletterOptedIn}
                                            isDisabled={this.state.gdprButtonUpdatePending}
                                            onClick={this.prepareForUpdateNewsletterOptedIn}
                                        />
                                    </div>
                                    <div className="display-account-info">
                                        <span className="info-message gdpr-info-message">
                                            {AppStore.translate('view.my-account_page_gdpr_button_info_text')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
