"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import MediaItem from '../../types/MediaItem';
import Content from './media-item-tile-bubble/Content.react';
import Config from '../../lib/Config';

/**
 * MediaItemTileBubble component
 *
 * @mixes MediaItemComponentCTAs
 */
export default class MediaItemTileBubble extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        shouldTileBubbleBeVisible: PropTypes.bool,
        mediaItem: PropTypes.instanceOf(MediaItem),
        tileNode: PropTypes.object,
        shouldCTAButtonsBeVisible: PropTypes.bool,
        isPurchaseEnabled: PropTypes.bool
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        shouldTileBubbleBeVisible: false,
        mediaItem: null,
        tileNode: null,
        shouldCTAButtonsBeVisible: true,
        isPurchaseEnabled: true
    };

    /**
     * React: state
     */
    state = {
        isVisible: false,
        isHovered: false,
        timeoutId: 0,
        position: {
            over: false,
            under: false,
            side: false,
            left: false,
            right: false,
            coordinates: {
                top: 'auto',
                bottom: 'auto',
                left: 'auto',
                right: 'auto'
            }
        }
    };

    /**
     * React: UNSAFE_componentWillReceiveProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.mediaItem) {
            this.setState({
                isVisible: false,
                isHovered: false
            });

        } else if (nextProps.shouldTileBubbleBeVisible && this.state.isHovered && nextProps.mediaItem === this.props.mediaItem) {
            this.setState({
                isVisible: true
            });

        } else if (nextProps.shouldTileBubbleBeVisible) {
            clearTimeout(this.state.timeoutId);

            this.setState({
                isVisible: false,
                timeoutId: setTimeout(this._displayBubble, 600),
                position: this._calculatePosition(nextProps.tileNode)
            });

        } else if (!nextProps.shouldTileBubbleBeVisible) {
            clearTimeout(this.state.timeoutId);

            this.setState({
                isVisible: false,
                timeoutId: 0
            });
        }
    }

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        clearTimeout(this.state.timeoutId);
    }

    /**
     * React: render
     */
    render() {
        var mediaItem = this.props.mediaItem;
        var className = "movie-tile-bubble big-tile-bubble" +
            (this.state.position.over ? " overlay-over" : "") +
            (this.state.position.under ? " overlay-under" : "") +
            (this.state.position.side ? " overlay-side" : "") +
            (this.state.position.left ? " overlay-left" : "") +
            (this.state.position.right ? " overlay-right" : "");
        var bubbleStyle = {
            top: this.state.position.coordinates.top,
            bottom: this.state.position.coordinates.bottom,
            left: this.state.position.coordinates.left,
            right: this.state.position.coordinates.right,
            // MS Edge fix
            zIndex: '9999',
            // needed for debugging and restyling
            display: (this.state.isVisible || this.state.isHovered || Config.TILE_POPUP_BUBBLE_DEBUG_ENABLED) ? "block" : "none"
        };

        return mediaItem && (this.state.isVisible || this.state.isHovered || Config.TILE_POPUP_BUBBLE_DEBUG_ENABLED) ? (
            <div className={className} style={bubbleStyle}
                 onMouseEnter={this._setHovered.bind(null, true)}
                 onMouseLeave={this._setHovered.bind(null, false)}>
                <Content mediaItem={mediaItem}
                         shouldCTAButtonsBeVisible={this.props.shouldCTAButtonsBeVisible}
                         isPurchaseEnabled={this.props.isPurchaseEnabled} />
            </div>
        ) : null;
    }

    /**
     * Display bubble
     *
     * @private
     */
    _displayBubble = () => {
        this.setState({
            isVisible: true
        });
    };

    /**
     * Toggle hovered
     *
     * @param {boolean} hovered
     * @private
     */
    _setHovered = (hovered) => {
        this.setState({
            isHovered: hovered
        });
    };

    /**
     * Calculate position
     *
     * @type {Object} tileNode
     * @private
     */
    _calculatePosition = (tileNode) => {
        var position = {
            under: false,
            over: false,
            side: false,
            left: false,
            right: false,
            coordinates: {
                top: "auto",
                bottom: "auto",
                left: "auto",
                right: "auto"
            }
        };

        // document dimensions
        var body = document.body,
            html = document.documentElement;

        var documentDimensions = {
            height: Math.max(body.scrollHeight, body.offsetHeight,
                html.clientHeight, html.scrollHeight, html.offsetHeight),
            width: Math.max(body.scrollWidth, body.offsetWidth,
                html.clientWidth, html.scrollWidth, html.offsetWidth)
        };

        // window dimensions
        var windowDimensions = {
            width: window.innerWidth,
            height: window.innerHeight
        };

        // css width of the bubble
        var bubbleWidth = 360;
        var bubbleHeightAprox = 250;
        var navbarHeight = 90;

        // tile position
        var absoluteTilePosition = this._getAbsoluteOffset(tileNode);

        // overlay dimensions
        var tile = {
            height: tileNode.scrollHeight,
            width: tileNode.scrollWidth,
            top: absoluteTilePosition.top,
            left: absoluteTilePosition.left,
            bottom: (documentDimensions.height - (absoluteTilePosition.top + tileNode.scrollHeight)),
            right: (documentDimensions.width - (absoluteTilePosition.left + tileNode.scrollWidth))
        };

        // tile position
        var tilePosition = tileNode.getBoundingClientRect();
        var tileScreenPosition = {
            top: tilePosition.top,
            left: tilePosition.left,
            bottom: windowDimensions.height - tilePosition.bottom,
            right: windowDimensions.width - tilePosition.right
        };

        if (tileScreenPosition.top > bubbleHeightAprox + navbarHeight) {
            // overlay over. -2 px is for MS Edge fix
            position.coordinates.bottom = (tile.bottom + tile.height - 2) + "px";
            position.over = true;
        } else if (windowDimensions.height > tileScreenPosition.top + tile.height + bubbleHeightAprox) {
            // overlay under
            position.coordinates.top = (tile.top + tile.height) + "px";
            position.under = true;
        } else {
            // overlay side
            position.coordinates.top = (tile.top + tile.height / 2) + "px";
            position.side = true;

            //overlay side horizontal position
            if (tile.left > tile.right) {
                // overlay on the left side (pointer is on the right)
                position.coordinates.right = (tile.right + tile.width) + "px";
                position.right = true;
            } else {
                // overlay on the right side (pointer is on the left)
                position.coordinates.left = (tile.left + tile.width) + "px";
                position.left = true;
            }
        }

        if (position.over || position.under) {
            //overlay over or overlay under horizontal position
            if (tileScreenPosition.left < 150) {
                // overlay left
                position.coordinates.left = tile.left + "px";
                position.left = true;
            } else if (tileScreenPosition.right < 150) {
                // overlay right
                position.coordinates.right = tile.right + "px";
                position.right = true;
            } else {
                // overlay center
                position.coordinates.left = (tile.left - (bubbleWidth - tile.width) / 2) + "px";
            }
        }

        return position;
    };

    /**
     *
     * @param element
     * @returns {{top: number, left: number}}
     * @private
     */
    _getAbsoluteOffset = (element) => {
        var top = 0;
        var left = 0;

        do {
            top += element.offsetTop || 0;
            left += element.offsetLeft || 0;
            element = element.offsetParent;
        } while (element);

        return {
            top: top,
            left: left
        };
    };
}
