"use strict";

import Immutable from 'immutable';
import moment from 'moment/moment';

/**
 * UserPreferencesDTO definition
 *
 * @typedef {Object} UserPreferencesDTO
 * @property {?boolean} audioDescriptionAllTitles
 * @property {?boolean} captionsAllTitles
 * @property {?boolean} commsAffiliateEmail
 * @property {?boolean} commsAffiliatePost
 * @property {?boolean} commsAffiliateSMS
 * @property {?string} preferenceUpdateDate
 * @property {?boolean} requirePinAtAppLoad
 * @property {?boolean} requirePinAtPlayback
 * @property {?boolean} requirePinAtPurchase
 * @property {?boolean} showToolTips
 */

/**
 * UserPreferences definition
 *
 * @typedef {Immutable.Record} UserPreferences
 * @property {?boolean} audioDescriptionAllTitles
 * @property {?boolean} captionsAllTitles
 * @property {?boolean} commsAffiliateEmail
 * @property {?boolean} commsAffiliatePost
 * @property {?boolean} commsAffiliateSMS
 * @property {?string} preferenceUpdateDate
 * @property {?boolean} requirePinAtAppLoad
 * @property {?boolean} requirePinAtPlayback
 * @property {?boolean} requirePinAtPurchase
 * @property {?boolean} showToolTips

 */

/**
 * UserProfile record
 *
 * @type {UserProfile}
 */
class UserPreferences extends Immutable.Record({
    audioDescriptionAllTitles: null,
    captionsAllTitles: null,
    commsAffiliateEmail: null,
    commsAffiliatePost: null,
    commsAffiliateSMS: null,
    preferenceUpdateDate: null,
    requirePinAtAppLoad: null,
    requirePinAtPlayback: null,
    requirePinAtPurchase: null,
    showToolTips: null
}) {
    /**
     * Set values from data transfer object
     *
     * @param {UserProfileDTO} dataTransferObject
     * @return {UserProfile}
     */
    fromDTO(dataTransferObject) {
        //Boolean value from API are actually strings, - converting to bool i s necessery
        return new UserPreferences({
            ...this.toJS(),
            username: typeof dataTransferObject.audioDescriptionAllTitles !== 'undefined' ?
                dataTransferObject.audioDescriptionAllTitles.toLowerCase() === 'true' : this.audioDescriptionAllTitles,
            captionsAllTitles: typeof dataTransferObject.captionsAllTitles !== 'undefined' ?
                dataTransferObject.captionsAllTitles.toLowerCase() === 'true' : this.captionsAllTitles,
            commsAffiliateEmail: typeof dataTransferObject.commsAffiliateEmail !== 'undefined' ?
                dataTransferObject.commsAffiliateEmail.toLowerCase() === 'true' : this.commsAffiliateEmail,
            commsAffiliatePost: typeof dataTransferObject.commsAffiliatePost !== 'undefined' ?
                dataTransferObject.commsAffiliatePost.toLowerCase() === 'true' : this.commsAffiliatePost,
            commsAffiliateSMS: typeof dataTransferObject.commsAffiliateSMS !== 'undefined' ?
                dataTransferObject.commsAffiliateSMS.toLowerCase() === 'true' : this.commsAffiliateSMS,
            preferenceUpdateDate: typeof dataTransferObject.preferenceUpdateDate !== 'undefined' ?
                moment(dataTransferObject.preferenceUpdateDate).format("DD.MM.YYYY") : this.preferenceUpdateDate,
            requirePinAtAppLoad: typeof dataTransferObject.requirePinAtAppLoad !== 'undefined' ?
                dataTransferObject.requirePinAtAppLoad.toLowerCase() === 'true' : this.requirePinAtAppLoad,
            requirePinAtPlayback: typeof dataTransferObject.requirePinAtPlayback !== 'undefined' ?
                dataTransferObject.requirePinAtPlayback.toLowerCase() === 'true' : this.requirePinAtPlayback,
            requirePinAtPurchase: typeof dataTransferObject.requirePinAtPurchase !== 'undefined' ?
                dataTransferObject.requirePinAtPurchase.toLowerCase() === 'true' : this.requirePinAtPurchase,
            showToolTips: typeof dataTransferObject.showToolTips !== 'undefined' ?
                dataTransferObject.showToolTips.toLowerCase() === 'true' : this.showToolTips
        });
    }
}

export default UserPreferences;
