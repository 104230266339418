"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import MobileDetect from 'mobile-detect';
import uuidGenerator from 'uuid';
import Config from '../../lib/Config';
import UIActions from '../../actions/UIActions';
import MediaItem from '../../types/MediaItem';
import MediaItemComponentCTAs from '../mixins/MediaItemComponentCTAs';
import AppStore from '../../stores/AppStore';
import AnalyticsActions from '../../actions/AnalyticsActions';
import EntitlementStore from '../../stores/EntitlementStore';
import Button from '../ui/Button.react';
import SilverlightPlayer from './media-player/SilverlightPlayer.react';
import ShakaPlayer from './media-player/ShakaPlayer.react';

/**
 * MediaPlayer component
 */
class MediaPlayer extends MediaItemComponentCTAs(React.Component) {
    /**
     * React: propTypes
     */
    static propTypes = {
        mediaItem: PropTypes.instanceOf(MediaItem).isRequired,
        playerType: PropTypes.oneOf(['html5', 'azure', 'silverlight', 'shaka']),
        availablePlayerAction: PropTypes.arrayOf(PropTypes.oneOf(['play', 'download', 'trailer'])),
        displayControls: PropTypes.bool,
        autoPlay: PropTypes.bool,
        onPlaybackStart: PropTypes.func,
        onPlaybackStop: PropTypes.func
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        playerType: 'silverlight',
        availablePlayerAction: ['trailer'],
        displayControls: true,
        autoPlay: true
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: state
     */
    state = {
        playbackStarted: false,
        playerAction: null
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        EntitlementStore.addUpdateListener(this._stopPlaybackOnOverlayPlayerStart);
        this._instanceId = registerMediaPlayerInstance(this);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        EntitlementStore.removeUpdateListener(this._stopPlaybackOnOverlayPlayerStart);
        removeMediaPlayerInstance(this._instanceId);
    }

    /**
     * MediaPlayer instance id
     *
     * @type {*}
     * @private
     */
    _instanceId = null;

    /**
     * React: render
     */
    render() {
        let mediaItem = this.props.mediaItem;

        return (
            <div className="media-player">
                {this.state.playbackStarted ? (
                    <div className="player-container">
                        {this.props.playerType === 'silverlight' || this.state.playerAction === 'download' ? (
                            <SilverlightPlayer mediaItem={mediaItem}
                                playerAction={this.state.playerAction}
                                displayControls={this.props.displayControls}
                                autoPlay={this.props.autoPlay} />
                        ) : null}
                        {this.props.playerType === 'shaka' && this.state.playerAction !== 'download' ? (
                            <ShakaPlayer mediaItem={mediaItem}
                                playerAction={this.state.playerAction}
                                displayControls={this.props.displayControls}
                                autoPlay={this.props.autoPlay}
                                closePlayer={this._stopPlayback} />
                        ) : null}
                        <button className="close" onClick={this._stopPlayback} />
                    </div>
                ) : (
                    <div className="cta-menu full-size-play-button">
                        {this.props.availablePlayerAction.map((playerAction, index) => (
                            <Button shape="square-chromeless"
                                key={index}
                                className={'top-icon cta-' + playerAction}
                                onClick={this._startPlayback.bind(null, playerAction)}>
                                <i className={'ui-icon icon-' + (playerAction === 'download' ? 'download' : 'play')} />
                                {AppStore.translate('button.cta.' + playerAction)}
                            </Button>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    /**
     * Start playback
     *
     * @private
     */
    _startPlayback = (playerAction) => {
        // show native app download option if user on mobile
        let md = new MobileDetect(window.navigator.userAgent);
        if ((md.os() === 'AndroidOS' || md.os() === 'iOS') && playerAction !== 'trailer') {
            let appUrl = md.os() === 'AndroidOS' ? Config.GOOGLE_PLAY_STORE_APP_URL : Config.APPLE_STORE_APP_URL;
            UIActions.showCustomOverlay((
                <div className="alert-box-size col-xs-12 col-sm-8 col-md-6">
                    <h2 className="overlay-header">{AppStore.translate('view.cta_action_modal.title.download_native_app')}</h2>
                    <div className="overlay-content">
                        <p>{AppStore.translate('view.cta_action_modal.message.download_native_app')}</p>
                        <div className="buttons-container">
                            <Button
                                type='button'
                                shape="regular"
                                onClick={UIActions.closeCustomOverlay}
                            >{AppStore.translate('button.cancel')}</Button>
                            <Button
                                type='link'
                                shape="regular"
                                linkTo={appUrl}
                                target="_blank"
                                className="cta-virgin-purple"
                            >{AppStore.translate('view.cta_action_modal.message.get_app')}</Button>
                        </div>
                    </div>
                </div>
            ));
            return;
        }

        AnalyticsActions.trackEvent({
            category: 'Promotion',
            action: 'Teaser',
            label: `Trailer Played: ${this.props.mediaItem.title}`
        });

        this.setState({
            playbackStarted: true,
            playerAction: playerAction
        }, () => {
            if (typeof this.props.onPlaybackStart === 'function') {
                this.props.onPlaybackStart();
            }
        });
    };

    /**
     * Stop playback
     *
     * @private
     */
    _stopPlayback = () => {
        this.setState({
            playbackStarted: false,
            playerAction: null
        }, () => {
            if (typeof this.props.onPlaybackStop === 'function') {
                this.props.onPlaybackStop();
            }
        });
    };

    /**
     * Stop playback on overlay player start
     *
     * @private
     */
    _stopPlaybackOnOverlayPlayerStart = () => {
        if (EntitlementStore.playerMediaItem) {
            this._stopPlayback();
        }
    };
}

/**
 * MediaPlayer instance remotes
 */
let mediaPlayers = {};
function registerMediaPlayerInstance(mediaPlayer) {
    let uuid = uuidGenerator.v4();
    mediaPlayers[uuid] = { stopPlayback: mediaPlayer._stopPlayback };
    return uuid;
}

function removeMediaPlayerInstance(mediaPlayerInstanceId) {
    delete mediaPlayers[mediaPlayerInstanceId];
}

window.mediaPlayerStopPlayback = (mediaPlayerInstanceId = null) => {
    mediaPlayerInstanceId
        ? mediaPlayers[mediaPlayerInstanceId].stopPlayback()
        : Object.keys(mediaPlayers).forEach(mediaPlayerInstanceId => mediaPlayers[mediaPlayerInstanceId].stopPlayback());
};

export default MediaPlayer;
