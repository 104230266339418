import {Dispatcher} from 'flux';
import uuidGenerator from 'uuid';
import Config from '../lib/Config';

const flux = new Dispatcher();

export function register(callback) {
    return flux.register(callback);
}

export function waitFor(ids) {
    return flux.waitFor(ids);
}

/**
 * Dispatches a single action.
 */
export function dispatch(type, action = {}, body = null, error = null) {
    const uuid = action.uuid || uuidGenerator.v4();

    if (!type) {
        throw new Error('You forgot to specify type.');
    }

    if (error) {
        console.error('** [dispatcher]', type, {uuid, ...action}, error);
        flux.dispatch({type, uuid, ...action, error});
        return uuid;
    }

    if (Config.SHOW_LOGGER_MSGS) {
        console.info('** [dispatcher]', type, {uuid, ...action, ...body});
    }

    flux.dispatch({type, uuid, ...action, ...body});
    return uuid;
}

/**
 * Dispatches three actions for an async operation represented by promise.
 */
export function dispatchAsync(promise, types, action = {}) {
    const {request, success, failure} = types;
    const uuid = action.uuid || uuidGenerator.v4();

    dispatch(request, {uuid, ...action});
    promise
        .then((body) => dispatch(success, {uuid, ...action}, body))
        .catch((error) => dispatch(failure, {uuid, ...action}, null, error));

    return uuid;
}
