"use strict";

import Immutable from 'immutable';
import BaseStore from './BaseStore';
import {waitFor} from '../dispatchers/AppDispatcher';
import CustomOverlayContentItem from '../types/CustomOverlayContentItem';
import ActionTypes from '../actions/ActionTypes';
import PurchaseStore from '../stores/PurchaseStore';
import AuthStore from '../stores/AuthStore';
import OverlayIds from '../lib/OverlayIds';

/**
 * Flux: UIStore
 */
class UIStore extends BaseStore {
    /**
     * _customOverlayContent
     *
     * @type {Immutable.List<CustomOverlayContentItem>}
     * @private
     */
    _customOverlayContent = Immutable.List();

    /**
     * Constructor
     */
    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this));
    }

    /**
     * Flux: register store to actions
     *
     * @param action
     * @private
     */
    _registerToActions(action) {
        this._error = null;

        switch (action.type) {
            // custom overlay
            case ActionTypes.SHOW_CUSTOM_OVERLAY: {
                const customOverlayContentItem = new CustomOverlayContentItem({}).fromDTO({
                    overlayId: action.overlayId,
                    showCloseButton: action.showCloseButton,
                    isNode: action.isNode
                });
                this._customOverlayContent = this._customOverlayContent.push(customOverlayContentItem);
                this.emitUpdate();
                break;
            }

            case ActionTypes.CLOSE_CUSTOM_OVERLAY: {
                if (action.overlayId && action.overlayId === OverlayIds.ALL) {
                    this._customOverlayContent = Immutable.List();
                }

                if (action.overlayId) {
                    this._customOverlayContent = this._customOverlayContent.filter(customOverlayContentItem => {
                        return customOverlayContentItem.overlayId !== action.overlayId;
                    });
                }

                if (!action.overlayId) {
                    this._customOverlayContent = this._customOverlayContent.pop();
                }

                this.emitUpdate();
                break;
            }

            // on payment method add/update success
            case ActionTypes.REQUEST_ADD_PAYMENT_METHOD_SUCCESS:
            case ActionTypes.REQUEST_UPDATE_PAYMENT_METHOD_SUCCESS: {
                waitFor([AuthStore.dispatchToken]);
                this._customOverlayContent = this._customOverlayContent.filter(customOverlayContentItem => {
                    return customOverlayContentItem.overlayId !== OverlayIds.PAYMENT_METHOD;
                });
                this.emitUpdate();
                break;
            }

            // on fast checkout success
            case ActionTypes.REQUEST_FAST_CHECKOUT_MEDIA_ITEM_OFFER_SUCCESS:
            case ActionTypes.REQUEST_FAST_CHECKOUT_BUNDLE_OFFER_SUCCESS: {
                waitFor([PurchaseStore.dispatchToken]);
                const customOverlayContentItem = new CustomOverlayContentItem({}).fromDTO({
                    overlayId: OverlayIds.CHECKOUT,
                    showCloseButton: false
                });
                this._customOverlayContent = this._customOverlayContent.push(customOverlayContentItem);
                this.emitUpdate();
                break;
            }

            // on fast checkout cancel
            case ActionTypes.CANCEL_CHECKOUT: {
                waitFor([PurchaseStore.dispatchToken]);
                this._customOverlayContent = this._customOverlayContent.filter(customOverlayContentItem => {
                    return customOverlayContentItem.overlayId !== OverlayIds.CHECKOUT;
                });
                this.emitUpdate();
                break;
            }

            default:
                break;
        }
    }

    /**
     * Get _customOverlayContent
     *
     * @returns {null|*}
     */
    get customOverlayContent() {
        return this._customOverlayContent;
    }

    /**
     * Get _error
     *
     * @returns {null|*}
     */
    get error() {
        return this._error;
    }
}

export default new UIStore();
