/* eslint-disable react/no-unused-state */
/* eslint-disable import/no-cycle */
import React from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import Config from '../../lib/Config';
import {MediaItemClassification} from '../../types/MediaItem';
import WatchlistActions from '../../actions/WatchlistActions';
import PurchaseActions from '../../actions/PurchaseActions';
import AppStore from '../../stores/AppStore';
import WatchlistStore from '../../stores/WatchlistStore';
import MediaStore from '../../stores/MediaStore';
import Button from '../ui/Button.react';
import MediaItemImage from './MediaItemImage.react';
import UIActions from '../../actions/UIActions';
import OverlayIds from '../../lib/OverlayIds';

/**
 * PreOrderedMediaItemAlertOverlay component
 */
export default class PreOrderedMediaItemAlertOverlay extends React.Component {
    /**
     * React: state
     */
    state = {
        preOrderedMediaItemAlert: WatchlistStore.preOrderedMediaItemAlerts.first(),
        mediaBucket: WatchlistStore.preOrderedMediaItemAlerts.first()
            ? MediaStore.getMediaBucket(WatchlistStore.preOrderedMediaBucketKey)
            : null,
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        WatchlistStore.addUpdateListener(this.setPreOrderedMediaItemAlert);
        MediaStore.addUpdateListener(this.setMediaItemMediaBucket);
    }

    /**
     * React: shouldComponentUpdate
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    componentDidUpdate() {
        if (!this.state.preOrderedMediaItemAlert) {
            setTimeout(() => {
                UIActions.closeCustomOverlay({
                    overlayId: OverlayIds.PREORDERED_ITEM,
                    showCloseButton: false,
                });
            }, 0);
        }
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        WatchlistStore.removeUpdateListener(this.setPreOrderedMediaItemAlert);
        MediaStore.removeUpdateListener(this.setMediaItemMediaBucket);
    }

    /**
     * Set pre-ordered mediaItem alert
     */
    setPreOrderedMediaItemAlert = () => {
        this.setState({
            preOrderedMediaItemAlert: WatchlistStore.preOrderedMediaItemAlerts.first(),
        });
    };

    /**
     * Set mediaItem mediaBucket
     */
    setMediaItemMediaBucket = () => {
        if (this.state.preOrderedMediaItemAlert) {
            this.setState({
                mediaBucket: MediaStore.getMediaBucket(WatchlistStore.preOrderedMediaBucketKey),
            });
        }
    };

    /**
     * Checkout mediaItem offer
     *
     * @private
     */
    fastCheckoutMediaItemOffer = (mediaItem, offer) => {
        WatchlistActions.incrementPreOrderedMediaItemAlertDisplayCount({
            alertId: this.state.preOrderedMediaItemAlert.id,
        });
        PurchaseActions.fastCheckoutMediaItemOffer({
            offerId: offer.id,
            mediaItemId: mediaItem.id,
        });
        this.setState({preOrderedMediaItemAlert: null});
    };

    /**
     * Cancel pre-ordered mediaItem alert
     *
     * @private
     */
    cancelPreOrderedMediaItemAlert = mediaItem => {
        WatchlistActions.cancelPreOrderedMediaItemAlert({
            alertId: this.state.preOrderedMediaItemAlert.id,
            mediaItemId: mediaItem.id,
        });
    };

    /**
     * Toggle mediaItem watchlist status
     *
     * @private
     */
    toggleMediaItemWatchlistStatus = mediaItem => {
        WatchlistActions.incrementPreOrderedMediaItemAlertDisplayCount({
            alertId: this.state.preOrderedMediaItemAlert.id,
        });
        WatchlistActions.removeMediaItemFromWatchlist({
            mediaItemId: mediaItem.id,
            watchlistItemId: mediaItem.watchlistItem.id,
        });
    };

    /**
     * Close pre-ordered mediaItem alert overlay
     * @private
     */
    closePreOrderedMediaItemAlertOverlay = mediaItem => {
        WatchlistActions.incrementPreOrderedMediaItemAlertDisplayCount({
            alertId: this.state.preOrderedMediaItemAlert.id,
        });
        WatchlistActions.closePreOrderedMediaItemAlert({mediaItemId: mediaItem.id});
    };

    /**
     * React: render
     */
    render() {
        const preOrderedMediaItemAlert = this.state.preOrderedMediaItemAlert;
        const mediaBucket = preOrderedMediaItemAlert
            ? MediaStore.getMediaBucket(WatchlistStore.preOrderedMediaBucketKey)
            : null;
        const mediaItem = mediaBucket ? mediaBucket.media().get(preOrderedMediaItemAlert.referenceId) : null;

        return preOrderedMediaItemAlert ? (
            <main className="checkout-overlay preorder-overlay">
                <div className="container">
                    <div className="row">
                        <div
                            className="preorder-container"
                        >
                            <h2 className="overlay-header">Release notice</h2>
                            <div className="preorder-container-inner">
                                <div className="preorder-information-container">
                                    <div className="media-item-image-container">
                                        <MediaItemImage mediaItem={mediaItem} />
                                    </div>
                                    <div className="basic-information-container">
                                        <div>
                                            <h2>{mediaItem.title}</h2>
                                            <div className="misc-information">
                                                {mediaItem.releaseYear}<br />
                                                {AppStore.translate('label.certificate')} {mediaItem.certificate}<br />
                                                {mediaItem.duration} {AppStore.translate('label.duration_min')}<br />
                                                {mediaItem.genres.join(', ')}
                                            </div>
                                        </div>
                                        <p>{AppStore.translate('view.pre_order_available_overlay.media_item_available')}</p>
                                    </div>

                                </div>
                                <div className="preorder-overlay-buttons-container">
                                    <div className="cta-buttons-container">
                                        {!mediaItem.isPurchased ? mediaItem.offers.map((offer, index) => {
                                            return (
                                                <Button
                                                    shape="regular"
                                                    key={index}
                                                    className="cta-purchase-est left-align full-width"
                                                    disabled={mediaItem.updatePending
                                                        || preOrderedMediaItemAlert.updatePending}
                                                    updatePending={offer.updatePending}
                                                    onClick={() => this.fastCheckoutMediaItemOffer(mediaItem, offer)}
                                                >
                                                    {offer.currency === 'GBP' ? (
                                                        <i className="ui-icon icon-pound-circle" />
                                                    ) : (offer.currency === 'EUR' ? (
                                                        <i className="ui-icon icon-euro-circle" />
                                                    ) : (
                                                        <i className="ui-icon icon-basket" />
                                                    ))}
                                                    {offer.price.toLocaleString(Config.LOCALE, {
                                                        style: 'decimal',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}
                                                    {mediaItem.classification === MediaItemClassification.MOVIE ? (
                                                        <span className="right-content">
                                                            {AppStore.translate('label.digital')}&nbsp;
                                                            <i className="ui-icon icon-hd-sign video-options-icon" />&nbsp;
                                                        </span>
                                                    ) : (
                                                        <span className="right-content">
                                                            {AppStore.translate('label.digital')}&nbsp;
                                                            <i className="ui-icon icon-hd-sign video-options-icon" />
                                                        </span>
                                                    )}
                                                </Button>
                                            );
                                        }) : null}
                                        <Button
                                            shape="regular"
                                            disabled={mediaItem.updatePending || preOrderedMediaItemAlert.updatePending}
                                            updatePending={preOrderedMediaItemAlert.updatePending}
                                            onClick={() => this.cancelPreOrderedMediaItemAlert(mediaItem)}
                                        >
                                            <strong>{AppStore.translate('button.do_not_remind_again')}</strong>
                                        </Button>
                                        <Button
                                            shape="regular"
                                            disabled={mediaItem.updatePending
                                                || preOrderedMediaItemAlert.updatePending
                                                || !mediaItem.isOnWatchlist}
                                            updatePending={mediaItem.watchlistPending}
                                            onClick={() => this.toggleMediaItemWatchlistStatus(mediaItem)}
                                        >
                                            <strong>{AppStore.translate('button.remove_from_watchlist')}&nbsp;</strong>
                                        </Button>
                                        <Button
                                            shape="regular"
                                            disabled={mediaItem.updatePending || preOrderedMediaItemAlert.updatePending}
                                            onClick={() => this.closePreOrderedMediaItemAlertOverlay(mediaItem)}
                                        >
                                            <strong>{AppStore.translate('button.close')}</strong>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        ) : null;
    }
}
