"use strict";

import Immutable from 'immutable';
import PageRow from './PageRow';

/**
 * AppSettingDTO definition
 *
 * @typedef {Object} AppSettingDTO
 * @property {?string} SettingKey - The setting key.
 * @property {?number} AffiliateId - Gets or sets the affiliate identifier.
 * @property {?Array<PageRowDTO>|string} SettingValue - The setting value.
 * @property {?string} SettingGroup - The group that this setting applies to. Each client is will be interested in the settings contained in a group and it's parent groups.
 * @property {?boolean} IsHtml - Indicates if this setting is in HTML.
 * @property {?boolean} IsJson - Indicates if this setting is in JSON.
 * @property {?boolean} IsLocked - Indicates that this setting can not be edited in the DCR Admin UI.
 * @property {?boolean} IsHidden - Indicates that this setting should be hidden from all default AppSetting requests. This can be overridden for internal requests.
 * @property {?string} Description - Description of the App Setting.
 * @property {?string} ModifiedDate - Last modified date and time.
 * @property {?string} ModifiedBy - Modified by.
 */

/**
 * AppSetting definition
 *
 * @typedef {Immutable.Record} AppSetting
 * @property {string} settingKey
 * @property {number} affiliateId
 * @property {Array<PageRow>|string} settingValue
 * @property {string} settingGroup
 * @property {boolean} isHtml
 * @property {boolean} isJson
 * @property {boolean} isLocked
 * @property {boolean} isHidden
 * @property {string} description
 * @property {string} modifiedDate
 * @property {string} modifiedBy
 */

/**
 * AppSetting record
 *
 * @type {AppSetting}
 *
 */
class AppSetting extends Immutable.Record({
    settingKey: null,
    affiliateId: null,
    settingValue: null,
    settingGroup: null,
    isHtml: null,
    isJson: null,
    isLocked: null,
    isHidden: null,
    description: null,
    modifiedDate: null,
    modifiedBy: null
}) {
    /**
     * toJS shallow
     * converts Record to object by keeping all second level immutable or other complex properties intact
     *
     * @return {Object}
     */
    toJSShallow() {
        return {
            ...this.toJS()
        };
    }

    /**
     * Set values from data transfer object
     *
     * @param {AppSettingDTO} dataTransferObject
     * @return {AppSetting}
     */
    fromDTO(dataTransferObject) {
        return new AppSetting({
            ...this.toJSShallow(),
            settingKey: typeof dataTransferObject.SettingKey !== 'undefined' ? dataTransferObject.SettingKey : this.settingKey,
            affiliateId: typeof dataTransferObject.AffiliateId !== 'undefined' ? dataTransferObject.AffiliateId : this.affiliateId,
            settingValue: typeof dataTransferObject.SettingValue !== 'undefined' && typeof dataTransferObject.SettingKey !== 'undefined' ?
                (dataTransferObject.IsJson && dataTransferObject.SettingKey.indexOf('PageRow') > -1 ? _getPageRowsFromDTO(dataTransferObject)
                    : (dataTransferObject.IsJson ? _getSettingValueFromDTO(dataTransferObject) : dataTransferObject.SettingValue))
                : this.settingValue,
            settingGroup: typeof dataTransferObject.SettingGroup !== 'undefined' ? dataTransferObject.SettingGroup : this.settingGroup,
            isHtml: typeof dataTransferObject.IsHtml !== 'undefined' ? dataTransferObject.IsHtml : this.isHtml,
            isJson: typeof dataTransferObject.IsJson !== 'undefined' ? dataTransferObject.IsJson : this.isJson,
            isLocked: typeof dataTransferObject.IsLocked !== 'undefined' ? dataTransferObject.IsLocked : this.isLocked,
            isHidden: typeof dataTransferObject.IsHidden !== 'undefined' ? dataTransferObject.IsHidden : this.isHidden,
            description: typeof dataTransferObject.Description !== 'undefined' ? dataTransferObject.Description : this.description,
            modifiedDate: typeof dataTransferObject.ModifiedDate !== 'undefined' ? dataTransferObject.ModifiedDate : this.modifiedDate,
            modifiedBy: typeof dataTransferObject.ModifiedBy !== 'undefined' ? dataTransferObject.ModifiedBy : this.modifiedBy
        });
    }

    /**
     * Set settingValue
     *
     * @param {*} settingValue
     * @return {MediaItem}
     */
    setSettingValue(settingValue) {
        return new AppSetting({
            ...this.toJSShallow(),
            settingValue: settingValue
        });
    }
}

/**
 * Get setting value data transfer object
 *
 * @param {AppSettingDTO} dataTransferObject
 * @returns {Array<PageRow>}
 * @private
 */
function _getSettingValueFromDTO(dataTransferObject) {
    try {
        return JSON.parse(dataTransferObject.SettingValue);
    } catch (err) {
        return null;
    }
}

/**
 * Get page rows from data transfer object
 *
 * @param {AppSettingDTO} dataTransferObject
 * @returns {Array<PageRow>}
 * @private
 */
function _getPageRowsFromDTO(dataTransferObject) {
    try {
        var settingValue = JSON.parse(dataTransferObject.SettingValue);
        var pageRows = [];
        settingValue.forEach((pageRowDTO) => {
            let pageRow = new PageRow({}).fromDTO(pageRowDTO);
            if (pageRow.type) {
                pageRows.push(pageRow);
            }
        });

        return pageRows;
    } catch (err) {
        return [];
    }
}

export default AppSetting;
