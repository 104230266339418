"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import AppStore from '../../../stores/AppStore';

export default class AppMeta extends React.Component {

    /**
     * React: propTypes
     */
    static propTypes = {
        titleText: PropTypes.string.isRequired
    };

    /**
     * React: render
     */
    render() {
        const titleText = this.props.titleText ? this.props.titleText : '';
        return (
            <Helmet>
                <title>{AppStore.translate('general.site.title')} | {titleText}</title>
            </Helmet>
        );
    }
}
