"use strict";

import * as AppDispatcher from '../dispatchers/AppDispatcher';
import ActionTypes from './ActionTypes';

/**
 * Flux: UIActions
 */
export default {
    // Open custom popup
    // TODO refactor params
    showCustomOverlay: (params = {}, showCloseButton = true) => {
        if (typeof params.overlayId === 'undefined') {
            params = {
                overlayId: params,
                showCloseButton: showCloseButton,
                isNode: true
            };
        }
        AppDispatcher.dispatch(ActionTypes.SHOW_CUSTOM_OVERLAY, {...params});
    },

    // Close custom popup
    closeCustomOverlay: (params = {}) => {
        AppDispatcher.dispatch(ActionTypes.CLOSE_CUSTOM_OVERLAY, typeof params.overlayId !== 'undefined' && {...params});
    }
};
