/* eslint-disable import/no-cycle */

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {injectStripe, CardNumberElement, CardExpiryElement, CardCvcElement} from 'react-stripe-elements';
import * as ErrorParser from '../../../lib/ErrorParser';
import OverlayIds from '../../../lib/OverlayIds';
import AppStore from '../../../stores/AppStore';
import EntitlementStore from '../../../stores/EntitlementStore';
import UIStore from '../../../stores/UIStore';
import AuthActions from '../../../actions/AuthActions';
import AppActions from '../../../actions/AppActions';
import UIActions from '../../../actions/UIActions';
import AuthStore from '../../../stores/AuthStore';
import Router from '../../../lib/Router';
import AnalyticsActions from '../../../actions/AnalyticsActions';
import Button from '../../ui/Button.react';
import NotificationBarOverlay from '../NotificationBarOverlay.react';
import Spinner from '../../ui/Spinner2.react';


/**
 * PaymentMethodStripeElementsForm component
 */
class PaymentMethodStripeElementsForm extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    /**
     * React: state
     */
    state = {
        cardName: '',
        updatePending: false,
        errorMessage: null,
        addressLookupResultSelected: false,
        addressLookupError: false,
        addressLookupUpdatePending: false,
        addressLookupResult: '',
        postcode: '',
        cardAddressLine1: '',
        cardAddressLine2: '',
        cardAddressLine3: '',
        cardAddressCity: '',
        cardAddressZip: '',
        cardElementReady: false,
        cardNumber: false,
        cardExpiry: false,
        cardCvc: false,
        expiryElementReady: false,
        cvvElementReady: false,
    };

    componentDidMount() {
        AuthStore.addUpdateListener(this.setFormStatus);
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentDidUpdate() {
        if (this.myRef.current) {
            this.myRef.current.focus();
        }
    }

    componentWillUnmount() {
        AuthStore.removeListener(AuthStore.STORE_UPDATED, this.setFormStatus);
        this.setState({updatePending: false});
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    myRef = React.createRef();
    /**
     * On field change
     *
     * @param {string} fieldName
     * @param {*} value
     * @private
     */

    onFormFieldChange = e => {
        if (e.target.name === 'cardName') {
            const nameValid = /^[a-zA-Z '-]+$/.test(e.target.value);
            if (nameValid || e.target.value === '') this.setState({cardName: e.target.value});
            return;
        }
        this.setState({[e.target.name]: e.target.value});
    };

    onStripeElementChange = e => {
        this.setState({[e.elementType]: e.complete});
    };

    /**
     * Set payment method
     */
    setPaymentMethod = e => {
        e.preventDefault();
        const userProfile = AuthStore.userProfile;
        AuthStore.addUpdateListener(this.setFormStatus);
        AuthActions.updatePaymentMethodV3({
            cardElement: this.props.elements.getElement('cardNumber'),
            confirmCardSetup: this.props.stripe.confirmCardSetup,
            cardName: this.state.cardName,
            email: userProfile.email,
            city: this.state.cardAddressCity,
            line1: this.state.cardAddressLine1,
            line2: `${this.state.cardAddressLine2
                ? this.state.cardAddressLine2
                : ''}${this.state.cardAddressLine3
                ? ', ' + this.state.cardAddressLine3
                : ''}`.trim(),
            postal_code: this.state.cardAddressZip,
        });
    };

    /**
     * Set form status
     */
    setFormStatus = () => {
        const error = AuthStore.error;
        const errorMessage = error
            ? (ErrorParser.parseErrorMessage(error.message)
                || AppStore.translate('notification.general.error.communication_error'))
            // eslint-disable-next-line react/no-access-state-in-setstate
            : this.state.errorMessage;

        if (EntitlementStore.stbPairingData.size > 0) {
            UIStore.addUpdateListener(this.checkIsCustomOverlayClosed);
        }

        this.setState({
            updatePending: AuthStore.updatePending,
            errorMessage: errorMessage,
        }, () => {
            if (!this.state.updatePending) {
                AuthStore.removeListener(AuthStore.STORE_UPDATED, this.setFormStatus);
            }
        });
    };

    /**
     * check if stripe overlay is closed, then redirect user to storefront to finish stb pairing process
     */
    checkIsCustomOverlayClosed = () => {
        if (UIStore.customOverlayContent.size === 0) {
            setTimeout(() => {
                this.context.router.push(Router.HOME);
            }, 0);
            UIStore.removeUpdateListener(this.checkIsCustomOverlayClosed);
        }
    };

    /**
     * Close payment method overlay
     */
    closePaymentMethodOverlay = () => {
        UIActions.closeCustomOverlay({
            overlayId: OverlayIds.PAYMENT_METHOD,
            showCloseButton: false,
        });
    };

    /**
     * Show CVV info
     */
    showCVVInfo = () => {
        UIActions.showCustomOverlay({
            overlayId: OverlayIds.CVV_INFO,
            showCloseButton: false,
        });
        AnalyticsActions.trackEvent({
            category: 'My Account',
            action: 'Add Payment',
            label: 'Whats CSV',
        });
    };

    /**
     * Lookup address by postcode
     *
     * @private
     */
    lookupAddressByPostcode = () => {
        this.setState({
            addressLookupResultSelected: false,
            addressLookupError: false,
        }, () => {
            AppStore.addUpdateListener(this.setAddressLookupResult);
            AppActions.lookupAddressByPostcode({postCode: this.state.postcode});
        });
    };

    /**
     * Set Address lookup result
     *
     * @private
     */
    setAddressLookupResult = () => {
        this.setState({
            addressLookupUpdatePending: AppStore.addressLookupPending,
            addressLookupResult: AppStore.addressLookupResult,
            addressLookupError: AppStore.error,
            updatePending: AppStore.addressLookupPending,
        }, () => {
            if (!this.state.addressLookupUpdatePending) {
                AppStore.removeListener(AppStore.STORE_UPDATED, this.setAddressLookupResult);
            }
        });
    };

    /**
     * handle key press
     */
    handleKeyPress = e => {
        if (e.keyCode === 13) {
            this.lookupAddressByPostcode();
        }
    }

    /**
     * format address
     *
     * @param address
     * @private
     */
    formatAddressLookupResultRow = address => {
        const addressRawArray = address.split(',').filter(element => element !== ' ');
        return (
            <span>
                <strong>{addressRawArray[0]}</strong>,
                {addressRawArray[1]},
                {addressRawArray[2]},
                {addressRawArray[addressRawArray.length - 1]}
            </span>
        );
    };

    /**
     * Select address lookup result
     *
     * @param {string} fieldName
     * @private
     */
    selectAddressLookupAddress = (e, address) => {
        const pickAddressArray = address.split(',');
        this.setState(prevState => ({
            cardAddressLine1: pickAddressArray[0] ? pickAddressArray[0].trim() : '',
            cardAddressLine2: pickAddressArray[1] ? pickAddressArray[1].trim() : '',
            cardAddressLine3: pickAddressArray[2] ? pickAddressArray[2].trim() : '',
            cardAddressCity: pickAddressArray[pickAddressArray.length - 2]
                ? pickAddressArray[pickAddressArray.length - 2].trim() : '',
            cardAddressZip: prevState.postcode.toUpperCase(),
            addressLookupResultSelected: true,
        }));
    };

    hideAddressLookupResults = () => {
        this.setState({
            addressLookupResult: null,
            addressLookupError: false,
        });
    };

    clearDropdownResults = () => {
        this.setState({addressLookupResult: ''});
    };

    stripeElementsReady = element => {
        this.setState({[element]: true});
    };

    render() {
        const style = {
            base: {
                'fontFamily': 'inherit',
                'fontSize': '13px',
                ':disabled': {
                    'backgroundColor': '#e0e0e0',
                    'color': 'black',
                },
            },
        };
        const stripeElementsReady = this.state.cardElementReady
            && this.state.expiryElementReady
            && this.state.cvvElementReady;

        return (
            <Fragment>
                <div
                    className="payment-spinner-container"
                    style={{'display': stripeElementsReady ? ' none' : 'block'}}
                >
                    <Spinner />
                </div>
                <div
                    className="stripe-v3-container col-xs-12 col-md-10 col-lg-8 col-offset-md-1 col-offset-lg-2"
                    style={{'visibility': stripeElementsReady ? ' visible' : 'hidden'}}
                >
                    <h2 className="stripe-v3-header">
                        {AppStore.translate('view.update-payment-popup.payment-page-header').toUpperCase()}
                        <button
                            type="button"
                            className="close stripe-header-btn-close"
                            onClick={this.closePaymentMethodOverlay}
                        />
                    </h2>
                    <NotificationBarOverlay />
                    <form onSubmit={this.setPaymentMethod}>
                        <div className="payment-block-container stripe-payment-block-container">
                            <div className="set-payment-container stripe-payment-container">
                                <div className="ui-form light">
                                    <h3 className="set-payment-form-header">
                                        {AppStore.translate('view.update-payment-popup.payment-info-header')}
                                    </h3>
                                    <div className="stripe-label-container">
                                        <label
                                            className="stripe-v3-label"
                                        >{AppStore.translate('view.payment_page.card.name')}
                                        </label>
                                    </div>
                                    <input
                                        name="cardName"
                                        className="payment-name-input"
                                        value={this.state.cardName}
                                        placeholder={AppStore.translate('view.account_page_set_payment.card_name_placeholder')}
                                        onChange={this.onFormFieldChange}
                                        disabled={this.state.updatePending}
                                    />
                                    <div className="stripe-label-container">
                                        <label className="stripe-v3-label">Card number:</label>
                                    </div>
                                    <CardNumberElement
                                        onReady={() => this.stripeElementsReady('cardElementReady')}
                                        onChange={this.onStripeElementChange}
                                        style={style}
                                        disabled={this.state.updatePending}
                                    />

                                    <p className="terms-conditions-annotation-grey info-text">
                                        {AppStore.translate('view.account_page_set_payment.payment_card_types_info')}
                                    </p>

                                    <div className="stripe-expires-cvv-container">
                                        <div className="expires-element-container">
                                            <div className="stripe-label-container">
                                                <label className="stripe-v3-label">
                                                    {AppStore.translate('field.label.cc.expires-text')}
                                                </label>
                                            </div>
                                            <CardExpiryElement
                                                onReady={() => this.stripeElementsReady('expiryElementReady')}
                                                onChange={this.onStripeElementChange}
                                                style={style}
                                                disabled={this.state.updatePending}
                                            />
                                        </div>
                                        <div className="cvc-card-element-container">
                                            <div className="stripe-label-container">
                                                <label className="stripe-v3-label">Cvv number:</label>
                                            </div>
                                            <CardCvcElement
                                                onReady={() => this.stripeElementsReady('cvvElementReady')}
                                                onChange={this.onStripeElementChange}
                                                style={style}
                                                disabled={this.state.updatePending}
                                                placeholder="CVV"
                                            />
                                            <span
                                                onClick={!this.state.updatePending ? this.showCVVInfo : null}
                                                className="stripe-v3-cvv-info-link"
                                            >
                                                {AppStore.translate('view.payment_page.card.what_is_cvc')}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="address-lookup">
                                        <div className="stripe-label-container">
                                            <label className="stripe-v3-label">Address lookup:</label>
                                        </div>
                                        <div className="address-lookup-container">
                                            <input
                                                name="postcode"
                                                className="payment-name-input address-lookup-input"
                                                placeholder={AppStore.translate('field.placeholder.postcode_lookup')}
                                                value={this.state.postcode}
                                                onChange={this.onFormFieldChange}
                                                onKeyDown={this.handleKeyPress}
                                                disabled={this.state.updatePending}
                                            />
                                            <Button
                                                type="button"
                                                shape="square"
                                                className="disabled btn-neutral btn-square address-lookup-btn"
                                                updatePending={this.state.addressLookupUpdatePending}
                                                disabled={this.state.updatePending
                                                    || this.state.addressLookupUpdatePending}
                                                onClick={this.lookupAddressByPostcode}
                                            >
                                                {AppStore.translate('button.find-address')}
                                                <span className="find-btn-address">Address</span>
                                            </Button>
                                        </div>
                                        {this.state.addressLookupError ? (
                                            <div className="address-lookup-popup">
                                                <div className="address-lookup-error">
                                                    <p>Address not found</p>
                                                    <i onClick={this.hideAddressLookupResults} className="ui-icon icon-close" />
                                                </div>
                                            </div>
                                        ) : null}

                                        {this.state.addressLookupResult && !this.state.addressLookupResultSelected ? (
                                            <div
                                                className="address-lookup-popup"
                                                tabIndex="0"
                                                ref={this.myRef}
                                                onBlur={this.clearDropdownResults}
                                            >
                                                <ul>
                                                    {this.state.addressLookupResult.addresses.map((address, index) => (
                                                        <li
                                                            key={index}
                                                            onClick={e => this.selectAddressLookupAddress(e, address)}
                                                        >
                                                            {this.formatAddressLookupResultRow(address)}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ) : null}
                                        <div className="stripe-label-container">
                                            <label className="stripe-v3-label">
                                                {AppStore.translate('view.payment_page.card.address_block_label')}
                                            </label>
                                        </div>
                                        <input
                                            name="cardAddressLine1"
                                            className="payment-address-input"
                                            placeholder={AppStore.translate('view.account_page_set_payment.billing_address_line_1_placeholder')}
                                            value={this.state.cardAddressLine1}
                                            onChange={this.onFormFieldChange}
                                            disabled={this.state.updatePending}
                                        />

                                        <input
                                            name="cardAddressLine2"
                                            className="payment-address-input"
                                            value={this.state.cardAddressLine2}
                                            placeholder={AppStore.translate('view.account_page_set_payment.billing_address_line_2_placeholder')}
                                            onChange={this.onFormFieldChange}
                                            disabled={this.state.updatePending}
                                        />

                                        <input
                                            name="cardAddressLine3"
                                            className="payment-address-input"
                                            value={this.state.cardAddressLine3}
                                            placeholder={AppStore.translate('view.account_page_set_payment.billing_address_line_3_placeholder')}
                                            onChange={this.onFormFieldChange}
                                            disabled={this.state.updatePending}
                                        />
                                        <div className="stripe-address-city-county-wrapper">
                                            <div className="stripe-address-city-wrapper">
                                                <label className="stripe-v3-label address-label">
                                                    {AppStore.translate('field.label.address-city')}
                                                </label>
                                                <input
                                                    name="cardAddressCity"
                                                    className="payment-address-input city-address-input"
                                                    value={this.state.cardAddressCity}
                                                    placeholder={AppStore.translate('view.account_page_set_payment.billing_address_city_placeholder')}
                                                    onChange={this.onFormFieldChange}
                                                    disabled={this.state.updatePending}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="stripe-btn-container">
                                <p className="stripe-save-payment-info">
                                    By clicking on &apos;Save payment details&apos; you agree the card
                                    above will be charged when you confirm
                                    a purchase in the Virgin Media Store.
                                </p>
                                <div className="stripe-btn-wrapper">
                                    <Button
                                        type="submit"
                                        className="cta-payment-positive cta-colored stripe-btn"
                                        shape="regular"
                                        disabled={this.state.updatePending
                                            || !this.state.cardName
                                            || !this.state.cardAddressLine1
                                            || !this.state.cardNumber
                                            || !this.state.cardExpiry
                                            || !this.state.cardCvc
                                            || this.state.postcode.length < 5
                                        }
                                        updatePending={this.state.updatePending}
                                    >
                                        {AppStore.translate('button.save-payment')}
                                    </Button>
                                    <Button
                                        shape="regular"
                                        className="stripe-btn stripe-btn-cancel"
                                        disabled={this.state.updatePending}
                                        onClick={this.closePaymentMethodOverlay}
                                    >
                                        {AppStore.translate('button.cancel')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Fragment>
        );
    }
}

PaymentMethodStripeElementsForm.propTypes = {
    elements: PropTypes.object.isRequired,
    stripe: PropTypes.object.isRequired,
};

export default injectStripe(PaymentMethodStripeElementsForm);
