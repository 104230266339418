"use strict";

import Immutable from 'immutable';

/**
 * Main navigation item definition
 *
 * @typedef {Immutable.Record} MainNavigationItem
 * @property {?string} type
 * @property {?string} identifier
 * @property {?string} label
 */

/**
 * MainNavigationItem record
 *
 * @type {MainNavigationItem}
 */
class MainNavigationItem extends Immutable.Record({
    type: null,
    identifier: null,
    label: null
}) {
    // no op
}

export default MainNavigationItem;
