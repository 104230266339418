"use strict";

import Immutable from 'immutable';
import moment from 'moment';
import AMSLicenseKey from './AMSLicenseKey';

/**
 * Protection definition
 *
 * @typedef {Object} AMSLicenseDTO
 * @property {?string} TokenType
 * @property {?string} Source
 * @property {?string} StreamingType,
 * @property {?Array<ProtectionInfoDTO>} ProtectionInfos
 * @property {?Array<string>} DeliveryProtocol
 * @property {?number} EntitlementId
 * @property {?string} DrmProfile
 * @property {?string} ExpirationDate
 * @property {?string} FirstPlayExpiration
 */

/**
 * AMSLicense definition
 *
 * @typedef {Immutable.Record} AMSLicense
 * @property {?string} tokenType
 * @property {?string} source
 * @property {?string} streamingType,
 * @property {?Array<AMSLicenseKey>} licenseKeys
 * @property {?Array<string>} deliveryProtocol
 * @property {?number} entitlementId
 * @property {?string} drmProfile
 * @property {?Object} expirationDate
 * @property {?string} firstPlayExpiration
 */

/**
 * AMSLicense record
 *
 * @type {AMSLicense}
 */
class AMSLicense extends Immutable.Record({
    tokenType: null,
    source: null,
    streamingType: null,
    licenseKeys: [],
    deliveryProtocol: [],
    entitlementId: null,
    drmProfile: null,
    expirationDate: null,
    firstPlayExpiration: null
}) {
    /**
     * Set values from data transfer object
     *
     * @param {AMSLicenseDTO} dataTransferObject
     * @return {AMSLicense}
     */
    fromDTO(dataTransferObject) {
        return new AMSLicense({
            ...this.toJS(),
            tokenType: typeof dataTransferObject.TokenType !== 'undefined' ? dataTransferObject.TokenType : this.tokenType,
            source: typeof dataTransferObject.Source !== 'undefined' ? dataTransferObject.Source : this.source,
            streamingType: typeof dataTransferObject.StreamingType !== 'undefined' ? dataTransferObject.StreamingType : this.streamingType,
            licenseKeys: typeof dataTransferObject.ProtectionInfos !== 'undefined' ? _getAMSLicenseKeyFromDTO(dataTransferObject) : this.licenseKeys,
            deliveryProtocol: typeof dataTransferObject.DeliveryProtocol !== 'undefined' ? dataTransferObject.DeliveryProtocol : this.deliveryProtocol,
            entitlementId: typeof dataTransferObject.EntitlementId !== 'undefined' ? dataTransferObject.EntitlementId : this.entitlementId,
            drmProfile: typeof dataTransferObject.DrmProfile !== 'undefined' ? dataTransferObject.DrmProfile : this.drmProfile,
            expirationDate: typeof dataTransferObject.ExpirationDate !== 'undefined' ? moment(dataTransferObject.ExpirationDate) : this.expirationDate,
            firstPlayExpiration: typeof dataTransferObject.FirstPlayExpiration !== 'undefined' ? dataTransferObject.FirstPlayExpiration : this.firstPlayExpiration
        });
    }
}

/**
 * Get AMS license keys from data transfer object
 *
 * @param {AMSLicenseDTO} dataTransferObject
 * @returns {Array<AMSLicenseKey>}
 * @private
 */
function _getAMSLicenseKeyFromDTO(dataTransferObject) {
    let amsLicenseKeys = [];
    dataTransferObject.ProtectionInfos.forEach((protectionInfoDTO) => {
        let amsLicenseKey = new AMSLicenseKey({}).fromDTO(protectionInfoDTO);
        if (amsLicenseKey.protectionType) {
            amsLicenseKeys.push(amsLicenseKey);
        }
    });

    return amsLicenseKeys;
}

export {AMSLicense as default, AMSLicense};
