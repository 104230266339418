"use strict";

import React from 'react';
import {Link} from 'react-router';
import Config from '../../lib/Config';
import AppStore from '../../stores/AppStore';
import Router from '../../lib/Router';
import logo from '../../../assets-virgin/images/logo.png';

/**
 * Footer component
 */
export default class Footer extends React.Component {
    /**
     * React: render
     */
    render() {
        return (
            <footer>

                <div className="container">
                    <div className="row">

                        <div className="col-xs-12 col-md-12 col-lg-1">
                            <Link to={Router.PARENT} target="_blank" id="footer-logo" className="logo"
                                style={{
                                    'backgroundImage': `url(${logo})`
                                }}
                            />
                        </div>

                        <div className="col-xs-4 col-md-12 col-lg-8 inline-links links-list">
                            <div>
                                <Link to={Router.ABOUT} target="_blank">{AppStore.translate('view.navigation.label.about')}</Link> |
                                <Link to={Router.BROADBAND} target="_blank">{AppStore.translate('view.navigation.label.braodband')}</Link> |
                                <Link to={Router.TERMS_AND_CONDITIONS} target="_blank">{AppStore.translate('view.navigation.label.terms_of_use')}</Link> |
                                <Link to={Router.COOKIES} target="_blank">{AppStore.translate('view.navigation.label.cookies')}</Link> |
                                <Link to={Router.PRIVACY} target="_blank">{AppStore.translate('view.navigation.label.privacy')}</Link> |
                                <Link to={Router.HELP}>{AppStore.translate('view.navigation.label.help')}</Link> |
                                <Link to={Router.CONTACT}>{AppStore.translate('view.navigation.label.contact')}</Link>
                            </div>
                        </div>

                        <div id="copyright-container" className="col-xs-12 col-md-12 col-lg-3">
                            <div className="copyright inline-copyright">
                                <div>
                                    {AppStore.translate('view.footer.message.copyright_company', {year: new Date().getFullYear()})} <br/>
                                    {AppStore.translate('view.footer.message.copyright_reserved')}
                                </div>
                            </div>
                            <div className="copyright">Version: {Config.VERSION} build: {Config.BUILD}</div>
                        </div>

                    </div>
                </div>

            </footer>
        );
    }
}
