"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import {Link} from 'react-router';
import Router from '../../../lib/Router';
import AppStore from '../../../stores/AppStore';
import MediaItem, {MediaItemClassification} from '../../../types/MediaItem';
import MediaStore from '../../../stores/MediaStore';

/**
 * BackToSeason component
 */
export default class BackToSeason extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        mediaItem: PropTypes.instanceOf(MediaItem).isRequired
    };

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        let mediaItem = this.props.mediaItem;
        let seasonMediaItem = MediaStore.getMediaBucket(mediaItem.bucketKey).mediaItemParent(mediaItem);
        let seriesMediaItem = MediaStore.getMediaBucket(mediaItem.bucketKey).mediaItemRootParent(mediaItem);
        let basicInfoGridColumns = mediaItem.classification === MediaItemClassification.SERIES
            ? " col-xs-9 col-md-9 col-lg-8" : " col-xs-12 col-md-12 col-lg-12";

        return seasonMediaItem && seriesMediaItem ? (
            <div className={"series-seasons" + basicInfoGridColumns}>
                <Link to={Router.MEDIA_ITEM(seasonMediaItem)} className="back-button">
                    {AppStore.translate('label.back_to_season', {mediaItemTitle: seasonMediaItem.title})}
                </Link>
            </div>
        ) : null;
    }
}
