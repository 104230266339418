"use strict";

/**
 * Translation strings
 *
 * @type {*}
 */
let Lang = {
    /**
     * General
     */
    'test.with_params': (params) => `Test string ${params['test']}`,
    'general.site.title': 'Virgin Media Store',

    // buttons
    'button.show_less': `Show less`,
    'button.show_more': `Show more`,
    'button.more': `more`,
    'button.use_last': `Use last`,
    'button.reset': `Reset`,
    'button.close': `Close`,
    'button.cancel': `Cancel`,
    'button.submit': `Submit`,
    'button.load_more': `Load more`,
    'button.purchase': `Purchase`,
    'button.apply': `Apply`,
    'button.library': `My Library`,
    'button.change': `Change`,
    'button.update': `Update`,
    'button.ok': `OK`,
    'button.add': `Add`,
    'button.add_payment': `Add Card`,
    'button.edit': `Edit`,
    'button.edit_card': `Edit card`,
    'button.save': `Save`,
    'button.save-payment': `Save payment details`,
    'button.next': `Next`,
    'button.signup': `Sign up`,
    'button.signin': `Sign in`,
    'button.back': `Back`,
    'button.complete': `Accept & Continue`,
    'button.confirm': `Confirm`,
    'button.edit_profile': 'Edit details',
    'button.close_account': 'Close account',
    'button.resend_activation_email': `Resend activation email`,
    'button.rename': `OK`,
    'button.continue': `Continue`,
    'button.find-address': `Find`,
    'button.accept': `Accept`,
    'button.decline': `Decline`,
    'button.do_not_remind_again': `Don't remind me again`,

    'button.cta.trailer': `Trailer`,
    'button.cta.details': `Details`,
    'button.cta.share': `Share`,
    'button.cta.pre-order': `Pre-Order`,
    'button.cta.purchase_est': `Buy in Digital `,
    'button.cta.download': `Download`,
    'button.cta.watchlist': `Wish List`,
    'button.cta.play': `Watch now`,
    'button.my_watchlist': `My Wish List`,
    'button.remove_from_watchlist': `Remove from My Wish List`,
    'button.add_payment_details': `Save card details`,
    'button.save_contact_preferences': `Save contact preferences`,
    'button.finish_previous': `Finish Previous`,
    'button.start_new': `Start New`,
    'button.connect_tv': `Connect TV`,

    // labels
    'label.duration': `Duration:`,
    'label.production_year': `Year:`,
    'label.certificate': `Certificate:`,
    'label.content_advice': `Advice:`,
    'label.hd_advice': `HD is only available on `,
    'label.hd_advice_devices': `compatible devices.`,
    'label.genre': `Genre:`,
    'label.audio_languages': `Language:`,
    'label.duration_min': `Min`,
    'label.duration_left': `left`,
    'label.loading': `Loading...`,
    'label.cast_list.unknown': `Unknowns:`,
    'label.cast_list.director': `Directors:`,
    'label.cast_list.writer': `Writers:`,
    'label.cast_list.composer': `Composers:`,
    'label.classification.movie': `Movie`,
    'label.classification.series': `TV Series`,
    'label.classification.season': `TV Season`,
    'label.pre-ordered': `Pre-ordered`,
    'label.with': `with`,
    'label.back_to_season': (params) => `Back to ${params.mediaItemTitle}`,
    'label.movie_count': (params) => `${params.itemCount} movies`,
    'label.season_count': (params) => `${params.itemCount} seasons`,
    'label.cast_list.actor': `Cast:`,
    'label.digital': `Buy in Digital`,
    'label.digital_pre_order': `Digital`,
    'label.classification.episode': `Episode`,

    // fields
    'field.placeholder.search': `Search for movie or show`,
    'field.placeholder.password': `Password`,
    'field.placeholder.confirm_password': `Confirm password`,
    'field.label.email': `Email address`,
    'field.label.name': `Your Name:`,
    'field.label.card': `Card Number:`,
    'field.label.cc.expires-text': `Expires on:`,
    'field.label.address-city': `City / Town:`,
    'field.label.address-county': `County:`,
    'field.label.cvv-text': `Cvv Number:`,
    'field.placeholder.email': `Your email address`,
    'field.placeholder.email_forgot_password': `Your email address`,
    'field.placeholder.email-signin': `Your email address`,
    'field.placeholder.email-confirm': `Type it in again to be sure`,
    'field.error.email-confirm-not-match': `Oops! Your emails does not match. Please correct.`,
    'field.error.fullname-confirm-not-valid': `Please remove characters that are not allowed. Use only digits, letters, space and ! * _`,
    'field.error.signin-email-not-valid': `Oops! Email is not valid. Please correct.`,
    'field.error.email-not-valid': `Oops! Your email is not valid. Please correct.`,
    'field.label.new-password-signup': `Password`,
    'field.placeholder.password-signin': `Your password for Store`,
    'field.placeholder.new-password-signup': `Enter a password for Store`,
    'field.placeholder.new-password-signup-retype': `Type it in again to be sure`,
    'field.placeholder.new-password-update-retype': `Type in your new password again to be sure`,
    'field.placeholder.old_password': `Type in your old password`,
    'field.placeholder.new_password': `Type in a new password`,
    'field.placeholder.postcode_lookup': `Type in your postcode`,
    'field.error.password-confirm-not-match': `Oops! Your passwords does not match. Please try again.`,
    'field.error.password-not-strong': `Oops! Your password is not according to password rules. Please correct.`,
    'field.error.signup-password-error': `A password needs 8 characters or more and have at least one upper case letter, one lower case and one number.`,
    'field.error.password-too-short': `Oops! Your password is too short. Please correct.`,
    'field.error.stb_code_invalid': `Oops! That’s not a valid code number. Please try again.`,
    'field.label.password_rule': `Password rules: a password needs 8 characters or more and have at least one upper case letter, one lower case and one number.`,
    'field.label.password': `Password`,
    'field.placeholder.confirm_new_password': `Type your new password again to be sure`,
    'field.placeholder.first_name': `First name`,
    'field.placeholder.last_name': `Last name`,
    'field.label.full_name': `Display name`,
    'field.placeholder.full_name': `What would you like us to call you?`,
    'field.placeholder.user_name': `Display name`,
    'field.placeholder.stb_pairing_code': `Enter the code shown on your TV`,

    // filters
    'filter.label.offer': `Type`,
    'filter.label.certificate': `Age`,
    'filter.label.genre': `Genre `,
    'filter.label.audio_language': `Language`,
    'filter.label.release_year': `Release year`,
    'filter.offer.watch': `Watch`,
    'filter.offer.buy': `Buy`,
    'filter.offer.rent': `Rent`,
    'filter.audio_language.any': `Show all`,
    'filter.audio_language.en': `English`,
    'filter.audio_language.de': `German`,
    'filter.audio_language.fr': `French`,
    'filter.audio_language.es': `Spanish`,
    'filter.audio_language.it': `Italian`,
    'filter.audio.any': `Show all`,
    'filter.audio.en': `English`,
    'filter.audio.de': `German`,
    'filter.audio.fr': `French`,
    'filter.audio.es': `Spanish`,
    'filter.audio.it': `Italian`,
    'filter.certificate.any': `Show all`,
    'filter.certificate.u': `U`,
    'filter.certificate.pg': `PG`,
    'filter.certificate.tv-pg': `TV-PG`,
    'filter.certificate.tv-g': `TV-G`,
    'filter.certificate.tv-ma': `TV-MA`,
    'filter.certificate.tv-14': `TV-14`,
    'filter.certificate.tv-y': `TV-Y`,
    'filter.certificate.12': `12`,
    'filter.certificate.15': `15`,
    'filter.certificate.18': `18`,
    'filter.certificate.nr': `NR`,
    'filter.year.any': `Show all`,
    'filter.year.2010': `after 2010`,
    'filter.year.2000': `2000s`,
    'filter.year.1990': `1990s`,
    'filter.year.1980': `1980s`,
    'filter.year.1800': `Older`,

    // messages
    'message.coming_soon': `Coming soon..`,
    'message.sign_in': `Sign In`,
    'message.no_media_for_filter_selection': `No media available for your filter selection, please reset your filter.`,
    'message.no_content_available': `No content available. Please try later.`,

    // notifications
    'notification.general.error.communication_error': (params) => `There was a problem connecting to the store. Close and restart the app, and then try again. If this problem continues, please call our team on ${params.CUSTOMER_SUPPORT_TELEPHONE}`,
    'notification.account.error.password_mismatch': `Please ensure both passwords match.`,
    'notification.account.error.empty_credentials': `You haven't typed in the email or password.`,
    'notification.account.error.wrong_credentials': `You've typed in the wrong email or password. Passwords are case-sensitive, so please check your caps lock isn't on.`,
    'notification.account.error.ip_address_not_authorized': `Your IP address is not authorized.`,
    'notification.account.error.account_not_activated': `Error. Check your email to verify account.`,
    'notification.account.error.account_inactive_or_suspended': `Error. Your account is suspended or inactive.`,
    'notification.account.error.account_closed': `Sorry that account is now closed, please open a new account by going to`,
    'notification.account.error.sign_up_form_incomplete': `Please complete all fields on the sign up form.`,
    'notification.account.error.payment_method_form_incomplete': `Please complete all fields on the payment details form.`,
    'notification.account.error.payment_method_form_card_number_wrong': `Please fill in card number field with correct card number.`,
    'notification.account.error.profile_form_incomplete': `Please complete full name field on account details form.`,
    'notification.account.error.password_form_incomplete': `If you wish to change password please complete all password fields.`,
    'notification.account.error.account_exist': `This email address is already registered, please sign in.`,
    'notification.account.error.password_short': `A password must be at least 8 characters long. Please try again.`,
    'notification.account.error.password_not_secure': `A password must contain at least one upper case letter, one lower case letter and one number. Please try again.`,
    'notification.account.error.email_not_valid': `Email-address must be valid. Please try again.`,
    'notification.account.success.signed_in': `You are now signed in.`,
    'notification.account.success.signed_up': `Thank you for signing up`,
    'notification.account.success.profile_updated': `Your account details are updated.`,
    'notification.account.success.preferences_updated': `Your settings have been updated. It may take up to 28 days for changes to take effect.`,
    'notification.account.success.password_changed': `You have successfully changed your password.`,
    'notification.account.success.account_activated': `Your account is activated. Please sign in.`,
    'notification.account.success.activation_email_resent': `Activation email has been resent to your email address.`,
    'notification.account.success.signed_out': `You have successfully signed out. We hope to see you back soon.`,
    'notification.account.success.password_reset_requested': `A password re-set link has been sent to your registered email address`,
    'notification.account.success.payment_method_set': `Your payment method is set.`,
    'notification.voucher.status.valid': `Voucher successfully applied`,
    'notification.voucher.status.not_found': `Sorry, we can't recognise your voucher, please try again.`,
    'notification.voucher.status.campaign_not_active': `Sorry, this voucher can't be used yet, please enter a different voucher number.`,
    'notification.voucher.status.expired': `Sorry, the voucher has expired, please enter a different voucher number.`,
    'notification.voucher.status.no_offer_found': `Sorry, we can't recognise your voucher, please try again or exit this screen.`,
    'notification.voucher.status.redeemed': `Sorry, this voucher has already been used, please enter a different voucher number.`,
    'notification.voucher.status.not_valid_for_user': `Sorry, this voucher isn't valid, please enter a different voucher number.`,
    'notification.voucher.status.campaign_not_started': `Sorry, this voucher can't be used yet, please enter a different voucher number.`,
    'notification.purchase.error.expired_card': `The card registered against account has expired, please update your details.`,
    'notification.purchase.error.incorrect_cvc': `The security code you have entered is incorrect, please check and re-enter.`,
    //'notification.purchase.error.incorrect_zip': `Please use a card that is registered to the account holders address.`,
    'notification.purchase.error.incorrect_zip': `The address you have entered does not match the card details, please try again.`,
    'notification.purchase.error.card_declined': `Sorry, your card has been declined. To continue click Change to update your card details.`,
    'notification.purchase.error.missing': (params) => `Sorry, we can't complete this transaction.  Please contact our team on ${params.CUSTOMER_SUPPORT_EMAIL}.`,
    'notification.purchase.error.processing_error': `Sorry, we weren't able to complete this purchase, no payment has been taken from your account. Please try again.`,
    'notification.checkout.error.processing_error': `Sorry we were not able to process your order. Please check your card details and try again later.`,
    'notification.account.error.unable_to_remove_device': (params) => `Sorry, we weren't able to remove device. If this problem continues, please call our team on ${params.CUSTOMER_SUPPORT_TELEPHONE}`,
    'notification.account.error.unable_to_rename_device': `Sorry, we weren't able to rename the device`,
    'notification.watchlist.error.processing_error': `Sorry, we weren't able to change wish list status for this media. Please try again later.`,
    'notification.charge.basket.payment.intent_error': `Your bank requested re-authentication. Click Change to proceed.`,
    'notification.charge.basket.3ds.auth_error': `We are unable to authenticate your payment method. Please check your card details and try again.`,

    // Views

    'view.page_not_found.title': `404`,
    'view.page_not_found.subtitle': `Page not found`,
    'view.navigation.label.guest': `Guest`,
    'view.navigation.label.home': `Home`,
    'view.navigation.label.account': `My Account`,
    'view.navigation.label.privacy': `Privacy`,
    'view.navigation.label.cookies': `Cookies`,
    'view.navigation.label.library': `My Library`,
    'view.navigation.label.devices': `My Devices`,
    'view.navigation.label.movies': `Movies`,
    'view.navigation.label.tv_shows': `TV Series`,
    'view.navigation.label.collections': `Collections`,
    'view.filter_bar.title': `Filter:`,
    'view.season_carousel.title': (params) => `${params.seasonMediaItemTitle} (${params.episodeCount} episodes)`,
    'view.movie_box_set_carousel.title': (params) => `This movie box set contains ${params.itemCount} movies`,
    'view.tv_box_set_carousel.title': (params) => `This TV box set contains ${params.itemCount} seasons`,
    'view.recommendations_carousel.title': `You may also like`,
    'view.account_page.title.sign_in': `Sign in`,
    'view.account_page.subtitle.sign_in': `Please sign in in order to watch`,
    'view.account_page.title.sign_up': `Sign up`,
    'view.account_page.title.set_payment_method': `Debit/Credit card details`,
    'view.account_page.title.terms_and_conditions': `Terms & Conditions`,
    'view.account_page.title.terms_and_conditions_updated': `Update to our Terms & Conditions`,
    'view.account_page.title.change_password': `Change password`,
    'view.account_page.title.request_password_reset': `Forgot your password`,
    'view.account_page.title.reset_password': `Reset password`,
    'view.account_page.title.payment_details': `My payment details`,
    'view.account_page.title.payment_details_update': `My payment card`,
    'view.account_page.title.user_preferences': `My contact preferences`,
    'view.account_page.title.billing_address': `My Billing Address`,
    'view.account_page.link.sign_in': `Sign in`,
    'view.account_page.link.sign_up': `Sign up`,
    'view.account_page.message.forgot_password': `Forgot your password?`,
    'view.account_page.message.preferences_last_update_label': `Your contact preferences were last updated on`,
    'view.account_page.message.update_agreements_text': `If the box is ticked below it means you agree to share your contact details so that Virgin Media Store can send you updates about brilliant offers, rewards and product news.`,
    'view.account_page.message.preferences_opt_in_label': `Receiving Marketing Communication?`,
    'view.account_page.message.terms_of_use': `Terms of use`,
    'view.account_page.message.sent_email': `Your billing receipts will be sent to the above email.`,
    'view.account_page.message.newsletter_opt_in': `Keeping in touch`,
    'view.account_page.message.newsletter_opt_in.text_3': ` See our `,
    'view.account_page.message.provide_email': `Please provide your email address so that we can send you further instructions.`,
    'view.account_page.message.provide_new_password': `Please type in your new password.`,
    'view.account_page.message.sign_up': `Need to register?`,
    'view.account_page.message.change_password': `Change password`,
    'view.account_page.message.devices_status': (params) => `You have ${params.registeredDevicesCount} device(s) registered out of ${params.REGISTERED_DEVICE_LIMIT}.`,
    'view.account_page.table_title.device': `Device`,
    'view.account_page.table_title.added_date': `Added`,
    'view.account_page.table_title.actions': `remove`,
    'view.account_page.table_value.empty_slot': `Empty slot`,
    'view.account_page.message.contact_support_to_remove_device': `To remove a lost, stolen or broken device, please contact customer service, `,
    'view.account_page.message.contact_support_to_remove_device_2': `click here`,
    'view.account_page.message.contact_support_to_remove_device_3': ` for details.`,
    'view.account_page.date_time.added_date': `DD MMM YYYY`,
    'view.account_page.message.remove_other_device_title': `NOTE`,
    'view.account_page.message.remove_other_device': (params) => `Please log on to the device you are trying to remove or call ${params.CUSTOMER_SUPPORT_TELEPHONE} to remove this device.`,
    'view.account_page.message.remove_device_unavailable_title': `Device Settings`,
    'view.account_page.message.remove_device_unavailable': (params) => `Device changes are limited to one per month. You can remove this device from ${params.removalAvailableDate}.`,
    'view.account_page.message.remove_current_device_title': `Device Settings`,
    'view.account_page.message.remove_current_device': `Are you sure you wish to remove this device from your account?`,
    'view.navigation_search.message.no_media_for_search_query': `No media available for your search.`,
    'view.search_page.title': (params) => `Search: ${params.resultTotalCount} ${params.resultTotalCount === 1 ? 'Result' : 'Results'} for ${params.searchQuery}`,
    'view.search_page.message.no_search_query': `Type in search field to start your search.`,
    'view.search_page.message.no_media_for_search_query': (params) => `No media found for your search: '${params.searchQuery}'${params.filtersActive ? ', and filter selection' : ''}.`,
    'view.cta_action_modal.title.download_native_app': `Watch Videos on mobile`,
    'view.cta_action_modal.message.download_native_app': `In order to watch videos you need to download our mobile app.`,
    'view.cta_action_modal.message.get_app': `Get the App`,
    'view.cta_action_modal.title.browser_unsupported': `Store Downloads app`,
    'view.cta_action_modal.message.browser_unsupported': `You are on a device that does not support silverlight. For more details
                        on compatible devices & browsers check 'Getting Started' section in our FAQs.`,
    'view.cta_action_modal.message.download_store_app': `In order to download and watch movies offline you need to
                        install Store Downloads app. Already installed? Open the app. Not got the app? Click Download.`,
    'view.close_account_modal.title': `Close account`,
    'view.close_account_modal.message.close_account': (params) => `To close your account please email customer services on ${params.CUSTOMER_SUPPORT_EMAIL}`,
    'view.collection_page.message.collection_not_found': `Media collection not found.`,
    'view.collection_page.message.no_media_in_collection': (params) => `No media found in collection '${params.collectionTitle}'`,
    'view.bundle_page.movie_count': (params) => `This special offer contains ${params.itemCount} movies`,
    'view.bundle_page.season_count': (params) => `This special offer contains ${params.itemCount} seasons`,
    'view.promotion_page.message.promotion_not_found': `Media promotion not found.`,
    'view.promotion_page.message.no_media_in_promotion': (params) => `No media found in promotion '${params.promotionTitle}'`,
    'view.genre_page.message.no_media_for_genre': (params) => `No media found for '${params.genreName}' genre`,
    'view.genre_page.message.no_media_for_name': (params) => `No media found for name: ${params.castFullName}`,
    'view.payment_page.title': `Review order & pay`,
    'view.payment_page.title.step_1': `Step 1 of 2`,
    'view.payment_page.title.step_2': `Step 2 of 2`,
    'view.payment_page.title.transaction_details': `Transaction Details`,
    'view.payment_page.title.thank_you': `Thank you for your payment`,
    'view.payment_page.title.what_next': `What would you like to do next?`,
    'view.payment_page.title.delivery_information': `Your DVD will be delivered to the following address within 7 days`,
    'view.payment_page.billing.title.movie': `Your billing and DVD delivery address is:`,
    'view.payment_page.billing.title.series': `Your billing address is:`,
    'view.payment_page.billing.annotation.movie': `You should usually get this within 2 weeks of the DVD’s official release date. The delivery address must be the same as your payment card billing address.`,
    'view.payment_page.billing.annotation.series': ``,
    'view.payment_page.billing.annotation.contact': `If you want to update your address, go to 'My Account'`,
    'view.payment_page.offer.availability': `Available on:`,
    'view.payment_page.offer.limits': `Unlimited viewing on up to 5 devices at the same time.`,
    'view.payment_page.offer.fvod_status.step_1': `Add to library`,
    'view.payment_page.offer.svod_status.step_1': `Add to library`,
    'view.payment_page.offer.est_status.step_1': `Buy HD + DVD`,
    'view.payment_page.offer.dtr_status.step_1': `Rent HD`,
    'view.payment_page.offer.status.step_2': `Purchased`,
    'view.payment_page.offer.delivery': `Free delivery`,
    'view.payment_page.offer.vat': `VAT(20%)`,
    'view.payment_page.offer.total_price': `Total`,
    'view.payment_page.card.name': `Name on card:`,
    'view.payment_page.card.number': `Card number`,
    'view.payment_page.card.cvc': `CVV number`,
    'view.payment_page.card.what_is_cvc': `What's CVV?`,
    'view.payment_page.card.cvc_explanation': `Its a little code to help keep you secure when making online purchases. This three or four digit Card Verification Value is printed on your debit or credit card, usually at the top of the signature strip on the back.`,
    'view.payment_page.card.expiry_date': `Expires on`,
    'view.payment_page.card.address_line_1': `Address`,
    'view.payment_page.card.address_line_2': `Address line 2`,
    'view.payment_page.card.city': `City / Town`,
    'view.payment_page.card.zip': `Postcode`,
    'view.payment_page.card.country': `Country`,
    'view.payment_page.voucher.label': `Use voucher`,
    'view.payment_page.voucher.message.apply': `Apply a discount using a voucher code:`,
    'view.payment_page.confirm_purchase.title.movie': `Buy & Keep`,
    'view.payment_page.confirm_purchase.title.series': `Buy`,
    'view.payment_page.successful.message.title': `Thank you!`,
    'view.payment_page.successful.message.payment_successful': `Your purchase is now in My Library.`,
    'view.payment_page.successful.message.receipt_sent': `Your receipt has been sent to `,
    'view.payment_page.successful.message.payment_successful.series': `Your purchase is now in My Library. We are sending your receipt to `,
    'view.payment_page.successful.message.what_next': `What would you like to do next?`,
    'view.pre_order_available_overlay.dont_remind_me': "Don't remind me again",
    'view.pre_order_available_overlay.media_item_available': 'This title in My Wishlist has now been released and is available for purchase',
    'view.library_page.title': `My Library`,
    'view.library_page.title.purchased_media': `Purchased media`,
    'view.library_page.message.no_media_in_library': `There is no media in your library`,
    'view.media_player.message.not_supported': `Your browser does not support the video tag.`,
    'view.media_player.controls.subtitles': `Subtitles`,
    'view.media_player.title.resume_playback': `Resume playback`,
    'view.media_player.title.new_device_detected': `New device detected!`,
    'view.media_player.title.device_limit_exceeded': `Device limit exceeded!`,
    'view.media_player.message.device_limit_status': (params) => `${params.registeredDevicesCount} out of ${params.REGISTERED_DEVICE_LIMIT}`,
    'view.media_player.message.device_limit_exceeded': `You have exceeded the limit on number of devices. You can de-register another device that is no longer in use by going to settings.`,
    'view.media_player.title.note': `Note`,
    'view.media_player.message.reload_page_to_continue': `No available actions. Please reload page to continue.`,
    'view.media_player.title.stream_limit_exceeded': `Warning`,
    'view.media_player.message.stream_limit_exceeded': `You have exceed the limit on number of currently playing items. Please stop watching one of the other items on your devices in order to start this item.`,
    'view.media_player.title.error': `Error`,
    'view.media_player.message.something_went_wrong': (params) => `Something went wrong. Please close player or reload application. ${params.errorMessage ? 'Error: ' + params.errorMessage : ''}`,
    'view.media_player.controls.settings': `Settings`,
    'view.media_player.controls.continue': `Continue`,
    'view.media_player.controls.cancel': `Cancel`,
    'view.media_player.controls.close': `Close`,
    'view.account_page.title.update_profile': `My Store sign in`,
    'view.account_page.message.accept_terms_and_conditions': `By creating an account you agree to the website `,
    'view.account_page.message.accept_terms_and_conditions_link_text': `Terms & Conditions.`,
    'view.account_page.message.accept_privacy_policy_text': ` All information entered will be stored and processed in line with our`,
    'view.account_page.message.accept_policy_link_text': `data privacy policy.`,
    //'view.account_page.message.accept_terms_and_conditions': `I accept the Virgin Media Store Terms and Conditions`,
    'view.account_page.message.accept_terms_and_conditions_annotation': `Please scroll down to read the Terms & Conditions and tick the box to accept and continue.`,
    //'view.account_page.message.payment_agreement': `Tick this box to confirm you agree the card above will be charged for purchases in the Virgin Media Store.`,
    'view.account_page.message.payment_agreement': `Any purchase that you make on Store will be charges to this card.
Once you save your card details you can use your TV PIN to confirm purchase on your Virgin TV.
You can change your payment details anytime by going to MyAccount.`,
    'view.account_page.message.newsletter_opt_in.text': `Tick this box to say "Oh yes! I want to get updates from Virgin Media Store!" and you agree to share your contact details so that we can keep in touch (which may be by email, post, sms or phone).`,
    'view.account_page.message.newsletter_opt_in_update.text': `Receive updates by email, post, sms or phone`,
    // 'view.account_page.message.newsletter_opt_in.text': `We would like to keep you informed about our exciting offers, products
    //     and services. By ticking the box above you are giving Virgin Media permission to keep in touch by post, phone and email.`,
    'view.payment_page.card.address_block_label': `Street address:`,
    'view.account_page.message.newsletter_opt_in.text_2': `Fear of commitment? That's perfectly natural, so if you change your mind later just tick another little box in 'My Account' on this website to stop getting updates forever. We won't judge.`,
    'view.account_page.message.gdpr.text_link': `General Data Protection Regulations`,
    'view.account_page.message.gdpr.paragraph_text': (params) => `Obviously we want to stay in your good books, so we keep your personal details private and
        treat them with the utmost care (find out more <a href='${params.termsAndConditionsLink}' target="_blank">here</a>), and fully comply with your data subject rights under the  <a href='${params.gdprLink}' target="_blank">General Data Protection Regulations</a>`,

    'view.account_page.message.newsletter_opt_in.text_3_link': `Privacy Policy`,
    'view.account_page.message.newsletter_opt_in.popup_info_header': `Keeping in touch with us`,
    'view.account_page.message.newsletter_opt_in.popup_info_text': `By clicking on 'keep me in touch' and signing up to Virgin Media Store you are giving Virgin Media permission to keep in touch by the post, phone and email so that we can tell you about our offers, products and services.`,
    'view.account_page.message.rename_device_title': `Rename device`,
    'view.account_page.message.rename_device_message': `Enter new name for device`,
    'view.account_page.message.signup_info': `Your sign in details will be the ones you used to sign up to Store, not your Virgin Media or Mobile account.`,
    'view.account_page.message.signup_link': `New to Virgin Media Store? Sign Up`,
    'view.account_page.title.device_settings': `My devices`,
    'view.account_page.title_text': `The fastest way to our hottest releases`,
    'view.account_page.subtitle_text': `Sign up now to buy edge-of-your-seat blockbusters and binge-worthy TV Box Sets. Break out the popcorn to watch them at home, or on the go.`,
    'view.account_sign_in_page.title_text': `Welcome Back`,
    'view.account_sign_in_page.subtitle_text': `We've got loads of the latest movies & must-see TV Box Sets waiting for you to check out.`,
    'view.account_page.date_time.removal_available_date': `Do MMMM`,
    'view.account_page_set_payment.title_text': `Save a payment card for purchases`,
    'view.account_page_set_payment.cvv_placeholder': `e.g. 123`,
    'view.account_page_set_payment.card_name_placeholder': `e.g. Mr John Smith`,
    'view.account_page_set_payment.subtitle_text': `Your card will only be charged when you confirm a purchase. For now, we'll just save your card details and keep them safe.`,
    'view.account_marketing_opt_in.title_text': `Stay in the loop`,
    'view.account_marketing_opt_in.subtitle_text': `What brilliant offers, rewards and product news will you miss out on if you don't tick that little tick box down there? We think you'll want to know...`,
    'view.account_page_set_payment.payment_card_standards': ` As you'd expect, we comply with Payment Card Industry Data Security Standards.`,
    'view.account_page_set_payment.payment_card_types_info': `We accept Visa, Debit, Credit, MasterCard and American Express`,
    'view.account_page_set_payment.billing_title_address_text': `Billing address`,
    'view.account_page_set_payment.billing_address_line_1_placeholder': `Address line 1`,
    'view.account_page_set_payment.billing_address_line_2_placeholder': `Address line 2 (optional)`,
    'view.account_page_set_payment.billing_address_line_3_placeholder': `Address line 3 (optional)`,
    'view.account_page_set_payment.billing_address_city_placeholder': `City / Town (optional)`,
    'view.account_page_set_payment.billing_address_county_placeholder': `County (optional)`,
    'view.account_page_set_payment.billing_address_subtitle_text': `We'll only be able to accept UK addresses. It's also where we'll send your brand new DVDs.`,
    'view.account_page_set_payment.skip_text': `Don't want to fill this now?`,
    'view.account_page_set_payment.zip_code_placeholder': `Your postcode (optional)`,
    'view.account_page_set_payment.zip_code_last_placeholder': `e.g. LD1 1DL`,
    'view.account_page_set_payment.address_city_placeholder': `e.g. London`,
    'view.my-account_page_set_payment.payment_not_set': `You haven’t saved your card details. Click Add Card to save your payment details and make Store purchases on your Virgin TV.`,
    'view.stb-pairing-page.title': `Connect your Virgin TV`,
    'view.stb-pairing-page.label.stb-code-input-label': `Connect your Virgin TV`,
    'view.stb-pairing-device-limit-reached': `Sorry, you’ve got the maximum 5 devices registered. Please delete one of them and try again.`,
    'view-stb-pairing.notification-error': `Sorry, you’ve used the wrong pairing code. Please try again.`,
    'view.account_page.message.remove_stb_device': `Please call 0800 027 0801 to remove this device.`,
    'view.my-account_page_gdpr_button_info_text': `Select to receieve the latest offers and news from Virgin Media Store`,
    'view.payment_page.successful.title': ``,
    'view.navigation.label.sign_in_sign_up': `Sign in/Sign up`,
    'view.navigation.label.sign_in': `Sign in`,
    'view.navigation.label.sign_up': `Sign up`,
    'view.navigation.label.sign_up_special_note': `Please note this is not the sign in for your Virgin Media or Mobile account.`,
    'view.navigation.label.sign_out': `Sign out`,
    'view.navigation.label.sign_out_popup_title': `Sign out`,
    'view.navigation.label.sign_out_popup_info_text': `Are you sure you want to leave Virgin Media Store?`,
    'view.navigation.label.watchlist': `My Wish List`,
    'view.navigation.label.about': `About Us`,
    'view.navigation.label.braodband': `BROADBAND & TV DEALS`,
    'view.navigation.label.help': `Help & FAQs`,
    'view.navigation.label.terms_of_use': `Terms and conditions`,
    'view.navigation.label.contact': `Contact us`,
    'view.navigation.label.MoviePageRowOrder': `Movies`,
    'view.navigation.label.TVPageRowOrder': `TV Shows`,
    'view.navigation.label.StorefrontPageRowOrder': `Collections`,
    'view.navigation.label.BackNavButton': `Back`,
    'view.cta_action_modal.title.download_store_app': `Store downloads app`,
    'view.cta_action_modal.title.finish_payment_setup': `NOTE`,
    'view.cta_action_modal.message.finish_payment_setup': `We've noticed you haven't added your payment details yet, which you need to do before making a purchase.`,
    'button.cta.go_to_my_account': `Add payment card`,
    'view.recently_viewed_carousel.title': `Continue watching`,
    'view.footer.message.copyright_reserved': `All rights reserved.`,
    'view.footer.message.copyright_company': (params) => `© ${params.year} VirginMedia.com`,
    'view.share_email.subject': (params) => `Virgin media – „${params.mediaItem.title}“`,
    'view.share_email.body': (params) => `I found the „${params.mediaItem.title}“ on Virgin media:%0D%0A ${params.link}`,
    'view.media_player.message.resume_playback_available': ``, // no text
    'view.media_player.controls.start_from_beginning': `Start from beginning`,
    'view.media_player.controls.resume_from_last_play_position': `Resume playing`,
    'view.watchlist_page.title': `My Wish List`,
    'view.watchlist_page.message.no_media_on_watchlist': `There is no media on your wish list.`,
    'view.media_player.message.add_device': (params) => `You have ${params.registeredDevicesFreeSlotCount} device slots available. Would you like to add this device to your Virgin Media account?`,
    'view.update-payment-popup.payment-info-header': `My payment card details`,
    'view.update-payment-popup.payment-page-header': `Add/change payment card details`,

    // stb pairing

    'stb-pairing-popup-success-productpurchase-header': `Almost there!`,
    'stb-pairing-popup-success-productpurchase-msg-1': `We’ve saved your payment card details.`,
    'stb-pairing-popup-success-productpurchase-msg-2': `You can now pick up your Virgin TV remote and complete your purchase on your TV.`,
    'stb-pairing-cc-not-added-msg': `It looks like you haven't added a payment card to your account. Please note that you will not be able to complete your purchase without adding a credit card.`,
    'view.stb-pairing-popup-success-message-header': `Sign in Successful`,
    'view.stb-pairing-popup-success-message': `You can now pick up your Virgin TV remote and enjoy your favourite movies and TV shows on Store.`,
    'view.stb-pairing-popup-success-message-link': `Click here to add or amend your card details.`,
    'view.stb-pairing-device_limit_reached': `Error. Device limit reached`,
    'view.stb-pairing-error': `Error. Please try again, if the problem persists please contact customer services.`,

    //links
    'receive_marketing_link': '',
    'gdpr_info_link': '',

    //error subcodes
    'error_code_1': `Error 1. Please try again, if the problem persists please contact customer services.`,
    'error_code_2': `Error 2. Please try again, if the problem persists please contact customer services.`,
    'error_code_3': `No EST frontend impact`,
    'error_code_4': `Error 4. Please try again, if the problem persists please contact customer services.`,
    'error_code_5': `Error 5. Please try again, if the problem persists please contact customer services.`,
    'error_code_6': `Error 6. Please try again, if the problem persists please contact customer services.`,
    'error_code_7': `No EST frontend impact`,
    'error_code_8': `No EST frontend impact`,
    'error_code_9': `No EST frontend impact`,
    'error_code_10': `Error 10. Please try again, if the problem persists please contact customer services.`,
    'error_code_11': `Error 11. Please try again, if the problem persists please contact customer services.`,
    'error_code_12': `No EST frontend impact`,
    'error_code_14': `Error 14. Please try again, if the problem persists please contact customer services.`,
    'error_code_15': `Error 15. Please try again, if the problem persists please contact customer services.`,
    'error_code_16': `Error 16. Please try again, if the problem persists please contact customer services.`,
    'error_code_17': `Error 17. Please try again, if the problem persists please contact customer services.`,
    'error_code_18': `Error 18. Please try again, if the problem persists please contact customer services.`,
    'error_code_19': `Error 19. Please try again, if the problem persists please contact customer services.`,
    'error_code_20': `Error 20. Please try again, if the problem persists please contact customer services.`,
    'error_code_21': `Error 21. Please try again, if the problem persists please contact customer services.`,
    'error_code_23': `Error 23. Please try again, if the problem persists please contact customer services.`,
    'error_code_24': `Error 24. Please try again, if the problem persists please contact customer services.`,
    'error_code_25': `Error 25. Please try again, if the problem persists please contact customer services.`,
    'error_code_26': `Error 26. Please try again, if the problem persists please contact customer services.`,
    'error_code_27': `No EST frontend impact`,
    'error_code_28': `No EST frontend impact`,
    'error_code_29': `Error 29. Please try again, if the problem persists please contact customer services.`,
    'error_code_30': `Error 30. Please try again, if the problem persists please contact customer services.`,
    'error_code_31': `Error 31. Please try again, if the problem persists please contact customer services.`,
    'error_code_32': `Error 32. Please try again, if the problem persists please contact customer services.`,
    'error_code_33': `Error 33. Please try again, if the problem persists please contact customer services.`,
    'error_code_34': `Error 34. Please try again, if the problem persists please contact customer services.`,
    'error_code_35': `Error 35. Please try again, if the problem persists please contact customer services.`,
    'error_code_36': `Error 36. Please try again, if the problem persists please contact customer services.`,
    'error_code_37': `Error 37. Please try again, if the problem persists please contact customer services.`,
    'error_code_38': `Content can only be streamed or downloaded from within the United Kingdom.`,
    'error_code_39': `Please log in again to update your password.`,
    'error_code_40': `No EST frontend impact`,
    'error_code_41': `Error 41. Please try again, if the problem persists please contact customer services.`,
    'error_code_42': `Too many failed attempts. Please try again in 24 hours or contact customer services. `,
    'error_code_44': `Error 44. Please try again, if the problem persists please contact customer services.`,
    'error_code_45': `Error 45. Please try again, if the problem persists please contact customer services.`,
    'error_code_46': `Email address already registred`,
    'error_code_47': `Error 47. Please try again, if the problem persists please contact customer services.`,
    'error_code_48': `Password needs to be 8 characters or more, have at least one upper case letter, one lower case letter and one number.`,
    'error_code_49': `Error 49. Please try again, if the problem persists please contact customer services.`,
    'error_code_50': `Please enter a valid email address`,
    'error_code_51': `Please enter a valid email address`,
    'error_code_52': `Error 52. Please try again, if the problem persists please contact customer services.`,
    'error_code_53': `Error 53. Please try again, if the problem persists please contact customer services.`,
    'error_code_54': `Error 54. Please try again, if the problem persists please contact customer services.`,
    'error_code_55': `Password not recognised`,
    'error_code_56': `Error 56. Please try again, if the problem persists please contact customer services.`,
    'error_code_58': `Error 58. Please try again, if the problem persists please contact customer services.`,
    'error_code_59': `Error 59. Please try again, if the problem persists please contact customer services.`,
    'error_code_61': `Error 61. Please try again, if the problem persists please contact customer services.`,
    'error_code_62': `Accept Terms & Conditions to continue`,
    'error_code_63': `No EST frontend impact`,
    'error_code_64': `No EST frontend impact`,
    'error_code_65': `No EST frontend impact`,
    'error_code_66': `Please enter a valid email address`,
    'error_code_67': `Error 67. Please try again, if the problem persists please contact customer services.`,
    'error_code_70': `Error 70. Please try again, if the problem persists please contact customer services.`,
    'error_code_71': `Password not recognised`,
    'error_code_72': `Error 72. Please try again, if the problem persists please contact customer services.`,
    'error_code_73': `Error 73. Please try again, if the problem persists please contact customer services.`,
    'error_code_74': `Error 74. Please try again, if the problem persists please contact customer services.`,
    'error_code_75': `No EST frontend impact`,
    'error_code_76': `Password not recognised`,
    'error_code_77': `Please enter a valid email address`,
    'error_code_78': `Error 78. Please try again, if the problem persists please contact customer services.`,
    'error_code_79': `Error 79. Please try again, if the problem persists please contact customer services.`,
    'error_code_81': `Error 81. Please try again, if the problem persists please contact customer services.`,
    'error_code_82': `No EST frontend impact`,
    'error_code_83': `Error 83. Please try again, if the problem persists please contact customer services.`,
    'error_code_84': `Error 84. Please try again, if the problem persists please contact customer services.`,
    'error_code_85': `Error 85. Please try again, if the problem persists please contact customer services.`,
    'error_code_87': `Error 87. Please try again, if the problem persists please contact customer services.`,
    'error_code_92': `Error 92. Please try again, if the problem persists please contact customer services.`,
    'error_code_95': `No EST frontend impact`,
    'error_code_96': `No EST frontend impact`,
    'error_code_97': `No EST frontend impact`,
    'error_code_98': `No EST frontend impact`,
    'error_code_99': `Error 99. Please try again, if the problem persists please contact customer services.`,
    'error_code_100': `Error 100. Please try again, if the problem persists please contact customer services.`,
    'error_code_101': `Error 101. Please try again, if the problem persists please contact customer services.`,
    'error_code_102': `Error 102. Please try again, if the problem persists please contact customer services.`,
    'error_code_103': `Error 103. Please try again, if the problem persists please contact customer services.`,
    'error_code_105': `Error 105. Please try again, if the problem persists please contact customer services.`,
    'error_code_106': `No EST frontend impact`,
    'error_code_107': `Error 107. Please try again, if the problem persists please contact customer services.`,
    'error_code_110': `No EST frontend impact`,
    'error_code_111': `Error 111. Please try again, if the problem persists please contact customer services.`,
    'error_code_115': `No EST frontend impact`,
    'error_code_116': `No EST frontend impact`,
    'error_code_117': `No EST frontend impact`,
    'error_code_118': `No EST frontend impact`,
    'error_code_119': `Voucher not valid`,
    'error_code_121': `Error 121. Please try again, if the problem persists please contact customer services.`,
    'error_code_123': `No EST frontend impact`,
    'error_code_124': `No EST frontend impact`,
    'error_code_125': `No EST frontend impact`,
    'error_code_126': ``,
    'error_code_127': ``,
    'error_code_128': ``,
    'error_code_129': `Error 129. Please try again, if the problem persists please contact customer services.`,
    'error_code_130': `Error 130. Please try again, if the problem persists please contact customer services.`,
    'error_code_131': ``,
    'error_code_132': `Error 132. Please try again, if the problem persists please contact customer services.`,
    'error_code_133': `Error 133. Please try again, if the problem persists please contact customer services.`,
    'error_code_134': ``,
    'error_code_135': ``,
    'error_code_136': `No EST frontend impact`,
    'error_code_137': `Error 137. Please try again, if the problem persists please contact customer services.`,
    'error_code_138': `Error 138. Please try again, if the problem persists please contact customer services.`,
    'error_code_139': `No EST frontend impact`,
    'error_code_140': `No EST frontend impact`,
    'error_code_141': `Error 141. Please try again, if the problem persists please contact customer services.`,
    'error_code_142': `Error 142. Please try again, if the problem persists please contact customer services.`,
    'error_code_143': `Error 143. Please try again, if the problem persists please contact customer services.`,
    'error_code_144': ``,
    'error_code_145': `No EST frontend impact`,
    'error_code_146': ``,
    'error_code_147': `Error 147. Please try again, if the problem persists please contact customer services.`,
    'error_code_148': `No EST frontend impact`,
    'error_code_149': `Error 149. Please try again, if the problem persists please contact customer services.`,
    'error_code_150': `Please enter a device name`,
    'error_code_151': `No EST frontend impact`,
    'error_code_152': `No EST frontend impact`,
    'error_code_154': `No EST frontend impact`,
    'error_code_155': `No EST frontend impact`,
    'error_code_156': `No EST frontend impact`,
    'error_code_157': `Error 157. Please try again, if the problem persists please contact customer services.`,
    'error_code_158': `No EST frontend impact`,
    'error_code_159': `Error 159. Please try again, if the problem persists please contact customer services.`,
    'error_code_160': `Error 160. Please try again, if the problem persists please contact customer services.`,
    'error_code_161': ``,
    'error_code_162': `Error 162. Please try again, if the problem persists please contact customer services.`,
    'error_code_163': `Error 163. Please try again, if the problem persists please contact customer services.`,
    'error_code_164': `Error 164. Please try again, if the problem persists please contact customer services.`,
    'error_code_165': `Error 165. Please try again, if the problem persists please contact customer services.`,
    'error_code_166': `No EST frontend impact`,
    'error_code_167': `Error 167. Please try again, if the problem persists please contact customer services.`,
    'error_code_168': `Error 168. Please try again, if the problem persists please contact customer services.`,
    'error_code_169': `Error 169. Please try again, if the problem persists please contact customer services.`,
    'error_code_170': `Error 170. Please try again, if the problem persists please contact customer services.`,
    'error_code_171': `Error 171. Please try again, if the problem persists please contact customer services.`,
    'error_code_172': `Error 172. Please try again, if the problem persists please contact customer services.`,
    'error_code_173': `Please enter a voucher code`,
    'error_code_174': `Please enter a valid email address`,
    'error_code_175': `Error 175. Please try again, if the problem persists please contact customer services.`,
    'error_code_176': `Voucher not found, please try again.`,
    'error_code_177': `Voucher not found, please try again.`,
    'error_code_178': `Voucher not found, please try again.`,
    'error_code_179': `Voucher not found, please try again.`,
    'error_code_180': `Voucher not found, please try again.`,
    'error_code_181': `Voucher not found, please try again.`,
    'error_code_182': `Voucher not found, please try again.`,
    'error_code_183': `Error 183. Please try again, if the problem persists please contact customer services.`,
    'error_code_184': `Error 184. Please try again, if the problem persists please contact customer services.`,
    'error_code_185': `Error 185. Please try again, if the problem persists please contact customer services.`,
    'error_code_186': `Error 186. Please try again, if the problem persists please contact customer services.`,
    'error_code_187': `Error 187. Please try again, if the problem persists please contact customer services.`,
    'error_code_188': `Error 188. Please try again, if the problem persists please contact customer services.`,
    'error_code_189': `Password needs to be 8 characters or more, have at least one upper case letter, one lower case letter and one number.`,
    'error_code_190': ``,
    'error_code_191': ``,
    'error_code_192': `Error 192. Please try again, if the problem persists please contact customer services.`,
    'error_code_193': `Error 193. Please try again, if the problem persists please contact customer services.`,
    'error_code_194': `Error 194. Please try again, if the problem persists please contact customer services.`,
    'error_code_195': ``,
    'error_code_196': ``,
    'error_code_197': `Error 197. Please try again, if the problem persists please contact customer services.`,
    'error_code_57': `Your login details were not recognised. Please re-enter and try again.`,
};

export default Lang;
