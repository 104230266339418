"use strict";

import Config from '../../lib/Config';
import {mixin} from '../../utils/Common';

/**
 * MediaItemImageTools decorator
 */
const MediaItemImageTools = mixin({
    getImageLocation(imageType) {
        let furnitureImage = null;
        // optimal portrait image is one bigger than 240x336, we'll be using 325x455
        let optimalPortraitImage = null;
        let backupLandscapeFurnitureImage = null;

        this.furniture.forEach((furnitureItem) => {
            switch (imageType) {
                case 'poster':
                    if (furnitureItem.furnitureType.indexOf('portrait') > -1 && !optimalPortraitImage) {
                        if (furnitureItem.furnitureType.indexOf('325') > -1) {
                            optimalPortraitImage = furnitureItem;
                            furnitureImage = furnitureItem;
                        } else if (!furnitureImage) {
                            furnitureImage = furnitureItem;
                        }
                    }
                    break;
                case 'backdrop':
                    if (furnitureItem.furnitureType.indexOf('landscape') > -1
                        && furnitureItem.furnitureType.indexOf('landscape3') === -1
                        && !furnitureImage) {
                        furnitureImage = furnitureItem;
                    }
                    break;
                case 'background':
                    if (furnitureItem.furnitureType.indexOf('landscape2') > -1 && !furnitureImage) {
                        furnitureImage = furnitureItem;
                    }
                    break;
                case 'trailer':
                    if (furnitureItem.furnitureType.indexOf('landscape1') > -1 && !furnitureImage) {
                        furnitureImage = furnitureItem;
                    }
                    break;
                case 'landscape':
                    if (furnitureItem.furnitureType.indexOf('landscape') > -1
                        && furnitureItem.furnitureType.indexOf('landscape3') === -1
                        && !furnitureImage) {
                        furnitureImage = furnitureItem;
                    }
                    break;
                default:
                    if (furnitureItem.furnitureType.indexOf(imageType) > -1
                        && furnitureItem.furnitureType.indexOf('landscape3') === -1
                        && !furnitureImage) {
                        furnitureImage = furnitureItem;
                    }
                    break;
            }

            if (furnitureItem.furnitureType.indexOf('landscape') > -1
                && furnitureItem.furnitureType.indexOf('landscape3') === -1
                && !backupLandscapeFurnitureImage) {
                backupLandscapeFurnitureImage = furnitureItem;
            }
        });

        return furnitureImage
            ? this.furnitureBaseUrl + furnitureImage.uri + Config.IMAGE_OPTIMISATION_LEVEL
            : (imageType === 'poster'
                    ? Config.PLACEHOLDER_IMAGE_POSTER
                    : (
                        backupLandscapeFurnitureImage ? this.furnitureBaseUrl + backupLandscapeFurnitureImage.uri + Config.IMAGE_OPTIMISATION_LEVEL:
                            Config.PLACEHOLDER_IMAGE_BACKDROP)
            );
    }
});

export default MediaItemImageTools;
