"use strict";

import React from 'react';
import BluredImageSvg from './ui/BluredImageSvg.react';

/**
 * TestPage component
 */
export default class TestPage extends React.Component {
    /**
     * React: state
     */
    state = {
        collectionList: null
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        // MediaStore.addUpdateListener(this._setCollectionList);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        // MediaStore.removeUpdateListener(this._setCollectionList);
    }

    /**
     * React: render
     */
    render() {


        return (
            <main className="test-page">
                hello world

                <div className="profile blur-wrapper">
                    <BluredImageSvg imageUrl={'https://c2.staticflickr.com/2/1025/810058286_ac3f5f7521.jpg'}/>
                </div>
            </main>
        );
    }

}
