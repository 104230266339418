"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import { MediaItemClassification } from '../../types/MediaItem';
import EntitlementActions from '../../actions/EntitlementActions';
import MediaActions from '../../actions/MediaActions';
import EntitlementStore from '../../stores/EntitlementStore';
import MediaStore from '../../stores/MediaStore';
import SilverlightPlayer from './media-player/SilverlightPlayer.react';
import ShakaPlayer from './media-player/ShakaPlayer.react';
import AuthStore from '../../stores/AuthStore';

/**
 * MediaPlayerOverlay component
 */
class MediaPlayerOverlay extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        playerType: PropTypes.oneOf(['html5', 'azure', 'silverlight', 'shaka']),
        displayControls: PropTypes.bool,
        autoPlay: PropTypes.bool
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        playerType: 'silverlight'
    };

    /**
     * React: state
     */
    state = {
        mediaItem: null,
        playerAction: 'play'
    };

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        EntitlementStore.addUpdateListener(this._setMediaItem);
        this._closePlayer = this._closePlayer.bind(this);
    }

    /**
     * React: UNSAFE_componentWillUpdate
     */
    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (nextState.mediaItem) {
            document.getElementsByTagName('body')[0].classList.add('no-scroll');
        } else {
            document.getElementsByTagName('body')[0].classList.remove('no-scroll');
        }
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        EntitlementStore.removeUpdateListener(this._setMediaItem);
    }

    /**
     * React: shouldComponentUpdate
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        let mediaItem = this.state.mediaItem;
        let mediaBucket = mediaItem ? MediaStore.getMediaBucket(mediaItem.bucketKey) : null;
        let seriesMediaItem = mediaBucket ? MediaStore.getMediaBucket(mediaItem.bucketKey).mediaItemRootParent(mediaItem) : null;
        let playlistMedia = mediaBucket && mediaItem.classification === MediaItemClassification.SEASON
            ? mediaBucket.mediaItemChildren(mediaItem)
            : (mediaBucket && mediaItem.classification === MediaItemClassification.EPISODE
                ? mediaBucket.mediaItemChildren(mediaBucket.mediaItemParent(mediaItem))
                : null);

        return mediaItem ? (
            <div className="media-player-overlay">
                <div className="player-container">
                    {this.props.playerType === 'silverlight' || this.state.playerAction === 'download' ? (
                        <SilverlightPlayer mediaItem={mediaItem}
                            playerAction={this.state.playerAction}
                            displayControls={this.props.displayControls}
                            autoPlay={this.props.autoPlay} />
                    ) : null}
                    {this.props.playerType === 'shaka' && this.state.playerAction !== 'download' ? (
                        <ShakaPlayer mediaItem={mediaItem}
                            playlistMedia={playlistMedia}
                            seriesMediaItem={seriesMediaItem}
                            playerAction={this.state.playerAction}
                            displayControls={this.props.displayControls}
                            autoPlay={this.props.autoPlay}
                            closePlayer={this._closePlayer} />
                    ) : null}
                </div>
                <button className="close" onClick={this._closePlayer} />
            </div>
        ) : null;
    }

    /**
     * Set mediaItem
     */
    _setMediaItem = () => {
        this.setState({
            mediaItem: EntitlementStore.playerMediaItem,
            playerAction: EntitlementStore.playerAction
        });
    };

    /**
     * Close player
     *
     * @private
     */
    _closePlayer = () => {
        EntitlementActions.stopMediaItemPlayback();
        if (AuthStore.isSignedIn()) {
            MediaActions.getRecentlyViewedMedia();
        }
    };
}

export default MediaPlayerOverlay;
