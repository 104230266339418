"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import InputUIs from '../mixins/InputUIs';

/**
 * Checkbox component
 */
export default class TextInput extends InputUIs(React.Component) {
    /**
     * React: propTypes
     */
    static propTypes = {
        type: PropTypes.oneOf(['text', 'email', 'password']),
        value: PropTypes.string,
        label: PropTypes.node,
        disabled: PropTypes.bool,
        inlineLabel: PropTypes.bool,
        inline: PropTypes.bool,
        useStatusIcon: PropTypes.bool,
        statusIcon: PropTypes.string,
        onChange: PropTypes.func,
        onKeyPress: PropTypes.func,
        onClick: PropTypes.func,
        error: PropTypes.string,
        placeholder: PropTypes.string,
        labelStyle: PropTypes.object,
        inputStyle: PropTypes.object,
        inputClassName: PropTypes.string,
        labelClassName: PropTypes.string,
        maxLength: PropTypes.number
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        type: 'text',
        value: '',
        key: '',
        event: {},
        disabled: false,
        inlineLabel: true,
        inline: false,
        useStatusIcon: false,
        maxLength: null
    };

    /**
     * React: state
     */
    state = {
        value: this.props.value
    };

    /**
     * React: UNSAFE_componentWillReceiveProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value
        });
    }

    /**
     * React: render
     */
    render() {
        var className = 'ui-text-input ' +
            (this.props.inputClassName ? this.props.inputClassName : '') +
            (this.props.useStatusIcon ? 'use-status-icon ' : '') +
            (this.props.statusIcon == 'success' ? 'status-success ' : '') +
            (this.props.statusIcon == 'error' ? 'status-error ' : '');
        var error = this.props.error ? (<div className="error-message">{this.props.error}</div>) : '';
        var rootClassName = "ui-input" +
            (this.props.inlineLabel ? ' inline-label' : '') +
            (this.props.inline ? ' inline' : '') +
            (this.props.error ? ' show-error' : '');
        return (
            <div className={rootClassName}>
                {(this.props.label && <label className={this.props.labelClassName} style={this.props.labelStyle}>{this.props.label}</label>)}
                <div className={className}>
                    <input type={this.props.type}
                           value={this.state.value}
                           onChange={this._onChange}
                           placeholder={this.props.placeholder}
                           onClick={this._onClick}
                           onKeyPress={this._onKeyPress}
                           disabled={this.props.disabled}
                           style={this.props.inputStyle}
                           maxLength={this.props.maxLength}/>
                    {error}
                </div>
            </div>
        );
    }

    /**
     * On change
     */
    _onChange = (event) => {
        this.setState({
            value: event.target.value
        }, () => {
            if (typeof this.props.onChange === 'function') {
                this.props.onChange(this.state.value);
            }
        });
    };

    /**
     * On click
     */
    _onClick = () => {
        if (typeof this.props.onClick === 'function') {
            this.props.onClick(this.state.value);
        }
    };

    /**
     * On onKeyPress
     */
    _onKeyPress = (event) => {
        if (typeof this.props.onKeyPress === 'function') {
            if (event.key === 'Enter') {
                event.preventDefault();
            }
            this.setState({
                key: event.key
            }, () => {
                this.props.onKeyPress(this.state.key);
            });
        }
    };

}
