"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import MediaItem, {MediaItemClassification} from '../../../types/MediaItem';
import MediaStore from '../../../stores/MediaStore';

/**
 * SeriesSeasons component
 */
export default class SeriesSeasons extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        mediaItem: PropTypes.instanceOf(MediaItem).isRequired,
        seasonMediaItem: PropTypes.instanceOf(MediaItem).isRequired,
        setMediaItemSeason: PropTypes.func
    };

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        var mediaItem = this.props.mediaItem;
        var seriesSeasons = [];
        if (mediaItem.classification === MediaItemClassification.SERIES) {
            MediaStore.getMediaBucket(mediaItem.bucketKey).mediaItemChildren(mediaItem).reverse().forEach((seasonMediaItem, key) => {
                let seasonMediaItemMedia = MediaStore.getMediaBucket(seasonMediaItem.bucketKey).mediaItemChildren(seasonMediaItem);

                if (seasonMediaItemMedia.toArray().some( el => el && el.offers.length !== 0)) {
                    seriesSeasons.push(
                        <option key={key} value={seasonMediaItem.id}>{seasonMediaItem.title} (1
                            - {seasonMediaItemMedia.size})</option>
                        );
                }
            });
        }

        return seriesSeasons.length > 0 ? (
                <div className="series-seasons col-xs-12 col-md-9 col-lg-8">
                    <div className="drop-down-wrapper">
                        <select className="drop-down full-width"
                                value={this.props.seasonMediaItem.id}
                                onChange={this._selectMediaItemSeason}>
                            {seriesSeasons}
                        </select>
                    </div>
                </div>
            ) : null;
    }

    /**
     * Select media item season
     *
     * @private
     */
    _selectMediaItemSeason = (event) => {
        if (typeof this.props.setMediaItemSeason === 'function') {
            this.props.setMediaItemSeason(event.target.value);
        }
    };
}
