"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import Immutable from 'immutable';
import AppStore from '../stores/AppStore';
import CollectionGallery from './elements/CollectionGallery.react';

/**
 * PromotionCollectionsPage component
 */
export default class PromotionCollectionsPage extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        params: PropTypes.object
    };

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        const promotion = this.props.params && this.props.params.promotionName
            ? AppStore.promotionListByName.get(this.props.params.promotionName)
            : null;
        const promotionCollections = promotion
            ? promotion.collectionItems.reduce((collectionList, promotionItem) => {
                let collection = AppStore.collectionListByTitle.get(promotionItem.promotionalLink);
                return collection ? collectionList.set(collection.id, collection) : collectionList;
            }, Immutable.OrderedMap())
            : null;

        return (
            <main>
                {!promotion ? (
                    <div className="no-results">
                        {AppStore.translate('view.promotion_page.message.promotion_not_found')}
                    </div>
                ) : null}

                {promotionCollections && promotionCollections.size === 0 ? (
                    <div className="no-results">
                        {AppStore.translate('view.promotion_page.message.no_media_in_promotion', {promotionTitle: promotion.title})}
                    </div>
                ) : null}

                {promotionCollections && promotionCollections.size > 0 ? (
                    <CollectionGallery
                        title={promotion.title}
                        tileType={'backdrop'}
                        collections={promotionCollections}
                    />
                ) : null}
            </main>
        );
    }
}
