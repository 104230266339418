"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import Router from '../lib/Router';
import AppStore from '../stores/AppStore';
import MediaActions from '../actions/MediaActions';
import MediaStore from '../stores/MediaStore';
import {MediaItemClassification} from '../types/MediaItem';
import Filter from '../lib/Filter';
import MediaGallery from './elements/MediaGallery.react';
import MediaCarouselTally from './elements/MediaCarouselTally.react';
import FilterBar from './elements/FilterBar.react';

/**
 * PromotionPage component
 */
export default class PromotionPage extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        params: PropTypes.object,
        location: PropTypes.object
    };

    /**
     * React: state
     */
    state = {
        activeFilters: AppStore.activeFilters,
        mediaBucket: null,
        mediaBucketHash: {}
    };

    /**
     * Bucket keys
     */
    _mediaBucketKey = null;
    _mediaBucketKeyHash = {};

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        if (this.props.params && this.props.params.promotionName && AppStore.promotionListByName.get(this.props.params.promotionName)) {
            let promotion = this.props.params && this.props.params.promotionName
                ? AppStore.promotionListByName.get(this.props.params.promotionName)
                : null;
            if (promotion) {
                this._mediaBucketKey = MediaActions.getPromotionMedia({
                    mediaItemIds: promotion.mediaItemItems.map(promotionItem => promotionItem.promotionalLink)
                });

                promotion.collectionItems.forEach(promotionItem => {
                    this._mediaBucketKeyHash[promotionItem.promotionalLink] = MediaActions.getCollectionMedia({
                        collectionTitle: promotionItem.promotionalLink
                    });
                });
            }
        }
    }

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        AppStore.addUpdateListener(this._setActiveFilters);
        MediaStore.addUpdateListener(this._setMediaBucket);
    }

    /**
     * React: UNSAFE_componentWillReceiveProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((nextProps.params && nextProps.params.promotionName && nextProps.params.promotionName !== this.props.params.promotionName && AppStore.promotionListByName.get(this.props.params.promotionName))
            || (nextProps.location.query && nextProps.location.query.classification && nextProps.location.query.classification !== this.props.location.query.classification)) {
            MediaStore.destroyMediaBucket(this._mediaBucketKey);
            this._mediaBucketKey = null;
            Object.keys(this._mediaBucketKeyHash).forEach(key => MediaStore.destroyMediaBucket(this._mediaBucketKeyHash[key]));
            this._mediaBucketKeyHash = {};
            this.setState({
                mediaBucket: null,
                mediaBucketHash: {}
            }, () => {
                let promotion = this.props.params && this.props.params.promotionName
                    ? AppStore.promotionListByName.get(this.props.params.promotionName)
                    : null;
                if (promotion) {
                    this._mediaBucketKey = MediaActions.getPromotionMedia({
                        mediaItemIds: promotion.mediaItemItems.map(promotionItem => promotionItem.promotionalLink)
                    });

                    promotion.collectionItems.forEach(promotionItem => {
                        this._mediaBucketKeyHash[promotionItem.promotionalLink] = MediaActions.getCollectionMedia({
                            collectionTitle: promotionItem.promotionalLink
                        });
                    });
                }
            });
        }
    }

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AppStore.removeUpdateListener(this._setActiveFilters);
        MediaStore.removeUpdateListener(this._setMediaBucket);
        Object.keys(this._mediaBucketKeyHash).forEach(key => MediaStore.destroyMediaBucket(this._mediaBucketKeyHash[key]));
        this._mediaBucketKey = MediaStore.destroyMediaBucket(this._mediaBucketKey);
        this._mediaBucketKeyHash = {};
        this.setState({
            mediaBucket: null,
            mediaBucketHash: {}
        });
    }

    /**
     * React: render
     */
    render() {
        var promotion = this.props.params && this.props.params.promotionName
            ? AppStore.promotionListByName.get(this.props.params.promotionName)
            : null;
        var mediaBucket = this.state.mediaBucket;
        var media = mediaBucket ? mediaBucket.media() : null;
        media = media && this.props.location && this.props.location.query && this.props.location.query.classification ? media.filter(mediaItem => {
            let classification = this.props.location.query.classification;
            return MediaItemClassification[classification.toUpperCase()] === MediaItemClassification.SERIES
                ? mediaItem.classification === MediaItemClassification.SERIES
                || mediaItem.classification === MediaItemClassification.SEASON
                || mediaItem.classification === MediaItemClassification.EPISODE
                || mediaItem.classification === MediaItemClassification.TV_BOX_SET
                : mediaItem.classification === MediaItemClassification.MOVIE
                || mediaItem.classification === MediaItemClassification.MOVIE_BOX_SET;
        }) : media;
        var filteredMedia = media ? Filter.media(media, this.state.activeFilters) : null;

        return (
            <main>
                <FilterBar />
                {typeof promotion === 'undefined' && (
                    <div className="no-results">
                        {AppStore.translate('view.promotion_page.message.promotion_not_found')}
                    </div>
                )}

                {media && media.size === 0 && promotion.collectionItems.length === 0 ? (
                    <div className="no-results">
                        {AppStore.translate('view.promotion_page.message.no_media_in_promotion', {promotionTitle: promotion.title})}
                    </div>
                ) : (media && media.size > 0 && filteredMedia && filteredMedia.size === 0 && (
                    <div className="no-results">{AppStore.translate('message.no_media_for_filter_selection')}</div>
                ))}

                {filteredMedia && filteredMedia.size > 0 && (
                    <MediaGallery title={promotion ? promotion.title : null}
                                  media={filteredMedia}
                                  mediaLoading={mediaBucket && mediaBucket.updatePending} />
                )}

                {promotion && promotion.collectionItems.length > 0 && promotion.collectionItems.map(promotionItem => {
                    let collection = AppStore.collectionListByTitle.get(promotionItem.promotionalLink);
                    if (collection) {
                        let mediaBucket = this.state.mediaBucketHash[promotionItem.promotionalLink];
                        let media = mediaBucket ? mediaBucket.media() : null;
                        return (mediaBucket && mediaBucket.updatePending) || (media && media.size > 0) ? (
                            <MediaCarouselTally key={collection.title}
                                                title={collection.title}
                                                linkTo={Router.COLLECTIONS(collection)}
                                                media={media}
                                                mediaTotalCount={media ? media.size : 0}
                                                navigationType="side-arrows-in-elipse"
                                                mediaLoading={mediaBucket && mediaBucket.updatePending} />
                        ) : null;
                    }

                    return null;
                })}
            </main>
        );
    }

    /**
     * Set active filters
     */
    _setActiveFilters = () => {
        this.setState({
            activeFilters: AppStore.activeFilters
        });
    };

    /**
     * Set mediaBucket
     */
    _setMediaBucket = () => {
        this.setState({
            mediaBucket: MediaStore.getMediaBucket(this._mediaBucketKey),
            mediaBucketHash: Object.keys(this._mediaBucketKeyHash).reduce((mediaBucketHash, key) => {
                mediaBucketHash[key] = MediaStore.getMediaBucket(this._mediaBucketKeyHash[key]);
                return mediaBucketHash;
            }, {})
        });
    };
}
