"use strict";

import React from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import {MediaItemClassification} from '../types/MediaItem';
import {OfferType} from '../types/Offer';
import MediaActions from '../actions/MediaActions';
import AppStore from '../stores/AppStore';
import MediaStore from '../stores/MediaStore';
import Filter from '../lib/Filter';
import MediaGallery from './elements/MediaGallery.react';
import FilterBar from './elements/FilterBar.react';

/**
 * WatchlistPage component
 */
export default class WatchlistPage extends React.Component {
    /**
     * React: state
     */
    state = {
        activeFilters: AppStore.activeFilters,
        mediaBucket: null,
        watchlistFilter: null
    };

    /**
     * Bucket keys
     */
    _mediaBucketKey = null;

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        this._mediaBucketKey = MediaActions.getWatchlistMedia();
    }

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        // add store listeners
        AppStore.addUpdateListener(this._setActiveFilters);
        MediaStore.addUpdateListener(this._setMediaBucket);
    }

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AppStore.removeUpdateListener(this._setActiveFilters);
        MediaStore.removeUpdateListener(this._setMediaBucket);
        this._mediaBucketKey = MediaStore.destroyMediaBucket(this._mediaBucketKey);
    }

    /**
     * React: render
     */
    render() {
        var mediaBucket = this.state.mediaBucket;
        var media = mediaBucket
            ? mediaBucket.media()
                .filter(mediaItem => !!mediaItem.isOnWatchlist
                        || mediaItem.classification === MediaItemClassification.SERIES)
            : null;
        var filteredMedia = media ? Filter.media(media, this.state.activeFilters) : null;
        var watchlistFilteredMedia = filteredMedia;

        switch (this.state.watchlistFilter) {
            case MediaItemClassification.MOVIE:
                watchlistFilteredMedia = filteredMedia
                    .filter(this._filterMovieMediaItems);
                break;

            case MediaItemClassification.SERIES:
                watchlistFilteredMedia = filteredMedia
                    .filter(this._filterSeriesMediaItems);
                break;

            case MediaItemClassification.MOVIE_BOX_SET:
            case MediaItemClassification.TV_BOX_SET:
                watchlistFilteredMedia = filteredMedia
                    .filter(this._filterBoxetsMediaItems);
                break;

            case OfferType.POEST:
                watchlistFilteredMedia = filteredMedia
                    .filter(this._filterPreOrderMediaItems);
                break;
        }

        return (
            <main>
                <FilterBar/>
                <div className="wishlist-collection-container">
                    <div className="media-gallery statistics-container">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 header">
                                    <h2>{AppStore.translate('view.watchlist_page.title')}{filteredMedia ? ' - ' : ''}
                                        {filteredMedia ? (
                                            <span className="gallery-filter-container">
                                                <span className={"filter-item-container"
                                                + (this.state.watchlistFilter === null ? ' active' : '')}
                                                      onClick={this._toggleWatchlistFilter.bind(null, null)}>All
                                                    <span className="gallery-filter-count">
                                                        {filteredMedia.size}
                                                        </span>
                                                </span>
                                                <span className={"filter-item-container"
                                                + (this.state.watchlistFilter === MediaItemClassification.MOVIE ? ' active' : '')}
                                                      onClick={this._toggleWatchlistFilter.bind(null, MediaItemClassification.MOVIE)}>Movies
                                                    <span className="gallery-filter-count">
                                                        {filteredMedia
                                                            .filter(this._filterMovieMediaItems)
                                                            .size}
                                                    </span>
                                                </span>
                                                <span className={"filter-item-container"
                                                + (this.state.watchlistFilter === MediaItemClassification.SERIES ? ' active' : '')}
                                                      onClick={this._toggleWatchlistFilter.bind(null, MediaItemClassification.SERIES)}>TV Series
                                                    <span className="gallery-filter-count">
                                                        {filteredMedia
                                                            .filter(this._filterSeriesMediaItems)
                                                            .size}
                                                    </span>
                                                </span>
                                                <span className={"filter-item-container"
                                                + (this.state.watchlistFilter === OfferType.POEST ? ' active' : '')}
                                                      onClick={this._toggleWatchlistFilter.bind(null, OfferType.POEST)}>Pre-Order
                                                    <span className="gallery-filter-count">
                                                        {filteredMedia
                                                            .filter(this._filterPreOrderMediaItems)
                                                            .size}
                                                    </span>
                                                </span>
                                                <span className={"filter-item-container"
                                                + (this.state.watchlistFilter === MediaItemClassification.TV_BOX_SET ? ' active' : '')}
                                                      onClick={this._toggleWatchlistFilter.bind(null, MediaItemClassification.TV_BOX_SET)}>Boxsets
                                                    <span className="gallery-filter-count">
                                                        {filteredMedia
                                                            .filter(this._filterBoxetsMediaItems)
                                                            .size}
                                                    </span>
                                                </span>
                                            </span>
                                        ) : null}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    {media && media.size === 0 ? (
                        <div className="no-results">
                            {AppStore.translate('view.watchlist_page.message.no_media_on_watchlist')}
                        </div>
                    ) : (watchlistFilteredMedia && watchlistFilteredMedia.size === 0 ? (
                        <div className="no-results">
                            {AppStore.translate('message.no_media_for_filter_selection')}
                        </div>
                    ) : (
                        <MediaGallery media={watchlistFilteredMedia}
                                      mediaLoading={mediaBucket && mediaBucket.updatePending}/>
                    ))}
                </div>
            </main>
        );
    }

    /**
     * Set active filters
     */
    _setActiveFilters = () => {
        this.setState({
            activeFilters: AppStore.activeFilters
        });
    };

    /**
     * Set mediaBucket
     */
    _setMediaBucket = () => {
        this.setState({
            mediaBucket: MediaStore.getMediaBucket(this._mediaBucketKey)
        });
    };

    /**
     * Toggle watchlist filter
     */
    _toggleWatchlistFilter = (filterKey) => {
        this.setState({
            watchlistFilter: filterKey
        });
    };

    /**
     * Filter movie mediaItems
     * @param mediaItem
     * @private
     */
    _filterMovieMediaItems = mediaItem => mediaItem.classification === MediaItemClassification.MOVIE && !mediaItem.offers.reduce((preOrderOfferMatched, offer) => {
        return preOrderOfferMatched || offer.offerType === OfferType.POEST;
    }, false);

    /**
     * Filter series mediaItems
     * @param mediaItem
     * @private
     */
    _filterSeriesMediaItems = mediaItem => (mediaItem.classification === MediaItemClassification.SERIES
    || mediaItem.classification === MediaItemClassification.SEASON
    || mediaItem.classification === MediaItemClassification.EPISODE)
    && !mediaItem.offers.reduce((preOrderOfferMatched, offer) => {
        return preOrderOfferMatched || offer.offerType === OfferType.POEST;
    }, false);

    /**
     * Filter Boxsets mediaItems
     * @param mediaItem
     * @private
     */
    _filterBoxetsMediaItems = mediaItem => (mediaItem.classification === MediaItemClassification.MOVIE_BOX_SET
        || mediaItem.classification === MediaItemClassification.TV_BOX_SET)
        && !mediaItem.offers.reduce((preOrderOfferMatched, offer) => {
            return preOrderOfferMatched || offer.offerType === OfferType.POEST;
        }, false);

    /**
     * Filter pre-order mediaItems
     * @param mediaItem
     * @private
     */
    _filterPreOrderMediaItems = mediaItem => mediaItem.offers.reduce((preOrderOfferMatched, offer) => {
        return preOrderOfferMatched || offer.offerType === OfferType.POEST;
    }, false);
}
