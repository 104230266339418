"use strict";

import React from 'react';
import moment from 'moment';
import shallowCompare from 'react-addons-shallow-compare';
import * as ErrorParser from '../../lib/ErrorParser';
import OverlayIds from '../../lib/OverlayIds';
import Config from '../../lib/Config';
import AppStore from '../../stores/AppStore';
import AuthActions from '../../actions/AuthActions';
import UIActions from '../../actions/UIActions';
import AuthStore from '../../stores/AuthStore';
import TextInput from '../ui/TextInput.react';
import Button from '../ui/Button.react';
import PaymentCardInput from '../ui/PaymentCardInput.react';

/**
 * PaymentMethodV1Overlay component
 */
export default class PaymentMethodV1Overlay extends React.Component {
    /**
     * React: state
     */
    state = {
        cardName: '',
        cardNumber: '',
        cardCVC: '',
        cardExpiryMonth: parseInt(moment().format('M')),
        cardExpiryYear: parseInt(moment().format('YYYY')),
        updatePending: false,
        errorMessage: null
    };

    /**
     * Constructor
     */
    constructor(props, context) {
        super(props, context);

        const customerPaymentDetails = AuthStore.customerPaymentDetails;
        if (customerPaymentDetails && customerPaymentDetails.defaultCard) {
            let card = customerPaymentDetails.defaultCard;
            this.state = {
                ...this.state,
                cardName: card.cardHolderName,
                cardNumber: '************' + card.lastFourDigits,
                cardCVC: '',
                cardExpiryMonth: card.expiryMonth,
                cardExpiryYear: card.expiryYear
            };
        }
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AuthStore.removeListener(AuthStore.STORE_UPDATED, this._setFormStatus);
        this.setState({
            updatePending: false
        });
    }

    /**
     * React: shouldComponentUpdate
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        return (
            <main className="checkout-overlay">
                <div className="container">
                    <div className="row">
                        <div className="checkout-block-content col-xs-12 col-md-10 col-lg-8 col-offset-md-1 col-offset-lg-2">
                            <h2 className="overlay-header">{AppStore.translate('view.update-payment-popup.payment-page-header').toUpperCase()}</h2>
                            <form onSubmit={this._setPaymentMethod}>
                                <div className="payment-block-container">
                                    <div className="set-payment-container">
                                        {this.state.errorMessage ? (
                                            <div className="checkout-payment-form-error-message">
                                                {this.state.errorMessage}
                                            </div>
                                        ) : null}

                                        <div className="ui-form light">
                                            <h3>{AppStore.translate('view.update-payment-popup.payment-info-header')}</h3>

                                            <TextInput
                                                label={AppStore.translate('field.label.name')}
                                                value={this.state.cardName}
                                                disabled={this.state.updatePending}
                                                placeholder={AppStore.translate('view.account_page_set_payment.card_name_placeholder')}
                                                inline={false}
                                                onChange={this._onFormFieldChange.bind(null, 'cardName')}/>

                                            <PaymentCardInput
                                                label={AppStore.translate('field.label.card')}
                                                value={this.state.cardNumber}
                                                disabled={this.state.updatePending}
                                                inline={false}
                                                inlineLabel={false}
                                                onChange={this._onFormFieldChange.bind(null, 'cardNumber')}/>
                                            <p className="terms-conditions-annotation-grey info-text">{AppStore.translate('view.account_page_set_payment.payment_card_types_info')}</p>
                                            <div className="date-ccv-wrapper">
                                                <div className="ui-drop-down-wrapper ui-input">
                                                    <label>{AppStore.translate('field.label.cc.expires-text')}</label>

                                                    <div className="card-exp-date-container">
                                                        <div className="ui-drop-down small-width">
                                                            <select className="drop-down-select"
                                                                    disabled={this.state.updatePending}
                                                                    value={this.state.cardExpiryMonth}
                                                                    onChange={this._onFieldChange.bind(null, 'cardExpiryMonth')}>
                                                                <option value={1}>01</option>
                                                                <option value={2}>02</option>
                                                                <option value={3}>03</option>
                                                                <option value={4}>04</option>
                                                                <option value={5}>05</option>
                                                                <option value={6}>06</option>
                                                                <option value={7}>07</option>
                                                                <option value={8}>08</option>
                                                                <option value={9}>09</option>
                                                                <option value={10}>10</option>
                                                                <option value={11}>11</option>
                                                                <option value={12}>12</option>
                                                            </select>
                                                        </div>

                                                        <div className="ui-drop-down small-width">
                                                            <select className="drop-down-select"
                                                                    value={this.state.cardExpiryYear}
                                                                    disabled={this.state.updatePending}
                                                                    onChange={this._onFieldChange.bind(null, 'cardExpiryYear')}>
                                                                {[...new Array(Config.NUMBER_OF_YEARS + parseInt(moment().format('Y')) - Config.START_YEAR)].map((x, i) => {
                                                                    let optionYear = Config.START_YEAR + i;
                                                                    return (
                                                                        <option key={i} value={optionYear}>
                                                                            {optionYear}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="cvv-container">
                                                    <TextInput
                                                        inputClassName="small-width cvv-input"
                                                        label={AppStore.translate('field.label.cvv-text')}
                                                        placeholder={AppStore.translate('view.account_page_set_payment.cvv_placeholder')}
                                                        value={this.state.cardCVC}
                                                        disabled={this.state.updatePending}
                                                        onChange={this._onFormFieldChange.bind(null, 'cardCVC')}/>
                                                    <span onClick={!this.state.updatePending ? this._showCVVInfo : null}
                                                        className={'cvv-info-link-payment-method-form'}>
                                                            {AppStore.translate('view.payment_page.card.what_is_cvc')}
                                                        </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="confirm-purchase-container edit-form-cta-buttons-container">
                                        <Button type='submit'
                                            className="cta-payment-positive cta-colored"
                                                shape="regular"
                                                disabled={this.state.updatePending|| this._formIsIncomplete()}
                                                updatePending={this.state.updatePending}>
                                            {AppStore.translate('button.save')}
                                        </Button>
                                        <Button shape="regular"
                                                disabled={this.state.updatePending}
                                                onClick={this._closePaymentMethodOverlay}>
                                            {AppStore.translate('button.cancel')}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        );
    }

    /**
     * On field change
     *
     * @param {string} fieldName
     * @param {*} value
     * @private
     */
    _onFormFieldChange = (fieldName, value) => {
        let newState = {};
        newState[fieldName] = value;
        this.setState(newState);
    };

    /**
     * On field change
     *
     * @param {string} fieldName
     * @param {Object} event
     * @private
     */
    _onFieldChange = (fieldName, event) => {
        let newState = {};
        newState[fieldName] = event.target.value;
        this.setState(newState);
    };

    /**
     * Returns if all some form field is not completed
     *
     * @private
     */
    _formIsIncomplete = () => {
        return !this.state.cardName
            || !this.state.cardNumber
            || !this.state.cardCVC
            || !this.state.cardExpiryYear
            || !this.state.cardExpiryMonth;
    };

    /**
     * Set payment method
     *
     * @param {Object} event
     * @private
     */
    _setPaymentMethod = (event) => {
        event.preventDefault();
        if (this._formIsIncomplete()) {
            this.setState({
                errorMessage: AppStore.translate('notification.account.error.payment_method_form_incomplete')
            });
            return;
        }

        if (this.state.cardNumber.indexOf('*') > -1) {
            this.setState({
                errorMessage: AppStore.translate('notification.account.error.payment_method_form_card_number_wrong')
            });
            return;
        }

        const userProfile = AuthStore.userProfile;
        const customerPaymentDetails = AuthStore.customerPaymentDetails;
        AuthStore.addUpdateListener(this._setFormStatus);

        if (customerPaymentDetails && customerPaymentDetails.defaultCard) {
            AuthActions.updatePaymentMethod({
                cardName: this.state.cardName,
                cardNumber: this.state.cardNumber,
                cardCVC: this.state.cardCVC,
                cardExpiryYear: this.state.cardExpiryYear,
                cardExpiryMonth: this.state.cardExpiryMonth,
                email: userProfile.email,
                preventNotifications: true
            });
        } else {
            AuthActions.addPaymentMethod({
                cardName: this.state.cardName,
                cardNumber: this.state.cardNumber,
                cardCVC: this.state.cardCVC,
                cardExpiryYear: this.state.cardExpiryYear,
                cardExpiryMonth: this.state.cardExpiryMonth,
                email: userProfile.email,
                preventNotifications: true
            });
        }
    };

    /**
     * Set form status
     */
    _setFormStatus = () => {
        const error = AuthStore.error;
        const errorMessage = error
            ? (ErrorParser.parseErrorMessage(error.message) || AppStore.translate('notification.general.error.communication_error'))
            : this.state.errorMessage;

        this.setState({
            updatePending: AuthStore.updatePending,
            errorMessage: errorMessage
        }, () => {
            if (!this.state.updatePending) {
                AuthStore.removeListener(AuthStore.STORE_UPDATED, this._setFormStatus);
            }
        });
    };

    /**
     * Close payment method overlay
     */
    _closePaymentMethodOverlay = () => {
        UIActions.closeCustomOverlay({
            overlayId: OverlayIds.PAYMENT_METHOD,
            showCloseButton: false
        });
    };

    /**
     * Show CVV info
     */
    _showCVVInfo = () => {
        UIActions.showCustomOverlay({
            overlayId: OverlayIds.CVV_INFO,
            showCloseButton: false
        });
    };
}
