"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import Spinner from './Spinner2.react';

/**
 * Button component
 * - depending on shape parameter, buttons can have all passed content, some set of icons/text or no content at all
 * - depending on type and shape parameter, buttons can be in link or button format; link format uses react-router
 * - there are 3 possible buttons states: updatePending, disabled and active;
 * - disabled and active states can not be combined; disabled state has precedence over active state
 * - active state is not available for link format buttons; disabled state is available for some link format buttons
 * - updatePending state can cause replacement of icon or injection of pending indicator in content (spinner)
 * - active state can cause injection of active indicator in content (additional icon)
 * - secondary button is less important in user interface (styling)
 * - action type describes consequence of action in user interface (styling)
 */
export default class Button extends React.Component {
    /**
     * React: displayName
     */
    static displayName = "Button";

    /**
     * React: propTypes
     */
    static propTypes = {
        onClick: PropTypes.func,
        type: PropTypes.oneOf(['button', 'submit', 'reset', 'link']),
        actionType: PropTypes.oneOf(['positive', 'neutral', 'warning', 'negative', 'info']),
        linkTo: PropTypes.string,
        target: PropTypes.string,
        shape: PropTypes.oneOf(['regular', 'plain', 'signpost-left', 'signpost-right', 'square', 'square-glass', 'square-chromeless',
            'icon-regular', 'icon-switch', 'icon-rounded', 'icon-rounded-large', 'icon-elipse-large-left', 'icon-elipse-large-right',
            'dot', 'arrow-left', 'arrow-right', 'arrow-left-large', 'arrow-right-large', 'arrow-right-large-in-elipse']),
        className: PropTypes.string,
        style: PropTypes.object,
        isSecondary: PropTypes.bool,
        updatePending: PropTypes.bool,
        disabled: PropTypes.bool,
        active: PropTypes.bool
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        type: 'button',
        className: '',
        style: {},
        isSecondary: false,
        updatePending: false,
        disabled: false,
        active: false
    };

    /**
     * React: render
     */
    render() {
        var buttonsWithFullContent = ['regular', 'plain', 'square', 'square-glass', 'signpost-left', 'signpost-right'];
        var buttonsWithIconContent = ['icon-regular', 'icon-switch', 'icon-rounded', 'icon-rounded-large', 'icon-elipse-large-left', 'icon-elipse-large-right', 'square-chromeless'];
        var buttonsWithoutContent = ['dot', 'arrow-left', 'arrow-right', 'arrow-left-large', 'arrow-right-large', 'arrow-right-large-in-elipse'];
        var buttonsWithLinkFormat = ['regular', 'icon-rounded', 'icon-rounded-large', 'square-chromeless', 'signpost-left', 'signpost-right'];

        var className = 'ui-button'
            + (this.props.isSecondary ? ' btn-secondary' : '')
            + (this.props.updatePending ? ' update-pending' : '')
            + (this.props.disabled ? ' disabled' : '')
            + (buttonsWithLinkFormat.indexOf(this.props.shape) === -1 ? (
                this.props.active && !this.props.disabled ? ' active' : ''
            ) : '')
            + (typeof this.props.actionType !== 'undefined' ? ' btn-' + this.props.actionType : ' btn-neutral')
            + (typeof this.props.shape !== 'undefined' ? ' btn-' + this.props.shape : ' btn-regular')
            + (this.props.className ? ' ' + this.props.className : '');

        // decide what is content
        var content = [];
        if (buttonsWithIconContent.indexOf(this.props.shape) > -1 || className.indexOf('top-icon') > -1) {
            content = this._parseIconButtonContent();
        } else if (buttonsWithFullContent.indexOf(this.props.shape) > -1) {
            content = this._parseButtonContent();
        } else  if (buttonsWithoutContent.indexOf(this.props.shape) > -1) {
            // no-op
        }

        // decide what format to use
        return buttonsWithLinkFormat.indexOf(this.props.shape) > -1 && this.props.type === 'link' ? (
            <Link to={this.props.linkTo} target={this.props.target} className={className} style={this.props.style}>
                <div className="button-content">
                    {content.map((element, index) => {
                        return React.cloneElement(element, {key: index});
                    })}
                </div>
            </Link>
        ) : (
            <button type={this.props.type}
                    onClick={this._onClick}
                    disabled={this.props.disabled}
                    className={className}
                    style={this.props.style}>
                <div className="button-content">
                    {content.map((element, index) => {
                        return React.cloneElement(element, {key: index});
                    })}
                </div>
            </button>
        );
    }

    /**
     * Parse button content
     * - wraps text nodes in spans
     * - replaces first icon with spinner if update is pending, or injects spinner if no icon present
     * - uses other elements as passed
     */
    _parseButtonContent = () => {
        var content = [];
        var pendingIcon = null;
        var parseElement = (element) => {
            if (typeof element === 'string') {
                content.push(<span title={element}>{element}</span>);
            } else if (typeof element !== 'undefined') {
                if (this.props.updatePending && !pendingIcon && element.type && element.type === "i") {
                    pendingIcon = element;
                    content.push(<Spinner />);
                } else {
                    content.push(element);
                }
            } else {
                content.push(<span>&nbsp;</span>);
            }
        };

        if (Array.isArray(this.props.children)) {
            this.props.children.forEach((element) => {
                parseElement(element);
            });
        } else {
            parseElement(this.props.children, 0);
        }

        if (this.props.updatePending && !pendingIcon) {
            content.unshift(<Spinner />);
        }

        return content;
    };

    /**
     * Parse icon button content
     * - wraps first found text node in span if provided, discards other text nodes
     * - uses first found icon, discards other icons
     * - replaces icon with spinner if update is pending
     * - if no icon found injects question mark icon
     * - if shape is icon-switch, injects checkbox icon or spinner depending on state
     */
    _parseIconButtonContent = () => {
        var content = [];
        var iconElement = null;
        var textElement = null;
        var parseElement = (element) => {
            if (typeof element === 'string' && !textElement) {
                textElement = (<span title={element}>{element}</span>);
            }
            if (typeof element !== 'undefined' && element.type && element.type === "i" && !iconElement) {
                iconElement = element;
            }
        };

        if (Array.isArray(this.props.children)) {
            this.props.children.forEach((element) => {
                parseElement(element);
            });
        } else {
            parseElement(this.props.children);
        }

        if (this.props.shape === 'icon-switch') {
            content.push(
                React.cloneElement(
                    iconElement ? iconElement : (<i className="ui-icon icon-question"/>),
                    {},
                    this.props.updatePending ? (<Spinner />) : (<i className="ui-icon icon-check-box"/>)
                )
            );
        } else {
            content.push(this.props.updatePending ? (<Spinner />)
                : (iconElement ? iconElement : (<i className="ui-icon icon-question"/>)));
        }

        if (['icon-switch', 'square', 'square-glass', 'square-chromeless'].indexOf(this.props.shape) > -1) {
            if (!textElement && this.props.className.indexOf('top-icon') > -1) {
                content.push(<span>button</span>);
            } else if (textElement) {
                content.push(textElement);
            }
        }

        return content;
    };

    /**
     * On click
     */
    _onClick = (event) => {
        if (typeof this.props.onClick === 'function') {
            this.props.onClick();
        }
        if (this.props.type === 'button') {
            event.preventDefault();
        }
    };
}
