"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import Config from '../../lib/Config';
import MediaQuery from '../../utils/MediaQuery';
import {debounce} from '../../utils/Common';
import {CollectionType} from '../../types/Collection';
import MediaBucket from '../../types/MediaBucket';
import MediaItem from '../../types/MediaItem';
import Collection from '../../types/Collection';
import BundleShoppingCart from '../../types/BundleShoppingCart';
import Button from '../ui/Button.react';
import MediaItemImage from './MediaItemImage.react';

/**
 * BundleShoppingCart component
 */
export default class BundleShoppingCartControls extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        mediaBucket: PropTypes.instanceOf(MediaBucket),
        collection: PropTypes.instanceOf(Collection),
        bundleShoppingCart: PropTypes.instanceOf(BundleShoppingCart),
        totalSlotCount: PropTypes.number,
        removeMediaItemOfferFromBundleShoppingCart: PropTypes.func,
        fastCheckoutBundleOffer: PropTypes.func,
        updatePending: PropTypes.bool
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        mediaBucket: null,
        totalSlotCount: null,
        updatePending: false
    };

    /**
     * React: state
     */
    state = {
        slideTrayOffset: 0,
        navigationLeftDisabled: true,
        navigationRightDisabled: true,
        slideCount: 0,
        activeSlide: 0
    };

    /**
     * Constructor
     */
    constructor(props, context) {
        super(props, context);
        this._recalculateActiveSlide = debounce(this._recalculateActiveSlide, 350);
    }

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        window.addEventListener("resize", this._recalculateActiveSlide);
        this._setCarouselState();
    }

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    componentDidUpdate(prevProps) {
        if (this.props.bundleShoppingCart.cartItems.size !== prevProps.bundleShoppingCart.cartItems.size
            && this.props.bundleShoppingCart.cartItems.size !== 0) {
            this._setCarouselSlideForTileIndex(this.props.bundleShoppingCart.cartItems.size - 1);
        }
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this._recalculateActiveSlide);
    }

    /**
     * React: render
     */
    render() {
        const mediaBucket = this.props.mediaBucket;
        const collection = this.props.collection;
        const bundleShoppingCart = this.props.bundleShoppingCart;
        const cartItems = bundleShoppingCart.cartItems;

        return (
            <div className="bundle-shopping-cart">
                <div className="container">
                    <div className="row">
                        <div className="content col-xs-12">
                            <div className="cart-counter">
                                <span className="ui-icon icon-ic_cart">{cartItems.size || null}</span>
                            </div>
                            <div className="cart-items-carousel">
                                <div className="viewing-frame" ref="viewingFrame">
                                    <div className="slide-tray"
                                         style={{left: this.state.slideTrayOffset + 'px'}}
                                         ref="slideTray">
                                        {cartItems.toArray().map(mediaItemOffer => {
                                            const mediaItem = mediaBucket.mediaItem(mediaItemOffer.mediaItemId);
                                            return mediaItem ? (
                                                <div key={mediaItemOffer.mediaItemId}
                                                     className="cart-item-tile col-lg-2 col-md-3 col-xs-4">
                                                    <MediaItemImage mediaItem={mediaItem}
                                                                    tileType="poster"
                                                                    useImageLoadingIndicator={true}/>

                                                    <Button shape="icon-rounded"
                                                            className="cta-menu-button"
                                                            disabled={bundleShoppingCart.updatePending}
                                                            onClick={this.props.removeMediaItemOfferFromBundleShoppingCart.bind(null, mediaItem)}>
                                                        <i className="ui-icon icon-close"/>
                                                    </Button>
                                                </div>
                                            ) : null;
                                        })}
                                        {[...new Array(this.props.totalSlotCount - cartItems.size)].map((x, index) => {
                                            return (
                                               <div key={index}
                                                    className="cart-item-tile empty-slot col-lg-2 col-md-3 col-xs-4">
                                                   <MediaItemImage mediaItem={new MediaItem({})}
                                                                   tileType="poster"
                                                                   useImageLoadingIndicator={true}/>
                                                   <div className="empty-slot-message">
                                                       {collection.collectionType === CollectionType.TV_BUNDLE
                                                           ? `Choose a season above` : `Choose a movie above`}
                                                   </div>
                                               </div>
                                           );
                                        })}
                                    </div>

                                    {this.state.slideCount > 1 ? (
                                        <div className="navigation-side-arrows">
                                            <Button shape="arrow-left-large"
                                                    disabled={this.state.navigationLeftDisabled}
                                                    onClick={this._changeSlide.bind(null, -1)}/>
                                            <Button shape="arrow-right-large"
                                                    disabled={this.state.navigationRightDisabled}
                                                    onClick={this._changeSlide.bind(null, 1)}/>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="cta-menu">
                                {bundleShoppingCart.amountDue !== null ? (
                                    <Button shape="square"
                                            className="cta-play full-width"
                                            disabled={bundleShoppingCart.updatePending}
                                            updatePending={this.props.updatePending || bundleShoppingCart.updatePending}
                                            onClick={this.props.fastCheckoutBundleOffer}
                                    >
                                        {bundleShoppingCart.updatePending
                                            ? '' : 'Buy for '
                                            + bundleShoppingCart.amountDue.toLocaleString(Config.LOCALE, {
                                                style: 'currency',
                                                currency: bundleShoppingCart.currencyCode,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}
                                    </Button>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Change slide
     *
     * @param {number} direction
     * @private
     */
    _changeSlide = (direction) => {
        const tileWidth = this.refs.viewingFrame.getElementsByClassName('cart-item-tile')[0].getBoundingClientRect().width;
        const viewingFrameWidth = this.refs.viewingFrame.getBoundingClientRect().width;
        const newSlideTrayOffset = this.state.slideTrayOffset
            + -1 * direction * tileWidth * (

                this.props.totalSlotCount < Config.MEDIA_CAROUSEL_TALLY_MAX_ITEMS
                && ((direction === 1 && this.state.activeSlide === this.state.slideCount - 2)
                    || (direction === -1 &&  this.state.activeSlide === this.state.slideCount - 1))
                && this.props.totalSlotCount % this._numberOfWholeTilesIn(viewingFrameWidth) !== 0
                    ? this.props.totalSlotCount % this._numberOfWholeTilesIn(viewingFrameWidth)
                    : this._numberOfWholeTilesIn(viewingFrameWidth)
            );

        //firefox fix: check if newSlideTrayOffset > 0 and reset to 0 for first slide
        this._setCarouselState(newSlideTrayOffset > 0 ? 0 : newSlideTrayOffset);
    };

    /**
     * Go to slide
     *
     * @param {number} index
     * @private
     */
    _goToSlide = (index) => {
        const viewingFrameWidth = this.refs.viewingFrame.getBoundingClientRect().width;
        const tileWidth = this.refs.viewingFrame.getElementsByClassName('cart-item-tile')[0].getBoundingClientRect().width;

        this._setCarouselState(-1 * index * this._numberOfWholeTilesIn(viewingFrameWidth) * tileWidth);
    };

    /**
     * Go to slide where tile index is
     *
     * @param {number} tileIndex
     * @private
     */
    _setCarouselSlideForTileIndex = (tileIndex) => {
        const viewingFrameWidth = this.refs.viewingFrame.getBoundingClientRect().width;
        const tilesPerSlide = this._numberOfWholeTilesIn(viewingFrameWidth);
        const index = Math.floor(tileIndex / tilesPerSlide);

        if (index !== this.state.activeSlide) {
            this._goToSlide(index);
        }
    };

    /**
     * Recalculate active slide
     *
     * @private
     */
    _recalculateActiveSlide = () => {
        if (MediaQuery.match(MediaQuery.BREAKPOINT_LG)) {
            const tileWidth = this.refs.viewingFrame.getElementsByClassName('cart-item-tile')[0].getBoundingClientRect().width;
            const viewingFrameWidth = this.refs.viewingFrame.getBoundingClientRect().width;
            const slideTrayWidth = this.refs.slideTray.scrollWidth;

            let newSlideTrayOffset = -1 * this._numberOfSlidesIn(this.state.slideTrayOffset) * this._numberOfWholeTilesIn(viewingFrameWidth) * tileWidth;
            newSlideTrayOffset = Math.abs(newSlideTrayOffset) < slideTrayWidth - this._numberOfWholeTilesIn(viewingFrameWidth) * tileWidth
                ? newSlideTrayOffset
                : newSlideTrayOffset + this._numberOfWholeTilesIn(viewingFrameWidth) * tileWidth;

            this._setCarouselState(newSlideTrayOffset);
        } else {
            this._setCarouselState(0);
        }
    };

    /**
     * Set carousel state
     *
     * @param {?number} newSlideTrayOffset
     * @private
     */
    _setCarouselState = (newSlideTrayOffset = null) => {
        if (!this.props.totalSlotCount) return;

        const viewingFrameWidth = this.refs.viewingFrame.getBoundingClientRect().width;
        const slideTrayWidth = this.refs.slideTray.scrollWidth;
        newSlideTrayOffset = typeof newSlideTrayOffset !== 'undefined' && newSlideTrayOffset !== null && newSlideTrayOffset <= 0
            ? newSlideTrayOffset : this.state.slideTrayOffset;

        this.setState({
            slideTrayOffset: newSlideTrayOffset,
            slideCount: this._numberOfSlidesIn(slideTrayWidth),
            activeSlide: this._numberOfSlidesIn(newSlideTrayOffset),
            navigationLeftDisabled: this._numberOfTilesIn(newSlideTrayOffset) === 0,
            navigationRightDisabled: this._numberOfTilesIn(newSlideTrayOffset) + this._numberOfWholeTilesIn(viewingFrameWidth) >= this._numberOfTilesIn(slideTrayWidth)
        });
    };

    /**
     * Returns rounded number of tiles in target width
     *
     * @param {number} targetWidth
     * @param {number} tileWidthPercent
     * @return {number}
     * @private
     */
    _numberOfTilesIn = (targetWidth, tileWidthPercent = 50) => {
        const tileWidth = this.refs.viewingFrame.getElementsByClassName('cart-item-tile')[0].getBoundingClientRect().width;

        return Math.abs(
            targetWidth % tileWidth >= tileWidth * tileWidthPercent / 100
                ? Math.ceil(targetWidth / tileWidth)
                : Math.floor(targetWidth / tileWidth)
        );
    };

    /**
     * Returns number of whole tiles in target width
     * tile is whole if it is larger than tileWidthPercent
     *
     * @param {number} targetWidth
     * @param {number} tileWidthPercent
     * @return {number}
     * @private
     */
    _numberOfWholeTilesIn = (targetWidth, tileWidthPercent = 80) => {
        return this._numberOfTilesIn(targetWidth, tileWidthPercent);
    };

    /**
     * Returns rounded number of slides in target width
     *
     * @param {number} targetWidth
     * @param {number} slideWidthPercent
     * @return {number}
     * @private
     */
    _numberOfSlidesIn = (targetWidth, slideWidthPercent = 10) => {
        const viewingFrameWidth = this.refs.viewingFrame.getBoundingClientRect().width;
        const tileWidth = this.refs.viewingFrame.getElementsByClassName('cart-item-tile')[0].getBoundingClientRect().width;
        const slideWidth = this._numberOfWholeTilesIn(viewingFrameWidth) * tileWidth;

        return Math.abs(
            targetWidth % slideWidth >= slideWidth * slideWidthPercent / 100
                ? Math.ceil(targetWidth / slideWidth)
                : Math.floor(targetWidth / slideWidth)
        );
    };
}
