"use strict";

import React from 'react';
import PropTypes from 'prop-types';

/**
 * PromotionTagProps definition
 *
 * @typedef {Object} PromotionTagProps
 * @property {?string} tag
 * @property {?string} className
 */

/**
 * PromotionTag component
 *
 * @param {PromotionTagProps} props
 * @constructor
 */
const PromotionTag = (props) => {
    return props.tag ? (
        <span className={"ui-promotion-tag " + (props.className || "")}>{props.tag}</span>
    ) : null;
};

PromotionTag.propTypes = {
    tag: PropTypes.string,
    className: PropTypes.string
};

export default PromotionTag;
