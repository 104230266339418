"use strict";

import Immutable from 'immutable';

/**
 * PaymentProvidersCustomerIdsDTO definition
 *
 * @typedef {Object} PaymentProvidersCustomerIdsDTO
 * @property {?string} Stripe
 */

/**
 * PaymentProvidersCustomerIds definition
 *
 * @typedef {Immutable.Record} PaymentProvidersCustomerIds
 * @property {?string} stripe
 */

/**
 * PaymentProvidersCustomerIds record
 *
 * @type {PaymentProvidersCustomerIds}
 */
class PaymentProvidersCustomerIds extends Immutable.Record({
    stripe: null
}) {
    /**
     * Set values from data transfer object
     *
     * @param {PaymentProvidersCustomerIdsDTO} dataTransferObject
     * @return {PaymentProvidersCustomerIds}
     */
    fromDTO(dataTransferObject) {
        return new PaymentProvidersCustomerIds({
            ...this.toJS(),
            stripe: typeof dataTransferObject.Stripe !== 'undefined' ? dataTransferObject.Stripe : this.stripe
        });
    }
}

export default PaymentProvidersCustomerIds;
