"use strict";

import Immutable from 'immutable';
import MediaItem from './MediaItem';

/**
 * AlertDTO definition
 *
 * @typedef {Object} AlertDTO
 * @property {?number} Id - Gets or sets the identifier
 * @property {?string} AlertType - Gets or sets the type of the alert: ['PreOrder']
 * @property {?string} UserId - Gets or sets the user identifier
 * @property {?string} ReferenceId - Gets or sets the reference identifier
 * @property {?number} DisplayedCount - Gets or sets the displayed count
 * @property {?string} ExpiryDate - Gets or sets the expiry date
 * @property {?string} Status - Gets or sets the status: ['Active', 'Cancelled', 'Removed']
 * @property {?string} Heading - Gets or sets the heading
 * @property {?string} Body - Gets or sets the body
 * @property {?string} Url - Gets or sets the URL
 */

/**
 * Alert definition
 *
 * @typedef {Immutable.Record} Alert
 * @property {?number} id
 * @property {?string} type
 * @property {?string} userId
 * @property {?string} referenceId
 * @property {?number} displayedCount
 * @property {?string} expiryDate
 * @property {?string} status
 * @property {?string} heading
 * @property {?string} body
 * @property {?string} url
 */

/**
 * Alert record
 *
 * @type {Alert}
 */
class Alert extends Immutable.Record({
    id: null,
    type: null,
    userId: null,
    referenceId: null,
    displayedCount: null,
    expiryDate: null,
    status: null,
    heading: null,
    body: null,
    url: null,
    updatePending: false
}) {
    /**
     * toJS shallow
     * converts Record to object by keeping all second level immutable or other complex properties intact
     *
     * @return {Object}
     */
    toJSShallow() {
        return {
            ...this.toJS(),
            mediaItem: this.mediaItem
        };
    }

    /**
     * Set values from data transfer object
     *
     * @param {AlertDTO} dataTransferObject
     * @return {Alert}
     */
    fromDTO(dataTransferObject) {
        return new Alert({
            ...this.toJSShallow(),
            id: typeof dataTransferObject.Id !== 'undefined' ? dataTransferObject.Id : this.id,
            type: typeof dataTransferObject.AlertType !== 'undefined' ? AlertType.getValue(dataTransferObject.AlertType) : this.type,
            userId: typeof dataTransferObject.UserId !== 'undefined' ? dataTransferObject.UserId : this.userId,
            referenceId: typeof dataTransferObject.ReferenceId !== 'undefined' ? dataTransferObject.ReferenceId : this.referenceId,
            displayedCount: typeof dataTransferObject.DisplayedCount !== 'undefined' ? dataTransferObject.DisplayedCount : this.displayedCount,
            expiryDate: typeof dataTransferObject.ExpiryDate !== 'undefined' ? dataTransferObject.ExpiryDate : this.expiryDate,
            status: typeof dataTransferObject.Status !== 'undefined' ? AlertStatus.getValue(dataTransferObject.Status) : this.status,
            heading: typeof dataTransferObject.Heading !== 'undefined' ? dataTransferObject.Heading : this.heading,
            body: typeof dataTransferObject.Body !== 'undefined' ? dataTransferObject.Body : this.body,
            url: typeof dataTransferObject.Url !== 'undefined' ? dataTransferObject.Url : this.url,
            mediaItem: typeof dataTransferObject.MediaItemDTO !== 'undefined' ? new MediaItem({}).fromDTO(dataTransferObject.MediaItemDTO) : this.mediaItem
        });
    }

    /**
     * Set update pending
     *
     * @param {boolean} updatePending
     * @return {Alert}
     */
    setUpdatePending(updatePending) {
        return this.updatePending !== updatePending
            ? new Alert({
                ...this.toJSShallow(),
                updatePending: updatePending
            }) : this;
    }
}

/**
 * Alert types
 * @type {Object}
 */
var AlertType = {
    UNKNOWN: 'Unknown',
    PRE_ORDER: 'PreOrder',

    /**
     * Get value
     */
    getValue: function (inputValue) {
        let value = this.UNKNOWN;
        Object.keys(this).forEach((key) => {
            if (this[key] === inputValue) value = this[key];
        });
        return value;
    }
};

/**
 * Alert status
 * @type {Object}
 */
var AlertStatus = {
    UNKNOWN: 'Unknown',
    ACTIVE: 'Active',
    CANCELED: 'Cancelled',
    REMOVED: 'Removed',

    alertStatuses: ['Unknown', 'Active', 'Cancelled', 'Removed'],

    /**
     * Get value
     */
    getValue: function (inputIndex) {
        let inputValue = this.alertStatuses[inputIndex];
        let value = this.UNKNOWN;
        Object.keys(this).forEach((key) => {
            if (this[key] === inputValue) value = this[key];
        });
        return value;
    }
};

export {Alert as default, Alert, AlertStatus, AlertType};
