"use strict";

import Immutable from 'immutable';
import moment from 'moment';
import BundleOffer from './BundleOffer';

/**
 * CollectionDTO definition
 *
 * @typedef {Object} CollectionDTO
 * @property {?number} TotalMediaItemsCount - The count of active available movies in this Genre
 * @property {?number} Id - The unique Id of the collection
 * @property {?string} Title - The name of the Collection
 * @property {?string} Description - The collections description
 * @property {?string} CollectionType - The type of collection. General, BestSellers, NewReleases, SpecialOffers, RecommendedForDevice
 * @property {?boolean} IsSpecial - Is this collection 'special'? If so then this may need to appear as fixed headings in a UI
 * @property {?boolean} IsMobileOnly - Is this collection for rendering on mobile clients only?
 * @property {?boolean} IsBundle - Is this collection Bundle Offer collection (Multi-buy)
 * @property {?number} AffiliateId - The Affiliate Id to which this collection applies
 * @property {?string} StartDate - If a special collection this is the time it is published from
 * @property {?string} EndDate - If a special collection this is the time it is published to
 * @property {?string} ImageUrl - Gets or sets the image URL
 * @property {?string} DataFile - Gets or sets the data file
 * @property {?BundleOfferDTO} BundleOffer - BundleOffer offer for collection
 */

/**
 * Collection definition
 *
 * @typedef {Immutable.Record} Collection
 * @property {?number} id
 * @property {?number} mediaCount
 * @property {?string} title
 * @property {?string} description
 * @property {?string} collectionType
 * @property {?boolean} isSpecial
 * @property {?boolean} isMobileOnly
 * @property {?boolean} isBundle
 * @property {?boolean} isPrePackedBundle
 * @property {?number} affiliateId
 * @property {?Object} startDate
 * @property {?Object} endDate
 * @property {?string} imageUrl
 * @property {?string} dataFile
 * @property {?BundleOffer} bundleOffer
 * @property {?number} mediaCount
 */

/**
 * Collection record
 *
 * @type {Collection}
 */

class Collection extends Immutable.Record({
    id: null,
    mediaCount: null,
    title: null,
    description: null,
    collectionType: null,
    isSpecial: null,
    isMobileOnly: null,
    isBundle: false,
    isPrePackedBundle: false,
    affiliateId: null,
    startDate: null,
    endDate: null,
    imageUrl: '',
    dataFile: false,
    bundleOffer: null
}) {
    /**
     * toJS shallow
     * converts Record to object by keeping all second level immutable or other complex properties intact
     *
     * @return {Object}
     */
    toJSShallow() {
        return {
            ...this.toJS(),
            startDate: this.startDate,
            endDate: this.endDate
        };
    }

    /**
     * Set values from data transfer object
     *
     * @param {CollectionDTO} dataTransferObject
     * @return {Collection}
     */
    fromDTO(dataTransferObject) {
        return new Collection({
            ...this.toJSShallow(),
            id: typeof dataTransferObject.Id !== 'undefined' ? dataTransferObject.Id : this.id,
            mediaCount: typeof dataTransferObject.TotalMediaItemsCount !== 'undefined' ? dataTransferObject.TotalMediaItemsCount : this.mediaCount,
            title: typeof dataTransferObject.Title !== 'undefined' ? dataTransferObject.Title
                : (typeof dataTransferObject.Name !== 'undefined' ? dataTransferObject.Name : this.title),
            description: typeof dataTransferObject.Description !== 'undefined' ? dataTransferObject.Description
                : (typeof dataTransferObject.Description !== 'undefined' ? dataTransferObject.Description : this.description),
            collectionType: typeof dataTransferObject.CollectionType !== 'undefined' ? CollectionType.getValue(dataTransferObject.CollectionType) : this.collectionType,
            isSpecial: typeof dataTransferObject.IsSpecial !== 'undefined' ? dataTransferObject.IsSpecial : this.isSpecial,
            isMobileOnly: typeof dataTransferObject.IsMobileOnly !== 'undefined' ? dataTransferObject.IsMobileOnly : this.isMobileOnly,
            isBundle: typeof dataTransferObject.IsBundle !== 'undefined' ? dataTransferObject.IsBundle : this.isBundle,
            isPrePackedBundle: typeof dataTransferObject.BundleOffer !== 'undefined'? _calculateBundleTypeFromDTO(dataTransferObject) : this.isPrePackedBundle,
            affiliateId: typeof dataTransferObject.AffiliateId !== 'undefined' ? dataTransferObject.AffiliateId : this.affiliateId,
            startDate: typeof dataTransferObject.StartDate !== 'undefined' ? moment(dataTransferObject.StartDate) : this.startDate,
            endDate: typeof dataTransferObject.EndDate !== 'undefined' ? moment(dataTransferObject.EndDate) : this.endDate,
            imageUrl: typeof dataTransferObject.ImageUrl !== 'undefined' && dataTransferObject.ImageUrl !== null ? dataTransferObject.ImageUrl : this.imageUrl,
            dataFile: typeof dataTransferObject.DataFile !== 'undefined' ? dataTransferObject.DataFile : this.dataFile,
            bundleOffer: typeof dataTransferObject.BundleOffer !== 'undefined' && dataTransferObject.BundleOffer
                ? new BundleOffer({}).fromDTO(dataTransferObject.BundleOffer) : this.bundleOffer
        });
    }
}

/**
 * Calculates if bundle is pre-packed from DTO
 * pre-packed bundles have exact number of items in them that may be selected
 *
 * @param {CollectionDTO} dataTransferObject
 * @returns {boolean}
 * @private
 */
function _calculateBundleTypeFromDTO(dataTransferObject) {
    return dataTransferObject.IsBundle
        && dataTransferObject.TotalMediaItemsCount
        && dataTransferObject.BundleOffer
        && dataTransferObject.BundleOffer.MinSelectQuantity
        && dataTransferObject.BundleOffer.MinSelectQuantity === dataTransferObject.TotalMediaItemsCount;
}

/**
 * Collection type
 * @type {Object}
 */
var CollectionType = {
    GENERAL: 'General',
    MOVIE_BUNDLE: 'MovieBundle',
    TV_BUNDLE: 'TVBundle',

    /**
     * Get value
     */
    getValue: function (inputValue) {
        var value = this.GENERAL;
        Object.keys(this).forEach((key) => {
            if (this[key] === inputValue) value = this[key];
        });
        return value;
    }
};

export {Collection as default, Collection, CollectionType};
