"use strict";

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Blured Image Svg Filter component
 */
export default class BluredImageSvg extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        imageUrl: PropTypes.string,
        stdDeviation: PropTypes.number,
        height: PropTypes.string,
        width: PropTypes.string
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        stdDeviation: 20
    };

    /**
     * React: render
     */
    render() {
        return (
            <svg className="blur-ie" height={this.props.height} width={this.props.width}>
                <defs>
                    <filter id="blur">
                        <feGaussianBlur stdDeviation={this.props.stdDeviation} />
                    </filter>
                </defs>
                <image xlinkHref={this.props.imageUrl} filter="url(#blur)" height={this.props.height} width={this.props.width}></image>
            </svg>
        );
    }
}
