"use strict";

import Immutable from 'immutable';

/**
 * ExternalIdentifierDTO definition
 *
 * @typedef {Object} ExternalIdentifierDTO
 * @property {?string} Key - The key identifier
 * @property {?string} Value - The identifier value
 */

/**
 * ExternalIdentifier definition
 *
 * @typedef {Immutable.Record} ExternalIdentifier
 * @property {?string} key
 * @property {?string} value
 */

/**
 * ExternalIdentifier record
 *
 * @type {ExternalIdentifier}
 */
class ExternalIdentifier extends Immutable.Record({
    key: null,
    value: null
}) {
    /**
     * Set values from data transfer object
     *
     * @param {ExternalIdentifierDTO} dataTransferObject
     * @return {ExternalIdentifier}
     */
    fromDTO(dataTransferObject) {
        return new ExternalIdentifier({
            ...this.toJS(),
            key: typeof dataTransferObject.Key !== 'undefined' ? dataTransferObject.Key : this.key,
            value: typeof dataTransferObject.Value !== 'undefined' ? dataTransferObject.Value : this.value
        });
    }
}

export default ExternalIdentifier;
