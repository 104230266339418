"use strict";

import Immutable from 'immutable';
import moment from 'moment';
import AppStore from '../stores/AppStore';
import {MediaItemClassification} from '../types/MediaItem';

/**
 * Filter object
 */
var Filter = {
    // offers
    OFFER_WATCH: {query: "Watch"},
    OFFER_BUY: {query: "Download"},
    OFFER_RENT: {query: "Rental"},

    // audio language
    AUDIO_LANGUAGE_ANY: {query: ""},
    AUDIO_LANGUAGE_EN: {query: "EN"},
    AUDIO_LANGUAGE_DE: {query: "DE"},
    AUDIO_LANGUAGE_FR: {query: "FR"},
    AUDIO_LANGUAGE_ES: {query: "ES"},
    AUDIO_LANGUAGE_IT: {query: "IT"},

    // release years
    YEAR_ANY: {},
    YEAR_2010: {startYear: 2010, endYear: parseInt(moment().add(50, 'years').format("YYYY"))},
    YEAR_2000: {startYear: 2000, endYear: 2009},
    YEAR_1990: {startYear: 1990, endYear: 1999},
    YEAR_1980: {startYear: 1980, endYear: 1989},
    YEAR_1800: {startYear: 1800, endYear: 1979},

    /**
     * Get type
     *
     * @params {string} filterKey
     * @return {*}
     */
    getType: function (filterKey) {
        return typeof this[filterKey] !== 'undefined' ? (
            typeof this[filterKey].name !== 'undefined' ? this[filterKey] : Object.assign(this[filterKey], {
                name: AppStore.translate(`filter.${this.getTypeGroup(filterKey)}.${this.getTypeName(filterKey)}`)
            })
        ) : null;
    },

    /**
     * Get type group
     *
     * @params {string} filterKey
     * @return {*}
     */
    getTypeGroup: function (filterKey) {
        return filterKey.split('_')[0].toLowerCase();
    },

    /**
     * Get type name
     *
     * @params {string} filterKey
     * @return {*}
     */
    getTypeName: function (filterKey) {
        return filterKey.replace(/OFFER_|AUDIO_LANGUAGE_|YEAR_/g, "").toLowerCase();
    },

    /**
     * Get types
     *
     * @return {Object}
     */
    getAllTypes: function () {
        var filterTypes = {};
        Object.keys(this).filter((key) => {
            if (key.indexOf('OFFER_') > -1) return true;
            if (key.indexOf('CERTIFICATE_') > -1) return true;
            if (key.indexOf('GENRE_') > -1) return true;
            if (key.indexOf('YEAR_') > -1) return true;
            if (key.indexOf('AUDIO_LANGUAGE_') > -1) return true;
        }).forEach((key) => {
            filterTypes[key] = this[key];
        });

        return filterTypes;
    },

    /**
     * Get types by group
     *
     * @param {string} group
     * @return {Object}
     */
    getTypesByGroup: function (group) {
        var filterTypes = this.getAllTypes();
        var groupTypes = {};
        Object.keys(filterTypes).forEach((key) => {
            if (key.indexOf(group.toUpperCase() + '_') !== -1) {
                groupTypes[key] = filterTypes[key];
            }
        });
        return groupTypes;
    },

    /**
     * Set genre filters
     */
    setGenreFilters: function () {
        AppStore.genreList.forEach((genre) => {
            let filterKey = genre.id.replace(/[^\w\s]/g, '');
            filterKey = filterKey.replace(/ +(?= )/g, '');
            filterKey = 'GENRE_' + filterKey.replace(/ /g, '_').toUpperCase();
            this[filterKey] = genre;
        });
    },

    /**
     * Set certificate filters
     */
    setCertificateFilters: function () {
        this['CERTIFICATE_ANY'] = {query: 'any', name: 'any'};
        AppStore.certificateList.forEach((certificate) => {
            let filterKey = certificate.name;
            filterKey = 'CERTIFICATE_' + filterKey.replace(/ /g, '_').toUpperCase();
            this[filterKey] = {query: certificate.name, name: certificate.name, searchQuery: certificate.minRating};
        });
    },

    /**
     * Filter media
     *
     * @param {Immutable.Map<string, MediaItem>} media
     * @param {Immutable.OrderedMap<string, Object>} filters
     * @param {boolean} exclusive
     * @return {*}
     */
    media: function (media, filters = null, exclusive = true) {
        if (!filters) filters = AppStore.activeFilters;

        return !filters.isEmpty() ? media.filter((mediaItem) => {
            // matching filter groups from which only one filter type can be selected
            var singletonFilterCollection = filters.filter((filter, filterKey) => {
                return filterKey.indexOf('GENRE_') === -1;
            });
            var singletonFilterCollectionMatched = singletonFilterCollection.reduce((singletonFilterCollectionMatched, filter, filterKey) => {
                let singletonFilterMatched = true;
                // ..by certificate
                if (filterKey.indexOf('CERTIFICATE_') !== -1) {
                    singletonFilterMatched = mediaItem.certificate.toLowerCase().indexOf(filter.query.toLowerCase()) !== -1;

                    // ..by audio language
                } else if (filterKey.indexOf('AUDIO_LANGUAGE_') !== -1) {
                    singletonFilterMatched = mediaItem.audioLanguages.reduce((audioLanguageFilterMatched, audioLanguage) => {
                        return audioLanguageFilterMatched || audioLanguage.toLowerCase().indexOf(filter.query.toLowerCase()) !== -1;
                    }, false);

                    // ..by offers
                    // } else if (filterKey.indexOf('OFFER_') !== -1) {
                    //     singletonFilterMatched = typeof mediaItem.OfferType !== 'undefined' && mediaItem.OfferType.toLowerCase().indexOf(filter.query.toLowerCase()) !== -1;

                    // ..by release year
                } else if (filterKey.indexOf('YEAR_') !== -1) {
                    singletonFilterMatched = mediaItem.releaseYear >= filter.startYear && mediaItem.releaseYear < filter.endYear;
                }
                return exclusive
                    ? singletonFilterCollectionMatched && singletonFilterMatched
                    : singletonFilterCollectionMatched || singletonFilterMatched;
            }, exclusive);

            // match genre filters (OR)
            var genreFilterCollection = filters.filter((filter, filterKey) => {
                return filterKey.indexOf('GENRE_') !== -1;
            });
            var genreFilterCollectionMatched = genreFilterCollection.reduce((genreFilterCollectionMatched, filter) => {
                // ..by genre
                return genreFilterCollectionMatched || mediaItem.genres.reduce(
                        (genreFilterMatched, genre) => {
                            return genreFilterMatched || genre.toLowerCase().indexOf(filter.id.toLowerCase()) !== -1;
                        },
                        false
                    );
            }, false);

            return exclusive
                ? (
                (singletonFilterCollection.isEmpty() ? true : singletonFilterCollectionMatched)
                && (genreFilterCollection.isEmpty() ? true : genreFilterCollectionMatched)
            ) : (
                (singletonFilterCollection.isEmpty() ? false : singletonFilterCollectionMatched)
                || (genreFilterCollection.isEmpty() ? false : genreFilterCollectionMatched)
                || (singletonFilterCollection.isEmpty() && genreFilterCollection.isEmpty())
            );
        }) : media;
    },

    /**
     * Return search query params for set filters
     *
     * @return {Object}
     */
    getSearchQueryParams: function (noEpisodes = true) {
        var activeFilters = AppStore.activeFilters;
        var searchQueryParams = {};

        // var offerFilters = !activeFilters.isEmpty() ? activeFilters.filter((activeFilter, filterKey) => {
        //     return filterKey.indexOf('OFFER_') !== -1;
        // }) : Immutable.OrderedMap();

        var genreFilters = !activeFilters.isEmpty() ? activeFilters.filter((activeFilter, filterKey) => {
            return filterKey.indexOf('GENRE_') !== -1;
        }) : Immutable.OrderedMap();

        if (!genreFilters.isEmpty()) {
            searchQueryParams = {
                ...searchQueryParams,
                FilterPrimaryGenres: genreFilters.map((filter) => {
                    return filter.id;
                }).toArray()
            };
        }

        var certificateFilters = !activeFilters.isEmpty() ? activeFilters.filter((activeFilter, filterKey) => {
            return filterKey.indexOf('CERTIFICATE_') !== -1;
        }) : Immutable.OrderedMap();

        if (!certificateFilters.isEmpty()) {
            let filter = certificateFilters.first();
            searchQueryParams = {
                ...searchQueryParams,
                FilterRatingStart: filter.searchQuery,
                FilterRatingEnd: filter.searchQuery
            };
        }

        var releaseYearFilters = !activeFilters.isEmpty() ? activeFilters.filter((activeFilter, filterKey) => {
            return filterKey.indexOf('YEAR_') !== -1;
        }) : Immutable.OrderedMap();

        if (!releaseYearFilters.isEmpty()) {
            let filter = releaseYearFilters.first();
            searchQueryParams = {
                ...searchQueryParams,
                FilterReleaseYearStart: filter.startYear,
                FilterReleaseYearEnd: filter.endYear
            };
        }

        // var languageFilters = !activeFilters.isEmpty() ? activeFilters.filter((activeFilter, filterKey) => {
        //     return filterKey.indexOf('AUDIO_LANGUAGE_') !== -1;
        // }) : Immutable.OrderedMap();

        if (noEpisodes) {
            searchQueryParams = {
                ...searchQueryParams,
                FilterClassifications: [
                    MediaItemClassification.SERIES,
                    MediaItemClassification.MOVIE,
                    MediaItemClassification.TV_BOX_SET,
                    MediaItemClassification.MOVIE_BOX_SET
                ]
            };
        }

        return searchQueryParams;
    }
};

export {Filter as default, Filter};
