"use strict";

import Immutable from 'immutable';
import moment from 'moment';
import SupportedDevice from './SupportedDevice';

/**
 * OfferDTO definition
 *
 * @typedef {Object} OfferDTO
 * @property {?number} Id - The unique Offer identifier
 * @property {?string} StartAvailability - Start availability of the offer
 * @property {?string} EndAvailability - End availability of the offer
 * @property {?number} Price - The price in the currency indicated
 * @property {?string} Currency - The currency code that the price is given in
 * @property {?number} RentalTime - If rented this is the time in DAYS allowed till first play
 * @property {?number} ViewTime - Once started this is the length of time in HOURS the user has to finish viewing the movie
 * @property {?Array<SupportedDeviceDTO>} SupportedDevices - The list of supported capabilities for each device category
 * @property {?string} AssetTypeId - The type of asset this offer applies to (usually HD or SD)
 * @property {?number} WholeSalePrice
 * @property {?number} RetailPrice
 * @property {?string} OfferType
 */

/**
 * Offer definition
 *
 * @typedef {Immutable.Record} Offer
 *
 * @property {?number} id
 * @property {?Object} startAvailability
 * @property {?Object} endAvailability
 * @property {?number} price
 * @property {?string} currency
 * @property {?number} rentalTime
 * @property {?number} viewTime
 * @property {Array<SupportedDevice>} supportedDevices
 * @property {?string} assetTypeId
 * @property {?number} wholeSalePrice
 * @property {?number} retailPrice
 * @property {?string} offerType
 * @property {boolean} isInBasket
 * @property {?BasketItem} basketItem
 * @property {boolean} updatePending
 */

/**
 * Offer record
 *
 * @type {Offer}
 */
class Offer extends Immutable.Record({
    id: null,
    startAvailability: null,
    endAvailability: null,
    price: null,
    currency: null,
    rentalTime: null,
    viewTime: null,
    supportedDevices: null,
    assetTypeId: null,
    wholeSalePrice: null,
    retailPrice: null,
    offerType: null,
    isInBasket: false,
    basketItem: null,
    updatePending: false
}) {
    /**
     * toJS shallow
     * converts Record to object by keeping all second level immutable or other complex properties intact
     *
     * @return {Object}
     */
    toJSShallow() {
        return {
            ...this.toJS()
        };
    }

    /**
     * Set values from data transfer object
     *
     * @param {OfferDTO} dataTransferObject
     * @return {Offer}
     */
    fromDTO(dataTransferObject) {
        return new Offer({
            ...this.toJSShallow(),
            id: typeof dataTransferObject.Id !== 'undefined' ? dataTransferObject.Id : this.id,
            startAvailability: typeof dataTransferObject.StartAvailability !== 'undefined'
                ? moment(dataTransferObject.StartAvailability) : this.startAvailability,
            endAvailability: typeof dataTransferObject.EndAvailability !== 'undefined'
                ? moment(dataTransferObject.EndAvailability) : this.endAvailability,
            price: typeof dataTransferObject.Price !== 'undefined' ? parseFloat(dataTransferObject.Price) : this.price,
            wholeSalePrice: typeof dataTransferObject.WholeSalePrice !== 'undefined' ? parseFloat(dataTransferObject.WholeSalePrice) : this.wholeSalePrice,
            retailPrice: typeof dataTransferObject.RetailPrice !== 'undefined' ? parseFloat(dataTransferObject.RetailPrice) : this.retailPrice,
            currency: typeof dataTransferObject.Currency !== 'undefined' ? dataTransferObject.Currency : this.currency,
            rentalTime: typeof dataTransferObject.RentalTime !== 'undefined' ? dataTransferObject.RentalTime : this.rentalTime,
            viewTime: typeof dataTransferObject.ViewTime !== 'undefined' ? dataTransferObject.ViewTime : this.viewTime,
            supportedDevices: typeof dataTransferObject.SupportedDevices !== 'undefined'
                ? _getSupportedDevicesFromDTO(dataTransferObject) : this.supportedDevices,
            assetTypeId: typeof dataTransferObject.AssetTypeId !== 'undefined' ? dataTransferObject.AssetTypeId : this.assetTypeId,
            offerType: typeof dataTransferObject.OfferType !== 'undefined' ? OfferType.getValue(dataTransferObject.OfferType) : this.offerType
        });
    }

    /**
     * Set basket state
     *
     * @param {Immutable.Map<number, BasketItem>} basket
     * @param {?string} offerId
     * @return {MediaItem}
     */
    setBasketState(basket, offerId = null) {
        var basketItem = basket.get(offerId || this.id);
        var isInBasket = basketItem ? basketItem.isInBasket : false;
        var updatePending = basketItem ? basketItem.updatePending : false;

        return this.isInBasket !== isInBasket
        || this.basketItem !== basketItem
        || this.updatePending !== updatePending ? new Offer({
            ...this.toJSShallow(),
            isInBasket: isInBasket,
            basketItem: basketItem,
            updatePending: updatePending
        }) : this;
    }
}

/**
 * Get supported devices from data transfer object
 *
 * @param {OfferDTO} dataTransferObject
 * @returns {Array<SupportedDevice>}
 * @private
 */
function _getSupportedDevicesFromDTO(dataTransferObject) {
    var supportedDevices = [];
    dataTransferObject.SupportedDevices.forEach((supportedDeviceDTO) => {
        supportedDevices.push(new SupportedDevice({}).fromDTO(supportedDeviceDTO));
    });
    return supportedDevices;
}

/**
 * Offer type
 * @type {Object}
 */
var OfferType = {
    UNKNOWN: 'UNKNOWN',
    FVOD: 'FVOD',
    SVOD: 'SVOD',
    EST: 'EST',
    POEST: 'POEST',
    DTR: 'DTR',

    /**
     * Get value
     */
    getValue: function (inputValue) {
        var value = this.UNKNOWN;
        Object.keys(this).forEach((key) => {
            if (this[key] === inputValue) value = this[key];
        });
        return value;
    }
};

export {Offer as default, Offer, OfferType};
