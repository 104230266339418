"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import ImmutablePropTypes from 'react-immutable-proptypes';
import AppStore from '../../stores/AppStore';
import {debounce} from '../../utils/Common';
import MediaQuery from '../../utils/MediaQuery';
import MediaItem from '../../types/MediaItem';
import Button from '../ui/Button.react';
import Pagination from '../ui/Pagination.react';
import MediaItemTile from './MediaItemTile.react';
import MediaItemTileBubble from './MediaItemTileBubble.react';

/**
 * MediaCarousel component
 */
export default class MediaCarousel extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        title: PropTypes.string,
        linkTo: PropTypes.string,
        media: ImmutablePropTypes.mapOf(PropTypes.instanceOf(MediaItem)),
        mediaLoading: PropTypes.bool,
        tileType: PropTypes.oneOf(['poster', 'poster-bubble', 'poster-cta', 'backdrop', 'backdrop-progress-bar', 'backdrop-detailed']),
        collapsibleTiles: PropTypes.bool,
        navigationType: PropTypes.oneOf(['none', 'side-arrows', 'top-arrows']),
        gesturesEnabled: PropTypes.bool,
        highlighted: PropTypes.bool,
        className: PropTypes.string,
        isPurchaseEnabled: PropTypes.bool
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        title: '',
        linkTo: null,
        media: null,
        mediaLoading: false,
        tileType: 'poster-bubble',
        collapsibleTiles: false,
        navigationType: 'none',
        gesturesEnabled: false,
        highlighted: false,
        isPurchaseEnabled: true
    };

    /**
     * React: state
     */
    state = {
        slideTrayOffset: 0,
        navigationLeftDisabled: true,
        navigationRightDisabled: true,
        slideCount: 0,
        activeSlide: 0,
        shouldTileBubbleBeVisible: false,
        tileBubbleMediaItem: null,
        tileBubbleTileNode: null
    };

    /**
     * Constructor
     */
    constructor(props, context) {
        super(props, context);
        this._recalculateActiveSlide = debounce(this._recalculateActiveSlide, 350);
    }

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        window.addEventListener("resize", this._recalculateActiveSlide);
        this._setCarouselState();
    }

    /**
     * React: UNSAFE_componentWillReceiveProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.media && nextProps.media !== this.props.media && this.state.tileBubbleMediaItem) {
            this.setState({
                tileBubbleMediaItem: nextProps.media.get(this.state.tileBubbleMediaItem.id)
            });
        } else if (!nextProps.media) {
            this.setState({
                tileBubbleMediaItem: null,
                shouldTileBubbleBeVisible: false
            });
        }
    }

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: componentDidUpdate
     */
    componentDidUpdate(prevProps) {
        if (prevProps.media !== this.props.media) {
            this._setCarouselState();
        }
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this._recalculateActiveSlide);
    }

    /**
     * React: render
     */
    render() {
        var media = [];
        if (this.props.media && this.props.media.size > 0) {
            this.props.media.forEach((mediaItem, key) => {
                media.push(
                    <MediaItemTile key={key}
                                   mediaItem={mediaItem}
                                   tileType={this.props.tileType}
                                   toggleTileBubble={this._toggleTileBubble}
                                   collapsible={this.props.collapsibleTiles}
                                   isPurchaseEnabled={this.props.isPurchaseEnabled}/>
                );
            });

            // show loading tile set instead
        } else {
            for (let index = 0; index <= 5; index++) {
                media.push(
                    <MediaItemTile key={index}
                                   tileType={this.props.tileType}
                                   useImageLoadingIndicator={true}
                                   collapsible={this.props.collapsibleTiles}
                                   isPurchaseEnabled={this.props.isPurchaseEnabled}/>
                );
            }
        }

        if (this.props.mediaLoading && this.props.media && this.props.media.size > 0) {
            media.push(
                <MediaItemTile key="loading"
                               tileType={this.props.tileType}
                               useImageLoadingIndicator={true}
                               collapsible={this.props.collapsibleTiles}/>
            );
        }

        var className = 'media-carousel'
            + (this.props.collapsibleTiles ? ' collapsible-tiles' : '')
            + (this.props.highlighted ? ' highlighted' : '')
            + (this.props.className ? ' ' + this.props.className : '');

        return media.length === 0 ? null : (
            <div className={className}>
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 header">
                            <h2>{this.props.title || null}</h2>
                            {this.props.navigationType === 'top-arrows' && this.state.slideCount > 1 ? (
                                <Pagination className="navigation-top-arrows"
                                            pageCount={this.state.slideCount}
                                            activePage={this.state.activeSlide}
                                            navigationLeftDisabled={this.state.navigationLeftDisabled}
                                            navigationRightDisabled={this.state.navigationRightDisabled}
                                            onNavigateLeft={this._changeSlide.bind(null, -1)}
                                            onNavigateRight={this._changeSlide.bind(null, 1)}
                                            onPageSelect={this._goToSlide}/>
                            ) : null}
                            {this.props.linkTo ? (
                                <Button shape="signpost-right"
                                        className="more"
                                        type="link"
                                        linkTo={this.props.linkTo}>
                                    {AppStore.translate('button.more')}
                                </Button>
                            ) : null}
                        </div>
                    </div>

                    <div className="row">
                        <div className="viewing-frame" ref="viewingFrame">
                            <div className="slide-tray"
                                 style={{left: this.state.slideTrayOffset + 'px'}}
                                 ref="slideTray">
                                {media}
                            </div>

                            {this.props.navigationType === 'side-arrows' && this.state.slideCount > 1 ? (
                                <div className="navigation-side-arrows">
                                    <Button shape="arrow-left-large"
                                            disabled={this.state.navigationLeftDisabled}
                                            onClick={this._changeSlide.bind(null, -1)}/>
                                    <Button shape="arrow-right-large"
                                            disabled={this.state.navigationRightDisabled}
                                            onClick={this._changeSlide.bind(null, 1)}/>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {this.props.tileType === 'poster-bubble' ? (
                        <MediaItemTileBubble shouldTileBubbleBeVisible={this.state.shouldTileBubbleBeVisible}
                                             mediaItem={this.state.tileBubbleMediaItem}
                                             tileNode={this.state.tileBubbleTileNode}/>
                    ) : null}
                </div>
            </div>
        );
    }

    /**
     * Toggle tile bubble
     *
     * @param shouldBeVisible
     * @param mediaItem
     * @param tileNode
     * @private
     */
    _toggleTileBubble = (shouldBeVisible, mediaItem = null, tileNode = null) => {
        // return if tile type does not supports bubble
        if (this.props.tileType !== 'poster-bubble') return;

        this.setState({
            shouldTileBubbleBeVisible: shouldBeVisible,
            tileBubbleMediaItem: shouldBeVisible ? mediaItem : this.state.tileBubbleMediaItem,
            tileBubbleTileNode: shouldBeVisible ? tileNode : this.state.tileBubbleTileNode
        });
    };

    /**
     * Change slide
     *
     * @param {number} direction
     * @private
     */
    _changeSlide = (direction) => {
        var tileWidth = this.refs.viewingFrame.getElementsByClassName('media-item-tile')[0].getBoundingClientRect().width;
        var viewingFrameWidth = this.refs.viewingFrame.offsetWidth;
        var newSlideTrayOffset = this.state.slideTrayOffset + -1 * direction * tileWidth * this._numberOfWholeTilesIn(viewingFrameWidth);

        this._setCarouselState(newSlideTrayOffset);
    };

    /**
     * Go to slide
     *
     * @param {number} index
     * @private
     */
    _goToSlide = (index) => {
        var viewingFrameWidth = this.refs.viewingFrame.offsetWidth;
        var tileWidth = this.refs.viewingFrame.getElementsByClassName('media-item-tile')[0].getBoundingClientRect().width;

        this._setCarouselState(-1 * index * this._numberOfWholeTilesIn(viewingFrameWidth) * tileWidth);
    };

    /**
     * Recalculate active slide
     *
     * @private
     */
    _recalculateActiveSlide = () => {
        if (this.props.collapsibleTiles && MediaQuery.match(MediaQuery.BREAKPOINT_LG)) {
            var tileWidth = this.refs.viewingFrame.getElementsByClassName('media-item-tile')[0].getBoundingClientRect().width;
            var viewingFrameWidth = this.refs.viewingFrame.offsetWidth;
            var slideTrayWidth = this.refs.slideTray.scrollWidth;

            var newSlideTrayOffset = -1 * this._numberOfSlidesIn(this.state.slideTrayOffset) * this._numberOfWholeTilesIn(viewingFrameWidth) * tileWidth;
            newSlideTrayOffset = Math.abs(newSlideTrayOffset) < slideTrayWidth - this._numberOfWholeTilesIn(viewingFrameWidth) * tileWidth
                ? newSlideTrayOffset
                : newSlideTrayOffset + this._numberOfWholeTilesIn(viewingFrameWidth) * tileWidth;

            this._setCarouselState(newSlideTrayOffset);
        } else {
            this._setCarouselState(0);
        }
    };

    /**
     * Set carousel state
     *
     * @param {?number} newSlideTrayOffset
     * @private
     */
    _setCarouselState = (newSlideTrayOffset = null) => {
        if (!this.props.media || (this.props.media && this.props.media.size === 0)) return;

        var viewingFrameWidth = this.refs.viewingFrame.offsetWidth;
        var slideTrayWidth = this.refs.slideTray.scrollWidth;
        newSlideTrayOffset = typeof newSlideTrayOffset !== 'undefined' && newSlideTrayOffset !== null && newSlideTrayOffset <= 0
            ? newSlideTrayOffset : this.state.slideTrayOffset;

        this.setState({
            slideTrayOffset: newSlideTrayOffset,
            slideCount: this._numberOfSlidesIn(slideTrayWidth),
            activeSlide: this._numberOfSlidesIn(newSlideTrayOffset),
            navigationLeftDisabled: this._numberOfTilesIn(newSlideTrayOffset) === 0,
            navigationRightDisabled: this._numberOfTilesIn(newSlideTrayOffset) + this._numberOfWholeTilesIn(viewingFrameWidth) >= this._numberOfTilesIn(slideTrayWidth)
        });
    };

    /**
     * Returns rounded number of tiles in target width
     *
     * @param {number} targetWidth
     * @param {number} tileWidthPercent
     * @return {number}
     * @private
     */
    _numberOfTilesIn = (targetWidth, tileWidthPercent = 50) => {
        var tileWidth = this.refs.viewingFrame.getElementsByClassName('media-item-tile')[0].getBoundingClientRect().width;

        return Math.abs(
            targetWidth % tileWidth >= tileWidth * tileWidthPercent / 100
                ? Math.ceil(targetWidth / tileWidth)
                : Math.floor(targetWidth / tileWidth)
        );
    };

    /**
     * Returns number of whole tiles in target width
     * tile is whole if it is larger than tileWidthPercent
     *
     * @param {number} targetWidth
     * @param {number} tileWidthPercent
     * @return {number}
     * @private
     */
    _numberOfWholeTilesIn = (targetWidth, tileWidthPercent = 80) => {
        return this._numberOfTilesIn(targetWidth, tileWidthPercent);
    };

    /**
     * Returns rounded number of slides in target width
     *
     * @param {number} targetWidth
     * @param {number} slideWidthPercent
     * @return {number}
     * @private
     */
    _numberOfSlidesIn = (targetWidth, slideWidthPercent = 10) => {
        var viewingFrameWidth = this.refs.viewingFrame.offsetWidth;
        var tileWidth = this.refs.viewingFrame.getElementsByClassName('media-item-tile')[0].getBoundingClientRect().width;
        var slideWidth = this._numberOfWholeTilesIn(viewingFrameWidth) * tileWidth;

        return Math.abs(
            targetWidth % slideWidth >= slideWidth * slideWidthPercent / 100
                ? Math.ceil(targetWidth / slideWidth)
                : Math.floor(targetWidth / slideWidth)
        );
    };

    /**
     * Returns number of whole slides in target width
     * slide is whole if it is larger slideWidthPercent
     *
     * @param {number} targetWidth
     * @param {number} slideWidthPercent
     * @return {number}
     * @private
     */
    _numberOfWholeSlidesIn = (targetWidth, slideWidthPercent = 80) => {
        return this._numberOfSlidesIn(targetWidth, slideWidthPercent);
    };
}
