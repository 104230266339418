"use strict";

import React from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import MediaActions from '../actions/MediaActions';
import { MediaItemClassification } from '../types/MediaItem';
import AppStore from '../stores/AppStore';
import MediaStore from '../stores/MediaStore';
import Filter from '../lib/Filter';
import MediaGallery from './elements/MediaGallery.react';
import MediaCarousel from './elements/MediaCarousel.react';
import FilterBar from './elements/FilterBar.react';

/**
 * LibraryPage component
 */
export default class LibraryPage extends React.Component {
    /**
     * React: state
     */
    state = {
        activeFilters: AppStore.activeFilters,
        mediaBucket: null,
        recentlyViewedMediaBucket: null
    };

    /**
     * Bucket keys
     */
    _mediaBucketKey = null;
    _recentlyViewedMediaBucketKey = null;

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        this._mediaBucketKey = MediaActions.getLibraryMedia();
        this._recentlyViewedMediaBucketKey = MediaActions.getRecentlyViewedMedia();
    }

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        AppStore.addUpdateListener(this._setActiveFilters);
        MediaStore.addUpdateListener(this._setMediaBucket);
        MediaStore.addUpdateListener(this._setRecentlyViewedMediaBucket);
    }

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AppStore.removeUpdateListener(this._setActiveFilters);
        MediaStore.removeUpdateListener(this._setMediaBucket);
        MediaStore.removeUpdateListener(this._setRecentlyViewedMediaBucket);
        this._mediaBucketKey = MediaStore.destroyMediaBucket(this._mediaBucketKey);
        this._recentlyViewedMediaBucketKey = MediaStore.destroyMediaBucket(this._recentlyViewedMediaBucketKey);
    }

    /**
     * React: render
     */
    render() {
        var mediaBucket = this.state.mediaBucket;
        const media = mediaBucket
            // filter out SERIES media items for season packshot level display
            ? mediaBucket.media().filter(
                item => item.classification !== MediaItemClassification.SERIES
                    && item.classification !== MediaItemClassification.TV_BOX_SET
                    && item.classification !== MediaItemClassification.MOVIE_BOX_SET
            )
            : null;
        var filteredMedia = media ? Filter.media(media, this.state.activeFilters) : null;

        var recentlyViewedMediaBucket = this.state.recentlyViewedMediaBucket;
        var recentlyViewedMedia = recentlyViewedMediaBucket ? recentlyViewedMediaBucket.media() : null;
        var filteredRecentlyViewedMedia = recentlyViewedMedia ? Filter.media(recentlyViewedMedia, this.state.activeFilters) : null;

        return (
            <main className="library-page">
                <h2 className="page-title-header">{AppStore.translate('view.library_page.title').toUpperCase()}</h2>
                {filteredRecentlyViewedMedia && filteredRecentlyViewedMedia.size > 0 ? (
                    <MediaCarousel className="recently-viewed-carousel"
                                   key="currently_watching"
                                   title={AppStore.translate('view.recently_viewed_carousel.title')}
                                   media={filteredRecentlyViewedMedia}
                                   tileType="backdrop-progress-bar"
                                   navigationType="side-arrows"
                                   mediaLoading={recentlyViewedMediaBucket && recentlyViewedMediaBucket.updatePending}/>
                ) : null}
                <FilterBar/>
                {media && media.size === 0 && !mediaBucket.updatePending ? (
                    <div className="no-results">
                        {AppStore.translate('view.library_page.message.no_media_in_library')}
                    </div>
                ) : (filteredMedia && filteredMedia.size === 0 && !mediaBucket.updatePending ? (
                    <div className="no-results">
                        {AppStore.translate('message.no_media_for_filter_selection')}
                    </div>
                ) : (
                    <MediaGallery title={AppStore.translate('view.library_page.title.purchased_media')}
                                  // media={media}
                                  media={filteredMedia}
                                  mediaItemInfoTextVisible={false}
                                  mediaLoading={mediaBucket && mediaBucket.updatePending}/>
                ))}
            </main>
        );
    }

    /**
     * Set active filters
     */
    _setActiveFilters = () => {
        this.setState({
            activeFilters: AppStore.activeFilters
        });
    };

    /**
     * Set mediaBucket
     */
    _setMediaBucket = () => {
        this.setState({
            mediaBucket: MediaStore.getMediaBucket(this._mediaBucketKey)
        });
    };

    /**
     * Set recently viewed mediaBucket
     */
    _setRecentlyViewedMediaBucket = () => {
        this.setState({
            recentlyViewedMediaBucket: MediaStore.getMediaBucket(this._recentlyViewedMediaBucketKey)
        });
    };
}
