"use strict";

import * as Request from './Request';
import ApiEndpoints from './ApiEndpoints';
import Config from '../Config';

/**
 * Sign up user
 *
 * @param {Object} params
 * @return {*}
 */
export function signUpUser(params = {}) {
    return Request.getRequestPromise({
        url: ApiEndpoints.SIGN_UP_USER,
        method: 'POST',
        qs: {
            v: 3
        },
        body: {
            Title: '',
            GivenName: params.firstName || params.fullName || '',
            LastName: params.lastName || '',
            Password: params.password || '',
            Dob: '2016-04-25T07:23:45.876Z',
            Address1: '',
            Address2: '',
            City: '',
            Country: '',
            PostCode: '',
            DayTimeTelephone: '',
            MobileTelephone: '',
            FirmName: '',
            Gender: 'm',
            AdditionalData: {},
            EmailAddress: params.email,
            AcceptedTandCs: typeof params.acceptTermsAndConditions !== 'undefined'
                ? params.acceptTermsAndConditions : true
        },
        headers: {
            'Authorization': 'Bearer ' + Config.TRUSTED_APPLICATION_TOKEN
        }
    });
}

/**
 * Sign In user
 *
 * @param {Object} params
 * @param {string} params.username
 * @param {string} params.password
 * @return {*}
 */
export function signInUser(params = {}) {
    return Request.getRequestPromise({
        url: ApiEndpoints.SIGN_IN_USER,
        method: 'POST',
        qs: {
            v: 3
        },
        body: {
            "ActionType": 0,
            "ParametersDictionary": {
                "PortalCredentials": params.username + ":" + params.password
            }
        }
    });
}

/**
 * Get user profile
 *
 * @return {*}
 */
export function getUserProfile() {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.GET_USER_PROFILE,
        method: 'GET',
        qs: {
            v: 3
        }
    });
}

/**
 * Update user profile
 *
 * @param {Object} params
 * @return {*}
 */
export function updateUserProfile(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.UPDATE_USER_PROFILE,
        method: 'PUT',
        qs: {
            v: 3
        },
        body: {
            Title: '',
            GivenName: params.firstName || params.fullName || '',
            LastName: params.lastName || ''
        }
    });
}

/**
 * Get user preferences
 *
 * @return {*}
 */
export function getUserPreferences(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.GET_USER_PREFERENCES,
        method: 'GET',
        qs: {
            v: 3
        }
    }, params.authToken);
}

/**
 * Update user preferences
 *
 * @param {Object} params
 * @return {*}
 */
export function updateUserPreferences(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.UPDATE_USER_PREFERENCES,
        method: 'PUT',
        qs: {
            v: 3
        },
        body: {
            Preferences: params.preferences
        }
    }, params.authToken);
}

/**
 * Activate user
 *
 * @param params
 * @param {string} params.token
 * @returns {*}
 */
export function activateUser(params = {}) {
    return Request.getRequestPromise({
        url: ApiEndpoints.ACTIVATE_USER,
        method: 'GET',
        qs: {
            v: 3,
            token: params.token
        },
        headers: {
            'Authorization': 'Bearer ' + Config.TRUSTED_APPLICATION_TOKEN
        }
    });
}

/**
 * Accept terms and conditions
 *
 * @returns {*}
 */
export function acceptTermsAndConditions() {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.ACCEPT_TERMS_AND_CONDITIONS,
        method: 'GET',
        qs: {
            v: 3
        }
    });
}

/**
 * Change user password
 *
 * @param params
 * @param {string} params.oldPassword
 * @param {string} params.newPassword
 * @returns {*}
 */
export function changeUserPassword(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.CHANGE_USER_PASSWORD,
        method: 'PUT',
        qs: {
            v: 3
        },
        body: {
            "OldPassword": params.oldPassword,
            "NewPassword": params.newPassword
        }
    });
}

/**
 * Send reset password email
 *
 * @param params
 * @param {string} params.email
 * @returns {*}
 */
export function sendResetUserPasswordEmail(params = {}) {
    return Request.getRequestPromise({
        url: ApiEndpoints.SEND_RESET_USER_PASSWORD_EMAIL,
        method: 'GET',
        qs: {
            v: 3,
            userName: params.email
        },
        headers: {
            'Authorization': 'Bearer ' + Config.TRUSTED_APPLICATION_TOKEN
        }
    });
}

/**
 * Send reset password email
 *
 * @param params
 * @param {string} params.email
 * @returns {*}
 */
export function resendActivationEmail(params = {}) {
    return Request.getRequestPromise({
        url: ApiEndpoints.RESEND_ACTIVATION_EMAIL,
        method: 'GET',
        qs: {
            v: 3,
            userName: params.email
        },
        headers: {
            'Authorization': 'Bearer ' + Config.TRUSTED_APPLICATION_TOKEN
        }
    });
}

/**
 * Submit new reset password
 *
 * @param params
 * @param {string} params.token
 * @param {string} params.newPassword
 * @returns {*}
 */
export function resetUserPassword(params = {}) {
    return Request.getRequestPromise({
        url: ApiEndpoints.RESET_USER_PASSWORD,
        method: 'PUT',
        qs: {
            v: 3
        },
        body: {
            "Token": params.token,
            "NewPassword": params.newPassword
        },
        headers: {
            'Authorization': 'Bearer ' + Config.TRUSTED_APPLICATION_TOKEN
        }
    });
}

/**
 * Create payment provider customer
 *
 * @param {Object} params
 * @return {*}
 */
export function createPaymentProviderCustomer(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.CREATE_PAYMENT_PROVIDER_CUSTOMER(),
        method: 'POST',
        qs: {
            v: 3
        },
        body: {
            TokenId: params.stripeTokenId,
            Email: params.email
        }
    });
}

/**
 * Get setup intent secret
 *
 * @return {*}
 */
export function getSetupIntentSecret() {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.CREATE_PAYMENT_PROVIDER_CUSTOMER(),
        method: 'POST',
        qs: {
            v: 3
        },
        body: {
            TokenId: null,
            Email: null
        }
    });
}

/**
 * Update payment provider customer
 *
 * @param {Object} params
 * @return {*}
 */
export function updatePaymentProviderCustomer(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.UPDATE_PAYMENT_PROVIDER_CUSTOMER(),
        method: 'PUT',
        qs: {
            v: 3
        },
        body: {
            TokenId: params.stripeTokenId,
            Description: params.description || '',
            Email: params.email,
            Metadata: params.metadata || {}
        }
    });
}

/**
 * Remove payment provider customer
 *
 * @return {*}
 */
export function removePaymentProviderCustomer() {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.REMOVE_PAYMENT_PROVIDER_CUSTOMER(),
        method: 'DELETE',
        qs: {
            v: 3
        }
    });
}

/**
 * Retrieve stripe token
 *
 * @param {Object} params
 * @return {*}
 */
export function retrieveStripeToken(params = {}) {
    return Request.getRequestPromise({
        url: ApiEndpoints.RETRIEVE_STRIPE_TOKEN(params.stripeTokenId),
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + Config.STRIPE_API_PK
        }
    });
}

/**
 * Create stripe card token
 *
 * @param {Object} params
 * @return {*}
 */
export function createStripeCardToken(params = {}) {
    return Request.getRequestPromise({
        url: ApiEndpoints.CREATE_STRIPE_TOKEN,
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + Config.STRIPE_API_PK
        },
        form: {
            card: {
                name: params.cardName || '',
                number: params.cardNumber,
                cvc: params.cardCVC,
                exp_year: params.cardExpiryYear,
                exp_month: params.cardExpiryMonth,
                address_line1: params.cardAddressLine1 || '',
                address_line2: params.cardAddressLine2 || '',
                address_city: params.cardAddressCity || '',
                address_zip: params.cardAddressZip || ''
            }
        }
    });
}

/**
 * Get customer payment details
 *
 * @return {*}
 */
export function getCustomerPaymentDetails() {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.GET_CUSTOMER_PAYMENT_DETAILS,
        method: 'GET',
        qs: {
            v: 3
        }
    });
}
