"use strict";

import Config from './Config';

/**
 * vudevicefp ES6 shim
 * https://vucscdnprodne.azureedge.net/scripts/content_services/device_management/vufpindex.html
 * https://vucscdnprodne.azureedge.net/scripts/content_services/device_management/vudevicefp-debug.js
 */
export default class VubiquityDeviceFingerprint {
    static initialize(callback) {
        if (!window.Fingerprint2) {
            let scriptElement = document.createElement("script");
            scriptElement.src = Config.VUBIQUITY_DEVICE_FINGERPRINT_LIBRARY;
            scriptElement.onload = scriptElement.onreadystatechange = function () {
                if (!this.readyState || this.readyState === 'complete') {
                    callback();
                }
            };
            document.head.appendChild(scriptElement);
        } else {
            callback();
        }
    }
}
