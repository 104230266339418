"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import MediaItem from '../../../types/MediaItem';
import AppStore from '../../../stores/AppStore';

export default class MediaItemMeta extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        mediaItem: PropTypes.instanceOf(MediaItem)
    };

    /**
     * React: render
     */
    render() {
        let mediaItem = this.props.mediaItem;
        return (
            <Helmet>
                <title>{` ${AppStore.translate('general.site.title')} | ${mediaItem.title}`}</title>
                <meta name="description" content={mediaItem.shortSynopsis}/>
            </Helmet>
        );
    }
}
