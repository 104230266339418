"use strict";

import React from 'react';
import OverlayIds from '../../lib/OverlayIds';
import AppStore from '../../stores/AppStore';
import UIActions from '../../actions/UIActions';

/**
 * CVVInfoOverlay component
 */
export default class CVVInfoOverlay extends React.Component {

    focusRef = React.createRef();

    componentDidMount() {
        if (this.focusRef.current) {
            this.focusRef.current.focus();
        }
    }
    /**
     * React: render
     */
    render() {
        return (
            <div
                className="alert-box-size col-xs-12 col-sm-8 col-md-6"
                onBlur={this._closeCVVInfoOverlay}
                tabIndex="0"
                ref={this.focusRef}>
                <h2 className="overlay-header">
                    {AppStore.translate('view.payment_page.card.what_is_cvc')}
                    <button className="close" onClick={this._closeCVVInfoOverlay}/>
                </h2>
                <div className="overlay-content">
                    <p>{AppStore.translate('view.payment_page.card.cvc_explanation')}</p>
                </div>
            </div>
        );
    }

    /**
     * Close CVV info overlay
     */
    _closeCVVInfoOverlay = () => {
        UIActions.closeCustomOverlay({
            overlayId: OverlayIds.CVV_INFO,
            showCloseButton: false
        });
    };
}
