"use strict";

import Promise from 'bluebird';
import * as AppDispatcher from '../dispatchers/AppDispatcher';
import ApiService from '../lib/api/ApiService';
import ActionTypes from './ActionTypes';

/**
 * Flux: MediaActions
 */
export default {
    // Search media
    searchMedia: (params = {}) => {
        if (!params.query || params.query.length < 3) return;
        let promise = ApiService.searchMedia(params)
            .then(searchResult => {
                return Promise.resolve(searchResult.mediaItemDTOCollection)
                    //.then(ApiService.generateMediaGraphBucketPayload)
                    .then(ApiService.generateMediaBucketPayload)
                    .then(mediaGraphBucketPayload => ({
                        ...mediaGraphBucketPayload,
                        resultTotalCount: searchResult.resultTotalCount
                    }));
            });
        return AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_SEARCH_MEDIA,
            success: ActionTypes.REQUEST_SEARCH_MEDIA_SUCCESS,
            failure: ActionTypes.REQUEST_SEARCH_MEDIA_ERROR
        }, params);
    },

    // Get media item
    getMediaItem: (params = {}) => {
        let promise = ApiService.getMediaItem(params)
        // .then(ApiService.getRatingForMediaItem)
        // .then(ApiService.getPersonalRatingForMediaItem)
            .then(ApiService.generateMediaItemGraphBucketPayload);

        return AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_GET_MEDIA_ITEM,
            success: ActionTypes.REQUEST_GET_MEDIA_ITEM_SUCCESS,
            failure: ActionTypes.REQUEST_GET_MEDIA_ITEM_ERROR
        }, params);
    },

    // Get media item recommendations
    getMediaItemRecommendations: (params = {}) => {
        let promise = ApiService.getMediaItemRecommendations(params)
            .then(ApiService.generateMediaGraphBucketPayload);

        return AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_GET_MEDIA_ITEM_RECOMMENDATIONS,
            success: ActionTypes.REQUEST_GET_MEDIA_ITEM_RECOMMENDATIONS_SUCCESS,
            failure: ActionTypes.REQUEST_GET_MEDIA_ITEM_RECOMMENDATIONS_ERROR
        }, params);
    },

    // Get collection media
    getCollectionMedia: (params = {}) => {
        let promise = ApiService.getCollectionMedia(params)
            .then(mediaItemDTOCollection => params.skipPreProcessing
                ? ApiService.generateMediaGraphBucketPayload(mediaItemDTOCollection)
                : ApiService.generateMediaBucketPayload(mediaItemDTOCollection));

        return AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_GET_COLLECTION_MEDIA,
            success: ActionTypes.REQUEST_GET_COLLECTION_MEDIA_SUCCESS,
            failure: ActionTypes.REQUEST_GET_COLLECTION_MEDIA_ERROR
        }, params);
    },

    // Get promotion media
    getPromotionMedia: (params = {}) => {
        let promise = ApiService.getMedia(params)
            //.then(ApiService.generateMediaGraphBucketPayload);
            .then(ApiService.generateMediaBucketPayload);

        return AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_GET_PROMOTION_MEDIA,
            success: ActionTypes.REQUEST_GET_PROMOTION_MEDIA_SUCCESS,
            failure: ActionTypes.REQUEST_GET_PROMOTION_MEDIA_ERROR
        }, params);
    },

    // Get genre media
    getGenreMedia: (params = {}) => {
        const promise = ApiService.getGenreMedia(params)
            .then(genreResult => {
                return Promise.resolve(genreResult.mediaItemDTOCollection)
                    .then(ApiService.generateMediaBucketPayload)
                    .then(mediaGraphBucketPayload => ({
                        ...mediaGraphBucketPayload,
                        resultTotalCount: genreResult.resultTotalCount
                    })
                    );
            });

        return AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_GET_GENRE_MEDIA_FILTERED,
            success: ActionTypes.REQUEST_GET_GENRE_MEDIA_FILTERED_SUCCESS,
            failure: ActionTypes.REQUEST_GET_GENRE_MEDIA_FILTERED_ERROR
        }, params);
    },

    // Get cast media
    getCastMedia: (params = {}) => {
        let promise = ApiService.getCastMedia(params)
            .then(ApiService.generateMediaGraphBucketPayload);

        return AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_GET_CAST_MEDIA,
            success: ActionTypes.REQUEST_GET_CAST_MEDIA_SUCCESS,
            failure: ActionTypes.REQUEST_GET_CAST_MEDIA_ERROR
        }, params);
    },

    // Get watchlist media
    getWatchlistMedia: (params = {}) => {
        let promise = ApiService.getWatchlistMedia(params)
            .then(result => {
                return ApiService.generateMediaGraphBucketPayload(result.mediaItemDTOCollection)
                    .then(mediaGraphBucketPayload => ({
                        ...mediaGraphBucketPayload,
                        watchlistItemDTOCollection: result.watchlistItemDTOCollection
                    }));
            });

        return AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_GET_WATCHLIST_MEDIA,
            success: ActionTypes.REQUEST_GET_WATCHLIST_MEDIA_SUCCESS,
            failure: ActionTypes.REQUEST_GET_WATCHLIST_MEDIA_ERROR
        }, params);
    },

    // Get recently viewed media
    getRecentlyViewedMedia: (params = {}) => {
        let promise = ApiService.getRecentlyViewedMedia(params)
            .then(result => {
                return ApiService.generateMediaGraphBucketPayload(result.mediaItemDTOCollection)
                    .then(mediaGraphBucketPayload => {
                        return ApiService.getCurrentEntitlements(params)
                            .then(entitlementDTOCollection => ({
                                ...mediaGraphBucketPayload,
                                recentlyViewedItemDTOCollection: result.recentlyViewedItemDTOCollection,
                                entitlementDTOCollection
                            }));
                    });
            });

        return AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_GET_RECENTLY_VIEWED_MEDIA,
            success: ActionTypes.REQUEST_GET_RECENTLY_VIEWED_MEDIA_SUCCESS,
            failure: ActionTypes.REQUEST_GET_RECENTLY_VIEWED_MEDIA_ERROR
        }, params);
    },

    // Get library media
    getLibraryMedia: (params = {}) => {
        let promise = ApiService.getLibraryMedia(params)
            .then(result => {
                return ApiService.generateMediaGraphBucketPayload(result.mediaItemDTOCollection)
                    .then(mediaGraphBucketPayload => ({
                        ...mediaGraphBucketPayload,
                        entitlementDTOCollection: result.entitlementDTOCollection
                    }));
            });

        return AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_GET_LIBRARY_MEDIA,
            success: ActionTypes.REQUEST_GET_LIBRARY_MEDIA_SUCCESS,
            failure: ActionTypes.REQUEST_GET_LIBRARY_MEDIA_ERROR
        }, params);
    },

    // Rate media item
    rateMediaItem: (params = {}) => {
        let promise = ApiService.rateMediaItem(params);

        AppDispatcher.dispatchAsync(promise, {
            request: ActionTypes.REQUEST_RATE_MEDIA_ITEM,
            success: ActionTypes.REQUEST_RATE_MEDIA_ITEM_SUCCESS,
            failure: ActionTypes.REQUEST_RATE_MEDIA_ITEM_ERROR
        }, params);
    }
};
