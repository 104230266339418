/** @module ApiService */
"use strict";

import * as AppApi from './AppApi';
import * as AuthApi from './AuthApi';
import * as MediaItemApi from './MediaItemApi';
import * as MediaItemGraph from './MediaItemGraph';
import * as WatchlistApi from './WatchlistApi';
import * as PurchaseApi from './PurchaseApi';
import * as EntitlementApi from './EntitlementApi';
import * as LoggingApi from './LoggingApi';

export default {
    // auth
    signUpUser: AuthApi.signUpUser,
    getUserProfile: AuthApi.getUserProfile,
    updateUserProfile: AuthApi.updateUserProfile,
    getUserPreferences: AuthApi.getUserPreferences,
    updateUserPreferences: AuthApi.updateUserPreferences,
    signInUser: AuthApi.signInUser,
    activateUser: AuthApi.activateUser,
    acceptTermsAndConditions: AuthApi.acceptTermsAndConditions,
    changeUserPassword: AuthApi.changeUserPassword,
    sendResetUserPasswordEmail: AuthApi.sendResetUserPasswordEmail,
    resendActivationEmail: AuthApi.resendActivationEmail,
    resetUserPassword: AuthApi.resetUserPassword,
    createPaymentProviderCustomer: AuthApi.createPaymentProviderCustomer,
    updatePaymentProviderCustomer: AuthApi.updatePaymentProviderCustomer,
    removePaymentProviderCustomer: AuthApi.removePaymentProviderCustomer,
    getSetupIntentSecret: AuthApi.getSetupIntentSecret,
    retrieveStripeToken: AuthApi.retrieveStripeToken,
    createStripeCardToken: AuthApi.createStripeCardToken,
    getCustomerPaymentDetails: AuthApi.getCustomerPaymentDetails,

    // app
    getAppSettings: AppApi.getAppSettings,
    getCollectionList: AppApi.getCollectionList,
    getPromotionList: AppApi.getPromotionList,
    getTranslations: AppApi.getTranslations,
    getGenreList: AppApi.getGenreList,
    getGenreIcons: AppApi.getGenreIcons,
    getCertificateList: AppApi.getCertificateList,
    getPlatformHelp: AppApi.getPlatformHelp,
    getPlatformTermsAndConditions: AppApi.getPlatformTermsAndConditions,
    getAlerts: AppApi.getAlerts,
    cancelAlert: AppApi.cancelAlert,
    incrementAlertDisplayCount: AppApi.incrementAlertDisplayCount,
    lookupAddressByPostcode: AppApi.lookupAddressByPostcode,

    // media item
    searchMedia: MediaItemApi.searchMedia,
    getMedia: MediaItemApi.getMedia,
    getMediaItem: MediaItemApi.getMediaItem,
    // getMediaItemRating: MediaItemApi.getMediaItemRating,
    // getMediaItemPersonalRating: MediaItemApi.getMediaItemPersonalRating,
    getMediaItemRecommendations: MediaItemApi.getMediaItemRecommendations,
    getCollectionMedia: MediaItemApi.getCollectionMedia,
    getGenreMedia: MediaItemApi.getGenreMedia,
    getCastMedia: MediaItemApi.getCastMedia,
    getWatchlistMedia: MediaItemApi.getWatchlistMedia,
    getRecentlyViewedMedia: MediaItemApi.getRecentlyViewedMedia,
    getLibraryMedia: MediaItemApi.getLibraryMedia,
    rateMediaItem: MediaItemApi.rateMediaItem,

    // media item graph
    getMediaItemGraph: MediaItemGraph.getMediaItemGraph,
    getMediaGraph: MediaItemGraph.getMediaGraph,
    getMediaItemParent: MediaItemGraph.getMediaItemParent,
    getMediaItemRootParent: MediaItemGraph.getMediaItemRootParent,
    generateMediaItemBucketPayload: MediaItemGraph.generateMediaItemBucketPayload,
    generateMediaBucketPayload: MediaItemGraph.generateMediaBucketPayload,
    generateMediaItemGraphBucketPayload: MediaItemGraph.generateMediaItemGraphBucketPayload,
    generateMediaGraphBucketPayload: MediaItemGraph.generateMediaGraphBucketPayload,

    // watchlist
    getWatchlist: WatchlistApi.getWatchlist,
    addMediaItemToWatchlist: WatchlistApi.addMediaItemToWatchlist,
    removeMediaItemFromWatchlist: WatchlistApi.removeMediaItemFromWatchlist,

    // purchase
    getBasketItems: PurchaseApi.getBasketItems,
    addMediaItemOfferToBasket: PurchaseApi.addMediaItemOfferToBasket,
    removeBasketItem: PurchaseApi.removeBasketItem,
    removeAllBasketItems: PurchaseApi.removeAllBasketItems,
    validateBundleOffer: PurchaseApi.validateBundleOffer,
    chargeBasketWithPaymentProviderCustomerId: PurchaseApi.chargeBasketWithPaymentProviderCustomerId,
    chargeBasketWithStripeV3: PurchaseApi.chargeBasketWithStripeV3,
    chargeBasketWithTemporaryCardToken: PurchaseApi.chargeBasketWithTemporaryCardToken,
    verifyVoucher: PurchaseApi.verifyVoucher,
    createPurchaseOrderVODItem: PurchaseApi.createPurchaseOrderVODItem,

    // entitlement
    getEntitlement: EntitlementApi.getEntitlement,
    getCurrentEntitlements: EntitlementApi.getCurrentEntitlements,
    getHistoryEntitlements: EntitlementApi.getHistoryEntitlements,
    getRecentlyViewedItems: EntitlementApi.getRecentlyViewedItems,
    getAMSLicense: EntitlementApi.getAMSLicense,
    getTrailerLicense: EntitlementApi.getTrailerLicense,
    getRegisteredDevices: EntitlementApi.getRegisteredDevices,
    registerDevice: EntitlementApi.registerDevice,
    renameRegisteredDevice: EntitlementApi.renameRegisteredDevice,
    removeRegisteredDevice: EntitlementApi.removeRegisteredDevice,
    createPlaybackLog: EntitlementApi.createPlaybackLog,
    validateStbLinkCode: EntitlementApi.validateStbLinkCode,
    consumeStbLinkCode: EntitlementApi.consumeStbLinkCode,
    confirmStbRegistration: EntitlementApi.confirmStbRegistration,

    // logging
    logEvent: LoggingApi.logEvent,
    logException: LoggingApi.logException,
    logPageView: LoggingApi.logPageView,
    logCustomEvent: LoggingApi.logCustomEvent
};
