"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import Router from '../lib/Router';
import {debounce} from '../utils/Common';
import AppStore from '../stores/AppStore';
import AuthActions from '../actions/AuthActions';
import NotificationActions from '../actions/NotificationActions';
import AuthStore from '../stores/AuthStore';
import Button from './ui/Button.react';
import Spinner from './ui/Spinner2.react';

/**
 * AccountAcceptTermsAndConditionsPage component
 */
export default class AccountAcceptTermsAndConditionsPage extends React.Component {
    /**
     * React: state
     */
    state = {
        termsAndConditionsRead: false,
        termsAndConditionsAccepted: false,
        updatePending: false
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        this._setNavigationState = debounce(this._toggleTermsAndConditionsRead, 200);
    }

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        AuthStore.addUpdateListener(this._setFormStatus);
        this._toggleTermsAndConditionsRead();
        document.body.className += " black-background";
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AuthStore.removeListener(AuthStore.STORE_UPDATED, this._setFormStatus);
        this.setState({updatePending: false});

        document.body.className = document.body.className.replace(/black-background/,'');
    }

    /**
     * React: render
     */
    render() {
        let termsAndConditions = AppStore.platformArticles.get('terms_and_conditions')
            ? AppStore.platformArticles.get('terms_and_conditions').body : null;

        return (
            <main className="account-page container">
                <div className="row">
                    <div className="account-page-content col-xs-12 col-sm-8 col-md-5">

                        <div className="form-title">
                            <h4>
                                {this.context.router.isActive(Router.ACCEPT_TERMS_AND_CONDITIONS)
                                    ? AppStore.translate('view.account_page.title.terms_and_conditions')
                                    : AppStore.translate('view.account_page.title.terms_and_conditions_updated')}
                            </h4>
                        </div>

                        <div className="login-avatar">
                            <i className="ui-icon icon-avatar-profile-circle"/>
                        </div>

                        <div className="terms-text-inner-container"
                             onScroll={this._toggleTermsAndConditionsRead}>
                            {termsAndConditions ? <span className="tc-html" dangerouslySetInnerHTML={{__html: termsAndConditions}}></span> : (
                                <Spinner />
                            )}
                        </div>

                        <div
                            className={"terms-conditions-buttons-container cta-buttons-container" + (this.context.router.isActive(Router.ACCEPT_TERMS_AND_CONDITIONS) ? " center-button" : "")}>
                            {!this.context.router.isActive(Router.ACCEPT_TERMS_AND_CONDITIONS) ? (
                                <Button type="button"
                                        shape="square"
                                        disabled={this.state.updatePending}
                                        onClick={AuthActions.signOutUser}>
                                    {AppStore.translate('button.decline')}
                                </Button>
                            ) : null}
                            <Button type="button"
                                    shape="square"
                                    className="cta-play"
                                    disabled={this.state.updatePending
                                    || !this.state.termsAndConditionsRead}
                                    updatePending={this.state.updatePending}
                                    onClick={this._acceptTermsAndConditions}>
                                {this.context.router.isActive(Router.ACCEPT_TERMS_AND_CONDITIONS)
                                    ? AppStore.translate('button.complete')
                                    : AppStore.translate('button.accept')}
                            </Button>
                        </div>
                    </div>
                </div>
            </main>
        );
    }

    /**
     * Toggle terms and conditions read
     *
     * @private
     */
    _toggleTermsAndConditionsRead = () => {
        var componentDOMNode = ReactDOM.findDOMNode(this);
        var termsAndConditionsContainer = componentDOMNode.querySelector('.terms-text-inner-container');

        if(termsAndConditionsContainer.scrollTop + termsAndConditionsContainer.offsetHeight >= termsAndConditionsContainer.scrollHeight) {
            this.setState({
                termsAndConditionsRead: true
            });
        }
    };

    /**
     * Accept terms and conditions
     *
     * @private
     */
    _acceptTermsAndConditions = () => {
        this.setState({
            updatePending: true
        }, () => {
            AuthActions.acceptTermsAndConditions();
        });
    };

    /**
     * Set form status
     *
     * @private
     */
    _setFormStatus = () => {
        if (this.state.updatePending && !AuthStore.updatePending && AuthStore.error) {
            this.setState({updatePending: false});
        } else if (this.state.updatePending && !AuthStore.updatePending) {
            if (this.context.router.isActive(Router.ACCEPT_TERMS_AND_CONDITIONS)) {
                setTimeout(() => {
                    NotificationActions.displayNotification({
                        notificationType: 'success',
                        content: AppStore.translate('notification.account.success.signed_up')
                    });
                }, 0);
            }
            this.setState({updatePending: false});
        }
    };
}
