"use strict";

import Immutable from 'immutable';

/**
 * VoucherDTO definition
 *
 * @typedef {Object} VoucherDTO
 * @property {?number} Value - Value of the voucher
 * @property {?number} RedeemableValue
 * @property {?string} Status  ['NotFound', 'Valid', 'CampaignNotActive', 'Expired', 'NoOfferFound', 'Redeemed',
 *                              'NotValidForUser', 'CampaignNotStarted'],
 * @property {?string} StatusMessage
 */

/**
 * Voucher definition
 *
 * @typedef {Immutable.Record} Voucher
 * @property {?number} value
 * @property {?number} redeemableValue
 * @property {?string} status
 * @property {?string} statusMessage
 */

/**
 * Voucher record
 *
 * @type {Voucher}
 */
class Voucher extends Immutable.Record({
    value: null,
    redeemableValue: null,
    status: null,
    statusMessage: null
}) {
    /**
     * Set values from data transfer object
     *
     * @param {VoucherDTO} dataTransferObject
     * @return {Voucher}
     */
    fromDTO(dataTransferObject) {
        return new Voucher({
            ...this.toJS(),
            value: typeof dataTransferObject.Value !== 'undefined' ? parseFloat(dataTransferObject.Value) : this.value,
            redeemableValue: typeof dataTransferObject.RedeemableValue !== 'undefined' ? parseFloat(dataTransferObject.RedeemableValue) : this.redeemableValue,
            status: typeof dataTransferObject.Status !== 'undefined' ? VoucherStatus.getValue(dataTransferObject.Status) : this.status,
            statusMessage: typeof dataTransferObject.StatusMessage !== 'undefined' ? dataTransferObject.StatusMessage : this.statusMessage
        });
    }
}

/**
 * VoucherStatus
 * @type {Object}
 */
var VoucherStatus = {
    NOT_FOUND: 'NotFound',
    VALID: 'Valid',
    CAMPAIGN_NOT_ACTIVE: 'CampaignNotActive',
    EXPIRED: 'Expired',
    NO_OFFER_FOUND: 'NoOfferFound',
    REDEEMED: 'Redeemed',
    NOT_VALID_FOR_USER: 'NotValidForUser',
    CAMPAIGN_NOT_STARTED: 'CampaignNotStarted',

    voucherStatusTypes: ['NotFound', 'Valid', 'CampaignNotActive', 'Expired', 'NoOfferFound', 'Redeemed',
        'NotValidForUser', 'CampaignNotStarted'],

    /**
     * Get value
     */
    getValue: function (inputIndex) {
        var inputValue = this.voucherStatusTypes[inputIndex];
        var value = this.NOT_FOUND;
        Object.keys(this).forEach((key) => {
            if (this[key] === inputValue) value = this[key];
        });
        return value;
    }
};

export  {Voucher as default, Voucher, VoucherStatus};
