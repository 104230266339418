"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import RadioButton from './RadioButton.react';
import Checkbox from './Checkbox.react';

/**
 * Choice component
 */
export default class Choice extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        choices: PropTypes.oneOfType([
            PropTypes.objectOf(PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.object
            ])),
            PropTypes.arrayOf(PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.object
            ]))
        ]).isRequired,
        choiceLabel: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.string
        ]),
        valueReference: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.string
        ]),
        placeholder: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.object,
            PropTypes.arrayOf(PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.object
            ]))
        ]),
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
        multiple: PropTypes.bool,
        updatePending: PropTypes.bool,
        type: PropTypes.oneOf(['choice-select', 'choice-radio', 'choice-checkbox'])
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        choices: [],
        value: null,
        updatePending: false,
        disabled: false
    };

    /**
     * React: state
     */
    state = {
        value: this.props.value
    };

    /**
     * React: render
     */
    render() {
        var selectValue = this._getChoiceIndex(this.props.value);
        // console.log(selectValue)
        // this.props.value!=null && this.props.value!='' ? this.props.value : 'Select your option');

        return (
            <div className={this.props.updatePending ? 'update-pending' : ''}>
                {this.props.type=='choice-select' ? (
                    <select
                        disabled={this.props.disabled}
                        onChange={(event) => { this._onChangeSelect(event.target.value); }}
                        value={selectValue}>

                        <option disabled value='Select your option'>Select your option</option>

                        {/* TODO change value assignement */}
                        {this.props.choices.map(function(element, index) {
                            return (
                                <option
                                    key={index}
                                    value={index}>
                                    {element.label ? element.label : element.value}
                                </option>
                            );
                        })}
                    </select>
                ) : null }

                {this.props.type=='choice-radio' ? (
                    <div>
                        {this.props.choices.map(function(element, index) {
                            return (
                                <RadioButton
                                    disabled={this.props.disabled}
                                    onChange={() => this._onChangeRadioButton(element)}
                                    onClick={() => this._onClickRadioButton(element)}
                                    checked={this._isChoiceSelected(element)}
                                    label={element.label}
                                    value={element.value}
                                    key={index}
                                />
                            );
                        }.bind(this))}
                    </div>
                ) : null }

                {this.props.type=='choice-checkbox' ? (
                    <div>
                        {this.props.choices.map(function(element, index) {
                            return (
                                <Checkbox
                                    disabled={this.props.disabled}
                                    onChange={() => this._onChangeCheckbox(element)}
                                    onClick={() => this._onClickCheckbox(element)}
                                    checked={this._isChoiceSelectedCheckbox(element)}
                                    label={element.label}
                                    value={element.value}
                                    key={index}
                                />
                            );
                        }.bind(this))}
                    </div>
                ) : null }
            </div>
        );
    }

    _onChange = (value) => {
        // console.log(value)

        if(typeof this.props.onChange==='function'){
            this.props.onChange(value);
        }
    };

    _onChangeSelect = (value) => {
        // console.log(value)

        this.setState({
            selectValue: this.props.choices[value]
        });

        if(typeof this.props.onChange==='function'){
            this.props.onChange(value);
        }
    };

    /**
     * Parse choices
     */
    _parseChoices = (choices, value) => {
        var parsedValue = [];
        var valueReference = this.props.valueReference;
        if (Array.isArray(value)) {
            value.forEach((value) => {
                if (typeof value === 'object' && typeof valueReference === 'function') {
                    parsedValue.push(valueReference(value));
                } else if (typeof value === 'object' && typeof valueReference === 'string') {
                    parsedValue.push(value[valueReference]);
                } else {
                    parsedValue.push(value);
                }
            });
        } else {
            if (typeof value === 'object' && typeof valueReference === 'function') {
                parsedValue = valueReference(value);
            } else if (typeof value === 'object' && typeof valueReference === 'string') {
                parsedValue = value ? value[valueReference] : null;
            } else {
                parsedValue = value;
            }
        }

        var returnChoices = {};
        if (this.props.placeholder) {
            returnChoices['nullPlaceholder'] = {
                label: this.props.placeholder,
                selected: parsedValue == null,
                value: null
            };
        }
        Object.keys(choices).forEach((key) => {
            returnChoices[key] = {
                label: this._getChoiceLabel(choices, key),
                selected: this._isChoicePreSelected(choices, key, parsedValue),
                value: choices[key]
            };
        });

        return returnChoices;
    };

    /**
     * Is choice pre-selected?
     */
    _isChoicePreSelected = (choices, key, parsedValue) => {
        var choiceValue = null;
        var valueReference = this.props.valueReference;

        if (typeof valueReference === 'function') {
            choiceValue = valueReference(choices[key]);
        } else if (typeof valueReference === 'string') {
            choiceValue = choices[key][valueReference];
        } else {
            choiceValue = choices[key];
        }

        if (this.props.multiple && Array.isArray(parsedValue)) {
            return parsedValue.indexOf(choiceValue) > -1;
        } else {
            return parsedValue === choiceValue;
        }
    };

    /**
     * Get choice label
     */
    _getChoiceLabel = (choices, key) => {
        var choiceLabel = this.props.choiceLabel;
        if (typeof choiceLabel === 'function') {
            return choiceLabel(choices, key);
        } else if (typeof choiceLabel === 'string') {
            return choices[key][choiceLabel];
        } else if (typeof choices[key] === 'string' || typeof choices[key] === 'number') {
            return choices[key];
        } else {
            return key;
        }
    };

    /**
     * On change
     */
    _onChangeHandlerOld = (changedKey, isSelected) => {
        this.setState({
            choices: this._updateChoices(changedKey, isSelected),
            value: this._getValue()
        }, () => {
            if (typeof this.props.onChange === 'function') {
                this.props.onChange(this._getValue());
            }
        });
    };

    /**
     * Update choices
     */
    _updateChoices = (changedKey, isSelected) => {
        var choices = {};
        Object.keys(this.state.choices).forEach((key) => {
            let selected = !this.props.multiple
                ? (changedKey === key ? isSelected : false)
                : (changedKey === key ? isSelected : this.state.choices[key].selected);

            choices[key] = {
                label: this.state.choices[key].label,
                selected: selected,
                value: this.state.choices[key].value
            };
        });

        return choices;
    };

    /**
     * Select all choices
     * @param {bool} selected
     */
    _selectAllChoices = (selected) => {
        var choices = {};
        Object.keys(this.state.choices).forEach((key) => {
            choices[key] = {
                label: this.state.choices[key].label,
                selected: selected,
                value: this.state.choices[key].value
            };
        });

        this.setState({
            choices: choices,
            value: this._getValue()
        }, () => {
            if (typeof this.props.onChange === 'function') {
                this.props.onChange(this._getValue());
            }
        });
    };

    /**
     * Get value
     */
    _getValue = () => {
        let value = null;
        if (!this.props.multiple) {
            Object.keys(this.state.choices).forEach((key) => {
                if (this.state.choices[key].selected) {
                    value = this.state.choices[key].value;
                }
            });
        } else {
            value = [];
            Object.keys(this.state.choices).forEach((key) => {
                if (this.state.choices[key].selected) {
                    value.push(this.state.choices[key].value);
                }
            });
        }

        return value;
    };

    _isChoiceSelected = (choiceValue, current) => {
        var currentValue = current!=null ? current : this.state.value;

        if(choiceValue.id==currentValue || choiceValue.value==currentValue || choiceValue.label==currentValue ||
            choiceValue.id==currentValue.id || choiceValue.value==currentValue.value){
            return true;
        }

        return false;
    };

    _getChoiceIndex = (choice) => {
        var choiceSelected = false;

        if(this.state.value.constructor===Array){
            this.state.value.forEach(function(element, index) {
                var isSelected = this._isChoiceSelected(choice, element);
                // console.log(isSelected)

                if(isSelected){
                    choiceSelected = index;
                }

                // if(this.state.value.length-1===index){
                //     return choiceSelected;
                // }
            }.bind(this));

            return choiceSelected;
        }else {
            return this._isChoiceSelected(choice);
        }
    };

    _getChoiceCheckboxIndex = (choiceValue) => {
        var choiceSelected = false;

        if(this.state.value.constructor===Array){
            this.state.value.forEach(function(element, index) {
                var isSelected = this._isChoiceSelected(choiceValue, element);

                if(isSelected){
                    choiceSelected = index;
                }

                if(this.state.value.length-1===index){
                    return choiceSelected;
                }
            }.bind(this));

            return choiceSelected;
        }else {
            return this._isChoiceSelected(choiceValue);
        }
    };

    _isChoiceSelectedCheckbox = (choiceValue) => {
        return (typeof this._getChoiceCheckboxIndex(choiceValue)==='number');
    };

    _onClickRadioButton = () => {
        // if(typeof this.props.onClick==='function'){
        //     this.props.onClick();
        // }
    };

    _onChangeRadioButton = (buttonValue) => {
        this.setState({
            value: buttonValue
        });

        if(typeof this.props.onChange==='function'){
            this.props.onChange(buttonValue);
        }
    };

    _onChangeCheckbox = (buttonValue) => {
        this._toggleCheckboxValues(buttonValue);

        if(typeof this.props.onChange==='function'){
            this.props.onChange(buttonValue);
        }
    };

    _onClickCheckbox = () => {
        // if(typeof this.props.onClick==='function'){
        //     this.props.onClick();
        // }
    };

    _toggleCheckboxValues = (checkboxValue) => {
        var checkboxSelectedIndex = this._getChoiceCheckboxIndex(checkboxValue);
        var newValuesArray = this.state.value;

        if(checkboxSelectedIndex!==false){
            newValuesArray.splice(checkboxSelectedIndex, 1);
            console.log(newValuesArray);
        }else {
            newValuesArray.push(checkboxValue);
        }

        this.setState({
            value: newValuesArray
        });
    };
}
