"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import Config from '../../../lib/Config';
import Router from '../../../lib/Router';
import MediaItem from '../../../types/MediaItem';
import {OfferType} from '../../../types/Offer';
import {EntitlementClassification} from '../../../types/Entitlement';
import AppStore from '../../../stores/AppStore';
import Button from '../../ui/Button.react';
import MediaItemComponentCTAs from '../../mixins/MediaItemComponentCTAs';

/**
 * CTAButtons component
 *
 * @mixes MediaItemComponentCTAs
 */
export default class CTAButtons extends MediaItemComponentCTAs(React.Component) {
    /**
     * React: propTypes
     */
    static propTypes = {
        mediaItem: PropTypes.instanceOf(MediaItem).isRequired,
        isCTAMenuActive: PropTypes.bool,
        isPurchaseEnabled: PropTypes.bool
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        isPurchaseEnabled: true
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        var mediaItem = this.props.mediaItem;
        var isPurchaseEnabled = this.props.isPurchaseEnabled;

        return (
            <ul className={"cta-buttons" + (this.props.isCTAMenuActive ? " active" : "" )}>
                {mediaItem.entitlement && mediaItem.entitlement.entitlementClassification === EntitlementClassification.DTR ? (
                    <li className="expiration-notice">
                        <i className="ui-icon icon-rent"/>
                        <span>{mediaItem.entitlement.timeLeft}&nbsp;</span>
                        <span className="short">{AppStore.translate('label.duration_left')}</span>
                    </li>
                ) : null}

                {!mediaItem.isPurchased && isPurchaseEnabled ? mediaItem.offers.map((offer, index) => {
                    let purchaseAvailable = offer.offerType !== OfferType.POEST;
                    return (
                        <li key={index} className="mediaitem-tile-cta-btn-listitem">
                            <Button shape="square-chromeless"
                                    key={index}
                                    className={purchaseAvailable ? 'cta-purchase-est' : 'cta-pre-order'}
                                    disabled={purchaseAvailable ? mediaItem.updatePending : (mediaItem.updatePending || mediaItem.isOnWatchlist)}
                                    updatePending={purchaseAvailable ? offer.updatePending : mediaItem.watchlistPending}
                                    onClick={purchaseAvailable
                                        ? this._fastCheckoutMediaItemOffer.bind(null, offer.id) : this._preOrderMediaItem}>
                                {!purchaseAvailable && mediaItem.isOnWatchlist ? (
                                    <i className="ui-icon icon-check-circle"/>
                                ) : (
                                    <i className="ui-icon icon-buy"/>
                                )}

                                {purchaseAvailable ? offer.price.toLocaleString(Config.LOCALE, {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }) : (!mediaItem.isOnWatchlist ? AppStore.translate('button.cta.pre-order') : AppStore.translate('label.pre-ordered'))}
                            </Button>
                        </li>
                    );
                }) : null}

                {mediaItem.playbackAvailable ? (
                    <li>
                        <Button shape="square-chromeless"
                                className="cta-play"
                                onClick={this._playMediaItem}>
                            <i className="ui-icon icon-play"/>{AppStore.translate('button.cta.play')}
                        </Button>
                    </li>
                ) : null}

                {mediaItem.downloadAvailable ? (
                    <li>
                        <Button shape="square-chromeless"
                                className="cta-download"
                                onClick={this._openDownloadManagerOverlay}>
                            <i className="ui-icon icon-download"/>{AppStore.translate('button.cta.download')}
                        </Button>
                    </li>
                ) : null}

                {mediaItem.watchlistAvailable ? (
                    <li>
                        <Button shape="square-chromeless"
                                className="cta-watchlist"
                                disabled={mediaItem.updatePending}
                                updatePending={mediaItem.watchlistPending}
                                onClick={this._toggleMediaItemWatchlistStatus}>
                            {mediaItem.isOnWatchlist ? (
                                <i className="ui-icon icon-remove"/>
                            ) : (
                                <i className="ui-icon icon-wishlist"/>
                            )}
                            {AppStore.translate('button.cta.watchlist')}
                        </Button>
                    </li>
                ) : null}

                <li>
                    <Button shape="square-chromeless"
                            className="cta-details"
                            type="link"
                            linkTo={Router.MEDIA_ITEM(mediaItem)}>
                        <i className="ui-icon icon-details"/>{AppStore.translate('button.cta.details')}
                    </Button>
                </li>
            </ul>
        );
    }
}
