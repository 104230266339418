"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import Config from '../../../lib/Config';
import Router from '../../../lib/Router';
import {PageRowType} from '../../../types/PageRow';
import AuthActions from '../../../actions/AuthActions';
import UIActions from '../../../actions/UIActions';
import AuthStore from '../../../stores/AuthStore';
import AppStore from '../../../stores/AppStore';
import Button from '../../ui/Button.react';

/**
 * NavigationMobile component
 */
export default class NavigationMobile extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        toggleMobileMenu: PropTypes.func.isRequired,
        navRef: PropTypes.object
    };

    /**
     * React: state
     */
    state = {
        isSecondLevelOpen: false,
        isMovieSubmenuOpen: false,
        isTVShowsSubmenuOpen: false,
        isCollectionSubmenuOpen: false,
        secondLevelOpenType: '',
        isTransitioning: false
    };
    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: render
     */
    render() {
        return (
            <div className="nav-menu-popup-outer left-menu">
                <div className="nav-menu-popup">
                    <ul ref={this.props.navRef}
                        className={`nav-mob-first-level ${this.state.isSecondLevelOpen ? 'sec-level-open' : ''}`}>
                        <li>
                            <Link onClick={this._navigateTo.bind(null, Router.HOME)}>
                                <i className="ui-icon icon-home" />{AppStore.translate('view.navigation.label.home')}
                            </Link>
                        </li>
                        {AuthStore.isSignedIn() ? (
                            <li>
                                <Link onClick={this._navigateTo.bind(null, Router.UPDATE_ACCOUNT)}>
                                    <i className="ui-icon icon-user" />{AppStore.translate('view.navigation.label.account')}
                                </Link>
                            </li>
                        ) : null}
                        {AuthStore.isSignedIn() ? (
                            <li>
                                <Link onClick={this._navigateTo.bind(null, Router.MY_DEVICES)}>
                                    <i className="ui-icon icon-menu-devices" />{AppStore.translate('view.navigation.label.devices')}
                                </Link>
                            </li>
                        ) : null}
                        {AuthStore.isSignedIn() ? (
                            <li>
                                <Link onClick={this._navigateTo.bind(null, Router.WATCHLIST)}>
                                    <i className="ui-icon icon-wishlist" />{AppStore.translate('view.navigation.label.watchlist')}
                                </Link>
                            </li>
                        ) : null}
                        {AuthStore.isSignedIn() ? (
                            <li>
                                <Link onClick={this._navigateTo.bind(null, Router.LIBRARY)}>
                                    <i className="ui-icon icon-menu-library" />{AppStore.translate('view.navigation.label.library')}
                                </Link>
                            </li>
                        ) : null}
                        {AuthStore.isSignedIn() ? (
                            <li onClick={this._confirmSignOut}>
                                <span>
                                    <i className="ui-icon icon-menu-logout" />{AppStore.translate('view.navigation.label.sign_out')}
                                </span>
                            </li>
                        ) : (
                                <li>
                                    <Link onClick={this._navigateTo.bind(null, Router.SIGN_IN)}>
                                        <i className="ui-icon icon-menu-logout" />{AppStore.translate('view.navigation.label.sign_in')} / {AppStore.translate('view.navigation.label.sign_up')}
                                    </Link>
                                </li>
                            )}

                        {AppStore.mainNavigation.get(Config.STOREFRONT_SETTING_KEY) ? (
                            <hr />
                        ) : null}

                        <li onClick={this._navigateToSubmenu.bind(null, 'isMovieSubmenuOpen')}>
                            <span>
                                <i className="ui-icon icon-arrow-right" />{AppStore.translate('view.navigation.label.MoviePageRowOrder').toUpperCase()}
                            </span>
                        </li>
                        <li onClick={this._navigateToSubmenu.bind(null, 'isTVShowsSubmenuOpen')}>
                            <span>
                                <i className="ui-icon icon-arrow-right" />{AppStore.translate('view.navigation.label.TVPageRowOrder').toUpperCase()}
                            </span>
                        </li>
                        <li onClick={this._navigateToSubmenu.bind(null, 'isCollectionSubmenuOpen')}>
                            <span>
                                <i className="ui-icon icon-arrow-right" />{AppStore.translate('view.navigation.label.StorefrontPageRowOrder').toUpperCase()}
                            </span>
                        </li>

                        <hr />

                        <li>
                            <Link to={Router.ABOUT} target="blank">
                                {AppStore.translate('view.navigation.label.about').toUpperCase()}
                            </Link>
                        </li>

                        <li>
                            <Link to={Router.BROADBAND} target="blank">
                                {AppStore.translate('view.navigation.label.braodband').toUpperCase()}
                            </Link>
                        </li>

                        <li>
                            <Link to={Router.TERMS_AND_CONDITIONS} target="blank">
                                {AppStore.translate('view.navigation.label.terms_of_use').toUpperCase()}
                            </Link>
                        </li>

                        <li>
                            <Link to={Router.COOKIES} target="blank">
                                {AppStore.translate('view.navigation.label.cookies').toUpperCase()}
                            </Link>
                        </li>

                        <li>
                            <Link to={Router.PRIVACY} target="blank">
                                {AppStore.translate('view.navigation.label.privacy').toUpperCase()}
                            </Link>
                        </li>

                        <li>
                            <Link onClick={this._navigateTo.bind(null, Router.HELP)}>
                                {AppStore.translate('view.navigation.label.help').toUpperCase()}
                            </Link>
                        </li>

                        <li>
                            <Link onClick={this._navigateTo.bind(null, Router.CONTACT)}>
                                {AppStore.translate('view.navigation.label.contact').toUpperCase()}
                            </Link>
                        </li>
                    </ul>
                    {/* desktop navigation menu sub-menu (second level) */}

                    {AppStore.mainNavigation.toArray().map(mainNavigationSection => (
                        <ul key={mainNavigationSection.key} className={`nav-mob-second-level ${this._getClassNameForSubmenu(mainNavigationSection.key)}`}>
                            <li key={mainNavigationSection.key + 'subkey'} onClick={this._navigateToSubmenu.bind(null, 'reset')}><i
                                className="ui-icon icon-arrow-left" />{AppStore.translate('view.navigation.label.BackNavButton')}
                            </li>
                            <hr />
                            {mainNavigationSection.items.toArray().map((mainNavigationItem, index) => {
                                // collection
                                if (mainNavigationItem.type === PageRowType.COLLECTION && AppStore.collectionListByTitle.get(mainNavigationItem.identifier)) {
                                    let collection = AppStore.collectionListByTitle.get(mainNavigationItem.identifier);
                                    return (
                                        <li key={index}>
                                            <Link
                                                onClick={this._navigateTo.bind(null, Router.COLLECTIONS(collection, mainNavigationSection.classification))}>
                                                {mainNavigationItem.label.toUpperCase()}
                                            </Link>
                                        </li>
                                    );
                                }
                                // promotion [PromotionItemType.MEDIA_ITEM]
                                if (mainNavigationItem.type === PageRowType.PROMOTION) {
                                    return (
                                        <li key={index}>
                                            <Link
                                                onClick={this._navigateTo.bind(null, mainNavigationItem.identifier.indexOf('SPECIAL_OFFERS_') === -1
                                                    ? Router.PROMOTIONS(mainNavigationItem.identifier, mainNavigationSection.classification)
                                                    : Router.PROMOTION_COLLECTIONS(mainNavigationItem.identifier))}>
                                                {mainNavigationItem.label.toUpperCase()}
                                            </Link>
                                        </li>
                                    );
                                }
                                // search all
                                if (mainNavigationItem.type === PageRowType.SEARCH_ALL) {
                                    return (
                                        <li key={index}>
                                            <Link
                                                onClick={this._navigateTo.bind(null, Router.ALL_MEDIA(mainNavigationSection.classification))}>
                                                {mainNavigationItem.label.toUpperCase()}
                                            </Link>
                                        </li>
                                    );
                                }
                            })}
                            {mainNavigationSection.displayGenres ? (
                                AppStore.genreList.toArray().map((genre) => {
                                    return (
                                        <li key={genre.id}><Link
                                            onClick={this._navigateTo.bind(null, Router.GENRES(genre.id, mainNavigationSection.classification))}>
                                            {genre.name.toUpperCase()}</Link>
                                        </li>
                                    );
                                })
                            ) : null}
                        </ul>
                    ))}
                </div>
            </div>
        );
    }

    /**
     * Navigate to
     *
     * @param link
     * @private
     */
    _navigateTo = (link) => {
        this.props.toggleMobileMenu();
        this.context.router.push(link);
    };

    /**
     * Show Signout Popup
     *
     * @private
     */
    _confirmSignOut = () => {
        UIActions.showCustomOverlay((
            <div className="alert-box-size col-xs-12 col-sm-8 col-md-6">
                <h2 className="overlay-header">{AppStore.translate('view.navigation.label.sign_out_popup_title')}</h2>
                <div className="overlay-content">
                    <p className="popup-text-centered">{AppStore.translate('view.navigation.label.sign_out_popup_info_text')}</p>

                    <div className="buttons-container ">
                        <Button type="button"
                            shape="regular"
                            className="uppercase-button"
                            onClick={UIActions.closeCustomOverlay}>
                            Cancel
                        </Button>
                        <Button type="button"
                            shape="regular"
                            className="uppercase-button"
                            onClick={AuthActions.signOutUser}>
                            Sign out
                        </Button>
                    </div>
                </div>
            </div>
        ));
    };

    /**
     * navigate to submenu
     *
     * @private
     */
    _navigateToSubmenu = (fieldName) => {
        if (this.state.isTransitioning) {
            return false;
        } else {
            this.props.scrollMenuIntoView();
            const newState = {
                secondLevelOpenType: fieldName,
                [fieldName]: true,
                isSecondLevelOpen: !this.state.isSecondLevelOpen,
                isTransitioning: true
            };

            let blockTransitionTimeout = () => {
                setTimeout(() => {
                    this.setState({
                        isTransitioning: false
                    });
                }, 750);
            };

            this.setState(newState, blockTransitionTimeout);
        }
    };

    /**
     * get ClassName for submenu ul
     *
     * @private
     */
    _getClassNameForSubmenu = (submenuType) => {
        if (submenuType === 'MoviePageRowOrder' && this.state.secondLevelOpenType === 'isMovieSubmenuOpen') return 'opened';
        if (submenuType === 'TVPageRowOrder' && this.state.secondLevelOpenType === 'isTVShowsSubmenuOpen') return 'opened';
        if (submenuType === 'StorefrontPageRowOrder' && this.state.secondLevelOpenType === 'isCollectionSubmenuOpen') return 'opened';
        return '';
    };
}
