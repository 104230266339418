"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import InputUIs from '../mixins/InputUIs';

/**
 * Checkbox component
 */
export default class PaymentCardInput extends InputUIs(React.Component) {
    /**
     * React: propTypes
     */
    static propTypes = {
        type: PropTypes.oneOf(['text', 'email', 'password', 'number']),
        value: PropTypes.string,
        label: PropTypes.node,
        disabled: PropTypes.bool,
        inlineLabel: PropTypes.bool,
        inline: PropTypes.bool,
        useStatusIcon: PropTypes.bool,
        statusIcon: PropTypes.string,
        onChange: PropTypes.func,
        onClick: PropTypes.func,
        error: PropTypes.string,
        labelStyle: PropTypes.object,
        inputStyle: PropTypes.object,
        inputClassName: PropTypes.string,
        labelClassName: PropTypes.string,
        maxLength: PropTypes.number
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        type: 'number',
        value: '',
        disabled: false,
        inlineLabel: true,
        inline: false,
        useStatusIcon: false,
        maxLength: null
    };

    /**
     * React: state
     */
    state = {
        value: this.props.value,
        value_1: '',
        value_2: '',
        value_3: '',
        value_4: ''
    };

    /**
     * React: UNSAFE_componentWillReceiveProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value
        });
    }

    /**
     * React: render
     */
    render() {
        const inputFieldMarker = {
            FIELD_1: 'value_1',
            FIELD_2: 'value_2',
            FIELD_3: 'value_3',
            FIELD_4: 'value_4'
        };
        const className = 'ui-text-input ' +
            (this.props.inputClassName ? this.props.inputClassName : '') +
            (this.props.useStatusIcon ? 'use-status-icon ' : '') +
            (this.props.statusIcon == 'success' ? 'status-success ' : '') +
            (this.props.statusIcon == 'error' ? 'status-error ' : '');
        const error = this.props.error ? (<div className="error-message">{this.props.error}</div>) : '';
        const rootClassName = "ui-input" +
            (this.props.inlineLabel ? ' inline-label' : '') +
            (this.props.inline ? ' inline' : '') +
            (this.props.error ? ' show-error' : '');
        return (
            <div className={rootClassName}>
                {(this.props.label && <label className={this.props.labelClassName} style={this.props.labelStyle}>{this.props.label}</label>)}
                <div className={`pay-card-input-wrapper ${className}`}>
                    <input type={this.props.type}
                           value={this.state.value_1}
                           placeholder="...."
                           ref={(input) => { this.input_1 = input; }}
                           onChange={this._onChange.bind(null, inputFieldMarker.FIELD_1)}
                           onClick={this._onClick}
                           onKeyPress={this._onKeyPress}
                           disabled={this.props.disabled}
                           style={this.props.inputStyle}
                           maxLength="4"/>
                    <input type={this.props.type}
                           value={this.state.value_2}
                           placeholder="...."
                           ref={(input) => { this.input_2 = input; }}
                           onChange={this._onChange.bind(null, inputFieldMarker.FIELD_2)}
                           onClick={this._onClick}
                           onKeyPress={this._onKeyPress}
                           disabled={this.props.disabled}
                           style={this.props.inputStyle}
                           maxLength="4"/>
                    <input type={this.props.type}
                           value={this.state.value_3}
                           placeholder="...."
                           ref={(input) => { this.input_3 = input; }}
                           onChange={this._onChange.bind(null, inputFieldMarker.FIELD_3)}
                           onClick={this._onClick}
                           onKeyPress={this._onKeyPress}
                           disabled={this.props.disabled}
                           style={this.props.inputStyle}
                           maxLength="4"/>
                    <input type={this.props.type}
                           value={this.state.value_4}
                           placeholder="...."
                           ref={(input) => { this.input_4 = input; }}
                           onChange={this._onChange.bind(null, inputFieldMarker.FIELD_4)}
                           onClick={this._onClick}
                           onKeyPress={this._onKeyPress}
                           disabled={this.props.disabled}
                           style={this.props.inputStyle}
                           maxLength="4"/>
                </div>
                {error}
            </div>
        );
    }

    /**
     * Focus input
     */
    focusTextInput(currentField, shouldFocusPrevious) {
        if(shouldFocusPrevious) {
            // Explicitly focus the text input using the raw DOM API
            switch (currentField) {
                case 'value_4' :
                    this.input_3.focus();
                    break;
                case 'value_3' :
                    this.input_2.focus();
                    break;
                case 'value_2' :
                    this.input_1.focus();
                    break;
            }
        } else {
            // Explicitly focus the text input using the raw DOM API
            switch (currentField) {
                case 'value_1' :
                    this.input_2.focus();
                    break;
                case 'value_2' :
                    this.input_3.focus();
                    break;
                case 'value_3' :
                    this.input_4.focus();
                    break;
            }
        }
    }

    /**
     * On change
     */
    _onChange = (fieldname, event) => {
        event.persist();

        if(event.target.value.length <= 4) {
            if(event.target.value.length < this.state[fieldname].length && event.target.value.length === 0) {
                this.focusTextInput(fieldname, true);
            } else if(event.target.value.length > 3) {
                this.focusTextInput(fieldname);
            }

            this.setState({
                [fieldname]: event.target.value
            }, () => {
                if (typeof this.props.onChange === 'function') {
                    this.props.onChange(`${this.state.value_1}${this.state.value_2}${this.state.value_3}${this.state.value_4}`);
                }
            });
        }
    };

    /**
     * On key press check if key is number
     */
    _onKeyPress = event => {
        const regExNumbersOnly = /^\d+$/;
        // Forbid "e" and "E" characters in input
        // if(event.key === "E" || event.key === "e") {
        //     event.preventDefault();
        // }

        //Firefox and Edge fix, to prevent letters in input fields
        if(!regExNumbersOnly.test(event.key)) {
            event.preventDefault();
        }
    };

    /**
     * On click
     */
    _onClick = () => {
        if (typeof this.props.onClick === 'function') {
            this.props.onClick(this.state.value);
        }
    };
}
