"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import AppStore from '../stores/AppStore';
import MediaActions from '../actions/MediaActions';
import MediaStore from '../stores/MediaStore';
import Filter from '../lib/Filter';
import FilterBar from './elements/FilterBar.react';
import MediaGallery from './elements/MediaGallery.react';

/**
 * CastPage component
 */
export default class CastPage extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        params: PropTypes.object,
        location: PropTypes.object
    };

    /**
     * React: state
     */
    state = {
        activeFilters: AppStore.activeFilters,
        mediaBucket: null
    };

    /**
     * Bucket keys
     */
    _mediaBucketKey = null;

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        if (this.props.params && this.props.params.castFullName) {
            this._mediaBucketKey = MediaActions.getCastMedia({
                castFullName: this.props.params.castFullName
            });
        }
    }

    /**
     * React: componentDidMount
     */
    componentDidMount() {
        // add store listeners
        AppStore.addUpdateListener(this._setActiveFilters);
        MediaStore.addUpdateListener(this._setMediaBucket);
    }

    /**
     * React: UNSAFE_componentWillReceiveProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.params && nextProps.params.castFullName && nextProps.params.castFullName !== this.props.params.castFullName) {
            MediaStore.destroyMediaBucket(this._mediaBucketKey);
            this._mediaBucketKey = null;
            this.setState({
                mediaBucket: null
            }, () => {
                this._mediaBucketKey = MediaActions.getCastMedia({
                    castFullName: nextProps.params.castFullName
                });
            });
        }
    }

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: componentWillUnmount
     */
    componentWillUnmount() {
        AppStore.removeUpdateListener(this._setActiveFilters);
        MediaStore.removeUpdateListener(this._setMediaBucket);
        MediaStore.destroyMediaBucket(this._mediaBucketKey);
    }

    /**
     * React: render
     */
    render() {
        var mediaBucket = this.state.mediaBucket;
        var media = mediaBucket ? mediaBucket.media() : null;
        var filteredMedia = media ? Filter.media(media, this.state.activeFilters) : null;

        return (
            <main>
                <FilterBar/>
                {media && media.size === 0 ? (
                    <div className="no-results">
                        {AppStore.translate('view.genre_page.message.no_media_for_name', {castFullName: this.props.params.castFullName})}
                    </div>
                ) : (filteredMedia && filteredMedia.size === 0 ? (
                    <div className="no-results">{AppStore.translate('message.no_media_for_filter_selection')}</div>
                ) : (
                    <MediaGallery title={this.props.params.castFullName}
                                  media={filteredMedia}/>
                ))}
            </main>
        );
    }

    /**
     * Set active filters
     */
    _setActiveFilters = () => {
        this.setState({
            activeFilters: AppStore.activeFilters
        });
    };

    /**
     * Set mediaBucket
     */
    _setMediaBucket = () => {
        this.setState({
            mediaBucket: MediaStore.getMediaBucket(this._mediaBucketKey)
        });
    };
}
