"use strict";

import Immutable from 'immutable';
import PaymentProvidersCustomerIds from './PaymentProvidersCustomerIds';

/**
 * UserProfileDTO definition
 *
 * @typedef {Object} UserProfileDTO
 * @property {?string} UserName
 * @property {?string} Title
 * @property {?string} GivenName - Given/first name
 * @property {?string} LastName - Last name
 * @property {?string} Password - User password
 * @property {?string} Dob - Date of birth
 * @property {?string} Address1 - Address line 1
 * @property {?string} Address2 - Address line 2
 * @property {?string} City - City
 * @property {?string} Country - Country
 * @property {?string} PostCode - Post/zip code
 * @property {?string} DayTimeTelephone - Day phone number
 * @property {?string} MobileTelephone - Mobile phone number
 * @property {?string} FirmName
 * @property {?string} Gender - User gender
 * @property {?number} MaxFilmPurchases
 * @property {?boolean} IsApproved
 * @property {?boolean} IsLockedOut
 * @property {?boolean} AcceptedTandCs
 * @property {?PaymentProvidersCustomerIdsDTO} PaymentProvidersCustomerIds
 * @property {?string} EmailAddress - User email address used as user name
 */

/**
 * UserProfile definition
 *
 * @typedef {Immutable.Record} UserProfile
 * @property {?string} username
 * @property {?string} email
 * @property {?string} title
 * @property {?string} firstName
 * @property {?string} lastName
 * @property {?string} password
 * @property {?string} dateOfBirth
 * @property {?string} city
 * @property {?string} country
 * @property {?string} addressLine1
 * @property {?string} addressLine2
 * @property {?string} postCode
 * @property {?string} telephone
 * @property {?string} mobile
 * @property {?string} company
 * @property {?string} gender
 * @property {?number} maxFilmPurchases
 * @property {?boolean} isApproved
 * @property {?boolean} isLockedOut
 * @property {?boolean} termsAndConditionsAccepted
 * @property {?PaymentProvidersCustomerIdsDTO} paymentProviderCustomerIds
 */

/**
 * UserProfile record
 *
 * @type {UserProfile}
 */
class UserProfile extends Immutable.Record({
    username: null,
    email: null,
    title: null,
    firstName: null,
    lastName: null,
    password: null,
    dateOfBirth: null,
    city: null,
    country: null,
    addressLine1: null,
    addressLine2: null,
    postCode: null,
    telephone: null,
    mobile: null,
    company: null,
    gender: null,
    maxFilmPurchases: null,
    isApproved: null,
    isLockedOut: null,
    termsAndConditionsAccepted: null,
    paymentProviderCustomerIds: null
}) {
    /**
     * Set values from data transfer object
     *
     * @param {UserProfileDTO} dataTransferObject
     * @return {UserProfile}
     */
    fromDTO(dataTransferObject) {
        return new UserProfile({
            ...this.toJS(),
            username: typeof dataTransferObject.UserName !== 'undefined' ? dataTransferObject.UserName : this.username,
            email: typeof dataTransferObject.EmailAddress !== 'undefined' ? dataTransferObject.EmailAddress : this.email,
            title: typeof dataTransferObject.Title !== 'undefined' ? dataTransferObject.Title : this.title,
            firstName: typeof dataTransferObject.GivenName !== 'undefined' ? dataTransferObject.GivenName : this.firstName,
            lastName: typeof dataTransferObject.LastName !== 'undefined' ? dataTransferObject.LastName : this.lastName,
            password: typeof dataTransferObject.Password !== 'undefined' ? dataTransferObject.Password : this.password,
            dateOfBirth: typeof dataTransferObject.Dob !== 'undefined' ? dataTransferObject.Dob : this.dateOfBirth,
            city: typeof dataTransferObject.City !== 'undefined' ? dataTransferObject.City : this.city,
            country: typeof dataTransferObject.Country !== 'undefined' ? dataTransferObject.Country : this.country,
            addressLine1: typeof dataTransferObject.Address1 !== 'undefined' ? dataTransferObject.Address1 : this.addressLine1,
            addressLine2: typeof dataTransferObject.Address2 !== 'undefined' ? dataTransferObject.Address2 : this.addressLine2,
            postCode: typeof dataTransferObject.PostCode !== 'undefined' ? dataTransferObject.PostCode : this.postCode,
            telephone: typeof dataTransferObject.DayTimeTelephone !== 'undefined' ? dataTransferObject.DayTimeTelephone : this.telephone,
            mobile: typeof dataTransferObject.MobileTelephone !== 'undefined' ? dataTransferObject.MobileTelephone : this.mobile,
            company: typeof dataTransferObject.FirmName !== 'undefined' ? dataTransferObject.FirmName : this.company,
            gender: typeof dataTransferObject.Gender !== 'undefined' ? dataTransferObject.Gender : this.gender,
            maxFilmPurchases: typeof dataTransferObject.MaxFilmPurchases !== 'undefined' ? dataTransferObject.MaxFilmPurchases : this.maxFilmPurchases,
            isApproved: typeof dataTransferObject.IsApproved !== 'undefined' ? dataTransferObject.IsApproved : this.isApproved,
            isLockedOut: typeof dataTransferObject.IsLockedOut !== 'undefined' ? dataTransferObject.IsLockedOut : this.isLockedOut,
            termsAndConditionsAccepted: typeof dataTransferObject.AcceptedTandCs !== 'undefined' ? dataTransferObject.AcceptedTandCs : this.termsAndConditionsAccepted,
            paymentProviderCustomerIds: typeof dataTransferObject.PaymentProvidersCustomerIds !== 'undefined'
                ? new PaymentProvidersCustomerIds().fromDTO(dataTransferObject.PaymentProvidersCustomerIds) : this.paymentProviderCustomerIds
        });
    }
}

export default UserProfile;
