"use strict";

import React from 'react';
import AppStore from '../stores/AppStore';

/**
 * PageNotFound component
 */
export default class PageNotFound extends React.Component {
    /**
     * React: render
     */
    render() {
        return (
            <main className="not-found-backdrop">
                <div className="container">
                    <div className="row">
                        <div className="404-container col-xs-12">
                            <h1>{AppStore.translate('view.page_not_found.title')}</h1>
                            <h3>{AppStore.translate('view.page_not_found.subtitle')}</h3>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
