"use strict";

import 'moment-range';
import * as Request from './Request';
import ApiEndpoints from './ApiEndpoints';

/**
 * Get entitlement
 *
 * @param {Object} params
 * @return {*}
 */
export function getEntitlement(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.GET_ENTITLEMENT(params.mediaItemId),
        method: 'GET',
        qs: {
            v: 3,
            includeParentAndChildren: typeof params.includeParentAndChildren !== 'undefined' ? params.includeParentAndChildren : true
        }
    })
        .then(entitlementDTO => _preProcessEntitlement(entitlementDTO));
}

/**
 * Get current entitlements
 *
 * @param {Object} params
 * @return {*}
 */
export function getCurrentEntitlements(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.GET_CURRENT_ENTITLEMENTS,
        method: 'GET',
        qs: {
            v: 3,
            includeMediaItem: typeof params.includeMediaItem !== 'undefined' ? params.includeMediaItem : false,
            includeMediaAssets: typeof params.includeMediaAssets !== 'undefined' ? params.includeMediaAssets : false,
            includeParentAndChild: typeof params.includeParentAndChildren !== 'undefined' ? params.includeParentAndChildren : true,
            take: params.limit || 5000,
            skip: params.offset || 0
        }
    })
        .then(entitlementDTOCollection => entitlementDTOCollection.reduce((newEntitlementDTOCollection, entitlementDTO) => ([
            ...newEntitlementDTOCollection,
            entitlementDTO,
            ...(entitlementDTO.Children || [])
        ]), []))
        .then(entitlementDTOCollection => entitlementDTOCollection.map(_preProcessEntitlement))
        .then(entitlementDTOCollection => entitlementDTOCollection.sort((firstEntitlementDTO, secondEntitlementDTO) => {
            if (firstEntitlementDTO.PurchaseOrderId < secondEntitlementDTO.PurchaseOrderId) {
                return 1;
            } else if (firstEntitlementDTO.PurchaseOrderId > secondEntitlementDTO.PurchaseOrderId) {
                return -1;
            } else {
                return 0;
            }
        }))
        ;
}

/**
 * Get history entitlements
 *
 * @param {Object} params
 * @return {*}
 */
export function getHistoryEntitlements(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.GET_HISTORY_ENTITLEMENTS,
        method: 'GET',
        qs: {
            includeMediaItem: typeof params.includeMediaItem !== 'undefined' ? params.includeMediaItem : false,
            take: params.limit || 50,
            skip: params.offset || 0
        }
    }).then(entitlementDTOCollection => entitlementDTOCollection.map(_preProcessEntitlement));
}

/**
 * Get recently viewed items
 *
 * @param {Object} params
 * @return {*}
 */
export function getRecentlyViewedItems(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.GET_RECENTLY_VIEWED_ITEMS,
        method: 'GET',
        qs: {
            includeMediaItem: typeof params.includeMediaItem !== 'undefined' ? params.includeMediaItem : false,
            take: params.limit || 50,
            skip: params.offset || 0
        }
    })
        .then(recentlyViewedItemDTOCollection => recentlyViewedItemDTOCollection.map(_preProcessRecentlyViewedItem));
}

/**
 * Get Harmonic Movie license
 *
 * @param {Object} params
 * @return {*}
 */
export function getAMSLicense(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.GET_AMS_LICENSE,
        method: 'GET',
        qs: {
            v: 3,
            ipAddress: params.ipAddress,
            offerId: params.offerId,
            deviceId: params.deviceId,
            sessionId: params.sessionId,
            persistentLicenseRequested: typeof params.persistentLicenseRequested !== 'undefined'
                ? params.persistentLicenseRequested : false,
            deviceType: params.deviceType,
            keyId: params.keyId
        }
    });
}

/**
 * Get Trailer license
 *
 * @param {Object} params
 * @return {*}
 */
export function getTrailerLicense(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.GET_TRAILER_LICENSE,
        method: 'GET',
        qs: {
            offerId: params.offerId           
        }
    });
}

/**
 * Register device
 * @param {Object} params
 * @param {String} params.deviceTypeDescription
 * @param {String} params.deviceName
 * @param {String} params.deviceId
 * @return {*}
 */
export function registerDevice(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.REGISTER_DEVICE,
        method: 'POST',
        qs: {
            v: 3
        },
        body: {
            DeviceTypeDesciption: params.deviceTypeDescription,
            DeviceName: params.deviceName,
            DeviceGUID: params.deviceId
        }
    });
}
/**
 * Get registered devices
 *
 * @param {Object} params
 * @param {boolean} params.includeInactiveDevices
 * @return {*}
 */
export function getRegisteredDevices(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.GET_REGISTERED_DEVICES,
        method: 'GET',
        qs: {
            v: 3,
            ...(typeof params.includeInactiveDevices !== 'undefined'
                ? {includeInactiveDevices: params.includeInactiveDevices} : {})
        }
    });
}

/**
 * Rename registered devices
 *
 * @param {Object} params
 * @param {String} params.newName
 * @return {*}
 */
export function renameRegisteredDevice(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.CHANGE_REGISTERED_DEVICE_NAME(params.registeredDeviceId),
        method: 'PUT',
        qs: {
            newName: params.newName,
            v: 3
        }
    });
}

/**
 * Remove registered devices
 *
 * @param {Object} params
 * @return {*}
 */
export function removeRegisteredDevice(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.REMOVE_REGISTERED_DEVICE(params.registeredDeviceId),
        method: 'PUT',
        qs: {
            v: 3
        }
    });
}

/**
 * Validate stb Link code
 *
 * @param {Object} params
 * @return {*}
 */
export function validateStbLinkCode(params = {}) {
    return Request.getApiRequestPromise({
        url: ApiEndpoints.VISION360_API_URL + `validateLink`,
        method: 'GET',
        qs: {
            code: params.stbCode
        },
        headers: {
            'Authorization': 'Bearer ' + ApiEndpoints.VISION360_AUTH_KEY
        }
    });
}
/**
 * Consume stb link code
 * @param {Object} params
 * @param {String} params.consumeStbLinkCode
 * @return {*}
 */
export function consumeStbLinkCode(params = {}) {
    return Request.getApiRequestPromise({
        url: ApiEndpoints.VISION360_API_URL + `consumeLink`,
        method: 'GET',
        qs: {
            code: params.consumeStbLinkCode
        },
        headers: {
            'Authorization': 'Bearer ' + ApiEndpoints.VISION360_AUTH_KEY
        }
    });
}

/**
 * confirm stb registration
 * @param {Object} params
 * @param {String} params.deviceId
 * @param {Boolean} params.isCardAdded
 * @param {Boolean} params.registered
 * @param {String} params.updatedDeviceID
 * @return {*}
 */
export function confirmStbRegistration(params = {}) {
    return Request.getApiRequestPromise({
        url: ApiEndpoints.VISION360_API_URL + `confirmRegistration`,
        method: 'GET',
        qs: {
            device: params.device,
            isCardAdded: params.isCardAdded,
            registered: params.registered,
            updatedDeviceID: params.updatedDeviceID
        },
        headers: {
            'Authorization': 'Bearer ' + ApiEndpoints.VISION360_AUTH_KEY
        }
    });
}
/**
 * Create playback log
 *
 * @param {Object} params
 * @return {*}
 */
export function createPlaybackLog(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.CREATE_PLAYBACK_LOG,
        method: 'POST',
        body: {
            SupplierReference: params.mediaItemId,
            PlayPosition: params.currentTime,  // "00:00:00"
            SessionId: params.sessionId
        }
    });
}

/**
 * Pre-process watchlistItemDTO
 * - removes MediaItemMidiDTO property
 *
 * @param {RecentlyViewedItemDTO} recentlyViewedItemDTO
 * @return {RecentlyViewedItemDTO}
 */
function _preProcessRecentlyViewedItem(recentlyViewedItemDTO) {
    delete recentlyViewedItemDTO.MediaItemMidiDTO;

    return recentlyViewedItemDTO;
}

/**
 * Pre-process entitlementDTO
 * - renames property SupplierRef into SupplierReference
 * - removes MediaItem property
 *
 * @param {EntitlementDTO} entitlementDTO
 * @return {EntitlementDTO}
 */
function _preProcessEntitlement(entitlementDTO) {
    entitlementDTO.SupplierReference = entitlementDTO.SupplierRef;
    delete entitlementDTO.SupplierRef;
    delete entitlementDTO.MediaItem;

    return entitlementDTO;
}
