"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import Router from '../../../lib/Router';
import MediaItem from '../../../types/MediaItem';
import AppStore from '../../../stores/AppStore';
import Button from '../../ui/Button.react';
import MediaItemComponentCTAs from '../../mixins/MediaItemComponentCTAs';

/**
 * CTAButtonsBottom component
 *
 * @mixes MediaItemComponentCTAs
 */
export default class CTAButtonsBottom extends MediaItemComponentCTAs(React.Component) {
    /**
     * React: propTypes
     */
    static propTypes = {
        mediaItem: PropTypes.instanceOf(MediaItem).isRequired
    };

    /**
     * React: contextTypes
     */
    static contextTypes = {
        router: PropTypes.object
    };

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        var mediaItem = this.props.mediaItem;

        return (
            <div className="bottom-buttons-container">
                {mediaItem.trailerUrl ? (
                    <Button shape="square-chromeless"
                            className="cta-trailer"
                            onClick={this._playMediaItemTrailer}>
                        <i className="ui-icon icon-play"/>{AppStore.translate('button.cta.trailer')}
                    </Button>
                ) : null}

                {mediaItem.watchlistAvailable ? (
                    <Button shape="square-chromeless"
                            className="cta-watchlist"
                            disabled={mediaItem.updatePending}
                            updatePending={mediaItem.watchlistPending}
                            onClick={this._toggleMediaItemWatchlistStatus}>
                        {mediaItem.isOnWatchlist
                            ? (<i className="ui-icon icon-remove"/>)
                            : (<i className="ui-icon icon-add"/>)}
                        {AppStore.translate('button.cta.watchlist')}
                    </Button>
                ) : null}

                <Button shape="square-chromeless"
                        className="cta-details"
                        type="link"
                        linkTo={Router.MEDIA_ITEM(mediaItem)}>
                    <i className="ui-icon icon-details"/>{AppStore.translate('button.cta.details')}
                </Button>
            </div>
        );
    }
}
