"use strict";

import Promise from 'bluebird';
import * as Request from './Request';
import ApiEndpoints from './ApiEndpoints';
import * as WatchlistApi from './WatchlistApi';
import * as EntitlementApi from './EntitlementApi';
import {MediaItemClassification} from '../../types/MediaItem';
import Config from '../Config';
import Filter from '../Filter';
import * as MediaItemGraph from './MediaItemGraph';

/**
 * Search media
 *
 * @param {Object} params
 * @return {*}
 */
export function searchMedia(params = {}) {
    let filterQuerySearchParams = Filter.getSearchQueryParams();
    return Request.getRequestPromise({
        url: ApiEndpoints.SEARCH_MEDIA,
        method: 'POST',
        body: ['all', 'movie', 'series'].indexOf(params.query) !== -1
            ? {
                ...filterQuerySearchParams,
                ...(params.query !== 'all'
                    ? {
                        FilterClassifications: params.query.split(',').map(classification => MediaItemClassification[classification.toUpperCase()])
                    } : {}
                ),
                Limit: params.limit || Config.SEARCH_RESULTS_LIMIT,
                Offset: params.offset || 0,
                Sort: [{"SortTitle": "asc"}]
            } : {
                ...filterQuerySearchParams,
                Query: params.query.toLowerCase(),
                Limit: params.limit || Config.SEARCH_RESULTS_LIMIT,
                Offset: params.offset || 0
            }
    }).then((body) => {
        return ({resultTotalCount: body.Count, mediaItemDTOCollection: body.Data.map(_preProcessMediaItem)});
    });
}

/**
 * Get media
 *
 * @param {Object} params
 * @param {Array<string>} params.mediaItemIds
 * @param {boolean} params.includeInactiveMediaItems
 * @param {boolean} params.skipPreProcessing
 * @return {*}
 */
export function getMedia(params = {}) {
    if (!params.mediaItemIds || params.mediaItemIds.length === 0) return Promise.resolve([]);

    const mediaItemIdChunks = params.mediaItemIds.reduce((mediaItemIdChunks, mediaItemId) => {
        let mediaItemIdChunk = mediaItemIdChunks.pop();

        if (mediaItemIdChunk.length > 50) {
            mediaItemIdChunks.push(mediaItemIdChunk);
            mediaItemIdChunk = [];
        }

        mediaItemIdChunk.push(mediaItemId);
        mediaItemIdChunks.push(mediaItemIdChunk);

        return mediaItemIdChunks;
    }, [[]]);

    return Promise.map(
        mediaItemIdChunks,
        (mediaItemIds) => {
            return Request.getRequestPromise({
                url: ApiEndpoints.GET_MEDIA,
                method: 'GET',
                qs: {
                    References: mediaItemIds.join(','),
                    ...(typeof params.includeInactiveMediaItems !== 'undefined'
                        ? {IncludeInactiveMediaItems: params.includeInactiveMediaItems} : {})
                }
            }).then((body) => {
                return body.Data;
            }).catch(() => []);
        },
        {concurrency: 5}
    ).then(resultingMediaItemDTOCollections => {
        const mediaItemDTOCollection = resultingMediaItemDTOCollections
            .reduce((mediaItemDTOCollection, resultingMediaItemDTOCollection) => [
                ...mediaItemDTOCollection,
                ...resultingMediaItemDTOCollection
            ], []);

        return params.skipPreProcessing ? mediaItemDTOCollection : mediaItemDTOCollection.map(_preProcessMediaItem);
    });
}

/**
 * Get media item
 *
 * @param {Object} params
 * @param {string} params.mediaItemId
 * @return {*}
 */
export function getMediaItem(params = {}) {
    return Request.getRequestPromise({
        url: ApiEndpoints.GET_MEDIA_ITEM(params.mediaItemId),
        method: 'GET',
        qs: {
            IncludeInactiveMediaItems: true
        }
    }).then((body) => {
        return body.Data;
    }).then((mediaItemDTO) => {
        return _preProcessMediaItem(mediaItemDTO);
    });
}

/**
 * Get media item recommendations
 *
 * @param {Object} params
 * @param {number} params.mediaItemId
 * @return {*}
 */
export function getMediaItemRecommendations(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.GET_MEDIA_ITEM_RECOMMENDATIONS(params.mediaItemId),
        method: 'GET',
        qs: {
            filterLowerOrEqualCertificate: true
        }
    }).then((body) => {
        return body.Data;
    }).then((mediaItemDTOCollection) => {
        return mediaItemDTOCollection.map(_preProcessMediaItem);
    }).then((mediaItemDTOCollection) => {
        return Promise.map(mediaItemDTOCollection, MediaItemGraph.getMediaItemRootParent);
    });
}

/**
 * Get collection media
 *
 * @param {Object} params
 * @param {number} params.collectionTitle
 * @return {*}
 */
export function getCollectionMedia(params = {}) {
    return Request.getRequestPromise({
        url: ApiEndpoints.GET_COLLECTION_MEDIA,
        method: 'GET',
        qs: {
            v: 3,
            collectionName: params.collectionTitle
        }
    }).then((mediaItemDTOCollection) => {
        let mediaItemIds = mediaItemDTOCollection.map((mediaItemDTO) => {
            return mediaItemDTO.SupplierReference;
        });

        // skipPreProcessing: false will cause children not to be loaded when loading collection (double pre-preprocessing)
        // but page loading will be much much faster. Avoid loading children if possible in design (don't rely on data from children)
        return mediaItemIds.length > 0 ? getMedia({
            mediaItemIds: mediaItemIds,
            skipPreProcessing: typeof params.skipPreProcessing !== 'undefined' ? params.skipPreProcessing : false
        }) : [];
    }).then((mediaItemDTOCollection) => {
        return mediaItemDTOCollection.map(_preProcessMediaItem);
    });
}

/**
 * Get genre media
 *
 * @param {Object} params
 * @param {number} params.genreId
 * @param {string} params.classification
 * @param {number} params.genreId
 * @param {number} params.page
 * @return {*}
 */
export function getGenreMedia(params = {}) {
    return Request.getRequestPromise({
        url: ApiEndpoints.GET_GENRE_MEDIA_FILTERED,
        method: 'GET',
        qs: {
            genreName: params.genreId,
            pageIndex: params.page,
            pageSize: Config.STOREFRONT_GENRE_PAGE_ROW_MEDIA_LIMIT,
            classifications: _getClassification(params.classification),
            v: 3
        }
    })
    .then((body) => {
        return ({resultTotalCount: body.TotalMediaItemsCount, mediaItemDTOCollection: body.MediaItems.map(_preProcessMediaItem)});
    });
}

/**
 * Get cast media
 *
 * @param {Object} params
 * @param {number} params.castFullName
 * @return {*}
 */
export function getCastMedia(params = {}) {
    return Request.getRequestPromise({
        url: ApiEndpoints.SEARCH_MEDIA,
        method: 'POST',
        body: {
            Query: params.castFullName.toLowerCase(),
            Limit: 100,
            Offset: 0
        }
    }).then((body) => {
        return body.Data;
    }).then((mediaItemDTOCollection) => {
        return mediaItemDTOCollection.map(_preProcessMediaItem);
    });
}

/**
 * Get watchlist media
 *
 * @param {Object} params
 * @return {*}
 */
export function getWatchlistMedia(params = {}) {
    return WatchlistApi.getWatchlist(params)
        .then((watchlistItemDTOCollection) => {
            let mediaItemIds = watchlistItemDTOCollection.map((watchlistItemDTO) => {
                return watchlistItemDTO.SupplierReference;
            });

            return Promise.resolve(mediaItemIds.length > 0 ? getMedia({mediaItemIds: mediaItemIds}) : [])
                .then((mediaItemDTOCollection) => {
                    return Promise.map(mediaItemDTOCollection, MediaItemGraph.getMediaItemRootParent)
                        .then(mediaItemDTOCollection => ({watchlistItemDTOCollection, mediaItemDTOCollection}));
                });
        });
}

/**
 * Get recently viewed media
 *
 * @param {Object} params
 * @return {*}
 */
export function getRecentlyViewedMedia(params = {}) {
    return EntitlementApi.getRecentlyViewedItems(params)
        .then((recentlyViewedItemDTOCollection) => {
            let mediaItemIds = recentlyViewedItemDTOCollection.map((recentlyViewedItemDTO) => {
                return recentlyViewedItemDTO.SupplierReference;
            });

            return Promise.resolve(mediaItemIds.length > 0 ? getMedia({mediaItemIds: mediaItemIds}) : [])
                .then(mediaItemDTOCollection => ({recentlyViewedItemDTOCollection, mediaItemDTOCollection}));
        });
}

/**
 * Get library media
 *
 * @param {Object} params
 * @return {*}
 */
export function getLibraryMedia(params = {}) {
    return EntitlementApi.getCurrentEntitlements(params)
        .then((entitlementDTOCollection) => {
            let mediaItemIds = entitlementDTOCollection.map((entitlementDTO) => {
                return entitlementDTO.SupplierReference;
            });

            return Promise.resolve(mediaItemIds.length > 0
                ? getMedia({
                    mediaItemIds: mediaItemIds,
                    includeInactiveMediaItems: true
                }) : [])
                .then((mediaItemDTOCollection) => {
                    return Promise.map(mediaItemDTOCollection, MediaItemGraph.getMediaItemSeasonParent)
                        .then(mediaItemDTOCollection => ({entitlementDTOCollection, mediaItemDTOCollection}));
                });
        });
}

/**
 * Rate media item
 *
 * @param {Object} params
 * @param {number} params.mediaItemId
 * @param {number} params.userRating
 * @return {*}
 */
export function rateMediaItem(params = {}) {
    return Request.getAuthRequestPromise({
        url: ApiEndpoints.RATE_MEDIA_ITEM(params.mediaItemId),
        method: 'PUT',
        body: {
            Rating: params.userRating
        }
    });
}

/**
 * Pre-process mediaItemDTO
 * - normalizes and removes Parents property
 * - normalizes and removes Children property
 *
 * @param {MediaItemDTO} mediaItemDTO
 * @return {MediaItemDTO}
 */
function _preProcessMediaItem(mediaItemDTO) {
    // normalize and remove Parents property
    let mediaItemDTOParent = mediaItemDTO.Parent;
    mediaItemDTO.ParentId = mediaItemDTOParent ? mediaItemDTOParent.SupplierReference : null;

    // normalize and remove Children property
    mediaItemDTO.ChildrenIds = mediaItemDTO.Children ? mediaItemDTO.Children
        .map((mediaItemDTOChild) => {
            return mediaItemDTOChild.SupplierReference;
        }) : [];
    delete mediaItemDTO.Children;

    return mediaItemDTO;
}

/**
 * Get classification params
 * - returns classification as string with comma separated values to include multiple classifications per request
 *
 * @param {string} classification
 * @return string
 */
function _getClassification(classification) {
    switch (classification) {
        case 'series':
            return 'Brand,TVBoxSet';

        case 'movie':
            return 'Movie,MovieBoxSet';

        default:
            return classification;
    }
}
