"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import MediaQuery from '../../utils/MediaQuery';
import Collection from '../../types/Collection';
import AppStore from '../../stores/AppStore';
import Spinner from '../ui/Spinner2.react';
import PromotionTag from '../ui/PromotionTag.react';
import TileImage from './collection-item-tile/TileImage.react';

/**
 * CollectionTile component
 *
 * @mixes MediaItemComponentCTAs
 */
export default class CollectionTile extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        collection: PropTypes.instanceOf(Collection),
        tileType: PropTypes.oneOf(['poster', 'poster-bubble', 'backdrop', 'backdrop-progress-bar', 'backdrop-detailed']),
        collapsible: PropTypes.bool,
        useImageLoadingIndicator: PropTypes.bool
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        collection: null,
        tileType: 'poster-bubble',
        collapsible: false,
        useImageLoadingIndicator: true
    };

    /**
     * React: state
     */
    state = {
        isCollapsed: true,
        isCTAMenuActive: false
    };

    /**
     * React: shouldComponentUpdate
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        let collection = this.props.collection;
        let tileType = this.props.tileType;
        let className = 'media-item-tile'
            + ' ' + tileType
            + (this.props.collapsible ? ' collapsible' : '')
            + (this.props.collapsible && this.state.isCollapsed ? ' collapsed' : '')
            + (tileType === 'poster' || tileType === 'poster-bubble' ? ' col-lg-2 col-md-3' : '')
            + (tileType === 'backdrop' || tileType === 'backdrop-progress-bar' ? ' col-lg-4 col-md-6' : '')
            + (tileType === 'backdrop-detailed' ? ' col-lg-2 col-md-3' : '')
            + (this.props.collapsible ? ' col-xs-12' : (
                (tileType === 'poster' || tileType === 'poster-bubble' || tileType === 'backdrop-detailed' ? ' col-xs-4' : '')
                || (tileType === 'backdrop' || tileType === 'backdrop-progress-bar' ? ' col-xs-12' : '')
            ));

        return collection ? (
            <div className={className}>
                <div className="tile-content">
                    {collection.promotionTag ? (
                        <PromotionTag tag={collection.promotionTag}/>
                    ) : null}
                    <TileImage collection={collection}
                                         tileType={tileType}
                                         useImageLoadingIndicator={this.props.useImageLoadingIndicator}
                                         onClick={this._toggleCollapsed}/>
                </div>
            </div>
        ) : (
            <div className={className + " update-pending"}>
                <div className="tile-content">
                    <TileImage collection={collection}
                                    imageType={(tileType === 'backdrop' || tileType === 'backdrop-progress-bar') ? 'backdrop' : 'poster'}
                                    useImageLoadingIndicator={this.props.useImageLoadingIndicator}/>
                    <div className="information">
                        <h4 title="Loading..."><Spinner />{AppStore.translate('label.loading')}</h4>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Toggle collapsed
     */
    _toggleCollapsed = (event) => {
        if (this.props.collapsible && !MediaQuery.match(MediaQuery.BREAKPOINT_MD)) {
            event.preventDefault();
            event.stopPropagation();

            this.setState({
                isCollapsed: !this.state.isCollapsed
            });
        }
    };
}
