"use strict";

import React from 'react';
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare';
import platform from 'platform';
import Config from '../../../lib/Config';
import AppStore from '../../../stores/AppStore';
import AuthStore from '../../../stores/AuthStore';
import MediaItem from '../../../types/MediaItem';

/**
 * SilverlightPlayer component
 */
class SilverlightPlayer extends React.Component {
    /**
     * React: propTypes
     */
    static propTypes = {
        mediaPlayerInstanceId: PropTypes.string,
        mediaItem: PropTypes.instanceOf(MediaItem).isRequired,
        playerAction: PropTypes.oneOf(['play', 'download', 'trailer']),
        displayControls: PropTypes.bool,
        autoPlay: PropTypes.bool
    };

    /**
     * React: defaultProps
     */
    static defaultProps = {
        displayControls: true,
        autoPlay: true
    };

    /**
     * React: shouldComponentUpdate
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }

    /**
     * React: render
     */
    render() {
        let mediaItem = this.props.mediaItem;
        let initParams = 'environment=' + Config.ENVIRONMENT
            + (this.props.playerAction === 'trailer'
                ? ',videoUrl=' + mediaItem.trailerUrl
                : ',mediaItemId=' + mediaItem.id)
            + ',action=' + this.props.playerAction
            + ',device_id=' + AppStore.deviceId
            + ',authToken=' + AuthStore.identity.authToken
            + ',gaTrackingId=' + Config.GA_TRACKING_ID
            + ',controls=' + this.props.displayControls
            + ',autoPlay=' + this.props.autoPlay;

        return platform.name.indexOf('IE') > -1 ? (
            <div id="silverlightControlHost"
                 className="silverlight-player"
                 dangerouslySetInnerHTML={this._getPlayerMarkup(initParams)}>
            </div>
        ) : (
            <div id="silverlightControlHost" className="silverlight-player">
                <object data="data:application/x-silverlight-2," type="application/x-silverlight-2">
                    <param name="source" value="/silverlight/DownloadManager.xap?v=20160506_1"/>
                    <param name="onError" value={this._onPlayerError}/>
                    <param name="background" value="transparent"/>
                    <param name="minRuntimeVersion" value="5.0.61118.0"/>
                    <param name="autoUpgrade" value="true"/>
                    <param name="enableGPUAcceleration" value="true"/>
                    <param name="initParams" value={initParams}/>
                    <a href="http://go.microsoft.com/fwlink/?LinkID=149156&v=5.0.61118.0" target="_blank">
                        <img src="http://go.microsoft.com/fwlink/?LinkId=161376"
                             alt="Get Microsoft Silverlight"/>
                    </a>
                </object>
                <iframe id="_sl_historyFrame"></iframe>
            </div>
        );
    }

    /**
     * Get player markup
     */
    _getPlayerMarkup = (initParams) => {
        return {
            __html: `
                    <object data="data:application/x-silverlight-2," type="application/x-silverlight-2">
                        <param name="source" value="/silverlight/DownloadManager.xap?v=20160506_1"/>
                        <param name="onError" value=""/>
                        <param name="background" value="transparent"/>
                        <param name="minRuntimeVersion" value="5.0.61118.0"/>
                        <param name="autoUpgrade" value="true"/>
                        <param name="enableGPUAcceleration" value="true"/>
                        <param name="initParams" value="${initParams}"/>
                        <a href="http://go.microsoft.com/fwlink/?LinkID=149156&v=5.0.61118.0" target="_blank">
                            <img src="http://go.microsoft.com/fwlink/?LinkId=161376" alt="Get Microsoft Silverlight"/>
                        </a>
                    </object>
                    <iframe id="_sl_historyFrame"></iframe>
                    `
        };
    };

    /**
     * On player error
     *
     * @private
     */
    _onPlayerError = (sender, args) => {
        let appSource = "";
        if (sender != null && sender != 0) {
            appSource = sender.getHost().Source;
        }

        let errorType = args.ErrorType;
        let iErrorCode = args.ErrorCode;

        if (errorType == "ImageError" || errorType == "MediaError") {
            return;
        }

        let errMsg = "Unhandled Error in Silverlight Application " + appSource + "\n";

        errMsg += "Code: " + iErrorCode + "\n";
        errMsg += "Category: " + errorType + "\n";
        errMsg += "Message: " + args.ErrorMessage + "\n";

        if (errorType == "ParserError") {
            errMsg += "File: " + args.xamlFile + "\n";
            errMsg += "Line: " + args.lineNumber + "\n";
            errMsg += "Position: " + args.charPosition + "\n";
        }
        else if (errorType == "RuntimeError") {
            if (args.lineNumber != 0) {
                errMsg += "Line: " + args.lineNumber + "\n";
                errMsg += "Position: " + args.charPosition + "\n";
            }
            errMsg += "MethodName: " + args.methodName + "\n";
        }

        throw new Error(errMsg);
    };
}

export default SilverlightPlayer;
