"use strict";

import React from 'react';
import FormField from './ui/FormField.react';
import Form from './ui/Form.react';

/**
 * UIDemoPage component
 */
export default class UIFormPage extends React.Component {
    /**
     * React: state
     */
    state = {
        disabled: false,
        updatePending: false,
        error: '',

        buttonsUpdatePending: false,
        buttonsActive: false,
        buttonsDisabled: false,
        buttonsCTA: '',

        formData: {
            name: 'vladimir',
            password: '',
            email: 'vladimir.novakovic@spoiledmilk.com',
            textComment: 'Hello, this is my text comment',
            selectValue: 'opt2',
            secondSelectValue: 'opt3',
            radioValue: 'opt2',
            checkboxValues: ['opt1', 'opt2']
        },

        formChoices: {
            select: [{
                id: 1,
                label: 'option 1',
                value: 'opt1'
            }, {
                id: 2,
                label: 'option 2',
                value: 'opt2'
            }, {
                id: 3,
                value: 'opt3'
            }],
            secondSelect: [{
                id: 1,
                label: 'option 1',
                value: 'opt1'
            }, {
                id: 2,
                label: 'option 2',
                value: 'opt2'
            }, {
                id: 3,
                value: 'opt3'
            }],
            radio: [{
                id: 1,
                label: 'option 1',
                value: 'opt1'
            }, {
                id: 2,
                label: 'option 2',
                value: 'opt2'
            }, {
                id: 3,
                value: 'opt3'
            }],
            checkbox: [{
                id: 1,
                label: 'option 1',
                value: 'opt1'
            }, {
                id: 2,
                label: 'option 2',
                value: 'opt2'
            }, {
                id: 3,
                value: 'opt3'
            }]
        }
    };

    /**
     * React: render
     */
    render() {
        return (
            <main className="ui-demo-page">
                <div className="container">
                    <div className="row" style={{color: '#6a6a6a'}}>
                        <div className="demo-box col-sm-12 col-md-6 col-offset-md-3">
                            <Form onSubmit={this._submitForm}
                                  disabled={this.state.disabled}
                                  updatePending={this.state.updatePending}>
                                <FormField
                                    name="textOne"
                                    label="Text Input Label"
                                    value={this.state.formData.name}
                                    error={this.state.error}/>
                                <FormField
                                    name="password"
                                    type="password"
                                    value={this.state.formData.password}
                                    label="Password Input Label"
                                    error={this.state.error}/>
                                <FormField
                                    name="email"
                                    type="email"
                                    value={this.state.formData.email}
                                    label="Email Input Label"
                                    error={this.state.error}/>
                                <FormField
                                    name="textArea"
                                    type="textarea"
                                    value={this.state.formData.textComment}
                                    label="Textarea Input Label"
                                    error={this.state.error}/>
                                <FormField
                                    name="choiceSelect"
                                    type="choice-select"
                                    label="Select Input Label"
                                    value={this.state.formData.selectValue}
                                    choiceArray={this.state.formChoices.select}
                                    error={this.state.error}/>
                                <FormField
                                    name="choiceRadio"
                                    type="choice-radio"
                                    label="Radio Input Label"
                                    value={this.state.formData.radioValue}
                                    choiceArray={this.state.formChoices.radio}
                                    error={this.state.error}/>
                                <FormField
                                    name="choiceCheckbox"
                                    type="choice-checkbox"
                                    label="Checkbox Input Label"
                                    value={this.state.formData.checkboxValues}
                                    choiceArray={this.state.formChoices.checkbox}
                                    error={this.state.error}/>
                            </Form>


                            <br/>
                            <br/>
                            <button type='button' onClick={this._togglePendingState}>
                                Toggle pending state
                            </button>
                            <br/>

                            <button type='button' onClick={this._toggleDisabledState}>
                                Toggle disabled state
                            </button>
                            <br/>

                            <button type='button' onClick={this._toggleErrorState}>
                                Toggle error
                            </button>
                            <br/>
                        </div>
                    </div>
                </div>
            </main>
        );
    }

    // _submitForm = (formData) => {
    //     console.log(formData);
    // };

    /**
     * Toggle form update pending state
     *
     * @private
     */
    _togglePendingState = () => {
        // console.log('panding', !this.state.updatePending);
        this.setState({updatePending: !this.state.updatePending});
    };

    /**
     * Toggle form disabled state
     *
     * @private
     */
    _toggleDisabledState = () => {
        // console.log('disable', !this.state.disabled);
        this.setState({disabled: !this.state.disabled});
    };

    /**
     * Toggle form errors
     *
     * @private
     */
    _toggleErrorState = () => {
        console.log('error', !this.state.error);
        var error = this.state.error == '' ? "This is some error" : '';
        this.setState({error: error});
    };

    _changeNameValue = (newName) => {
        var formData = this.state.formData;
        formData.name = newName;

        this.setState({formData: formData});
    };

    _changePasswordValue = (newName) => {
        var formData = this.state.formData;
        formData.password = newName;

        this.setState({formData: formData});
    };

    _changeCommentValue = (newName) => {
        var formData = this.state.formData;
        formData.textComment = newName;

        this.setState({formData: formData});
    };

    _changeEmailValue = (newName) => {
        var formData = this.state.formData;
        formData.email = newName;

        this.setState({formData: formData});
    };

    _changeSelectValue = (newValue) => {
        var formData = this.state.formData;
        formData.selectValue = newValue;

        this.setState({formData: formData});
    };

    _changeSecondSelectValue = (newValue) => {
        var formData = this.state.formData;
        formData.secondSelectValue = newValue;

        this.setState({formData: formData});
    };

    _changeRadioValue = (newValue) => {
        var formData = this.state.formData;
        formData.radioValue = newValue;

        this.setState({formData: formData});
    };

    _onClickTest = () =>{
        console.log('click test');
    };
}
